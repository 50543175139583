import {useState,useEffect} from "react";

//custom hook for listening keypress
export default function useKeypress(target){
  //state for keeping track of whether key is pressed
   const [keypressed,setKeypressed]=useState(false);
   //if pressed key is our targeted key then set to true;
   
   function handleDown({key}){
     if(target===key){
       setKeypressed(true);
     }
   }
   //if released key is our targeted key then set to false
   function handleUp({key}){
     if(target===key){
       setKeypressed(false);
     }
   }

    //add event listeners
    useEffect(()=>{
      //set listeners
      window.addEventListener('keydown',handleDown);
      window.addEventListener('keyup',handleUp);

      //remove listerns
      return ()=>{
        window.removeEventListener('keydown',handleDown);
        window.removeEventListener('keyup',handleUp);
      }

    },[]);// Empty array ensures that effect is only run on mount and unmount

  return keypressed;
}