const initState = {
  user: {},
  isAuthenticated: false,
  token: null,
  isLoading: true,
  authError: null,
};

const authReducer = (state = initState, action) => {
  let payload = null;
  if (localStorage.getItem("user") !== null) {
    payload = JSON.parse(localStorage.getItem("user"));
    // const {token, user} = JSON.parse(payload);
  }

  switch (action.type) {
    case "LOGIN_ERROR":
      return Object.assign({}, state, {
        ...state,
        authError: "login Failed",
      });

    case "LOGIN_SUCCESS":
      console.log("login successSSS", action.data);

      localStorage.setItem("user", JSON.stringify(action.data));
      return Object.assign({}, state, {
        user: action.data,
        token: action.data._delegate.accessToken,
        isAuthenticated: true,
        isLoading: false,
      });

    case "IS_USER_LOGIN":
      if (payload !== null) {
        return Object.assign({}, state, {
          user: payload,
          token: payload.stsTokenManager.accessToken,
          isAuthenticated: true,
          isLoading: false,
        });
      } else {
        return Object.assign({}, state, {
          isLoading: false,
        });
      }

    case "LOGOUT":
      return Object.assign({}, state, {
        isAuthenticated: false,
        user: null,
        token: "",
      });

    default:
      return state;
  }
};

export default authReducer;
