import React from "react";

/// React router dom
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/// Css
import "../jsx/index.css";
import "../jsx/chart.css";

/// Layout
import Nav from "../jsx/layouts/nav";

/// View
import WebRepCallRecord from "../jsx/components/web-rep-pages/WebRepCallRecord";
import WebRepDashboard from "../jsx/components/web-rep-pages/WebRepDashboard";
import WebRepHome from "../jsx/components/web-rep-pages/WebRepHome";
import WebRepUserProfile from "../jsx/components/web-rep-pages/WebRepUserProfile";
import WebLoginPage from "../jsx/components/web-rep-pages/WebLoginPage";
import WebRepForgotPassword from "../jsx/components/web-rep-pages/WebRepForgotPassword";
import WebRepCallRecordHospital from "../jsx/components/web-rep-pages/WebRepCallRecordHospital";
import { PrivateRoute } from "../HOC/PrivateRoutes";

const WebRepRoutes = () => {
  const loggedInRoutes = [
    ////  Web Rep

    { url: "/", component: WebRepHome },
    { url: "dashboard", component: WebRepDashboard },
    { url: "call-record/:id", component: WebRepCallRecord },
    { url: "call-record-hospital/:id", component: WebRepCallRecordHospital },
    { url: "user-profile/:nid", component: WebRepUserProfile },
  ];

  const loggedOutRoutes = [{ url: "login", component: WebLoginPage }, { url: "forgot-password", component: WebRepForgotPassword }];

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");

  console.log(pagePath);

  return (
    <Router>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"} ${
          !path ? "right-profile" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`  ${!pagePath ? "content-body" : ""}`}>
          <div className={`${!pagePath ? "container-xxl" : ""}`}>
            <Switch>
              {loggedInRoutes.map((data, i) => (
                <PrivateRoute
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
              {loggedOutRoutes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {/* {!pagePath && <Footer />} */}
      </div>
    </Router>
  );
};

export default WebRepRoutes;
