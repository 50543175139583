import React,{useState,useRef} from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import swal from 'sweetalert';
const RepUserProfileTable = ({
  data,
  profile,
  setSelectedDoctors,
  setSelectedMessage,
  setSelectedInteraction,
  setSelectedProduct,
  hospitals,
  setData,
  setTableLoadOnPost,
  page,
  setPage,
  rowPerPage,
  totalCallRecord,
  setKeyMessage,
  sortTable
}) => {

  const sort =rowPerPage ;
  const activePag = useRef(0);
  const [test, settest] = useState(0);
  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data?.length; ++i) {
      if (i >= frist && i < sec) {
        data[i]?.classList?.remove("d-none");
      } else {
        data[i]?.classList?.add("d-none");
      }
    }
  };
  const handlePage=(no)=>{
    console.log(" set page number = ",no);
    setPage(no);
  }

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination;
    let paggination = []
    if(totalCallRecord){
      paggination =  Array(Math.ceil(totalCallRecord / sort))
      .fill()
      .map((_, i) => i + 1);
    }

  // Active paggination & chage data

  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  const formatter = (data,call_record_id) => {
    return {
      id: data.id,
      label: data.name,
      value: data.name,
      call_record_id: call_record_id
    };;
  };

  const doctorFormatter = (data) => {
    if(!Array.isArray(data)) return []
    return data.map(doc=>({label:doc.display_name,id:doc.id,tier:doc.tier}))
  };

  const setDatahandler = (data,item) => {     
      setSelectedDoctors(doctorFormatter(data.doctors));
      setSelectedMessage(formatter(data.key_message[0]));
      setSelectedInteraction(formatter(data.interaction_type,data.call_record_id));
      setSelectedProduct(formatter(data?.product));
      setKeyMessage(data.key_message.map(Km=> Km.id));
      setData( {
        key_message:data.key_message,
        interaction_type:{...data.interaction_type,call_record_id:data.call_record_id},
        product:data?.product,
        call_date:data.call_date,
        doctors:data.doctors,
        cid: data.cid,
        call_record_id: data.call_record_id,
        leave_behinds: [
          {
              name: 'JCO',
              value: data?.leave_behinds[0]?.value
          },
          // {
          //     name: 'NEJM',
          //     value: data?.leave_behinds[1]?.value
          // }
      ],
      notes:''
    });
    window.scroll({
      top: 200,
      left: 100,
      behavior: 'smooth'
    });
  };



  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body p-0">
          <div className="dataTables_wrapper">
            <Table
              responsive
              striped
              hover
              id="patientTable_basic_table"
              className="display dataTable no-footer  w-100"
            >
              <thead>
                <tr role="row">
                <th className="sorting">
                    <div className="d-flex align-items-center">
                      <span>CID</span>
                      <div className="d-flex flex-column ml-2">
                        <i className="fa fa-sort-up line-height-0 btn btn-sort p-0"  onClick={() => sortTable('cid', 'CALL_RECORD', 0)} />
                        <i className="fa fa-sort-down line-height-0 btn btn-sort p-0" onClick={() => sortTable('cid', 'CALL_RECORD', 1)} />
                      </div>
                    </div>
                  </th>
                  <th className="sorting">
                    <div className="d-flex align-items-center">
                      <span>Call Date</span>
                      <div className="d-flex flex-column ml-2">
                        <i className="fa fa-sort-up line-height-0 btn btn-sort p-0"  onClick={() => sortTable('call_date', 'CALL_RECORD', 0)} />
                        <i className="fa fa-sort-down line-height-0 btn btn-sort p-0" onClick={() => sortTable('call_date', 'CALL_RECORD', 1)} />
                      </div>
                    </div>
                  </th>
                  <th className="sorting">
                    <div className="d-flex align-items-center">
                       <span>Name</span>
                      {/* <div className="d-flex flex-column ml-2">
                        <i className="fa fa-sort-up line-height-0 btn btn-sort p-0"  onClick={() => sortTable('name', 'CALL_RECORD', 0)} />
                        <i className="fa fa-sort-down line-height-0 btn btn-sort p-0" onClick={() => sortTable('name', 'CALL_RECORD', 1)} />
                      </div> */}
                    </div>
                  </th>
                  <th className="sorting">
                    <div className="d-flex align-items-center">
                       <span>Type</span>
                      {/* <div className="d-flex flex-column ml-2">
                        <i className="fa fa-sort-up line-height-0 btn btn-sort p-0" />
                        <i className="fa fa-sort-down line-height-0 btn btn-sort p-0" />
                      </div> */}
                    </div>
                  </th>
                  <th className="sorting">
                    <div className="d-flex align-items-center">
                      <span>Status</span>
                      <div className="d-flex flex-column ml-2">
                        <i className="fa fa-sort-up line-height-0 btn btn-sort p-0"  onClick={() => sortTable('status', 'CALL_RECORD', 0)} />
                        <i className="fa fa-sort-down line-height-0 btn btn-sort p-0" onClick={() => sortTable('status', 'CALL_RECORD', 1)} />
                      </div>
                    </div>
                  </th>
                  <th className="sorting">
                    <div className="d-flex align-items-center">
                      <span>Tier</span>
                      {/* <div className="d-flex flex-column ml-2">
                        <i className="fa fa-sort-up line-height-0 btn btn-sort p-0" />
                        <i className="fa fa-sort-down line-height-0 btn btn-sort p-0" />
                      </div> */}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item) => (
                  item?.doctors?.concat(item?.hospitals)?.map(
                    (subitem) => 
                    (<tr role="row" className={item.trClassName}>
                      <td>{item?.cid}</td>
                      <td>{moment(item?.call_date).format('YYYY/MM/DD')}</td>
                      <td>{subitem.hid ? subitem.hospital_name : subitem.display_name}</td>
                      <td>{subitem.hid ? "Hospital" : "Doctor"}</td>
                      <td>
                        <span
                          className={`badge light ${item?.status === "SUBMITTED"
                              ? "text-primary"
                              : "text-warning"
                            }`}
                        >
                          <i className="fa fa-circle mr-2" />
                          {item?.status}
                        </span>
                      </td>
                      <td>{subitem?.tier}</td>
                    </tr>)
                  )
                ))}
              </tbody>
            </Table>
            
            <div className="d-sm-flex text-center align-items-center justify-content-between px-3">
                  <div
                    className="dataTables_info"
                    id="example_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing {(rowPerPage*page)-rowPerPage+1} to{" "} 
                      {rowPerPage*page}{" "}
                    of {totalCallRecord} entries
                  </div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example_paginate"
                  >
                    <button
                      className="paginate_button previous disabled"
                      to="/home"
                      onClick={() => handlePage(page - 1)}
                      disabled={page===1}
                    >
                      Previous
                    </button>
                    <span style={{maxWidth: '200px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'inline-flex'}}>
                      {paggination.map((number, i) => (
                        <button
                          key={i}
                          className={`paginate_button  ${
                            number === page ? "current" : ""
                          } ${number > 1 ? "ml-1" : ""}`}
                          onClick={() => handlePage(number)}
                        >
                          {number}
                        </button>
                      ))}
                    </span>
                    <button
                      className="paginate_button next"
                      onClick={() =>handlePage(page + 1)}
                      disabled={paggination.length===page}
                    >
                      Next
                    </button>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepUserProfileTable;
