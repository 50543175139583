import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import axios from "axios";
import { primaryStyle } from "../PluginsMenu/Select2/SelectStyles";
import Select from "react-select";

import swal from "sweetalert";

const options = [
  { value: "M", name: "male", label: "Male" },
  { value: "F", name: "female", label: "Female" },
];

const hospitalOptions = [
  { value: "true", name: "true", label: "True" },
  { value: "false", name: "false", label: "False" },
];

const HomeModal = ({ setLargeModal, largeModal, formType, hospital_id }) => {
  
  const [formData, setFormData] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const repId = useSelector((state) => state.firebase.auth.uid);

  const handleBlur = (e) => {
    console.log(" form data ", formData);
    console.log(" e data ", e);

    const newLoginData = { ...formData };
    if (e?.target) {
      newLoginData[e.target.name] = e.target.value;
    } else {
      newLoginData[e.name] = e.value;
    }
    setFormData(newLoginData);
  };
  const submitHandler = (e) => {
    let isValid=true;
    if (formType === 'DOCTOR') {

      if (hospital_id) 
        formData.primary_hospital_affiliated_id = hospital_id;
      const requiredData = { first_name: true, last_name: true, zipcode: true, npid: true }

      console.log("formdata ", formData);
      for (const key in requiredData) {
        if (!(formData[key])) {
          isValid = false;
          swal('Please fill the required field');
        }
      }
    } else {
      const requiredData = { hospital_name: true, zipcode: true };
      for (const key in requiredData) {
        if (!(formData[key])) {
          isValid = false;
          swal('Please fill the required field');
        }
      }
    }
    e.preventDefault();
    if(!isValid)return;
    setSubmitting(true);
    if (formType === "DOCTOR") {

      const primary_hospital_id = (formData.primary_hospital_affiliated_id)?formData.primary_hospital_affiliated_id:hospital_id;
      const data = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        display_name: formData.display_name,
        npid: formData.npid,
        primary_speciality: formData.primary_speciality,
        gender: selectedOption,
        age: formData.age,
        education: formData.education,
        graduation_year: formData.graduation_year,
        soft_tissue_sarcoma_percentile: formData.soft_tissue_sarcoma_percentile,
        primary_hospital_affiliated_id: primary_hospital_id,
        tags: formData.tag ? [formData.tag]:[],
        health_grades_profile_url: formData.health_grades_profile_url,
        office_phone: formData.office_phone,
        address: {
          line1: formData.line1,
          street: formData.street,
          city: formData.city,
          state: formData.state,
          country: formData.country,
          zipcode: formData.zipcode,
        },
      };
      console.log(data);
      axios
        .post(`/v1/rep_orchestrator/doctor`, data
        ,{ headers: { 'x-rep-uid': repId }})
        .then((res) => {
          console.log(res);
          setSubmitting(false);
          swal("Success!", "Added Successfully!", "success")
          .then((val) => {
            setLargeModal(false);
            setFormData({});
          })
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            // Request made and server responded
            swal("Error!", err.response.data.error_message, "error")
          } else {
            swal("Something went wrong!", err.message, "error")
          }
          setSubmitting(false);
        });

    } else {
      const data = {
        legacy_id: formData.legacy_id,
        hospital_name: formData.hospital_name,
        hospital_website_url: formData.hospital_website_url,
        hospital_facility_url: formData.hospital_facility_url,
        sarcoma_specialized: selectedOption,
        address: {
          line1: formData.line1,
          street: formData.street,
          city: formData.city,
          state: formData.state,
          country: formData.country,
          zipcode: formData.zipcode,
        },
      };
      console.log(data);
      axios
        .post(`/v1/rep_orchestrator/hospital`, data
        ,{ headers: { 'x-rep-uid': repId }})
        .then((res) => {
          console.log(res);
          setSubmitting(false);
          swal("Success!", "Added Successfully!", "success")
          .then((val) => {
            setFormData({});
            setLargeModal(false);
          })
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
          swal("Something went wrong!", err.message, "error")
          // .then((val) => {
          //   setFormData({});
          // })
        });
    }
  };

  const physicianTags = [
    { label:"MD", value:	"MD", name:	'tag'},
    { label:"DO", value:	"DO", name:	"tag"},
    { label:"Pharm D", value:	"PHARM_D", name:	"tag"},
    { label:"R Ph", value:	"R_PH", name:	"tag"},
    { label:"Practice Manager", value:	"PM", name:	"tag"},
    { label:"Nurse Practioner", value:	"NP", name:	"tag"},
    { label:"Registered Nurse", value:	"RN", name:	"tag"},
    { label:"Physician Assistant", value:	"PA", name:	"tag"},
    { label:"Reimbursement Manager", value:	"REBM", name:	"tag"},
    { label:"Pharmacists", value:	"PHM", name:	"tag"},
    { label:"Others", value:	"OTH", name:	"tag"},
    { label:"None"	, value:"NONE", name:	"tag"}
  ];

  const inputs = (type) => {
    if (type === "DOCTOR")
      return [
        { name: "first_name", label: "First Name *", type: "text" ,required:true},
        { name: "last_name", label: "Last Name *", type: "text" },
        { name: "display_name", label: "Display Name ", type: "text" },
        { name: "npid", label: "NPI **", type: "text" ,required:true },
        {
          name: "primary_speciality",
          label: "Primary Speciality",
          type: "text",
        },
        { name: "line1", label: "Address Line 1", type: "text" },
        { name: "street", label: "Address Line 2", type: "text" },
        { name: "city", label: "City", type: "text" },
        { name: "state", label: "State", type: "text" },
        { name: "zipcode", label: "Zipcode *", type: "number" },
        {
          name: "primary_hospital_affiliated_id",
          label: "Primary Hospital Affiliated Id",
          type: "text",
          default: hospital_id
        },
        {
          name: "hospital_name",
          label: "Hospital Name",
          type: "text",
        },
        {
          name: "TAGS",
          label: "Physician Tags",
          type: "text",
          
        },
        { name: "office_phone", label: "Office Phone  ", type: "text" },
      ];
    else
      return [
        { name: "hospital_name", label: "Hospital Name *", type: "text" },
        { name: "line1", label: "Address Line 1", type: "text" },
        { name: "street", label: "Address Line 2", type: "text" },
        { name: "city", label: "City", type: "text" },
        { name: "state", label: "State", type: "text" },
        { name: "country", label: "Country", type: "text" },
        { name: "zipcode", label: "Zipcode *", type: "number" },
      ];
  };

  return (
    <Modal
      centered
      show={largeModal}
      size="xl"
      onHide={() => setLargeModal(false)}
    >
      <Modal.Header>
        <Modal.Title>Add  {formType ==='DOCTOR'?'Customer':'Account'}</Modal.Title>
        <Button
          variant=""
          className="close"
          onClick={() => setLargeModal(false)}
        >
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="card mb-0">
        <span style={{fontSize:'12px',fontStyle:'italic'}}> Mandatory fields are marked with an asterisk (*)</span>
        <span style={{fontSize:'12px',fontStyle:'italic'}}> Enter 'NA' if NPI is not available (**)</span>

          <form onSubmit={(e) => submitHandler(e)}>
            <div className="row mx-0 row-cols-1 row-cols-xl-2 py-4">
              {inputs(formType).map((item) => (
                <div className="form-group row mx-0">
                  <label className="col-sm-5 col-form-label text-capitalize">
                    {item.label}
                  </label>
                  <div className="col-sm-6">
                    {item.select ? (
                      <Select
                        styles={primaryStyle}
                        options={
                          formType === "DOCTOR" ? options : hospitalOptions
                        }
                        onChange={(e) => setSelectedOption(e.value)}
                      />
                   ) : (
                     item.name !== 'TAGS' ?
                       <input
                         name={item.name}
                         type={item.type}
                         className="form-control"
                         onChange={handleBlur}
                       /> :
                       <Select
                         styles={primaryStyle}
                         placeholder="Physician Tags"
                         options={physicianTags}
                         onChange={handleBlur}
                       />
                   )}
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-5 mb-4 d-flex align-items-center justify-content-center flex-column flex-md-row">
              <button
                type="submit"
                className="btn btn-primary rounded-pill px-5"
                disabled={submitting}
              >
                Submit
              </button>
              <span className="my-3 mx-3" />
              <button type="button" className="btn btn-dark rounded-pill px-5" onClick={() => {
                setLargeModal(false);
                setFormData({});
                }}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HomeModal;
