import { setUserData } from "./userActions";
import swal from "sweetalert";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import {firebaseConfig} from "../../../src/config/fbConfig"

export const forgotPassword = (email) => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();
    
    firebase.auth().sendPasswordResetEmail(email)
    .then(function () {
        swal("Email Sent!!", "Please check your email", "success")
    }).catch(function (e) {
        console.log(e)
        swal("Email Sending failed!!", "User doesnot exist", "error")
    }); 
  }
}

export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((user) => {
        console.log(user.user);
        if (user) {
          dispatch(
            setUserData({
              userId: user.user.uid,
              email: user.user.email,
            })
          );
          swal("Success!", "Logged In Successfully!", "success");

          return dispatch({ type: "LOGIN_SUCCESS", data: user.user });
        } else {
          return dispatch({
            type: "LOGIN_ERROR",
            payload: "Login Failed",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        swal("The credentails you have entered are wrong. Please  check again or contact support team!");

        dispatch({ type: "LOGIN_ERR", err });
      });
  };
};

export const randPassword = () => {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
};

export const signUp = (credentials, setModalCentered) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    
    var secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary");

    credentials.password = randPassword();
    secondaryApp
      .auth()
      .createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then((user) => {
        console.log(user);
        if (user) {
          swal("Success!", "Rep Added Successfully!", "success");
          setModalCentered(false);
          console.log(" user = ", user);
          // return dispatch({ type: "USER_CREATED" , action: credentials.email});
        } else {
          setModalCentered(false);
          // return dispatch({
          //   type: "LOGIN_ERROR",
          //   payload: "Creation of User Failed",
          // });
        }
      })
      .then(() => {
        secondaryApp.auth().sendPasswordResetEmail(credentials.email)
        .then(function () {
            swal("Email Sent!!", "Please check your email", "success")
        }).catch(function (e) {
            console.log(e)
            swal("Email Sending failed!!", "User doesnot exist", "error")
        }); 
      })
      .catch((err) => {
        setModalCentered(false);
        console.log(err);
        swal("Something went wrong!", err.message, "error");

        // dispatch({ type: "LOGIN_ERR", err });
      });
  };
};

export const isLoggedIn = (dispatch) => {
  return (dispatch) => {
    return dispatch({
      type: "IS_USER_LOGIN",
    });
  };
};

export const logOut = (dispatch) => {
  localStorage.clear();
  return (dispatch) => {
    return dispatch({
      type: "LOGOUT",
    });
  };
};
