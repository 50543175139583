import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./store/reducers/roodReducer";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { getFirestore, reduxFirestore } from "redux-firestore";
import { ReactReduxFirebaseProvider, getFirebase } from "react-redux-firebase";
import fbConfig from "./config/fbConfig";
import firebase from "firebase/compat/app";
import { composeWithDevTools } from "redux-devtools-extension";
import axios from "axios";

import SimpleReactLightbox from "simple-react-lightbox";


// axios.defaults.baseURL = "https://stage-rep-web-tool.herokuapp.com";
// axios.defaults.baseURL = "http://localhost:8080";
axios.defaults.baseURL = process.env.REACT_APP_API_BCKEND_URL;


const store = createStore(
  rootReducer,

  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reduxFirestore(fbConfig),
    composeWithDevTools(applyMiddleware())
    // reactReduxFirebase(fbConfig)
  )
);

const rrfProps = {
  firebase,
  config: fbConfig,
  dispatch: store.dispatch,
};

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <App />{" "}
        </ReactReduxFirebaseProvider>
      </Provider>
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById("root")
);
