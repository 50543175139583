import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { Table } from "react-bootstrap";
import cr from "../../../images/call_record_green.png";
import { primaryStyle } from "../PluginsMenu/Select2/SelectStyles";
import Select from "react-select";
import useKeypress from "../../../hooks/useKeypress";
import axios from "axios";
import GoToTop from "./GoToTop";
import "../../../css/table.css";

import PerfectScrollbar from "react-perfect-scrollbar";

//Images
import doc from "../../../images/doc2.png";
import { IconButton } from "@material-ui/core";

const DoctorUserProfile = ({
  data,
  profile,
  hospitals,
  onDoctorUpdate,
  hospitalUniverse,
  setHospitalUniverse,
  updateExistingDoctor,
  sortTable,
  repId,
}) => {
  const [email, setEmail] = useState(profile?.email);
  const [editEmail, setEditEmail] = React.useState(false);
  const [editSpeciality, setEditSpeciality] = useState(false);
  const [editPrimaryHospital, setEditPrimaryHospital] = React.useState(false);
  const [selectSpeciality, setSelectSpeciality] = useState(null);
  const [specialitiesQueryParamProfile, setSpecialitiesQueryParamProfile] =
    useState(null);
  const [checkCurrentValue, setCheckCurrentValue] = useState(null);
  const [editHospitalId, setEditHospitalId] = React.useState(null);
  const [linkingHospitalsEnabled, setLinkingHospitalsEnabled] =
    React.useState(false);
  const [search, setSearch] = React.useState("");
  const enter = useKeypress("Enter");

  useEffect(() => {
    fetchSpecialitiesList();
  }, []);

  useEffect(() => {
    if (enter) {
      onSearch();
    }
  }, [enter]);

  const onSearch = () => {
    axios
      .get(`/v1/rep_orchestrator/hospital/search?keyword=${search}`, {
        headers: { "x-rep-uid": repId },
      })
      .then((res) => {
        setHospitalUniverse(res.data.hospitals);
        setLinkingHospitalsEnabled(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLinking = (hos) => {
    console.log("hospital being sent = ", hos);
    // onDoctorUpdate(hos, "LINKING_D", profile);
    updateExistingDoctor(hos, "LINKING_D", profile);
    disableLinking();
  };
  const tagToNameMap = {
    RN: "Registered Nurse",
    PA: "Physician Assistant",
    NP: "Nurse Practitioner",
    REBM: "Reimbursement Manager",
    PHM: "Pharmacist",
    OTH: "Others",
  };

  const primaryQueryParam = [
    { value: "YES", label: "Yes" },
    { value: "NO", label: "No" },
  ];

  const fetchSpecialitiesList = () => {
    axios
      .get(`/v1/rep_orchestrator/meta/home`, {
        headers: { "x-rep-uid": repId },
      })
      .then((res) => {
        console.log("data = ", res.data);
        const objectList = res.data.specialities.map((str) => ({
          value: str,
          label: str,
        }));

        objectList.unshift({ key: "ALL", value: "ALL", label: "ALL" });
        setSpecialitiesQueryParamProfile(objectList);
      });
  };

  // A comparer used to determine if two entries are equal.
  const isSameHospital = (a, b) => a.id == b.id;

  const filterHospitals = (univ) => {
    if (!hospitals || hospitals.length === 0) {
      return univ;
    }

    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.
    const onlyInLeft = (left, right, compareFunction) =>
      left.filter(
        (leftValue) =>
          !right.some((rightValue) => compareFunction(leftValue, rightValue))
      );

    return onlyInLeft(univ, hospitals, isSameHospital);
  };

  useEffect(() => {
    setEmail(profile?.email);
  }, [profile?.email]);

  const handleEditSpecialityClick = () => {
    setEditSpeciality(true);
  };

  const closeHandleSpecialityClick = () => {
    setEditSpeciality(false);
  };
  const handleEditEmailClick = () => {
    setEditEmail(true);
  };

  const closeEditMode = () => {
    setEditEmail(false);
  };

  const handleEditPrimaryHospitalClick = (value) => {
    setEditPrimaryHospital(true);
    setEditHospitalId(value);
  };

  const closeEditPrimaryHospitalMode = () => {
    setEditPrimaryHospital(false);
    setEditHospitalId(null);
  };

  const disableLinking = () => {
    setLinkingHospitalsEnabled(false);
    if (filterHospitals(hospitalUniverse)?.length === 0) {
      setSearch("");
    }
  };

  const enableLinking = () => {
    setLinkingHospitalsEnabled(true);
    if (filterHospitals(hospitalUniverse)?.length === 0) {
      onSearch();
    }
  };

  const isPrimaryHospital = (item) => {
    let status = "Yes";
    status = item?.id == profile?.primary_hospital_affiliated_id ? "Yes" : "No";
    if (status === "Yes") {
      return status;
    }
    if (
      profile?.old_primary_hospital_id?.includes(item?.id) &&
      profile?.primary_hospital_affiliated_id?.includes("YES")
    ) {
      return "Yes";
    }

    if (profile?.old_primary_hospital_id === item?.id) {
      return "Yes";
    }
    return "No";
  };

  console.log("Profile", profile?.primary_speciality, checkCurrentValue);

  return (
    <div className="card mx-3">
      <div className="col-12 col-xl-12">
        <div className="card-body">
          <div className="media d-sm-flex d-block text-center text-sm-left pb-4 mb-4 border-bottom">
            <img
              alt="pic"
              className="rounded mr-sm-4 mr-0"
              width={130}
              src={doc}
            />
            <div className="media-body align-items-center">
              <div className="d-sm-flex d-block justify-content-between my-3 my-sm-0">
                <div>
                  <h3 className="fs-22 text-black font-w600 mb-0">
                    {profile?.display_name
                      ? profile?.display_name
                      : profile?.hospital_name}
                  </h3>
                  <p className="mb-2 mb-sm-2">{profile?.primary_speciality}</p>
                  <p className="mb-2 mb-sm-2">{profile?.tier}</p>
                </div>
              </div>
            </div>
            <div className="width300 mb-5">
              <Link
                to={{
                  pathname: "/call-record/" + profile?.id,
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary rounded-pill w-100 py-2"
                >
                  Record a Call
                </button>
              </Link>
              <a href="https://forms.office.com/r/vPgAgEfSGR" target="_blank">
                <button type="button" className="btn w-150 py-2">
                  Raise DCR
                </button>
              </a>
            </div>
          </div>
          <div className="row">
            {profile?.address?.zipcode ? (
              <div className="col-lg-7 mb-3">
                <div className="media">
                  <div>
                    {" "}
                    <span className="d-block  mb-2">Address</span>
                    <p className="fs-18 text-dark">
                      {profile?.address?.line1}
                      {profile?.address?.street}
                      {", "}
                      {profile?.address?.city}
                      {", "}
                      <strong>
                        {profile?.address?.state}
                        {", "} {profile?.address?.country}
                        {profile?.address?.zipcode}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="row">
            <div className="col-lg-3 mb-3">
              <span className="d-block  mb-2">NPI</span>
              <p className="fs-18 text-dark font-w600 mb-0">{profile?.npid}</p>
            </div>
            <div className="col-lg-3 mb-3">
              <span className="d-block  mb-2">Specialities</span>
              <span>
                {!editSpeciality ? (
                  <p className="fs-18 text-dark font-w600 mb-0">
                    {profile?.primary_speciality}
                    <i
                      className="fa fa-edit line-height-0 btn btn-sort p-1 ml-2"
                      onClick={handleEditSpecialityClick}
                    />
                  </p>
                ) : (
                  <div className="special-main-div">
                    <Select
                      styles={primaryStyle}
                      options={specialitiesQueryParamProfile}
                      value={selectSpeciality}
                      onChange={(e) => {
                        console.log("Speciality event -> ", e);
                        setSelectSpeciality(e);
                        setCheckCurrentValue(e.value);
                      }}
                      className="special-select"
                    />
                    {profile?.primary_speciality ===
                    checkCurrentValue ? null : (
                      <IconButton
                        color="green"
                        onClick={() => {
                          closeHandleSpecialityClick();
                          console.log(
                            "EventselectSpeciality",
                            selectSpeciality.label
                          );
                          onDoctorUpdate(
                            selectSpeciality.label,
                            "SPECIALITY_UPDATE"
                          );
                        }}
                        style={{
                          color: "green",
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        <DoneIcon />
                      </IconButton>
                    )}

                    <IconButton
                      onClick={closeHandleSpecialityClick}
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                )}
              </span>
            </div>
            <div className="col-lg-3 mb-md-0 mb-3">
              <span className="d-block  mb-2">Email</span>
              {!editEmail ? (
                <p className="fs-18 text-dark font-w600 mb-0">
                  <a href={"mailto:".concat(profile?.email)}>
                    {profile?.email ?? "None"}
                  </a>
                  <i
                    className="fa fa-edit line-height-0 btn btn-sort p-1 ml-2"
                    onClick={handleEditEmailClick}
                  />
                </p>
              ) : (
                <p className="fs-18 text-dark font-w600 mb-0">
                  <div className="d-inline-flex justify-content-around">
                    <input
                      type="text"
                      className="form-control"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />

                    <IconButton
                      color="green"
                      onClick={() => {
                        closeEditMode();
                        onDoctorUpdate(email, "EMAIL");
                      }}
                      style={{
                        color: "green",
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <DoneIcon />
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        closeEditMode();
                      }}
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </p>
              )}
            </div>
            <div className="col-lg-3 mb-3">
              <span className="d-block  mb-2">Email(IQVIA)</span>
              <p className="fs-18 text-dark font-w600 mb-0">
                <a href={"mailto:".concat(profile?.msl_email)}>
                  {profile?.msl_email}
                </a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-5 mb-md-0 mb-4">
              <div class="custom-control custom-checkbox mb-4">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="ts"
                  checked={profile?.treats_sarcoma}
                  onClick={(event) =>
                    onDoctorUpdate(event.target.checked, "SARCOMA_STATUS")
                  }
                />
                <label
                  class="custom-control-label"
                  for="ts"
                  style={{ paddingLeft: 8 }}
                >
                  <p className="fs-18 text-dark font-w600 mb-0">
                    HCP doesn't treat soft tissue sarcoma
                  </p>
                </label>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 mb-md-0 mb-4">
              <div class="custom-control custom-checkbox mb-4">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="fya"
                  checked={profile?.is_fyarro_adaptor}
                  onClick={(event) =>
                    onDoctorUpdate(event.target.checked, "IS_FYARRO_ADAPTOR")
                  }
                />
                <label
                  class="custom-control-label"
                  for="fya"
                  style={{ paddingLeft: 8 }}
                >
                  <p className="fs-18 text-dark font-w600 mb-0">
                    HCP is a Fyarro Adopter
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-xl-12 col-lg-8 mb-2">
              <div
                className="basic-form"
                onChange={(e) => {
                  onDoctorUpdate(e.target.value, "TAGS");
                }}
              >
                <div className="form-group mb-0">
                  <label className="radio-inline mr-3">
                    <input
                      type="radio"
                      name="optradio"
                      value="PA"
                      checked={profile?.tags?.includes("PA")}
                    />
                    <label for="tsy" style={{ paddingLeft: 8 }}>
                      <p className="fs-18 text-dark font-w600 mb-0">
                        {tagToNameMap["PA"]}
                      </p>
                    </label>
                  </label>
                  <label className="radio-inline mr-3">
                    <input
                      type="radio"
                      name="optradio"
                      value="NP"
                      checked={profile?.tags?.includes("NP")}
                    />
                    <label for="tsy" style={{ paddingLeft: 8 }}>
                      <p className="fs-18 text-dark font-w600 mb-0">
                        {tagToNameMap["NP"]}
                      </p>
                    </label>
                  </label>
                  <label className="radio-inline mr-3">
                    <input
                      type="radio"
                      name="optradio"
                      value="RN"
                      checked={profile?.tags?.includes("RN")}
                    />
                    <label for="tsy" style={{ paddingLeft: 8 }}>
                      <p className="fs-18 text-dark font-w600 mb-0">
                        {tagToNameMap["RN"]}
                      </p>
                    </label>
                  </label>
                  <label className="radio-inline mr-3">
                    <input
                      type="radio"
                      name="optradio"
                      value="REBM"
                      checked={profile?.tags?.includes("REBM")}
                    />
                    <label for="tsy" style={{ paddingLeft: 8 }}>
                      <p className="fs-18 text-dark font-w600 mb-0">
                        {tagToNameMap["REBM"]}
                      </p>
                    </label>
                  </label>
                  <label className="radio-inline mr-3">
                    <input
                      type="radio"
                      name="optradio"
                      value="PHM"
                      checked={profile?.tags?.includes("PHM")}
                    />
                    <label for="tsy" style={{ paddingLeft: 8 }}>
                      <p className="fs-18 text-dark font-w600 mb-0">
                        {tagToNameMap["PHM"]}
                      </p>
                    </label>
                  </label>
                  <label className="radio-inline mr-3">
                    <input
                      type="radio"
                      name="optradio"
                      value="OTH"
                      checked={profile?.tags?.includes("OTH")}
                    />
                    <label for="tsy" style={{ paddingLeft: 8 }}>
                      <p className="fs-18 text-dark font-w600 mb-0">
                        {tagToNameMap["OTH"]}
                      </p>
                    </label>
                  </label>
                  <label className="radio-inline mr-3">
                    <input
                      type="radio"
                      name="optradio"
                      value="NONE"
                      checked={profile?.tags?.length === 0}
                    />
                    <label for="tsy" style={{ paddingLeft: 8 }}>
                      <p className="fs-18 text-dark font-w600 mb-0">None</p>
                    </label>
                  </label>
                </div>
              </div>
            </div>
            {linkingHospitalsEnabled ? (
              <div className="row">
                <div className="col-8">
                  <div className="card p-4">
                    <p className="fs-18 text-dark font-w600 mb-0 py-4">
                      Affiliated HCOs:
                    </p>
                    <div className="col-15">
                      {/* <div className="card">
                          <div className="card-body p-0"> */}
                      <div className="dataTables_wrapper">
                        <Table
                          responsive
                          striped
                          hover
                          id="patientTable_basic_table"
                          className="display dataTable no-footer  w-100"
                        >
                          <thead>
                            <tr role="row">
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span> Name</span>
                                  <div className="d-flex flex-column ml-2">
                                    <i
                                      className="fa fa-sort-up line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          "hospital_name",
                                          "HOSPITALS",
                                          0
                                        )
                                      }
                                    />
                                    <i
                                      className="fa fa-sort-down line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          "hospital_name",
                                          "HOSPITALS",
                                          1
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Primary ?</span>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Sarcoma</span>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>City</span>
                                  <div className="d-flex flex-column ml-2">
                                    <i
                                      className="fa fa-sort-up line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "city"],
                                          "HOSPITALS",
                                          0
                                        )
                                      }
                                    />
                                    <i
                                      className="fa fa-sort-down line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "city"],
                                          "HOSPITALS",
                                          1
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>State</span>
                                  <div className="d-flex flex-column ml-2">
                                    <i
                                      className="fa fa-sort-up line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "state"],
                                          "HOSPITALS",
                                          0
                                        )
                                      }
                                    />
                                    <i
                                      className="fa fa-sort-down line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "state"],
                                          "HOSPITALS",
                                          1
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Zipcode</span>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Record</span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {hospitals?.map((item) => (
                              <tr role="row" className={item.trClassName}>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/user-profile/H" + item?.id,
                                    }}
                                    target="_blank"
                                  >
                                    {item?.hospital_name}
                                  </Link>
                                </td>
                                <td>
                                  {editPrimaryHospital &&
                                  editHospitalId === item?.id
                                    ? ""
                                    : isPrimaryHospital(item)}
                                  {editPrimaryHospital &&
                                  editHospitalId === item?.id ? (
                                    <p className="fs-18 text-dark font-w600 mb-0">
                                      <div className="d-inline-flex justify-content-around">
                                        <div className="width150 mr-3 mb-3">
                                          <Select
                                            styles={primaryStyle}
                                            options={primaryQueryParam}
                                            onChange={(e) => {
                                              closeEditPrimaryHospitalMode();
                                              onDoctorUpdate(
                                                ""
                                                  .concat(item?.id, "_")
                                                  .concat(e.value),
                                                "PRIMARY_D"
                                              );
                                            }}
                                          />
                                        </div>
                                        {/* <IconButton color="green" onClick={() => { closeEditPrimaryHospitalMode(); onDoctorUpdate(email, "EMAIL"); }}
                                   style={{ color: "green", "paddingTop": 0, "paddingBottom": 0 }}>
                                   <DoneIcon />
                                 </IconButton> */}

                                        <IconButton
                                          onClick={() => {
                                            closeEditPrimaryHospitalMode();
                                          }}
                                          style={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                          }}
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                      </div>
                                    </p>
                                  ) : (
                                    <i
                                      className="fa fa-edit line-height-0 btn btn-sort p-1 ml-2"
                                      onClick={() => {
                                        handleEditPrimaryHospitalClick(
                                          item?.id
                                        );
                                      }}
                                    />
                                  )}
                                </td>
                                {/* <td>
                         {!editPrimaryHospital ?
                           <i className="fa fa-edit line-height-0 btn btn-sort p-1 ml-2" onClick={handleEditPrimaryHospitalClick} />
                           : ""
                         }
                       </td> */}
                                {/* <td>{item?.hid}</td> */}
                                <td>
                                  {item?.sarcoma_specialized ? "YES" : "NO"}
                                </td>
                                <td> {item?.address?.city}</td>
                                <td> {item?.address?.state}</td>
                                <td> {item?.address?.zipcode}</td>
                                <td>
                                  <Link
                                    to={{
                                      pathname:
                                        "/call-record-hospital/" + item?.id,
                                    }}
                                    target="_blank"
                                  >
                                    <img
                                      alt="pic"
                                      className="rounded mr-sm-4 mr-0"
                                      width={40}
                                      src={cr}
                                    />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      {/* </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="card p-4">
                    <form
                      noValidate
                      className="form-head"
                      onSubmit={(event) => event.preventDefault()}
                    >
                      <div>
                        <p className="fs-18 text-dark font-w600 mb-0 py-4">
                          Search HCOs
                          <button
                            className="btn btn-primary btn-sm ml-2"
                            style={{ marginLeft: "900px" }}
                            onClick={() => {
                              disableLinking();
                            }}
                          >
                            Close
                          </button>
                        </p>
                      </div>
                      <div className="input-group search-area d-inline-flex py-2 border">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search here"
                          onChange={(e) => {
                            setSearch(e.target.value);
                            // setSearchActive(true);
                          }}
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="input-group-text"
                            onClick={() => onSearch()}
                          >
                            <i className="flaticon-381-search-2" />
                          </button>
                        </div>
                      </div>
                    </form>
                    {filterHospitals(hospitalUniverse).length == 0 ? (
                      <div
                        className="alert alert-danger"
                        role="alert"
                        style={{
                          marginTop: 30,
                          paddingTop: 10,
                          paddingBottom: 0,
                        }}
                      >
                        No results found
                      </div>
                    ) : (
                      ""
                    )}
                    <PerfectScrollbar
                      id="DZ_W_Todo2"
                      className="widget-media dz-scroll ps ps--active-y height400 mt-4 pr-4"
                    >
                      {filterHospitals(hospitalUniverse)?.map((item) => (
                        <div className="d-flex mb-4">
                          <div>
                            <div className="font-weight-bold text-black">
                              <Link
                                to={{
                                  pathname: "/user-profile/H" + item?.id,
                                }}
                                target="_blank"
                              >
                                {item?.hospital_name}
                              </Link>
                            </div>
                            {item?.address?.city}
                            {", "}
                            {item?.address?.zipcode}
                          </div>
                          <div className="ml-auto">
                            <div className="custom-control custom-checkbox mb-0 check-lg">
                              <button
                                type="button"
                                className="btn btn-primary rounded-pill w-20 py-2"
                                onClick={() => handleLinking(item)}
                              >
                                Affiliate
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </PerfectScrollbar>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                {/* <p className='text-dark font-w600 mb-1 py-4 '> */}
                <p className="fs-18 text-dark font-w600 mb-0 py-4">
                  Affiliated Accounts:
                  <button
                    type="button"
                    className="btn btn-primary rounded-pill w-20 py-2"
                    style={{ marginLeft: "850px" }}
                    onClick={() => enableLinking()}
                  >
                    Affiliate new HCOs
                  </button>
                </p>
                <div className="col-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div className="dataTables_wrapper">
                        <Table
                          responsive
                          striped
                          hover
                          id="patientTable_basic_table"
                          className="display dataTable no-footer  w-100"
                        >
                          <thead>
                            <tr role="row">
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span> Name</span>
                                  <div className="d-flex flex-column ml-2">
                                    <i
                                      className="fa fa-sort-up line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          "hospital_name",
                                          "HOSPITALS",
                                          0
                                        )
                                      }
                                    />
                                    <i
                                      className="fa fa-sort-down line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          "hospital_name",
                                          "HOSPITALS",
                                          1
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Primary Affiliated ?</span>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Sarcoma Center</span>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>City</span>
                                  <div className="d-flex flex-column ml-2">
                                    <i
                                      className="fa fa-sort-up line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "city"],
                                          "HOSPITALS",
                                          0
                                        )
                                      }
                                    />
                                    <i
                                      className="fa fa-sort-down line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "city"],
                                          "HOSPITALS",
                                          1
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>State</span>
                                  <div className="d-flex flex-column ml-2">
                                    <i
                                      className="fa fa-sort-up line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "state"],
                                          "HOSPITALS",
                                          0
                                        )
                                      }
                                    />
                                    <i
                                      className="fa fa-sort-down line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "state"],
                                          "HOSPITALS",
                                          1
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Zipcode</span>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Record</span>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Profile</span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {hospitals?.map((item) => (
                              <tr role="row" className={item.trClassName}>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/user-profile/H" + item?.id,
                                    }}
                                    target="_blank"
                                  >
                                    {item?.hospital_name}
                                  </Link>
                                </td>
                                <td>
                                  {editPrimaryHospital &&
                                  editHospitalId === item?.id
                                    ? ""
                                    : isPrimaryHospital(item)}
                                  {editPrimaryHospital &&
                                  editHospitalId === item?.id ? (
                                    <p className="fs-18 text-dark font-w600 mb-0">
                                      <div className="d-inline-flex justify-content-around">
                                        <div className="width150 mr-3 mb-3">
                                          <Select
                                            styles={primaryStyle}
                                            options={primaryQueryParam}
                                            onChange={(e) => {
                                              closeEditPrimaryHospitalMode();
                                              onDoctorUpdate(
                                                ""
                                                  .concat(item?.id, "_")
                                                  .concat(e.value),
                                                "PRIMARY_D"
                                              );
                                            }}
                                          />
                                        </div>
                                        {/* <IconButton color="green" onClick={() => { closeEditPrimaryHospitalMode(); onDoctorUpdate(email, "EMAIL"); }}
                             style={{ color: "green", "paddingTop": 0, "paddingBottom": 0 }}>
                             <DoneIcon />
                           </IconButton> */}

                                        <IconButton
                                          onClick={() => {
                                            closeEditPrimaryHospitalMode();
                                          }}
                                          style={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                          }}
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                      </div>
                                    </p>
                                  ) : (
                                    <i
                                      className="fa fa-edit line-height-0 btn btn-sort p-1 ml-2"
                                      onClick={() => {
                                        handleEditPrimaryHospitalClick(
                                          item?.id
                                        );
                                      }}
                                    />
                                  )}
                                </td>
                                {/* <td>
                   {!editPrimaryHospital ?
                     <i className="fa fa-edit line-height-0 btn btn-sort p-1 ml-2" onClick={handleEditPrimaryHospitalClick} />
                     : ""
                   }
                 </td> */}
                                {/* <td>{item?.hid}</td> */}
                                <td>
                                  {item?.sarcoma_specialized ? "YES" : "NO"}
                                </td>
                                <td> {item?.address?.city}</td>
                                <td> {item?.address?.state}</td>
                                <td> {item?.address?.zipcode}</td>
                                <td>
                                  <Link
                                    to={{
                                      pathname:
                                        "/call-record-hospital/" + item?.id,
                                    }}
                                    target="_blank"
                                  >
                                    <img
                                      alt="pic"
                                      className="rounded mr-sm-4 mr-0"
                                      width={40}
                                      src={cr}
                                    />
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/user-profile/H" + item?.id,
                                    }}
                                    target="_blank"
                                  >
                                    <svg
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M15.75 12C15.75 12.9946 15.3549 13.9484 14.6517 14.6517C13.9484 15.3549 12.9946 15.75 12 15.75C11.0054 15.75 10.0516 15.3549 9.34835 14.6517C8.64509 13.9484 8.25 12.9946 8.25 12C8.25 11.0054 8.64509 10.0516 9.34835 9.34835C10.0516 8.64509 11.0054 8.25 12 8.25C12.9946 8.25 13.9484 8.64509 14.6517 9.34835C15.3549 10.0516 15.75 11.0054 15.75 12V12Z"
                                        fill="black"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0 12C0 12 4.5 3.75 12 3.75C19.5 3.75 24 12 24 12C24 12 19.5 20.25 12 20.25C4.5 20.25 0 12 0 12ZM12 17.25C13.3924 17.25 14.7277 16.6969 15.7123 15.7123C16.6969 14.7277 17.25 13.3924 17.25 12C17.25 10.6076 16.6969 9.27226 15.7123 8.28769C14.7277 7.30312 13.3924 6.75 12 6.75C10.6076 6.75 9.27226 7.30312 8.28769 8.28769C7.30312 9.27226 6.75 10.6076 6.75 12C6.75 13.3924 7.30312 14.7277 8.28769 15.7123C9.27226 16.6969 10.6076 17.25 12 17.25V17.25Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorUserProfile;
