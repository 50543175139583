import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import DoctorUserProfile from "../table/DoctorUserProfile";
import HospitalUserProfile from "../table/HospitalUserProfile";
import RepUserProfile from "../table/RepUserProfile";
import UserProfileTable from "../table/UserProfileTable";
import RepUserProfileTable from "../table/RepUserProfileTable";

import { useSelector } from "react-redux";
import swal from "sweetalert";
import axios from "axios";
import useKeypress from "../../../hooks/useKeypress";

const WebRepUserProfile = () => {
  const repId = useSelector((state) => state.firebase.auth.uid);
  const { nid } = useParams();
  const [profile, setProfile] = useState(null);
  const [profileType, setProfileType] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [doctorsUniverse, setDoctorsUniverse] = useState([]);
  const [hospitalUniverse, setHospitalUniverse] = useState([]);
  const [hospitaldoctors, setHospitalDoctors] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [reps, setReps] = useState([]);
  const [search, setSearch] = useState([]);
  const [interaction, setInteraction] = useState([]);
  const [products, setProducts] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [callRecord, setCallRecord] = useState([]);
  const [tableLoadOnPost, setTableLoadOnPost] = useState(false);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedInteraction, setSelectedInteraction] = useState({});
  const [loading, setLoading] = useState(false);
  const [completed1, setCompleted1] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowPerPage] = useState(100);
  const [totalCallRecord, setTotalCallRecord] = useState(0);
  const enter = useKeypress("Enter");

  const sortTable = (name, type, order) => {
    let searchedData = null;
    if (type === "DOCTORS") {
      searchedData = doctors;
    } else if (type === "HOSPITALS") {
      searchedData = hospitals;
    } else if (type === "CALL_RECORD") {
      searchedData = callRecord;
    }
    const newSData = [].concat(searchedData);
    if (newSData) {
      if (order === 1) {
        if (name instanceof Array) {
          newSData.sort((a, b) => {
            return a[name[0]][name[1]]?.toLowerCase() >
              b[name[0]][name[1]]?.toLowerCase()
              ? 1
              : -1;
          });
        } else {
          newSData.sort((a, b) => {
            if (typeof a[name] === "string") {
              return a[name]?.toLowerCase() > b[name]?.toLowerCase() ? 1 : -1;
            }
            return a[name] > b[name] ? 1 : -1;
          });
        }
      } else {
        if (name instanceof Array) {
          newSData.sort((a, b) => {
            return b[name[0]][name[1]]?.toLowerCase() >
              a[name[0]][name[1]]?.toLowerCase()
              ? 1
              : -1;
          });
        } else {
          newSData.sort((a, b) => {
            if (typeof a[name] === "string") {
              return b[name]?.toLowerCase() > a[name]?.toLowerCase() ? 1 : -1;
            }
            return b[name] > a[name] ? 1 : -1;
          });
        }
      }
    }

    if (type === "DOCTORS") {
      setDoctors(newSData);
    } else if (type === "HOSPITALS") {
      setHospitals(newSData);
    } else if (type === "CALL_RECORD") {
      setCallRecord(newSData);
    }
  };

  const tagToNameMap = {
    RN: "Registered Nurse",
    PA: "Physician Assistant",
    NP: "Nurse Practitioner",
    REBM: "Reimbursement Manager",
    PHM: "Pharmacist",
    OTH: "Others",
  };

  const updateExistingDoctor = (value, id, doctor) => {
    var message = document.createElement("div");
    message.style = "position: relative;";
    if (id === "LINKING_H") {
      message.innerHTML = "New HCP is successfully affiliated!!";
      doctor = { ...doctor, update_type: "HCP_HOSPITAL_LINKING_H" };
    } else if (id === "LINKING_D") {
      message.innerHTML = "HCP successfully affiliated to selected account !!";
      doctor = { ...doctor, update_type: "HCP_HOSPITAL_LINKING_D" };
    }
    doctor = { ...doctor, hospital_ids: [value.id] };

    axios
      .put(`/v1/rep_orchestrator/doctor`, doctor, {
        headers: { "x-rep-uid": repId },
      })
      .then((res) => {
        console.log(res);
        swal({
          content: message,
          icon: "success",
        });

        axios
          .get(
            `/v1/rep_orchestrator/hospital/search?doc_ids=${profile.id}&page_number=1&page_size=100&sort_by=hospital_name&sort_order=asc`,
            { headers: { "x-rep-uid": repId } }
          )
          .then((res) => {
            setHospitals(res.data.hospitals);
            console.log(" ===== updating hospitals after linking ");
          })
          .catch((err) => {
            console.log(err);
            setCompleted1(true);
          });
        axios
          .get(
            `/v1/rep_orchestrator/doctor/search?hospital_id=${profile.id}&page_number=1&page_size=200&sort_by=display_name&sort_order=asc`,
            { headers: { "x-rep-uid": repId } }
          )
          .then((res) => {
            setDoctors(res.data.doctors);
            setCompleted1(true);
          })
          .catch((err) => {
            console.log(err);
            setCompleted1(true);
          });
      })
      .catch((err) => {
        console.log(err);
        swal("Something went wrong!", err.message, "error");
      });
  };

  const onDoctorUpdate = (value, id, updatedDoctor) => {
    console.log("value = ", value, " id =  ", id, " updated = ", updatedDoctor);

    let newProfile = profile;

    var message = document.createElement("div");

    message.style = "position: relative;";

    if (id === "EMAIL") {
      newProfile = { ...newProfile, email: value };
      newProfile = { ...newProfile, update_type: "HCP_EMAIL_UPDATE" };
      if (value) {
        message.innerHTML = "Updated Physician Email with " + value + " !!";
      } else if (value === "") {
        message.innerHTML = "Removed Physcian Email successfully";
      }
    } else if (id === "LINKING_H" || id === "LINKING_D") {
      newProfile = updatedDoctor;
      if (id === "LINKING_H") {
        message.innerHTML = "New HCP is successfully affiliated!!";
        newProfile = {
          ...newProfile,
          update_type: "HCP_HOSPITAL_LINKING_H",
        };
      } else {
        message.innerHTML =
          "HCP successfully affiliated to selected account !!";
        newProfile = { ...newProfile, update_type: "HCP_HOSPITAL_LINKING_D" };
      }
    } else if (id === "PRIMARY_H" || id === "PRIMARY_D") {
      if (updatedDoctor) {
        newProfile = {
          ...updatedDoctor,
          primary_hospital_affiliated_id: value,
        };
        newProfile = { ...newProfile, update_type: "HCP_PRIMARY_ACCOUNT_FLAG" };
      } else {
        newProfile = { ...newProfile, primary_hospital_affiliated_id: value };
        newProfile = { ...newProfile, update_type: "HCP_PRIMARY_ACCOUNT_FLAG" };
      }
      if (value.includes("YES") && updatedDoctor) {
        message.innerHTML = "Updated  Primary Tag for HCP successfully.";
      } else if (value.includes("NO") && updatedDoctor) {
        message.innerHTML = "Removed Primary Tag for HCP successfully.";
      } else if (value.includes("YES") && !updatedDoctor) {
        message.innerHTML =
          "Updated Physcian Primary Tag to Account successfully. All other primary tags have been removed";
        newProfile["old_primary_hospital_id"] = value;
      } else {
        message.innerHTML = "Removed Primary Tag for Account successfully";
        newProfile["old_primary_hospital_id"] =
          profile.primary_hospital_affiliated_id;
      }
    } else if (id === "TAGS") {
      // if (value === "NONE") {
      //   newProfile = { ...newProfile, tags: [] };
      // } else {
      newProfile = { ...newProfile, tags: [value] };
      newProfile = { ...newProfile, update_type: "HCP_TAG_UPDATE" };
      // }
      if (!value || value === "NONE") {
        message.innerHTML = "Removed Physician tags successfully !!";
      } else {
        message.innerHTML =
          "Updated Physician tag to " +
          tagToNameMap[value] +
          " successfully !!";
      }
    } else if (id == "SARCOMA_STATUS") {
      newProfile = { ...newProfile, treats_sarcoma: value };
      newProfile = { ...newProfile, update_type: "HCP_SARCOMA_FLAG" };

      if (value) {
        message.innerHTML =
          "HCP is tagged as <strong> Does not treat Soft tissue Sarcoma </strong>";
      } else {
        message.innerHTML =
          "<strong> Does not treat Soft tissue Sarcoma </strong> tag has been removed";
      }
    } else if (id == "IS_FYARRO_ADAPTOR") {
      newProfile = { ...newProfile, is_fyarro_adaptor: value };
      newProfile = { ...newProfile, update_type: "HCP_FYARRO_FLAG" };
      if (value) {
        message.innerHTML = "Tagged as <strong> Is Fyarro adaptor </strong>";
      } else {
        message.innerHTML =
          "<strong> Is Fyarro adaptor </strong> tag has been removed";
      }
    } else if (id == "SPECIALITY_UPDATE") {
      newProfile = { ...newProfile, primary_speciality: value };
      newProfile = { ...newProfile, update_type: "HCP_SPECIALITY" };
      if (value) {
        message.innerHTML = "Updated Physician Speciality - " + value + " !!";
      } else if (value === "") {
        message.innerHTML = "Removed Physcian Speciality successfully";
      }
    }

    if (id !== "LINKING_H" && id !== "LINKING_D") {
      delete newProfile["hospital_ids"];
    }

    if (!updatedDoctor) {
      var data = newProfile;
      if (value === "NONE") {
        newProfile = { ...newProfile, tags: [] };
      }
      if (id === "LINKING_H") {
        data = { ...newProfile, hospital_ids: [value.id] };
      } else if (id === "LINKING_D") {
        data = { ...newProfile, hospital_ids: [value.id] };
      }
      console.log("Data before Put request -->", data);
      axios
        .put(`/v1/rep_orchestrator/doctor`, data, {
          headers: { "x-rep-uid": repId },
        })
        .then((res) => {
          console.log("Res after Put request -->", res);
          swal({
            content: message,
            icon: "success",
          });
          if (id !== "PRIMARY_H" && id !== "LINKING_H") {
            setProfile(newProfile);
          }
          axios
            .get(
              `/v1/rep_orchestrator/hospital/search?doc_ids=${newProfile.id}&page_number=1&page_size=100&sort_by=hospital_name&sort_order=asc`,
              { headers: { "x-rep-uid": repId } }
            )
            .then((res) => {
              setHospitals(res.data.hospitals);
            })
            .catch((err) => {
              console.log(err);
              setCompleted1(true);
            });
        })
        .catch((err) => {
          console.log(err);
          swal("Something went wrong!", err.message, "error");
        });
    } else {
      var data = newProfile;
      if (id === "LINKING_H" || id === "LINKING_D") {
        data = { ...data, hospital_ids: [value.id] };
      }
      axios
        .put(`/v1/rep_orchestrator/doctor`, data, {
          headers: { "x-rep-uid": repId },
        })
        .then((res) => {
          console.log(res);
          swal({
            content: message,
            icon: "success",
          });
          if (id !== "PRIMARY_H" && id !== "LINKING_H") {
            setProfile(newProfile);
          }
          axios
            .get(
              `/v1/rep_orchestrator/hospital/search?doc_ids=${profile.id}&page_number=1&page_size=100&sort_by=hospital_name&sort_order=asc`,
              { headers: { "x-rep-uid": repId } }
            )
            .then((res) => {
              setHospitals(res.data.hospitals);
              console.log(" ===== updating hospitals after linking ");
            })
            .catch((err) => {
              console.log(err);
              setCompleted1(true);
            });
          axios
            .get(
              `/v1/rep_orchestrator/doctor/search?hospital_id=${profile.id}&page_number=1&page_size=200&sort_by=display_name&sort_order=asc`,
              { headers: { "x-rep-uid": repId } }
            )
            .then((res) => {
              setDoctors(res.data.doctors);
              setCompleted1(true);
            })
            .catch((err) => {
              console.log(err);
              setCompleted1(true);
            });
        })
        .catch((err) => {
          console.log(err);
          swal("Something went wrong!", err.message, "error");
        });
    }
  };

  const onHospitalUpdate = (value, id, updatedHospital) => {
    var message = document.createElement("div");
    message.style = "position: relative;";

    if (id === "ACCESSIBILITY") {
      updatedHospital = { ...updatedHospital, accessibility_field: value };
      updatedHospital = {
        ...updatedHospital,
        update_type: "HCO_ACCESSIBILITY_TYPE",
      };
      if (!value) {
        message.innerHTML = "Removed HCO Accessibility field successfully !";
      } else {
        message.innerHTML =
          "Updated HCO Accessibility field to " + value + " successfully !";
      }
    }

    axios
      .put(`/v1/rep_orchestrator/hospital`, updatedHospital, {
        headers: { "x-rep-uid": repId },
      })
      .then((res) => {
        console.log(res);
        swal({
          content: message,
          icon: "success",
        });
        setProfile(updatedHospital);
      })
      .catch((err) => {
        console.log(err);
        swal("Something went wrong!", err.message, "error");
      });
  };

  useEffect(() => {
    setLoading(true);

    let id = nid;
    let currentProfile = "";
    if (id.includes("H")) {
      currentProfile = "HOSPITAL";
    } else if (id.includes("D")) {
      currentProfile = "DOCTOR";
    } else if (id.includes("R")) {
      currentProfile = "REP";
    }

    id = nid.substring(1);
    if (currentProfile === "HOSPITAL") {
      setProfileType("HOSPITAL");
      axios
        .get(`/v1/rep_orchestrator/hospital/search?hospital_ids=${id}`, {
          headers: { "x-rep-uid": repId },
        })
        .then((res) => {
          setProfile(res.data.hospitals[0]);
          setCompleted1(true);

          const current = res.data.hospitals[0];

          axios
            .get(
              `/v1/rep_orchestrator/doctor/search?hospital_id=${current.id}&page_number=1&page_size=200&sort_by=display_name&sort_order=asc`,
              { headers: { "x-rep-uid": repId } }
            )
            .then((res) => {
              setDoctors(res.data.doctors);
              setCompleted1(true);
            })
            .catch((err) => {
              console.log(err);
              setCompleted1(true);
            });
        })
        .catch((err) => {
          console.log(err);
          setCompleted1(true);
        });

      axios
        .get(`/v1/rep_orchestrator/doctor/search?page_number=1&page_size=20`, {
          headers: { "x-rep-uid": repId },
        })
        .then((res) => {
          setDoctorsUniverse(res.data.doctors);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(
          `/v1/rep_orchestrator/call_record/search?rep_id=${repId}&hospital_ids=${id}&page_number=${page}&page_size=${rowPerPage}`,
          { headers: { "x-rep-uid": repId } }
        )
        .then((res) => {
          setCallRecord(res.data.call_records);
          setTotalCallRecord(res.data.total);
        })
        .catch((err) => {
          console.log(err);
          setTableLoadOnPost(false);
        });

      axios
        .get(
          `/v1/rep_orchestrator/doctor/search?hospital_id=${id}&page_number=1&page_size=200&sort_by=display_name&sort_order=asc`,
          { headers: { "x-rep-uid": repId } }
        )
        .then((res) => {
          setDoctors(res.data.doctors);
          setCompleted1(true);
        })
        .catch((err) => {
          console.log(err);
          setCompleted1(true);
        });
    } else if (currentProfile === "DOCTOR") {
      setProfileType("DOCTOR");
      console.log(" on doctor type ", profileType);

      axios
        .get(`/v1/rep_orchestrator/doctor/search?doctor_ids=${id}`, {
          headers: { "x-rep-uid": repId },
        })
        .then((res) => {
          setProfile(res.data.doctors[0]);
          setCompleted1(true);
          axios
            .get(
              `/v1/rep_orchestrator/hospital/search?doc_ids=${id}&page_number=1&page_size=100&sort_by=hospital_name&sort_order=asc`,
              { headers: { "x-rep-uid": repId } }
            )
            .then((res) => {
              setHospitals(res.data.hospitals);
            })
            .catch((err) => {
              console.log(err);
              setCompleted1(true);
            });
        })
        .catch((err) => {
          console.log(err);
          setCompleted1(true);
        });

      axios
        .get(
          `/v1/rep_orchestrator/hospital/search?page_number=1&page_size=20`,
          { headers: { "x-rep-uid": repId } }
        )
        .then((res) => {
          setHospitalUniverse(res.data.hospitals);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(
          `/v1/rep_orchestrator/call_record/search?rep_id=${repId}&doctor_ids=${id}&page_number=${page}&page_size=${rowPerPage}`,
          { headers: { "x-rep-uid": repId } }
        )
        .then((res) => {
          setCallRecord(res.data.call_records);
          setTotalCallRecord(res.data.total);
        })
        .catch((err) => {
          console.log(err);
          setTableLoadOnPost(false);
        });

      console.log(" on doctor ", profile);
    } else if (currentProfile === "REP") {
      setProfileType("REP");

      axios
        .get(`/v1/rep_orchestrator/rep/search?id=${id}`, {
          headers: { "x-rep-uid": repId },
        })
        .then((res) => {
          setProfile(res.data.reps[0]);
          setCompleted1(true);
        })
        .catch((err) => {
          console.log(err);
          setCompleted1(true);
        });

      axios
        .get(
          `/v1/rep_orchestrator/call_record/search?rep_id=${repId}&page_number=${page}&page_size=${rowPerPage}`,
          { headers: { "x-rep-uid": repId } }
        )
        .then((res) => {
          setCallRecord(res.data.call_records);
          setTotalCallRecord(res.data.total);
          setTableLoadOnPost(false);
        })
        .catch((err) => {
          console.log(err);
          setTableLoadOnPost(false);
        });
    }
  }, [nid]);

  useEffect(() => {
    let id = nid;
    let currentProfile = "";
    if (id.includes("H")) {
      id = nid.substring(1);

      currentProfile = "HOSPITAL";
      axios
        .get(
          `/v1/rep_orchestrator/call_record/search?rep_id=${repId}&hospital_ids=${id}&page_number=${page}&page_size=${rowPerPage}`,
          { headers: { "x-rep-uid": repId } }
        )
        .then((res) => {
          setCallRecord(res.data.call_records);
          setTotalCallRecord(res.data.total);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (id.includes("D")) {
      currentProfile = "DOCTOR";
      id = nid.substring(1);

      axios
        .get(
          `/v1/rep_orchestrator/call_record/search?rep_id=${repId}&doctor_ids=${id}&page_number=${page}&page_size=${rowPerPage}`,
          { headers: { "x-rep-uid": repId } }
        )
        .then((res) => {
          setCallRecord(res.data.call_records);
          setTotalCallRecord(res.data.total);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (id.includes("R")) {
      currentProfile = "REP";

      axios
        .get(
          `/v1/rep_orchestrator/call_record/search?rep_id=${repId}&page_number=${page}&page_size=${rowPerPage}`,
          { headers: { "x-rep-uid": repId } }
        )
        .then((res) => {
          setCallRecord(res.data.call_records);
          setTotalCallRecord(res.data.total);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    console.log(" current profile = ", currentProfile);
  }, [page, nid]);

  return (
    <section className="user-profile">
      <div>
        {profileType === "DOCTOR" ? (
          <DoctorUserProfile
            data={data}
            profile={profile}
            hospitals={hospitals}
            onDoctorUpdate={onDoctorUpdate}
            updateExistingDoctor={updateExistingDoctor}
            hospitalUniverse={hospitalUniverse}
            sortTable={sortTable}
            setHospitalUniverse={setHospitalUniverse}
            repId={repId}
          />
        ) : profileType === "HOSPITAL" ? (
          <HospitalUserProfile
            profile={profile}
            doctors={doctors}
            onDoctorUpdate={onDoctorUpdate}
            updateExistingDoctor={updateExistingDoctor}
            onHospitalUpdate={onHospitalUpdate}
            sortTable={sortTable}
            doctorsUniverse={doctorsUniverse}
            repId={repId}
            setDoctorsUniverse={setDoctorsUniverse}
          />
        ) : (
          <RepUserProfile
            profile={profile}
            sortTable={sortTable}
            repId={repId}
          />
        )}
      </div>
      <div className="mt-5">
        {profileType === "REP" ? (
          <RepUserProfileTable
            data={callRecord}
            setSelectedDoctors={setSelectedDoctors}
            setSelectedMessage={setSelectedMessage}
            setSelectedInteraction={setSelectedInteraction}
            setSelectedProduct={setSelectedProduct}
            doctors={setDoctors}
            profile={profile}
            hospitals={hospitals}
            setData={setData}
            setTableLoadOnPost={setTableLoadOnPost}
            page={page}
            setPage={setPage}
            totalCallRecord={totalCallRecord}
            rowPerPage={rowPerPage}
            sortTable={sortTable}
          />
        ) : (
          <UserProfileTable
            data={callRecord}
            setSelectedDoctors={setSelectedDoctors}
            setSelectedMessage={setSelectedMessage}
            setSelectedInteraction={setSelectedInteraction}
            setSelectedProduct={setSelectedProduct}
            doctors={setDoctors}
            profile={profile}
            hospitals={hospitals}
            setData={setData}
            setTableLoadOnPost={setTableLoadOnPost}
            page={page}
            setPage={setPage}
            totalCallRecord={totalCallRecord}
            rowPerPage={rowPerPage}
            sortTable={sortTable}
            pageSource={"USER_PAGE"}
          />
        )}
      </div>
    </section>
  );
};

export default WebRepUserProfile;
