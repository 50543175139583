import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../store/actions/authActions";


import ForgotPassword from "../../pages/ForgotPassword";


const WebRepForgotPassword = () => {
    // const [email, setEmail] = useState();

    const dispatch = useDispatch();

    const submitHandler = (email) => {
        // setEmail(email);
        dispatch(forgotPassword(email));
    }

    return (
            <ForgotPassword onSubmit={submitHandler}/>   
    );
};

export default WebRepForgotPassword;
