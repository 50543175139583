import React, { Fragment, useEffect } from "react";
import WebRepRoutes from "./web-rep-routes/Routes";
import { useSelector, useDispatch } from "react-redux";
import { isLoggedIn } from "./store/actions/authActions";
import Spinner from 'react-bootstrap/Spinner';

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { withResizeDetector } from "react-resize-detector";

const App = ({ width }) => {
  const dispatch = useDispatch();

  const firebaseLoaded = useSelector((state) => state.firebase.auth.isLoaded);

  const body = document.querySelector("body");

  useEffect(() => {
    dispatch(isLoggedIn());
  }, [dispatch]);

  width >= 1300
    ? body.setAttribute("data-sidebar-style", "full")
    : body.setAttribute("data-sidebar-style", "overlay");

  if (!firebaseLoaded) {
    return<div className="text-center">
    <Spinner animation="border" role="status" style={{ borderTopColor: 'green' }}>
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
  }

  return (
    <Fragment>
      {/* <Markup /> */}
      <WebRepRoutes />
    </Fragment>
  );
};

export default withResizeDetector(App);
