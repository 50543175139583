import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

export function PrivateRoute({ Component, role, ...rest }) {
  const {isAuthenticated } = useSelector((state) => state.auth);

  const { isLoading } = useSelector((state) => state.auth);

  if (isLoading) return <>Loading ...</>;

  if (
    !isLoading &&
    !isAuthenticated
  ) {
    return <Redirect to="/login" />;
  }

  return (
    <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
  );
}
