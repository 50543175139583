import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";



// This form will ask a specific set of questions to doctor id
const AlertsDismissFormModal = ({ setLargeModal, largeModal, formType, alert, refreshAlerts }) => {

    const [formData, setFormData] = useState({});
    const [profile, setProfile] = useState({});

    const [confirmValidity, setConfirmValidity] = useState(alert.validity_of_suggestion);
    const [treatsMalignantPecoma, setTreatsMalignantPecoma] = useState(alert.malignant_pecoma_status);

    const [isValiditySet, setIsValiditySet] = useState(false);
    const [isTreatsMalignantPecomaSet, setIsTreatsMalignantPecomaSet] = useState(false);

    const repId = useSelector((state) => state.firebase.auth.uid);

    useEffect(() => {
        if (alert.validity_of_suggestion == 0 && alert.malignant_pecoma_status == 0) {
            setConfirmValidity(-1);
            setTreatsMalignantPecoma(-1);
        } else {
            setConfirmValidity(alert.validity_of_suggestion);
            setTreatsMalignantPecoma(alert.malignant_pecoma_status);
        }
    }, [alert])

    const submitHandler = (e) => {
        let isValid = true;

        e.preventDefault();
        if (!isValid) return;

        console.log("valid", confirmValidity, " pecoma ", treatsMalignantPecoma);
        if (confirmValidity == 3 || treatsMalignantPecoma == 3) {
            console.log(" dismissed set false");
            alert.dismiss_status = 'FALSE';
        } else {
            console.log(" dismissed set TURE");
            alert.dismiss_status = 'TRUE';
        }
        alert.malignant_pecoma_status = treatsMalignantPecoma;
        alert.validity_of_suggestion = confirmValidity;

        axios
            .put(`/v1/rep_orchestrator/rep/alerts`, alert,
                { headers: { 'x-rep-uid': repId } })
            .then((res) => {
                console.log(res);
                let msg = (alert.dismiss_status == 'TRUE') ? `Feedback on the alert has been captured successfully` : `Follow up required on the alert`;
                swal("Success!", msg, "success")
                    .then((val) => {
                        setLargeModal(false);
                        setIsValiditySet(false);
                        setIsTreatsMalignantPecomaSet(false);
                        refreshAlerts();
                    });
            })
            .catch((err) => {
                console.log(err);
                swal("Something went wrong!", err.message, "error")
            });

        setLargeModal(false);
    };

    return (
        <Modal
            centered
            show={largeModal}
            size="l"
            onHide={() => {
                setLargeModal(false);
                setIsValiditySet(false);
                setIsTreatsMalignantPecomaSet(false);
            }}
        >
            <Modal.Header>
                <Modal.Title>Dismiss the alert</Modal.Title>
                <Button
                    variant=""
                    className="close"
                    onClick={() => {
                        setLargeModal(false);
                        setIsValiditySet(false);
                        setIsTreatsMalignantPecomaSet(false);
                    }}
                >
                    <span>&times;</span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="card mb-0">
                    <span style={{ fontSize: '12px', fontStyle: 'italic' }}> Mandatory questions are marked with an asterisk (*)</span>
                    <form onSubmit={(e) => submitHandler(e)}>
                        <div className='row mb-0'>
                            <div className="col-xl-12 col-lg-12 mb-3" style={{ paddingLeft: 40 }}>
                                <div className="basic-form" onChange={(e) => {
                                    setConfirmValidity(e.target.value);
                                    setIsValiditySet(true);
                                }}>
                                    <p className='fs-18 text-dark font-w600 mb-3'>
                                        Is this suggestion valid ?*
                                    </p>
                                    <div className="form-group mb-0">
                                        <label className="radio-inline mr-3">
                                            <input type="radio" name="optradio" value="1" checked={confirmValidity == 1} />
                                            <label for="vld" style={{ paddingLeft: 8 }}>
                                                <p >
                                                    Yes
                                                </p>
                                            </label>
                                        </label>
                                        <br />
                                        <label className="radio-inline mr-3">
                                            <input type="radio" name="optradio" value="0" checked={confirmValidity == 0} />
                                            <label for="vld" style={{ paddingLeft: 8 }}>
                                                <p>
                                                    No
                                                </p>
                                            </label>
                                        </label>
                                        <br />
                                        <label className="radio-inline mr-3">
                                            <input type="radio" name="optradio" value="2" checked={confirmValidity == 2} />
                                            <label for="vld" style={{ paddingLeft: 8 }}>
                                                <p >
                                                    Followed up, not able to confirm
                                                </p>
                                            </label>
                                        </label>
                                        <br />
                                        <label className="radio-inline mr-3">
                                            <input type="radio" name="optradio" value="3" checked={confirmValidity == 3} />
                                            <label for="vld" style={{ paddingLeft: 8 }}>
                                                <p >
                                                    Not followed up yet
                                                </p>
                                            </label>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-12 col-lg-12 mb-3" style={{ paddingLeft: 40 }}>
                                <div className="basic-form" onChange={(e) => {
                                    setTreatsMalignantPecoma(e.target.value);
                                    setIsTreatsMalignantPecomaSet(true);
                                }}>
                                    <div className="form-group mb-0">
                                        <p className='fs-18 text-dark font-w600 mb-3'>
                                            Does HCP treat MALIGNANT PECOMA ?*
                                        </p>
                                        <label className="radio-inline mr-3">
                                            <input type="radio" name="optradio2" value="1" checked={treatsMalignantPecoma == 1} />
                                            <label for="pcm" style={{ paddingLeft: 8 }}>
                                                <p>
                                                    Yes
                                                </p>
                                            </label>
                                        </label>
                                        <br />
                                        <label className="radio-inline mr-3">
                                            <input type="radio" name="optradio2" value="0" checked={treatsMalignantPecoma == 0} />
                                            <label for="pcm" style={{ paddingLeft: 8 }}>
                                                <p>
                                                    No
                                                </p>
                                            </label>
                                        </label>
                                        <br />
                                        <label className="radio-inline mr-3">
                                            <input type="radio" name="optradio2" value="2" checked={treatsMalignantPecoma == 2} />
                                            <label for="pcm" style={{ paddingLeft: 8 }}>
                                                <p>
                                                    Followed up, not able to confirm
                                                </p>
                                            </label>
                                        </label>
                                        <br />
                                        <label className="radio-inline mr-3">
                                            <input type="radio" name="optradio2" value="3" checked={treatsMalignantPecoma == 3} />
                                            <label for="pcm" style={{ paddingLeft: 8 }}>
                                                <p>
                                                    Not followed up yet
                                                </p>
                                            </label>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-0 mb-4 d-flex align-items-center justify-content-center flex-column flex-md-row">
                            <button
                                type="submit"
                                className="btn btn-primary rounded-pill px-5"
                            disabled={
    (((alert.validity_of_suggestion || alert.validity_of_suggestion == 0) && 
      (alert.malignant_pecoma_status || alert.malignant_pecoma_status == 0)) 
                                || (isValiditySet && isTreatsMalignantPecomaSet))
                                ? false: true
                    
                                }
                            >
                               Submit
                            </button>
                            <span className="my-3 mx-3" />
                            <button type="button"
                                className="btn bg-primary-alt text-black rounded-pill px-5"
                                onClick={() => {
                                    setLargeModal(false);
                                    setIsValiditySet(false);
                                    setIsTreatsMalignantPecomaSet(false);
                                }}>
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AlertsDismissFormModal;
