const initState = {};

const userReducer = function (state = initState, action) {
  switch (action.type) {
    case "SET_USER_DATA": {
      return {
        ...state,
        ...action.data,
      };
    }
    case "REMOVE_USER_DATA": {
      return {
        ...state,
      };
    }
    case "USER_LOGGED_OUT": {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
