import React, { useState, useEffect } from "react";
import Select from "react-select";
import { primaryStyle } from "../PluginsMenu/Select2/SelectStyles";
import axios from "axios";
import swal from "sweetalert";
import HomeTableSearch from "../table/HomeTableSearch";
import HomeModal from "../webRepModals/HomeModal";
import RepViewModal from "../webRepModals/RepViewModal";
import AlertsDismissFormModal from  "../webRepModals/AlertsDismissFormModal";
import { useSelector } from "react-redux";
import useKeypress from '../../../hooks/useKeypress'
import { rowsPerPageParam, getStates, getCityForState, ALL_CITIES, sortByFieldForPhysicians, ascDesc, sortByFieldForHospitals, viewTypeToLabelMap, sarcomaCentersQueryParam, kolsQueryParam} from "../table/HomePageConstants";
import MirfFormModal from "../webRepModals/MirfFormModal";
import '../../../css/homepage-table.css';
import { CSVLink } from "react-csv";

/* load the codepage support library for extended support with older formats  */



const SORT_ORDER_ASC = { value: "asc", label: "A-Z" };

const MY_TARGETS = "MY_TARGETS";
const MY_PHYSICIANS = "MY_PHYSICIANS";
const CUSTOM_PHYSICIANS = "CUSTOM_PHYSICIANS";
const CUSTOM_VIEW_PHYSICIANS   = "CUSTOM_VIEW_PHYSICIANS";
const CUSTOM_VIEW_ACCOUNTS = "CUSTOM_VIEW_ACCOUNTS";
const CUSTOM_ACCOUNTS = "CUSTOM_ACCOUNTS";
const MY_ACCOUNTS = "MY_ACCOUNTS";
const PHYSICIANS = "PHYSICIANS";
const ACCOUNTS = "ACCOUNTS";
const MY_VIEWS = "MY_VIEWS";
const MY_ALERTS = "MY_ALERTS";
const MY_MIRFS = "MY_MIRFS";
const MIRF = "MIRF";
const ALL = 'ALL';
const DEFAULT = 'DEFAULT';
const CUSTOM = 'CUSTOM';
const VIEW = 'VIEW';
const ALERT = 'ALERT';


export const myViewsOptionsCopy = [
  { value: MY_TARGETS, label: "Select my Targets", type: PHYSICIANS, sub_type: DEFAULT},
  { value: MY_PHYSICIANS, label: "Select my Physicians", type: PHYSICIANS, sub_type: DEFAULT},
  { value: MY_ACCOUNTS, label: "Select my Accounts", type: ACCOUNTS , sub_type: DEFAULT},
  { value: PHYSICIANS, label: "Select all Physicians", type: PHYSICIANS , sub_type: DEFAULT},
  { value: ACCOUNTS, label: "Select all Accounts", type: ACCOUNTS , sub_type: DEFAULT},
  { value: MY_VIEWS, label: "Select my Views", type: VIEW , sub_type: DEFAULT},
  { value: MY_MIRFS, label: "Select my MIRF", type: MIRF , sub_type: DEFAULT},
  { value: MY_ALERTS, label: "Select my Alerts", type: ALERT , sub_type: DEFAULT},
];


var statesQueryParam =  getStates(PHYSICIANS, null);
var cityQueryParam =   getCityForState(null, null, PHYSICIANS);


const WebRepHome = () => {
  const [search, setSearch] = useState(null);
  const [viewType, setViewType] = useState(MY_TARGETS);
  const [myFetchedViews, setMyFetchedViews] = useState([]);
  const [myViewsOptions, setMyViewsOptions] = useState([]);

  // Download Utils
  const [downloadCsvData, setDownloadCsvData] = useState('');
  const [tiersQueryParam, setTiersQueryParam] = useState([{ key: 'ALL', value: "ALL", label: "All" }]);
  const [specialitiesQueryParam, setSpecialitiesQueryParam] = useState([{ key: 'ALL', value: "ALL", label: "All" }]);


  const csvLink = React.useRef(null);

  const [formType, setForm] = useState("doctors");
  const [createViewEnabled, setCreateViewEnabled] = useState(false);
  const [createEntityEnabled, setCreateEntityEnabled] = useState(false);
  const [dismissAlertEnabled, setDismissAlertEnabled] = useState(false);
  const [mirfFormEnabled, setMirfFormEnabled] = useState(false);
  const [mirfSelectedHCP, setMirfSelectedHCP] = useState(null);
  const [myMirfsSubmitted, setMyMirfsSubmitted] = useState([]);
  const [myAlerts, setMyAlerts] = useState([]);
  const [myCurrentAlert, setMyCurrentAlert] = useState(null);
  const [myCurrentMirfView, setMyCurrentMirfView] = useState(null);

  const [searching, setSearching] = useState(false);
  const [searchedData, setSearchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [tiers, setTiers] = useState(null);
  const [specialitySelected, setSpecialitySelected] = useState(null);
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [stateVal, setStateVal] = useState(ALL);
  const [cityVal, setCityVal] = useState(ALL);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [kols, setKols] = useState('');
  const [sarcomaCenters, setSarcomaCenters] = useState('');
  const [totalRecord, setTotalRecord] = useState(0)
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(null);
  const enter = useKeypress('Enter');
  const [isSerachActive, setSearchActive] = useState(true);
  const [view, setView] = useState({});
  const [viewId, setViewId] = useState(null);
  const repId = useSelector((state) => state.firebase.auth.uid);


  const clearAllStates = () => {
    fetchAndSetMyViews();
    fetchAndSetMyAlerts();
    setCity(null);
    setCityVal(ALL);
    setState(null);
    setStateVal(ALL);
    setKols(null);
    setTiers(null);
    setSpecialitySelected(null);
    setSearch(null);
    setSortBy(null);
    setSortOrder(null);
    setSarcomaCenters('');
    setViewId(null);
    setRowsPerPage(null);
  };

  useEffect(() => {
    fetchAndSetMyViews();
    fetchAndSetMyMirfs();
    fetchAndSetMyAlerts();
    fetchAndSetMyHomeDropdowns();
  }, []);


  useEffect(() => {
      if (search === null) {
        onSearch();
      }
  }, [search])


  const fetchAndSetMyHomeDropdowns = () => {
    axios.get(`/v1/rep_orchestrator/meta/home`,
    { headers: { 'x-rep-uid': repId } })
    .then(res => {
      console.log('data = ', res.data);
      setTiersQueryParam(res.data.tiers);

      const objectList = res.data.specialities.map(str => ({
        key: str,
        value: str,
        label: str
      }));

      objectList.unshift({key: 'ALL', value: 'ALL', label: 'ALL'});

      setSpecialitiesQueryParam(objectList);

    })
  }

  const fetchAndSetMyAlerts = () => {
    axios.get(`/v1/rep_orchestrator/rep/alerts`,
      { headers: { 'x-rep-uid': repId } })
      .then(res => {
        setMyAlerts(res.data);
      })
      .catch(err => console.log(err));
  };

  const fetchAndSetMyViewsAndSetMyTargetsAsDefault = () => {
    fetchAndSetMyViews();
    setViewType(MY_VIEWS);
  }

  const getTierQueryParams = () => {
    if (viewType == MY_PHYSICIANS || viewType == MY_TARGETS) {
      return tiersQueryParam.MY_PHYSICIANS;
    } else if (viewType == PHYSICIANS) {
      return tiersQueryParam.PHYSICIANS;
    }
    return [];
  };

  useEffect(() => {
    if (downloadCsvData !== '') {
      // csvLink.current.click();
      csvLink?.current?.link?.click();
      setDownloadCsvData('');
    }
  }, [downloadCsvData]);

  const handleDownloadClick = () => {
    const csvData = [];

    if (isTableType(viewType, 'HCP')) {
      searchedData.forEach((item) => {
        const row = {
          "Npi": item?.npid,
          "Name": item.display_name,
          "Tier": item?.tier,
          "KOL": item?.sarcoma_KOLs,
          "Specialty": item?.primary_speciality,
          "Street": item?.address?.street,
          "City": item?.address?.city,
          "State": item?.address?.state,
          "Zip": item?.address?.zipcode,
          "Email": item.email,
        };
        csvData.push(row);
      });
    } else if (isTableType(viewType, 'HCO')) {
      searchedData.forEach((item) => {
        const row = {
          "Legacy Id": item?.hid,
          "Name": item?.hospital_name,
          "street": item?.address?.street,
          "City": item?.address?.city,
          "State": item?.address?.state,
          "Zip": item?.address?.zipcode,
          "Sarcoma Center": item?.sarcoma_specialized,
        };
        csvData.push(row);
      });
    }


    // Execut below statements after above iteration is completed
    setDownloadCsvData(csvData);

    // handleDownloadClick2(null, csvData);

  };

  const fetchAndSetMyMirfs = () => {
    axios
    .get(`/v1/rep_orchestrator/rep/mirf`,
      { headers: { 'x-rep-uid': repId } })
    .then((res) => {
        setMyMirfsSubmitted(res.data);
    })
    .catch((err) => console.log(err));
  }

  const fetchAndSetMyViews = () => {
    axios
      .get(`/v1/rep_orchestrator/view/search`,
        { headers: { 'x-rep-uid': repId } })
      .then((res) => {
        setMyViewsOptions([]);
        setMyFetchedViews(res.data.views);
        let myViewsCopyC =  Object.create(myViewsOptionsCopy);
        for (var i = 0; i < res.data.views.length; i++) {
          myViewsCopyC.push({ value: res.data.views[i].id, type: 'CUSTOM_VIEW_'.concat(res.data.views[i].view_type), label: res.data.views[i].view_name });
        }
        setMyViewsOptions(myViewsCopyC);
      })
      .catch((err) => console.log(err));
  }


  const handlePageCurrentViewType = (e) => {
    clearAllStates();
    setPage(1);
    setSearching(true);
    if (e.type === VIEW) {
      setViewType(e.value);

    } else if (e.sub_type === DEFAULT) {
      setViewType(e.value);
      statesQueryParam = getStates(e.value, repId);
      cityQueryParam = getCityForState(null, repId, e.value);
      // onSearch();
    } else {

      setViewId(e.value);
      setViewType(e.type);
      // setSearchedData(myFetchedViews);
      if (e.sub_type === CUSTOM && e.type === PHYSICIANS) {
        statesQueryParam = getStates(e.value, repId);
        cityQueryParam = getCityForState(null, repId, e.value);
      } else if (e.sub_type === CUSTOM && e.type === ACCOUNTS) {
        statesQueryParam = getStates(e.value, repId);
        cityQueryParam = getCityForState(null, repId, e.value);
      }
    }
  }

  useEffect(() => {
    if (searching) {
      onSearch();
    }
  }, [viewType,page,tiers,sarcomaCenters,kols,rowsPerPage,state,city,sortBy,sortOrder, specialitySelected]);

  useEffect(() => {
    if (viewId) {
      onSearch();
    }
    }, [viewId]);

  useEffect(() => {
    setSearchedData(searchedData);
  }, [searchedData]);

  useEffect(() => {
    if (isSerachActive || enter) {
      setSearchActive(false);
      onSearch();
    }
  }, [enter])

  const onSearch = () => {

    setSearchActive(false);

    if (viewType !== null) {

      if (!viewType.includes("CUSTOM_VIEW_")) {
        setViewId(null);
      }

      let tierPassed = tiers ? ((tiers.value === "ALL" || tiers.value === PHYSICIANS) ? false : true) : false;
      let specialityPassed = (!specialitySelected  || specialitySelected.value === "ALL") ? false: true;
      let kolsRequested = kols;
      let query = `&page_size=${rowsPerPage ? rowsPerPage.value : 100}${tierPassed ? `&tiers=${tiers.value}` : ''}${specialityPassed ? `&primary_speciality=${encodeURIComponent(specialitySelected.value)}` : ''}${viewId ? `&view_id=${viewId}` : ''}${kolsRequested && kols.value != 'false' ? `&sarcoma_kols=${kols.value}` : ''}${stateVal && stateVal != ALL ? `&state=${stateVal}` : ''}${sortBy ? `&sort_by=${sortBy.value}&sort_order=${sortOrder.value}` : ''}${cityVal && cityVal != ALL ? `&city=${cityVal}` : ''}&page_number=${page}`;

      let keyword = search ? search.value : '';

      if (viewType === MY_ACCOUNTS) {
        setSearching(true);
        setLoading(true);
        axios
          .get(`/v1/rep_orchestrator/rep/target?key_word=${keyword}${query}&rep_id=${repId}&fetch_type=${viewType}&sarcoma_center=${sarcomaCenters}`,
            { headers: { 'x-rep-uid': repId } })
          .then((res) => {
            if (res.data.mappings[0].hospitals) {
              setSearchedData(res.data.mappings[0].hospitals);
            } else {
              setSearchedData([]);
            }
            setTotalRecord(res.data.total_count)
            setLoading(false);
          })
          .catch((err) => console.log(err));

      } else if (viewType === MY_VIEWS) {
        // setMyFetchedViews(mockView);
      } else if (viewType === MY_PHYSICIANS || viewType === MY_TARGETS) {
        setSearching(true);
        setLoading(true);
        setSearchedData([]);
        let fetch_type = viewType;

        statesQueryParam = getStates(viewType, repId);

        axios
          .get(`/v1/rep_orchestrator/rep/target?key_word=${keyword}${query}&rep_id=${repId}&fetch_type=${fetch_type}`,
            { headers: { 'x-rep-uid': repId } })
          .then((res) => {
            if (res.data.mappings[0].doctors) {
              setSearchedData(res.data.mappings[0].doctors);
            } else {
              setSearchedData([]);
            }
            setTotalRecord(res.data.total_count)
            setLoading(false);
          })
          .catch((err) => console.log(err));
      } else if (viewType === PHYSICIANS || viewType === 'CUSTOM_VIEW_PHYSICIANS') {
        setSearching(true);
        setLoading(true);
        axios
          .get(`v1/rep_orchestrator/doctor/search?keyword=${keyword}${query}`,
            { headers: { 'x-rep-uid': repId } })
          .then((res) => {
            setSearchedData(res.data.doctors);
            setTotalRecord(res.data.total)
            setLoading(false);
          })
          .catch((err) => console.log(err));

      } else {
        console.log(" type is " , viewType);
        setSearching(true);
        setLoading(true);
        axios
          .get(`v1/rep_orchestrator/hospital/search?keyword=${keyword}${query}&sarcoma_center=${sarcomaCenters}`,
            { headers: { 'x-rep-uid': repId } })
          .then((res) => {
            setSearchedData(res.data.hospitals);
            setTotalRecord(res.data.total)
            setLoading(false);
          })
          .catch((err) => console.log(err));
      }
    } else {
      swal("Something went wrong!", "Please Fill both fields", "error");
    }
  };

  const fetchLabelForMainViewSelect = () => {

    if (viewId) {
      if (!myViewsOptions || myViewsOptions.length == 0) {
        return '';
      }
      return myViewsOptions.filter(x => x.value === viewId)[0].label;
    } else {
      return viewTypeToLabelMap[viewType];
    }
  }

  const isTableType = (viewType, requestedType) => {
    const isHcpTable = (viewType === PHYSICIANS || viewType === MY_TARGETS || viewType === MY_PHYSICIANS || viewType === CUSTOM_VIEW_PHYSICIANS);
    const isHcoTable = (viewType === ACCOUNTS || viewType === MY_ACCOUNTS || viewType === CUSTOM_VIEW_ACCOUNTS);

    if (requestedType === 'HCP' && isHcpTable) {
      return true;
    }
    if (requestedType === 'HCO' && isHcoTable) {
      return true;
    }
    if (requestedType === 'HCP_HCO' && (isHcpTable || isHcoTable)) {
      return true;
    }
    return false;
  }

  const handleModal = (type, values) => {
    if (type === VIEW) {
      setCreateViewEnabled(true);
      setCreateEntityEnabled(false);
      setMirfFormEnabled(false);
      setDismissAlertEnabled(false);
      setView({});
      setShow(true);
    } else if (type === 'MIRF_FORM_VIEW') {
      setCreateViewEnabled(false);
      setCreateEntityEnabled(false);
      setMirfFormEnabled(true);
      setDismissAlertEnabled(false);
      setMirfSelectedHCP(null);
      setMyCurrentMirfView(values);
      setView({});
      setShow(true);
    }
    
    else if (type === 'MIRF_FORM') {
      setCreateViewEnabled(false);
      setCreateEntityEnabled(false);
      setMirfFormEnabled(true);
      setDismissAlertEnabled(false);
      setMyCurrentMirfView(null);
      setMirfSelectedHCP(values);
  } else if (type === 'DISMISS_ALERT') {
      setCreateViewEnabled(false);
      setCreateEntityEnabled(false);
      setMirfFormEnabled(false);
      setMyCurrentMirfView(null);
      setDismissAlertEnabled(true);
      setMyCurrentAlert(values);
      setView({});
    } else {
      setCreateViewEnabled(false);
      setCreateEntityEnabled(true);
      setMirfFormEnabled(false);
      setDismissAlertEnabled(false);
    }
    setForm(type);
    setShow(true);
  }

  return (
    <section>
      {
        createEntityEnabled ?
        <HomeModal
        formType={formType}
        setLargeModal={setShow}
        largeModal={show}
      />: ''
      }
      {
        createViewEnabled ? 
        <RepViewModal formType="VIEW" setLargeModal={setShow} largeModal={show} view={view}  fetchAndSetMyViews={fetchAndSetMyViews} />  :  ''
      }
      {
        dismissAlertEnabled ? 
        <AlertsDismissFormModal formType="VIEW" setLargeModal={setShow} largeModal={show} alert={myCurrentAlert} refreshAlerts={fetchAndSetMyAlerts} />  :  ''
      }
      {
        mirfFormEnabled ?
        <MirfFormModal formType="VIEW" setLargeModal={setShow} largeModal={show} hcp={mirfSelectedHCP} currentMirf={myCurrentMirfView} fetchAndSetMyMirfs={fetchAndSetMyMirfs} />  :  ''
      }
      <div className="form-head d-flex flex-wrap  align-items-center  justify-content-center">
        <div className="width190 pr-4 mb-3">
          <Select
            styles={primaryStyle}
            placeholder="My Targets"
            options={myViewsOptions}
            value = {{'label': fetchLabelForMainViewSelect(), value: viewType}}
            onChange={(e) => {
              handlePageCurrentViewType(e);
            }}
          />
        </div>
        <div className="col-12 col-md-4 col-xl-3  mb-3">
          <div
            className="input-group search-area d-inline-flex w-80 px-0 py-1"
            style={{ maxWidth: "initial" }}
          >
            <input
              type="text"
              className="form-control text-center"
              placeholder={(viewType === MY_ACCOUNTS|| viewType === ACCOUNTS) ? "Search HCOs" : "Search HCPs"}
              value={search ? search.value : ''}
              onChange={(e) => {
                setSearch({"label": e.target.value, "value": e.target.value});
                setSearchActive(true);
              }}
            />
            <div className="input-group-append">
              <button
                type="button"
                className="input-group-text"
                disabled={!search || search.value.length === 0}
                onClick={onSearch}
              >
                <i className="flaticon-381-search-2" />
              </button>
            </div>
          </div>
        </div>
        {
         (!isTableType(viewType, 'HCO')) 
          ?
          <div className="width150 mr-3 mb-3">
            <Select
              styles={primaryStyle}
              placeholder="Tier"
              options={getTierQueryParams()}
              isDisabled={!(isTableType(viewType, 'HCP'))}
              value={tiers}
              onChange={(e) => {
                setTiers(e);
                setPage(1);
              }}
            />
          </div> :
          ''
        }
        {
          (!isTableType(viewType, 'HCO')) 
            ?
            <div className="width100 mr-3 mb-3">
              <Select
                styles={primaryStyle}
                placeholder="KOLS"
                options={kolsQueryParam}
                isDisabled={!(isTableType(viewType, 'HCP'))}
                value={kols}
                onChange={(e) => {
                  setKols(e);
                  setPage(1);
                }}
              />
            </div>
            : ''}
          <div className="width130 mr-3 mb-3">
          <Select
            styles={primaryStyle}
            placeholder="State"
            options={statesQueryParam}
            isDisabled={!(isTableType(viewType, 'HCP_HCO'))}
            value = {state}
            onChange={(e) => {
              if (e.value === ALL) {
                cityQueryParam = getCityForState(null, repId, viewType);
              } else {
                cityQueryParam = getCityForState(e.value, repId, viewType);
              }
              setState(e);
              setStateVal(e.value);
              setCity(ALL_CITIES);
              setCityVal(ALL);
              setPage(1);
            }}
          />
        </div>
        <div className="width130 mr-3 mb-3">
          <Select
            styles={primaryStyle}
            placeholder="City"
            options={cityQueryParam}
            isDisabled={!(isTableType(viewType, 'HCP_HCO'))}
            value={city}
            onChange={(e) => {

              if (e.label === ALL) {
                if (!state || state.value === ALL) {
                  cityQueryParam = getCityForState(null, repId, viewType);
                } else {
                  cityQueryParam = getCityForState(state.value, repId, viewType);
                }
              }
              setCity(e);
              setCityVal(e.value);
              setPage(1);
            }}
          />
        </div>

        <div className="d-flex flex-wrap align-items-center  justify-content-center">
          {isTableType(viewType, 'HCO') 
            ?
            <div className="width200 mr-3 mb-3">
              <Select
                styles={primaryStyle}
                placeholder="Sarcoma Centers"
                options={sarcomaCentersQueryParam}
                onChange={(e) => setSarcomaCenters(e.value)}
                value={sarcomaCenters ? {'label': sarcomaCenters === 'YES'? 'Sarcoma Centers' : 'All HCOs', value: sarcomaCenters} : null}
              />
            </div> :
            ''}
        </div>
        {
              (!isTableType(viewType, 'HCO'))
                ?
                <div className="width130 mr-3 mb-3">
                  <Select
                    styles={primaryStyle}
                    placeholder="Speciality"
                    options={specialitiesQueryParam}
                    isDisabled={!(isTableType(viewType, 'HCP'))}
                    value={specialitySelected}
                    onChange={(e) => {
                      setSpecialitySelected(e);
                      setPage(1);
                    }}
                  />
                </div> :
                ''
          }
      </div>
      <div>
        <div>
          <div className="d-flex px-4 mb-4 align-items-center justify-content-center">
          
            <Select
              className="col-2 col-md-2"
              styles={primaryStyle}
              placeholder="Sort by field"
              isDisabled={!(isTableType(viewType, 'HCP_HCO'))}
              value={sortBy}
              options={isTableType(viewType, 'HCP') ? sortByFieldForPhysicians : sortByFieldForHospitals}
              onChange={(e) => {
                setSortBy(e);
                setSortOrder(SORT_ORDER_ASC);
              }}
            />
            <Select
              className="col-1 col-md-1"
              styles={primaryStyle}
              placeholder="A-Z"
              options={ascDesc}
              isDisabled={!sortBy || sortBy.value === ''}
              value={sortOrder}
              onChange={(e) => {
                setSortOrder(e);
              }}
            />
            <Select
              className="col-1 col-md-2"
              styles={primaryStyle}
              placeholder="Rows Per Page"
              options={rowsPerPageParam}
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(e);
                setSearching(true);
              }}
            />
            <div className="width130 mb-3 mr-3">
              <button
                type="button"
                className="btn btn-primary rounded-pill w-100 py-2"
                onClick={() => handleModal('HOSPITAL')}>
                Add HCO
              </button>
            </div>

            <div className="width173 mb-3 mr-3">
              <button
                type="button"
                className="btn btn-primary rounded-pill w-100 py-2"
                onClick={() => handleModal('DOCTOR')}
              >
                Add HCP
              </button>
            </div>
            <div className="width163 mb-3 mr-3">
              <button
                type="button"
                className="btn btn-primary rounded-pill w-200 py-2"
                onClick={() => handleModal(VIEW)}
              >
                Create View
              </button>
            </div>
            {
              isTableType(viewType, 'HCP_HCO') ?

                <div className="width163 mb-3 mr-3">
                  <a
                    download
                    href
                    className="btn btn-primary rounded-pill w-200 py-2"
                    onClick={handleDownloadClick}
                  >
                    Download
                  </a>
                  <CSVLink
                    data={downloadCsvData} 
                    filename={isTableType(viewType,'HCP') ? `searched_data_hcp.csv`: `searched_data_hco.csv`}
                    className='hidden'
                    ref={csvLink}
                    target='_blank'
                  />
                </div>
            : ''
            }
            <div className="width150 mb-3 ">
              <button
                type="button"
                className="btn btn-primary rounded-pill w-100 py-2"
                onClick={() => {
                  clearAllStates();
                }}
              >
                Clear Filters
              </button>
            </div>
          </div>

        </div>
        <div>
          <HomeTableSearch
            type={viewType}
            data={searchedData}
            loading={loading}
            totalRecord={totalRecord}
            rowPerSize={rowsPerPage ? rowsPerPage.value : 100}
            setPage={setPage}
            page={page}
            setSearchedData={setSearchedData}
            setCreateViewEnabled={setCreateViewEnabled}
            setView={setView}
            setShow={setShow}
            myFetchedViews={myFetchedViews}
            handlePageCurrentViewType={handlePageCurrentViewType}
            handleModal={handleModal}
            repId={repId}
            fetchAndSetMyViewsAndSetMyTargetsAsDefault={fetchAndSetMyViewsAndSetMyTargetsAsDefault}
            myMirfsSubmitted={myMirfsSubmitted}
            myAlerts={myAlerts}
            clearAllStates = {clearAllStates}
          />
        </div>
      </div>
    </section>
  );
};

export default WebRepHome;
