export const physicianTags = [
    { label: "MD", value: "MD", name: 'tag' },
    { label: "DO", value: "DO", name: "tag" },
    { label: "Pharm D", value: "PHARM_D", name: "tag" },
    { label: "R Ph", value: "R_PH", name: "tag" },
    { label: "Practice Manager", value: "PM", name: "tag" },
    { label: "Nurse Practioner", value: "NP", name: "tag" },
    { label: "Registered Nurse", value: "RN", name: "tag" },
    { label: "Physician Assistant", value: "PA", name: "tag" },
    { label: "Reimbursement Manager", value: "REBM", name: "tag" },
    { label: "Pharmacists", value: "PHM", name: "tag" },
    { label: "Others", value: "OTH", name: "tag" },
    { label: "None", value: "NONE", name: "tag" }
];

export const viewTargetTypes = [
    { label: "Physicians", value: "HCP", name: 'view_type' },
    { label: "Accounts", value: "HCO", name: "view_type" },
];


export const defaultHCPViewTypes = [
    { label: "Select your Super Targets", value: "super_targets", name: 'default_views_list_HCP' },
    { label: "Select your High Decile Targets", value: "high_decile", name: 'default_views_list_HCP' },
    { label: "Select your KOLs", value: "kols", name: "default_views_list_HCP" },
    // { label: "Select your Fyarro Adopters", value: "fyarro_adopters", name: "default_views_list_HCP" },
];

export const defaultHCOViewTypes = [
    { label: "Select your Sarcoma Centers", value: "sarcoma_centers", name: 'default_views_list_HCO' },
    // { label: "Select your Fyarro Ordered Accounts", value: "fyarro_adopters_accounts", name: "default_views_list_HCO" },
];

export const modalOptionsMap = {

    "view_type": {
        "options": viewTargetTypes,
        "place_holder": "View Target Type",
    },
    "default_views_list_HCP": {
        "options": defaultHCPViewTypes,
        "place_holder": "Default HCP Views",
    },
    "default_views_list_": {
        "options": defaultHCPViewTypes,
        "place_holder": "Default HCP Views",
    },
    "default_views_list_undefined": {
        "options": defaultHCPViewTypes,
        "place_holder": "Default HCP Views",
    },
    "TAGS": {
        "options": physicianTags,
        "place_holder": "Customer Tags",
    },
    "default_views_list_HCO": {
        "options": defaultHCOViewTypes,
        "place_holder": "Default HCO Views",
    }
};

export const enumNames = [
    "TAGS", "default_views_list_HCP", "default_views_list", "default_views_list_HCO", 'view_type'
]

export const sortTable = (name, type, order, searchedData) => {

    const newSData = [].concat(searchedData);
    if (newSData) {
        if (order === 1) {
            if (name instanceof Array) {
                newSData.sort((a, b) => {
                    return a[name[0]][name[1]]?.toLowerCase() > b[name[0]][name[1]]?.toLowerCase() ? 1 : -1;
                });
            } else {
                newSData.sort((a, b) => {
                    if (name === "tier") {
                        let extraTier = "TIER_" + (Object.keys(tierMappings).length + 1);
                        let aName = a[name] ?? extraTier, bName = b[name] ?? extraTier;
                        return aName?.toLowerCase() > bName?.toLowerCase() ? 1 : -1;
                    }
                    if (typeof a[name] === 'string') {
                        return a[name]?.toLowerCase() > b[name]?.toLowerCase() ? 1 : -1;
                    }
                    return a[name] > b[name] ? 1 : -1;
                });
            }
        } else {
            if (name instanceof Array) {
                newSData.sort((a, b) => {
                    return b[name[0]][name[1]]?.toLowerCase() > a[name[0]][name[1]]?.toLowerCase() ? 1 : -1;
                });
            } else {
                newSData.sort((a, b) => {
                    if (name === "tier") {
                        let extraTier = "TIER_" + (Object.keys(tierMappings).length + 1);
                        let aName = a[name] ?? extraTier, bName = b[name] ?? extraTier;
                        return aName?.toLowerCase() < bName?.toLowerCase() ? 1 : -1;
                    }

                    if (typeof a[name] === 'string') {
                        return b[name]?.toLowerCase() > a[name]?.toLowerCase() ? 1 : -1;
                    }
                    return b[name] > a[name] ? 1 : -1;
                });
            }
        }

        return newSData;
    }
};

export const requiredDataFormTypeMap = {
    "DOCTOR": { first_name: true, last_name: true, zipcode: true, npid: true },
    "HOSPITAL": { hospital_name: true, zipcode: true },
    "VIEW": { viewName: true, viewType: true },
    "MIRF": {
        "visit_type": true,
        "name": true,
        "product": true,
        "state": true,
        "city": true,
        "zipcode": true,
        "street_address": true,
        "requested_date": true,
        "degree": true,
        "email": true
    }
}


export const tiersQueryParam = [
    { key: 'ALL', value: "ALL", label: "All" },
    // {key: 'TIER_1', value: "TIER_1", label: "Super Targets" },
    { key: 'TIER_2',value: "High", label: "High Decile" },
    { key: 'TIER_3',value: "Medium", label: "Medium Decile" },
    { key: 'TIER_4',value: "Low", label: "Low Decile" }
];

export const sarcomaCentersQueryParam = [
    { value: "YES", label: "Sarcoma Center" },
    { value: "NO", label: "All HCOs" },
];

export const tierMappings = {
    // "Super Targets": "Super Targets",
    "High Decile": "High Decile",
    "Medium Decile": "Medium Decile",
    "Low Decile": "Low Decile",
}


export const inputs = (type, hospital_id) => {
    if (type === 'DOCTOR') {
        return [
            { name: "first_name", label: "First Name *", type: "text", required: true },
            { name: "last_name", label: "Last Name *", type: "text" },
            { name: "display_name", label: "Display Name ", type: "text" },
            { name: "email", label: "Email", type: "text" },
            { name: "npid", label: "NPI **", type: "text", required: true },
            {
                name: "primary_speciality",
                label: "Primary Speciality",
                type: "text",
            },
            { name: "line1", label: "Address Line 1", type: "text" },
            { name: "street", label: "Address Line 2", type: "text" },
            { name: "city", label: "City", type: "text" },
            { name: "state", label: "State", type: "text" },
            { name: "zipcode", label: "Zipcode *", type: "text" },
            {
                name: "primary_hospital_affiliated_id",
                label: "Primary Hospital Affiliated Id",
                type: "text",
                default: hospital_id
            },
            {
                name: "hospital_name",
                label: "Hospital Name",
                type: "text",
            },
            // {
            //     name: "TAGS",
            //     label: "Physician Tags",
            //     type: "text",

            // },
            { name: "office_phone", label: "Office Phone  ", type: "text" },
        ];
    } else if (type === 'VIEW') {
        return [
            { name: "view_name", label: "Name of the View*", type: "text" },
            { name: "view_type", label: "View Type*", type: "text", enum: true },
            { name: "view_description", label: "Description of the View", type: "text" },
            { name: "default_views_list", label: "Default Views", type: "text", enum: true },
        ];
    }
    else {
        return [
            { name: "hospital_name", label: "Hospital Name *", type: "text" },
            { name: "line1", label: "Address Line 1", type: "text" },
            { name: "street", label: "Address Line 2", type: "text" },
            { name: "city", label: "City", type: "text" },
            { name: "state", label: "State", type: "text" },
            { name: "country", label: "Country", type: "text" },
            { name: "zipcode", label: "Zipcode *", type: "text" },
        ];
    }
};
