import React, { Component } from 'react';

/// Link
import { Link } from 'react-router-dom';

/// Scroll
import PerfectScrollbar from 'react-perfect-scrollbar';

/// Menu
import MetisMenu from 'metismenujs';

///icons
import { ReactComponent as Icon0 } from '../../../icons/sidebar/s-icon-0.svg';
import { ReactComponent as Icon1 } from '../../../icons/sidebar/s-icon-1.svg';
import { ReactComponent as Icon2 } from '../../../icons/sidebar/s-icon-2.svg';
import { ReactComponent as Icon3 } from '../../../icons/sidebar/s-icon-3.svg';
import { ReactComponent as Icon4 } from '../../../icons/sidebar/s-icon-4.svg';
// import drump from "../../../images/card/drump.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    // this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className='mm-wrapper'>
        <ul className='metismenu' ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector('.nav-control');
    var aaa = document.querySelector('#main-wrapper');

    function toggleFunc() {
      return aaa.classList.toggle('menu-toggle');
    }

    btn.addEventListener('click', toggleFunc);
  }
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split('/');
    path = path[path.length - 1];

    /// Active menu
    let deshBoard = ['dashboard'],
      app = ['user-profile', 'call-record'],
      charts = ['home'],
      bootstrap = [
        'ui-accordion',
        'ui-badge',
        'ui-alert',
        'ui-button',
        'ui-modal',
        'ui-button-group',
        'ui-list-group',
        'ui-media-object',
        'ui-card',
        'ui-carousel',
        'ui-dropdown',
        'ui-popover',
        'ui-progressbar',
        'ui-tab',
        'ui-typography',
        'ui-pagination',
        'ui-grid',
      ],
      plugins = [
        'uc-select2',
        'uc-nestable',
        'uc-sweetalert',
        'uc-toastr',
        'uc-noui-slider',
        'map-jqvmap',
        'uc-lightgallery',
      ],
      widget = ['widget-basic'],
      forms = [
        'form-element',
        'form-wizard',
        'form-editor-summernote',
        'form-pickers',
        'form-validation-jquery',
      ],
      table = ['table-bootstrap-basic', 'table-datatable-basic'],
      pages = [
        'page-registser',
        'page-login',
        'page-lock-screen',
        'page-error-400',
        'page-error-403',
        'page-error-404',
        'page-error-500',
        'page-error-503',
      ],
      error = [
        'page-error-400',
        'page-error-403',
        'page-error-404',
        'page-error-500',
        'page-error-503',
      ];
    return (
      <div className='deznav'>
        <PerfectScrollbar className='deznav-scroll'>
          <MM className='metismenu' id='menu'>
            <li className={`${deshBoard.includes(path) ? 'mm-active' : ''}`}>
              <Link to='dashboard' className='ai-icon' aria-expanded='false'>
                <Icon0 />
                <span className='nav-text'>Dashboard</span>
              </Link>
            </li>
            <li className={`${app.includes(path) ? 'mm-active' : ''}`}>
              <Link className='has-arrow ai-icon' to='#' aria-expanded='false'>
                <Icon1 />
                <span className='nav-text'>Doctor</span>
              </Link>
              <ul aria-expanded='false'>
                <li>
                  <Link
                    className={`${path === 'post-details' ? 'mm-active' : ''}`}
                    to='user-profile'
                  >
                    Profile
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'post-details' ? 'mm-active' : ''}`}
                    to='call-record'
                  >
                    Call Record
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${charts.includes(path) ? 'mm-active' : ''}`}>
              <Link className='has-arrow ai-icon' to='#' aria-expanded='false'>
                <Icon2 />
                <span className='nav-text'>Department</span>
              </Link>
              <ul aria-expanded='false'>
                <li>
                  <Link
                    className={`${path === 'chart-chartjs' ? 'mm-active' : ''}`}
                    to='home'
                  >
                    Add New
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${bootstrap.includes(path) ? 'mm-active' : ''}`}>
              <Link className='has-arrow ai-icon' to='#' aria-expanded='false'>
                <Icon3 />
                <span className='nav-text'>Representatives</span>
              </Link>
              <ul aria-expanded='false'>
                <li>
                  <Link
                    className={`${path === 'ui-accordion' ? 'mm-active' : ''}`}
                    to='/ui-accordion'
                  >
                    Accordion
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'ui-alert' ? 'mm-active' : ''}`}
                    to='/ui-alert'
                  >
                    Alert
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'ui-badge' ? 'mm-active' : ''}`}
                    to='/ui-badge'
                  >
                    Badge
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'ui-button' ? 'mm-active' : ''}`}
                    to='/ui-button'
                  >
                    Button
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'ui-modal' ? 'mm-active' : ''}`}
                    to='/ui-modal'
                  >
                    Modal
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === 'ui-button-group' ? 'mm-active' : ''
                    }`}
                    to='/ui-button-group'
                  >
                    Button Group
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'ui-list-group' ? 'mm-active' : ''}`}
                    to='/ui-list-group'
                  >
                    List Group
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === 'ui-media-object' ? 'mm-active' : ''
                    }`}
                    to='/ui-media-object'
                  >
                    Media Object
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'ui-card' ? 'mm-active' : ''}`}
                    to='/ui-card'
                  >
                    Cards
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'ui-carousel' ? 'mm-active' : ''}`}
                    to='/ui-carousel'
                  >
                    Carousel
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'ui-dropdown' ? 'mm-active' : ''}`}
                    to='/ui-dropdown'
                  >
                    Dropdown
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'ui-popover' ? 'mm-active' : ''}`}
                    to='/ui-popover'
                  >
                    Popover
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === 'ui-progressbar' ? 'mm-active' : ''
                    }`}
                    to='/ui-progressbar'
                  >
                    Progressbar
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'ui-tab' ? 'mm-active' : ''}`}
                    to='/ui-tab'
                  >
                    Tab
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'ui-typography' ? 'mm-active' : ''}`}
                    to='/ui-typography'
                  >
                    Typography
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'ui-pagination' ? 'mm-active' : ''}`}
                    to='/ui-pagination'
                  >
                    Pagination
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'ui-grid' ? 'mm-active' : ''}`}
                    to='/ui-grid'
                  >
                    Grid
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${plugins.includes(path) ? 'mm-active' : ''}`}>
              <Link className='has-arrow ai-icon' to='#' aria-expanded='false'>
                <Icon4 />
                <span className='nav-text'>Settings</span>
              </Link>
              <ul aria-expanded='false'>
                <li>
                  <Link
                    className={`${path === 'uc-select2' ? 'mm-active' : ''}`}
                    to='/uc-select2'
                  >
                    Select 2
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'uc-nestable' ? 'mm-active' : ''}`}
                    to='/uc-nestable'
                  >
                    Nestedable
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === 'uc-noui-slider' ? 'mm-active' : ''
                    }`}
                    to='/uc-noui-slider'
                  >
                    Noui Slider
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'uc-sweetalert' ? 'mm-active' : ''}`}
                    to='/uc-sweetalert'
                  >
                    Sweet Alert
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === 'map-jqvmap' ? 'mm-active' : ''}`}
                    to='/map-jqvmap'
                  >
                    Jqv Map
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === 'uc-lightgallery' ? 'mm-active' : ''
                    }`}
                    to='/uc-lightgallery'
                  >
                    Light Gallery
                  </Link>
                </li>
              </ul>
            </li>
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
