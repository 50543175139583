import { repCityStateMappings, allStatesCities } from "../table/Constants";
import { repAccountStateCityMappings, allAccountStateCityMappings } from "../table/AccountConstants";

const MY_PHYSICIANS = "MY_PHYSICIANS";
const MY_ACCOUNTS = "MY_ACCOUNTS";
const PHYSICIANS = "PHYSICIANS";
const ACCOUNTS = "ACCOUNTS";
const MY_VIEWS = "MY_VIEWS";
const MY_ALERTS = "MY_ALERTS";
const CUSTOM = 'CUSTOM';
const MY_TARGETS = 'MY_TARGETS';


export const ALL_CITIES = { "name": "ALL", "label": "ALL", "value": "ALL" };
const ALL_STATES = { "name": "ALL", "label": "ALL", "value": "ALL", "cities": [] };


export const getStates = (value, repId) => {
    if (!repId) {
        return allStatesCities["0"];
    }

    let temp = repCityStateMappings[repId.substring(0, 5)];
    if (value === PHYSICIANS) {
        temp = allStatesCities["0"];
    } else if (value === MY_ACCOUNTS) {
        temp = repAccountStateCityMappings[repId.substring(0, 5)];
    } else if (value === ACCOUNTS) {
        temp = allAccountStateCityMappings["0"];
    }
    // sort temp
    // a.toLowerCase().localeCompare(b.toLowerCase());
    
    // Remove ALL_STATES from the list
   
    if (temp) {
        temp = temp.filter(item => item.name !== "ALL");
        temp.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        var newArray = temp.slice();
        newArray.unshift(ALL_STATES);
        return newArray;
    }
    return [ALL_STATES];
}

export const getCityForState = (state, repId, viewType) => {
    
    var cities = getCityForStateHelper(state,repId,viewType);

   
    // sort by alphabetical order
    if (cities) {
        cities = cities.filter(item => item.name !== "ALL");
        cities.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
            return 0;
        });

        var newArray = cities.slice();
        newArray.unshift(ALL_CITIES);
        return newArray;
    }
    return [ALL_CITIES];
};

const getCityForStateHelper = (state,repId, viewType) => {
    
    if (!state) {
        return [ALL_CITIES].concat([].concat.apply([], getStates(viewType, repId)?.map(sweetItem => {
            return sweetItem['cities']
        })));
    }
    return [ALL_CITIES].concat((getStates(viewType, repId)?.filter(item => item.name === state))[0]['cities']);
}

export const rowsPerPageParam = [
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 200, label: "200" },
    { value: 250, label: "250" }
];

// export const tierMappings = {
//     "TIER_1": "Super Targets",
//     "TIER_2": "High Decile",
//     "TIER_3": "Medium Decile",
//     "TIER_4": "Low Decile",
//     'ALL': 'All'
// }

export const sortByFieldForPhysicians = [
    { value: "display_name", label: "Sort by - HCP Name" },
    { value: "sarcomaKOLs", label: "Sort by - KOLs?" },
    { value: "tier", label: "Sort by - Tier" },
    { value: "city", label: "Sort by - City" },
    { value: "state", label: "Sort by - State" },
];

export const ascDesc = [
    { value: "asc", label: "A-Z" },
    { value: "desc", label: "Z-A" }
];

export const sortByFieldForHospitals = [
    { value: "hospital_name", label: "Sort by - HCO Name" },
    { value: "sarcoma_center", label: "Sort by -Sarcoma Center?" },
    { value: "city", label: "Sort by - City" },
    { value: "state", label: "Sort by - State" },
];


export const viewTypeToLabelMap = {
    MY_TARGETS:  "My Targets",
    MY_PHYSICIANS: "My HCPs",
    MY_ACCOUNTS: "My Accounts",
    PHYSICIANS: "All Physicians",
    ACCOUNTS: "All Accounts",
    MY_VIEWS: "My Views",
    MY_ALERTS: "My Alerts",
    CUSTOM: "My Custom View",
    'MY_MIRFS': "My MIRFs",
    'CUSTOM_VIEW': 'Nimesh View 1'
}


export const sarcomaCentersQueryParam = [
    { value: "YES", label: "Sarcoma Centers" },
    { value: "NO", label: 'All Accounts' },
]

export const kolsQueryParam = [
    { value: "true", label: "KOLS" },
    { value: "false", label: 'All' },
]
