export const primaryStyle = {
  placeholder: (provided, state) => ({
    ...provided,
    color: '#c4c7c9',
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    backgroundColor: state.isSelected ? '#36C95F' : '#fbfdfb',
    fontSize: 12,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const fontSize = '14px';
    return { ...provided, opacity, transition, fontSize };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  control: (provided, state) => ({
    ...provided,

    // none of react-select's styles are passed to <Control />
    width: '100%',
    boxShadow: '0 0 3px #60cf60',
    borderColor: state.isSelected ? '#36C95F' : '#bcbdbc',
    '&:hover': {
      borderColor: '#36C95F',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 40,
  }),
};
