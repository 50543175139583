import React,{useState,useRef} from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import swal from 'sweetalert';
import doc from "../../../images/rep_image.png";

const UserProfileTable = ({
  data,
  profile,
  setSelectedDoctors,
  setSelectedMessage,
  setSelectedInteraction,
  setSelectedProduct,
  hospitals,
  setData,
  setTableLoadOnPost,
  page,
  setPage,
  rowPerPage,
  totalCallRecord,
  setKeyMessage
}) => {

  return (
    <div className='card mx-3'>
    <div className='col-12 col-xl-7'>
      <div className='card-body'>
        <div className='media d-sm-flex d-block text-center text-sm-left pb-4 mb-4 border-bottom'>
          <img
            alt='pic'
            className='rounded mr-sm-4 mr-0'
            width={130}
            src={doc}
          />
          <div className='media-body align-items-center'>
            <div className='d-sm-flex d-block justify-content-between my-3 my-sm-0'>
              <div>
                <h3 className='fs-22 text-black font-w600 mb-0'>
                  {profile?.first_name}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          {profile?.address?.line1 ?
            <div className='col-lg-7 mb-3'>
              <div className='media'>
                <div>
                  {" "}
                  <span className="d-block text-light mb-2">Address</span>
                  <p className="fs-18 text-dark">
                    {profile?.address?.line1} {profile?.address?.street}{" "}
                    {profile?.address?.city}{" "}
                    <strong>
                      {profile?.address?.state} {profile?.address?.country}{" "}
                      {profile?.address?.zipcode}
                    </strong>
                  </p>
                </div>
              </div>
            </div> : ""}

        
          <div className='col-lg-6 mb-md-0 mb-4'>
            <div className='media'>
              <div className='media-body'>
                <span className='d-block text-light mb-2'>Phone</span>
                <p className='fs-18 text-dark font-w600 mb-0'>
                  {profile?.phone}
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-6 mb-4'>
            <div className='media'>
              <div className='media-body'>
                <span className='d-block text-light mb-2'>Email</span>
                <p className='fs-18 text-dark font-w600 mb-0'>
                  {profile?.email}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default UserProfileTable;
