import React, { useState, useRef, useEffect } from "react";
import { Pagination, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { sortTable } from "../table/RepModalConstants";
import cr from "../../../images/call_record_green.png";


const HomeTableSearch = ({ type, data, loading, totalRecord, rowPerSize, setPage, page, pageSource, setView, setShow, setSearchedData, myFetchedViews, setCreateViewEnabled, handlePageCurrentViewType, handleModal, repId, fetchAndSetMyViewsAndSetMyTargetsAsDefault, myMirfsSubmitted, myAlerts, clearAllStates}) => {
  const sort = rowPerSize;
  const activePag = useRef(0);
  const [test, settest] = useState(0);


 

  const enableLinking = (e) => {
    setShow(true);
    if (e && e === 'NEW') {
      setView({});
    } else {
      setView(e);
      setCreateViewEnabled(true);
    }
  };

  const handleViewView = (e) => {
    enableLinking(e);
  };

  const handleDelete = (item) => {

    let type = "";
    if (item?.hid) {
      type = "HOSPITALS";
    } else if (item?.display_name) {
      // DELTE DOCTOR
      type = "DOCTORS";
    } else {
      // DELETE REP
      type = "REP";
    }

    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to Delete this?",
      icon: "warning",
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          axios
            .delete(`/v1/rep_orchestrator/admin/${item.id}?type=${type}`)

            .then((res) => {
              swal('Deleted Sucessfully');
            })
            .catch((err) => {
              swal('Delete Failed');
            });
        }
      });
  }

  
  const handleDeleteOfView = (item) => {

    swal({
      title: "Delete",
      text: "Are you sure that you want to delete this view?",
      icon: "warning",
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          axios
            .delete(`/v1/rep_orchestrator/view/${item.id}`,
            { headers: { 'x-rep-uid': repId } })
            .then((res) => {
              swal('Deleted View Sucessfully');
              // fetchAndSetMyViews();
              fetchAndSetMyViewsAndSetMyTargetsAsDefault();
            })
            .catch((err) => {
              swal('Delete Failed');
            });
        }
      });
  }

  const boldText = {
    fontWeight: 'bold'
};


  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data?.length; ++i) {
      if (i >= frist && i < sec) {
        data[i]?.classList?.remove("d-none");
      } else {
        data[i]?.classList?.add("d-none");
      }
    }
  };
  const handlePage = (no) => {
    setPage(no);
  }

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);

  // paggination;
  let paggination = Array(Math.ceil(totalRecord / sort))
    .fill()
    .map((_, i) => i + 1);

  const getPaginationArray = () => {
    var min = Math.max(1, page-3), max = Math.min(page+3,  (totalRecord / rowPerSize + 1));
    var  yy = Array.apply(null, { length: max + 1 - min }).map(function (_, idx) {
      return idx + min;
    });
    return yy;
  };

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");
  // console.log(document.querySelectorAll(".sorting_1 input")[0].checked);
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  if (loading === true) {
    return <div className="text-center">
    <Spinner animation="border" role="status" style={{ borderTopColor: 'green' }}>
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>;
  }

  return (
    <>
      {(type === "PHYSICIANS" || type === "MY_TARGETS" || type === "MY_PHYSICIANS" || type === 'CUSTOM_VIEW_PHYSICIANS' || type.includes("TIER")) ? (
        <div className="col-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="dataTables_wrapper">
                <Table
                  responsive
                  striped
                  hover
                  id="patientTable_basic_table"
                  className="display dataTable no-footer  w-100" 
                  style={{ height: "200px" }}
                >
                  <thead>

                    <tr role="row">
                      <th className="sorting_asc">
                        <div className="d-flex align-items-center">
                          <span>HCP Name</span>
                          <div className="d-flex flex-column ml-2">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('display_name', 'DOCTORS', 0, data))} />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('display_name', 'DOCTORS', 1, data))} />
                          </div>
                        </div>
                      </th>
                      {/* <th >
                        <div className="d-flex align-items-center">
                          <span>NPID</span>
                          <div className="d-flex flex-column ml-2">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('npid', 'DOCTORS', 0, data))} />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('npid', 'DOCTORS', 1, data))} />
                          </div>
                        </div>
                      </th> */}
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Speciality</span>
                          <div className="d-flex flex-column ml-2">
                            <button className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('primary_speciality', 'DOCTORS', 0, data))} />
                            <button className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('primary_speciality', 'DOCTORS', 1, data))} />
                          </div>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Tier</span>
                          <div className="d-flex flex-column ml-3">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('tier', 'DOCTORS', 0, data))} />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('tier', 'DOCTORS', 1, data))} />
                          </div>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>KOL</span>
                          <div className="d-flex flex-column ml-2">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('sarcoma_KOLs', 'DOCTORS', 0, data))} />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('sarcoma_KOLs', 'DOCTORS', 1, data))} />
                          </div>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Street</span>
                          <div className="d-flex flex-column ml-2">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'street'], 'DOCTORS', 0, data))} />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'street'], 'DOCTORS', 1, data))} />
                          </div>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span> City</span>
                          <div className="d-flex flex-column ml-2">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'city'], 'DOCTORS', 0, data))} />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'city'], 'DOCTORS', 1, data))} />
                          </div>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>State</span>
                          <div className="d-flex flex-column ml-2">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'state'], 'DOCTORS', 0, data))} />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'state'], 'DOCTORS', 1, data))} />
                          </div>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Record</span>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Profile</span>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>MIRF</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item) => (
                      <tr role="row" className={item?.trClassName}>
                        <td>{item?.display_name}</td>
                        {/* <td>{item?.npid}</td> */}
                        <td> {item?.primary_speciality}</td>
                        <td>
                          <span className="text-cyan font-w600">
                            {item?.tier}
                          </span>
                        </td>
                        <td>{(item?.sarcoma_KOLs) ? 'YES' : 'NO'}</td>
                        <td>{item?.address?.street ? item.address.street + ', ' : ''} {item?.address?.zipcode ? item.address.zipcode : ''}</td>
                        <td>{item?.address?.city}</td>
                        <td>{item?.address?.state}</td>
                        <td>
                          <Link
                            to={{
                              pathname: "/call-record/" + item?.id,
                            }}
                            target="_blank"
                          >
                            <img
                              alt='pic'
                              className='rounded mr-sm-4 mr-0'
                              width={40}
                              src={cr}
                            />
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={{
                              pathname: "/user-profile/D" + item?.id,
                            }}
                            target="_blank"
                          >
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.75 12C15.75 12.9946 15.3549 13.9484 14.6517 14.6517C13.9484 15.3549 12.9946 15.75 12 15.75C11.0054 15.75 10.0516 15.3549 9.34835 14.6517C8.64509 13.9484 8.25 12.9946 8.25 12C8.25 11.0054 8.64509 10.0516 9.34835 9.34835C10.0516 8.64509 11.0054 8.25 12 8.25C12.9946 8.25 13.9484 8.64509 14.6517 9.34835C15.3549 10.0516 15.75 11.0054 15.75 12V12Z"
                                fill="black"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 12C0 12 4.5 3.75 12 3.75C19.5 3.75 24 12 24 12C24 12 19.5 20.25 12 20.25C4.5 20.25 0 12 0 12ZM12 17.25C13.3924 17.25 14.7277 16.6969 15.7123 15.7123C16.6969 14.7277 17.25 13.3924 17.25 12C17.25 10.6076 16.6969 9.27226 15.7123 8.28769C14.7277 7.30312 13.3924 6.75 12 6.75C10.6076 6.75 9.27226 7.30312 8.28769 8.28769C7.30312 9.27226 6.75 10.6076 6.75 12C6.75 13.3924 7.30312 14.7277 8.28769 15.7123C9.27226 16.6969 10.6076 17.25 12 17.25V17.25Z"
                                fill="black"
                              />
                            </svg>
                          </Link>
                        </td>
                        <td>
                          <div className="width163 mb-3 mr-3">
                            <button
                              type="button"
                              className="btn btn-primary rounded-pill w-200 py-2"
                              onClick={() => handleModal('MIRF_FORM', item)}
                            >
                              MIRF
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <div className="d-sm-flex text-center align-items-center justify-content-between px-3">
                  <div
                    className="dataTables_info"
                    id="example_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing {(rowPerSize * page) - rowPerSize + 1} to{" "}
                    {rowPerSize * page}{" "}
                    of {totalRecord} entries
                  </div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example_paginate"
                  >
                    <button
                      className="paginate_button previous disabled"
                      to="/home"
                      onClick={() => handlePage(page - 1)}
                      disabled={page === 1}
                    >
                      Previous
                    </button>
                    <span style={{
                      maxWidth: '200px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'inline-flex'
                    }}>
                      {getPaginationArray().map((number, i) => (
                        <button
                          key={i}
                          className={`paginate_button  ${number === page ? "current" : ""
                            } ${i > 0 ? "ml-1" : ""}`}
                          onClick={() => handlePage(number)}
                        >
                          {number}
                        </button>
                      ))}
                    </span>
                    <button
                      className="paginate_button next"
                      onClick={() => handlePage(page + 1)}
                      disabled={
                        (totalRecord / rowPerSize + 1) === page
                      }
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (type === "REPRESENTATIVES") ? (
        <div className="col-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="dataTables_wrapper">
                <Table
                  responsive
                  striped
                  hover
                  id="patientTable_basic_table"
                  className="display dataTable no-footer  w-100"
                >
                  <thead>
                    <tr role="row">
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span> Name</span>
                          <div className="d-flex flex-column ml-2">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" />
                          </div>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Line</span>
                          <div className="d-flex flex-column ml-2">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" />
                          </div>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>City</span>
                          <div className="d-flex flex-column ml-2">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" />
                          </div>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>State</span>
                          <div className="d-flex flex-column ml-2">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" />
                          </div>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Country</span>
                          <div className="d-flex flex-column ml-2">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" />
                          </div>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Zipcode</span>
                          <div className="d-flex flex-column ml-2">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" />
                          </div>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Profile</span>
                          <div className="d-flex flex-column ml-2">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" />
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item) => (
                      <tr role="row" className={item?.trClassName}>
                        <td>{item?.first_name}</td>
                        <td> {item?.address?.line1}</td>
                        <td> {item?.address?.city}</td>
                        <td> {item?.address?.state}</td>
                        <td> {item?.address?.country}</td>
                        <td> {item?.address?.zipcode}</td>
                        <td>
                          <Link
                            to={{
                              pathname: "/call-record-hospital/" + item?.id,
                            }}
                            target="_blank"
                          >
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.75 12C15.75 12.9946 15.3549 13.9484 14.6517 14.6517C13.9484 15.3549 12.9946 15.75 12 15.75C11.0054 15.75 10.0516 15.3549 9.34835 14.6517C8.64509 13.9484 8.25 12.9946 8.25 12C8.25 11.0054 8.64509 10.0516 9.34835 9.34835C10.0516 8.64509 11.0054 8.25 12 8.25C12.9946 8.25 13.9484 8.64509 14.6517 9.34835C15.3549 10.0516 15.75 11.0054 15.75 12V12Z"
                                fill="black"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 12C0 12 4.5 3.75 12 3.75C19.5 3.75 24 12 24 12C24 12 19.5 20.25 12 20.25C4.5 20.25 0 12 0 12ZM12 17.25C13.3924 17.25 14.7277 16.6969 15.7123 15.7123C16.6969 14.7277 17.25 13.3924 17.25 12C17.25 10.6076 16.6969 9.27226 15.7123 8.28769C14.7277 7.30312 13.3924 6.75 12 6.75C10.6076 6.75 9.27226 7.30312 8.28769 8.28769C7.30312 9.27226 6.75 10.6076 6.75 12C6.75 13.3924 7.30312 14.7277 8.28769 15.7123C9.27226 16.6969 10.6076 17.25 12 17.25V17.25Z"
                                fill="black"
                              />
                            </svg>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-sm-flex text-center align-items-center justify-content-between px-3">
                  <div
                    className="dataTables_info"
                    id="example_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing {activePag.current * sort + 1} to{" "}
                    {data.length > (activePag.current + 1) * sort
                      ? (activePag.current + 1) * sort
                      : data.length}{" "}
                    of {data.length} entries
                  </div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example_paginate"
                  >
                    <Link
                      className="paginate_button previous disabled"
                      to="/home"
                      onClick={() =>
                        activePag.current > 0 && onClick(activePag.current - 1)
                      }
                    >
                      Previous
                    </Link>
                    <span>
                      {getPaginationArray().map((number, i) => (
                        <Link
                          key={i}
                          to="/home"
                          className={`paginate_button  ${activePag.current === i ? "current" : ""
                            } ${i > 0 ? "ml-1" : ""}`}
                          onClick={() => onClick(i)}
                        >
                          {number}
                        </Link>
                      ))}
                    </span>
                    <Link
                      className="paginate_button next"
                      to="/home"
                      onClick={() =>
                        activePag.current + 1 < paggination.length &&
                        onClick(activePag.current + 1)
                      }
                    >
                      Next
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (type === "ACCOUNTS" || type === 'CUSTOM_VIEW_ACCOUNTS' || type === "MY_ACCOUNTS") ? (<div className="col-12">
        <div className="card">
          <div className="card-body p-0">
            <div className="dataTables_wrapper">
              <Table
                responsive
                striped
                hover
                id="patientTable_basic_table"
                className="display dataTable no-footer  w-100"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting">
                      <div className="d-flex align-items-center">
                        <span> Name</span>
                        <div className="d-flex flex-column ml-2">
                          <button className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('hospital_name', 'HOSPITAL', 0, data))} />
                          <button className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('hospital_name', 'HOSPITAL', 1, data))} />
                        </div>
                      </div>
                    </th>
                    <th className="sorting">
                      <div className="d-flex align-items-center">
                        <span>Hospital ID</span>
                        <div className="d-flex flex-column ml-2">
                          <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('hid', 'HOSPITAL', 0, data))} />
                          <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('hid', 'HOSPITAL', 1, data))} />
                        </div>
                      </div>
                    </th>
                    <th className="sorting">
                      <div className="d-flex align-items-center">
                        <span>Sarcoma Center</span>
                        <div className="d-flex flex-column ml-2">
                          <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('sarcoma_specialized', 'HOSPITAL', 0, data))} />
                          <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('sarcoma_specialized', 'HOSPITAL', 1, data))} />
                        </div>
                      </div>
                    </th>
                    <th className="sorting">
                      <div className="d-flex align-items-center">
                        <span>Street</span>
                        <div className="d-flex flex-column ml-2">
                          <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'street'], 'HOSPITAL', 0, data))} />
                          <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'street'], 'HOSPITAL', 1, data))} />
                        </div>
                      </div>
                    </th>
                    <th className="sorting">
                      <div className="d-flex align-items-center">
                        <span>City</span>
                        <div className="d-flex flex-column ml-2">
                          <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'city'], 'HOSPITAL', 0, data))} />
                          <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'city'], 'HOSPITAL', 1, data))} />
                        </div>
                      </div>
                    </th>
                    <th className="sorting">
                      <div className="d-flex align-items-center">
                        <span>State</span>
                        <div className="d-flex flex-column ml-2">
                          <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'state'], 'HOSPITAL', 0, data))} />
                          <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'state'], 'HOSPITAL', 1, data))} />
                        </div>
                      </div>
                    </th>
                    <th className="sorting">
                      <div className="d-flex align-items-center">
                        <span>Zipcode</span>
                        <div className="d-flex flex-column ml-2">
                          <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'zipcode'], 'HOSPITAL', 0, data))} />
                          <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'zipcode'], 'HOSPITAL', 1, data))} />
                        </div>
                      </div>
                    </th>
                    <th className="sorting">
                      <div className="d-flex align-items-center">
                        <span>Record</span>
                      </div>
                    </th>
                    <th className="sorting">
                      <div className="d-flex align-items-center">
                        <span>Profile</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item) => (
                    <tr role="row" className={item?.trClassName}>
                      <td>{item?.hospital_name}</td>
                      <td>{item?.hid}</td>
                      <td>{item?.sarcoma_specialized ? "YES" : "NO"}</td>
                      <td> {item?.address?.street}</td>
                      <td> {item?.address?.city}</td>
                      <td> {item?.address?.state}</td>
                      <td> {item?.address?.zipcode}</td>
                      <td>
                        <Link
                          to={{
                            pathname: "/call-record-hospital/" + item?.id,
                          }}
                          target="_blank"
                        >

                          <img
                            alt='pic'
                            className='rounded mr-sm-4 mr-0'
                            width={40}
                            src={cr}
                          />
                        </Link>
                      </td>

                      <td>
                        <Link
                          to={{
                            pathname: "/user-profile/H" + item?.id,
                          }}
                          target="_blank"
                        >
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.75 12C15.75 12.9946 15.3549 13.9484 14.6517 14.6517C13.9484 15.3549 12.9946 15.75 12 15.75C11.0054 15.75 10.0516 15.3549 9.34835 14.6517C8.64509 13.9484 8.25 12.9946 8.25 12C8.25 11.0054 8.64509 10.0516 9.34835 9.34835C10.0516 8.64509 11.0054 8.25 12 8.25C12.9946 8.25 13.9484 8.64509 14.6517 9.34835C15.3549 10.0516 15.75 11.0054 15.75 12V12Z"
                              fill="black"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0 12C0 12 4.5 3.75 12 3.75C19.5 3.75 24 12 24 12C24 12 19.5 20.25 12 20.25C4.5 20.25 0 12 0 12ZM12 17.25C13.3924 17.25 14.7277 16.6969 15.7123 15.7123C16.6969 14.7277 17.25 13.3924 17.25 12C17.25 10.6076 16.6969 9.27226 15.7123 8.28769C14.7277 7.30312 13.3924 6.75 12 6.75C10.6076 6.75 9.27226 7.30312 8.28769 8.28769C7.30312 9.27226 6.75 10.6076 6.75 12C6.75 13.3924 7.30312 14.7277 8.28769 15.7123C9.27226 16.6969 10.6076 17.25 12 17.25V17.25Z"
                              fill="black"
                            />
                          </svg>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="d-sm-flex text-center align-items-center justify-content-between px-3">
                <div
                  className="dataTables_info"
                  id="example_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {(rowPerSize * page) - rowPerSize + 1} to{" "}
                  {rowPerSize * page}{" "}
                  of {totalRecord} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example_paginate"
                >
                  <button
                    className="paginate_button previous disabled"
                    to="/home"
                    onClick={() => handlePage(page - 1)}
                    disabled={page === 1}
                  >
                    Previous
                  </button>
                  <span style={{
                    maxWidth: '200px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'inline-flex'
                  }}>
                    {getPaginationArray().map((number, i) => (
                      <button
                        key={i}
                        className={`paginate_button  ${number === page ? "current" : ""
                          } ${i > 0 ? "ml-1" : ""}`}
                        onClick={() => handlePage(number)}
                      >
                        {number}
                      </button>
                    ))}
                  </span>
                  <button
                    className="paginate_button next"
                    onClick={() => handlePage(page + 1)}
                    disabled={
                      (totalRecord / rowPerSize + 1) === page
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>) :
        (type === "MY_ALERTS") ?
              (
                <div className="card">
            <div className="card-body p-0">
              <div className="dataTables_wrapper">
                <Table
                  responsive
                  striped
                  hover
                  id="patientTable_basic_table"
                  className="display dataTable no-footer  w-100"
                >
                  <thead>
                    <tr role="row">
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>HCP Name</span>
                        </div>
                      </th>
                      {/* <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Tier</span>
                        </div>
                      </th> */}
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span> Alert Title</span>
                        </div>
                      </th>
                      {/* <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Priority</span>
                        </div>
                      </th> */}
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Therapy Inititation Date</span>
                        </div>
                      </th>
                      {/* <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Created At</span>
                        </div>
                      </th> */}
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Profile</span>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Record</span>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Dismiss</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {myAlerts?.map((item) => (
                      <tr role="row" className={item.trClassName}>
                        {/* <td> {"ALRT0000".concat(item?.alert_id)}</td> */}
                        <td>{item?.doctor?.display_name} </td>
                        <td>{item?.alert_title} </td>
                        {/* <td style={item?.priority === 'URGENT'? 
                        {color: "red","paddingTop":0, "paddingBottom":0, fontWeight:'bold'}: 
                        {color: "black","paddingTop":0, "paddingBottom":0}}> {item?.priority}</td>                         */}
                        <td> {item?.patient_initiation_date}</td>
                        {/* <td> {item?.created_at?.slice(0, 10)}</td> */}

                        <td>
                          <Link
                            to={{
                              pathname: "/user-profile/D" + item?.doctor_id,
                            }}
                            target="_blank"
                          >
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.75 12C15.75 12.9946 15.3549 13.9484 14.6517 14.6517C13.9484 15.3549 12.9946 15.75 12 15.75C11.0054 15.75 10.0516 15.3549 9.34835 14.6517C8.64509 13.9484 8.25 12.9946 8.25 12C8.25 11.0054 8.64509 10.0516 9.34835 9.34835C10.0516 8.64509 11.0054 8.25 12 8.25C12.9946 8.25 13.9484 8.64509 14.6517 9.34835C15.3549 10.0516 15.75 11.0054 15.75 12V12Z"
                                fill="black"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 12C0 12 4.5 3.75 12 3.75C19.5 3.75 24 12 24 12C24 12 19.5 20.25 12 20.25C4.5 20.25 0 12 0 12ZM12 17.25C13.3924 17.25 14.7277 16.6969 15.7123 15.7123C16.6969 14.7277 17.25 13.3924 17.25 12C17.25 10.6076 16.6969 9.27226 15.7123 8.28769C14.7277 7.30312 13.3924 6.75 12 6.75C10.6076 6.75 9.27226 7.30312 8.28769 8.28769C7.30312 9.27226 6.75 10.6076 6.75 12C6.75 13.3924 7.30312 14.7277 8.28769 15.7123C9.27226 16.6969 10.6076 17.25 12 17.25V17.25Z"
                                fill="black"
                              />
                            </svg>
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={{
                              pathname: "/call-record/" + item?.doctor_id,
                            }}
                            target="_blank"
                          >
                            <img
                              alt='pic'
                              className='rounded mr-sm-4 mr-0'
                              width={40}
                              src={cr}
                            />
                          </Link>
                        </td>
                        <td>
                          <div className="width163 mb-3 mr-3">
                            <button
                              type="button"
                              className="btn btn-primary rounded-pill w-200 py-2"
                              onClick={() => {
                                handleModal('DISMISS_ALERT', item);
                              }}
                            >
                              DISMISS
                            </button>
                          </div>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
              )
          :
          (type === "MY_MIRFS") ?
          (<div className="card">
            <div className="card-body p-0">
              <div className="dataTables_wrapper">
                <Table
                  responsive
                  striped
                  hover
                  id="patientTable_basic_table"
                  className="display dataTable no-footer  w-100"
                >
                  <thead>
                    <tr role="row">
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>MIRF ID</span>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span> Name</span>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Visit Type</span>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Requested Date</span>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Created At</span>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>View</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {myMirfsSubmitted?.map((item) => (
                      <tr role="row" className={item.trClassName}>
                        <td> {"MIRF0000".concat(item?.id)}</td>
                        <td>{item?.name} </td>
                        <td> {item?.visit_type}</td>                        
                        <td> {item?.requested_date.slice(0, 10)}</td>
                        <td> {item?.created_at.slice(0, 10)}</td>
                        <td>
                          <Link to="#">
                            <i
                              className="flaticon-381-view-2 scale2"
                              onClick={() => {
                                handleModal('MIRF_FORM_VIEW', item);
                              }}
                            />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          ):
          (<div className="card">
            <div className="card-body p-0">
              <div className="dataTables_wrapper">
                <Table
                  responsive
                  striped
                  hover
                  id="patientTable_basic_table"
                  className="display dataTable no-footer  w-100"
                >
                  <thead>
                    <tr role="row">
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>View ID</span>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span> Name of the view</span>
                          {/* <div className="d-flex flex-column ml-2">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('view_name', 'HOSPITALS', 0, data))} />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable('view_name', 'HOSPITALS', 1, data))} />
                          </div> */}
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Description of the view</span>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>View Type</span>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Created Date</span>
                          {/* <div className="d-flex flex-column ml-2">
                            <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'city'], 'HOSPITALS', 0, data))} />
                            <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSearchedData(sortTable(['address', 'city'], 'HOSPITALS', 1, data))} />
                          </div> */}
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>View</span>
                        </div>
                      </th>
                      <th className="sorting">
                        <div className="d-flex align-items-center">
                          <span>Delete</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {myFetchedViews?.map((item) => (
                      <tr role="row" className={item.trClassName}>
                        <td> {item?.id}</td>
                        <td>
                          <div
                           className="width180 mb-3 mr-3"
                           >
                            <button
                              type="button"
                              className="btn btn-primary rounded-pill w-100 py-2"
                              onClick={() => {
                                handlePageCurrentViewType({ value: item?.id, 'viewId': item?.id, label: item?.view_name, type: 'CUSTOM_VIEW_'.concat(item?.view_type), sub_type: 'CUSTOM' });
                              }}>
                              {item?.view_name}
                            </button>
                          </div>
                        </td>
                        <td>{item?.description}</td>
                        <td> {item?.view_type}</td>
                        <td> {item?.created_at}</td>
                        <td>
                          <Link to="#">
                            <i
                              className="flaticon-381-view-2 scale2"
                              onClick={() => {
                                handleViewView(item);
                              }}
                            />
                          </Link>
                        </td>
                        <td>
                          <Link to="#">
                            <i
                              className="flaticon-381-trash scale2"
                              onClick={() => {
                                handleDeleteOfView(item);
                              }
                              }
                            />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          )}
    </>
  );
};

export default HomeTableSearch;
