import React, { Fragment, useState } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import ChatBox from "../ChatBox";
import { useSelector } from "react-redux";
import axios from "axios";

const JobieNav = ({ title }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  const { isLoading } = useSelector((state) => state.auth);
  const [toggle, setToggle] = useState("");

  const onClick = (name) => setToggle(toggle === name ? "" : name);

  const repId = useSelector((state) => state.firebase.auth.uid);
  const [profile, setProfile] = useState(null);

  if (!isAuthenticated) {
    return <> </>;
  }

  return (
    <Fragment>
      <Header
        onNote={() => onClick("chatbox")}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
      />
      <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />
    </Fragment>
  );
};


export default JobieNav;
