import React,{useState,useRef} from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import swal from 'sweetalert';
const UserProfileTable = ({
  data,
  profile,
  setSelectedDoctors,
  setSelectedMessage,
  setSelectedInteraction,
  setSelectedProduct,
  hospitals,
  setData,
  setTableLoadOnPost,
  page,
  setPage,
  rowPerPage,
  totalCallRecord,
  setKeyMessage,
  sortTable,
  pageSource
}) => {

  const sort =rowPerPage ;
  const activePag = useRef(0);
  const [test, settest] = useState(0);
  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data?.length; ++i) {
      if (i >= frist && i < sec) {
        data[i]?.classList?.remove("d-none");
      } else {
        data[i]?.classList?.add("d-none");
      }
    }
  };

  const getPaginationArray = () => {
    var min = Math.max(1, page - 3), max = Math.min(page + 3, (totalCallRecord / rowPerPage + 1));
    return Array.apply(null, { length: max + 1 - min })
      .map(function (_, idx) {
        return idx + min;
      });
  };

  const handlePage=(no)=>{
    setPage(no);
  }

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination;
    let paggination = []
    if(totalCallRecord){
      paggination =  Array(Math.ceil(totalCallRecord / sort))
      .fill()
      .map((_, i) => i + 1);
    }

  // Active paggination & chage data

  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  const formatter = (data,call_record_id) => {
    return {
      id: data.id,
      label: data.name,
      value: data.name,
      call_record_id: call_record_id
    };;
  };

  const doctorFormatter = (data) => {
    if(!Array.isArray(data)) return []
    return data.map(doc=>({label:doc.display_name,id:doc.id,tier:doc.tier}))
  };

  const setDatahandler = (data,item) => {     
      setSelectedDoctors(doctorFormatter(data.doctors));
      setSelectedMessage(formatter(data.key_message[0]));
      setSelectedInteraction(formatter(data.interaction_type,data.call_record_id));
      setSelectedProduct(formatter(data?.product));
      setKeyMessage(data.key_message.map(Km=> Km.id));
      setData( {
        key_message:data.key_message,
        interaction_type:{...data.interaction_type,call_record_id:data.call_record_id},
        product:data?.product,
        call_date:data.call_date,
        status:data.status,
        doctors:data.doctors,
        cid: data.cid,
        call_record_id: data.call_record_id,
        leave_behinds: [
          {
              name: 'JCO',
              value: data?.leave_behinds[0]?.value
          },
      ],
      notes:''
    });
    window.scroll({
      top: 200,
      left: 100,
      behavior: 'smooth'
    });
  };

  const doctorName = (item) => {
    console.log("item ", item);
    if (!profile.hid) {
      var doctors = item.doctors;
      for (var i = 0; i < doctors.length; i++) {
        if (doctors[i].id === profile.id) {
          return doctors[i].display_name;
        }
      }
    } else {
      var hospitals = item.hospitals;
      for (var i = 0; i < hospitals.length; i++) {
        if (hospitals[i].id === profile.id) {
          return hospitals[i].hospital_name;
        }
      }
    }
  };

  const fillter = (items) => {

    if (!profile?.hid  ) {
   
      let filterRecs = [];
      for (var i = 0; i < items.length; i++) {
         let currItem = items[i];
         if (currItem.doctors) {
            var flagFound = false;
            for (var j=0; j< currItem.doctors.length; j++) {
                if (currItem.doctors[j].id === profile.id) {
                  flagFound = true;
                  console.log (" all items -> true ",i);
                  break;
                }
            }

            if (flagFound) {
              filterRecs.push(currItem);
            }
         }

      }
      return filterRecs;
    }
    return items;

  };


  const doctorTier = (item) => {

    if (!profile.hid) {
      var doctors = item.doctors;
      for (var i = 0; i < doctors.length; i++) {
        if (doctors[i].id === profile.id) {
          return doctors[i].tier;
        }
      }
    } else {
      return "";
    }
  };

  const handleDelete = (item) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to Delete this?",
      icon: "warning",
      dangerMode: true,
    })
    .then(willDelete => {
      if (willDelete) {
        axios
        .delete(`/v1/rep_orchestrator/call_record/${item.cid}` )
        .then((res) => {
          swal('Deleted Sucessfully').then(()=> setTableLoadOnPost(true))
        })
        .catch((err) => {
          swal('fail').then(()=> setTableLoadOnPost(true))
        });
      }
    });
  
  };



  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body p-0">
          <div className="dataTables_wrapper">
            <Table
              responsive
              striped
              hover
              id="patientTable_basic_table"
              className="display dataTable no-footer  w-100"
            >
              <thead>
                <tr role="row">
                  <th className="sorting">
                    <div className="d-flex align-items-center">
                      <span>CID</span>
                      <div className="d-flex flex-column ml-2">
                        <i className="fa fa-sort-up line-height-0 btn btn-sort p-0"  onClick={() => sortTable('cid', 'CALL_RECORD', 0)} />
                        <i className="fa fa-sort-down line-height-0 btn btn-sort p-0" onClick={() => sortTable('cid', 'CALL_RECORD', 1)} />
                      </div>
                    </div>
                  </th>
                  <th className="sorting">
                    <div className="d-flex align-items-center">
                      <span>Call Date</span>
                      <div className="d-flex flex-column ml-2">
                        <i className="fa fa-sort-up line-height-0 btn btn-sort p-0"  onClick={() => sortTable('call_date', 'CALL_RECORD', 0)} />
                        <i className="fa fa-sort-down line-height-0 btn btn-sort p-0" onClick={() => sortTable('call_date', 'CALL_RECORD', 1)} />
                      </div>
                    </div>
                  </th>
                  <th className="sorting">
                    <div className="d-flex align-items-center">
                       <span>Name</span>
                      {/* <div className="d-flex flex-column ml-2">
                        <i className="fa fa-sort-up line-height-0 btn btn-sort p-0"  onClick={() => sortTable('name', 'CALL_RECORD', 0)} />
                        <i className="fa fa-sort-down line-height-0 btn btn-sort p-0" onClick={() => sortTable('name', 'CALL_RECORD', 1)} />
                      </div> */}
                    </div>
                  </th>
                  <th className="sorting">
                    <div className="d-flex align-items-center">
                      <span>Status</span>
                      <div className="d-flex flex-column ml-2">
                        <i className="fa fa-sort-up line-height-0 btn btn-sort p-0"  onClick={() => sortTable('status', 'CALL_RECORD', 0)} />
                        <i className="fa fa-sort-down line-height-0 btn btn-sort p-0" onClick={() => sortTable('status', 'CALL_RECORD', 1)} />
                      </div>
                    </div>
                  </th>
                  <th className="sorting">
                    <div className="d-flex align-items-center">
                      <span>Tier</span>
                      {/* <div className="d-flex flex-column ml-2">
                        <i className="fa fa-sort-up line-height-0 btn btn-sort p-0"  onClick={() => sortTable('tier', 'CALL_RECORD', 0)} />
                        <i className="fa fa-sort-down line-height-0 btn btn-sort p-0" onClick={() => sortTable('tier', 'CALL_RECORD', 1)} />
                      </div> */}
                    </div>
                  </th>
                  {(!pageSource) ? <th className="sorting">View</th> : ''}
                  {(!pageSource) ? <th className="sorting">Edit</th> : ''}
                  {(!pageSource) ? <th className="sorting">Delete</th> : ''}
                </tr>
              </thead>
              <tbody>
                {fillter(data)?.map((item) => (
                  <tr role="row" className={item.trClassName}>
                    <td>{item?.cid}</td>
                    <td>{moment(item?.call_date).format('YYYY/MM/DD')}</td>
                    <td>{doctorName(item)}</td>
                    <td>
                      <span
                        className={`badge light ${item?.status === "SUBMITTED"
                          ? "text-primary"
                          : "text-warning"
                          }`}
                      >
                        <i className="fa fa-circle mr-2" />
                        {item?.status}
                      </span>
                    </td>
                    <td>{doctorTier(item)}</td>

                    {(!pageSource) ?
                      (<td>
                        <Link to="#">
                          <i className="flaticon-381-view-2 scale2"
                            onClick={() => setDatahandler(item)} />
                        </Link>
                      </td>) : ''}
                    {(!pageSource) ?
                      <td>
                        <Link to="#" style={{ cursor: item.status === "SUBMITTED" ? 'not-allowed' : 'default' }} >
                          <i
                            className={
                              item.status === "SUBMITTED"
                                ? "flaticon-381-forbidden"
                                : "flaticon-381-edit-1 scale2"
                            }
                            onClick={() => {
                              if (item.status === "SAVED") {
                                setDatahandler(item)
                              }
                            }}
                          />
                        </Link>
                      </td> : ''}
                    {(!pageSource) ?
                      <td>
                        <Link to="#" style={{ cursor: item.status === "SUBMITTED" ? 'not-allowed' : 'default' }}>
                          <i
                            className={
                              item.status === "SUBMITTED"
                                ? "flaticon-381-forbidden"
                                : "flaticon-381-trash scale2"
                            }
                            onClick={() => {
                              if (item.status === "SAVED") {
                                handleDelete(item);
                              }
                            }
                            }
                          />
                        </Link>
                      </td> : ''}
                  </tr>
                ))}
              </tbody>
            </Table>
            
            <div className="d-sm-flex text-center align-items-center justify-content-between px-3">
                  <div
                    className="dataTables_info"
                    id="example_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing {(rowPerPage*page)-rowPerPage+1} to{" "} 
                      {rowPerPage*page}{" "}
                    of {totalCallRecord} entries
                  </div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example_paginate"
                  >
                    <button
                      className="paginate_button previous disabled"
                      to="/home"
                      onClick={() => handlePage(page - 1)}
                      disabled={page===1}
                    >
                      Previous
                    </button>
                    <span style={{maxWidth: '200px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'inline-flex'}}>
                      {getPaginationArray().map((number, i) => (
                        <button
                          key={i}
                          className={`paginate_button  ${
                            number === page ? "current" : ""
                          } ${number > 1 ? "ml-1" : ""}`}
                          onClick={() => handlePage(number)}
                        >
                          {number}
                        </button>
                      ))}
                    </span>
                    <button
                      className="paginate_button next"
                      onClick={() =>handlePage(page + 1)}
                      disabled={paggination.length===page}
                    >
                      Next
                    </button>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileTable;
