export const repCityStateMappings = {
  "YjmmX": [
      {
          "name": "ALL",
          "label": "ALL",
          "value": "ALL",
          "cities": []
      },
      {
          "name": "CA",
          "label": "California",
          "value": "CA",
          "cities": [
              {
                  "name": "DOWNEY",
                  "label": "DOWNEY",
                  "value": "DOWNEY"
              },
              {
                  "name": "DUARTE",
                  "label": "DUARTE",
                  "value": "DUARTE"
              },
              {
                  "name": "SAN BERNARDINO",
                  "label": "SAN BERNARDINO",
                  "value": "SAN BERNARDINO"
              },
              {
                  "name": "SANTA MARIA",
                  "label": "SANTA MARIA",
                  "value": "SANTA MARIA"
              },
              {
                  "name": "SAN FRANCISCO",
                  "label": "SAN FRANCISCO",
                  "value": "SAN FRANCISCO"
              },
              {
                  "name": "LOS ANGELES",
                  "label": "LOS ANGELES",
                  "value": "LOS ANGELES"
              },
              {
                  "name": "HEMET",
                  "label": "HEMET",
                  "value": "HEMET"
              },
              {
                  "name": "ORANGE",
                  "label": "ORANGE",
                  "value": "ORANGE"
              },
              {
                  "name": "SAN DIEGO",
                  "label": "SAN DIEGO",
                  "value": "SAN DIEGO"
              },
              {
                  "name": "WOODLAND HILLS",
                  "label": "WOODLAND HILLS",
                  "value": "WOODLAND HILLS"
              },
              {
                  "name": "LOMA LINDA",
                  "label": "LOMA LINDA",
                  "value": "LOMA LINDA"
              },
              {
                  "name": "COSTA MESA",
                  "label": "COSTA MESA",
                  "value": "COSTA MESA"
              },
              {
                  "name": "FONTANA",
                  "label": "FONTANA",
                  "value": "FONTANA"
              },
              {
                  "name": "BAKERSFIELD",
                  "label": "BAKERSFIELD",
                  "value": "BAKERSFIELD"
              },
              {
                  "name": "CERRITOS",
                  "label": "CERRITOS",
                  "value": "CERRITOS"
              },
              {
                  "name": "TORRANCE",
                  "label": "TORRANCE",
                  "value": "TORRANCE"
              },
              {
                  "name": "SOUTH SAN FRANCISCO",
                  "label": "SOUTH SAN FRANCISCO",
                  "value": "SOUTH SAN FRANCISCO"
              },
              {
                  "name": "MILL VALLEY",
                  "label": "MILL VALLEY",
                  "value": "MILL VALLEY"
              },
              {
                  "name": "LAGUNA NIGUEL",
                  "label": "LAGUNA NIGUEL",
                  "value": "LAGUNA NIGUEL"
              },
              {
                  "name": "ARLETA",
                  "label": "ARLETA",
                  "value": "ARLETA"
              },
              {
                  "name": "IRVINE",
                  "label": "IRVINE",
                  "value": "IRVINE"
              },
              {
                  "name": "LANCASTER",
                  "label": "LANCASTER",
                  "value": "LANCASTER"
              },
              {
                  "name": "LA JOLLA",
                  "label": "LA JOLLA",
                  "value": "LA JOLLA"
              },
              {
                  "name": "SACRAMENTO",
                  "label": "SACRAMENTO",
                  "value": "SACRAMENTO"
              },
              {
                  "name": "MADERA",
                  "label": "MADERA",
                  "value": "MADERA"
              },
              {
                  "name": "BURBANK",
                  "label": "BURBANK",
                  "value": "BURBANK"
              },
              {
                  "name": "SALINAS",
                  "label": "SALINAS",
                  "value": "SALINAS"
              },
              {
                  "name": "STANFORD",
                  "label": "STANFORD",
                  "value": "STANFORD"
              },
              {
                  "name": "STOCKTON",
                  "label": "STOCKTON",
                  "value": "STOCKTON"
              },
              {
                  "name": "VACAVILLE",
                  "label": "VACAVILLE",
                  "value": "VACAVILLE"
              },
              {
                  "name": "ANAHEIM",
                  "label": "ANAHEIM",
                  "value": "ANAHEIM"
              },
              {
                  "name": "SANTA ROSA",
                  "label": "SANTA ROSA",
                  "value": "SANTA ROSA"
              },
              {
                  "name": "SANTA BARBARA",
                  "label": "SANTA BARBARA",
                  "value": "SANTA BARBARA"
              },
              {
                  "name": "LOS ALAMITOS",
                  "label": "LOS ALAMITOS",
                  "value": "LOS ALAMITOS"
              },
              {
                  "name": "SUNNYVALE",
                  "label": "SUNNYVALE",
                  "value": "SUNNYVALE"
              },
              {
                  "name": "OAKLAND",
                  "label": "OAKLAND",
                  "value": "OAKLAND"
              },
              {
                  "name": "EMERYVILLE",
                  "label": "EMERYVILLE",
                  "value": "EMERYVILLE"
              },
              {
                  "name": "WEST HOLLYWOOD",
                  "label": "WEST HOLLYWOOD",
                  "value": "WEST HOLLYWOOD"
              },
              {
                  "name": "REDDING",
                  "label": "REDDING",
                  "value": "REDDING"
              },
              {
                  "name": "PLEASANT HILL",
                  "label": "PLEASANT HILL",
                  "value": "PLEASANT HILL"
              },
              {
                  "name": "SOUTH SAN FRANC",
                  "label": "SOUTH SAN FRANC",
                  "value": "SOUTH SAN FRANC"
              },
              {
                  "name": "OCEANSIDE",
                  "label": "OCEANSIDE",
                  "value": "OCEANSIDE"
              },
              {
                  "name": "LONG BEACH",
                  "label": "LONG BEACH",
                  "value": "LONG BEACH"
              },
              {
                  "name": "CHICO",
                  "label": "CHICO",
                  "value": "CHICO"
              },
              {
                  "name": "PORTERVILLE",
                  "label": "PORTERVILLE",
                  "value": "PORTERVILLE"
              },
              {
                  "name": "SAN JOSE",
                  "label": "SAN JOSE",
                  "value": "SAN JOSE"
              },
              {
                  "name": "CASTRO VALLEY",
                  "label": "CASTRO VALLEY",
                  "value": "CASTRO VALLEY"
              },
              {
                  "name": "MARTINEZ",
                  "label": "MARTINEZ",
                  "value": "MARTINEZ"
              },
              {
                  "name": "FAIRFIELD",
                  "label": "FAIRFIELD",
                  "value": "FAIRFIELD"
              },
              {
                  "name": "NEWPORT BEACH",
                  "label": "NEWPORT BEACH",
                  "value": "NEWPORT BEACH"
              },
              {
                  "name": "MONTEREY",
                  "label": "MONTEREY",
                  "value": "MONTEREY"
              },
              {
                  "name": "HARBOR CITY",
                  "label": "HARBOR CITY",
                  "value": "HARBOR CITY"
              },
              {
                  "name": "BERKELEY",
                  "label": "BERKELEY",
                  "value": "BERKELEY"
              },
              {
                  "name": "RANCHO MIRAGE",
                  "label": "RANCHO MIRAGE",
                  "value": "RANCHO MIRAGE"
              },
              {
                  "name": "FOUNTAIN VALLEY",
                  "label": "FOUNTAIN VALLEY",
                  "value": "FOUNTAIN VALLEY"
              },
              {
                  "name": "BALDWIN PARK",
                  "label": "BALDWIN PARK",
                  "value": "BALDWIN PARK"
              },
              {
                  "name": "BEVERLY HILLS",
                  "label": "BEVERLY HILLS",
                  "value": "BEVERLY HILLS"
              },
              {
                  "name": "OXNARD",
                  "label": "OXNARD",
                  "value": "OXNARD"
              },
              {
                  "name": "REDWOOD CITY",
                  "label": "REDWOOD CITY",
                  "value": "REDWOOD CITY"
              },
              {
                  "name": "ROSEVILLE",
                  "label": "ROSEVILLE",
                  "value": "ROSEVILLE"
              },
              {
                  "name": "FRENCH CAMP",
                  "label": "FRENCH CAMP",
                  "value": "FRENCH CAMP"
              },
              {
                  "name": "LAGUNA BEACH",
                  "label": "LAGUNA BEACH",
                  "value": "LAGUNA BEACH"
              },
              {
                  "name": "SAN LUIS OBISPO",
                  "label": "SAN LUIS OBISPO",
                  "value": "SAN LUIS OBISPO"
              },
              {
                  "name": "FRESNO",
                  "label": "FRESNO",
                  "value": "FRESNO"
              },
              {
                  "name": "PALO ALTO",
                  "label": "PALO ALTO",
                  "value": "PALO ALTO"
              },
              {
                  "name": "VALLEJO",
                  "label": "VALLEJO",
                  "value": "VALLEJO"
              },
              {
                  "name": "GLENDALE",
                  "label": "GLENDALE",
                  "value": "GLENDALE"
              },
              {
                  "name": "RIVERSIDE",
                  "label": "RIVERSIDE",
                  "value": "RIVERSIDE"
              },
              {
                  "name": "TEMECULA",
                  "label": "TEMECULA",
                  "value": "TEMECULA"
              },
              {
                  "name": "CHINO",
                  "label": "CHINO",
                  "value": "CHINO"
              },
              {
                  "name": "SANTA CRUZ",
                  "label": "SANTA CRUZ",
                  "value": "SANTA CRUZ"
              },
              {
                  "name": "VAN NUYS",
                  "label": "VAN NUYS",
                  "value": "VAN NUYS"
              },
              {
                  "name": "MOUNTAIN VIEW",
                  "label": "MOUNTAIN VIEW",
                  "value": "MOUNTAIN VIEW"
              },
              {
                  "name": "CAMERON PARK",
                  "label": "CAMERON PARK",
                  "value": "CAMERON PARK"
              },
              {
                  "name": "MARYSVILLE",
                  "label": "MARYSVILLE",
                  "value": "MARYSVILLE"
              },
              {
                  "name": "SANTA MONICA",
                  "label": "SANTA MONICA",
                  "value": "SANTA MONICA"
              },
              {
                  "name": "TUSTIN",
                  "label": "TUSTIN",
                  "value": "TUSTIN"
              },
              {
                  "name": "HAYWARD",
                  "label": "HAYWARD",
                  "value": "HAYWARD"
              },
              {
                  "name": "COLTON",
                  "label": "COLTON",
                  "value": "COLTON"
              },
              {
                  "name": "GLENDORA",
                  "label": "GLENDORA",
                  "value": "GLENDORA"
              },
              {
                  "name": "PALMDALE",
                  "label": "PALMDALE",
                  "value": "PALMDALE"
              },
              {
                  "name": "WALNUT CREEK",
                  "label": "WALNUT CREEK",
                  "value": "WALNUT CREEK"
              },
              {
                  "name": "CALABASAS",
                  "label": "CALABASAS",
                  "value": "CALABASAS"
              },
              {
                  "name": "LAGUNA HILLS",
                  "label": "LAGUNA HILLS",
                  "value": "LAGUNA HILLS"
              },
              {
                  "name": "HUNTINGTON BEACH",
                  "label": "HUNTINGTON BEACH",
                  "value": "HUNTINGTON BEACH"
              },
              {
                  "name": "SANTA CLARA",
                  "label": "SANTA CLARA",
                  "value": "SANTA CLARA"
              },
              {
                  "name": "LOSANGELES",
                  "label": "LOSANGELES",
                  "value": "LOSANGELES"
              },
              {
                  "name": "MODESTO",
                  "label": "MODESTO",
                  "value": "MODESTO"
              },
              {
                  "name": "MERCED",
                  "label": "MERCED",
                  "value": "MERCED"
              },
              {
                  "name": "BELLFLOWER",
                  "label": "BELLFLOWER",
                  "value": "BELLFLOWER"
              },
              {
                  "name": "LA MESA",
                  "label": "LA MESA",
                  "value": "LA MESA"
              },
              {
                  "name": "CORONA",
                  "label": "CORONA",
                  "value": "CORONA"
              },
              {
                  "name": "SYLMAR",
                  "label": "SYLMAR",
                  "value": "SYLMAR"
              },
              {
                  "name": "MORENO VALLEY",
                  "label": "MORENO VALLEY",
                  "value": "MORENO VALLEY"
              },
              {
                  "name": "VISALIA",
                  "label": "VISALIA",
                  "value": "VISALIA"
              },
              {
                  "name": "EL CENTRO",
                  "label": "EL CENTRO",
                  "value": "EL CENTRO"
              },
              {
                  "name": "SOUTH PASADENA",
                  "label": "SOUTH PASADENA",
                  "value": "SOUTH PASADENA"
              },
              {
                  "name": "ANTIOCH",
                  "label": "ANTIOCH",
                  "value": "ANTIOCH"
              },
              {
                  "name": "PASADENA",
                  "label": "PASADENA",
                  "value": "PASADENA"
              },
              {
                  "name": "WEST COVINA",
                  "label": "WEST COVINA",
                  "value": "WEST COVINA"
              },
              {
                  "name": "SEAL BEACH",
                  "label": "SEAL BEACH",
                  "value": "SEAL BEACH"
              },
              {
                  "name": "PIEDMONT",
                  "label": "PIEDMONT",
                  "value": "PIEDMONT"
              },
              {
                  "name": "RICHMOND",
                  "label": "RICHMOND",
                  "value": "RICHMOND"
              },
              {
                  "name": "GILROY",
                  "label": "GILROY",
                  "value": "GILROY"
              },
              {
                  "name": "SAN MARCOS",
                  "label": "SAN MARCOS",
                  "value": "SAN MARCOS"
              },
              {
                  "name": "MISSION VIEJO",
                  "label": "MISSION VIEJO",
                  "value": "MISSION VIEJO"
              },
              {
                  "name": "LYNWOOD",
                  "label": "LYNWOOD",
                  "value": "LYNWOOD"
              },
              {
                  "name": "PANORAMA CITY",
                  "label": "PANORAMA CITY",
                  "value": "PANORAMA CITY"
              },
              {
                  "name": "FULLERTON",
                  "label": "FULLERTON",
                  "value": "FULLERTON"
              },
              {
                  "name": "SOLVANG",
                  "label": "SOLVANG",
                  "value": "SOLVANG"
              },
              {
                  "name": "VALENCIA",
                  "label": "VALENCIA",
                  "value": "VALENCIA"
              },
              {
                  "name": "MATHER",
                  "label": "MATHER",
                  "value": "MATHER"
              },
              {
                  "name": "EL SEGUNDO",
                  "label": "EL SEGUNDO",
                  "value": "EL SEGUNDO"
              },
              {
                  "name": "BELMONT",
                  "label": "BELMONT",
                  "value": "BELMONT"
              },
              {
                  "name": "Paramount",
                  "label": "Paramount",
                  "value": "Paramount"
              },
              {
                  "name": "VICTORVILLE",
                  "label": "VICTORVILLE",
                  "value": "VICTORVILLE"
              },
              {
                  "name": "UKIAH",
                  "label": "UKIAH",
                  "value": "UKIAH"
              },
              {
                  "name": "WALNUT",
                  "label": "WALNUT",
                  "value": "WALNUT"
              },
              {
                  "name": "EUREKA",
                  "label": "EUREKA",
                  "value": "EUREKA"
              },
              {
                  "name": "SANTA ANA",
                  "label": "SANTA ANA",
                  "value": "SANTA ANA"
              },
              {
                  "name": "CHULA VISTA",
                  "label": "CHULA VISTA",
                  "value": "CHULA VISTA"
              },
              {
                  "name": "BREA",
                  "label": "BREA",
                  "value": "BREA"
              },
              {
                  "name": "WHITTIER",
                  "label": "WHITTIER",
                  "value": "WHITTIER"
              },
              {
                  "name": "FREMONT",
                  "label": "FREMONT",
                  "value": "FREMONT"
              },
              {
                  "name": "SAN MATEO",
                  "label": "SAN MATEO",
                  "value": "SAN MATEO"
              },
              {
                  "name": "CUPERTINO",
                  "label": "CUPERTINO",
                  "value": "CUPERTINO"
              },
              {
                  "name": "ARCADIA",
                  "label": "ARCADIA",
                  "value": "ARCADIA"
              },
              {
                  "name": "LA CANADA FLINTRIDGE",
                  "label": "LA CANADA FLINTRIDGE",
                  "value": "LA CANADA FLINTRIDGE"
              },
              {
                  "name": "WINTERHAVEN",
                  "label": "WINTERHAVEN",
                  "value": "WINTERHAVEN"
              },
              {
                  "name": "CLOVIS",
                  "label": "CLOVIS",
                  "value": "CLOVIS"
              },
              {
                  "name": "THOUSAND OAKS",
                  "label": "THOUSAND OAKS",
                  "value": "THOUSAND OAKS"
              },
              {
                  "name": "SONORA",
                  "label": "SONORA",
                  "value": "SONORA"
              },
              {
                  "name": "SAN RAMON",
                  "label": "SAN RAMON",
                  "value": "SAN RAMON"
              },
              {
                  "name": "PACIFIC PALISADES",
                  "label": "PACIFIC PALISADES",
                  "value": "PACIFIC PALISADES"
              },
              {
                  "name": "WEST HILLS",
                  "label": "WEST HILLS",
                  "value": "WEST HILLS"
              },
              {
                  "name": "ALISO VIEJO",
                  "label": "ALISO VIEJO",
                  "value": "ALISO VIEJO"
              },
              {
                  "name": "CARMICHAEL",
                  "label": "CARMICHAEL",
                  "value": "CARMICHAEL"
              },
              {
                  "name": "NORTHRIDGE",
                  "label": "NORTHRIDGE",
                  "value": "NORTHRIDGE"
              },
              {
                  "name": "CANOGA PARK",
                  "label": "CANOGA PARK",
                  "value": "CANOGA PARK"
              },
              {
                  "name": "DALY CITY",
                  "label": "DALY CITY",
                  "value": "DALY CITY"
              },
              {
                  "name": "TARZANA",
                  "label": "TARZANA",
                  "value": "TARZANA"
              },
              {
                  "name": "YUBA CITY",
                  "label": "YUBA CITY",
                  "value": "YUBA CITY"
              },
              {
                  "name": "MURRIETA",
                  "label": "MURRIETA",
                  "value": "MURRIETA"
              },
              {
                  "name": "PALM SPRINGS",
                  "label": "PALM SPRINGS",
                  "value": "PALM SPRINGS"
              },
              {
                  "name": "MISSION HILLS",
                  "label": "MISSION HILLS",
                  "value": "MISSION HILLS"
              },
              {
                  "name": "SACRAMNETO",
                  "label": "SACRAMNETO",
                  "value": "SACRAMNETO"
              },
              {
                  "name": "LAKEWOOD",
                  "label": "LAKEWOOD",
                  "value": "LAKEWOOD"
              },
              {
                  "name": "CONCORD",
                  "label": "CONCORD",
                  "value": "CONCORD"
              },
              {
                  "name": "MONTEREY PARK",
                  "label": "MONTEREY PARK",
                  "value": "MONTEREY PARK"
              },
              {
                  "name": "ALHAMBRA",
                  "label": "ALHAMBRA",
                  "value": "ALHAMBRA"
              },
              {
                  "name": "FOLSOM",
                  "label": "FOLSOM",
                  "value": "FOLSOM"
              },
              {
                  "name": "GREENBRAE",
                  "label": "GREENBRAE",
                  "value": "GREENBRAE"
              },
              {
                  "name": "ESCONDIDO",
                  "label": "ESCONDIDO",
                  "value": "ESCONDIDO"
              },
              {
                  "name": "POMONA",
                  "label": "POMONA",
                  "value": "POMONA"
              },
              {
                  "name": "WOODLAND",
                  "label": "WOODLAND",
                  "value": "WOODLAND"
              },
              {
                  "name": "MONTEBELLO",
                  "label": "MONTEBELLO",
                  "value": "MONTEBELLO"
              },
              {
                  "name": "PLEASANTON",
                  "label": "PLEASANTON",
                  "value": "PLEASANTON"
              },
              {
                  "name": "TULARE",
                  "label": "TULARE",
                  "value": "TULARE"
              },
              {
                  "name": "VENTURA",
                  "label": "VENTURA",
                  "value": "VENTURA"
              },
              {
                  "name": "NAPA",
                  "label": "NAPA",
                  "value": "NAPA"
              },
              {
                  "name": "FORT BRAGG",
                  "label": "FORT BRAGG",
                  "value": "FORT BRAGG"
              },
              {
                  "name": "AUBURN",
                  "label": "AUBURN",
                  "value": "AUBURN"
              },
              {
                  "name": "UPLAND",
                  "label": "UPLAND",
                  "value": "UPLAND"
              },
              {
                  "name": "SAINT HELENA",
                  "label": "SAINT HELENA",
                  "value": "SAINT HELENA"
              },
              {
                  "name": "CARLSBAD",
                  "label": "CARLSBAD",
                  "value": "CARLSBAD"
              },
              {
                  "name": "REDONDO BEACH",
                  "label": "REDONDO BEACH",
                  "value": "REDONDO BEACH"
              },
              {
                  "name": "WESTLAKE VILLAGE",
                  "label": "WESTLAKE VILLAGE",
                  "value": "WESTLAKE VILLAGE"
              },
              {
                  "name": "ROCKLIN",
                  "label": "ROCKLIN",
                  "value": "ROCKLIN"
              },
              {
                  "name": "CLAREMONT",
                  "label": "CLAREMONT",
                  "value": "CLAREMONT"
              },
              {
                  "name": "GROVER BEACH",
                  "label": "GROVER BEACH",
                  "value": "GROVER BEACH"
              },
              {
                  "name": "GRASS VALLEY",
                  "label": "GRASS VALLEY",
                  "value": "GRASS VALLEY"
              },
              {
                  "name": "CLEARLAKE",
                  "label": "CLEARLAKE",
                  "value": "CLEARLAKE"
              },
              {
                  "name": "LOS GATOS",
                  "label": "LOS GATOS",
                  "value": "LOS GATOS"
              },
              {
                  "name": "TRUCKEE",
                  "label": "TRUCKEE",
                  "value": "TRUCKEE"
              },
              {
                  "name": "TURLOCK",
                  "label": "TURLOCK",
                  "value": "TURLOCK"
              },
              {
                  "name": "MARINA DEL REY",
                  "label": "MARINA DEL REY",
                  "value": "MARINA DEL REY"
              },
              {
                  "name": "GARDEN GROVE",
                  "label": "GARDEN GROVE",
                  "value": "GARDEN GROVE"
              },
              {
                  "name": "HANFORD",
                  "label": "HANFORD",
                  "value": "HANFORD"
              },
              {
                  "name": "LOMPOC",
                  "label": "LOMPOC",
                  "value": "LOMPOC"
              },
              {
                  "name": "SAN RAFAEL",
                  "label": "SAN RAFAEL",
                  "value": "SAN RAFAEL"
              },
              {
                  "name": "BARSTOW",
                  "label": "BARSTOW",
                  "value": "BARSTOW"
              },
              {
                  "name": "CHINO HILLS",
                  "label": "CHINO HILLS",
                  "value": "CHINO HILLS"
              },
              {
                  "name": "REDLANDS",
                  "label": "REDLANDS",
                  "value": "REDLANDS"
              },
              {
                  "name": "CRESCENT CITY",
                  "label": "CRESCENT CITY",
                  "value": "CRESCENT CITY"
              },
              {
                  "name": "SHERMAN OAKS",
                  "label": "SHERMAN OAKS",
                  "value": "SHERMAN OAKS"
              },
              {
                  "name": "MANTECA",
                  "label": "MANTECA",
                  "value": "MANTECA"
              },
              {
                  "name": "BALDWIN HILLS",
                  "label": "BALDWIN HILLS",
                  "value": "BALDWIN HILLS"
              },
              {
                  "name": "APTOS",
                  "label": "APTOS",
                  "value": "APTOS"
              },
              {
                  "name": "MANHATTAN BEACH",
                  "label": "MANHATTAN BEACH",
                  "value": "MANHATTAN BEACH"
              },
              {
                  "name": "INGLEWOOD",
                  "label": "INGLEWOOD",
                  "value": "INGLEWOOD"
              },
              {
                  "name": "ARROYO GRANDE",
                  "label": "ARROYO GRANDE",
                  "value": "ARROYO GRANDE"
              },
              {
                  "name": "MENLO PARK",
                  "label": "MENLO PARK",
                  "value": "MENLO PARK"
              },
              {
                  "name": "ALAMEDA",
                  "label": "ALAMEDA",
                  "value": "ALAMEDA"
              },
              {
                  "name": "JACKSON",
                  "label": "JACKSON",
                  "value": "JACKSON"
              },
              {
                  "name": "RANCHO CORDOVA",
                  "label": "RANCHO CORDOVA",
                  "value": "RANCHO CORDOVA"
              },
              {
                  "name": "PORTER RANCH",
                  "label": "PORTER RANCH",
                  "value": "PORTER RANCH"
              },
              {
                  "name": "MOKELUMNE HILL",
                  "label": "MOKELUMNE HILL",
                  "value": "MOKELUMNE HILL"
              },
              {
                  "name": "OROVILLE",
                  "label": "OROVILLE",
                  "value": "OROVILLE"
              },
              {
                  "name": "NEWBURY PARK",
                  "label": "NEWBURY PARK",
                  "value": "NEWBURY PARK"
              },
              {
                  "name": "PACIFICA",
                  "label": "PACIFICA",
                  "value": "PACIFICA"
              },
              {
                  "name": "ELK GROVE",
                  "label": "ELK GROVE",
                  "value": "ELK GROVE"
              },
              {
                  "name": "SAN LEANDRO",
                  "label": "SAN LEANDRO",
                  "value": "SAN LEANDRO"
              },
              {
                  "name": "BURLINGAME",
                  "label": "BURLINGAME",
                  "value": "BURLINGAME"
              },
              {
                  "name": "BEAUMONT",
                  "label": "BEAUMONT",
                  "value": "BEAUMONT"
              },
              {
                  "name": "DIXON",
                  "label": "DIXON",
                  "value": "DIXON"
              },
              {
                  "name": "RED BLUFF",
                  "label": "RED BLUFF",
                  "value": "RED BLUFF"
              },
              {
                  "name": "LAGUNA HILL",
                  "label": "LAGUNA HILL",
                  "value": "LAGUNA HILL"
              },
              {
                  "name": "FAIR OAKS",
                  "label": "FAIR OAKS",
                  "value": "FAIR OAKS"
              },
              {
                  "name": "ROSEMEAD",
                  "label": "ROSEMEAD",
                  "value": "ROSEMEAD"
              },
              {
                  "name": "LIVERMORE",
                  "label": "LIVERMORE",
                  "value": "LIVERMORE"
              },
              {
                  "name": "LODI",
                  "label": "LODI",
                  "value": "LODI"
              },
              {
                  "name": "DANA POINT",
                  "label": "DANA POINT",
                  "value": "DANA POINT"
              },
              {
                  "name": "SAN CLEMENTE",
                  "label": "SAN CLEMENTE",
                  "value": "SAN CLEMENTE"
              },
              {
                  "name": "NORCO",
                  "label": "NORCO",
                  "value": "NORCO"
              },
              {
                  "name": "JOSHUA TREE",
                  "label": "JOSHUA TREE",
                  "value": "JOSHUA TREE"
              },
              {
                  "name": "VISTA",
                  "label": "VISTA",
                  "value": "VISTA"
              },
              {
                  "name": "FOSTER CITY",
                  "label": "FOSTER CITY",
                  "value": "FOSTER CITY"
              },
              {
                  "name": "AGOURA HILLS",
                  "label": "AGOURA HILLS",
                  "value": "AGOURA HILLS"
              },
              {
                  "name": "NATIONAL CITY",
                  "label": "NATIONAL CITY",
                  "value": "NATIONAL CITY"
              },
              {
                  "name": "EL MONTE",
                  "label": "EL MONTE",
                  "value": "EL MONTE"
              },
              {
                  "name": "BRAWLEY",
                  "label": "BRAWLEY",
                  "value": "BRAWLEY"
              },
              {
                  "name": "RANCHOCUCOMONGA",
                  "label": "RANCHOCUCOMONGA",
                  "value": "RANCHOCUCOMONGA"
              },
              {
                  "name": "CAMARILLO",
                  "label": "CAMARILLO",
                  "value": "CAMARILLO"
              },
              {
                  "name": "DANVILLE",
                  "label": "DANVILLE",
                  "value": "DANVILLE"
              },
              {
                  "name": "RANCHO CUCAMONG",
                  "label": "RANCHO CUCAMONG",
                  "value": "RANCHO CUCAMONG"
              },
              {
                  "name": "ATHERTON",
                  "label": "ATHERTON",
                  "value": "ATHERTON"
              },
              {
                  "name": "NIPOMO",
                  "label": "NIPOMO",
                  "value": "NIPOMO"
              },
              {
                  "name": "NORTH HOLLYWOOD",
                  "label": "NORTH HOLLYWOOD",
                  "value": "NORTH HOLLYWOOD"
              },
              {
                  "name": "TEMPLETON",
                  "label": "TEMPLETON",
                  "value": "TEMPLETON"
              },
              {
                  "name": "SOLEDAD",
                  "label": "SOLEDAD",
                  "value": "SOLEDAD"
              }
          ]
      },
      {
          "name": "ID",
          "label": "Idaho",
          "value": "ID",
          "cities": [
              {
                  "name": "BOISE",
                  "label": "BOISE",
                  "value": "BOISE"
              },
              {
                  "name": "COEUR D ALENE",
                  "label": "COEUR D ALENE",
                  "value": "COEUR D ALENE"
              },
              {
                  "name": "Meridian",
                  "label": "Meridian",
                  "value": "Meridian"
              },
              {
                  "name": "TWIN FALLS",
                  "label": "TWIN FALLS",
                  "value": "TWIN FALLS"
              },
              {
                  "name": "IDAHO FALLS",
                  "label": "IDAHO FALLS",
                  "value": "IDAHO FALLS"
              },
              {
                  "name": "NAMPA",
                  "label": "NAMPA",
                  "value": "NAMPA"
              },
              {
                  "name": "COEUR D'ALENE",
                  "label": "COEUR D'ALENE",
                  "value": "COEUR D'ALENE"
              },
              {
                  "name": "LEWISTON",
                  "label": "LEWISTON",
                  "value": "LEWISTON"
              },
              {
                  "name": "REXBURG",
                  "label": "REXBURG",
                  "value": "REXBURG"
              },
              {
                  "name": "SANDPOINT",
                  "label": "SANDPOINT",
                  "value": "SANDPOINT"
              },
              {
                  "name": "CALDWELL",
                  "label": "CALDWELL",
                  "value": "CALDWELL"
              },
              {
                  "name": "POCATELLO",
                  "label": "POCATELLO",
                  "value": "POCATELLO"
              },
              {
                  "name": "POST FALLS",
                  "label": "POST FALLS",
                  "value": "POST FALLS"
              },
              {
                  "name": "DRIGGS",
                  "label": "DRIGGS",
                  "value": "DRIGGS"
              }
          ]
      },
      {
          "name": "WA",
          "label": "Washington",
          "value": "WA",
          "cities": [
              {
                  "name": "VANCOUVER",
                  "label": "VANCOUVER",
                  "value": "VANCOUVER"
              },
              {
                  "name": "SEATTLE",
                  "label": "SEATTLE",
                  "value": "SEATTLE"
              },
              {
                  "name": "TACOMA",
                  "label": "TACOMA",
                  "value": "TACOMA"
              },
              {
                  "name": "EVERETT",
                  "label": "EVERETT",
                  "value": "EVERETT"
              },
              {
                  "name": "JOINT BASE LEWI",
                  "label": "JOINT BASE LEWI",
                  "value": "JOINT BASE LEWI"
              },
              {
                  "name": "PORT TOWNSEND",
                  "label": "PORT TOWNSEND",
                  "value": "PORT TOWNSEND"
              },
              {
                  "name": "SUNNYSIDE",
                  "label": "SUNNYSIDE",
                  "value": "SUNNYSIDE"
              },
              {
                  "name": "OLYMPIA",
                  "label": "OLYMPIA",
                  "value": "OLYMPIA"
              },
              {
                  "name": "RICHLAND",
                  "label": "RICHLAND",
                  "value": "RICHLAND"
              },
              {
                  "name": "SPOKANE",
                  "label": "SPOKANE",
                  "value": "SPOKANE"
              },
              {
                  "name": "PUYALLUP",
                  "label": "PUYALLUP",
                  "value": "PUYALLUP"
              },
              {
                  "name": "PORT ORCHARD",
                  "label": "PORT ORCHARD",
                  "value": "PORT ORCHARD"
              },
              {
                  "name": "POULSBO",
                  "label": "POULSBO",
                  "value": "POULSBO"
              },
              {
                  "name": "FEDERAL WAY",
                  "label": "FEDERAL WAY",
                  "value": "FEDERAL WAY"
              },
              {
                  "name": "KIRKLAND",
                  "label": "KIRKLAND",
                  "value": "KIRKLAND"
              },
              {
                  "name": "LONGVIEW",
                  "label": "LONGVIEW",
                  "value": "LONGVIEW"
              },
              {
                  "name": "RENTON",
                  "label": "RENTON",
                  "value": "RENTON"
              },
              {
                  "name": "WALLA WALLA",
                  "label": "WALLA WALLA",
                  "value": "WALLA WALLA"
              },
              {
                  "name": "YAKIMA",
                  "label": "YAKIMA",
                  "value": "YAKIMA"
              },
              {
                  "name": "BELLINGHAM",
                  "label": "BELLINGHAM",
                  "value": "BELLINGHAM"
              },
              {
                  "name": "LYNNWOOD",
                  "label": "LYNNWOOD",
                  "value": "LYNNWOOD"
              },
              {
                  "name": "BREMERTON",
                  "label": "BREMERTON",
                  "value": "BREMERTON"
              },
              {
                  "name": "MOUNT VERNON",
                  "label": "MOUNT VERNON",
                  "value": "MOUNT VERNON"
              },
              {
                  "name": "CENTRALIA",
                  "label": "CENTRALIA",
                  "value": "CENTRALIA"
              },
              {
                  "name": "BELLEVUE",
                  "label": "BELLEVUE",
                  "value": "BELLEVUE"
              },
              {
                  "name": "AUBURN",
                  "label": "AUBURN",
                  "value": "AUBURN"
              },
              {
                  "name": "COLFAX",
                  "label": "COLFAX",
                  "value": "COLFAX"
              },
              {
                  "name": "EDMONDS",
                  "label": "EDMONDS",
                  "value": "EDMONDS"
              },
              {
                  "name": "KENNEWICK",
                  "label": "KENNEWICK",
                  "value": "KENNEWICK"
              },
              {
                  "name": "COUPEVILLE",
                  "label": "COUPEVILLE",
                  "value": "COUPEVILLE"
              },
              {
                  "name": "FIFE",
                  "label": "FIFE",
                  "value": "FIFE"
              },
              {
                  "name": "SEQUIM",
                  "label": "SEQUIM",
                  "value": "SEQUIM"
              },
              {
                  "name": "SEDRO WOOLLEY",
                  "label": "SEDRO WOOLLEY",
                  "value": "SEDRO WOOLLEY"
              },
              {
                  "name": "WENATCHEE",
                  "label": "WENATCHEE",
                  "value": "WENATCHEE"
              },
              {
                  "name": "LAKEWOOD",
                  "label": "LAKEWOOD",
                  "value": "LAKEWOOD"
              },
              {
                  "name": "CHATTAROY",
                  "label": "CHATTAROY",
                  "value": "CHATTAROY"
              },
              {
                  "name": "ISSAQUAH",
                  "label": "ISSAQUAH",
                  "value": "ISSAQUAH"
              },
              {
                  "name": "LACEY",
                  "label": "LACEY",
                  "value": "LACEY"
              },
              {
                  "name": "BURIEN",
                  "label": "BURIEN",
                  "value": "BURIEN"
              },
              {
                  "name": "RITZVILLE",
                  "label": "RITZVILLE",
                  "value": "RITZVILLE"
              },
              {
                  "name": "NEAH BAY",
                  "label": "NEAH BAY",
                  "value": "NEAH BAY"
              },
              {
                  "name": "BOTHELL",
                  "label": "BOTHELL",
                  "value": "BOTHELL"
              },
              {
                  "name": "WHITE SALMON",
                  "label": "WHITE SALMON",
                  "value": "WHITE SALMON"
              },
              {
                  "name": "SILVERDALE",
                  "label": "SILVERDALE",
                  "value": "SILVERDALE"
              },
              {
                  "name": "ANACORTES",
                  "label": "ANACORTES",
                  "value": "ANACORTES"
              },
              {
                  "name": "ABERDEEN",
                  "label": "ABERDEEN",
                  "value": "ABERDEEN"
              },
              {
                  "name": "COLLEGE PLACE",
                  "label": "COLLEGE PLACE",
                  "value": "COLLEGE PLACE"
              },
              {
                  "name": "GIG HARBOR",
                  "label": "GIG HARBOR",
                  "value": "GIG HARBOR"
              },
              {
                  "name": "MCCHORD AFB",
                  "label": "MCCHORD AFB",
                  "value": "MCCHORD AFB"
              },
              {
                  "name": "WOODINVILLE",
                  "label": "WOODINVILLE",
                  "value": "WOODINVILLE"
              },
              {
                  "name": "CHEHALIS",
                  "label": "CHEHALIS",
                  "value": "CHEHALIS"
              },
              {
                  "name": "SNOHOMISH",
                  "label": "SNOHOMISH",
                  "value": "SNOHOMISH"
              },
              {
                  "name": "SUMAS",
                  "label": "SUMAS",
                  "value": "SUMAS"
              }
          ]
      },
      {
          "name": "MT",
          "label": "Montana",
          "value": "MT",
          "cities": [
              {
                  "name": "HELENA",
                  "label": "HELENA",
                  "value": "HELENA"
              },
              {
                  "name": "BILLINGS",
                  "label": "BILLINGS",
                  "value": "BILLINGS"
              },
              {
                  "name": "KALISPELL",
                  "label": "KALISPELL",
                  "value": "KALISPELL"
              },
              {
                  "name": "MISSOULA",
                  "label": "MISSOULA",
                  "value": "MISSOULA"
              },
              {
                  "name": "BOZEMAN",
                  "label": "BOZEMAN",
                  "value": "BOZEMAN"
              },
              {
                  "name": "SIDNEY",
                  "label": "SIDNEY",
                  "value": "SIDNEY"
              },
              {
                  "name": "GREAT FALLS",
                  "label": "GREAT FALLS",
                  "value": "GREAT FALLS"
              },
              {
                  "name": "HAVRE",
                  "label": "HAVRE",
                  "value": "HAVRE"
              },
              {
                  "name": "BUTTE",
                  "label": "BUTTE",
                  "value": "BUTTE"
              },
              {
                  "name": "SHERIDAN",
                  "label": "SHERIDAN",
                  "value": "SHERIDAN"
              },
              {
                  "name": "MILES CITY",
                  "label": "MILES CITY",
                  "value": "MILES CITY"
              }
          ]
      },
      {
          "name": "NV",
          "label": "Nevada",
          "value": "NV",
          "cities": [
              {
                  "name": "LAS VEGAS",
                  "label": "LAS VEGAS",
                  "value": "LAS VEGAS"
              },
              {
                  "name": "RENO",
                  "label": "RENO",
                  "value": "RENO"
              },
              {
                  "name": "HENDERSON",
                  "label": "HENDERSON",
                  "value": "HENDERSON"
              },
              {
                  "name": "GARDNERVILLE",
                  "label": "GARDNERVILLE",
                  "value": "GARDNERVILLE"
              },
              {
                  "name": "Carson City",
                  "label": "Carson City",
                  "value": "Carson City"
              },
              {
                  "name": "PAHRUMP",
                  "label": "PAHRUMP",
                  "value": "PAHRUMP"
              },
              {
                  "name": "SPARKS",
                  "label": "SPARKS",
                  "value": "SPARKS"
              },
              {
                  "name": "NELLIS AFB",
                  "label": "NELLIS AFB",
                  "value": "NELLIS AFB"
              },
              {
                  "name": "FERNLEY",
                  "label": "FERNLEY",
                  "value": "FERNLEY"
              }
          ]
      },
      {
          "name": "OR",
          "label": "Oregon",
          "value": "OR",
          "cities": [
              {
                  "name": "CORVALLIS",
                  "label": "CORVALLIS",
                  "value": "CORVALLIS"
              },
              {
                  "name": "SALEM",
                  "label": "SALEM",
                  "value": "SALEM"
              },
              {
                  "name": "HOOD RIVER",
                  "label": "HOOD RIVER",
                  "value": "HOOD RIVER"
              },
              {
                  "name": "BEND",
                  "label": "BEND",
                  "value": "BEND"
              },
              {
                  "name": "MEDFORD",
                  "label": "MEDFORD",
                  "value": "MEDFORD"
              },
              {
                  "name": "THE DALLES",
                  "label": "THE DALLES",
                  "value": "THE DALLES"
              },
              {
                  "name": "EUGENE",
                  "label": "EUGENE",
                  "value": "EUGENE"
              },
              {
                  "name": "PORTLAND",
                  "label": "PORTLAND",
                  "value": "PORTLAND"
              },
              {
                  "name": "GRANTS PASS",
                  "label": "GRANTS PASS",
                  "value": "GRANTS PASS"
              },
              {
                  "name": "OREGON CITY",
                  "label": "OREGON CITY",
                  "value": "OREGON CITY"
              },
              {
                  "name": "ROSEBURG",
                  "label": "ROSEBURG",
                  "value": "ROSEBURG"
              },
              {
                  "name": "TIGARD",
                  "label": "TIGARD",
                  "value": "TIGARD"
              },
              {
                  "name": "COOS BAY",
                  "label": "COOS BAY",
                  "value": "COOS BAY"
              },
              {
                  "name": "KLAMATH FALLS",
                  "label": "KLAMATH FALLS",
                  "value": "KLAMATH FALLS"
              },
              {
                  "name": "PORRTLAND",
                  "label": "PORRTLAND",
                  "value": "PORRTLAND"
              },
              {
                  "name": "LAKE OSWEGO",
                  "label": "LAKE OSWEGO",
                  "value": "LAKE OSWEGO"
              },
              {
                  "name": "ASTORIA",
                  "label": "ASTORIA",
                  "value": "ASTORIA"
              },
              {
                  "name": "CLACKAMAS",
                  "label": "CLACKAMAS",
                  "value": "CLACKAMAS"
              },
              {
                  "name": "FLORENCE",
                  "label": "FLORENCE",
                  "value": "FLORENCE"
              },
              {
                  "name": "PENDLETON",
                  "label": "PENDLETON",
                  "value": "PENDLETON"
              },
              {
                  "name": "LA GRANDE",
                  "label": "LA GRANDE",
                  "value": "LA GRANDE"
              },
              {
                  "name": "SEASIDE",
                  "label": "SEASIDE",
                  "value": "SEASIDE"
              },
              {
                  "name": "MCMINNVILLE",
                  "label": "MCMINNVILLE",
                  "value": "MCMINNVILLE"
              },
              {
                  "name": "NEWBERG",
                  "label": "NEWBERG",
                  "value": "NEWBERG"
              },
              {
                  "name": "JACKSONVILLE",
                  "label": "JACKSONVILLE",
                  "value": "JACKSONVILLE"
              },
              {
                  "name": "BROOKINGS",
                  "label": "BROOKINGS",
                  "value": "BROOKINGS"
              },
              {
                  "name": "HILLSBORO",
                  "label": "HILLSBORO",
                  "value": "HILLSBORO"
              },
              {
                  "name": "TILLAMOOK",
                  "label": "TILLAMOOK",
                  "value": "TILLAMOOK"
              },
              {
                  "name": "NORTH BEND",
                  "label": "NORTH BEND",
                  "value": "NORTH BEND"
              },
              {
                  "name": "BEAVERTON",
                  "label": "BEAVERTON",
                  "value": "BEAVERTON"
              },
              {
                  "name": "BANDON",
                  "label": "BANDON",
                  "value": "BANDON"
              },
              {
                  "name": "TUALATIN",
                  "label": "TUALATIN",
                  "value": "TUALATIN"
              },
              {
                  "name": "COTTAGE GROVE",
                  "label": "COTTAGE GROVE",
                  "value": "COTTAGE GROVE"
              }
          ]
      },
      {
          "name": "AK",
          "label": "Alaska",
          "value": "AK",
          "cities": [
              {
                  "name": "JUNEAU",
                  "label": "JUNEAU",
                  "value": "JUNEAU"
              },
              {
                  "name": "PALMER",
                  "label": "PALMER",
                  "value": "PALMER"
              },
              {
                  "name": "FAIRBANKS",
                  "label": "FAIRBANKS",
                  "value": "FAIRBANKS"
              },
              {
                  "name": "ANCHORAGE",
                  "label": "ANCHORAGE",
                  "value": "ANCHORAGE"
              },
              {
                  "name": "SOLDOTNA",
                  "label": "SOLDOTNA",
                  "value": "SOLDOTNA"
              },
              {
                  "name": "HOMER",
                  "label": "HOMER",
                  "value": "HOMER"
              },
              {
                  "name": "KETCHIKAN",
                  "label": "KETCHIKAN",
                  "value": "KETCHIKAN"
              },
              {
                  "name": "NAKNEK",
                  "label": "NAKNEK",
                  "value": "NAKNEK"
              }
          ]
      },
      {
          "name": "HI",
          "label": "Hawaii",
          "value": "HI",
          "cities": [
              {
                  "name": "HONOLULU",
                  "label": "HONOLULU",
                  "value": "HONOLULU"
              },
              {
                  "name": "HILO",
                  "label": "HILO",
                  "value": "HILO"
              },
              {
                  "name": "TRIPLER ARMY MEDICAL CENTER",
                  "label": "TRIPLER ARMY MEDICAL CENTER",
                  "value": "TRIPLER ARMY MEDICAL CENTER"
              },
              {
                  "name": "WAILUKU",
                  "label": "WAILUKU",
                  "value": "WAILUKU"
              },
              {
                  "name": "PAHOA",
                  "label": "PAHOA",
                  "value": "PAHOA"
              },
              {
                  "name": "JBPHH",
                  "label": "JBPHH",
                  "value": "JBPHH"
              },
              {
                  "name": "KAILUA",
                  "label": "KAILUA",
                  "value": "KAILUA"
              },
              {
                  "name": "AIEA",
                  "label": "AIEA",
                  "value": "AIEA"
              },
              {
                  "name": "KAMUELA",
                  "label": "KAMUELA",
                  "value": "KAMUELA"
              }
          ]
      },
      {
          "name": "WY",
          "label": "Wyoming",
          "value": "WY",
          "cities": [
              {
                  "name": "CODY",
                  "label": "CODY",
                  "value": "CODY"
              },
              {
                  "name": "JACKSON",
                  "label": "JACKSON",
                  "value": "JACKSON"
              },
              {
                  "name": "SHERIDAN",
                  "label": "SHERIDAN",
                  "value": "SHERIDAN"
              },
              {
                  "name": "CHEYENNE",
                  "label": "CHEYENNE",
                  "value": "CHEYENNE"
              },
              {
                  "name": "LARAMIE",
                  "label": "LARAMIE",
                  "value": "LARAMIE"
              },
              {
                  "name": "POWELL",
                  "label": "POWELL",
                  "value": "POWELL"
              },
              {
                  "name": "LANDER",
                  "label": "LANDER",
                  "value": "LANDER"
              },
              {
                  "name": "CASPER",
                  "label": "CASPER",
                  "value": "CASPER"
              }
          ]
      },
      {
          "name": "MP",
          "label": "Northern Mariana Islands",
          "value": "MP",
          "cities": [
              {
                  "name": "SAIPAN",
                  "label": "SAIPAN",
                  "value": "SAIPAN"
              }
          ]
      }
  ],
  "wHEkc": [
      {
          "name": "ALL",
          "label": "ALL",
          "value": "ALL",
          "cities": []
      },
      {
          "name": "CO",
          "label": "Colorado",
          "value": "CO",
          "cities": [
              {
                  "name": "AURORA",
                  "label": "AURORA",
                  "value": "AURORA"
              },
              {
                  "name": "DENVER",
                  "label": "DENVER",
                  "value": "DENVER"
              },
              {
                  "name": "COLORADO SPRINGS",
                  "label": "COLORADO SPRINGS",
                  "value": "COLORADO SPRINGS"
              },
              {
                  "name": "FT COLLINS",
                  "label": "FT COLLINS",
                  "value": "FT COLLINS"
              },
              {
                  "name": "ENGLEWOOD",
                  "label": "ENGLEWOOD",
                  "value": "ENGLEWOOD"
              },
              {
                  "name": "USAF ACADEMY",
                  "label": "USAF ACADEMY",
                  "value": "USAF ACADEMY"
              },
              {
                  "name": "GLENWOOD SPRINGS",
                  "label": "GLENWOOD SPRINGS",
                  "value": "GLENWOOD SPRINGS"
              },
              {
                  "name": "CANON CITY",
                  "label": "CANON CITY",
                  "value": "CANON CITY"
              },
              {
                  "name": "WHEAT RIDGE",
                  "label": "WHEAT RIDGE",
                  "value": "WHEAT RIDGE"
              },
              {
                  "name": "FORT COLLINS",
                  "label": "FORT COLLINS",
                  "value": "FORT COLLINS"
              },
              {
                  "name": "MONTROSE",
                  "label": "MONTROSE",
                  "value": "MONTROSE"
              },
              {
                  "name": "TRINIDAD",
                  "label": "TRINIDAD",
                  "value": "TRINIDAD"
              },
              {
                  "name": "THORNTON",
                  "label": "THORNTON",
                  "value": "THORNTON"
              },
              {
                  "name": "LITTLETON",
                  "label": "LITTLETON",
                  "value": "LITTLETON"
              },
              {
                  "name": "GRAND JUNCTION",
                  "label": "GRAND JUNCTION",
                  "value": "GRAND JUNCTION"
              },
              {
                  "name": "LOVELAND",
                  "label": "LOVELAND",
                  "value": "LOVELAND"
              },
              {
                  "name": "SALIDA",
                  "label": "SALIDA",
                  "value": "SALIDA"
              },
              {
                  "name": "ASPEN",
                  "label": "ASPEN",
                  "value": "ASPEN"
              },
              {
                  "name": "LAKEWOOD",
                  "label": "LAKEWOOD",
                  "value": "LAKEWOOD"
              },
              {
                  "name": "LONGMONT",
                  "label": "LONGMONT",
                  "value": "LONGMONT"
              },
              {
                  "name": "BRIGHTON",
                  "label": "BRIGHTON",
                  "value": "BRIGHTON"
              },
              {
                  "name": "COLORADO SPGS",
                  "label": "COLORADO SPGS",
                  "value": "COLORADO SPGS"
              },
              {
                  "name": "GLENDALE",
                  "label": "GLENDALE",
                  "value": "GLENDALE"
              },
              {
                  "name": "PUEBLO",
                  "label": "PUEBLO",
                  "value": "PUEBLO"
              },
              {
                  "name": "BOULDER",
                  "label": "BOULDER",
                  "value": "BOULDER"
              },
              {
                  "name": "DURANGO",
                  "label": "DURANGO",
                  "value": "DURANGO"
              },
              {
                  "name": "PAGOSA SPRINGS",
                  "label": "PAGOSA SPRINGS",
                  "value": "PAGOSA SPRINGS"
              },
              {
                  "name": "LONE TREE",
                  "label": "LONE TREE",
                  "value": "LONE TREE"
              },
              {
                  "name": "GREENWOOD VILLAGE",
                  "label": "GREENWOOD VILLAGE",
                  "value": "GREENWOOD VILLAGE"
              },
              {
                  "name": "LAFAYETTE",
                  "label": "LAFAYETTE",
                  "value": "LAFAYETTE"
              },
              {
                  "name": "ARVADA",
                  "label": "ARVADA",
                  "value": "ARVADA"
              },
              {
                  "name": "STERLING",
                  "label": "STERLING",
                  "value": "STERLING"
              },
              {
                  "name": "GOLDEN",
                  "label": "GOLDEN",
                  "value": "GOLDEN"
              },
              {
                  "name": "HIGHLANDS RANCH",
                  "label": "HIGHLANDS RANCH",
                  "value": "HIGHLANDS RANCH"
              },
              {
                  "name": "DELTA",
                  "label": "DELTA",
                  "value": "DELTA"
              },
              {
                  "name": "STMBT SPGS",
                  "label": "STMBT SPGS",
                  "value": "STMBT SPGS"
              },
              {
                  "name": "PARKER",
                  "label": "PARKER",
                  "value": "PARKER"
              },
              {
                  "name": "GREELEY",
                  "label": "GREELEY",
                  "value": "GREELEY"
              },
              {
                  "name": "ALAMOSA",
                  "label": "ALAMOSA",
                  "value": "ALAMOSA"
              },
              {
                  "name": "EDWARDS",
                  "label": "EDWARDS",
                  "value": "EDWARDS"
              },
              {
                  "name": "BROOMFIELD",
                  "label": "BROOMFIELD",
                  "value": "BROOMFIELD"
              }
          ]
      },
      {
          "name": "TN",
          "label": "Tennessee",
          "value": "TN",
          "cities": [
              {
                  "name": "MEMPHIS",
                  "label": "MEMPHIS",
                  "value": "MEMPHIS"
              },
              {
                  "name": "GERMANTOWN",
                  "label": "GERMANTOWN",
                  "value": "GERMANTOWN"
              },
              {
                  "name": "JACKSON",
                  "label": "JACKSON",
                  "value": "JACKSON"
              },
              {
                  "name": "BARTLETT",
                  "label": "BARTLETT",
                  "value": "BARTLETT"
              },
              {
                  "name": "DYERSBURG",
                  "label": "DYERSBURG",
                  "value": "DYERSBURG"
              },
              {
                  "name": "UNION CITY",
                  "label": "UNION CITY",
                  "value": "UNION CITY"
              },
              {
                  "name": "COLLIERVILLE",
                  "label": "COLLIERVILLE",
                  "value": "COLLIERVILLE"
              },
              {
                  "name": "SAVANNAH",
                  "label": "SAVANNAH",
                  "value": "SAVANNAH"
              },
              {
                  "name": "PARIS",
                  "label": "PARIS",
                  "value": "PARIS"
              },
              {
                  "name": "COVINGTON",
                  "label": "COVINGTON",
                  "value": "COVINGTON"
              }
          ]
      },
      {
          "name": "TX",
          "label": "Texas",
          "value": "TX",
          "cities": [
              {
                  "name": "BROWNSVILLE",
                  "label": "BROWNSVILLE",
                  "value": "BROWNSVILLE"
              },
              {
                  "name": "SAN ANTONIO",
                  "label": "SAN ANTONIO",
                  "value": "SAN ANTONIO"
              },
              {
                  "name": "CYPRESS",
                  "label": "CYPRESS",
                  "value": "CYPRESS"
              },
              {
                  "name": "HOUSTON",
                  "label": "HOUSTON",
                  "value": "HOUSTON"
              },
              {
                  "name": "ALLEN",
                  "label": "ALLEN",
                  "value": "ALLEN"
              },
              {
                  "name": "DALLAS",
                  "label": "DALLAS",
                  "value": "DALLAS"
              },
              {
                  "name": "EL PASO",
                  "label": "EL PASO",
                  "value": "EL PASO"
              },
              {
                  "name": "IOWA PARK",
                  "label": "IOWA PARK",
                  "value": "IOWA PARK"
              },
              {
                  "name": "AMARILLO",
                  "label": "AMARILLO",
                  "value": "AMARILLO"
              },
              {
                  "name": "MCALLEN",
                  "label": "MCALLEN",
                  "value": "MCALLEN"
              },
              {
                  "name": "PARIS",
                  "label": "PARIS",
                  "value": "PARIS"
              },
              {
                  "name": "SHENANDOAH",
                  "label": "SHENANDOAH",
                  "value": "SHENANDOAH"
              },
              {
                  "name": "LAKE JACKSON",
                  "label": "LAKE JACKSON",
                  "value": "LAKE JACKSON"
              },
              {
                  "name": "TOMBALL",
                  "label": "TOMBALL",
                  "value": "TOMBALL"
              },
              {
                  "name": "EDINBURG",
                  "label": "EDINBURG",
                  "value": "EDINBURG"
              },
              {
                  "name": "THE WOODLANDS",
                  "label": "THE WOODLANDS",
                  "value": "THE WOODLANDS"
              },
              {
                  "name": "LUBBOCK",
                  "label": "LUBBOCK",
                  "value": "LUBBOCK"
              },
              {
                  "name": "FLOWER MOUND",
                  "label": "FLOWER MOUND",
                  "value": "FLOWER MOUND"
              },
              {
                  "name": "FORT WORTH",
                  "label": "FORT WORTH",
                  "value": "FORT WORTH"
              },
              {
                  "name": "BELLAIRE",
                  "label": "BELLAIRE",
                  "value": "BELLAIRE"
              },
              {
                  "name": "ROWLETT",
                  "label": "ROWLETT",
                  "value": "ROWLETT"
              },
              {
                  "name": "PASADENA",
                  "label": "PASADENA",
                  "value": "PASADENA"
              },
              {
                  "name": "AUSTIN",
                  "label": "AUSTIN",
                  "value": "AUSTIN"
              },
              {
                  "name": "FT SAM HOUSTON",
                  "label": "FT SAM HOUSTON",
                  "value": "FT SAM HOUSTON"
              },
              {
                  "name": "BEDFORD",
                  "label": "BEDFORD",
                  "value": "BEDFORD"
              },
              {
                  "name": "SUGAR LAND",
                  "label": "SUGAR LAND",
                  "value": "SUGAR LAND"
              },
              {
                  "name": "ABILENE",
                  "label": "ABILENE",
                  "value": "ABILENE"
              },
              {
                  "name": "HARLINGEN",
                  "label": "HARLINGEN",
                  "value": "HARLINGEN"
              },
              {
                  "name": "TEXARKANA",
                  "label": "TEXARKANA",
                  "value": "TEXARKANA"
              },
              {
                  "name": "WICHITA FALLS",
                  "label": "WICHITA FALLS",
                  "value": "WICHITA FALLS"
              },
              {
                  "name": "TYLER",
                  "label": "TYLER",
                  "value": "TYLER"
              },
              {
                  "name": "DENTON",
                  "label": "DENTON",
                  "value": "DENTON"
              },
              {
                  "name": "DENISON",
                  "label": "DENISON",
                  "value": "DENISON"
              },
              {
                  "name": "WEATHERFORD",
                  "label": "WEATHERFORD",
                  "value": "WEATHERFORD"
              },
              {
                  "name": "WACO",
                  "label": "WACO",
                  "value": "WACO"
              },
              {
                  "name": "PLANO",
                  "label": "PLANO",
                  "value": "PLANO"
              },
              {
                  "name": "IRVING",
                  "label": "IRVING",
                  "value": "IRVING"
              },
              {
                  "name": "NEW BRAUNFELS",
                  "label": "NEW BRAUNFELS",
                  "value": "NEW BRAUNFELS"
              },
              {
                  "name": "ODESSA",
                  "label": "ODESSA",
                  "value": "ODESSA"
              },
              {
                  "name": "COLLEGE STATION",
                  "label": "COLLEGE STATION",
                  "value": "COLLEGE STATION"
              },
              {
                  "name": "PORT ARTHUR",
                  "label": "PORT ARTHUR",
                  "value": "PORT ARTHUR"
              },
              {
                  "name": "ARLINGTON",
                  "label": "ARLINGTON",
                  "value": "ARLINGTON"
              },
              {
                  "name": "FRISCO",
                  "label": "FRISCO",
                  "value": "FRISCO"
              },
              {
                  "name": "CORPUS CHRISTI",
                  "label": "CORPUS CHRISTI",
                  "value": "CORPUS CHRISTI"
              },
              {
                  "name": "MIDLOTHIAN",
                  "label": "MIDLOTHIAN",
                  "value": "MIDLOTHIAN"
              },
              {
                  "name": "BEAUMONT",
                  "label": "BEAUMONT",
                  "value": "BEAUMONT"
              },
              {
                  "name": "BURLESON",
                  "label": "BURLESON",
                  "value": "BURLESON"
              },
              {
                  "name": "RICHARDSON",
                  "label": "RICHARDSON",
                  "value": "RICHARDSON"
              },
              {
                  "name": "LAREDO",
                  "label": "LAREDO",
                  "value": "LAREDO"
              },
              {
                  "name": "ROUND ROCK",
                  "label": "ROUND ROCK",
                  "value": "ROUND ROCK"
              },
              {
                  "name": "KINGWOOD",
                  "label": "KINGWOOD",
                  "value": "KINGWOOD"
              },
              {
                  "name": "ADDISON",
                  "label": "ADDISON",
                  "value": "ADDISON"
              },
              {
                  "name": "BROWNWOOD",
                  "label": "BROWNWOOD",
                  "value": "BROWNWOOD"
              },
              {
                  "name": "SOUTHLAKE",
                  "label": "SOUTHLAKE",
                  "value": "SOUTHLAKE"
              },
              {
                  "name": "TEMPLE",
                  "label": "TEMPLE",
                  "value": "TEMPLE"
              },
              {
                  "name": "KELLER",
                  "label": "KELLER",
                  "value": "KELLER"
              },
              {
                  "name": "VICTORIA",
                  "label": "VICTORIA",
                  "value": "VICTORIA"
              },
              {
                  "name": "LONGVIEW",
                  "label": "LONGVIEW",
                  "value": "LONGVIEW"
              },
              {
                  "name": "MOUNT PLEASANT",
                  "label": "MOUNT PLEASANT",
                  "value": "MOUNT PLEASANT"
              },
              {
                  "name": "HUMBLE",
                  "label": "HUMBLE",
                  "value": "HUMBLE"
              },
              {
                  "name": "GEORGETOWN",
                  "label": "GEORGETOWN",
                  "value": "GEORGETOWN"
              },
              {
                  "name": "LUFKIN",
                  "label": "LUFKIN",
                  "value": "LUFKIN"
              },
              {
                  "name": "MANSFIELD",
                  "label": "MANSFIELD",
                  "value": "MANSFIELD"
              },
              {
                  "name": "PEARLAND",
                  "label": "PEARLAND",
                  "value": "PEARLAND"
              },
              {
                  "name": "LIVE OAK",
                  "label": "LIVE OAK",
                  "value": "LIVE OAK"
              },
              {
                  "name": "MCKINNEY",
                  "label": "MCKINNEY",
                  "value": "MCKINNEY"
              },
              {
                  "name": "GRAPEVINE",
                  "label": "GRAPEVINE",
                  "value": "GRAPEVINE"
              },
              {
                  "name": "WEBSTER",
                  "label": "WEBSTER",
                  "value": "WEBSTER"
              },
              {
                  "name": "MC KINNEY",
                  "label": "MC KINNEY",
                  "value": "MC KINNEY"
              },
              {
                  "name": "KILLEEN",
                  "label": "KILLEEN",
                  "value": "KILLEEN"
              },
              {
                  "name": "KATY",
                  "label": "KATY",
                  "value": "KATY"
              },
              {
                  "name": "ALICE",
                  "label": "ALICE",
                  "value": "ALICE"
              },
              {
                  "name": "MESQUITE",
                  "label": "MESQUITE",
                  "value": "MESQUITE"
              },
              {
                  "name": "FREDERICKSBURG",
                  "label": "FREDERICKSBURG",
                  "value": "FREDERICKSBURG"
              },
              {
                  "name": "BRYAN",
                  "label": "BRYAN",
                  "value": "BRYAN"
              },
              {
                  "name": "WESLACO",
                  "label": "WESLACO",
                  "value": "WESLACO"
              },
              {
                  "name": "LAKEWAY",
                  "label": "LAKEWAY",
                  "value": "LAKEWAY"
              },
              {
                  "name": "NEW CANEY",
                  "label": "NEW CANEY",
                  "value": "NEW CANEY"
              },
              {
                  "name": "DEL RIO",
                  "label": "DEL RIO",
                  "value": "DEL RIO"
              },
              {
                  "name": "KYLE",
                  "label": "KYLE",
                  "value": "KYLE"
              },
              {
                  "name": "GALVESTON",
                  "label": "GALVESTON",
                  "value": "GALVESTON"
              },
              {
                  "name": "ALEDO",
                  "label": "ALEDO",
                  "value": "ALEDO"
              },
              {
                  "name": "CONROE",
                  "label": "CONROE",
                  "value": "CONROE"
              },
              {
                  "name": "MIDLAND",
                  "label": "MIDLAND",
                  "value": "MIDLAND"
              },
              {
                  "name": "HURST",
                  "label": "HURST",
                  "value": "HURST"
              },
              {
                  "name": "WAXAHACHIE",
                  "label": "WAXAHACHIE",
                  "value": "WAXAHACHIE"
              },
              {
                  "name": "NORTH RICHLAND HILL",
                  "label": "NORTH RICHLAND HILL",
                  "value": "NORTH RICHLAND HILL"
              },
              {
                  "name": "BASTROP",
                  "label": "BASTROP",
                  "value": "BASTROP"
              },
              {
                  "name": "HUNTSVILLE",
                  "label": "HUNTSVILLE",
                  "value": "HUNTSVILLE"
              },
              {
                  "name": "DECATUR",
                  "label": "DECATUR",
                  "value": "DECATUR"
              },
              {
                  "name": "TEXAS CITY",
                  "label": "TEXAS CITY",
                  "value": "TEXAS CITY"
              },
              {
                  "name": "SAN ANGELO",
                  "label": "SAN ANGELO",
                  "value": "SAN ANGELO"
              },
              {
                  "name": "CEDAR PARK",
                  "label": "CEDAR PARK",
                  "value": "CEDAR PARK"
              },
              {
                  "name": "ATHENS",
                  "label": "ATHENS",
                  "value": "ATHENS"
              },
              {
                  "name": "CARROLLTON",
                  "label": "CARROLLTON",
                  "value": "CARROLLTON"
              },
              {
                  "name": "GARLAND",
                  "label": "GARLAND",
                  "value": "GARLAND"
              },
              {
                  "name": "KERRVILLE",
                  "label": "KERRVILLE",
                  "value": "KERRVILLE"
              },
              {
                  "name": "MISSOURI CITY",
                  "label": "MISSOURI CITY",
                  "value": "MISSOURI CITY"
              },
              {
                  "name": "EAGLE PASS",
                  "label": "EAGLE PASS",
                  "value": "EAGLE PASS"
              },
              {
                  "name": "CLEBURNE",
                  "label": "CLEBURNE",
                  "value": "CLEBURNE"
              },
              {
                  "name": "GRANBURY",
                  "label": "GRANBURY",
                  "value": "GRANBURY"
              },
              {
                  "name": "FORT SAM HOUSTON",
                  "label": "FORT SAM HOUSTON",
                  "value": "FORT SAM HOUSTON"
              },
              {
                  "name": "GRANDVIEW",
                  "label": "GRANDVIEW",
                  "value": "GRANDVIEW"
              },
              {
                  "name": "HORIZON CITY",
                  "label": "HORIZON CITY",
                  "value": "HORIZON CITY"
              },
              {
                  "name": "BAYTOWN",
                  "label": "BAYTOWN",
                  "value": "BAYTOWN"
              },
              {
                  "name": "UVALDE",
                  "label": "UVALDE",
                  "value": "UVALDE"
              },
              {
                  "name": "SPRING",
                  "label": "SPRING",
                  "value": "SPRING"
              },
              {
                  "name": "SAN MARCOS",
                  "label": "SAN MARCOS",
                  "value": "SAN MARCOS"
              },
              {
                  "name": "PALESTINE",
                  "label": "PALESTINE",
                  "value": "PALESTINE"
              },
              {
                  "name": "DUNCANVILLE",
                  "label": "DUNCANVILLE",
                  "value": "DUNCANVILLE"
              },
              {
                  "name": "LEWISVILLE",
                  "label": "LEWISVILLE",
                  "value": "LEWISVILLE"
              },
              {
                  "name": "ROCKWALL",
                  "label": "ROCKWALL",
                  "value": "ROCKWALL"
              }
          ]
      },
      {
          "name": "LA",
          "label": "Louisiana",
          "value": "LA",
          "cities": [
              {
                  "name": "NEW ORLEANS",
                  "label": "NEW ORLEANS",
                  "value": "NEW ORLEANS"
              },
              {
                  "name": "METAIRIE",
                  "label": "METAIRIE",
                  "value": "METAIRIE"
              },
              {
                  "name": "BATON ROUGE",
                  "label": "BATON ROUGE",
                  "value": "BATON ROUGE"
              },
              {
                  "name": "SHREVEPORT",
                  "label": "SHREVEPORT",
                  "value": "SHREVEPORT"
              },
              {
                  "name": "ALEXANDRIA",
                  "label": "ALEXANDRIA",
                  "value": "ALEXANDRIA"
              },
              {
                  "name": "NEW IBERIA",
                  "label": "NEW IBERIA",
                  "value": "NEW IBERIA"
              },
              {
                  "name": "HAMMOND",
                  "label": "HAMMOND",
                  "value": "HAMMOND"
              },
              {
                  "name": "COVINGTON",
                  "label": "COVINGTON",
                  "value": "COVINGTON"
              },
              {
                  "name": "SLIDELL",
                  "label": "SLIDELL",
                  "value": "SLIDELL"
              },
              {
                  "name": "LAFAYETTE",
                  "label": "LAFAYETTE",
                  "value": "LAFAYETTE"
              },
              {
                  "name": "MARRERO",
                  "label": "MARRERO",
                  "value": "MARRERO"
              },
              {
                  "name": "ABBEVILLE",
                  "label": "ABBEVILLE",
                  "value": "ABBEVILLE"
              },
              {
                  "name": "LEESVILLE",
                  "label": "LEESVILLE",
                  "value": "LEESVILLE"
              },
              {
                  "name": "THIBODAUX",
                  "label": "THIBODAUX",
                  "value": "THIBODAUX"
              },
              {
                  "name": "CROWLEY",
                  "label": "CROWLEY",
                  "value": "CROWLEY"
              },
              {
                  "name": "HOUMA",
                  "label": "HOUMA",
                  "value": "HOUMA"
              },
              {
                  "name": "LAKE CHARLES",
                  "label": "LAKE CHARLES",
                  "value": "LAKE CHARLES"
              },
              {
                  "name": "SPRINGHILL",
                  "label": "SPRINGHILL",
                  "value": "SPRINGHILL"
              },
              {
                  "name": "RUSTON",
                  "label": "RUSTON",
                  "value": "RUSTON"
              },
              {
                  "name": "WINNSBORO",
                  "label": "WINNSBORO",
                  "value": "WINNSBORO"
              },
              {
                  "name": "KENNER",
                  "label": "KENNER",
                  "value": "KENNER"
              },
              {
                  "name": "OPELOUSAS",
                  "label": "OPELOUSAS",
                  "value": "OPELOUSAS"
              },
              {
                  "name": "ZACHARY",
                  "label": "ZACHARY",
                  "value": "ZACHARY"
              },
              {
                  "name": "GRETNA",
                  "label": "GRETNA",
                  "value": "GRETNA"
              },
              {
                  "name": "MAMOU",
                  "label": "MAMOU",
                  "value": "MAMOU"
              },
              {
                  "name": "WEST MONROE",
                  "label": "WEST MONROE",
                  "value": "WEST MONROE"
              },
              {
                  "name": "MONROE",
                  "label": "MONROE",
                  "value": "MONROE"
              },
              {
                  "name": "HARVEY",
                  "label": "HARVEY",
                  "value": "HARVEY"
              },
              {
                  "name": "RACELAND",
                  "label": "RACELAND",
                  "value": "RACELAND"
              }
          ]
      },
      {
          "name": "AZ",
          "label": "Arizona",
          "value": "AZ",
          "cities": [
              {
                  "name": "PHOENIX",
                  "label": "PHOENIX",
                  "value": "PHOENIX"
              },
              {
                  "name": "TUCSON",
                  "label": "TUCSON",
                  "value": "TUCSON"
              },
              {
                  "name": "SCOTTSDALE",
                  "label": "SCOTTSDALE",
                  "value": "SCOTTSDALE"
              },
              {
                  "name": "KEARNY",
                  "label": "KEARNY",
                  "value": "KEARNY"
              },
              {
                  "name": "GILBERT",
                  "label": "GILBERT",
                  "value": "GILBERT"
              },
              {
                  "name": "TEMPE",
                  "label": "TEMPE",
                  "value": "TEMPE"
              },
              {
                  "name": "GLENDALE",
                  "label": "GLENDALE",
                  "value": "GLENDALE"
              },
              {
                  "name": "COTTONWOOD",
                  "label": "COTTONWOOD",
                  "value": "COTTONWOOD"
              },
              {
                  "name": "GREEN VALLEY",
                  "label": "GREEN VALLEY",
                  "value": "GREEN VALLEY"
              },
              {
                  "name": "GOODYEAR",
                  "label": "GOODYEAR",
                  "value": "GOODYEAR"
              },
              {
                  "name": "MESA",
                  "label": "MESA",
                  "value": "MESA"
              },
              {
                  "name": "PEORIA",
                  "label": "PEORIA",
                  "value": "PEORIA"
              },
              {
                  "name": "CHANDLER",
                  "label": "CHANDLER",
                  "value": "CHANDLER"
              },
              {
                  "name": "YUMA",
                  "label": "YUMA",
                  "value": "YUMA"
              },
              {
                  "name": "SIERRA VISTA",
                  "label": "SIERRA VISTA",
                  "value": "SIERRA VISTA"
              },
              {
                  "name": "ANTHEM",
                  "label": "ANTHEM",
                  "value": "ANTHEM"
              },
              {
                  "name": "GLOBE",
                  "label": "GLOBE",
                  "value": "GLOBE"
              },
              {
                  "name": "SURPRISE",
                  "label": "SURPRISE",
                  "value": "SURPRISE"
              },
              {
                  "name": "CHINLE",
                  "label": "CHINLE",
                  "value": "CHINLE"
              },
              {
                  "name": "SUN CITY",
                  "label": "SUN CITY",
                  "value": "SUN CITY"
              },
              {
                  "name": "PRESCOTT VALLEY",
                  "label": "PRESCOTT VALLEY",
                  "value": "PRESCOTT VALLEY"
              },
              {
                  "name": "LAKE HAVASU CITY",
                  "label": "LAKE HAVASU CITY",
                  "value": "LAKE HAVASU CITY"
              },
              {
                  "name": "CASA GRANDE",
                  "label": "CASA GRANDE",
                  "value": "CASA GRANDE"
              },
              {
                  "name": "SUN CITY WEST",
                  "label": "SUN CITY WEST",
                  "value": "SUN CITY WEST"
              },
              {
                  "name": "PARKS",
                  "label": "PARKS",
                  "value": "PARKS"
              },
              {
                  "name": "FLAGSTAFF",
                  "label": "FLAGSTAFF",
                  "value": "FLAGSTAFF"
              },
              {
                  "name": "TUBA CITY",
                  "label": "TUBA CITY",
                  "value": "TUBA CITY"
              },
              {
                  "name": "AVONDALE",
                  "label": "AVONDALE",
                  "value": "AVONDALE"
              },
              {
                  "name": "RED MESA",
                  "label": "RED MESA",
                  "value": "RED MESA"
              },
              {
                  "name": "BULLHEAD CITY",
                  "label": "BULLHEAD CITY",
                  "value": "BULLHEAD CITY"
              },
              {
                  "name": "SELLS",
                  "label": "SELLS",
                  "value": "SELLS"
              },
              {
                  "name": "PRESCOTT",
                  "label": "PRESCOTT",
                  "value": "PRESCOTT"
              },
              {
                  "name": "KINGMAN",
                  "label": "KINGMAN",
                  "value": "KINGMAN"
              }
          ]
      },
      {
          "name": "UT",
          "label": "Utah",
          "value": "UT",
          "cities": [
              {
                  "name": "AMERICAN FORK",
                  "label": "AMERICAN FORK",
                  "value": "AMERICAN FORK"
              },
              {
                  "name": "SALT LAKE CITY",
                  "label": "SALT LAKE CITY",
                  "value": "SALT LAKE CITY"
              },
              {
                  "name": "MURRAY",
                  "label": "MURRAY",
                  "value": "MURRAY"
              },
              {
                  "name": "PROVO",
                  "label": "PROVO",
                  "value": "PROVO"
              },
              {
                  "name": "OGDEN",
                  "label": "OGDEN",
                  "value": "OGDEN"
              },
              {
                  "name": "BOUNTIFUL",
                  "label": "BOUNTIFUL",
                  "value": "BOUNTIFUL"
              },
              {
                  "name": "LOGAN",
                  "label": "LOGAN",
                  "value": "LOGAN"
              },
              {
                  "name": "ST GEORGE",
                  "label": "ST GEORGE",
                  "value": "ST GEORGE"
              },
              {
                  "name": "OREM",
                  "label": "OREM",
                  "value": "OREM"
              },
              {
                  "name": "SAINT GEORGE",
                  "label": "SAINT GEORGE",
                  "value": "SAINT GEORGE"
              },
              {
                  "name": "ST. GEORGE",
                  "label": "ST. GEORGE",
                  "value": "ST. GEORGE"
              },
              {
                  "name": "BRIGHAM CITY",
                  "label": "BRIGHAM CITY",
                  "value": "BRIGHAM CITY"
              },
              {
                  "name": "W VALLEY CITY",
                  "label": "W VALLEY CITY",
                  "value": "W VALLEY CITY"
              },
              {
                  "name": "CLEARFIELD",
                  "label": "CLEARFIELD",
                  "value": "CLEARFIELD"
              },
              {
                  "name": "SARATOGA SPRINGS",
                  "label": "SARATOGA SPRINGS",
                  "value": "SARATOGA SPRINGS"
              },
              {
                  "name": "WEST JORDAN",
                  "label": "WEST JORDAN",
                  "value": "WEST JORDAN"
              },
              {
                  "name": "SOUTH JORDAN",
                  "label": "SOUTH JORDAN",
                  "value": "SOUTH JORDAN"
              }
          ]
      },
      {
          "name": "MS",
          "label": "Mississippi",
          "value": "MS",
          "cities": [
              {
                  "name": "NEW ALBANY",
                  "label": "NEW ALBANY",
                  "value": "NEW ALBANY"
              },
              {
                  "name": "GRENADA",
                  "label": "GRENADA",
                  "value": "GRENADA"
              },
              {
                  "name": "STARKVILLE",
                  "label": "STARKVILLE",
                  "value": "STARKVILLE"
              },
              {
                  "name": "JACKSON",
                  "label": "JACKSON",
                  "value": "JACKSON"
              },
              {
                  "name": "LAUREL",
                  "label": "LAUREL",
                  "value": "LAUREL"
              },
              {
                  "name": "SOUTHAVEN",
                  "label": "SOUTHAVEN",
                  "value": "SOUTHAVEN"
              },
              {
                  "name": "GULFPORT",
                  "label": "GULFPORT",
                  "value": "GULFPORT"
              },
              {
                  "name": "HATTIESBURG",
                  "label": "HATTIESBURG",
                  "value": "HATTIESBURG"
              },
              {
                  "name": "WATER VALLEY",
                  "label": "WATER VALLEY",
                  "value": "WATER VALLEY"
              },
              {
                  "name": "PASCAGOULA",
                  "label": "PASCAGOULA",
                  "value": "PASCAGOULA"
              },
              {
                  "name": "GAUTIER",
                  "label": "GAUTIER",
                  "value": "GAUTIER"
              },
              {
                  "name": "OLIVE BRANCH",
                  "label": "OLIVE BRANCH",
                  "value": "OLIVE BRANCH"
              },
              {
                  "name": "GREENVILLE",
                  "label": "GREENVILLE",
                  "value": "GREENVILLE"
              },
              {
                  "name": "BAY ST LOUIS",
                  "label": "BAY ST LOUIS",
                  "value": "BAY ST LOUIS"
              },
              {
                  "name": "GREENWOOD",
                  "label": "GREENWOOD",
                  "value": "GREENWOOD"
              },
              {
                  "name": "OXFORD",
                  "label": "OXFORD",
                  "value": "OXFORD"
              },
              {
                  "name": "MERIDIAN",
                  "label": "MERIDIAN",
                  "value": "MERIDIAN"
              },
              {
                  "name": "RIDGELAND",
                  "label": "RIDGELAND",
                  "value": "RIDGELAND"
              },
              {
                  "name": "KEESLER AFB",
                  "label": "KEESLER AFB",
                  "value": "KEESLER AFB"
              },
              {
                  "name": "TUPELO",
                  "label": "TUPELO",
                  "value": "TUPELO"
              },
              {
                  "name": "NATCHEZ",
                  "label": "NATCHEZ",
                  "value": "NATCHEZ"
              },
              {
                  "name": "CLARKSDALE",
                  "label": "CLARKSDALE",
                  "value": "CLARKSDALE"
              },
              {
                  "name": "CLINTON",
                  "label": "CLINTON",
                  "value": "CLINTON"
              },
              {
                  "name": "CORINTH",
                  "label": "CORINTH",
                  "value": "CORINTH"
              },
              {
                  "name": "MC COMB",
                  "label": "MC COMB",
                  "value": "MC COMB"
              },
              {
                  "name": "OCEAN SPRINGS",
                  "label": "OCEAN SPRINGS",
                  "value": "OCEAN SPRINGS"
              },
              {
                  "name": "COLUMBUS",
                  "label": "COLUMBUS",
                  "value": "COLUMBUS"
              },
              {
                  "name": "MCCOMB",
                  "label": "MCCOMB",
                  "value": "MCCOMB"
              },
              {
                  "name": "WEST POINT",
                  "label": "WEST POINT",
                  "value": "WEST POINT"
              }
          ]
      },
      {
          "name": "AR",
          "label": "Arkansas",
          "value": "AR",
          "cities": [
              {
                  "name": "JONESBORO",
                  "label": "JONESBORO",
                  "value": "JONESBORO"
              },
              {
                  "name": "LITTLE ROCK",
                  "label": "LITTLE ROCK",
                  "value": "LITTLE ROCK"
              },
              {
                  "name": "ROGERS",
                  "label": "ROGERS",
                  "value": "ROGERS"
              },
              {
                  "name": "SPRINGDALE",
                  "label": "SPRINGDALE",
                  "value": "SPRINGDALE"
              },
              {
                  "name": "FAYETTEVILLE",
                  "label": "FAYETTEVILLE",
                  "value": "FAYETTEVILLE"
              },
              {
                  "name": "HOT SPRINGS NP",
                  "label": "HOT SPRINGS NP",
                  "value": "HOT SPRINGS NP"
              },
              {
                  "name": "BLYTHEVILLE",
                  "label": "BLYTHEVILLE",
                  "value": "BLYTHEVILLE"
              },
              {
                  "name": "CROSSETT",
                  "label": "CROSSETT",
                  "value": "CROSSETT"
              },
              {
                  "name": "HARRISON",
                  "label": "HARRISON",
                  "value": "HARRISON"
              },
              {
                  "name": "FORT SMITH",
                  "label": "FORT SMITH",
                  "value": "FORT SMITH"
              },
              {
                  "name": "NORTH LITTLE ROCK",
                  "label": "NORTH LITTLE ROCK",
                  "value": "NORTH LITTLE ROCK"
              },
              {
                  "name": "WEST MEMPHIS",
                  "label": "WEST MEMPHIS",
                  "value": "WEST MEMPHIS"
              },
              {
                  "name": "HOT SPRINGS",
                  "label": "HOT SPRINGS",
                  "value": "HOT SPRINGS"
              },
              {
                  "name": "BATESVILLE",
                  "label": "BATESVILLE",
                  "value": "BATESVILLE"
              },
              {
                  "name": "RUSSELLVILLE",
                  "label": "RUSSELLVILLE",
                  "value": "RUSSELLVILLE"
              },
              {
                  "name": "MAGNOLIA",
                  "label": "MAGNOLIA",
                  "value": "MAGNOLIA"
              },
              {
                  "name": "SEARCY",
                  "label": "SEARCY",
                  "value": "SEARCY"
              },
              {
                  "name": "MONTICELLO",
                  "label": "MONTICELLO",
                  "value": "MONTICELLO"
              },
              {
                  "name": "PINE BLUFF",
                  "label": "PINE BLUFF",
                  "value": "PINE BLUFF"
              },
              {
                  "name": "BENTON",
                  "label": "BENTON",
                  "value": "BENTON"
              },
              {
                  "name": "SILOAM SPRINGS",
                  "label": "SILOAM SPRINGS",
                  "value": "SILOAM SPRINGS"
              },
              {
                  "name": "NEWPORT",
                  "label": "NEWPORT",
                  "value": "NEWPORT"
              },
              {
                  "name": "LOWELL",
                  "label": "LOWELL",
                  "value": "LOWELL"
              },
              {
                  "name": "CONWAY",
                  "label": "CONWAY",
                  "value": "CONWAY"
              },
              {
                  "name": "EL DORADO",
                  "label": "EL DORADO",
                  "value": "EL DORADO"
              },
              {
                  "name": "MOUNTAIN HOME",
                  "label": "MOUNTAIN HOME",
                  "value": "MOUNTAIN HOME"
              },
              {
                  "name": "BLACK ROCK",
                  "label": "BLACK ROCK",
                  "value": "BLACK ROCK"
              },
              {
                  "name": "BERRYVILLE",
                  "label": "BERRYVILLE",
                  "value": "BERRYVILLE"
              },
              {
                  "name": "JUDSONIA",
                  "label": "JUDSONIA",
                  "value": "JUDSONIA"
              },
              {
                  "name": "BENTONVILLE",
                  "label": "BENTONVILLE",
                  "value": "BENTONVILLE"
              }
          ]
      },
      {
          "name": "KS",
          "label": "Kansas",
          "value": "KS",
          "cities": [
              {
                  "name": "TOPEKA",
                  "label": "TOPEKA",
                  "value": "TOPEKA"
              },
              {
                  "name": "GARDEN CITY",
                  "label": "GARDEN CITY",
                  "value": "GARDEN CITY"
              },
              {
                  "name": "OVERLAND PARK",
                  "label": "OVERLAND PARK",
                  "value": "OVERLAND PARK"
              },
              {
                  "name": "LAWRENCE",
                  "label": "LAWRENCE",
                  "value": "LAWRENCE"
              },
              {
                  "name": "HUTCHINSON",
                  "label": "HUTCHINSON",
                  "value": "HUTCHINSON"
              },
              {
                  "name": "SHAWNEE MISSION",
                  "label": "SHAWNEE MISSION",
                  "value": "SHAWNEE MISSION"
              },
              {
                  "name": "PITTSBURG",
                  "label": "PITTSBURG",
                  "value": "PITTSBURG"
              },
              {
                  "name": "WICHITA",
                  "label": "WICHITA",
                  "value": "WICHITA"
              },
              {
                  "name": "KANSAS CITY",
                  "label": "KANSAS CITY",
                  "value": "KANSAS CITY"
              },
              {
                  "name": "EMPORIA",
                  "label": "EMPORIA",
                  "value": "EMPORIA"
              },
              {
                  "name": "NEWTON",
                  "label": "NEWTON",
                  "value": "NEWTON"
              },
              {
                  "name": "LENEXA",
                  "label": "LENEXA",
                  "value": "LENEXA"
              },
              {
                  "name": "OLATHE",
                  "label": "OLATHE",
                  "value": "OLATHE"
              },
              {
                  "name": "WESTWOOD",
                  "label": "WESTWOOD",
                  "value": "WESTWOOD"
              },
              {
                  "name": "GREAT BEND",
                  "label": "GREAT BEND",
                  "value": "GREAT BEND"
              },
              {
                  "name": "SALINA",
                  "label": "SALINA",
                  "value": "SALINA"
              },
              {
                  "name": "HAYS",
                  "label": "HAYS",
                  "value": "HAYS"
              },
              {
                  "name": "LEAWOOD",
                  "label": "LEAWOOD",
                  "value": "LEAWOOD"
              },
              {
                  "name": "MANHATTAN",
                  "label": "MANHATTAN",
                  "value": "MANHATTAN"
              },
              {
                  "name": "DODGE CITY",
                  "label": "DODGE CITY",
                  "value": "DODGE CITY"
              },
              {
                  "name": "PRATT",
                  "label": "PRATT",
                  "value": "PRATT"
              },
              {
                  "name": "FORT LEAVENWORTH",
                  "label": "FORT LEAVENWORTH",
                  "value": "FORT LEAVENWORTH"
              },
              {
                  "name": "HOISINGTON",
                  "label": "HOISINGTON",
                  "value": "HOISINGTON"
              },
              {
                  "name": "COFFEYVILLE",
                  "label": "COFFEYVILLE",
                  "value": "COFFEYVILLE"
              },
              {
                  "name": "WINFIELD",
                  "label": "WINFIELD",
                  "value": "WINFIELD"
              },
              {
                  "name": "NEODESHA",
                  "label": "NEODESHA",
                  "value": "NEODESHA"
              }
          ]
      },
      {
          "name": "NE",
          "label": "Nebraska",
          "value": "NE",
          "cities": [
              {
                  "name": "KEARNEY",
                  "label": "KEARNEY",
                  "value": "KEARNEY"
              },
              {
                  "name": "GRAND ISLAND",
                  "label": "GRAND ISLAND",
                  "value": "GRAND ISLAND"
              },
              {
                  "name": "LINCOLN",
                  "label": "LINCOLN",
                  "value": "LINCOLN"
              },
              {
                  "name": "OMAHA",
                  "label": "OMAHA",
                  "value": "OMAHA"
              },
              {
                  "name": "SCHUYLER",
                  "label": "SCHUYLER",
                  "value": "SCHUYLER"
              },
              {
                  "name": "NORTH PLATTE",
                  "label": "NORTH PLATTE",
                  "value": "NORTH PLATTE"
              },
              {
                  "name": "NORFOLK",
                  "label": "NORFOLK",
                  "value": "NORFOLK"
              },
              {
                  "name": "HASTINGS",
                  "label": "HASTINGS",
                  "value": "HASTINGS"
              },
              {
                  "name": "BASSETT",
                  "label": "BASSETT",
                  "value": "BASSETT"
              },
              {
                  "name": "HEBRON",
                  "label": "HEBRON",
                  "value": "HEBRON"
              },
              {
                  "name": "FREMONT",
                  "label": "FREMONT",
                  "value": "FREMONT"
              },
              {
                  "name": "BELLEVUE",
                  "label": "BELLEVUE",
                  "value": "BELLEVUE"
              },
              {
                  "name": "PAPILLION",
                  "label": "PAPILLION",
                  "value": "PAPILLION"
              },
              {
                  "name": "LA VISTA",
                  "label": "LA VISTA",
                  "value": "LA VISTA"
              },
              {
                  "name": "BLAIR",
                  "label": "BLAIR",
                  "value": "BLAIR"
              },
              {
                  "name": "ONEILL",
                  "label": "ONEILL",
                  "value": "ONEILL"
              },
              {
                  "name": "SCOTTSBLUFF",
                  "label": "SCOTTSBLUFF",
                  "value": "SCOTTSBLUFF"
              },
              {
                  "name": "GOTHENBURG",
                  "label": "GOTHENBURG",
                  "value": "GOTHENBURG"
              },
              {
                  "name": "MCCOOK",
                  "label": "MCCOOK",
                  "value": "MCCOOK"
              },
              {
                  "name": "RED CLOUD",
                  "label": "RED CLOUD",
                  "value": "RED CLOUD"
              },
              {
                  "name": "VALLEY",
                  "label": "VALLEY",
                  "value": "VALLEY"
              },
              {
                  "name": "HOLDREGE",
                  "label": "HOLDREGE",
                  "value": "HOLDREGE"
              }
          ]
      },
      {
          "name": "OK",
          "label": "Oklahoma",
          "value": "OK",
          "cities": [
              {
                  "name": "OKLAHOMA CITY",
                  "label": "OKLAHOMA CITY",
                  "value": "OKLAHOMA CITY"
              },
              {
                  "name": "LAWTON",
                  "label": "LAWTON",
                  "value": "LAWTON"
              },
              {
                  "name": "TULSA",
                  "label": "TULSA",
                  "value": "TULSA"
              },
              {
                  "name": "MUSKOGEE",
                  "label": "MUSKOGEE",
                  "value": "MUSKOGEE"
              },
              {
                  "name": "ADA",
                  "label": "ADA",
                  "value": "ADA"
              },
              {
                  "name": "ATOKA",
                  "label": "ATOKA",
                  "value": "ATOKA"
              },
              {
                  "name": "BARTLESVILLE",
                  "label": "BARTLESVILLE",
                  "value": "BARTLESVILLE"
              },
              {
                  "name": "TAHLEQUAH",
                  "label": "TAHLEQUAH",
                  "value": "TAHLEQUAH"
              },
              {
                  "name": "NORMAN",
                  "label": "NORMAN",
                  "value": "NORMAN"
              },
              {
                  "name": "STILLWATER",
                  "label": "STILLWATER",
                  "value": "STILLWATER"
              },
              {
                  "name": "ARDMORE",
                  "label": "ARDMORE",
                  "value": "ARDMORE"
              },
              {
                  "name": "MIDWEST CITY",
                  "label": "MIDWEST CITY",
                  "value": "MIDWEST CITY"
              },
              {
                  "name": "POTEAU",
                  "label": "POTEAU",
                  "value": "POTEAU"
              },
              {
                  "name": "SHAWNEE",
                  "label": "SHAWNEE",
                  "value": "SHAWNEE"
              },
              {
                  "name": "MCALESTER",
                  "label": "MCALESTER",
                  "value": "MCALESTER"
              },
              {
                  "name": "EDMOND",
                  "label": "EDMOND",
                  "value": "EDMOND"
              },
              {
                  "name": "SAPULPA",
                  "label": "SAPULPA",
                  "value": "SAPULPA"
              }
          ]
      },
      {
          "name": "NM",
          "label": "New Mexico",
          "value": "NM",
          "cities": [
              {
                  "name": "ALBUQUERQUE",
                  "label": "ALBUQUERQUE",
                  "value": "ALBUQUERQUE"
              },
              {
                  "name": "RIO RANCHO",
                  "label": "RIO RANCHO",
                  "value": "RIO RANCHO"
              },
              {
                  "name": "SILVER CITY",
                  "label": "SILVER CITY",
                  "value": "SILVER CITY"
              },
              {
                  "name": "SUNLAND PARK",
                  "label": "SUNLAND PARK",
                  "value": "SUNLAND PARK"
              },
              {
                  "name": "LAS CRUCES",
                  "label": "LAS CRUCES",
                  "value": "LAS CRUCES"
              },
              {
                  "name": "SANTA FE",
                  "label": "SANTA FE",
                  "value": "SANTA FE"
              },
              {
                  "name": "DEMING",
                  "label": "DEMING",
                  "value": "DEMING"
              },
              {
                  "name": "FARMINGTON",
                  "label": "FARMINGTON",
                  "value": "FARMINGTON"
              },
              {
                  "name": "CARLSBAD",
                  "label": "CARLSBAD",
                  "value": "CARLSBAD"
              },
              {
                  "name": "ROSWELL",
                  "label": "ROSWELL",
                  "value": "ROSWELL"
              },
              {
                  "name": "ANTHONY",
                  "label": "ANTHONY",
                  "value": "ANTHONY"
              },
              {
                  "name": "LOS ALAMOS",
                  "label": "LOS ALAMOS",
                  "value": "LOS ALAMOS"
              },
              {
                  "name": "LOS LUNAS",
                  "label": "LOS LUNAS",
                  "value": "LOS LUNAS"
              },
              {
                  "name": "LOVINGTON",
                  "label": "LOVINGTON",
                  "value": "LOVINGTON"
              },
              {
                  "name": "GALLUP",
                  "label": "GALLUP",
                  "value": "GALLUP"
              },
              {
                  "name": "CLOVIS",
                  "label": "CLOVIS",
                  "value": "CLOVIS"
              },
              {
                  "name": "ALAMOGORDO",
                  "label": "ALAMOGORDO",
                  "value": "ALAMOGORDO"
              }
          ]
      }
  ],
  "GIikw": [
      {
          "name": "ALL",
          "label": "ALL",
          "value": "ALL",
          "cities": []
      },
      {
          "name": "NC",
          "label": "North Carolina",
          "value": "NC",
          "cities": [
              {
                  "name": "GREENSBORO",
                  "label": "GREENSBORO",
                  "value": "GREENSBORO"
              },
              {
                  "name": "CHAPEL HILL",
                  "label": "CHAPEL HILL",
                  "value": "CHAPEL HILL"
              },
              {
                  "name": "DURHAM",
                  "label": "DURHAM",
                  "value": "DURHAM"
              },
              {
                  "name": "WINSTON SALEM",
                  "label": "WINSTON SALEM",
                  "value": "WINSTON SALEM"
              },
              {
                  "name": "FAYETTEVILLE",
                  "label": "FAYETTEVILLE",
                  "value": "FAYETTEVILLE"
              },
              {
                  "name": "RALEIGH",
                  "label": "RALEIGH",
                  "value": "RALEIGH"
              },
              {
                  "name": "CHARLOTTE",
                  "label": "CHARLOTTE",
                  "value": "CHARLOTTE"
              },
              {
                  "name": "ASHEVILLE",
                  "label": "ASHEVILLE",
                  "value": "ASHEVILLE"
              },
              {
                  "name": "GREENVILLE",
                  "label": "GREENVILLE",
                  "value": "GREENVILLE"
              },
              {
                  "name": "MONROE",
                  "label": "MONROE",
                  "value": "MONROE"
              },
              {
                  "name": "MORGANTON",
                  "label": "MORGANTON",
                  "value": "MORGANTON"
              },
              {
                  "name": "NAGS HEAD",
                  "label": "NAGS HEAD",
                  "value": "NAGS HEAD"
              },
              {
                  "name": "BOONE",
                  "label": "BOONE",
                  "value": "BOONE"
              },
              {
                  "name": "REIDSVILLE",
                  "label": "REIDSVILLE",
                  "value": "REIDSVILLE"
              },
              {
                  "name": "HENDERSONVILLE",
                  "label": "HENDERSONVILLE",
                  "value": "HENDERSONVILLE"
              },
              {
                  "name": "LINCOLNTON",
                  "label": "LINCOLNTON",
                  "value": "LINCOLNTON"
              },
              {
                  "name": "CARY",
                  "label": "CARY",
                  "value": "CARY"
              },
              {
                  "name": "PINEHURST",
                  "label": "PINEHURST",
                  "value": "PINEHURST"
              },
              {
                  "name": "GASTONIA",
                  "label": "GASTONIA",
                  "value": "GASTONIA"
              },
              {
                  "name": "EDEN",
                  "label": "EDEN",
                  "value": "EDEN"
              },
              {
                  "name": "HICKORY",
                  "label": "HICKORY",
                  "value": "HICKORY"
              },
              {
                  "name": "N WILKESBORO",
                  "label": "N WILKESBORO",
                  "value": "N WILKESBORO"
              },
              {
                  "name": "CLYDE",
                  "label": "CLYDE",
                  "value": "CLYDE"
              },
              {
                  "name": "WINDSOR",
                  "label": "WINDSOR",
                  "value": "WINDSOR"
              },
              {
                  "name": "HUNTERSVILLE",
                  "label": "HUNTERSVILLE",
                  "value": "HUNTERSVILLE"
              },
              {
                  "name": "HENDERSON",
                  "label": "HENDERSON",
                  "value": "HENDERSON"
              },
              {
                  "name": "HIGH POINT",
                  "label": "HIGH POINT",
                  "value": "HIGH POINT"
              },
              {
                  "name": "WILSON",
                  "label": "WILSON",
                  "value": "WILSON"
              },
              {
                  "name": "STATESVILLE",
                  "label": "STATESVILLE",
                  "value": "STATESVILLE"
              },
              {
                  "name": "ASHEBORO",
                  "label": "ASHEBORO",
                  "value": "ASHEBORO"
              },
              {
                  "name": "SALISBURY",
                  "label": "SALISBURY",
                  "value": "SALISBURY"
              },
              {
                  "name": "THOMASVILLE",
                  "label": "THOMASVILLE",
                  "value": "THOMASVILLE"
              },
              {
                  "name": "MOREHEAD CITY",
                  "label": "MOREHEAD CITY",
                  "value": "MOREHEAD CITY"
              },
              {
                  "name": "BURLINGTON",
                  "label": "BURLINGTON",
                  "value": "BURLINGTON"
              },
              {
                  "name": "SHELBY",
                  "label": "SHELBY",
                  "value": "SHELBY"
              },
              {
                  "name": "CARRBORO",
                  "label": "CARRBORO",
                  "value": "CARRBORO"
              },
              {
                  "name": "WILMINGTON",
                  "label": "WILMINGTON",
                  "value": "WILMINGTON"
              },
              {
                  "name": "NEW BERN",
                  "label": "NEW BERN",
                  "value": "NEW BERN"
              },
              {
                  "name": "LAURINBURG",
                  "label": "LAURINBURG",
                  "value": "LAURINBURG"
              },
              {
                  "name": "ROANOKE RAPIDS",
                  "label": "ROANOKE RAPIDS",
                  "value": "ROANOKE RAPIDS"
              },
              {
                  "name": "GARNER",
                  "label": "GARNER",
                  "value": "GARNER"
              },
              {
                  "name": "CONCORD",
                  "label": "CONCORD",
                  "value": "CONCORD"
              },
              {
                  "name": "MATTHEWS",
                  "label": "MATTHEWS",
                  "value": "MATTHEWS"
              },
              {
                  "name": "GOLDSBORO",
                  "label": "GOLDSBORO",
                  "value": "GOLDSBORO"
              },
              {
                  "name": "NORLINA",
                  "label": "NORLINA",
                  "value": "NORLINA"
              },
              {
                  "name": "MOORESVILLE",
                  "label": "MOORESVILLE",
                  "value": "MOORESVILLE"
              },
              {
                  "name": "LUMBERTON",
                  "label": "LUMBERTON",
                  "value": "LUMBERTON"
              },
              {
                  "name": "VALDESE",
                  "label": "VALDESE",
                  "value": "VALDESE"
              },
              {
                  "name": "CULLOWHEE",
                  "label": "CULLOWHEE",
                  "value": "CULLOWHEE"
              },
              {
                  "name": "KINGS MOUNTAIN",
                  "label": "KINGS MOUNTAIN",
                  "value": "KINGS MOUNTAIN"
              },
              {
                  "name": "CONOVER",
                  "label": "CONOVER",
                  "value": "CONOVER"
              },
              {
                  "name": "MILLS RIVER",
                  "label": "MILLS RIVER",
                  "value": "MILLS RIVER"
              },
              {
                  "name": "ROCKY MOUNT",
                  "label": "ROCKY MOUNT",
                  "value": "ROCKY MOUNT"
              },
              {
                  "name": "SMITHFIELD",
                  "label": "SMITHFIELD",
                  "value": "SMITHFIELD"
              },
              {
                  "name": "MARION",
                  "label": "MARION",
                  "value": "MARION"
              },
              {
                  "name": "WHITEVILLE",
                  "label": "WHITEVILLE",
                  "value": "WHITEVILLE"
              },
              {
                  "name": "CAROLINA BEACH",
                  "label": "CAROLINA BEACH",
                  "value": "CAROLINA BEACH"
              },
              {
                  "name": "KENANSVILLE",
                  "label": "KENANSVILLE",
                  "value": "KENANSVILLE"
              },
              {
                  "name": "SHALLOTTE",
                  "label": "SHALLOTTE",
                  "value": "SHALLOTTE"
              },
              {
                  "name": "KINSTON",
                  "label": "KINSTON",
                  "value": "KINSTON"
              },
              {
                  "name": "LENOIR",
                  "label": "LENOIR",
                  "value": "LENOIR"
              },
              {
                  "name": "JACKSONVILLE",
                  "label": "JACKSONVILLE",
                  "value": "JACKSONVILLE"
              },
              {
                  "name": "NEBO",
                  "label": "NEBO",
                  "value": "NEBO"
              },
              {
                  "name": "MORRISVILLE",
                  "label": "MORRISVILLE",
                  "value": "MORRISVILLE"
              },
              {
                  "name": "FLETCHER",
                  "label": "FLETCHER",
                  "value": "FLETCHER"
              },
              {
                  "name": "CLEMMONS",
                  "label": "CLEMMONS",
                  "value": "CLEMMONS"
              },
              {
                  "name": "LILLINGTON",
                  "label": "LILLINGTON",
                  "value": "LILLINGTON"
              },
              {
                  "name": "ALBEMARLE",
                  "label": "ALBEMARLE",
                  "value": "ALBEMARLE"
              },
              {
                  "name": "KERNERSVILLE",
                  "label": "KERNERSVILLE",
                  "value": "KERNERSVILLE"
              },
              {
                  "name": "ROWLAND",
                  "label": "ROWLAND",
                  "value": "ROWLAND"
              },
              {
                  "name": "SPRUCE PINE",
                  "label": "SPRUCE PINE",
                  "value": "SPRUCE PINE"
              },
              {
                  "name": "NEWPORT",
                  "label": "NEWPORT",
                  "value": "NEWPORT"
              },
              {
                  "name": "ELIZABETH CITY",
                  "label": "ELIZABETH CITY",
                  "value": "ELIZABETH CITY"
              },
              {
                  "name": "FOREST CITY",
                  "label": "FOREST CITY",
                  "value": "FOREST CITY"
              },
              {
                  "name": "Sylva",
                  "label": "Sylva",
                  "value": "Sylva"
              },
              {
                  "name": "LEWISVILLE",
                  "label": "LEWISVILLE",
                  "value": "LEWISVILLE"
              },
              {
                  "name": "AHOSKIE",
                  "label": "AHOSKIE",
                  "value": "AHOSKIE"
              }
          ]
      },
      {
          "name": "FL",
          "label": "Florida",
          "value": "FL",
          "cities": [
              {
                  "name": "CAPE CORAL",
                  "label": "CAPE CORAL",
                  "value": "CAPE CORAL"
              },
              {
                  "name": "MIAMI",
                  "label": "MIAMI",
                  "value": "MIAMI"
              },
              {
                  "name": "TAMPA",
                  "label": "TAMPA",
                  "value": "TAMPA"
              },
              {
                  "name": "JACKSONVILLE",
                  "label": "JACKSONVILLE",
                  "value": "JACKSONVILLE"
              },
              {
                  "name": "ORLANDO",
                  "label": "ORLANDO",
                  "value": "ORLANDO"
              },
              {
                  "name": "KISSIMMEE",
                  "label": "KISSIMMEE",
                  "value": "KISSIMMEE"
              },
              {
                  "name": "PORT RICHEY",
                  "label": "PORT RICHEY",
                  "value": "PORT RICHEY"
              },
              {
                  "name": "GAINESVILLE",
                  "label": "GAINESVILLE",
                  "value": "GAINESVILLE"
              },
              {
                  "name": "PEMBROKE PINES",
                  "label": "PEMBROKE PINES",
                  "value": "PEMBROKE PINES"
              },
              {
                  "name": "THE VILLAGES",
                  "label": "THE VILLAGES",
                  "value": "THE VILLAGES"
              },
              {
                  "name": "MAITLAND",
                  "label": "MAITLAND",
                  "value": "MAITLAND"
              },
              {
                  "name": "CLERMONT",
                  "label": "CLERMONT",
                  "value": "CLERMONT"
              },
              {
                  "name": "STUART",
                  "label": "STUART",
                  "value": "STUART"
              },
              {
                  "name": "WEST PALM BEACH",
                  "label": "WEST PALM BEACH",
                  "value": "WEST PALM BEACH"
              },
              {
                  "name": "FERNANDINA BEACH",
                  "label": "FERNANDINA BEACH",
                  "value": "FERNANDINA BEACH"
              },
              {
                  "name": "WESTON",
                  "label": "WESTON",
                  "value": "WESTON"
              },
              {
                  "name": "HIALEAH",
                  "label": "HIALEAH",
                  "value": "HIALEAH"
              },
              {
                  "name": "BROOKSVILLE",
                  "label": "BROOKSVILLE",
                  "value": "BROOKSVILLE"
              },
              {
                  "name": "PLANTATION",
                  "label": "PLANTATION",
                  "value": "PLANTATION"
              },
              {
                  "name": "OCALA",
                  "label": "OCALA",
                  "value": "OCALA"
              },
              {
                  "name": "Hollywood",
                  "label": "Hollywood",
                  "value": "Hollywood"
              },
              {
                  "name": "REDDICK",
                  "label": "REDDICK",
                  "value": "REDDICK"
              },
              {
                  "name": "ST AUGUSTINE",
                  "label": "ST AUGUSTINE",
                  "value": "ST AUGUSTINE"
              },
              {
                  "name": "PENSACOLA",
                  "label": "PENSACOLA",
                  "value": "PENSACOLA"
              },
              {
                  "name": "SUNRISE",
                  "label": "SUNRISE",
                  "value": "SUNRISE"
              },
              {
                  "name": "BOCA RATON",
                  "label": "BOCA RATON",
                  "value": "BOCA RATON"
              },
              {
                  "name": "TALLAHASSEE",
                  "label": "TALLAHASSEE",
                  "value": "TALLAHASSEE"
              },
              {
                  "name": "FORT WALTON BEACH",
                  "label": "FORT WALTON BEACH",
                  "value": "FORT WALTON BEACH"
              },
              {
                  "name": "DAYTONA BEACH",
                  "label": "DAYTONA BEACH",
                  "value": "DAYTONA BEACH"
              },
              {
                  "name": "ORANGE PARK",
                  "label": "ORANGE PARK",
                  "value": "ORANGE PARK"
              },
              {
                  "name": "VERO BEACH",
                  "label": "VERO BEACH",
                  "value": "VERO BEACH"
              },
              {
                  "name": "SARASOTA",
                  "label": "SARASOTA",
                  "value": "SARASOTA"
              },
              {
                  "name": "MIAMI BEACH",
                  "label": "MIAMI BEACH",
                  "value": "MIAMI BEACH"
              },
              {
                  "name": "SAINT PETERSBURG",
                  "label": "SAINT PETERSBURG",
                  "value": "SAINT PETERSBURG"
              },
              {
                  "name": "CLEARWATER",
                  "label": "CLEARWATER",
                  "value": "CLEARWATER"
              },
              {
                  "name": "CORAL GABLES",
                  "label": "CORAL GABLES",
                  "value": "CORAL GABLES"
              },
              {
                  "name": "PALM COAST",
                  "label": "PALM COAST",
                  "value": "PALM COAST"
              },
              {
                  "name": "LEESBURG",
                  "label": "LEESBURG",
                  "value": "LEESBURG"
              },
              {
                  "name": "PANAMA CITY",
                  "label": "PANAMA CITY",
                  "value": "PANAMA CITY"
              },
              {
                  "name": "PALM BAY",
                  "label": "PALM BAY",
                  "value": "PALM BAY"
              },
              {
                  "name": "PALM BEACH GARDENS",
                  "label": "PALM BEACH GARDENS",
                  "value": "PALM BEACH GARDENS"
              },
              {
                  "name": "JUPITER",
                  "label": "JUPITER",
                  "value": "JUPITER"
              },
              {
                  "name": "ST PETERSBURG",
                  "label": "ST PETERSBURG",
                  "value": "ST PETERSBURG"
              },
              {
                  "name": "MIRAMAR",
                  "label": "MIRAMAR",
                  "value": "MIRAMAR"
              },
              {
                  "name": "MELBOURNE",
                  "label": "MELBOURNE",
                  "value": "MELBOURNE"
              },
              {
                  "name": "HUDSON",
                  "label": "HUDSON",
                  "value": "HUDSON"
              },
              {
                  "name": "TAMARAC",
                  "label": "TAMARAC",
                  "value": "TAMARAC"
              },
              {
                  "name": "RIVERVIEW",
                  "label": "RIVERVIEW",
                  "value": "RIVERVIEW"
              },
              {
                  "name": "ORMOND BEACH",
                  "label": "ORMOND BEACH",
                  "value": "ORMOND BEACH"
              },
              {
                  "name": "TRINITY",
                  "label": "TRINITY",
                  "value": "TRINITY"
              },
              {
                  "name": "FLEMING ISLAND",
                  "label": "FLEMING ISLAND",
                  "value": "FLEMING ISLAND"
              },
              {
                  "name": "WINTER PARK",
                  "label": "WINTER PARK",
                  "value": "WINTER PARK"
              },
              {
                  "name": "DELRAY BEACH",
                  "label": "DELRAY BEACH",
                  "value": "DELRAY BEACH"
              },
              {
                  "name": "MIAMI LAKES",
                  "label": "MIAMI LAKES",
                  "value": "MIAMI LAKES"
              },
              {
                  "name": "BRANDON",
                  "label": "BRANDON",
                  "value": "BRANDON"
              },
              {
                  "name": "NEW SMYRNA BEACH",
                  "label": "NEW SMYRNA BEACH",
                  "value": "NEW SMYRNA BEACH"
              },
              {
                  "name": "FORT LAUDERDALE",
                  "label": "FORT LAUDERDALE",
                  "value": "FORT LAUDERDALE"
              },
              {
                  "name": "CORAL SPRINGS",
                  "label": "CORAL SPRINGS",
                  "value": "CORAL SPRINGS"
              },
              {
                  "name": "SPRING HILL",
                  "label": "SPRING HILL",
                  "value": "SPRING HILL"
              },
              {
                  "name": "PORT ST LUCIE",
                  "label": "PORT ST LUCIE",
                  "value": "PORT ST LUCIE"
              },
              {
                  "name": "OVIEDO",
                  "label": "OVIEDO",
                  "value": "OVIEDO"
              },
              {
                  "name": "WINTER HAVEN",
                  "label": "WINTER HAVEN",
                  "value": "WINTER HAVEN"
              },
              {
                  "name": "RUSKIN",
                  "label": "RUSKIN",
                  "value": "RUSKIN"
              },
              {
                  "name": "LOXAHATCHEE",
                  "label": "LOXAHATCHEE",
                  "value": "LOXAHATCHEE"
              },
              {
                  "name": "PORT ORANGE",
                  "label": "PORT ORANGE",
                  "value": "PORT ORANGE"
              },
              {
                  "name": "HOMESTEAD",
                  "label": "HOMESTEAD",
                  "value": "HOMESTEAD"
              },
              {
                  "name": "ST. PETERSBURG",
                  "label": "ST. PETERSBURG",
                  "value": "ST. PETERSBURG"
              },
              {
                  "name": "NEW PORT RICHEY",
                  "label": "NEW PORT RICHEY",
                  "value": "NEW PORT RICHEY"
              },
              {
                  "name": "FORT MYERS",
                  "label": "FORT MYERS",
                  "value": "FORT MYERS"
              },
              {
                  "name": "SOUTH MIAMI",
                  "label": "SOUTH MIAMI",
                  "value": "SOUTH MIAMI"
              },
              {
                  "name": "TAVARES",
                  "label": "TAVARES",
                  "value": "TAVARES"
              },
              {
                  "name": "LAKEWOOD RANCH",
                  "label": "LAKEWOOD RANCH",
                  "value": "LAKEWOOD RANCH"
              },
              {
                  "name": "TEMPLE TERRACE",
                  "label": "TEMPLE TERRACE",
                  "value": "TEMPLE TERRACE"
              },
              {
                  "name": "PALM SPRINGS",
                  "label": "PALM SPRINGS",
                  "value": "PALM SPRINGS"
              },
              {
                  "name": "DAVENPORT",
                  "label": "DAVENPORT",
                  "value": "DAVENPORT"
              },
              {
                  "name": "PORT CHARLOTTE",
                  "label": "PORT CHARLOTTE",
                  "value": "PORT CHARLOTTE"
              },
              {
                  "name": "NAPLES",
                  "label": "NAPLES",
                  "value": "NAPLES"
              },
              {
                  "name": "BAY PINES",
                  "label": "BAY PINES",
                  "value": "BAY PINES"
              },
              {
                  "name": "MIAMI GARDENS",
                  "label": "MIAMI GARDENS",
                  "value": "MIAMI GARDENS"
              },
              {
                  "name": "DELAND",
                  "label": "DELAND",
                  "value": "DELAND"
              },
              {
                  "name": "LARGO",
                  "label": "LARGO",
                  "value": "LARGO"
              },
              {
                  "name": "MARIANNA",
                  "label": "MARIANNA",
                  "value": "MARIANNA"
              },
              {
                  "name": "ENGLEWOOD",
                  "label": "ENGLEWOOD",
                  "value": "ENGLEWOOD"
              },
              {
                  "name": "LAKELAND",
                  "label": "LAKELAND",
                  "value": "LAKELAND"
              },
              {
                  "name": "LAKE WORTH",
                  "label": "LAKE WORTH",
                  "value": "LAKE WORTH"
              },
              {
                  "name": "PLANT CITY",
                  "label": "PLANT CITY",
                  "value": "PLANT CITY"
              },
              {
                  "name": "PORT SAINT LUCIE",
                  "label": "PORT SAINT LUCIE",
                  "value": "PORT SAINT LUCIE"
              },
              {
                  "name": "BOYNTON BEACH",
                  "label": "BOYNTON BEACH",
                  "value": "BOYNTON BEACH"
              },
              {
                  "name": "BRADENTON",
                  "label": "BRADENTON",
                  "value": "BRADENTON"
              },
              {
                  "name": "VENICE",
                  "label": "VENICE",
                  "value": "VENICE"
              },
              {
                  "name": "FORT PIERCE",
                  "label": "FORT PIERCE",
                  "value": "FORT PIERCE"
              },
              {
                  "name": "FT LAUDERDALE",
                  "label": "FT LAUDERDALE",
                  "value": "FT LAUDERDALE"
              },
              {
                  "name": "ORANGE CITY",
                  "label": "ORANGE CITY",
                  "value": "ORANGE CITY"
              },
              {
                  "name": "LAKE CITY",
                  "label": "LAKE CITY",
                  "value": "LAKE CITY"
              },
              {
                  "name": "WESLEY CHAPEL",
                  "label": "WESLEY CHAPEL",
                  "value": "WESLEY CHAPEL"
              },
              {
                  "name": "SEBRING",
                  "label": "SEBRING",
                  "value": "SEBRING"
              },
              {
                  "name": "LECANTO",
                  "label": "LECANTO",
                  "value": "LECANTO"
              },
              {
                  "name": "EGLIN AFB",
                  "label": "EGLIN AFB",
                  "value": "EGLIN AFB"
              },
              {
                  "name": "WEST JACKSONVILLE",
                  "label": "WEST JACKSONVILLE",
                  "value": "WEST JACKSONVILLE"
              },
              {
                  "name": "ALTAMONTE SPRINGS",
                  "label": "ALTAMONTE SPRINGS",
                  "value": "ALTAMONTE SPRINGS"
              },
              {
                  "name": "PALM CITY",
                  "label": "PALM CITY",
                  "value": "PALM CITY"
              },
              {
                  "name": "ATLANTIS",
                  "label": "ATLANTIS",
                  "value": "ATLANTIS"
              },
              {
                  "name": "BONITA SPRINGS",
                  "label": "BONITA SPRINGS",
                  "value": "BONITA SPRINGS"
              },
              {
                  "name": "ZEPHYRHILLS",
                  "label": "ZEPHYRHILLS",
                  "value": "ZEPHYRHILLS"
              },
              {
                  "name": "TITUSVILLE",
                  "label": "TITUSVILLE",
                  "value": "TITUSVILLE"
              },
              {
                  "name": "FT WALTON BEACH",
                  "label": "FT WALTON BEACH",
                  "value": "FT WALTON BEACH"
              },
              {
                  "name": "SANTA ROSA BEACH",
                  "label": "SANTA ROSA BEACH",
                  "value": "SANTA ROSA BEACH"
              },
              {
                  "name": "MARGATE",
                  "label": "MARGATE",
                  "value": "MARGATE"
              },
              {
                  "name": "DEERFIELD BEACH",
                  "label": "DEERFIELD BEACH",
                  "value": "DEERFIELD BEACH"
              },
              {
                  "name": "SEBASTIAN",
                  "label": "SEBASTIAN",
                  "value": "SEBASTIAN"
              },
              {
                  "name": "MIDDLEBURG",
                  "label": "MIDDLEBURG",
                  "value": "MIDDLEBURG"
              },
              {
                  "name": "KEY WEST",
                  "label": "KEY WEST",
                  "value": "KEY WEST"
              },
              {
                  "name": "AVENTURA",
                  "label": "AVENTURA",
                  "value": "AVENTURA"
              },
              {
                  "name": "ROCKLEDGE",
                  "label": "ROCKLEDGE",
                  "value": "ROCKLEDGE"
              },
              {
                  "name": "LADY LAKE",
                  "label": "LADY LAKE",
                  "value": "LADY LAKE"
              },
              {
                  "name": "INVERNESS",
                  "label": "INVERNESS",
                  "value": "INVERNESS"
              },
              {
                  "name": "ALTAMONTE",
                  "label": "ALTAMONTE",
                  "value": "ALTAMONTE"
              },
              {
                  "name": "MARATHON",
                  "label": "MARATHON",
                  "value": "MARATHON"
              },
              {
                  "name": "SAFETY HARBOR",
                  "label": "SAFETY HARBOR",
                  "value": "SAFETY HARBOR"
              },
              {
                  "name": "DORAL",
                  "label": "DORAL",
                  "value": "DORAL"
              },
              {
                  "name": "LAKE MARY",
                  "label": "LAKE MARY",
                  "value": "LAKE MARY"
              },
              {
                  "name": "OAKLAND PARK",
                  "label": "OAKLAND PARK",
                  "value": "OAKLAND PARK"
              },
              {
                  "name": "MIAMI SHORES",
                  "label": "MIAMI SHORES",
                  "value": "MIAMI SHORES"
              },
              {
                  "name": "MIRAMAR BEACH",
                  "label": "MIRAMAR BEACH",
                  "value": "MIRAMAR BEACH"
              },
              {
                  "name": "PB GARDENS",
                  "label": "PB GARDENS",
                  "value": "PB GARDENS"
              },
              {
                  "name": "LAKE WALES",
                  "label": "LAKE WALES",
                  "value": "LAKE WALES"
              },
              {
                  "name": "OCOEE",
                  "label": "OCOEE",
                  "value": "OCOEE"
              },
              {
                  "name": "ESTERO",
                  "label": "ESTERO",
                  "value": "ESTERO"
              },
              {
                  "name": "SEFFNER",
                  "label": "SEFFNER",
                  "value": "SEFFNER"
              },
              {
                  "name": "POMPANO BEACH",
                  "label": "POMPANO BEACH",
                  "value": "POMPANO BEACH"
              },
              {
                  "name": "WELLINGTON",
                  "label": "WELLINGTON",
                  "value": "WELLINGTON"
              },
              {
                  "name": "JACKSONVILLE BEACH",
                  "label": "JACKSONVILLE BEACH",
                  "value": "JACKSONVILLE BEACH"
              },
              {
                  "name": "HOLIDAY",
                  "label": "HOLIDAY",
                  "value": "HOLIDAY"
              },
              {
                  "name": "STARKE",
                  "label": "STARKE",
                  "value": "STARKE"
              },
              {
                  "name": "NORTH MIAMI",
                  "label": "NORTH MIAMI",
                  "value": "NORTH MIAMI"
              },
              {
                  "name": "FT MYERS",
                  "label": "FT MYERS",
                  "value": "FT MYERS"
              },
              {
                  "name": "MERRITT ISLAND",
                  "label": "MERRITT ISLAND",
                  "value": "MERRITT ISLAND"
              },
              {
                  "name": "PONTE VEDRA BEACH",
                  "label": "PONTE VEDRA BEACH",
                  "value": "PONTE VEDRA BEACH"
              },
              {
                  "name": "SUMMERFIELD",
                  "label": "SUMMERFIELD",
                  "value": "SUMMERFIELD"
              },
              {
                  "name": "PALATKA",
                  "label": "PALATKA",
                  "value": "PALATKA"
              },
              {
                  "name": "VIERA",
                  "label": "VIERA",
                  "value": "VIERA"
              }
          ]
      },
      {
          "name": "GA",
          "label": "Georgia",
          "value": "GA",
          "cities": [
              {
                  "name": "MARIETTA",
                  "label": "MARIETTA",
                  "value": "MARIETTA"
              },
              {
                  "name": "ATLANTA",
                  "label": "ATLANTA",
                  "value": "ATLANTA"
              },
              {
                  "name": "DAHLONEGA",
                  "label": "DAHLONEGA",
                  "value": "DAHLONEGA"
              },
              {
                  "name": "ALPHARETTA",
                  "label": "ALPHARETTA",
                  "value": "ALPHARETTA"
              },
              {
                  "name": "JONESBORO",
                  "label": "JONESBORO",
                  "value": "JONESBORO"
              },
              {
                  "name": "ALBANY",
                  "label": "ALBANY",
                  "value": "ALBANY"
              },
              {
                  "name": "DALTON",
                  "label": "DALTON",
                  "value": "DALTON"
              },
              {
                  "name": "SAVANNAH",
                  "label": "SAVANNAH",
                  "value": "SAVANNAH"
              },
              {
                  "name": "ROSWELL",
                  "label": "ROSWELL",
                  "value": "ROSWELL"
              },
              {
                  "name": "CARROLLTON",
                  "label": "CARROLLTON",
                  "value": "CARROLLTON"
              },
              {
                  "name": "CUMMING",
                  "label": "CUMMING",
                  "value": "CUMMING"
              },
              {
                  "name": "NEWNAN",
                  "label": "NEWNAN",
                  "value": "NEWNAN"
              },
              {
                  "name": "STOCKBRIDGE",
                  "label": "STOCKBRIDGE",
                  "value": "STOCKBRIDGE"
              },
              {
                  "name": "VALDOSTA",
                  "label": "VALDOSTA",
                  "value": "VALDOSTA"
              },
              {
                  "name": "MACON",
                  "label": "MACON",
                  "value": "MACON"
              },
              {
                  "name": "GAINESVILLE",
                  "label": "GAINESVILLE",
                  "value": "GAINESVILLE"
              },
              {
                  "name": "AUGUSTA",
                  "label": "AUGUSTA",
                  "value": "AUGUSTA"
              },
              {
                  "name": "JOHNS CREEK",
                  "label": "JOHNS CREEK",
                  "value": "JOHNS CREEK"
              },
              {
                  "name": "ATHENS",
                  "label": "ATHENS",
                  "value": "ATHENS"
              },
              {
                  "name": "CHATSWORTH",
                  "label": "CHATSWORTH",
                  "value": "CHATSWORTH"
              },
              {
                  "name": "BLUE RIDGE",
                  "label": "BLUE RIDGE",
                  "value": "BLUE RIDGE"
              },
              {
                  "name": "COLUMBUS",
                  "label": "COLUMBUS",
                  "value": "COLUMBUS"
              },
              {
                  "name": "CONYERS",
                  "label": "CONYERS",
                  "value": "CONYERS"
              },
              {
                  "name": "DECATUR",
                  "label": "DECATUR",
                  "value": "DECATUR"
              },
              {
                  "name": "AMERICUS",
                  "label": "AMERICUS",
                  "value": "AMERICUS"
              },
              {
                  "name": "FAYETTEVILLE",
                  "label": "FAYETTEVILLE",
                  "value": "FAYETTEVILLE"
              },
              {
                  "name": "Lawrenceville",
                  "label": "Lawrenceville",
                  "value": "Lawrenceville"
              },
              {
                  "name": "CLEVELAND",
                  "label": "CLEVELAND",
                  "value": "CLEVELAND"
              },
              {
                  "name": "DUBLIN",
                  "label": "DUBLIN",
                  "value": "DUBLIN"
              },
              {
                  "name": "DOUGLASVILLE",
                  "label": "DOUGLASVILLE",
                  "value": "DOUGLASVILLE"
              },
              {
                  "name": "LAGRANGE",
                  "label": "LAGRANGE",
                  "value": "LAGRANGE"
              },
              {
                  "name": "LITHONIA",
                  "label": "LITHONIA",
                  "value": "LITHONIA"
              },
              {
                  "name": "TIFTON",
                  "label": "TIFTON",
                  "value": "TIFTON"
              },
              {
                  "name": "AUSTELL",
                  "label": "AUSTELL",
                  "value": "AUSTELL"
              },
              {
                  "name": "CALHOUN",
                  "label": "CALHOUN",
                  "value": "CALHOUN"
              },
              {
                  "name": "ST. MARYS",
                  "label": "ST. MARYS",
                  "value": "ST. MARYS"
              },
              {
                  "name": "MOULTRIE",
                  "label": "MOULTRIE",
                  "value": "MOULTRIE"
              },
              {
                  "name": "MILLEDGEVILLE",
                  "label": "MILLEDGEVILLE",
                  "value": "MILLEDGEVILLE"
              },
              {
                  "name": "HOMELAND",
                  "label": "HOMELAND",
                  "value": "HOMELAND"
              },
              {
                  "name": "WAYCROSS",
                  "label": "WAYCROSS",
                  "value": "WAYCROSS"
              },
              {
                  "name": "SNELLVILLE",
                  "label": "SNELLVILLE",
                  "value": "SNELLVILLE"
              },
              {
                  "name": "BLAIRSVILLE",
                  "label": "BLAIRSVILLE",
                  "value": "BLAIRSVILLE"
              },
              {
                  "name": "DALLAS",
                  "label": "DALLAS",
                  "value": "DALLAS"
              },
              {
                  "name": "DOUGLAS",
                  "label": "DOUGLAS",
                  "value": "DOUGLAS"
              },
              {
                  "name": "GRIFFIN",
                  "label": "GRIFFIN",
                  "value": "GRIFFIN"
              },
              {
                  "name": "EATONTON",
                  "label": "EATONTON",
                  "value": "EATONTON"
              },
              {
                  "name": "DULUTH",
                  "label": "DULUTH",
                  "value": "DULUTH"
              },
              {
                  "name": "SMYRNA",
                  "label": "SMYRNA",
                  "value": "SMYRNA"
              },
              {
                  "name": "METTER",
                  "label": "METTER",
                  "value": "METTER"
              },
              {
                  "name": "ROME",
                  "label": "ROME",
                  "value": "ROME"
              },
              {
                  "name": "HAWKINSVILLE",
                  "label": "HAWKINSVILLE",
                  "value": "HAWKINSVILLE"
              },
              {
                  "name": "SWAINSBORO",
                  "label": "SWAINSBORO",
                  "value": "SWAINSBORO"
              },
              {
                  "name": "KENNESAW",
                  "label": "KENNESAW",
                  "value": "KENNESAW"
              },
              {
                  "name": "THOMASVILLE",
                  "label": "THOMASVILLE",
                  "value": "THOMASVILLE"
              },
              {
                  "name": "COVINGTON",
                  "label": "COVINGTON",
                  "value": "COVINGTON"
              },
              {
                  "name": "CANTON",
                  "label": "CANTON",
                  "value": "CANTON"
              },
              {
                  "name": "BRUNSWICK",
                  "label": "BRUNSWICK",
                  "value": "BRUNSWICK"
              },
              {
                  "name": "SAINT MARYS",
                  "label": "SAINT MARYS",
                  "value": "SAINT MARYS"
              },
              {
                  "name": "CARTERSVILLE",
                  "label": "CARTERSVILLE",
                  "value": "CARTERSVILLE"
              },
              {
                  "name": "SPRINGFIELD",
                  "label": "SPRINGFIELD",
                  "value": "SPRINGFIELD"
              },
              {
                  "name": "HIRAM",
                  "label": "HIRAM",
                  "value": "HIRAM"
              },
              {
                  "name": "JASPER",
                  "label": "JASPER",
                  "value": "JASPER"
              },
              {
                  "name": "VIDALIA",
                  "label": "VIDALIA",
                  "value": "VIDALIA"
              },
              {
                  "name": "ST SIMONS ISLAND",
                  "label": "ST SIMONS ISLAND",
                  "value": "ST SIMONS ISLAND"
              },
              {
                  "name": "STONE MOUNTAIN",
                  "label": "STONE MOUNTAIN",
                  "value": "STONE MOUNTAIN"
              },
              {
                  "name": "THOMASTON",
                  "label": "THOMASTON",
                  "value": "THOMASTON"
              },
              {
                  "name": "CORDELE",
                  "label": "CORDELE",
                  "value": "CORDELE"
              },
              {
                  "name": "SANDY SPRINGS",
                  "label": "SANDY SPRINGS",
                  "value": "SANDY SPRINGS"
              },
              {
                  "name": "MCDONOUGH",
                  "label": "MCDONOUGH",
                  "value": "MCDONOUGH"
              },
              {
                  "name": "FORT BENNING",
                  "label": "FORT BENNING",
                  "value": "FORT BENNING"
              },
              {
                  "name": "GREENSBORO",
                  "label": "GREENSBORO",
                  "value": "GREENSBORO"
              },
              {
                  "name": "BROOKS",
                  "label": "BROOKS",
                  "value": "BROOKS"
              }
          ]
      },
      {
          "name": "MD",
          "label": "Maryland",
          "value": "MD",
          "cities": [
              {
                  "name": "SALISBURY",
                  "label": "SALISBURY",
                  "value": "SALISBURY"
              },
              {
                  "name": "BALTIMORE",
                  "label": "BALTIMORE",
                  "value": "BALTIMORE"
              },
              {
                  "name": "TOWSON",
                  "label": "TOWSON",
                  "value": "TOWSON"
              },
              {
                  "name": "LANHAM",
                  "label": "LANHAM",
                  "value": "LANHAM"
              },
              {
                  "name": "COLUMBIA",
                  "label": "COLUMBIA",
                  "value": "COLUMBIA"
              },
              {
                  "name": "ROCKVILLE",
                  "label": "ROCKVILLE",
                  "value": "ROCKVILLE"
              },
              {
                  "name": "GLEN BURNIE",
                  "label": "GLEN BURNIE",
                  "value": "GLEN BURNIE"
              },
              {
                  "name": "ANNAPOLIS",
                  "label": "ANNAPOLIS",
                  "value": "ANNAPOLIS"
              },
              {
                  "name": "POTOMAC",
                  "label": "POTOMAC",
                  "value": "POTOMAC"
              },
              {
                  "name": "GREENBELT",
                  "label": "GREENBELT",
                  "value": "GREENBELT"
              },
              {
                  "name": "BETHESDA",
                  "label": "BETHESDA",
                  "value": "BETHESDA"
              },
              {
                  "name": "RIVERDALE",
                  "label": "RIVERDALE",
                  "value": "RIVERDALE"
              },
              {
                  "name": "SILVER SPRING",
                  "label": "SILVER SPRING",
                  "value": "SILVER SPRING"
              },
              {
                  "name": "WALDORF",
                  "label": "WALDORF",
                  "value": "WALDORF"
              },
              {
                  "name": "LARGO",
                  "label": "LARGO",
                  "value": "LARGO"
              },
              {
                  "name": "BERLIN",
                  "label": "BERLIN",
                  "value": "BERLIN"
              },
              {
                  "name": "LUTHERVILLE",
                  "label": "LUTHERVILLE",
                  "value": "LUTHERVILLE"
              },
              {
                  "name": "HAGERSTOWN",
                  "label": "HAGERSTOWN",
                  "value": "HAGERSTOWN"
              },
              {
                  "name": "BEL AIR",
                  "label": "BEL AIR",
                  "value": "BEL AIR"
              },
              {
                  "name": "LINTHICUM",
                  "label": "LINTHICUM",
                  "value": "LINTHICUM"
              },
              {
                  "name": "CATONSVILLE",
                  "label": "CATONSVILLE",
                  "value": "CATONSVILLE"
              },
              {
                  "name": "CLINTON",
                  "label": "CLINTON",
                  "value": "CLINTON"
              },
              {
                  "name": "PRINCE FREDERICK",
                  "label": "PRINCE FREDERICK",
                  "value": "PRINCE FREDERICK"
              },
              {
                  "name": "ROSEDALE",
                  "label": "ROSEDALE",
                  "value": "ROSEDALE"
              },
              {
                  "name": "WESTMINSTER",
                  "label": "WESTMINSTER",
                  "value": "WESTMINSTER"
              },
              {
                  "name": "FREDERICK",
                  "label": "FREDERICK",
                  "value": "FREDERICK"
              },
              {
                  "name": "NORTH BETHESDA",
                  "label": "NORTH BETHESDA",
                  "value": "NORTH BETHESDA"
              },
              {
                  "name": "CALIFORNIA",
                  "label": "CALIFORNIA",
                  "value": "CALIFORNIA"
              },
              {
                  "name": "EASTON",
                  "label": "EASTON",
                  "value": "EASTON"
              },
              {
                  "name": "CHEVERLY",
                  "label": "CHEVERLY",
                  "value": "CHEVERLY"
              },
              {
                  "name": "BOWIE",
                  "label": "BOWIE",
                  "value": "BOWIE"
              },
              {
                  "name": "HYATTSVILLE",
                  "label": "HYATTSVILLE",
                  "value": "HYATTSVILLE"
              },
              {
                  "name": "ELLICOTT CITY",
                  "label": "ELLICOTT CITY",
                  "value": "ELLICOTT CITY"
              },
              {
                  "name": "GERMANTOWN",
                  "label": "GERMANTOWN",
                  "value": "GERMANTOWN"
              },
              {
                  "name": "ELKTON",
                  "label": "ELKTON",
                  "value": "ELKTON"
              },
              {
                  "name": "GAITHERSBURG",
                  "label": "GAITHERSBURG",
                  "value": "GAITHERSBURG"
              },
              {
                  "name": "SEVERN",
                  "label": "SEVERN",
                  "value": "SEVERN"
              },
              {
                  "name": "OLNEY",
                  "label": "OLNEY",
                  "value": "OLNEY"
              },
              {
                  "name": "LAUREL",
                  "label": "LAUREL",
                  "value": "LAUREL"
              },
              {
                  "name": "KENSINGTON",
                  "label": "KENSINGTON",
                  "value": "KENSINGTON"
              },
              {
                  "name": "CHEVY CHASE",
                  "label": "CHEVY CHASE",
                  "value": "CHEVY CHASE"
              },
              {
                  "name": "HOLLYWOOD",
                  "label": "HOLLYWOOD",
                  "value": "HOLLYWOOD"
              },
              {
                  "name": "CLARKSVILLE",
                  "label": "CLARKSVILLE",
                  "value": "CLARKSVILLE"
              }
          ]
      },
      {
          "name": "VA",
          "label": "Virginia",
          "value": "VA",
          "cities": [
              {
                  "name": "CHESAPEAKE",
                  "label": "CHESAPEAKE",
                  "value": "CHESAPEAKE"
              },
              {
                  "name": "FARMVILLE",
                  "label": "FARMVILLE",
                  "value": "FARMVILLE"
              },
              {
                  "name": "ARLINGTON",
                  "label": "ARLINGTON",
                  "value": "ARLINGTON"
              },
              {
                  "name": "CHARLOTTESVILLE",
                  "label": "CHARLOTTESVILLE",
                  "value": "CHARLOTTESVILLE"
              },
              {
                  "name": "NEWPORT NEWS",
                  "label": "NEWPORT NEWS",
                  "value": "NEWPORT NEWS"
              },
              {
                  "name": "HARRISONBURG",
                  "label": "HARRISONBURG",
                  "value": "HARRISONBURG"
              },
              {
                  "name": "LEESBURG",
                  "label": "LEESBURG",
                  "value": "LEESBURG"
              },
              {
                  "name": "WOODBRIDGE",
                  "label": "WOODBRIDGE",
                  "value": "WOODBRIDGE"
              },
              {
                  "name": "BRAMBLETON",
                  "label": "BRAMBLETON",
                  "value": "BRAMBLETON"
              },
              {
                  "name": "WINCHESTER",
                  "label": "WINCHESTER",
                  "value": "WINCHESTER"
              },
              {
                  "name": "CHESTER",
                  "label": "CHESTER",
                  "value": "CHESTER"
              },
              {
                  "name": "RICHMOND",
                  "label": "RICHMOND",
                  "value": "RICHMOND"
              },
              {
                  "name": "FREDERICKSBURG",
                  "label": "FREDERICKSBURG",
                  "value": "FREDERICKSBURG"
              },
              {
                  "name": "NORFOLK",
                  "label": "NORFOLK",
                  "value": "NORFOLK"
              },
              {
                  "name": "ABINGDON",
                  "label": "ABINGDON",
                  "value": "ABINGDON"
              },
              {
                  "name": "SUFFOLK",
                  "label": "SUFFOLK",
                  "value": "SUFFOLK"
              },
              {
                  "name": "FAIRFAX",
                  "label": "FAIRFAX",
                  "value": "FAIRFAX"
              },
              {
                  "name": "FALLS CHURCH",
                  "label": "FALLS CHURCH",
                  "value": "FALLS CHURCH"
              },
              {
                  "name": "MECHANICSVILLE",
                  "label": "MECHANICSVILLE",
                  "value": "MECHANICSVILLE"
              },
              {
                  "name": "HILTONS",
                  "label": "HILTONS",
                  "value": "HILTONS"
              },
              {
                  "name": "ALEXANDRIA",
                  "label": "ALEXANDRIA",
                  "value": "ALEXANDRIA"
              },
              {
                  "name": "PETERSBURG",
                  "label": "PETERSBURG",
                  "value": "PETERSBURG"
              },
              {
                  "name": "VIRGINIA BEACH",
                  "label": "VIRGINIA BEACH",
                  "value": "VIRGINIA BEACH"
              },
              {
                  "name": "FISHERSVILLE",
                  "label": "FISHERSVILLE",
                  "value": "FISHERSVILLE"
              },
              {
                  "name": "ROANOKE",
                  "label": "ROANOKE",
                  "value": "ROANOKE"
              },
              {
                  "name": "WILLIAMSBURG",
                  "label": "WILLIAMSBURG",
                  "value": "WILLIAMSBURG"
              },
              {
                  "name": "RESTON",
                  "label": "RESTON",
                  "value": "RESTON"
              },
              {
                  "name": "HAMPTON",
                  "label": "HAMPTON",
                  "value": "HAMPTON"
              },
              {
                  "name": "CULPEPER",
                  "label": "CULPEPER",
                  "value": "CULPEPER"
              },
              {
                  "name": "DANVILLE",
                  "label": "DANVILLE",
                  "value": "DANVILLE"
              },
              {
                  "name": "POUNDING MILL",
                  "label": "POUNDING MILL",
                  "value": "POUNDING MILL"
              },
              {
                  "name": "VIENNA",
                  "label": "VIENNA",
                  "value": "VIENNA"
              },
              {
                  "name": "FORT BELVOIR",
                  "label": "FORT BELVOIR",
                  "value": "FORT BELVOIR"
              },
              {
                  "name": "LYNCHBURG",
                  "label": "LYNCHBURG",
                  "value": "LYNCHBURG"
              },
              {
                  "name": "BRISTOL",
                  "label": "BRISTOL",
                  "value": "BRISTOL"
              },
              {
                  "name": "TAPPAHANNOCK",
                  "label": "TAPPAHANNOCK",
                  "value": "TAPPAHANNOCK"
              },
              {
                  "name": "HUDDLESTON",
                  "label": "HUDDLESTON",
                  "value": "HUDDLESTON"
              },
              {
                  "name": "LORTON",
                  "label": "LORTON",
                  "value": "LORTON"
              },
              {
                  "name": "GAINESVILLE",
                  "label": "GAINESVILLE",
                  "value": "GAINESVILLE"
              },
              {
                  "name": "WYTHEVILLE",
                  "label": "WYTHEVILLE",
                  "value": "WYTHEVILLE"
              },
              {
                  "name": "MARTINSVILLE",
                  "label": "MARTINSVILLE",
                  "value": "MARTINSVILLE"
              },
              {
                  "name": "PORTSMOUTH",
                  "label": "PORTSMOUTH",
                  "value": "PORTSMOUTH"
              },
              {
                  "name": "MIDLOTHIAN",
                  "label": "MIDLOTHIAN",
                  "value": "MIDLOTHIAN"
              },
              {
                  "name": "SPOTSYLVANIA",
                  "label": "SPOTSYLVANIA",
                  "value": "SPOTSYLVANIA"
              },
              {
                  "name": "MCLEAN",
                  "label": "MCLEAN",
                  "value": "MCLEAN"
              },
              {
                  "name": "SOUTH BOSTON",
                  "label": "SOUTH BOSTON",
                  "value": "SOUTH BOSTON"
              },
              {
                  "name": "SALEM",
                  "label": "SALEM",
                  "value": "SALEM"
              },
              {
                  "name": "ASHBURN",
                  "label": "ASHBURN",
                  "value": "ASHBURN"
              },
              {
                  "name": "NORTON",
                  "label": "NORTON",
                  "value": "NORTON"
              },
              {
                  "name": "PULASKI",
                  "label": "PULASKI",
                  "value": "PULASKI"
              },
              {
                  "name": "PRINCE GEORGE",
                  "label": "PRINCE GEORGE",
                  "value": "PRINCE GEORGE"
              },
              {
                  "name": "QUANTICO",
                  "label": "QUANTICO",
                  "value": "QUANTICO"
              },
              {
                  "name": "FORT LEE",
                  "label": "FORT LEE",
                  "value": "FORT LEE"
              },
              {
                  "name": "STAFFORD",
                  "label": "STAFFORD",
                  "value": "STAFFORD"
              },
              {
                  "name": "RICHLANDS",
                  "label": "RICHLANDS",
                  "value": "RICHLANDS"
              },
              {
                  "name": "CONCORD",
                  "label": "CONCORD",
                  "value": "CONCORD"
              },
              {
                  "name": "BLACKSBURG",
                  "label": "BLACKSBURG",
                  "value": "BLACKSBURG"
              },
              {
                  "name": "VINTON",
                  "label": "VINTON",
                  "value": "VINTON"
              },
              {
                  "name": "KILMARNOCK",
                  "label": "KILMARNOCK",
                  "value": "KILMARNOCK"
              },
              {
                  "name": "NORTH DINWIDDIE",
                  "label": "NORTH DINWIDDIE",
                  "value": "NORTH DINWIDDIE"
              },
              {
                  "name": "GLOUCESTER",
                  "label": "GLOUCESTER",
                  "value": "GLOUCESTER"
              },
              {
                  "name": "MANASSAS",
                  "label": "MANASSAS",
                  "value": "MANASSAS"
              },
              {
                  "name": "GLEN ALLEN",
                  "label": "GLEN ALLEN",
                  "value": "GLEN ALLEN"
              }
          ]
      },
      {
          "name": "AL",
          "label": "Alabama",
          "value": "AL",
          "cities": [
              {
                  "name": "MONTGOMERY",
                  "label": "MONTGOMERY",
                  "value": "MONTGOMERY"
              },
              {
                  "name": "BIRMINGHAM",
                  "label": "BIRMINGHAM",
                  "value": "BIRMINGHAM"
              },
              {
                  "name": "TUSCALOOSA",
                  "label": "TUSCALOOSA",
                  "value": "TUSCALOOSA"
              },
              {
                  "name": "HUNTSVILLE",
                  "label": "HUNTSVILLE",
                  "value": "HUNTSVILLE"
              },
              {
                  "name": "JASPER",
                  "label": "JASPER",
                  "value": "JASPER"
              },
              {
                  "name": "MOBILE",
                  "label": "MOBILE",
                  "value": "MOBILE"
              },
              {
                  "name": "VESTAVIA",
                  "label": "VESTAVIA",
                  "value": "VESTAVIA"
              },
              {
                  "name": "ALABASTER",
                  "label": "ALABASTER",
                  "value": "ALABASTER"
              },
              {
                  "name": "DOTHAN",
                  "label": "DOTHAN",
                  "value": "DOTHAN"
              },
              {
                  "name": "GADSDEN",
                  "label": "GADSDEN",
                  "value": "GADSDEN"
              },
              {
                  "name": "BOAZ",
                  "label": "BOAZ",
                  "value": "BOAZ"
              },
              {
                  "name": "OPELIKA",
                  "label": "OPELIKA",
                  "value": "OPELIKA"
              },
              {
                  "name": "MONROEVILLE",
                  "label": "MONROEVILLE",
                  "value": "MONROEVILLE"
              },
              {
                  "name": "DAPHNE",
                  "label": "DAPHNE",
                  "value": "DAPHNE"
              },
              {
                  "name": "DECATUR",
                  "label": "DECATUR",
                  "value": "DECATUR"
              },
              {
                  "name": "MUSCLE SHOALS",
                  "label": "MUSCLE SHOALS",
                  "value": "MUSCLE SHOALS"
              },
              {
                  "name": "HOMEWOOD",
                  "label": "HOMEWOOD",
                  "value": "HOMEWOOD"
              },
              {
                  "name": "ANNISTON",
                  "label": "ANNISTON",
                  "value": "ANNISTON"
              },
              {
                  "name": "FLORENCE",
                  "label": "FLORENCE",
                  "value": "FLORENCE"
              },
              {
                  "name": "GREENVILLE",
                  "label": "GREENVILLE",
                  "value": "GREENVILLE"
              },
              {
                  "name": "BREWTON",
                  "label": "BREWTON",
                  "value": "BREWTON"
              },
              {
                  "name": "HOOVER",
                  "label": "HOOVER",
                  "value": "HOOVER"
              },
              {
                  "name": "FOLEY",
                  "label": "FOLEY",
                  "value": "FOLEY"
              },
              {
                  "name": "PRATTVILLE",
                  "label": "PRATTVILLE",
                  "value": "PRATTVILLE"
              },
              {
                  "name": "NORTHPORT",
                  "label": "NORTHPORT",
                  "value": "NORTHPORT"
              },
              {
                  "name": "WEDOWEE",
                  "label": "WEDOWEE",
                  "value": "WEDOWEE"
              },
              {
                  "name": "WINFIELD",
                  "label": "WINFIELD",
                  "value": "WINFIELD"
              },
              {
                  "name": "ALEXANDER CITY",
                  "label": "ALEXANDER CITY",
                  "value": "ALEXANDER CITY"
              },
              {
                  "name": "PHENIX CITY",
                  "label": "PHENIX CITY",
                  "value": "PHENIX CITY"
              },
              {
                  "name": "IRVINGTON",
                  "label": "IRVINGTON",
                  "value": "IRVINGTON"
              },
              {
                  "name": "CULLMAN",
                  "label": "CULLMAN",
                  "value": "CULLMAN"
              },
              {
                  "name": "BESSEMER",
                  "label": "BESSEMER",
                  "value": "BESSEMER"
              },
              {
                  "name": "ALBERTVILLE",
                  "label": "ALBERTVILLE",
                  "value": "ALBERTVILLE"
              },
              {
                  "name": "AUBURN",
                  "label": "AUBURN",
                  "value": "AUBURN"
              },
              {
                  "name": "FT RUCKER",
                  "label": "FT RUCKER",
                  "value": "FT RUCKER"
              },
              {
                  "name": "GLENCOE",
                  "label": "GLENCOE",
                  "value": "GLENCOE"
              },
              {
                  "name": "ASHLAND",
                  "label": "ASHLAND",
                  "value": "ASHLAND"
              },
              {
                  "name": "ATTALLA",
                  "label": "ATTALLA",
                  "value": "ATTALLA"
              }
          ]
      },
      {
          "name": "DE",
          "label": "Delaware",
          "value": "DE",
          "cities": [
              {
                  "name": "NEWARK",
                  "label": "NEWARK",
                  "value": "NEWARK"
              },
              {
                  "name": "WILMINGTON",
                  "label": "WILMINGTON",
                  "value": "WILMINGTON"
              },
              {
                  "name": "REHOBOTH BEACH",
                  "label": "REHOBOTH BEACH",
                  "value": "REHOBOTH BEACH"
              },
              {
                  "name": "LEWES",
                  "label": "LEWES",
                  "value": "LEWES"
              },
              {
                  "name": "DOVER",
                  "label": "DOVER",
                  "value": "DOVER"
              },
              {
                  "name": "MILFORD",
                  "label": "MILFORD",
                  "value": "MILFORD"
              },
              {
                  "name": "NEWPORT",
                  "label": "NEWPORT",
                  "value": "NEWPORT"
              },
              {
                  "name": "SEAFORD",
                  "label": "SEAFORD",
                  "value": "SEAFORD"
              }
          ]
      },
      {
          "name": "WV",
          "label": "West Virginia",
          "value": "WV",
          "cities": [
              {
                  "name": "PRINCETON",
                  "label": "PRINCETON",
                  "value": "PRINCETON"
              },
              {
                  "name": "BLUEFIELD",
                  "label": "BLUEFIELD",
                  "value": "BLUEFIELD"
              },
              {
                  "name": "BECKLEY",
                  "label": "BECKLEY",
                  "value": "BECKLEY"
              },
              {
                  "name": "HUNTINGTON",
                  "label": "HUNTINGTON",
                  "value": "HUNTINGTON"
              },
              {
                  "name": "MARTINSBURG",
                  "label": "MARTINSBURG",
                  "value": "MARTINSBURG"
              },
              {
                  "name": "BUCKHANNON",
                  "label": "BUCKHANNON",
                  "value": "BUCKHANNON"
              },
              {
                  "name": "SOUTH CHARLESTON",
                  "label": "SOUTH CHARLESTON",
                  "value": "SOUTH CHARLESTON"
              },
              {
                  "name": "CHARLESTON",
                  "label": "CHARLESTON",
                  "value": "CHARLESTON"
              },
              {
                  "name": "GASSAWAY",
                  "label": "GASSAWAY",
                  "value": "GASSAWAY"
              },
              {
                  "name": "PARKERSBURG",
                  "label": "PARKERSBURG",
                  "value": "PARKERSBURG"
              },
              {
                  "name": "LOGAN",
                  "label": "LOGAN",
                  "value": "LOGAN"
              },
              {
                  "name": "ELKINS",
                  "label": "ELKINS",
                  "value": "ELKINS"
              },
              {
                  "name": "POINT PLEASANT",
                  "label": "POINT PLEASANT",
                  "value": "POINT PLEASANT"
              },
              {
                  "name": "SOUTHCHARLESTON",
                  "label": "SOUTHCHARLESTON",
                  "value": "SOUTHCHARLESTON"
              },
              {
                  "name": "MADISON",
                  "label": "MADISON",
                  "value": "MADISON"
              },
              {
                  "name": "MINERAL WELLS",
                  "label": "MINERAL WELLS",
                  "value": "MINERAL WELLS"
              }
          ]
      },
      {
          "name": "SC",
          "label": "South Carolina",
          "value": "SC",
          "cities": [
              {
                  "name": "HILTON HEAD ISLAND",
                  "label": "HILTON HEAD ISLAND",
                  "value": "HILTON HEAD ISLAND"
              },
              {
                  "name": "GREENVILLE",
                  "label": "GREENVILLE",
                  "value": "GREENVILLE"
              },
              {
                  "name": "WEST COLUMBIA",
                  "label": "WEST COLUMBIA",
                  "value": "WEST COLUMBIA"
              },
              {
                  "name": "CHARLESTON",
                  "label": "CHARLESTON",
                  "value": "CHARLESTON"
              },
              {
                  "name": "MYRTLE BEACH",
                  "label": "MYRTLE BEACH",
                  "value": "MYRTLE BEACH"
              },
              {
                  "name": "COLUMBIA",
                  "label": "COLUMBIA",
                  "value": "COLUMBIA"
              },
              {
                  "name": "SPARTANBURG",
                  "label": "SPARTANBURG",
                  "value": "SPARTANBURG"
              },
              {
                  "name": "FLORENCE",
                  "label": "FLORENCE",
                  "value": "FLORENCE"
              },
              {
                  "name": "MURRELLS INLET",
                  "label": "MURRELLS INLET",
                  "value": "MURRELLS INLET"
              },
              {
                  "name": "ORANGEBURG",
                  "label": "ORANGEBURG",
                  "value": "ORANGEBURG"
              },
              {
                  "name": "NORTH CHARLESTON",
                  "label": "NORTH CHARLESTON",
                  "value": "NORTH CHARLESTON"
              },
              {
                  "name": "ROCK HILL",
                  "label": "ROCK HILL",
                  "value": "ROCK HILL"
              },
              {
                  "name": "BLUFFTON",
                  "label": "BLUFFTON",
                  "value": "BLUFFTON"
              },
              {
                  "name": "CONWAY",
                  "label": "CONWAY",
                  "value": "CONWAY"
              },
              {
                  "name": "N CHARLESTON",
                  "label": "N CHARLESTON",
                  "value": "N CHARLESTON"
              },
              {
                  "name": "OKATIE",
                  "label": "OKATIE",
                  "value": "OKATIE"
              },
              {
                  "name": "SUMTER",
                  "label": "SUMTER",
                  "value": "SUMTER"
              },
              {
                  "name": "LITTLE RIVER",
                  "label": "LITTLE RIVER",
                  "value": "LITTLE RIVER"
              },
              {
                  "name": "ST MATTHEWS",
                  "label": "ST MATTHEWS",
                  "value": "ST MATTHEWS"
              },
              {
                  "name": "SENECA",
                  "label": "SENECA",
                  "value": "SENECA"
              },
              {
                  "name": "GREENWOOD",
                  "label": "GREENWOOD",
                  "value": "GREENWOOD"
              },
              {
                  "name": "AIKEN",
                  "label": "AIKEN",
                  "value": "AIKEN"
              },
              {
                  "name": "ANDERSON",
                  "label": "ANDERSON",
                  "value": "ANDERSON"
              },
              {
                  "name": "CLINTON",
                  "label": "CLINTON",
                  "value": "CLINTON"
              },
              {
                  "name": "MOUNT PLEASANT",
                  "label": "MOUNT PLEASANT",
                  "value": "MOUNT PLEASANT"
              },
              {
                  "name": "BEAUFORT",
                  "label": "BEAUFORT",
                  "value": "BEAUFORT"
              },
              {
                  "name": "LADSON",
                  "label": "LADSON",
                  "value": "LADSON"
              },
              {
                  "name": "EASLEY",
                  "label": "EASLEY",
                  "value": "EASLEY"
              },
              {
                  "name": "GAFFNEY",
                  "label": "GAFFNEY",
                  "value": "GAFFNEY"
              }
          ]
      },
      {
          "name": "DC",
          "label": "District of Columbia",
          "value": "DC",
          "cities": [
              {
                  "name": "WASHINGTON",
                  "label": "WASHINGTON",
                  "value": "WASHINGTON"
              },
              {
                  "name": "COLUMBIA",
                  "label": "COLUMBIA",
                  "value": "COLUMBIA"
              }
          ]
      },
      {
          "name": "PR",
          "label": "Puerto Rico",
          "value": "PR",
          "cities": [
              {
                  "name": "HUMACAO",
                  "label": "HUMACAO",
                  "value": "HUMACAO"
              },
              {
                  "name": "MAYAGUEZ",
                  "label": "MAYAGUEZ",
                  "value": "MAYAGUEZ"
              },
              {
                  "name": "CAROLINA",
                  "label": "CAROLINA",
                  "value": "CAROLINA"
              },
              {
                  "name": "SAN JUAN",
                  "label": "SAN JUAN",
                  "value": "SAN JUAN"
              },
              {
                  "name": "PONCE",
                  "label": "PONCE",
                  "value": "PONCE"
              },
              {
                  "name": "SANTA ISABEL",
                  "label": "SANTA ISABEL",
                  "value": "SANTA ISABEL"
              },
              {
                  "name": "BAYAMON",
                  "label": "BAYAMON",
                  "value": "BAYAMON"
              },
              {
                  "name": "CAGUAS",
                  "label": "CAGUAS",
                  "value": "CAGUAS"
              },
              {
                  "name": "MANATI",
                  "label": "MANATI",
                  "value": "MANATI"
              },
              {
                  "name": "AGUADILLA",
                  "label": "AGUADILLA",
                  "value": "AGUADILLA"
              },
              {
                  "name": "SANTURCE",
                  "label": "SANTURCE",
                  "value": "SANTURCE"
              },
              {
                  "name": "TOA ALTA",
                  "label": "TOA ALTA",
                  "value": "TOA ALTA"
              },
              {
                  "name": "GUAYNABO",
                  "label": "GUAYNABO",
                  "value": "GUAYNABO"
              },
              {
                  "name": "SAN GERMAN",
                  "label": "SAN GERMAN",
                  "value": "SAN GERMAN"
              },
              {
                  "name": "DORADO",
                  "label": "DORADO",
                  "value": "DORADO"
              },
              {
                  "name": "ARECIBO",
                  "label": "ARECIBO",
                  "value": "ARECIBO"
              },
              {
                  "name": "FAJARDO",
                  "label": "FAJARDO",
                  "value": "FAJARDO"
              },
              {
                  "name": "MERCEDITA",
                  "label": "MERCEDITA",
                  "value": "MERCEDITA"
              },
              {
                  "name": "HATO REY",
                  "label": "HATO REY",
                  "value": "HATO REY"
              },
              {
                  "name": "RIO PIEDRAS",
                  "label": "RIO PIEDRAS",
                  "value": "RIO PIEDRAS"
              },
              {
                  "name": "BARCELONETA",
                  "label": "BARCELONETA",
                  "value": "BARCELONETA"
              },
              {
                  "name": "ADJUNTAS",
                  "label": "ADJUNTAS",
                  "value": "ADJUNTAS"
              },
              {
                  "name": "CAYEY",
                  "label": "CAYEY",
                  "value": "CAYEY"
              },
              {
                  "name": "YAUCO",
                  "label": "YAUCO",
                  "value": "YAUCO"
              },
              {
                  "name": "TOA BAJA",
                  "label": "TOA BAJA",
                  "value": "TOA BAJA"
              }
          ]
      },
      {
          "name": "GU",
          "label": "Guam",
          "value": "GU",
          "cities": [
              {
                  "name": "TAMUNING",
                  "label": "TAMUNING",
                  "value": "TAMUNING"
              },
              {
                  "name": "DEDEDO",
                  "label": "DEDEDO",
                  "value": "DEDEDO"
              }
          ]
      },
      {
          "name": "VI",
          "label": "Virgin Islands",
          "value": "VI",
          "cities": [
              {
                  "name": "CHRISTIANSTED",
                  "label": "CHRISTIANSTED",
                  "value": "CHRISTIANSTED"
              },
              {
                  "name": "ST THOMAS",
                  "label": "ST THOMAS",
                  "value": "ST THOMAS"
              }
          ]
      }
  ],
  "WGtJH": [
      {
          "name": "ALL",
          "label": "ALL",
          "value": "ALL",
          "cities": []
      },
      {
          "name": "TN",
          "label": "Tennessee",
          "value": "TN",
          "cities": [
              {
                  "name": "MURFREESBORO",
                  "label": "MURFREESBORO",
                  "value": "MURFREESBORO"
              },
              {
                  "name": "NASHVILLE",
                  "label": "NASHVILLE",
                  "value": "NASHVILLE"
              },
              {
                  "name": "KNOXVILLE",
                  "label": "KNOXVILLE",
                  "value": "KNOXVILLE"
              },
              {
                  "name": "CHATTANOOGA",
                  "label": "CHATTANOOGA",
                  "value": "CHATTANOOGA"
              },
              {
                  "name": "OAK RIDGE",
                  "label": "OAK RIDGE",
                  "value": "OAK RIDGE"
              },
              {
                  "name": "GREENEVILLE",
                  "label": "GREENEVILLE",
                  "value": "GREENEVILLE"
              },
              {
                  "name": "ALCOA",
                  "label": "ALCOA",
                  "value": "ALCOA"
              },
              {
                  "name": "JOHNSON CITY",
                  "label": "JOHNSON CITY",
                  "value": "JOHNSON CITY"
              },
              {
                  "name": "GALLATIN",
                  "label": "GALLATIN",
                  "value": "GALLATIN"
              },
              {
                  "name": "COLUMBIA",
                  "label": "COLUMBIA",
                  "value": "COLUMBIA"
              },
              {
                  "name": "HERMITAGE",
                  "label": "HERMITAGE",
                  "value": "HERMITAGE"
              },
              {
                  "name": "POWELL",
                  "label": "POWELL",
                  "value": "POWELL"
              },
              {
                  "name": "SEVIERVILLE",
                  "label": "SEVIERVILLE",
                  "value": "SEVIERVILLE"
              },
              {
                  "name": "HENDERSONVILLE",
                  "label": "HENDERSONVILLE",
                  "value": "HENDERSONVILLE"
              },
              {
                  "name": "CLARKSVILLE",
                  "label": "CLARKSVILLE",
                  "value": "CLARKSVILLE"
              },
              {
                  "name": "KINGSPORT",
                  "label": "KINGSPORT",
                  "value": "KINGSPORT"
              },
              {
                  "name": "ATHENS",
                  "label": "ATHENS",
                  "value": "ATHENS"
              },
              {
                  "name": "CROSSVILLE",
                  "label": "CROSSVILLE",
                  "value": "CROSSVILLE"
              },
              {
                  "name": "DICKSON",
                  "label": "DICKSON",
                  "value": "DICKSON"
              },
              {
                  "name": "FRANKLIN",
                  "label": "FRANKLIN",
                  "value": "FRANKLIN"
              },
              {
                  "name": "LA FOLLETTE",
                  "label": "LA FOLLETTE",
                  "value": "LA FOLLETTE"
              },
              {
                  "name": "SPRING HILL",
                  "label": "SPRING HILL",
                  "value": "SPRING HILL"
              },
              {
                  "name": "CHURCH HILL",
                  "label": "CHURCH HILL",
                  "value": "CHURCH HILL"
              },
              {
                  "name": "MCMINNVILLE",
                  "label": "MCMINNVILLE",
                  "value": "MCMINNVILLE"
              },
              {
                  "name": "LENOIR CITY",
                  "label": "LENOIR CITY",
                  "value": "LENOIR CITY"
              },
              {
                  "name": "SHELBYVILLE",
                  "label": "SHELBYVILLE",
                  "value": "SHELBYVILLE"
              },
              {
                  "name": "COOKEVILLE",
                  "label": "COOKEVILLE",
                  "value": "COOKEVILLE"
              },
              {
                  "name": "MARYVILLE",
                  "label": "MARYVILLE",
                  "value": "MARYVILLE"
              },
              {
                  "name": "HARROGATE",
                  "label": "HARROGATE",
                  "value": "HARROGATE"
              },
              {
                  "name": "DANDRIDGE",
                  "label": "DANDRIDGE",
                  "value": "DANDRIDGE"
              },
              {
                  "name": "SPRINGFIELD",
                  "label": "SPRINGFIELD",
                  "value": "SPRINGFIELD"
              },
              {
                  "name": "MORRISTOWN",
                  "label": "MORRISTOWN",
                  "value": "MORRISTOWN"
              },
              {
                  "name": "SMYRNA",
                  "label": "SMYRNA",
                  "value": "SMYRNA"
              },
              {
                  "name": "MOUNTAIN HOME",
                  "label": "MOUNTAIN HOME",
                  "value": "MOUNTAIN HOME"
              },
              {
                  "name": "LAFOLLETTE",
                  "label": "LAFOLLETTE",
                  "value": "LAFOLLETTE"
              },
              {
                  "name": "CLEVELAND",
                  "label": "CLEVELAND",
                  "value": "CLEVELAND"
              },
              {
                  "name": "JONESBOROUGH",
                  "label": "JONESBOROUGH",
                  "value": "JONESBOROUGH"
              }
          ]
      },
      {
          "name": "OH",
          "label": "Ohio",
          "value": "OH",
          "cities": [
              {
                  "name": "COLUMBUS",
                  "label": "COLUMBUS",
                  "value": "COLUMBUS"
              },
              {
                  "name": "CINCINNATI",
                  "label": "CINCINNATI",
                  "value": "CINCINNATI"
              },
              {
                  "name": "CLEVELAND",
                  "label": "CLEVELAND",
                  "value": "CLEVELAND"
              },
              {
                  "name": "LIMA",
                  "label": "LIMA",
                  "value": "LIMA"
              },
              {
                  "name": "HARPSTER",
                  "label": "HARPSTER",
                  "value": "HARPSTER"
              },
              {
                  "name": "DAYTON",
                  "label": "DAYTON",
                  "value": "DAYTON"
              },
              {
                  "name": "SYLVANIA",
                  "label": "SYLVANIA",
                  "value": "SYLVANIA"
              },
              {
                  "name": "MARION",
                  "label": "MARION",
                  "value": "MARION"
              },
              {
                  "name": "TOLEDO",
                  "label": "TOLEDO",
                  "value": "TOLEDO"
              },
              {
                  "name": "AKRON",
                  "label": "AKRON",
                  "value": "AKRON"
              },
              {
                  "name": "ASHTABULA",
                  "label": "ASHTABULA",
                  "value": "ASHTABULA"
              },
              {
                  "name": "KETTERING",
                  "label": "KETTERING",
                  "value": "KETTERING"
              },
              {
                  "name": "NORTH CANTON",
                  "label": "NORTH CANTON",
                  "value": "NORTH CANTON"
              },
              {
                  "name": "ENGLEWOOD",
                  "label": "ENGLEWOOD",
                  "value": "ENGLEWOOD"
              },
              {
                  "name": "WRIGHT PATTERSON AFB",
                  "label": "WRIGHT PATTERSON AFB",
                  "value": "WRIGHT PATTERSON AFB"
              },
              {
                  "name": "LORAIN",
                  "label": "LORAIN",
                  "value": "LORAIN"
              },
              {
                  "name": "CHILLICOTHE",
                  "label": "CHILLICOTHE",
                  "value": "CHILLICOTHE"
              },
              {
                  "name": "HILLIARD",
                  "label": "HILLIARD",
                  "value": "HILLIARD"
              },
              {
                  "name": "AVON",
                  "label": "AVON",
                  "value": "AVON"
              },
              {
                  "name": "Mayfield Heights",
                  "label": "Mayfield Heights",
                  "value": "Mayfield Heights"
              },
              {
                  "name": "ALLIANCE",
                  "label": "ALLIANCE",
                  "value": "ALLIANCE"
              },
              {
                  "name": "PARMA",
                  "label": "PARMA",
                  "value": "PARMA"
              },
              {
                  "name": "FAIRFIELD",
                  "label": "FAIRFIELD",
                  "value": "FAIRFIELD"
              },
              {
                  "name": "SANDUSKY",
                  "label": "SANDUSKY",
                  "value": "SANDUSKY"
              },
              {
                  "name": "WESTERVILLE",
                  "label": "WESTERVILLE",
                  "value": "WESTERVILLE"
              },
              {
                  "name": "GALLIPOLIS",
                  "label": "GALLIPOLIS",
                  "value": "GALLIPOLIS"
              },
              {
                  "name": "WOOSTER",
                  "label": "WOOSTER",
                  "value": "WOOSTER"
              },
              {
                  "name": "CANTON",
                  "label": "CANTON",
                  "value": "CANTON"
              },
              {
                  "name": "FINDLAY",
                  "label": "FINDLAY",
                  "value": "FINDLAY"
              },
              {
                  "name": "MENTOR",
                  "label": "MENTOR",
                  "value": "MENTOR"
              },
              {
                  "name": "WESTLAKE",
                  "label": "WESTLAKE",
                  "value": "WESTLAKE"
              },
              {
                  "name": "YOUNGSTOWN",
                  "label": "YOUNGSTOWN",
                  "value": "YOUNGSTOWN"
              },
              {
                  "name": "ELYRIA",
                  "label": "ELYRIA",
                  "value": "ELYRIA"
              },
              {
                  "name": "MANSFIELD",
                  "label": "MANSFIELD",
                  "value": "MANSFIELD"
              },
              {
                  "name": "CHARDON",
                  "label": "CHARDON",
                  "value": "CHARDON"
              },
              {
                  "name": "HAMILTON",
                  "label": "HAMILTON",
                  "value": "HAMILTON"
              },
              {
                  "name": "NORWOOD",
                  "label": "NORWOOD",
                  "value": "NORWOOD"
              },
              {
                  "name": "LEBANON",
                  "label": "LEBANON",
                  "value": "LEBANON"
              },
              {
                  "name": "PLAIN CITY",
                  "label": "PLAIN CITY",
                  "value": "PLAIN CITY"
              },
              {
                  "name": "BATAVIA",
                  "label": "BATAVIA",
                  "value": "BATAVIA"
              },
              {
                  "name": "WILMINGTON",
                  "label": "WILMINGTON",
                  "value": "WILMINGTON"
              },
              {
                  "name": "WEST CHESTER",
                  "label": "WEST CHESTER",
                  "value": "WEST CHESTER"
              },
              {
                  "name": "WAUSEON",
                  "label": "WAUSEON",
                  "value": "WAUSEON"
              },
              {
                  "name": "CLEVELAND HEIGHTS",
                  "label": "CLEVELAND HEIGHTS",
                  "value": "CLEVELAND HEIGHTS"
              },
              {
                  "name": "BRYAN",
                  "label": "BRYAN",
                  "value": "BRYAN"
              },
              {
                  "name": "CAMBRIDGE",
                  "label": "CAMBRIDGE",
                  "value": "CAMBRIDGE"
              },
              {
                  "name": "ASHLAND",
                  "label": "ASHLAND",
                  "value": "ASHLAND"
              },
              {
                  "name": "BROOKLYN",
                  "label": "BROOKLYN",
                  "value": "BROOKLYN"
              },
              {
                  "name": "BEAVERCREEK",
                  "label": "BEAVERCREEK",
                  "value": "BEAVERCREEK"
              },
              {
                  "name": "UPPER SANDUSKY",
                  "label": "UPPER SANDUSKY",
                  "value": "UPPER SANDUSKY"
              },
              {
                  "name": "STEUBENVILLE",
                  "label": "STEUBENVILLE",
                  "value": "STEUBENVILLE"
              },
              {
                  "name": "MARIETTA",
                  "label": "MARIETTA",
                  "value": "MARIETTA"
              },
              {
                  "name": "MIDDLEBURG HTS",
                  "label": "MIDDLEBURG HTS",
                  "value": "MIDDLEBURG HTS"
              },
              {
                  "name": "OAKWOOD",
                  "label": "OAKWOOD",
                  "value": "OAKWOOD"
              },
              {
                  "name": "COPLEY",
                  "label": "COPLEY",
                  "value": "COPLEY"
              },
              {
                  "name": "WADSWORTH",
                  "label": "WADSWORTH",
                  "value": "WADSWORTH"
              },
              {
                  "name": "NORTH RIDGEVILLE",
                  "label": "NORTH RIDGEVILLE",
                  "value": "NORTH RIDGEVILLE"
              },
              {
                  "name": "OXFORD",
                  "label": "OXFORD",
                  "value": "OXFORD"
              },
              {
                  "name": "WARREN",
                  "label": "WARREN",
                  "value": "WARREN"
              },
              {
                  "name": "EUCLID",
                  "label": "EUCLID",
                  "value": "EUCLID"
              },
              {
                  "name": "NEWARK",
                  "label": "NEWARK",
                  "value": "NEWARK"
              },
              {
                  "name": "PORTSMOUTH",
                  "label": "PORTSMOUTH",
                  "value": "PORTSMOUTH"
              },
              {
                  "name": "ZANESVILLE",
                  "label": "ZANESVILLE",
                  "value": "ZANESVILLE"
              },
              {
                  "name": "RUSHVILLE",
                  "label": "RUSHVILLE",
                  "value": "RUSHVILLE"
              },
              {
                  "name": "COSHOCTON",
                  "label": "COSHOCTON",
                  "value": "COSHOCTON"
              },
              {
                  "name": "MOUNT VERNON",
                  "label": "MOUNT VERNON",
                  "value": "MOUNT VERNON"
              },
              {
                  "name": "FRANKLIN",
                  "label": "FRANKLIN",
                  "value": "FRANKLIN"
              },
              {
                  "name": "NEW BOSTON",
                  "label": "NEW BOSTON",
                  "value": "NEW BOSTON"
              },
              {
                  "name": "DUBLIN",
                  "label": "DUBLIN",
                  "value": "DUBLIN"
              },
              {
                  "name": "HOLLAND",
                  "label": "HOLLAND",
                  "value": "HOLLAND"
              },
              {
                  "name": "SALEM",
                  "label": "SALEM",
                  "value": "SALEM"
              },
              {
                  "name": "BARBERTON",
                  "label": "BARBERTON",
                  "value": "BARBERTON"
              },
              {
                  "name": "NORWALK",
                  "label": "NORWALK",
                  "value": "NORWALK"
              },
              {
                  "name": "COLDWATER",
                  "label": "COLDWATER",
                  "value": "COLDWATER"
              },
              {
                  "name": "SHAKER HEIGHTS",
                  "label": "SHAKER HEIGHTS",
                  "value": "SHAKER HEIGHTS"
              },
              {
                  "name": "WAPAKONETA",
                  "label": "WAPAKONETA",
                  "value": "WAPAKONETA"
              },
              {
                  "name": "LANCASTER",
                  "label": "LANCASTER",
                  "value": "LANCASTER"
              },
              {
                  "name": "FREMONT",
                  "label": "FREMONT",
                  "value": "FREMONT"
              },
              {
                  "name": "MASSILLON",
                  "label": "MASSILLON",
                  "value": "MASSILLON"
              },
              {
                  "name": "SPRINGFIELD",
                  "label": "SPRINGFIELD",
                  "value": "SPRINGFIELD"
              },
              {
                  "name": "NORTH OLMSTED",
                  "label": "NORTH OLMSTED",
                  "value": "NORTH OLMSTED"
              },
              {
                  "name": "RAVENNA",
                  "label": "RAVENNA",
                  "value": "RAVENNA"
              },
              {
                  "name": "LEESBURG",
                  "label": "LEESBURG",
                  "value": "LEESBURG"
              },
              {
                  "name": "SEAMAN",
                  "label": "SEAMAN",
                  "value": "SEAMAN"
              },
              {
                  "name": "MEDINA",
                  "label": "MEDINA",
                  "value": "MEDINA"
              },
              {
                  "name": "GEORGETOWN",
                  "label": "GEORGETOWN",
                  "value": "GEORGETOWN"
              },
              {
                  "name": "CANFIELD",
                  "label": "CANFIELD",
                  "value": "CANFIELD"
              },
              {
                  "name": "AVON LAKE",
                  "label": "AVON LAKE",
                  "value": "AVON LAKE"
              },
              {
                  "name": "YELLOW SPRINGS",
                  "label": "YELLOW SPRINGS",
                  "value": "YELLOW SPRINGS"
              },
              {
                  "name": "DELAWARE",
                  "label": "DELAWARE",
                  "value": "DELAWARE"
              },
              {
                  "name": "GALION",
                  "label": "GALION",
                  "value": "GALION"
              },
              {
                  "name": "JACKSON",
                  "label": "JACKSON",
                  "value": "JACKSON"
              },
              {
                  "name": "LONDON",
                  "label": "LONDON",
                  "value": "LONDON"
              },
              {
                  "name": "KENTON",
                  "label": "KENTON",
                  "value": "KENTON"
              },
              {
                  "name": "CLEVELEAND",
                  "label": "CLEVELEAND",
                  "value": "CLEVELEAND"
              },
              {
                  "name": "CIRCLEVILLE",
                  "label": "CIRCLEVILLE",
                  "value": "CIRCLEVILLE"
              },
              {
                  "name": "HILLSBORO",
                  "label": "HILLSBORO",
                  "value": "HILLSBORO"
              },
              {
                  "name": "GENEVA",
                  "label": "GENEVA",
                  "value": "GENEVA"
              },
              {
                  "name": "IRONTON",
                  "label": "IRONTON",
                  "value": "IRONTON"
              },
              {
                  "name": "PICKERINGTON",
                  "label": "PICKERINGTON",
                  "value": "PICKERINGTON"
              },
              {
                  "name": "MIDDLETOWN",
                  "label": "MIDDLETOWN",
                  "value": "MIDDLETOWN"
              },
              {
                  "name": "TROY",
                  "label": "TROY",
                  "value": "TROY"
              },
              {
                  "name": "WOODVILLE",
                  "label": "WOODVILLE",
                  "value": "WOODVILLE"
              },
              {
                  "name": "SIDNEY",
                  "label": "SIDNEY",
                  "value": "SIDNEY"
              },
              {
                  "name": "MARYSVILLE",
                  "label": "MARYSVILLE",
                  "value": "MARYSVILLE"
              },
              {
                  "name": "HIGHLAND HILLS",
                  "label": "HIGHLAND HILLS",
                  "value": "HIGHLAND HILLS"
              },
              {
                  "name": "FREDERICKTOWN",
                  "label": "FREDERICKTOWN",
                  "value": "FREDERICKTOWN"
              },
              {
                  "name": "GAHANNA",
                  "label": "GAHANNA",
                  "value": "GAHANNA"
              },
              {
                  "name": "MIAMISBURG",
                  "label": "MIAMISBURG",
                  "value": "MIAMISBURG"
              }
          ]
      },
      {
          "name": "MI",
          "label": "Michigan",
          "value": "MI",
          "cities": [
              {
                  "name": "ANN ARBOR",
                  "label": "ANN ARBOR",
                  "value": "ANN ARBOR"
              },
              {
                  "name": "ROCHESTER",
                  "label": "ROCHESTER",
                  "value": "ROCHESTER"
              },
              {
                  "name": "CADILLAC",
                  "label": "CADILLAC",
                  "value": "CADILLAC"
              },
              {
                  "name": "GRAND RAPIDS",
                  "label": "GRAND RAPIDS",
                  "value": "GRAND RAPIDS"
              },
              {
                  "name": "FARMINGTON",
                  "label": "FARMINGTON",
                  "value": "FARMINGTON"
              },
              {
                  "name": "COLDWATER",
                  "label": "COLDWATER",
                  "value": "COLDWATER"
              },
              {
                  "name": "WEST BRANCH",
                  "label": "WEST BRANCH",
                  "value": "WEST BRANCH"
              },
              {
                  "name": "KALAMAZOO",
                  "label": "KALAMAZOO",
                  "value": "KALAMAZOO"
              },
              {
                  "name": "ROSEVILLE",
                  "label": "ROSEVILLE",
                  "value": "ROSEVILLE"
              },
              {
                  "name": "HOLLY",
                  "label": "HOLLY",
                  "value": "HOLLY"
              },
              {
                  "name": "SOUTHFIELD",
                  "label": "SOUTHFIELD",
                  "value": "SOUTHFIELD"
              },
              {
                  "name": "DETROIT",
                  "label": "DETROIT",
                  "value": "DETROIT"
              },
              {
                  "name": "GAYLORD",
                  "label": "GAYLORD",
                  "value": "GAYLORD"
              },
              {
                  "name": "FREMONT",
                  "label": "FREMONT",
                  "value": "FREMONT"
              },
              {
                  "name": "SAGINAW",
                  "label": "SAGINAW",
                  "value": "SAGINAW"
              },
              {
                  "name": "PONTIAC",
                  "label": "PONTIAC",
                  "value": "PONTIAC"
              },
              {
                  "name": "ROYAL OAK",
                  "label": "ROYAL OAK",
                  "value": "ROYAL OAK"
              },
              {
                  "name": "MOUNT PLEASANT",
                  "label": "MOUNT PLEASANT",
                  "value": "MOUNT PLEASANT"
              },
              {
                  "name": "MACOMB",
                  "label": "MACOMB",
                  "value": "MACOMB"
              },
              {
                  "name": "SAULT SAINT MARIE",
                  "label": "SAULT SAINT MARIE",
                  "value": "SAULT SAINT MARIE"
              },
              {
                  "name": "IONIA",
                  "label": "IONIA",
                  "value": "IONIA"
              },
              {
                  "name": "BAY CITY",
                  "label": "BAY CITY",
                  "value": "BAY CITY"
              },
              {
                  "name": "IRON MOUNTAIN",
                  "label": "IRON MOUNTAIN",
                  "value": "IRON MOUNTAIN"
              },
              {
                  "name": "BATTLE CREEK",
                  "label": "BATTLE CREEK",
                  "value": "BATTLE CREEK"
              },
              {
                  "name": "WYANDOTTE",
                  "label": "WYANDOTTE",
                  "value": "WYANDOTTE"
              },
              {
                  "name": "WYOMING",
                  "label": "WYOMING",
                  "value": "WYOMING"
              },
              {
                  "name": "BROWNSTOWN",
                  "label": "BROWNSTOWN",
                  "value": "BROWNSTOWN"
              },
              {
                  "name": "FLINT",
                  "label": "FLINT",
                  "value": "FLINT"
              },
              {
                  "name": "BLOOMFIELD HILLS",
                  "label": "BLOOMFIELD HILLS",
                  "value": "BLOOMFIELD HILLS"
              },
              {
                  "name": "OAK PARK",
                  "label": "OAK PARK",
                  "value": "OAK PARK"
              },
              {
                  "name": "MONROE",
                  "label": "MONROE",
                  "value": "MONROE"
              },
              {
                  "name": "STERLING HEIGHTS",
                  "label": "STERLING HEIGHTS",
                  "value": "STERLING HEIGHTS"
              },
              {
                  "name": "LANSING",
                  "label": "LANSING",
                  "value": "LANSING"
              },
              {
                  "name": "SAINT JOSEPH",
                  "label": "SAINT JOSEPH",
                  "value": "SAINT JOSEPH"
              },
              {
                  "name": "EAST LANSING",
                  "label": "EAST LANSING",
                  "value": "EAST LANSING"
              },
              {
                  "name": "LAPEER",
                  "label": "LAPEER",
                  "value": "LAPEER"
              },
              {
                  "name": "YPSILANTI",
                  "label": "YPSILANTI",
                  "value": "YPSILANTI"
              },
              {
                  "name": "PORT HOPE",
                  "label": "PORT HOPE",
                  "value": "PORT HOPE"
              },
              {
                  "name": "CLINTON TOWNSHIP",
                  "label": "CLINTON TOWNSHIP",
                  "value": "CLINTON TOWNSHIP"
              },
              {
                  "name": "COMMERCE TOWNSHIP",
                  "label": "COMMERCE TOWNSHIP",
                  "value": "COMMERCE TOWNSHIP"
              },
              {
                  "name": "PETOSKEY",
                  "label": "PETOSKEY",
                  "value": "PETOSKEY"
              },
              {
                  "name": "GROSSE POINTE",
                  "label": "GROSSE POINTE",
                  "value": "GROSSE POINTE"
              },
              {
                  "name": "MARSHALL",
                  "label": "MARSHALL",
                  "value": "MARSHALL"
              },
              {
                  "name": "NOVI",
                  "label": "NOVI",
                  "value": "NOVI"
              },
              {
                  "name": "DEARBORN",
                  "label": "DEARBORN",
                  "value": "DEARBORN"
              },
              {
                  "name": "ROCHESTER HILLS",
                  "label": "ROCHESTER HILLS",
                  "value": "ROCHESTER HILLS"
              },
              {
                  "name": "TROY",
                  "label": "TROY",
                  "value": "TROY"
              },
              {
                  "name": "OWOSSO",
                  "label": "OWOSSO",
                  "value": "OWOSSO"
              },
              {
                  "name": "FARMINGTON HILLS",
                  "label": "FARMINGTON HILLS",
                  "value": "FARMINGTON HILLS"
              },
              {
                  "name": "GROSSE POINTE WOODS",
                  "label": "GROSSE POINTE WOODS",
                  "value": "GROSSE POINTE WOODS"
              },
              {
                  "name": "REED CITY",
                  "label": "REED CITY",
                  "value": "REED CITY"
              },
              {
                  "name": "BROOKLYN",
                  "label": "BROOKLYN",
                  "value": "BROOKLYN"
              },
              {
                  "name": "GREENVILLE",
                  "label": "GREENVILLE",
                  "value": "GREENVILLE"
              },
              {
                  "name": "GRAND BLANC",
                  "label": "GRAND BLANC",
                  "value": "GRAND BLANC"
              },
              {
                  "name": "MUSKEGON",
                  "label": "MUSKEGON",
                  "value": "MUSKEGON"
              },
              {
                  "name": "MARQUETTE",
                  "label": "MARQUETTE",
                  "value": "MARQUETTE"
              },
              {
                  "name": "OKEMOS",
                  "label": "OKEMOS",
                  "value": "OKEMOS"
              },
              {
                  "name": "JACKSON",
                  "label": "JACKSON",
                  "value": "JACKSON"
              },
              {
                  "name": "GLADWIN",
                  "label": "GLADWIN",
                  "value": "GLADWIN"
              },
              {
                  "name": "MT PLEASANT",
                  "label": "MT PLEASANT",
                  "value": "MT PLEASANT"
              },
              {
                  "name": "HANCOCK",
                  "label": "HANCOCK",
                  "value": "HANCOCK"
              },
              {
                  "name": "MIDLAND",
                  "label": "MIDLAND",
                  "value": "MIDLAND"
              },
              {
                  "name": "CHELSEA",
                  "label": "CHELSEA",
                  "value": "CHELSEA"
              },
              {
                  "name": "WEST BLOOMFIELD",
                  "label": "WEST BLOOMFIELD",
                  "value": "WEST BLOOMFIELD"
              },
              {
                  "name": "ESCANABA",
                  "label": "ESCANABA",
                  "value": "ESCANABA"
              },
              {
                  "name": "TRAVERSE CITY",
                  "label": "TRAVERSE CITY",
                  "value": "TRAVERSE CITY"
              },
              {
                  "name": "TAYLOR",
                  "label": "TAYLOR",
                  "value": "TAYLOR"
              },
              {
                  "name": "CHARLEVOIX",
                  "label": "CHARLEVOIX",
                  "value": "CHARLEVOIX"
              },
              {
                  "name": "MADISON HEIGHTS",
                  "label": "MADISON HEIGHTS",
                  "value": "MADISON HEIGHTS"
              },
              {
                  "name": "CLARKSTON",
                  "label": "CLARKSTON",
                  "value": "CLARKSTON"
              },
              {
                  "name": "BIG RAPIDS",
                  "label": "BIG RAPIDS",
                  "value": "BIG RAPIDS"
              },
              {
                  "name": "LIVONIA",
                  "label": "LIVONIA",
                  "value": "LIVONIA"
              },
              {
                  "name": "CLINTON TWP",
                  "label": "CLINTON TWP",
                  "value": "CLINTON TWP"
              },
              {
                  "name": "ORION",
                  "label": "ORION",
                  "value": "ORION"
              },
              {
                  "name": "HOLLAND",
                  "label": "HOLLAND",
                  "value": "HOLLAND"
              },
              {
                  "name": "LUDINGTON",
                  "label": "LUDINGTON",
                  "value": "LUDINGTON"
              },
              {
                  "name": "MOUNT CLEMENS",
                  "label": "MOUNT CLEMENS",
                  "value": "MOUNT CLEMENS"
              },
              {
                  "name": "BRIGHTON",
                  "label": "BRIGHTON",
                  "value": "BRIGHTON"
              },
              {
                  "name": "HAMTRAMCK",
                  "label": "HAMTRAMCK",
                  "value": "HAMTRAMCK"
              },
              {
                  "name": "PIGEON",
                  "label": "PIGEON",
                  "value": "PIGEON"
              },
              {
                  "name": "PORT HURON",
                  "label": "PORT HURON",
                  "value": "PORT HURON"
              },
              {
                  "name": "KINGSFORD",
                  "label": "KINGSFORD",
                  "value": "KINGSFORD"
              },
              {
                  "name": "BROWNSTOWN TWP",
                  "label": "BROWNSTOWN TWP",
                  "value": "BROWNSTOWN TWP"
              },
              {
                  "name": "ALPENA",
                  "label": "ALPENA",
                  "value": "ALPENA"
              },
              {
                  "name": "LAURIUM",
                  "label": "LAURIUM",
                  "value": "LAURIUM"
              },
              {
                  "name": "GRAND HAVEN",
                  "label": "GRAND HAVEN",
                  "value": "GRAND HAVEN"
              },
              {
                  "name": "BROCKWAY",
                  "label": "BROCKWAY",
                  "value": "BROCKWAY"
              },
              {
                  "name": "WOODHAVEN",
                  "label": "WOODHAVEN",
                  "value": "WOODHAVEN"
              },
              {
                  "name": "GROSSE PTE WDS",
                  "label": "GROSSE PTE WDS",
                  "value": "GROSSE PTE WDS"
              },
              {
                  "name": "NORTON SHORES",
                  "label": "NORTON SHORES",
                  "value": "NORTON SHORES"
              },
              {
                  "name": "WARREN",
                  "label": "WARREN",
                  "value": "WARREN"
              },
              {
                  "name": "IMLAY CITY",
                  "label": "IMLAY CITY",
                  "value": "IMLAY CITY"
              },
              {
                  "name": "IRONWOOD",
                  "label": "IRONWOOD",
                  "value": "IRONWOOD"
              },
              {
                  "name": "REDFORD",
                  "label": "REDFORD",
                  "value": "REDFORD"
              },
              {
                  "name": "DOWAGIAC",
                  "label": "DOWAGIAC",
                  "value": "DOWAGIAC"
              },
              {
                  "name": "SHELBY TWP",
                  "label": "SHELBY TWP",
                  "value": "SHELBY TWP"
              },
              {
                  "name": "SOUTH HAVEN",
                  "label": "SOUTH HAVEN",
                  "value": "SOUTH HAVEN"
              },
              {
                  "name": "GRAYLING",
                  "label": "GRAYLING",
                  "value": "GRAYLING"
              },
              {
                  "name": "ALLEN PARK",
                  "label": "ALLEN PARK",
                  "value": "ALLEN PARK"
              },
              {
                  "name": "FORT GRATIOT",
                  "label": "FORT GRATIOT",
                  "value": "FORT GRATIOT"
              },
              {
                  "name": "TAWAS CITY",
                  "label": "TAWAS CITY",
                  "value": "TAWAS CITY"
              },
              {
                  "name": "HASTINGS",
                  "label": "HASTINGS",
                  "value": "HASTINGS"
              },
              {
                  "name": "THREE RIVERS",
                  "label": "THREE RIVERS",
                  "value": "THREE RIVERS"
              },
              {
                  "name": "ALMA",
                  "label": "ALMA",
                  "value": "ALMA"
              },
              {
                  "name": "HIGHLAND",
                  "label": "HIGHLAND",
                  "value": "HIGHLAND"
              },
              {
                  "name": "KALKASKA",
                  "label": "KALKASKA",
                  "value": "KALKASKA"
              }
          ]
      },
      {
          "name": "WI",
          "label": "Wisconsin",
          "value": "WI",
          "cities": [
              {
                  "name": "GREEN BAY",
                  "label": "GREEN BAY",
                  "value": "GREEN BAY"
              },
              {
                  "name": "APPLETON",
                  "label": "APPLETON",
                  "value": "APPLETON"
              },
              {
                  "name": "MADISON",
                  "label": "MADISON",
                  "value": "MADISON"
              },
              {
                  "name": "MILWAUKEE",
                  "label": "MILWAUKEE",
                  "value": "MILWAUKEE"
              },
              {
                  "name": "MARSHFIELD",
                  "label": "MARSHFIELD",
                  "value": "MARSHFIELD"
              },
              {
                  "name": "EAU CLAIRE",
                  "label": "EAU CLAIRE",
                  "value": "EAU CLAIRE"
              },
              {
                  "name": "KESHENA",
                  "label": "KESHENA",
                  "value": "KESHENA"
              },
              {
                  "name": "WEST ALLIS",
                  "label": "WEST ALLIS",
                  "value": "WEST ALLIS"
              },
              {
                  "name": "NEENAH",
                  "label": "NEENAH",
                  "value": "NEENAH"
              },
              {
                  "name": "ASHLAND",
                  "label": "ASHLAND",
                  "value": "ASHLAND"
              },
              {
                  "name": "STEVENS POINT",
                  "label": "STEVENS POINT",
                  "value": "STEVENS POINT"
              },
              {
                  "name": "MINOCQUA",
                  "label": "MINOCQUA",
                  "value": "MINOCQUA"
              },
              {
                  "name": "WAUKESHA",
                  "label": "WAUKESHA",
                  "value": "WAUKESHA"
              },
              {
                  "name": "KENOSHA",
                  "label": "KENOSHA",
                  "value": "KENOSHA"
              },
              {
                  "name": "BURLINGTON",
                  "label": "BURLINGTON",
                  "value": "BURLINGTON"
              },
              {
                  "name": "Barron",
                  "label": "Barron",
                  "value": "Barron"
              },
              {
                  "name": "LA CROSSE",
                  "label": "LA CROSSE",
                  "value": "LA CROSSE"
              },
              {
                  "name": "OSCEOLA",
                  "label": "OSCEOLA",
                  "value": "OSCEOLA"
              },
              {
                  "name": "WAUWATOSA",
                  "label": "WAUWATOSA",
                  "value": "WAUWATOSA"
              },
              {
                  "name": "HARTLAND",
                  "label": "HARTLAND",
                  "value": "HARTLAND"
              },
              {
                  "name": "RICE LAKE",
                  "label": "RICE LAKE",
                  "value": "RICE LAKE"
              },
              {
                  "name": "WAUSAU",
                  "label": "WAUSAU",
                  "value": "WAUSAU"
              },
              {
                  "name": "TWO RIVERS",
                  "label": "TWO RIVERS",
                  "value": "TWO RIVERS"
              },
              {
                  "name": "WISCONSIN RAPIDS",
                  "label": "WISCONSIN RAPIDS",
                  "value": "WISCONSIN RAPIDS"
              },
              {
                  "name": "MIDDLETON",
                  "label": "MIDDLETON",
                  "value": "MIDDLETON"
              },
              {
                  "name": "OCONTO",
                  "label": "OCONTO",
                  "value": "OCONTO"
              },
              {
                  "name": "MARINETTE",
                  "label": "MARINETTE",
                  "value": "MARINETTE"
              },
              {
                  "name": "PLEASANT PRAIRIE",
                  "label": "PLEASANT PRAIRIE",
                  "value": "PLEASANT PRAIRIE"
              },
              {
                  "name": "FOND DU LAC",
                  "label": "FOND DU LAC",
                  "value": "FOND DU LAC"
              },
              {
                  "name": "NEW RICHMOND",
                  "label": "NEW RICHMOND",
                  "value": "NEW RICHMOND"
              },
              {
                  "name": "OCONOMOWOC",
                  "label": "OCONOMOWOC",
                  "value": "OCONOMOWOC"
              },
              {
                  "name": "JANESVILLE",
                  "label": "JANESVILLE",
                  "value": "JANESVILLE"
              },
              {
                  "name": "SHEBOYGAN",
                  "label": "SHEBOYGAN",
                  "value": "SHEBOYGAN"
              },
              {
                  "name": "OSHKOSH",
                  "label": "OSHKOSH",
                  "value": "OSHKOSH"
              },
              {
                  "name": "LUXEMBURG",
                  "label": "LUXEMBURG",
                  "value": "LUXEMBURG"
              },
              {
                  "name": "BROOKFIELD",
                  "label": "BROOKFIELD",
                  "value": "BROOKFIELD"
              },
              {
                  "name": "KAUKAUNA",
                  "label": "KAUKAUNA",
                  "value": "KAUKAUNA"
              },
              {
                  "name": "TOMAH",
                  "label": "TOMAH",
                  "value": "TOMAH"
              },
              {
                  "name": "RACINE",
                  "label": "RACINE",
                  "value": "RACINE"
              },
              {
                  "name": "GRAFTON",
                  "label": "GRAFTON",
                  "value": "GRAFTON"
              },
              {
                  "name": "GLENDALE",
                  "label": "GLENDALE",
                  "value": "GLENDALE"
              },
              {
                  "name": "BELOIT",
                  "label": "BELOIT",
                  "value": "BELOIT"
              },
              {
                  "name": "BLACK RIVER FALLS",
                  "label": "BLACK RIVER FALLS",
                  "value": "BLACK RIVER FALLS"
              },
              {
                  "name": "FORT ATKINSON",
                  "label": "FORT ATKINSON",
                  "value": "FORT ATKINSON"
              },
              {
                  "name": "ALTOONA",
                  "label": "ALTOONA",
                  "value": "ALTOONA"
              },
              {
                  "name": "BALDWIN",
                  "label": "BALDWIN",
                  "value": "BALDWIN"
              },
              {
                  "name": "NEW BERLIN",
                  "label": "NEW BERLIN",
                  "value": "NEW BERLIN"
              },
              {
                  "name": "ONALASKA",
                  "label": "ONALASKA",
                  "value": "ONALASKA"
              },
              {
                  "name": "BERLIN",
                  "label": "BERLIN",
                  "value": "BERLIN"
              },
              {
                  "name": "WEST BEND",
                  "label": "WEST BEND",
                  "value": "WEST BEND"
              },
              {
                  "name": "REEDSBURG",
                  "label": "REEDSBURG",
                  "value": "REEDSBURG"
              },
              {
                  "name": "MEQUON",
                  "label": "MEQUON",
                  "value": "MEQUON"
              },
              {
                  "name": "OCONTO FALLS",
                  "label": "OCONTO FALLS",
                  "value": "OCONTO FALLS"
              },
              {
                  "name": "MONROE",
                  "label": "MONROE",
                  "value": "MONROE"
              },
              {
                  "name": "NEW LONDON",
                  "label": "NEW LONDON",
                  "value": "NEW LONDON"
              },
              {
                  "name": "AMERY",
                  "label": "AMERY",
                  "value": "AMERY"
              },
              {
                  "name": "RHINELANDER",
                  "label": "RHINELANDER",
                  "value": "RHINELANDER"
              },
              {
                  "name": "MENASHA",
                  "label": "MENASHA",
                  "value": "MENASHA"
              },
              {
                  "name": "FRANKLIN",
                  "label": "FRANKLIN",
                  "value": "FRANKLIN"
              },
              {
                  "name": "GERMANTOWN",
                  "label": "GERMANTOWN",
                  "value": "GERMANTOWN"
              },
              {
                  "name": "MENOMONEE FALLS",
                  "label": "MENOMONEE FALLS",
                  "value": "MENOMONEE FALLS"
              },
              {
                  "name": "SUMMIT",
                  "label": "SUMMIT",
                  "value": "SUMMIT"
              },
              {
                  "name": "SAINT CROIX FALLS",
                  "label": "SAINT CROIX FALLS",
                  "value": "SAINT CROIX FALLS"
              },
              {
                  "name": "MUKWONAGO",
                  "label": "MUKWONAGO",
                  "value": "MUKWONAGO"
              },
              {
                  "name": "SULLIVAN",
                  "label": "SULLIVAN",
                  "value": "SULLIVAN"
              },
              {
                  "name": "PRAIRIE DU SAC",
                  "label": "PRAIRIE DU SAC",
                  "value": "PRAIRIE DU SAC"
              },
              {
                  "name": "DE PERE",
                  "label": "DE PERE",
                  "value": "DE PERE"
              }
          ]
      },
      {
          "name": "IA",
          "label": "Iowa",
          "value": "IA",
          "cities": [
              {
                  "name": "RED OAK",
                  "label": "RED OAK",
                  "value": "RED OAK"
              },
              {
                  "name": "DES MOINES",
                  "label": "DES MOINES",
                  "value": "DES MOINES"
              },
              {
                  "name": "IOWA CITY",
                  "label": "IOWA CITY",
                  "value": "IOWA CITY"
              },
              {
                  "name": "SIOUX CITY",
                  "label": "SIOUX CITY",
                  "value": "SIOUX CITY"
              },
              {
                  "name": "CRESTON",
                  "label": "CRESTON",
                  "value": "CRESTON"
              },
              {
                  "name": "MT PLEASANT",
                  "label": "MT PLEASANT",
                  "value": "MT PLEASANT"
              },
              {
                  "name": "WATERLOO",
                  "label": "WATERLOO",
                  "value": "WATERLOO"
              },
              {
                  "name": "CEDAR RAPIDS",
                  "label": "CEDAR RAPIDS",
                  "value": "CEDAR RAPIDS"
              },
              {
                  "name": "DECORAH",
                  "label": "DECORAH",
                  "value": "DECORAH"
              },
              {
                  "name": "AMES",
                  "label": "AMES",
                  "value": "AMES"
              },
              {
                  "name": "MASON CITY",
                  "label": "MASON CITY",
                  "value": "MASON CITY"
              },
              {
                  "name": "CLINTON",
                  "label": "CLINTON",
                  "value": "CLINTON"
              },
              {
                  "name": "MARENGO",
                  "label": "MARENGO",
                  "value": "MARENGO"
              },
              {
                  "name": "HARLAN",
                  "label": "HARLAN",
                  "value": "HARLAN"
              },
              {
                  "name": "SHENANDOAH",
                  "label": "SHENANDOAH",
                  "value": "SHENANDOAH"
              },
              {
                  "name": "MO VALLEY",
                  "label": "MO VALLEY",
                  "value": "MO VALLEY"
              },
              {
                  "name": "DAVENPORT",
                  "label": "DAVENPORT",
                  "value": "DAVENPORT"
              },
              {
                  "name": "FORT DODGE",
                  "label": "FORT DODGE",
                  "value": "FORT DODGE"
              },
              {
                  "name": "WEST DES MOINES",
                  "label": "WEST DES MOINES",
                  "value": "WEST DES MOINES"
              },
              {
                  "name": "DENISON",
                  "label": "DENISON",
                  "value": "DENISON"
              },
              {
                  "name": "BETTENDORF",
                  "label": "BETTENDORF",
                  "value": "BETTENDORF"
              },
              {
                  "name": "COUNCIL BLUFFS",
                  "label": "COUNCIL BLUFFS",
                  "value": "COUNCIL BLUFFS"
              },
              {
                  "name": "DUBUQUE",
                  "label": "DUBUQUE",
                  "value": "DUBUQUE"
              },
              {
                  "name": "SPENCER",
                  "label": "SPENCER",
                  "value": "SPENCER"
              },
              {
                  "name": "BELLE PLAINE",
                  "label": "BELLE PLAINE",
                  "value": "BELLE PLAINE"
              },
              {
                  "name": "WEST BURLINGTON",
                  "label": "WEST BURLINGTON",
                  "value": "WEST BURLINGTON"
              },
              {
                  "name": "CARROLL",
                  "label": "CARROLL",
                  "value": "CARROLL"
              },
              {
                  "name": "ATLANTIC",
                  "label": "ATLANTIC",
                  "value": "ATLANTIC"
              }
          ]
      },
      {
          "name": "IL",
          "label": "Illinois",
          "value": "IL",
          "cities": [
              {
                  "name": "CHICAGO",
                  "label": "CHICAGO",
                  "value": "CHICAGO"
              },
              {
                  "name": "SILVIS",
                  "label": "SILVIS",
                  "value": "SILVIS"
              },
              {
                  "name": "MELROSE PARK",
                  "label": "MELROSE PARK",
                  "value": "MELROSE PARK"
              },
              {
                  "name": "MCHENRY",
                  "label": "MCHENRY",
                  "value": "MCHENRY"
              },
              {
                  "name": "ROLLING MEADOWS",
                  "label": "ROLLING MEADOWS",
                  "value": "ROLLING MEADOWS"
              },
              {
                  "name": "SPRINGFIELD",
                  "label": "SPRINGFIELD",
                  "value": "SPRINGFIELD"
              },
              {
                  "name": "CHICAGO RIDGE",
                  "label": "CHICAGO RIDGE",
                  "value": "CHICAGO RIDGE"
              },
              {
                  "name": "ELMHURST",
                  "label": "ELMHURST",
                  "value": "ELMHURST"
              },
              {
                  "name": "URBANA",
                  "label": "URBANA",
                  "value": "URBANA"
              },
              {
                  "name": "NILES",
                  "label": "NILES",
                  "value": "NILES"
              },
              {
                  "name": "NAPERVILLE",
                  "label": "NAPERVILLE",
                  "value": "NAPERVILLE"
              },
              {
                  "name": "PEORIA",
                  "label": "PEORIA",
                  "value": "PEORIA"
              },
              {
                  "name": "DOWNERS GROVE",
                  "label": "DOWNERS GROVE",
                  "value": "DOWNERS GROVE"
              },
              {
                  "name": "BARRINGTON",
                  "label": "BARRINGTON",
                  "value": "BARRINGTON"
              },
              {
                  "name": "BURR RIDGE",
                  "label": "BURR RIDGE",
                  "value": "BURR RIDGE"
              },
              {
                  "name": "MAYWWOD",
                  "label": "MAYWWOD",
                  "value": "MAYWWOD"
              },
              {
                  "name": "CARTERVILLE",
                  "label": "CARTERVILLE",
                  "value": "CARTERVILLE"
              },
              {
                  "name": "ROCKFORD",
                  "label": "ROCKFORD",
                  "value": "ROCKFORD"
              },
              {
                  "name": "MOLINE",
                  "label": "MOLINE",
                  "value": "MOLINE"
              },
              {
                  "name": "OAK LAWN",
                  "label": "OAK LAWN",
                  "value": "OAK LAWN"
              },
              {
                  "name": "DECATUR",
                  "label": "DECATUR",
                  "value": "DECATUR"
              },
              {
                  "name": "SHILOH",
                  "label": "SHILOH",
                  "value": "SHILOH"
              },
              {
                  "name": "WARRENVILLE",
                  "label": "WARRENVILLE",
                  "value": "WARRENVILLE"
              },
              {
                  "name": "KANKAKEE",
                  "label": "KANKAKEE",
                  "value": "KANKAKEE"
              },
              {
                  "name": "HINSDALE",
                  "label": "HINSDALE",
                  "value": "HINSDALE"
              },
              {
                  "name": "PALOS HEIGHTS",
                  "label": "PALOS HEIGHTS",
                  "value": "PALOS HEIGHTS"
              },
              {
                  "name": "HAZEL CREST",
                  "label": "HAZEL CREST",
                  "value": "HAZEL CREST"
              },
              {
                  "name": "ELGIN",
                  "label": "ELGIN",
                  "value": "ELGIN"
              },
              {
                  "name": "ZION",
                  "label": "ZION",
                  "value": "ZION"
              },
              {
                  "name": "TINLEY PARK",
                  "label": "TINLEY PARK",
                  "value": "TINLEY PARK"
              },
              {
                  "name": "HARVEY",
                  "label": "HARVEY",
                  "value": "HARVEY"
              },
              {
                  "name": "PARK RIDGE",
                  "label": "PARK RIDGE",
                  "value": "PARK RIDGE"
              },
              {
                  "name": "EVANSTON",
                  "label": "EVANSTON",
                  "value": "EVANSTON"
              },
              {
                  "name": "AURORA",
                  "label": "AURORA",
                  "value": "AURORA"
              },
              {
                  "name": "LIBERTYVILLE",
                  "label": "LIBERTYVILLE",
                  "value": "LIBERTYVILLE"
              },
              {
                  "name": "GENEVA",
                  "label": "GENEVA",
                  "value": "GENEVA"
              },
              {
                  "name": "MOUNT VERNON",
                  "label": "MOUNT VERNON",
                  "value": "MOUNT VERNON"
              },
              {
                  "name": "QUINCY",
                  "label": "QUINCY",
                  "value": "QUINCY"
              },
              {
                  "name": "CARBONDALE",
                  "label": "CARBONDALE",
                  "value": "CARBONDALE"
              },
              {
                  "name": "JOLIET",
                  "label": "JOLIET",
                  "value": "JOLIET"
              },
              {
                  "name": "LOCKPORT",
                  "label": "LOCKPORT",
                  "value": "LOCKPORT"
              },
              {
                  "name": "SHELBYVILLE",
                  "label": "SHELBYVILLE",
                  "value": "SHELBYVILLE"
              },
              {
                  "name": "BERWYN",
                  "label": "BERWYN",
                  "value": "BERWYN"
              },
              {
                  "name": "NEW LENOX",
                  "label": "NEW LENOX",
                  "value": "NEW LENOX"
              },
              {
                  "name": "LISLE",
                  "label": "LISLE",
                  "value": "LISLE"
              },
              {
                  "name": "DEERFIELD",
                  "label": "DEERFIELD",
                  "value": "DEERFIELD"
              },
              {
                  "name": "MATTOON",
                  "label": "MATTOON",
                  "value": "MATTOON"
              },
              {
                  "name": "HUNTLEY",
                  "label": "HUNTLEY",
                  "value": "HUNTLEY"
              },
              {
                  "name": "ROSEMONT",
                  "label": "ROSEMONT",
                  "value": "ROSEMONT"
              },
              {
                  "name": "CHAMPAIGN",
                  "label": "CHAMPAIGN",
                  "value": "CHAMPAIGN"
              },
              {
                  "name": "CAROL STREAM",
                  "label": "CAROL STREAM",
                  "value": "CAROL STREAM"
              },
              {
                  "name": "CRYSTAL LAKE",
                  "label": "CRYSTAL LAKE",
                  "value": "CRYSTAL LAKE"
              },
              {
                  "name": "DARIEN",
                  "label": "DARIEN",
                  "value": "DARIEN"
              },
              {
                  "name": "MOKENA",
                  "label": "MOKENA",
                  "value": "MOKENA"
              },
              {
                  "name": "GURNEE",
                  "label": "GURNEE",
                  "value": "GURNEE"
              },
              {
                  "name": "SKOKIE",
                  "label": "SKOKIE",
                  "value": "SKOKIE"
              },
              {
                  "name": "NORMAL",
                  "label": "NORMAL",
                  "value": "NORMAL"
              },
              {
                  "name": "NORTH AURORA",
                  "label": "NORTH AURORA",
                  "value": "NORTH AURORA"
              },
              {
                  "name": "BOLINGBROOK",
                  "label": "BOLINGBROOK",
                  "value": "BOLINGBROOK"
              },
              {
                  "name": "DEKALB",
                  "label": "DEKALB",
                  "value": "DEKALB"
              },
              {
                  "name": "LAKEMOOR",
                  "label": "LAKEMOOR",
                  "value": "LAKEMOOR"
              },
              {
                  "name": "STERLING",
                  "label": "STERLING",
                  "value": "STERLING"
              },
              {
                  "name": "MARION",
                  "label": "MARION",
                  "value": "MARION"
              },
              {
                  "name": "HOMEWOOD",
                  "label": "HOMEWOOD",
                  "value": "HOMEWOOD"
              },
              {
                  "name": "NORTH CHICAGO",
                  "label": "NORTH CHICAGO",
                  "value": "NORTH CHICAGO"
              },
              {
                  "name": "MARQUETTE HEIGHTS",
                  "label": "MARQUETTE HEIGHTS",
                  "value": "MARQUETTE HEIGHTS"
              },
              {
                  "name": "HIGHLAND PARK",
                  "label": "HIGHLAND PARK",
                  "value": "HIGHLAND PARK"
              },
              {
                  "name": "ARLNGTN HTS",
                  "label": "ARLNGTN HTS",
                  "value": "ARLNGTN HTS"
              },
              {
                  "name": "EFFINGHAM",
                  "label": "EFFINGHAM",
                  "value": "EFFINGHAM"
              },
              {
                  "name": "MARYVILLE",
                  "label": "MARYVILLE",
                  "value": "MARYVILLE"
              },
              {
                  "name": "GLENVIEW",
                  "label": "GLENVIEW",
                  "value": "GLENVIEW"
              },
              {
                  "name": "PITTSFIELD",
                  "label": "PITTSFIELD",
                  "value": "PITTSFIELD"
              },
              {
                  "name": "BLOOMINGTON",
                  "label": "BLOOMINGTON",
                  "value": "BLOOMINGTON"
              },
              {
                  "name": "HINES",
                  "label": "HINES",
                  "value": "HINES"
              },
              {
                  "name": "ROCK ISLAND",
                  "label": "ROCK ISLAND",
                  "value": "ROCK ISLAND"
              },
              {
                  "name": "BELLEVILLE",
                  "label": "BELLEVILLE",
                  "value": "BELLEVILLE"
              },
              {
                  "name": "WINFIELD",
                  "label": "WINFIELD",
                  "value": "WINFIELD"
              },
              {
                  "name": "MORTON",
                  "label": "MORTON",
                  "value": "MORTON"
              },
              {
                  "name": "NORTHBROOK",
                  "label": "NORTHBROOK",
                  "value": "NORTHBROOK"
              },
              {
                  "name": "LAKE FOREST",
                  "label": "LAKE FOREST",
                  "value": "LAKE FOREST"
              },
              {
                  "name": "OTTAWA",
                  "label": "OTTAWA",
                  "value": "OTTAWA"
              },
              {
                  "name": "EVERGREEN PARK",
                  "label": "EVERGREEN PARK",
                  "value": "EVERGREEN PARK"
              },
              {
                  "name": "MT CARMEL",
                  "label": "MT CARMEL",
                  "value": "MT CARMEL"
              },
              {
                  "name": "ARLINGTON HEIGHTS",
                  "label": "ARLINGTON HEIGHTS",
                  "value": "ARLINGTON HEIGHTS"
              },
              {
                  "name": "ELK GROVE VILLAGE",
                  "label": "ELK GROVE VILLAGE",
                  "value": "ELK GROVE VILLAGE"
              },
              {
                  "name": "NORTHFIELD",
                  "label": "NORTHFIELD",
                  "value": "NORTHFIELD"
              },
              {
                  "name": "WAUKEGAN",
                  "label": "WAUKEGAN",
                  "value": "WAUKEGAN"
              },
              {
                  "name": "CARLINVILLE",
                  "label": "CARLINVILLE",
                  "value": "CARLINVILLE"
              },
              {
                  "name": "O FALLON",
                  "label": "O FALLON",
                  "value": "O FALLON"
              },
              {
                  "name": "ALTON",
                  "label": "ALTON",
                  "value": "ALTON"
              },
              {
                  "name": "ORLAND PARK",
                  "label": "ORLAND PARK",
                  "value": "ORLAND PARK"
              },
              {
                  "name": "OLYMPIA FIELDS",
                  "label": "OLYMPIA FIELDS",
                  "value": "OLYMPIA FIELDS"
              },
              {
                  "name": "GRANITE CITY",
                  "label": "GRANITE CITY",
                  "value": "GRANITE CITY"
              },
              {
                  "name": "HAINESVILLE",
                  "label": "HAINESVILLE",
                  "value": "HAINESVILLE"
              },
              {
                  "name": "SANDWICH",
                  "label": "SANDWICH",
                  "value": "SANDWICH"
              },
              {
                  "name": "EUREKA",
                  "label": "EUREKA",
                  "value": "EUREKA"
              },
              {
                  "name": "MORRISON",
                  "label": "MORRISON",
                  "value": "MORRISON"
              },
              {
                  "name": "DANVILLE",
                  "label": "DANVILLE",
                  "value": "DANVILLE"
              },
              {
                  "name": "CHESTER",
                  "label": "CHESTER",
                  "value": "CHESTER"
              },
              {
                  "name": "FREEPORT",
                  "label": "FREEPORT",
                  "value": "FREEPORT"
              },
              {
                  "name": "DESPLAINES",
                  "label": "DESPLAINES",
                  "value": "DESPLAINES"
              }
          ]
      },
      {
          "name": "KY",
          "label": "Kentucky",
          "value": "KY",
          "cities": [
              {
                  "name": "LOUISVILLE",
                  "label": "LOUISVILLE",
                  "value": "LOUISVILLE"
              },
              {
                  "name": "GEORGETOWN",
                  "label": "GEORGETOWN",
                  "value": "GEORGETOWN"
              },
              {
                  "name": "BOWLING GREEN",
                  "label": "BOWLING GREEN",
                  "value": "BOWLING GREEN"
              },
              {
                  "name": "NICHOLASVILLE",
                  "label": "NICHOLASVILLE",
                  "value": "NICHOLASVILLE"
              },
              {
                  "name": "CORBIN",
                  "label": "CORBIN",
                  "value": "CORBIN"
              },
              {
                  "name": "ELIZABETHTOWN",
                  "label": "ELIZABETHTOWN",
                  "value": "ELIZABETHTOWN"
              },
              {
                  "name": "PADUCAH",
                  "label": "PADUCAH",
                  "value": "PADUCAH"
              },
              {
                  "name": "ASHLAND",
                  "label": "ASHLAND",
                  "value": "ASHLAND"
              },
              {
                  "name": "HOPKINSVILLE",
                  "label": "HOPKINSVILLE",
                  "value": "HOPKINSVILLE"
              },
              {
                  "name": "COLUMBIA",
                  "label": "COLUMBIA",
                  "value": "COLUMBIA"
              },
              {
                  "name": "LEXINGTON",
                  "label": "LEXINGTON",
                  "value": "LEXINGTON"
              },
              {
                  "name": "FLORENCE",
                  "label": "FLORENCE",
                  "value": "FLORENCE"
              },
              {
                  "name": "DANVILLE",
                  "label": "DANVILLE",
                  "value": "DANVILLE"
              },
              {
                  "name": "HAZARD",
                  "label": "HAZARD",
                  "value": "HAZARD"
              },
              {
                  "name": "PIKEVILLE",
                  "label": "PIKEVILLE",
                  "value": "PIKEVILLE"
              },
              {
                  "name": "CRESTWOOD",
                  "label": "CRESTWOOD",
                  "value": "CRESTWOOD"
              },
              {
                  "name": "MANCHESTER",
                  "label": "MANCHESTER",
                  "value": "MANCHESTER"
              },
              {
                  "name": "COVINGTON",
                  "label": "COVINGTON",
                  "value": "COVINGTON"
              },
              {
                  "name": "FRANKFORT",
                  "label": "FRANKFORT",
                  "value": "FRANKFORT"
              },
              {
                  "name": "HARLAN",
                  "label": "HARLAN",
                  "value": "HARLAN"
              },
              {
                  "name": "EDGEWOOD",
                  "label": "EDGEWOOD",
                  "value": "EDGEWOOD"
              },
              {
                  "name": "HARRODSBURG",
                  "label": "HARRODSBURG",
                  "value": "HARRODSBURG"
              },
              {
                  "name": "GLASGOW",
                  "label": "GLASGOW",
                  "value": "GLASGOW"
              },
              {
                  "name": "LEITCHFIELD",
                  "label": "LEITCHFIELD",
                  "value": "LEITCHFIELD"
              },
              {
                  "name": "FRANKLIN",
                  "label": "FRANKLIN",
                  "value": "FRANKLIN"
              },
              {
                  "name": "MOREHEAD",
                  "label": "MOREHEAD",
                  "value": "MOREHEAD"
              },
              {
                  "name": "HENDERSON",
                  "label": "HENDERSON",
                  "value": "HENDERSON"
              },
              {
                  "name": "MADISONVILLE",
                  "label": "MADISONVILLE",
                  "value": "MADISONVILLE"
              },
              {
                  "name": "SOMERSET",
                  "label": "SOMERSET",
                  "value": "SOMERSET"
              },
              {
                  "name": "MIDDLESBORO",
                  "label": "MIDDLESBORO",
                  "value": "MIDDLESBORO"
              },
              {
                  "name": "BARDSTOWN",
                  "label": "BARDSTOWN",
                  "value": "BARDSTOWN"
              },
              {
                  "name": "JACKSON",
                  "label": "JACKSON",
                  "value": "JACKSON"
              },
              {
                  "name": "SOUTH WILLIAMSON",
                  "label": "SOUTH WILLIAMSON",
                  "value": "SOUTH WILLIAMSON"
              },
              {
                  "name": "MOUNT VERNON",
                  "label": "MOUNT VERNON",
                  "value": "MOUNT VERNON"
              },
              {
                  "name": "OWENSBORO",
                  "label": "OWENSBORO",
                  "value": "OWENSBORO"
              },
              {
                  "name": "MURRAY",
                  "label": "MURRAY",
                  "value": "MURRAY"
              },
              {
                  "name": "SHELBYVILLE",
                  "label": "SHELBYVILLE",
                  "value": "SHELBYVILLE"
              },
              {
                  "name": "ERLANGER",
                  "label": "ERLANGER",
                  "value": "ERLANGER"
              },
              {
                  "name": "BRANDENBURG",
                  "label": "BRANDENBURG",
                  "value": "BRANDENBURG"
              },
              {
                  "name": "PRESTONSBURG",
                  "label": "PRESTONSBURG",
                  "value": "PRESTONSBURG"
              },
              {
                  "name": "HIGHLAND HEIGHTS",
                  "label": "HIGHLAND HEIGHTS",
                  "value": "HIGHLAND HEIGHTS"
              },
              {
                  "name": "LAGRANGE",
                  "label": "LAGRANGE",
                  "value": "LAGRANGE"
              },
              {
                  "name": "LONDON",
                  "label": "LONDON",
                  "value": "LONDON"
              },
              {
                  "name": "MAYSVILLE",
                  "label": "MAYSVILLE",
                  "value": "MAYSVILLE"
              },
              {
                  "name": "FT THOMAS",
                  "label": "FT THOMAS",
                  "value": "FT THOMAS"
              },
              {
                  "name": "MOUNT STERLING",
                  "label": "MOUNT STERLING",
                  "value": "MOUNT STERLING"
              },
              {
                  "name": "WINCHESTER",
                  "label": "WINCHESTER",
                  "value": "WINCHESTER"
              }
          ]
      },
      {
          "name": "MN",
          "label": "Minnesota",
          "value": "MN",
          "cities": [
              {
                  "name": "ROCHESTER",
                  "label": "ROCHESTER",
                  "value": "ROCHESTER"
              },
              {
                  "name": "FARIBAULT",
                  "label": "FARIBAULT",
                  "value": "FARIBAULT"
              },
              {
                  "name": "ST LOUIS PARK",
                  "label": "ST LOUIS PARK",
                  "value": "ST LOUIS PARK"
              },
              {
                  "name": "MINNEAPOLIS",
                  "label": "MINNEAPOLIS",
                  "value": "MINNEAPOLIS"
              },
              {
                  "name": "BURNSVILLE",
                  "label": "BURNSVILLE",
                  "value": "BURNSVILLE"
              },
              {
                  "name": "RED WING",
                  "label": "RED WING",
                  "value": "RED WING"
              },
              {
                  "name": "HIBBING",
                  "label": "HIBBING",
                  "value": "HIBBING"
              },
              {
                  "name": "ST PAUL",
                  "label": "ST PAUL",
                  "value": "ST PAUL"
              },
              {
                  "name": "SAINT CLOUD",
                  "label": "SAINT CLOUD",
                  "value": "SAINT CLOUD"
              },
              {
                  "name": "BRAINERD",
                  "label": "BRAINERD",
                  "value": "BRAINERD"
              },
              {
                  "name": "DULUTH",
                  "label": "DULUTH",
                  "value": "DULUTH"
              },
              {
                  "name": "BEMIDJI",
                  "label": "BEMIDJI",
                  "value": "BEMIDJI"
              },
              {
                  "name": "PLYMOUTH",
                  "label": "PLYMOUTH",
                  "value": "PLYMOUTH"
              },
              {
                  "name": "COON RAPIDS",
                  "label": "COON RAPIDS",
                  "value": "COON RAPIDS"
              },
              {
                  "name": "EDINA",
                  "label": "EDINA",
                  "value": "EDINA"
              },
              {
                  "name": "SAINT PAUL",
                  "label": "SAINT PAUL",
                  "value": "SAINT PAUL"
              },
              {
                  "name": "ALBERT LEA",
                  "label": "ALBERT LEA",
                  "value": "ALBERT LEA"
              },
              {
                  "name": "ROSEVILLE",
                  "label": "ROSEVILLE",
                  "value": "ROSEVILLE"
              },
              {
                  "name": "ST CLOUD",
                  "label": "ST CLOUD",
                  "value": "ST CLOUD"
              },
              {
                  "name": "MONTEVIDEO",
                  "label": "MONTEVIDEO",
                  "value": "MONTEVIDEO"
              },
              {
                  "name": "MARSHALL",
                  "label": "MARSHALL",
                  "value": "MARSHALL"
              },
              {
                  "name": "WILLMAR",
                  "label": "WILLMAR",
                  "value": "WILLMAR"
              },
              {
                  "name": "WORTHINGTON",
                  "label": "WORTHINGTON",
                  "value": "WORTHINGTON"
              },
              {
                  "name": "MAPLEWOOD",
                  "label": "MAPLEWOOD",
                  "value": "MAPLEWOOD"
              },
              {
                  "name": "ROBBINSDALE",
                  "label": "ROBBINSDALE",
                  "value": "ROBBINSDALE"
              },
              {
                  "name": "CHASKA",
                  "label": "CHASKA",
                  "value": "CHASKA"
              },
              {
                  "name": "E GRAND FORKS",
                  "label": "E GRAND FORKS",
                  "value": "E GRAND FORKS"
              },
              {
                  "name": "MANKATO",
                  "label": "MANKATO",
                  "value": "MANKATO"
              },
              {
                  "name": "ALEXANDRIA",
                  "label": "ALEXANDRIA",
                  "value": "ALEXANDRIA"
              },
              {
                  "name": "WOODBURY",
                  "label": "WOODBURY",
                  "value": "WOODBURY"
              },
              {
                  "name": "STILLWATER",
                  "label": "STILLWATER",
                  "value": "STILLWATER"
              },
              {
                  "name": "CLOQUET",
                  "label": "CLOQUET",
                  "value": "CLOQUET"
              },
              {
                  "name": "GRAND RAPIDS",
                  "label": "GRAND RAPIDS",
                  "value": "GRAND RAPIDS"
              },
              {
                  "name": "ROGERS",
                  "label": "ROGERS",
                  "value": "ROGERS"
              },
              {
                  "name": "EDEN PRAIRIE",
                  "label": "EDEN PRAIRIE",
                  "value": "EDEN PRAIRIE"
              },
              {
                  "name": "HUTCHINSON",
                  "label": "HUTCHINSON",
                  "value": "HUTCHINSON"
              },
              {
                  "name": "MORA",
                  "label": "MORA",
                  "value": "MORA"
              },
              {
                  "name": "PAYNESVILLE",
                  "label": "PAYNESVILLE",
                  "value": "PAYNESVILLE"
              },
              {
                  "name": "FRIDLEY",
                  "label": "FRIDLEY",
                  "value": "FRIDLEY"
              },
              {
                  "name": "MENDOTA HEIGHTS",
                  "label": "MENDOTA HEIGHTS",
                  "value": "MENDOTA HEIGHTS"
              },
              {
                  "name": "CROOKSTON",
                  "label": "CROOKSTON",
                  "value": "CROOKSTON"
              },
              {
                  "name": "CAMBRIDGE",
                  "label": "CAMBRIDGE",
                  "value": "CAMBRIDGE"
              },
              {
                  "name": "THIEF RIVER FALLS",
                  "label": "THIEF RIVER FALLS",
                  "value": "THIEF RIVER FALLS"
              },
              {
                  "name": "OAKDALE",
                  "label": "OAKDALE",
                  "value": "OAKDALE"
              },
              {
                  "name": "ROSEAU",
                  "label": "ROSEAU",
                  "value": "ROSEAU"
              },
              {
                  "name": "SAINT LOUIS PARK",
                  "label": "SAINT LOUIS PARK",
                  "value": "SAINT LOUIS PARK"
              },
              {
                  "name": "LAKEVILLE",
                  "label": "LAKEVILLE",
                  "value": "LAKEVILLE"
              },
              {
                  "name": "WYOMING",
                  "label": "WYOMING",
                  "value": "WYOMING"
              },
              {
                  "name": "BLOOMINGTON",
                  "label": "BLOOMINGTON",
                  "value": "BLOOMINGTON"
              },
              {
                  "name": "OSSEO",
                  "label": "OSSEO",
                  "value": "OSSEO"
              },
              {
                  "name": "NORTHFIELD",
                  "label": "NORTHFIELD",
                  "value": "NORTHFIELD"
              },
              {
                  "name": "FERGUS FALLS",
                  "label": "FERGUS FALLS",
                  "value": "FERGUS FALLS"
              },
              {
                  "name": "RICHFIELD",
                  "label": "RICHFIELD",
                  "value": "RICHFIELD"
              },
              {
                  "name": "BUFFALO",
                  "label": "BUFFALO",
                  "value": "BUFFALO"
              },
              {
                  "name": "BAXTER",
                  "label": "BAXTER",
                  "value": "BAXTER"
              },
              {
                  "name": "WACONIA",
                  "label": "WACONIA",
                  "value": "WACONIA"
              },
              {
                  "name": "WINONA",
                  "label": "WINONA",
                  "value": "WINONA"
              },
              {
                  "name": "FAIRMONT",
                  "label": "FAIRMONT",
                  "value": "FAIRMONT"
              }
          ]
      },
      {
          "name": "MO",
          "label": "Missouri",
          "value": "MO",
          "cities": [
              {
                  "name": "JEFFERSON CITY",
                  "label": "JEFFERSON CITY",
                  "value": "JEFFERSON CITY"
              },
              {
                  "name": "SPRINGFIELD",
                  "label": "SPRINGFIELD",
                  "value": "SPRINGFIELD"
              },
              {
                  "name": "CHESTERFIELD",
                  "label": "CHESTERFIELD",
                  "value": "CHESTERFIELD"
              },
              {
                  "name": "JOPLIN",
                  "label": "JOPLIN",
                  "value": "JOPLIN"
              },
              {
                  "name": "INDEPENDENCE",
                  "label": "INDEPENDENCE",
                  "value": "INDEPENDENCE"
              },
              {
                  "name": "SAINT LOUIS",
                  "label": "SAINT LOUIS",
                  "value": "SAINT LOUIS"
              },
              {
                  "name": "ST. LOUIS",
                  "label": "ST. LOUIS",
                  "value": "ST. LOUIS"
              },
              {
                  "name": "LAKE SAINT LOUIS",
                  "label": "LAKE SAINT LOUIS",
                  "value": "LAKE SAINT LOUIS"
              },
              {
                  "name": "KANSAS CITY",
                  "label": "KANSAS CITY",
                  "value": "KANSAS CITY"
              },
              {
                  "name": "COLUMBIA",
                  "label": "COLUMBIA",
                  "value": "COLUMBIA"
              },
              {
                  "name": "WEST PLAINS",
                  "label": "WEST PLAINS",
                  "value": "WEST PLAINS"
              },
              {
                  "name": "SEDALIA",
                  "label": "SEDALIA",
                  "value": "SEDALIA"
              },
              {
                  "name": "LIBERTY",
                  "label": "LIBERTY",
                  "value": "LIBERTY"
              },
              {
                  "name": "ST LOUIS",
                  "label": "ST LOUIS",
                  "value": "ST LOUIS"
              },
              {
                  "name": "MONETT",
                  "label": "MONETT",
                  "value": "MONETT"
              },
              {
                  "name": "SAINT JOSEPH",
                  "label": "SAINT JOSEPH",
                  "value": "SAINT JOSEPH"
              },
              {
                  "name": "ROLLA",
                  "label": "ROLLA",
                  "value": "ROLLA"
              },
              {
                  "name": "POPLAR BLUFF",
                  "label": "POPLAR BLUFF",
                  "value": "POPLAR BLUFF"
              },
              {
                  "name": "BRANSON",
                  "label": "BRANSON",
                  "value": "BRANSON"
              },
              {
                  "name": "HANNIBAL",
                  "label": "HANNIBAL",
                  "value": "HANNIBAL"
              },
              {
                  "name": "NORTH KANSAS CITY",
                  "label": "NORTH KANSAS CITY",
                  "value": "NORTH KANSAS CITY"
              },
              {
                  "name": "FENTON",
                  "label": "FENTON",
                  "value": "FENTON"
              },
              {
                  "name": "ST CHARLES",
                  "label": "ST CHARLES",
                  "value": "ST CHARLES"
              },
              {
                  "name": "AURORA",
                  "label": "AURORA",
                  "value": "AURORA"
              },
              {
                  "name": "LEES SUMMIT",
                  "label": "LEES SUMMIT",
                  "value": "LEES SUMMIT"
              },
              {
                  "name": "MEXICO",
                  "label": "MEXICO",
                  "value": "MEXICO"
              },
              {
                  "name": "FESTUS",
                  "label": "FESTUS",
                  "value": "FESTUS"
              },
              {
                  "name": "BRIDGETON",
                  "label": "BRIDGETON",
                  "value": "BRIDGETON"
              },
              {
                  "name": "CAPE GIRARDEAU",
                  "label": "CAPE GIRARDEAU",
                  "value": "CAPE GIRARDEAU"
              },
              {
                  "name": "WASHINGTON",
                  "label": "WASHINGTON",
                  "value": "WASHINGTON"
              },
              {
                  "name": "OSAGE BEACH",
                  "label": "OSAGE BEACH",
                  "value": "OSAGE BEACH"
              },
              {
                  "name": "N KANSAS CITY",
                  "label": "N KANSAS CITY",
                  "value": "N KANSAS CITY"
              },
              {
                  "name": "CLARKSON VALLEY",
                  "label": "CLARKSON VALLEY",
                  "value": "CLARKSON VALLEY"
              },
              {
                  "name": "BLUE SPRINGS",
                  "label": "BLUE SPRINGS",
                  "value": "BLUE SPRINGS"
              },
              {
                  "name": "CASSVILLE",
                  "label": "CASSVILLE",
                  "value": "CASSVILLE"
              },
              {
                  "name": "FARMINGTON",
                  "label": "FARMINGTON",
                  "value": "FARMINGTON"
              },
              {
                  "name": "BOLIVAR",
                  "label": "BOLIVAR",
                  "value": "BOLIVAR"
              },
              {
                  "name": "AFFTON",
                  "label": "AFFTON",
                  "value": "AFFTON"
              },
              {
                  "name": "RAYMORE",
                  "label": "RAYMORE",
                  "value": "RAYMORE"
              },
              {
                  "name": "SAINT CHARLES",
                  "label": "SAINT CHARLES",
                  "value": "SAINT CHARLES"
              },
              {
                  "name": "CLINTON",
                  "label": "CLINTON",
                  "value": "CLINTON"
              },
              {
                  "name": "CREVE COEUR",
                  "label": "CREVE COEUR",
                  "value": "CREVE COEUR"
              },
              {
                  "name": "CHILLICOTHE",
                  "label": "CHILLICOTHE",
                  "value": "CHILLICOTHE"
              },
              {
                  "name": "LEBANON",
                  "label": "LEBANON",
                  "value": "LEBANON"
              },
              {
                  "name": "MEMPHIS",
                  "label": "MEMPHIS",
                  "value": "MEMPHIS"
              },
              {
                  "name": "SAINT PETERS",
                  "label": "SAINT PETERS",
                  "value": "SAINT PETERS"
              }
          ]
      },
      {
          "name": "IN",
          "label": "Indiana",
          "value": "IN",
          "cities": [
              {
                  "name": "AVON",
                  "label": "AVON",
                  "value": "AVON"
              },
              {
                  "name": "MUNSTER",
                  "label": "MUNSTER",
                  "value": "MUNSTER"
              },
              {
                  "name": "LAFAYETTE",
                  "label": "LAFAYETTE",
                  "value": "LAFAYETTE"
              },
              {
                  "name": "FRANKLIN",
                  "label": "FRANKLIN",
                  "value": "FRANKLIN"
              },
              {
                  "name": "MISHAWAKA",
                  "label": "MISHAWAKA",
                  "value": "MISHAWAKA"
              },
              {
                  "name": "SOUTH BEND",
                  "label": "SOUTH BEND",
                  "value": "SOUTH BEND"
              },
              {
                  "name": "FLOYDS KNOBS",
                  "label": "FLOYDS KNOBS",
                  "value": "FLOYDS KNOBS"
              },
              {
                  "name": "INDIANAPOLIS",
                  "label": "INDIANAPOLIS",
                  "value": "INDIANAPOLIS"
              },
              {
                  "name": "NEWBURGH",
                  "label": "NEWBURGH",
                  "value": "NEWBURGH"
              },
              {
                  "name": "NOBLESVILLE",
                  "label": "NOBLESVILLE",
                  "value": "NOBLESVILLE"
              },
              {
                  "name": "FORT WAYNE",
                  "label": "FORT WAYNE",
                  "value": "FORT WAYNE"
              },
              {
                  "name": "VALPARAISO",
                  "label": "VALPARAISO",
                  "value": "VALPARAISO"
              },
              {
                  "name": "DYER",
                  "label": "DYER",
                  "value": "DYER"
              },
              {
                  "name": "LA PORTE",
                  "label": "LA PORTE",
                  "value": "LA PORTE"
              },
              {
                  "name": "GREENFIELD",
                  "label": "GREENFIELD",
                  "value": "GREENFIELD"
              },
              {
                  "name": "EVANSVILLE",
                  "label": "EVANSVILLE",
                  "value": "EVANSVILLE"
              },
              {
                  "name": "GOSHEN",
                  "label": "GOSHEN",
                  "value": "GOSHEN"
              },
              {
                  "name": "ELKHART",
                  "label": "ELKHART",
                  "value": "ELKHART"
              },
              {
                  "name": "MARTINSVILLE",
                  "label": "MARTINSVILLE",
                  "value": "MARTINSVILLE"
              },
              {
                  "name": "CORYDON",
                  "label": "CORYDON",
                  "value": "CORYDON"
              },
              {
                  "name": "ANDERSON",
                  "label": "ANDERSON",
                  "value": "ANDERSON"
              },
              {
                  "name": "MICHIGAN CITY",
                  "label": "MICHIGAN CITY",
                  "value": "MICHIGAN CITY"
              },
              {
                  "name": "TERRE HAUTE",
                  "label": "TERRE HAUTE",
                  "value": "TERRE HAUTE"
              },
              {
                  "name": "DANVILLE",
                  "label": "DANVILLE",
                  "value": "DANVILLE"
              },
              {
                  "name": "BOONVILLE",
                  "label": "BOONVILLE",
                  "value": "BOONVILLE"
              },
              {
                  "name": "GREENDALE",
                  "label": "GREENDALE",
                  "value": "GREENDALE"
              },
              {
                  "name": "JASPER",
                  "label": "JASPER",
                  "value": "JASPER"
              },
              {
                  "name": "MUNCIE",
                  "label": "MUNCIE",
                  "value": "MUNCIE"
              },
              {
                  "name": "RICHMOND",
                  "label": "RICHMOND",
                  "value": "RICHMOND"
              },
              {
                  "name": "CROWN POINT",
                  "label": "CROWN POINT",
                  "value": "CROWN POINT"
              },
              {
                  "name": "BLOOMINGTON",
                  "label": "BLOOMINGTON",
                  "value": "BLOOMINGTON"
              },
              {
                  "name": "VINCENNES",
                  "label": "VINCENNES",
                  "value": "VINCENNES"
              },
              {
                  "name": "COLUMBUS",
                  "label": "COLUMBUS",
                  "value": "COLUMBUS"
              },
              {
                  "name": "BEDFORD",
                  "label": "BEDFORD",
                  "value": "BEDFORD"
              },
              {
                  "name": "NEW ALBANY",
                  "label": "NEW ALBANY",
                  "value": "NEW ALBANY"
              },
              {
                  "name": "SEYMOUR",
                  "label": "SEYMOUR",
                  "value": "SEYMOUR"
              },
              {
                  "name": "MERRILLVILLE",
                  "label": "MERRILLVILLE",
                  "value": "MERRILLVILLE"
              },
              {
                  "name": "HOBART",
                  "label": "HOBART",
                  "value": "HOBART"
              },
              {
                  "name": "KOKOMO",
                  "label": "KOKOMO",
                  "value": "KOKOMO"
              },
              {
                  "name": "MARION",
                  "label": "MARION",
                  "value": "MARION"
              },
              {
                  "name": "FISHERS",
                  "label": "FISHERS",
                  "value": "FISHERS"
              },
              {
                  "name": "MOORESVILLE",
                  "label": "MOORESVILLE",
                  "value": "MOORESVILLE"
              },
              {
                  "name": "CLARKSVILLE",
                  "label": "CLARKSVILLE",
                  "value": "CLARKSVILLE"
              },
              {
                  "name": "LAWRENCEBURG",
                  "label": "LAWRENCEBURG",
                  "value": "LAWRENCEBURG"
              },
              {
                  "name": "JEFFERSONVILLE",
                  "label": "JEFFERSONVILLE",
                  "value": "JEFFERSONVILLE"
              },
              {
                  "name": "SHELBYVILLE",
                  "label": "SHELBYVILLE",
                  "value": "SHELBYVILLE"
              },
              {
                  "name": "WESTVILLE",
                  "label": "WESTVILLE",
                  "value": "WESTVILLE"
              },
              {
                  "name": "WARSAW",
                  "label": "WARSAW",
                  "value": "WARSAW"
              },
              {
                  "name": "GREENWOOD",
                  "label": "GREENWOOD",
                  "value": "GREENWOOD"
              },
              {
                  "name": "CARMEL",
                  "label": "CARMEL",
                  "value": "CARMEL"
              }
          ]
      },
      {
          "name": "SD",
          "label": "South Dakota",
          "value": "SD",
          "cities": [
              {
                  "name": "SIOUX FALLS",
                  "label": "SIOUX FALLS",
                  "value": "SIOUX FALLS"
              },
              {
                  "name": "WINNER",
                  "label": "WINNER",
                  "value": "WINNER"
              },
              {
                  "name": "FORT MEADE",
                  "label": "FORT MEADE",
                  "value": "FORT MEADE"
              },
              {
                  "name": "RAPID CITY",
                  "label": "RAPID CITY",
                  "value": "RAPID CITY"
              },
              {
                  "name": "VERMILLION",
                  "label": "VERMILLION",
                  "value": "VERMILLION"
              },
              {
                  "name": "ABERDEEN",
                  "label": "ABERDEEN",
                  "value": "ABERDEEN"
              },
              {
                  "name": "YANKTON",
                  "label": "YANKTON",
                  "value": "YANKTON"
              },
              {
                  "name": "WATERTOWN",
                  "label": "WATERTOWN",
                  "value": "WATERTOWN"
              },
              {
                  "name": "SPEARFISH",
                  "label": "SPEARFISH",
                  "value": "SPEARFISH"
              }
          ]
      },
      {
          "name": "ND",
          "label": "North Dakota",
          "value": "ND",
          "cities": [
              {
                  "name": "FARGO",
                  "label": "FARGO",
                  "value": "FARGO"
              },
              {
                  "name": "BISMARCK",
                  "label": "BISMARCK",
                  "value": "BISMARCK"
              },
              {
                  "name": "GRAND FORKS",
                  "label": "GRAND FORKS",
                  "value": "GRAND FORKS"
              },
              {
                  "name": "WILLISTON",
                  "label": "WILLISTON",
                  "value": "WILLISTON"
              },
              {
                  "name": "MINOT",
                  "label": "MINOT",
                  "value": "MINOT"
              },
              {
                  "name": "DICKINSON",
                  "label": "DICKINSON",
                  "value": "DICKINSON"
              }
          ]
      }
  ],
  "4W8ho": [
      {
          "name": "ALL",
          "label": "ALL",
          "value": "ALL",
          "cities": []
      },
      {
          "name": "NY",
          "label": "New York",
          "value": "NY",
          "cities": [
              {
                  "name": "BROOKLYN",
                  "label": "BROOKLYN",
                  "value": "BROOKLYN"
              },
              {
                  "name": "NEW YORK",
                  "label": "NEW YORK",
                  "value": "NEW YORK"
              },
              {
                  "name": "LAKE GROVE",
                  "label": "LAKE GROVE",
                  "value": "LAKE GROVE"
              },
              {
                  "name": "GREAT NECK",
                  "label": "GREAT NECK",
                  "value": "GREAT NECK"
              },
              {
                  "name": "TROY",
                  "label": "TROY",
                  "value": "TROY"
              },
              {
                  "name": "COOPERSTOWN",
                  "label": "COOPERSTOWN",
                  "value": "COOPERSTOWN"
              },
              {
                  "name": "BRONX",
                  "label": "BRONX",
                  "value": "BRONX"
              },
              {
                  "name": "ROCHESTER",
                  "label": "ROCHESTER",
                  "value": "ROCHESTER"
              },
              {
                  "name": "FLUSHING",
                  "label": "FLUSHING",
                  "value": "FLUSHING"
              },
              {
                  "name": "SYRACUSE",
                  "label": "SYRACUSE",
                  "value": "SYRACUSE"
              },
              {
                  "name": "ITHACA",
                  "label": "ITHACA",
                  "value": "ITHACA"
              },
              {
                  "name": "VALLEY COTTAGE",
                  "label": "VALLEY COTTAGE",
                  "value": "VALLEY COTTAGE"
              },
              {
                  "name": "EAST SYRACUSE",
                  "label": "EAST SYRACUSE",
                  "value": "EAST SYRACUSE"
              },
              {
                  "name": "OCEANSIDE",
                  "label": "OCEANSIDE",
                  "value": "OCEANSIDE"
              },
              {
                  "name": "GREENVALE",
                  "label": "GREENVALE",
                  "value": "GREENVALE"
              },
              {
                  "name": "ALBANY",
                  "label": "ALBANY",
                  "value": "ALBANY"
              },
              {
                  "name": "BUFFALO",
                  "label": "BUFFALO",
                  "value": "BUFFALO"
              },
              {
                  "name": "KINGSTON",
                  "label": "KINGSTON",
                  "value": "KINGSTON"
              },
              {
                  "name": "HEMPSTEAD",
                  "label": "HEMPSTEAD",
                  "value": "HEMPSTEAD"
              },
              {
                  "name": "WEST ISLIP",
                  "label": "WEST ISLIP",
                  "value": "WEST ISLIP"
              },
              {
                  "name": "STATEN ISLAND",
                  "label": "STATEN ISLAND",
                  "value": "STATEN ISLAND"
              },
              {
                  "name": "POUGHKEEPSIE",
                  "label": "POUGHKEEPSIE",
                  "value": "POUGHKEEPSIE"
              },
              {
                  "name": "YONKERS",
                  "label": "YONKERS",
                  "value": "YONKERS"
              },
              {
                  "name": "NEW HYDE PARK",
                  "label": "NEW HYDE PARK",
                  "value": "NEW HYDE PARK"
              },
              {
                  "name": "JAMAICA",
                  "label": "JAMAICA",
                  "value": "JAMAICA"
              },
              {
                  "name": "WHITE PLAINS",
                  "label": "WHITE PLAINS",
                  "value": "WHITE PLAINS"
              },
              {
                  "name": "UNIONDALE",
                  "label": "UNIONDALE",
                  "value": "UNIONDALE"
              },
              {
                  "name": "STONY BROOK",
                  "label": "STONY BROOK",
                  "value": "STONY BROOK"
              },
              {
                  "name": "CANDOR",
                  "label": "CANDOR",
                  "value": "CANDOR"
              },
              {
                  "name": "FRESH MEADOWS",
                  "label": "FRESH MEADOWS",
                  "value": "FRESH MEADOWS"
              },
              {
                  "name": "PATCHOGUE",
                  "label": "PATCHOGUE",
                  "value": "PATCHOGUE"
              },
              {
                  "name": "BROCKPORT",
                  "label": "BROCKPORT",
                  "value": "BROCKPORT"
              },
              {
                  "name": "WESTBURY",
                  "label": "WESTBURY",
                  "value": "WESTBURY"
              },
              {
                  "name": "CLAVERACK",
                  "label": "CLAVERACK",
                  "value": "CLAVERACK"
              },
              {
                  "name": "SUFFERN",
                  "label": "SUFFERN",
                  "value": "SUFFERN"
              },
              {
                  "name": "WILLIAMSVILLE",
                  "label": "WILLIAMSVILLE",
                  "value": "WILLIAMSVILLE"
              },
              {
                  "name": "LAKE SUCCESS",
                  "label": "LAKE SUCCESS",
                  "value": "LAKE SUCCESS"
              },
              {
                  "name": "GLENS FALLS",
                  "label": "GLENS FALLS",
                  "value": "GLENS FALLS"
              },
              {
                  "name": "KINGS PARK",
                  "label": "KINGS PARK",
                  "value": "KINGS PARK"
              },
              {
                  "name": "CHEEKTOWAGA",
                  "label": "CHEEKTOWAGA",
                  "value": "CHEEKTOWAGA"
              },
              {
                  "name": "HOGANSBURG",
                  "label": "HOGANSBURG",
                  "value": "HOGANSBURG"
              },
              {
                  "name": "MANHASSET",
                  "label": "MANHASSET",
                  "value": "MANHASSET"
              },
              {
                  "name": "HAWTHORNE",
                  "label": "HAWTHORNE",
                  "value": "HAWTHORNE"
              },
              {
                  "name": "WOODBURY",
                  "label": "WOODBURY",
                  "value": "WOODBURY"
              },
              {
                  "name": "FARMINGDALE",
                  "label": "FARMINGDALE",
                  "value": "FARMINGDALE"
              },
              {
                  "name": "JAMESTOWN",
                  "label": "JAMESTOWN",
                  "value": "JAMESTOWN"
              },
              {
                  "name": "EASTCHESTER",
                  "label": "EASTCHESTER",
                  "value": "EASTCHESTER"
              },
              {
                  "name": "SLEEPY HOLLOW",
                  "label": "SLEEPY HOLLOW",
                  "value": "SLEEPY HOLLOW"
              },
              {
                  "name": "PORT JEFFERSON",
                  "label": "PORT JEFFERSON",
                  "value": "PORT JEFFERSON"
              },
              {
                  "name": "HORNELL",
                  "label": "HORNELL",
                  "value": "HORNELL"
              },
              {
                  "name": "LATHAM",
                  "label": "LATHAM",
                  "value": "LATHAM"
              },
              {
                  "name": "WEST HARRISON",
                  "label": "WEST HARRISON",
                  "value": "WEST HARRISON"
              },
              {
                  "name": "PORT JEFFERSON STATION",
                  "label": "PORT JEFFERSON STATION",
                  "value": "PORT JEFFERSON STATION"
              },
              {
                  "name": "NORTHPORT",
                  "label": "NORTHPORT",
                  "value": "NORTHPORT"
              },
              {
                  "name": "CLIFTON PARK",
                  "label": "CLIFTON PARK",
                  "value": "CLIFTON PARK"
              },
              {
                  "name": "ELMIRA",
                  "label": "ELMIRA",
                  "value": "ELMIRA"
              },
              {
                  "name": "HUNTINGTON",
                  "label": "HUNTINGTON",
                  "value": "HUNTINGTON"
              },
              {
                  "name": "SHIRLEY",
                  "label": "SHIRLEY",
                  "value": "SHIRLEY"
              },
              {
                  "name": "ROCKVILLE CENTRE",
                  "label": "ROCKVILLE CENTRE",
                  "value": "ROCKVILLE CENTRE"
              },
              {
                  "name": "MIDDLETOWN",
                  "label": "MIDDLETOWN",
                  "value": "MIDDLETOWN"
              },
              {
                  "name": "VALHALLA",
                  "label": "VALHALLA",
                  "value": "VALHALLA"
              },
              {
                  "name": "CORTLAND",
                  "label": "CORTLAND",
                  "value": "CORTLAND"
              },
              {
                  "name": "NYACK",
                  "label": "NYACK",
                  "value": "NYACK"
              },
              {
                  "name": "SARATOGA SPRINGS",
                  "label": "SARATOGA SPRINGS",
                  "value": "SARATOGA SPRINGS"
              },
              {
                  "name": "OGDENSBURG",
                  "label": "OGDENSBURG",
                  "value": "OGDENSBURG"
              },
              {
                  "name": "LANCASTER",
                  "label": "LANCASTER",
                  "value": "LANCASTER"
              },
              {
                  "name": "PLATTSBURGH",
                  "label": "PLATTSBURGH",
                  "value": "PLATTSBURGH"
              },
              {
                  "name": "WELLSVILLE",
                  "label": "WELLSVILLE",
                  "value": "WELLSVILLE"
              },
              {
                  "name": "FISHKILL",
                  "label": "FISHKILL",
                  "value": "FISHKILL"
              },
              {
                  "name": "RIVERHEAD",
                  "label": "RIVERHEAD",
                  "value": "RIVERHEAD"
              },
              {
                  "name": "COMMACK",
                  "label": "COMMACK",
                  "value": "COMMACK"
              },
              {
                  "name": "OSWEGO",
                  "label": "OSWEGO",
                  "value": "OSWEGO"
              },
              {
                  "name": "LIVERPOOL",
                  "label": "LIVERPOOL",
                  "value": "LIVERPOOL"
              },
              {
                  "name": "EAST PATCHOGUE",
                  "label": "EAST PATCHOGUE",
                  "value": "EAST PATCHOGUE"
              },
              {
                  "name": "MINEOLA",
                  "label": "MINEOLA",
                  "value": "MINEOLA"
              },
              {
                  "name": "AUBURN",
                  "label": "AUBURN",
                  "value": "AUBURN"
              },
              {
                  "name": "JOHNSON CITY",
                  "label": "JOHNSON CITY",
                  "value": "JOHNSON CITY"
              },
              {
                  "name": "MONSEY",
                  "label": "MONSEY",
                  "value": "MONSEY"
              },
              {
                  "name": "KENMORE",
                  "label": "KENMORE",
                  "value": "KENMORE"
              },
              {
                  "name": "BELLEROSE",
                  "label": "BELLEROSE",
                  "value": "BELLEROSE"
              },
              {
                  "name": "CLIFTON SPRINGS",
                  "label": "CLIFTON SPRINGS",
                  "value": "CLIFTON SPRINGS"
              },
              {
                  "name": "WATERTOWN",
                  "label": "WATERTOWN",
                  "value": "WATERTOWN"
              },
              {
                  "name": "POTSDAM",
                  "label": "POTSDAM",
                  "value": "POTSDAM"
              },
              {
                  "name": "BREWSTER",
                  "label": "BREWSTER",
                  "value": "BREWSTER"
              },
              {
                  "name": "LYNBROOK",
                  "label": "LYNBROOK",
                  "value": "LYNBROOK"
              },
              {
                  "name": "ONEIDA",
                  "label": "ONEIDA",
                  "value": "ONEIDA"
              },
              {
                  "name": "UTICA",
                  "label": "UTICA",
                  "value": "UTICA"
              },
              {
                  "name": "OLEAN",
                  "label": "OLEAN",
                  "value": "OLEAN"
              },
              {
                  "name": "GLEN OAKS",
                  "label": "GLEN OAKS",
                  "value": "GLEN OAKS"
              },
              {
                  "name": "SARANAC LAKE",
                  "label": "SARANAC LAKE",
                  "value": "SARANAC LAKE"
              },
              {
                  "name": "FULTON",
                  "label": "FULTON",
                  "value": "FULTON"
              },
              {
                  "name": "SCARSDALE",
                  "label": "SCARSDALE",
                  "value": "SCARSDALE"
              },
              {
                  "name": "MOUNT KISCO",
                  "label": "MOUNT KISCO",
                  "value": "MOUNT KISCO"
              },
              {
                  "name": "ELLICOTTVILLE",
                  "label": "ELLICOTTVILLE",
                  "value": "ELLICOTTVILLE"
              },
              {
                  "name": "BALDWIN",
                  "label": "BALDWIN",
                  "value": "BALDWIN"
              },
              {
                  "name": "ELMHURST",
                  "label": "ELMHURST",
                  "value": "ELMHURST"
              },
              {
                  "name": "NORTH MERRICK",
                  "label": "NORTH MERRICK",
                  "value": "NORTH MERRICK"
              },
              {
                  "name": "BAYSHORE",
                  "label": "BAYSHORE",
                  "value": "BAYSHORE"
              },
              {
                  "name": "AMSTERDAM",
                  "label": "AMSTERDAM",
                  "value": "AMSTERDAM"
              },
              {
                  "name": "BAYSIDE",
                  "label": "BAYSIDE",
                  "value": "BAYSIDE"
              },
              {
                  "name": "RONKONKOMA",
                  "label": "RONKONKOMA",
                  "value": "RONKONKOMA"
              },
              {
                  "name": "LAKE RONKONKOMA",
                  "label": "LAKE RONKONKOMA",
                  "value": "LAKE RONKONKOMA"
              },
              {
                  "name": "GREENPORT",
                  "label": "GREENPORT",
                  "value": "GREENPORT"
              },
              {
                  "name": "BRONXVILLE",
                  "label": "BRONXVILLE",
                  "value": "BRONXVILLE"
              },
              {
                  "name": "TARRYTOWN",
                  "label": "TARRYTOWN",
                  "value": "TARRYTOWN"
              },
              {
                  "name": "MASTIC",
                  "label": "MASTIC",
                  "value": "MASTIC"
              },
              {
                  "name": "ROCKY POINT",
                  "label": "ROCKY POINT",
                  "value": "ROCKY POINT"
              },
              {
                  "name": "YORKTOWN HEIGHTS",
                  "label": "YORKTOWN HEIGHTS",
                  "value": "YORKTOWN HEIGHTS"
              },
              {
                  "name": "NEWBURGH",
                  "label": "NEWBURGH",
                  "value": "NEWBURGH"
              },
              {
                  "name": "BAY SHORE",
                  "label": "BAY SHORE",
                  "value": "BAY SHORE"
              },
              {
                  "name": "WARWICK",
                  "label": "WARWICK",
                  "value": "WARWICK"
              },
              {
                  "name": "BETHPAGE",
                  "label": "BETHPAGE",
                  "value": "BETHPAGE"
              },
              {
                  "name": "WHITE",
                  "label": "WHITE",
                  "value": "WHITE"
              },
              {
                  "name": "REGO PARK",
                  "label": "REGO PARK",
                  "value": "REGO PARK"
              },
              {
                  "name": "NEW CITY",
                  "label": "NEW CITY",
                  "value": "NEW CITY"
              },
              {
                  "name": "ONEONTA",
                  "label": "ONEONTA",
                  "value": "ONEONTA"
              },
              {
                  "name": "FOREST HILLS",
                  "label": "FOREST HILLS",
                  "value": "FOREST HILLS"
              },
              {
                  "name": "PLAINVIEW",
                  "label": "PLAINVIEW",
                  "value": "PLAINVIEW"
              },
              {
                  "name": "NANUET",
                  "label": "NANUET",
                  "value": "NANUET"
              },
              {
                  "name": "ROSLYN",
                  "label": "ROSLYN",
                  "value": "ROSLYN"
              },
              {
                  "name": "NORTH BABYLON",
                  "label": "NORTH BABYLON",
                  "value": "NORTH BABYLON"
              },
              {
                  "name": "EAST HAMPTON",
                  "label": "EAST HAMPTON",
                  "value": "EAST HAMPTON"
              },
              {
                  "name": "ORCHARD PARK",
                  "label": "ORCHARD PARK",
                  "value": "ORCHARD PARK"
              },
              {
                  "name": "SYOSSET",
                  "label": "SYOSSET",
                  "value": "SYOSSET"
              },
              {
                  "name": "BRENTWOOD",
                  "label": "BRENTWOOD",
                  "value": "BRENTWOOD"
              },
              {
                  "name": "GOSHEN",
                  "label": "GOSHEN",
                  "value": "GOSHEN"
              },
              {
                  "name": "PINE BUSH",
                  "label": "PINE BUSH",
                  "value": "PINE BUSH"
              },
              {
                  "name": "EAST MEADOW",
                  "label": "EAST MEADOW",
                  "value": "EAST MEADOW"
              },
              {
                  "name": "NEW ROCHELLE",
                  "label": "NEW ROCHELLE",
                  "value": "NEW ROCHELLE"
              },
              {
                  "name": "WOODSIDE",
                  "label": "WOODSIDE",
                  "value": "WOODSIDE"
              },
              {
                  "name": "NEW HARTFORD",
                  "label": "NEW HARTFORD",
                  "value": "NEW HARTFORD"
              },
              {
                  "name": "HUDSON",
                  "label": "HUDSON",
                  "value": "HUDSON"
              },
              {
                  "name": "MERRICK",
                  "label": "MERRICK",
                  "value": "MERRICK"
              },
              {
                  "name": "SMITHTOWN",
                  "label": "SMITHTOWN",
                  "value": "SMITHTOWN"
              },
              {
                  "name": "HORSEHEADS",
                  "label": "HORSEHEADS",
                  "value": "HORSEHEADS"
              },
              {
                  "name": "WEST SENECA",
                  "label": "WEST SENECA",
                  "value": "WEST SENECA"
              },
              {
                  "name": "SCHENECTADY",
                  "label": "SCHENECTADY",
                  "value": "SCHENECTADY"
              },
              {
                  "name": "LINDENHURST",
                  "label": "LINDENHURST",
                  "value": "LINDENHURST"
              },
              {
                  "name": "NISKAYUNA",
                  "label": "NISKAYUNA",
                  "value": "NISKAYUNA"
              },
              {
                  "name": "GANSEVOORT",
                  "label": "GANSEVOORT",
                  "value": "GANSEVOORT"
              },
              {
                  "name": "CORNWALL",
                  "label": "CORNWALL",
                  "value": "CORNWALL"
              },
              {
                  "name": "FARMINGVILLE",
                  "label": "FARMINGVILLE",
                  "value": "FARMINGVILLE"
              },
              {
                  "name": "RICHMOND HILL",
                  "label": "RICHMOND HILL",
                  "value": "RICHMOND HILL"
              },
              {
                  "name": "SOUTHAMPTON",
                  "label": "SOUTHAMPTON",
                  "value": "SOUTHAMPTON"
              },
              {
                  "name": "GREENLAWN",
                  "label": "GREENLAWN",
                  "value": "GREENLAWN"
              },
              {
                  "name": "PORT JERVIS",
                  "label": "PORT JERVIS",
                  "value": "PORT JERVIS"
              },
              {
                  "name": "VOORHEESVILLE",
                  "label": "VOORHEESVILLE",
                  "value": "VOORHEESVILLE"
              },
              {
                  "name": "GLENDALE",
                  "label": "GLENDALE",
                  "value": "GLENDALE"
              },
              {
                  "name": "HUDSON FALLS",
                  "label": "HUDSON FALLS",
                  "value": "HUDSON FALLS"
              },
              {
                  "name": "ELMA",
                  "label": "ELMA",
                  "value": "ELMA"
              },
              {
                  "name": "PURCHASE",
                  "label": "PURCHASE",
                  "value": "PURCHASE"
              },
              {
                  "name": "ROME",
                  "label": "ROME",
                  "value": "ROME"
              },
              {
                  "name": "MORICHES",
                  "label": "MORICHES",
                  "value": "MORICHES"
              },
              {
                  "name": "CHITTENANGO",
                  "label": "CHITTENANGO",
                  "value": "CHITTENANGO"
              },
              {
                  "name": "NIAGARA FALLS",
                  "label": "NIAGARA FALLS",
                  "value": "NIAGARA FALLS"
              },
              {
                  "name": "CORNING",
                  "label": "CORNING",
                  "value": "CORNING"
              },
              {
                  "name": "SLINGERLANDS",
                  "label": "SLINGERLANDS",
                  "value": "SLINGERLANDS"
              },
              {
                  "name": "IRVINGTON",
                  "label": "IRVINGTON",
                  "value": "IRVINGTON"
              },
              {
                  "name": "CORTLANDT MANOR",
                  "label": "CORTLANDT MANOR",
                  "value": "CORTLANDT MANOR"
              },
              {
                  "name": "HOWARD BEACH",
                  "label": "HOWARD BEACH",
                  "value": "HOWARD BEACH"
              },
              {
                  "name": "PEEKSKILL",
                  "label": "PEEKSKILL",
                  "value": "PEEKSKILL"
              },
              {
                  "name": "MALTA",
                  "label": "MALTA",
                  "value": "MALTA"
              },
              {
                  "name": "MONROE",
                  "label": "MONROE",
                  "value": "MONROE"
              },
              {
                  "name": "WESTBROOKEVILLE",
                  "label": "WESTBROOKEVILLE",
                  "value": "WESTBROOKEVILLE"
              },
              {
                  "name": "POMONA",
                  "label": "POMONA",
                  "value": "POMONA"
              },
              {
                  "name": "MOUNT VERNON",
                  "label": "MOUNT VERNON",
                  "value": "MOUNT VERNON"
              },
              {
                  "name": "NORTH SYRACUSE",
                  "label": "NORTH SYRACUSE",
                  "value": "NORTH SYRACUSE"
              },
              {
                  "name": "SPRING VALLEY",
                  "label": "SPRING VALLEY",
                  "value": "SPRING VALLEY"
              },
              {
                  "name": "RINEBECK",
                  "label": "RINEBECK",
                  "value": "RINEBECK"
              },
              {
                  "name": "LONG ISLAND CITY",
                  "label": "LONG ISLAND CITY",
                  "value": "LONG ISLAND CITY"
              },
              {
                  "name": "MELVILLE",
                  "label": "MELVILLE",
                  "value": "MELVILLE"
              },
              {
                  "name": "CANANDAIGUA",
                  "label": "CANANDAIGUA",
                  "value": "CANANDAIGUA"
              },
              {
                  "name": "HAUPPAUGE",
                  "label": "HAUPPAUGE",
                  "value": "HAUPPAUGE"
              },
              {
                  "name": "NEW WINDSOR",
                  "label": "NEW WINDSOR",
                  "value": "NEW WINDSOR"
              },
              {
                  "name": "MIDDLE ISLAND",
                  "label": "MIDDLE ISLAND",
                  "value": "MIDDLE ISLAND"
              },
              {
                  "name": "WESTHAMPTON BEACH",
                  "label": "WESTHAMPTON BEACH",
                  "value": "WESTHAMPTON BEACH"
              },
              {
                  "name": "MALONE",
                  "label": "MALONE",
                  "value": "MALONE"
              },
              {
                  "name": "BRIGHTWATERS",
                  "label": "BRIGHTWATERS",
                  "value": "BRIGHTWATERS"
              },
              {
                  "name": "WAPPINGERS FALLS",
                  "label": "WAPPINGERS FALLS",
                  "value": "WAPPINGERS FALLS"
              },
              {
                  "name": "CEDARHURST",
                  "label": "CEDARHURST",
                  "value": "CEDARHURST"
              },
              {
                  "name": "ROSLYN HEIGHTS",
                  "label": "ROSLYN HEIGHTS",
                  "value": "ROSLYN HEIGHTS"
              },
              {
                  "name": "CORAM",
                  "label": "CORAM",
                  "value": "CORAM"
              },
              {
                  "name": "CENTRAL SQUARE",
                  "label": "CENTRAL SQUARE",
                  "value": "CENTRAL SQUARE"
              },
              {
                  "name": "HOLBROOK",
                  "label": "HOLBROOK",
                  "value": "HOLBROOK"
              },
              {
                  "name": "QUEENS VILLAGE",
                  "label": "QUEENS VILLAGE",
                  "value": "QUEENS VILLAGE"
              },
              {
                  "name": "VALLEY STREAM",
                  "label": "VALLEY STREAM",
                  "value": "VALLEY STREAM"
              },
              {
                  "name": "FREEVILLE",
                  "label": "FREEVILLE",
                  "value": "FREEVILLE"
              },
              {
                  "name": "CENTRAL ISLIP",
                  "label": "CENTRAL ISLIP",
                  "value": "CENTRAL ISLIP"
              },
              {
                  "name": "ALBERTSON",
                  "label": "ALBERTSON",
                  "value": "ALBERTSON"
              },
              {
                  "name": "BLUE POINT",
                  "label": "BLUE POINT",
                  "value": "BLUE POINT"
              }
          ]
      },
      {
          "name": "PA",
          "label": "Pennsylvania",
          "value": "PA",
          "cities": [
              {
                  "name": "PHILADELPHIA",
                  "label": "PHILADELPHIA",
                  "value": "PHILADELPHIA"
              },
              {
                  "name": "CLAIRTON",
                  "label": "CLAIRTON",
                  "value": "CLAIRTON"
              },
              {
                  "name": "HERSHEY",
                  "label": "HERSHEY",
                  "value": "HERSHEY"
              },
              {
                  "name": "KITTANNING",
                  "label": "KITTANNING",
                  "value": "KITTANNING"
              },
              {
                  "name": "PITTSBURGH",
                  "label": "PITTSBURGH",
                  "value": "PITTSBURGH"
              },
              {
                  "name": "CAMP HILL",
                  "label": "CAMP HILL",
                  "value": "CAMP HILL"
              },
              {
                  "name": "WALLINGFORD",
                  "label": "WALLINGFORD",
                  "value": "WALLINGFORD"
              },
              {
                  "name": "SAYRE",
                  "label": "SAYRE",
                  "value": "SAYRE"
              },
              {
                  "name": "MECHANICSBURG",
                  "label": "MECHANICSBURG",
                  "value": "MECHANICSBURG"
              },
              {
                  "name": "WEST READING",
                  "label": "WEST READING",
                  "value": "WEST READING"
              },
              {
                  "name": "CLARKS SUMMIT",
                  "label": "CLARKS SUMMIT",
                  "value": "CLARKS SUMMIT"
              },
              {
                  "name": "HUNTINGDON",
                  "label": "HUNTINGDON",
                  "value": "HUNTINGDON"
              },
              {
                  "name": "BUTLER",
                  "label": "BUTLER",
                  "value": "BUTLER"
              },
              {
                  "name": "PLYMOUTH MEETING",
                  "label": "PLYMOUTH MEETING",
                  "value": "PLYMOUTH MEETING"
              },
              {
                  "name": "WILLOW GROVE",
                  "label": "WILLOW GROVE",
                  "value": "WILLOW GROVE"
              },
              {
                  "name": "LANCASTER",
                  "label": "LANCASTER",
                  "value": "LANCASTER"
              },
              {
                  "name": "LANGHORNE",
                  "label": "LANGHORNE",
                  "value": "LANGHORNE"
              },
              {
                  "name": "ALLENTOWN",
                  "label": "ALLENTOWN",
                  "value": "ALLENTOWN"
              },
              {
                  "name": "DANVILLE",
                  "label": "DANVILLE",
                  "value": "DANVILLE"
              },
              {
                  "name": "ERIE",
                  "label": "ERIE",
                  "value": "ERIE"
              },
              {
                  "name": "NORRISTOWN",
                  "label": "NORRISTOWN",
                  "value": "NORRISTOWN"
              },
              {
                  "name": "HORSHAM",
                  "label": "HORSHAM",
                  "value": "HORSHAM"
              },
              {
                  "name": "YORK",
                  "label": "YORK",
                  "value": "YORK"
              },
              {
                  "name": "CANONSBURG",
                  "label": "CANONSBURG",
                  "value": "CANONSBURG"
              },
              {
                  "name": "GETTYSBURG",
                  "label": "GETTYSBURG",
                  "value": "GETTYSBURG"
              },
              {
                  "name": "MEADVILLE",
                  "label": "MEADVILLE",
                  "value": "MEADVILLE"
              },
              {
                  "name": "MONONGAHELA",
                  "label": "MONONGAHELA",
                  "value": "MONONGAHELA"
              },
              {
                  "name": "SCRANTON",
                  "label": "SCRANTON",
                  "value": "SCRANTON"
              },
              {
                  "name": "BETHLEHEM",
                  "label": "BETHLEHEM",
                  "value": "BETHLEHEM"
              },
              {
                  "name": "PHOENIXVILLE",
                  "label": "PHOENIXVILLE",
                  "value": "PHOENIXVILLE"
              },
              {
                  "name": "MCKEES ROCKS",
                  "label": "MCKEES ROCKS",
                  "value": "MCKEES ROCKS"
              },
              {
                  "name": "HAZLETON",
                  "label": "HAZLETON",
                  "value": "HAZLETON"
              },
              {
                  "name": "PAOLI",
                  "label": "PAOLI",
                  "value": "PAOLI"
              },
              {
                  "name": "LEWISBURG",
                  "label": "LEWISBURG",
                  "value": "LEWISBURG"
              },
              {
                  "name": "STATE COLLEGE",
                  "label": "STATE COLLEGE",
                  "value": "STATE COLLEGE"
              },
              {
                  "name": "RIDLEY PARK",
                  "label": "RIDLEY PARK",
                  "value": "RIDLEY PARK"
              },
              {
                  "name": "ABINGTON",
                  "label": "ABINGTON",
                  "value": "ABINGTON"
              },
              {
                  "name": "SELLERSVILLE",
                  "label": "SELLERSVILLE",
                  "value": "SELLERSVILLE"
              },
              {
                  "name": "KING OF PRUSSIA",
                  "label": "KING OF PRUSSIA",
                  "value": "KING OF PRUSSIA"
              },
              {
                  "name": "MCKEESPORT",
                  "label": "MCKEESPORT",
                  "value": "MCKEESPORT"
              },
              {
                  "name": "EASTON",
                  "label": "EASTON",
                  "value": "EASTON"
              },
              {
                  "name": "GREENSBURG",
                  "label": "GREENSBURG",
                  "value": "GREENSBURG"
              },
              {
                  "name": "PHILA",
                  "label": "PHILA",
                  "value": "PHILA"
              },
              {
                  "name": "DARBY",
                  "label": "DARBY",
                  "value": "DARBY"
              },
              {
                  "name": "DUNMORE",
                  "label": "DUNMORE",
                  "value": "DUNMORE"
              },
              {
                  "name": "PUNXSUTAWNEY",
                  "label": "PUNXSUTAWNEY",
                  "value": "PUNXSUTAWNEY"
              },
              {
                  "name": "EAST NORRITON",
                  "label": "EAST NORRITON",
                  "value": "EAST NORRITON"
              },
              {
                  "name": "LEBANON",
                  "label": "LEBANON",
                  "value": "LEBANON"
              },
              {
                  "name": "EPHRATA",
                  "label": "EPHRATA",
                  "value": "EPHRATA"
              },
              {
                  "name": "CARLISLE",
                  "label": "CARLISLE",
                  "value": "CARLISLE"
              },
              {
                  "name": "FARRELL",
                  "label": "FARRELL",
                  "value": "FARRELL"
              },
              {
                  "name": "GROVE CITY",
                  "label": "GROVE CITY",
                  "value": "GROVE CITY"
              },
              {
                  "name": "NEWTOWN",
                  "label": "NEWTOWN",
                  "value": "NEWTOWN"
              },
              {
                  "name": "HARRISBURG",
                  "label": "HARRISBURG",
                  "value": "HARRISBURG"
              },
              {
                  "name": "MONROEVILLE",
                  "label": "MONROEVILLE",
                  "value": "MONROEVILLE"
              },
              {
                  "name": "EAST STROUDSBURG",
                  "label": "EAST STROUDSBURG",
                  "value": "EAST STROUDSBURG"
              },
              {
                  "name": "WILLIAMSPORT",
                  "label": "WILLIAMSPORT",
                  "value": "WILLIAMSPORT"
              },
              {
                  "name": "HANOVER",
                  "label": "HANOVER",
                  "value": "HANOVER"
              },
              {
                  "name": "BRYN MAWR",
                  "label": "BRYN MAWR",
                  "value": "BRYN MAWR"
              },
              {
                  "name": "DU BOIS",
                  "label": "DU BOIS",
                  "value": "DU BOIS"
              },
              {
                  "name": "CHAMBERSBURG",
                  "label": "CHAMBERSBURG",
                  "value": "CHAMBERSBURG"
              },
              {
                  "name": "WEST CHESTER",
                  "label": "WEST CHESTER",
                  "value": "WEST CHESTER"
              },
              {
                  "name": "GIBSONIA",
                  "label": "GIBSONIA",
                  "value": "GIBSONIA"
              },
              {
                  "name": "WAMPUM",
                  "label": "WAMPUM",
                  "value": "WAMPUM"
              },
              {
                  "name": "READING",
                  "label": "READING",
                  "value": "READING"
              },
              {
                  "name": "WILKES BARRE",
                  "label": "WILKES BARRE",
                  "value": "WILKES BARRE"
              },
              {
                  "name": "BETHEL PARK",
                  "label": "BETHEL PARK",
                  "value": "BETHEL PARK"
              },
              {
                  "name": "FOUNTAIN HILL",
                  "label": "FOUNTAIN HILL",
                  "value": "FOUNTAIN HILL"
              },
              {
                  "name": "WARREN",
                  "label": "WARREN",
                  "value": "WARREN"
              },
              {
                  "name": "POTTSVILLE",
                  "label": "POTTSVILLE",
                  "value": "POTTSVILLE"
              },
              {
                  "name": "KINGSTON",
                  "label": "KINGSTON",
                  "value": "KINGSTON"
              },
              {
                  "name": "UNIONTOWN",
                  "label": "UNIONTOWN",
                  "value": "UNIONTOWN"
              },
              {
                  "name": "ALTOONA",
                  "label": "ALTOONA",
                  "value": "ALTOONA"
              },
              {
                  "name": "CHESTER",
                  "label": "CHESTER",
                  "value": "CHESTER"
              },
              {
                  "name": "MEADOWBROOK",
                  "label": "MEADOWBROOK",
                  "value": "MEADOWBROOK"
              },
              {
                  "name": "YARDLEY",
                  "label": "YARDLEY",
                  "value": "YARDLEY"
              },
              {
                  "name": "POTTSTOWN",
                  "label": "POTTSTOWN",
                  "value": "POTTSTOWN"
              },
              {
                  "name": "BERWYN",
                  "label": "BERWYN",
                  "value": "BERWYN"
              },
              {
                  "name": "INDIANA",
                  "label": "INDIANA",
                  "value": "INDIANA"
              },
              {
                  "name": "BROOMALL",
                  "label": "BROOMALL",
                  "value": "BROOMALL"
              },
              {
                  "name": "WEXFORD",
                  "label": "WEXFORD",
                  "value": "WEXFORD"
              },
              {
                  "name": "BEAVER",
                  "label": "BEAVER",
                  "value": "BEAVER"
              },
              {
                  "name": "NEW CASTLE",
                  "label": "NEW CASTLE",
                  "value": "NEW CASTLE"
              },
              {
                  "name": "LEWISTOWN",
                  "label": "LEWISTOWN",
                  "value": "LEWISTOWN"
              },
              {
                  "name": "WYNDMOOR",
                  "label": "WYNDMOOR",
                  "value": "WYNDMOOR"
              },
              {
                  "name": "WYNNEWOOD",
                  "label": "WYNNEWOOD",
                  "value": "WYNNEWOOD"
              },
              {
                  "name": "WARWICK",
                  "label": "WARWICK",
                  "value": "WARWICK"
              },
              {
                  "name": "GREENVILLE",
                  "label": "GREENVILLE",
                  "value": "GREENVILLE"
              },
              {
                  "name": "LATROBE",
                  "label": "LATROBE",
                  "value": "LATROBE"
              },
              {
                  "name": "E STROUDSBURG",
                  "label": "E STROUDSBURG",
                  "value": "E STROUDSBURG"
              },
              {
                  "name": "CHALFONT",
                  "label": "CHALFONT",
                  "value": "CHALFONT"
              },
              {
                  "name": "COUDERSPORT",
                  "label": "COUDERSPORT",
                  "value": "COUDERSPORT"
              },
              {
                  "name": "BENSALEM",
                  "label": "BENSALEM",
                  "value": "BENSALEM"
              },
              {
                  "name": "STROUDSBURG",
                  "label": "STROUDSBURG",
                  "value": "STROUDSBURG"
              },
              {
                  "name": "NORTHAMPTON",
                  "label": "NORTHAMPTON",
                  "value": "NORTHAMPTON"
              },
              {
                  "name": "LEVITTOWN",
                  "label": "LEVITTOWN",
                  "value": "LEVITTOWN"
              },
              {
                  "name": "WASHINGTON",
                  "label": "WASHINGTON",
                  "value": "WASHINGTON"
              },
              {
                  "name": "NATRONA HEIGHTS",
                  "label": "NATRONA HEIGHTS",
                  "value": "NATRONA HEIGHTS"
              },
              {
                  "name": "GLEN MILLS",
                  "label": "GLEN MILLS",
                  "value": "GLEN MILLS"
              },
              {
                  "name": "DUBOIS",
                  "label": "DUBOIS",
                  "value": "DUBOIS"
              },
              {
                  "name": "WEST MIFFLIN",
                  "label": "WEST MIFFLIN",
                  "value": "WEST MIFFLIN"
              },
              {
                  "name": "JOHNSTOWN",
                  "label": "JOHNSTOWN",
                  "value": "JOHNSTOWN"
              },
              {
                  "name": "DOWNINGTOWN",
                  "label": "DOWNINGTOWN",
                  "value": "DOWNINGTOWN"
              },
              {
                  "name": "MOON TOWNSHIP",
                  "label": "MOON TOWNSHIP",
                  "value": "MOON TOWNSHIP"
              },
              {
                  "name": "WATSONTOWN",
                  "label": "WATSONTOWN",
                  "value": "WATSONTOWN"
              },
              {
                  "name": "CLARION",
                  "label": "CLARION",
                  "value": "CLARION"
              },
              {
                  "name": "N HUNTINGDON",
                  "label": "N HUNTINGDON",
                  "value": "N HUNTINGDON"
              },
              {
                  "name": "MCMURRAY",
                  "label": "MCMURRAY",
                  "value": "MCMURRAY"
              },
              {
                  "name": "WAYNE",
                  "label": "WAYNE",
                  "value": "WAYNE"
              },
              {
                  "name": "CLEARFIELD",
                  "label": "CLEARFIELD",
                  "value": "CLEARFIELD"
              },
              {
                  "name": "SENECA",
                  "label": "SENECA",
                  "value": "SENECA"
              },
              {
                  "name": "SELINSGROVE",
                  "label": "SELINSGROVE",
                  "value": "SELINSGROVE"
              },
              {
                  "name": "MT PLEASANT",
                  "label": "MT PLEASANT",
                  "value": "MT PLEASANT"
              },
              {
                  "name": "SEVEN FIELDS",
                  "label": "SEVEN FIELDS",
                  "value": "SEVEN FIELDS"
              },
              {
                  "name": "WEST GROVE",
                  "label": "WEST GROVE",
                  "value": "WEST GROVE"
              },
              {
                  "name": "WAYMART",
                  "label": "WAYMART",
                  "value": "WAYMART"
              },
              {
                  "name": "MARS",
                  "label": "MARS",
                  "value": "MARS"
              },
              {
                  "name": "MEDIA",
                  "label": "MEDIA",
                  "value": "MEDIA"
              },
              {
                  "name": "EXTON",
                  "label": "EXTON",
                  "value": "EXTON"
              },
              {
                  "name": "CHESTER SPRINGS",
                  "label": "CHESTER SPRINGS",
                  "value": "CHESTER SPRINGS"
              },
              {
                  "name": "WELLSBORO",
                  "label": "WELLSBORO",
                  "value": "WELLSBORO"
              },
              {
                  "name": "RADNOR",
                  "label": "RADNOR",
                  "value": "RADNOR"
              },
              {
                  "name": "LANSDALE",
                  "label": "LANSDALE",
                  "value": "LANSDALE"
              },
              {
                  "name": "HATBORO",
                  "label": "HATBORO",
                  "value": "HATBORO"
              },
              {
                  "name": "ROSEMONT",
                  "label": "ROSEMONT",
                  "value": "ROSEMONT"
              },
              {
                  "name": "BRADFORD",
                  "label": "BRADFORD",
                  "value": "BRADFORD"
              },
              {
                  "name": "ALLISON PARK",
                  "label": "ALLISON PARK",
                  "value": "ALLISON PARK"
              },
              {
                  "name": "LOWER BURRELL",
                  "label": "LOWER BURRELL",
                  "value": "LOWER BURRELL"
              }
          ]
      },
      {
          "name": "CT",
          "label": "Connecticut",
          "value": "CT",
          "cities": [
              {
                  "name": "NEW HAVEN",
                  "label": "NEW HAVEN",
                  "value": "NEW HAVEN"
              },
              {
                  "name": "TRUMBULL",
                  "label": "TRUMBULL",
                  "value": "TRUMBULL"
              },
              {
                  "name": "MIDDLETOWN",
                  "label": "MIDDLETOWN",
                  "value": "MIDDLETOWN"
              },
              {
                  "name": "WATERBURY",
                  "label": "WATERBURY",
                  "value": "WATERBURY"
              },
              {
                  "name": "MANCHESTER",
                  "label": "MANCHESTER",
                  "value": "MANCHESTER"
              },
              {
                  "name": "BRIDGEPORT",
                  "label": "BRIDGEPORT",
                  "value": "BRIDGEPORT"
              },
              {
                  "name": "PUTNAM",
                  "label": "PUTNAM",
                  "value": "PUTNAM"
              },
              {
                  "name": "HARTFORD",
                  "label": "HARTFORD",
                  "value": "HARTFORD"
              },
              {
                  "name": "NORWICH",
                  "label": "NORWICH",
                  "value": "NORWICH"
              },
              {
                  "name": "WETHERSFIELD",
                  "label": "WETHERSFIELD",
                  "value": "WETHERSFIELD"
              },
              {
                  "name": "ENFIELD",
                  "label": "ENFIELD",
                  "value": "ENFIELD"
              },
              {
                  "name": "ORANGE",
                  "label": "ORANGE",
                  "value": "ORANGE"
              },
              {
                  "name": "GUILFORD",
                  "label": "GUILFORD",
                  "value": "GUILFORD"
              },
              {
                  "name": "WATERFORD",
                  "label": "WATERFORD",
                  "value": "WATERFORD"
              },
              {
                  "name": "FARMINGTON",
                  "label": "FARMINGTON",
                  "value": "FARMINGTON"
              },
              {
                  "name": "DANBURY",
                  "label": "DANBURY",
                  "value": "DANBURY"
              },
              {
                  "name": "MERIDEN",
                  "label": "MERIDEN",
                  "value": "MERIDEN"
              },
              {
                  "name": "STAMFORD",
                  "label": "STAMFORD",
                  "value": "STAMFORD"
              },
              {
                  "name": "GREENWICH",
                  "label": "GREENWICH",
                  "value": "GREENWICH"
              },
              {
                  "name": "NEW BRITAIN",
                  "label": "NEW BRITAIN",
                  "value": "NEW BRITAIN"
              },
              {
                  "name": "SOUTHBURY",
                  "label": "SOUTHBURY",
                  "value": "SOUTHBURY"
              },
              {
                  "name": "ROCKY HILL",
                  "label": "ROCKY HILL",
                  "value": "ROCKY HILL"
              },
              {
                  "name": "DERBY",
                  "label": "DERBY",
                  "value": "DERBY"
              },
              {
                  "name": "NORWALK",
                  "label": "NORWALK",
                  "value": "NORWALK"
              },
              {
                  "name": "TORRINGTON",
                  "label": "TORRINGTON",
                  "value": "TORRINGTON"
              },
              {
                  "name": "LAKEVILLE",
                  "label": "LAKEVILLE",
                  "value": "LAKEVILLE"
              },
              {
                  "name": "WILLIMANTIC",
                  "label": "WILLIMANTIC",
                  "value": "WILLIMANTIC"
              },
              {
                  "name": "AVON",
                  "label": "AVON",
                  "value": "AVON"
              },
              {
                  "name": "MILFORD",
                  "label": "MILFORD",
                  "value": "MILFORD"
              },
              {
                  "name": "HAMDEN",
                  "label": "HAMDEN",
                  "value": "HAMDEN"
              },
              {
                  "name": "BRISTOL",
                  "label": "BRISTOL",
                  "value": "BRISTOL"
              },
              {
                  "name": "FAIRFIELD",
                  "label": "FAIRFIELD",
                  "value": "FAIRFIELD"
              },
              {
                  "name": "BROOKFIELD",
                  "label": "BROOKFIELD",
                  "value": "BROOKFIELD"
              },
              {
                  "name": "NORTH HAVEN",
                  "label": "NORTH HAVEN",
                  "value": "NORTH HAVEN"
              },
              {
                  "name": "WEST HAVEN",
                  "label": "WEST HAVEN",
                  "value": "WEST HAVEN"
              },
              {
                  "name": "BETHEL",
                  "label": "BETHEL",
                  "value": "BETHEL"
              },
              {
                  "name": "WALLINGFORD",
                  "label": "WALLINGFORD",
                  "value": "WALLINGFORD"
              }
          ]
      },
      {
          "name": "MA",
          "label": "Massachusetts",
          "value": "MA",
          "cities": [
              {
                  "name": "BOSTON",
                  "label": "BOSTON",
                  "value": "BOSTON"
              },
              {
                  "name": "ATTLEBORO",
                  "label": "ATTLEBORO",
                  "value": "ATTLEBORO"
              },
              {
                  "name": "PITTSFIELD",
                  "label": "PITTSFIELD",
                  "value": "PITTSFIELD"
              },
              {
                  "name": "CAMBRIDGE",
                  "label": "CAMBRIDGE",
                  "value": "CAMBRIDGE"
              },
              {
                  "name": "BRIGHTON",
                  "label": "BRIGHTON",
                  "value": "BRIGHTON"
              },
              {
                  "name": "WORCESTER",
                  "label": "WORCESTER",
                  "value": "WORCESTER"
              },
              {
                  "name": "FALMOUTH",
                  "label": "FALMOUTH",
                  "value": "FALMOUTH"
              },
              {
                  "name": "LOWELL",
                  "label": "LOWELL",
                  "value": "LOWELL"
              },
              {
                  "name": "QUINCY",
                  "label": "QUINCY",
                  "value": "QUINCY"
              },
              {
                  "name": "OXFORD",
                  "label": "OXFORD",
                  "value": "OXFORD"
              },
              {
                  "name": "PLYMOUTH",
                  "label": "PLYMOUTH",
                  "value": "PLYMOUTH"
              },
              {
                  "name": "GARDNER",
                  "label": "GARDNER",
                  "value": "GARDNER"
              },
              {
                  "name": "MILFORD",
                  "label": "MILFORD",
                  "value": "MILFORD"
              },
              {
                  "name": "METHUEN",
                  "label": "METHUEN",
                  "value": "METHUEN"
              },
              {
                  "name": "NEWTON",
                  "label": "NEWTON",
                  "value": "NEWTON"
              },
              {
                  "name": "BURLINGTON",
                  "label": "BURLINGTON",
                  "value": "BURLINGTON"
              },
              {
                  "name": "FRAMINGHAM",
                  "label": "FRAMINGHAM",
                  "value": "FRAMINGHAM"
              },
              {
                  "name": "FALL RIVER",
                  "label": "FALL RIVER",
                  "value": "FALL RIVER"
              },
              {
                  "name": "NEWBURYPORT",
                  "label": "NEWBURYPORT",
                  "value": "NEWBURYPORT"
              },
              {
                  "name": "FAIRHAVEN",
                  "label": "FAIRHAVEN",
                  "value": "FAIRHAVEN"
              },
              {
                  "name": "NEEDHAM",
                  "label": "NEEDHAM",
                  "value": "NEEDHAM"
              },
              {
                  "name": "SPRINGFIELD",
                  "label": "SPRINGFIELD",
                  "value": "SPRINGFIELD"
              },
              {
                  "name": "WELLESLEY",
                  "label": "WELLESLEY",
                  "value": "WELLESLEY"
              },
              {
                  "name": "NORTHAMPTON",
                  "label": "NORTHAMPTON",
                  "value": "NORTHAMPTON"
              },
              {
                  "name": "WEYMOUTH",
                  "label": "WEYMOUTH",
                  "value": "WEYMOUTH"
              },
              {
                  "name": "LAWRENCE",
                  "label": "LAWRENCE",
                  "value": "LAWRENCE"
              },
              {
                  "name": "SOUTH WEYMOUTH",
                  "label": "SOUTH WEYMOUTH",
                  "value": "SOUTH WEYMOUTH"
              },
              {
                  "name": "DANVERS",
                  "label": "DANVERS",
                  "value": "DANVERS"
              },
              {
                  "name": "BRAINTREE",
                  "label": "BRAINTREE",
                  "value": "BRAINTREE"
              },
              {
                  "name": "NORTH DARTMOUTH",
                  "label": "NORTH DARTMOUTH",
                  "value": "NORTH DARTMOUTH"
              },
              {
                  "name": "HYANNIS",
                  "label": "HYANNIS",
                  "value": "HYANNIS"
              },
              {
                  "name": "WESTON",
                  "label": "WESTON",
                  "value": "WESTON"
              },
              {
                  "name": "MILTON",
                  "label": "MILTON",
                  "value": "MILTON"
              },
              {
                  "name": "WALTHAM",
                  "label": "WALTHAM",
                  "value": "WALTHAM"
              },
              {
                  "name": "BROCKTON",
                  "label": "BROCKTON",
                  "value": "BROCKTON"
              },
              {
                  "name": "ATHOL",
                  "label": "ATHOL",
                  "value": "ATHOL"
              },
              {
                  "name": "FEEDING HILLS",
                  "label": "FEEDING HILLS",
                  "value": "FEEDING HILLS"
              },
              {
                  "name": "CONCORD",
                  "label": "CONCORD",
                  "value": "CONCORD"
              },
              {
                  "name": "CHELSEA",
                  "label": "CHELSEA",
                  "value": "CHELSEA"
              },
              {
                  "name": "SOMERVILLE",
                  "label": "SOMERVILLE",
                  "value": "SOMERVILLE"
              },
              {
                  "name": "ACTON",
                  "label": "ACTON",
                  "value": "ACTON"
              },
              {
                  "name": "WINCHESTER",
                  "label": "WINCHESTER",
                  "value": "WINCHESTER"
              },
              {
                  "name": "SOUTHBRIDGE",
                  "label": "SOUTHBRIDGE",
                  "value": "SOUTHBRIDGE"
              },
              {
                  "name": "TAUNTON",
                  "label": "TAUNTON",
                  "value": "TAUNTON"
              },
              {
                  "name": "GREENFIELD",
                  "label": "GREENFIELD",
                  "value": "GREENFIELD"
              },
              {
                  "name": "STONEHAM",
                  "label": "STONEHAM",
                  "value": "STONEHAM"
              },
              {
                  "name": "NORTH FALMOUTH",
                  "label": "NORTH FALMOUTH",
                  "value": "NORTH FALMOUTH"
              },
              {
                  "name": "EVERETT",
                  "label": "EVERETT",
                  "value": "EVERETT"
              },
              {
                  "name": "TYNGSBORO",
                  "label": "TYNGSBORO",
                  "value": "TYNGSBORO"
              },
              {
                  "name": "FITCHBURG",
                  "label": "FITCHBURG",
                  "value": "FITCHBURG"
              },
              {
                  "name": "SHARON",
                  "label": "SHARON",
                  "value": "SHARON"
              },
              {
                  "name": "PEABODY",
                  "label": "PEABODY",
                  "value": "PEABODY"
              },
              {
                  "name": "CHESTNUT HILL",
                  "label": "CHESTNUT HILL",
                  "value": "CHESTNUT HILL"
              },
              {
                  "name": "NATICK",
                  "label": "NATICK",
                  "value": "NATICK"
              },
              {
                  "name": "DORCHESTER",
                  "label": "DORCHESTER",
                  "value": "DORCHESTER"
              },
              {
                  "name": "OAK BLUFFS",
                  "label": "OAK BLUFFS",
                  "value": "OAK BLUFFS"
              },
              {
                  "name": "HOLYOKE",
                  "label": "HOLYOKE",
                  "value": "HOLYOKE"
              },
              {
                  "name": "MARBLEHEAD",
                  "label": "MARBLEHEAD",
                  "value": "MARBLEHEAD"
              },
              {
                  "name": "EAST BOSTON",
                  "label": "EAST BOSTON",
                  "value": "EAST BOSTON"
              },
              {
                  "name": "JAMAICA PLAIN",
                  "label": "JAMAICA PLAIN",
                  "value": "JAMAICA PLAIN"
              },
              {
                  "name": "WEST ROXBURY",
                  "label": "WEST ROXBURY",
                  "value": "WEST ROXBURY"
              },
              {
                  "name": "AYER",
                  "label": "AYER",
                  "value": "AYER"
              },
              {
                  "name": "NORTH ANDOVER",
                  "label": "NORTH ANDOVER",
                  "value": "NORTH ANDOVER"
              },
              {
                  "name": "REVERE",
                  "label": "REVERE",
                  "value": "REVERE"
              },
              {
                  "name": "WELLESLEY HILLS",
                  "label": "WELLESLEY HILLS",
                  "value": "WELLESLEY HILLS"
              },
              {
                  "name": "MELROSE",
                  "label": "MELROSE",
                  "value": "MELROSE"
              },
              {
                  "name": "DARTMOUTH",
                  "label": "DARTMOUTH",
                  "value": "DARTMOUTH"
              }
          ]
      },
      {
          "name": "NJ",
          "label": "New Jersey",
          "value": "NJ",
          "cities": [
              {
                  "name": "MOORESTOWN",
                  "label": "MOORESTOWN",
                  "value": "MOORESTOWN"
              },
              {
                  "name": "BELLEVILLE",
                  "label": "BELLEVILLE",
                  "value": "BELLEVILLE"
              },
              {
                  "name": "UNION",
                  "label": "UNION",
                  "value": "UNION"
              },
              {
                  "name": "BERKELEY HEIGHTS",
                  "label": "BERKELEY HEIGHTS",
                  "value": "BERKELEY HEIGHTS"
              },
              {
                  "name": "NEWARK",
                  "label": "NEWARK",
                  "value": "NEWARK"
              },
              {
                  "name": "BASKING RIDGE",
                  "label": "BASKING RIDGE",
                  "value": "BASKING RIDGE"
              },
              {
                  "name": "CAMDEN",
                  "label": "CAMDEN",
                  "value": "CAMDEN"
              },
              {
                  "name": "VINELAND",
                  "label": "VINELAND",
                  "value": "VINELAND"
              },
              {
                  "name": "VOORHEES",
                  "label": "VOORHEES",
                  "value": "VOORHEES"
              },
              {
                  "name": "WEST LONG BRANCH",
                  "label": "WEST LONG BRANCH",
                  "value": "WEST LONG BRANCH"
              },
              {
                  "name": "EAST ORANGE",
                  "label": "EAST ORANGE",
                  "value": "EAST ORANGE"
              },
              {
                  "name": "PATERSON",
                  "label": "PATERSON",
                  "value": "PATERSON"
              },
              {
                  "name": "PENNINGTON",
                  "label": "PENNINGTON",
                  "value": "PENNINGTON"
              },
              {
                  "name": "SOMERVILLE",
                  "label": "SOMERVILLE",
                  "value": "SOMERVILLE"
              },
              {
                  "name": "MOUNTAIN LAKES",
                  "label": "MOUNTAIN LAKES",
                  "value": "MOUNTAIN LAKES"
              },
              {
                  "name": "HACKENSACK",
                  "label": "HACKENSACK",
                  "value": "HACKENSACK"
              },
              {
                  "name": "MORRISTOWN",
                  "label": "MORRISTOWN",
                  "value": "MORRISTOWN"
              },
              {
                  "name": "MANAHAWKIN",
                  "label": "MANAHAWKIN",
                  "value": "MANAHAWKIN"
              },
              {
                  "name": "ELIZABETH",
                  "label": "ELIZABETH",
                  "value": "ELIZABETH"
              },
              {
                  "name": "ENGLEWOOD",
                  "label": "ENGLEWOOD",
                  "value": "ENGLEWOOD"
              },
              {
                  "name": "EGG HARBOR TOWNSHIP",
                  "label": "EGG HARBOR TOWNSHIP",
                  "value": "EGG HARBOR TOWNSHIP"
              },
              {
                  "name": "HOWELL",
                  "label": "HOWELL",
                  "value": "HOWELL"
              },
              {
                  "name": "EAST BRUNSWICK",
                  "label": "EAST BRUNSWICK",
                  "value": "EAST BRUNSWICK"
              },
              {
                  "name": "TOMS RIVER",
                  "label": "TOMS RIVER",
                  "value": "TOMS RIVER"
              },
              {
                  "name": "NEW BRUNSWICK",
                  "label": "NEW BRUNSWICK",
                  "value": "NEW BRUNSWICK"
              },
              {
                  "name": "JERSEY CITY",
                  "label": "JERSEY CITY",
                  "value": "JERSEY CITY"
              },
              {
                  "name": "WEST ORANGE",
                  "label": "WEST ORANGE",
                  "value": "WEST ORANGE"
              },
              {
                  "name": "MARMORA",
                  "label": "MARMORA",
                  "value": "MARMORA"
              },
              {
                  "name": "NORTHFIELD",
                  "label": "NORTHFIELD",
                  "value": "NORTHFIELD"
              },
              {
                  "name": "FLANDERS",
                  "label": "FLANDERS",
                  "value": "FLANDERS"
              },
              {
                  "name": "LITTLE SILVER",
                  "label": "LITTLE SILVER",
                  "value": "LITTLE SILVER"
              },
              {
                  "name": "SOMERSET",
                  "label": "SOMERSET",
                  "value": "SOMERSET"
              },
              {
                  "name": "ATLANTIC CITY",
                  "label": "ATLANTIC CITY",
                  "value": "ATLANTIC CITY"
              },
              {
                  "name": "HARRISON",
                  "label": "HARRISON",
                  "value": "HARRISON"
              },
              {
                  "name": "EDISON",
                  "label": "EDISON",
                  "value": "EDISON"
              },
              {
                  "name": "REDBANK",
                  "label": "REDBANK",
                  "value": "REDBANK"
              },
              {
                  "name": "MONROE TOWNSHIP",
                  "label": "MONROE TOWNSHIP",
                  "value": "MONROE TOWNSHIP"
              },
              {
                  "name": "BRICK",
                  "label": "BRICK",
                  "value": "BRICK"
              },
              {
                  "name": "CHERRY HILL",
                  "label": "CHERRY HILL",
                  "value": "CHERRY HILL"
              },
              {
                  "name": "MIDDLETOWN",
                  "label": "MIDDLETOWN",
                  "value": "MIDDLETOWN"
              },
              {
                  "name": "AVENEL",
                  "label": "AVENEL",
                  "value": "AVENEL"
              },
              {
                  "name": "NEPTUNE",
                  "label": "NEPTUNE",
                  "value": "NEPTUNE"
              },
              {
                  "name": "MONTVALE",
                  "label": "MONTVALE",
                  "value": "MONTVALE"
              },
              {
                  "name": "MULLICA HILL",
                  "label": "MULLICA HILL",
                  "value": "MULLICA HILL"
              },
              {
                  "name": "SEWELL",
                  "label": "SEWELL",
                  "value": "SEWELL"
              },
              {
                  "name": "FLEMINGTON",
                  "label": "FLEMINGTON",
                  "value": "FLEMINGTON"
              },
              {
                  "name": "LIVINGSTON",
                  "label": "LIVINGSTON",
                  "value": "LIVINGSTON"
              },
              {
                  "name": "ENGLEWOOD CLIFFS",
                  "label": "ENGLEWOOD CLIFFS",
                  "value": "ENGLEWOOD CLIFFS"
              },
              {
                  "name": "FREEHOLD",
                  "label": "FREEHOLD",
                  "value": "FREEHOLD"
              },
              {
                  "name": "CLIFTON",
                  "label": "CLIFTON",
                  "value": "CLIFTON"
              },
              {
                  "name": "LONG BRANCH",
                  "label": "LONG BRANCH",
                  "value": "LONG BRANCH"
              },
              {
                  "name": "TRENTON",
                  "label": "TRENTON",
                  "value": "TRENTON"
              },
              {
                  "name": "ISELIN",
                  "label": "ISELIN",
                  "value": "ISELIN"
              },
              {
                  "name": "LINWOOD",
                  "label": "LINWOOD",
                  "value": "LINWOOD"
              },
              {
                  "name": "AUDUBON",
                  "label": "AUDUBON",
                  "value": "AUDUBON"
              },
              {
                  "name": "GALLOWAY",
                  "label": "GALLOWAY",
                  "value": "GALLOWAY"
              },
              {
                  "name": "HILLSBOROUGH",
                  "label": "HILLSBOROUGH",
                  "value": "HILLSBOROUGH"
              },
              {
                  "name": "PHILLIPSBURG",
                  "label": "PHILLIPSBURG",
                  "value": "PHILLIPSBURG"
              },
              {
                  "name": "LAUREL SPRINGS",
                  "label": "LAUREL SPRINGS",
                  "value": "LAUREL SPRINGS"
              },
              {
                  "name": "WAYNE",
                  "label": "WAYNE",
                  "value": "WAYNE"
              },
              {
                  "name": "SUMMIT",
                  "label": "SUMMIT",
                  "value": "SUMMIT"
              },
              {
                  "name": "FORT LEE",
                  "label": "FORT LEE",
                  "value": "FORT LEE"
              },
              {
                  "name": "MICKLETON",
                  "label": "MICKLETON",
                  "value": "MICKLETON"
              },
              {
                  "name": "NORTH BERGEN",
                  "label": "NORTH BERGEN",
                  "value": "NORTH BERGEN"
              },
              {
                  "name": "TEANECK",
                  "label": "TEANECK",
                  "value": "TEANECK"
              },
              {
                  "name": "PARLIN",
                  "label": "PARLIN",
                  "value": "PARLIN"
              },
              {
                  "name": "BEACHWOOD",
                  "label": "BEACHWOOD",
                  "value": "BEACHWOOD"
              },
              {
                  "name": "WILLINGBORO",
                  "label": "WILLINGBORO",
                  "value": "WILLINGBORO"
              },
              {
                  "name": "SPRINGFIELD",
                  "label": "SPRINGFIELD",
                  "value": "SPRINGFIELD"
              },
              {
                  "name": "PASSAIC",
                  "label": "PASSAIC",
                  "value": "PASSAIC"
              },
              {
                  "name": "ROCKAWAY",
                  "label": "ROCKAWAY",
                  "value": "ROCKAWAY"
              },
              {
                  "name": "HOBOKEN",
                  "label": "HOBOKEN",
                  "value": "HOBOKEN"
              },
              {
                  "name": "NORTH BRUNSWICK",
                  "label": "NORTH BRUNSWICK",
                  "value": "NORTH BRUNSWICK"
              },
              {
                  "name": "PRINCETON",
                  "label": "PRINCETON",
                  "value": "PRINCETON"
              },
              {
                  "name": "TURNERSVILLE",
                  "label": "TURNERSVILLE",
                  "value": "TURNERSVILLE"
              },
              {
                  "name": "LYONS",
                  "label": "LYONS",
                  "value": "LYONS"
              },
              {
                  "name": "PLAINSBORO",
                  "label": "PLAINSBORO",
                  "value": "PLAINSBORO"
              },
              {
                  "name": "MT LAUREL",
                  "label": "MT LAUREL",
                  "value": "MT LAUREL"
              },
              {
                  "name": "RIDGEWOOD",
                  "label": "RIDGEWOOD",
                  "value": "RIDGEWOOD"
              },
              {
                  "name": "SPARTA",
                  "label": "SPARTA",
                  "value": "SPARTA"
              },
              {
                  "name": "RUTHERFORD",
                  "label": "RUTHERFORD",
                  "value": "RUTHERFORD"
              },
              {
                  "name": "FAIR LAWN",
                  "label": "FAIR LAWN",
                  "value": "FAIR LAWN"
              },
              {
                  "name": "RED BANK",
                  "label": "RED BANK",
                  "value": "RED BANK"
              },
              {
                  "name": "PARAMUS",
                  "label": "PARAMUS",
                  "value": "PARAMUS"
              },
              {
                  "name": "RUMSON",
                  "label": "RUMSON",
                  "value": "RUMSON"
              },
              {
                  "name": "KEANSBURG",
                  "label": "KEANSBURG",
                  "value": "KEANSBURG"
              },
              {
                  "name": "NEPTUNE TWP",
                  "label": "NEPTUNE TWP",
                  "value": "NEPTUNE TWP"
              },
              {
                  "name": "MONTCLAIR",
                  "label": "MONTCLAIR",
                  "value": "MONTCLAIR"
              },
              {
                  "name": "MARLTON",
                  "label": "MARLTON",
                  "value": "MARLTON"
              },
              {
                  "name": "SOMERS POINT",
                  "label": "SOMERS POINT",
                  "value": "SOMERS POINT"
              },
              {
                  "name": "WESTFIELD",
                  "label": "WESTFIELD",
                  "value": "WESTFIELD"
              },
              {
                  "name": "WESTWOOD",
                  "label": "WESTWOOD",
                  "value": "WESTWOOD"
              },
              {
                  "name": "POMPTON PLAINS",
                  "label": "POMPTON PLAINS",
                  "value": "POMPTON PLAINS"
              },
              {
                  "name": "LAWRENCEVILLE",
                  "label": "LAWRENCEVILLE",
                  "value": "LAWRENCEVILLE"
              },
              {
                  "name": "BOUND BROOK",
                  "label": "BOUND BROOK",
                  "value": "BOUND BROOK"
              },
              {
                  "name": "LAKEWOOD",
                  "label": "LAKEWOOD",
                  "value": "LAKEWOOD"
              },
              {
                  "name": "MARLBORO",
                  "label": "MARLBORO",
                  "value": "MARLBORO"
              },
              {
                  "name": "OCEAN",
                  "label": "OCEAN",
                  "value": "OCEAN"
              },
              {
                  "name": "WEST ATLANTIC CITY",
                  "label": "WEST ATLANTIC CITY",
                  "value": "WEST ATLANTIC CITY"
              },
              {
                  "name": "MOUNT HOLLY",
                  "label": "MOUNT HOLLY",
                  "value": "MOUNT HOLLY"
              },
              {
                  "name": "PRINCETON JUNCTION",
                  "label": "PRINCETON JUNCTION",
                  "value": "PRINCETON JUNCTION"
              },
              {
                  "name": "SWEDESBORO",
                  "label": "SWEDESBORO",
                  "value": "SWEDESBORO"
              },
              {
                  "name": "ROBBINSVILLE",
                  "label": "ROBBINSVILLE",
                  "value": "ROBBINSVILLE"
              },
              {
                  "name": "EAST WINDSOR",
                  "label": "EAST WINDSOR",
                  "value": "EAST WINDSOR"
              },
              {
                  "name": "LINCROFT",
                  "label": "LINCROFT",
                  "value": "LINCROFT"
              },
              {
                  "name": "FAIR HAVEN",
                  "label": "FAIR HAVEN",
                  "value": "FAIR HAVEN"
              },
              {
                  "name": "RAHWAY",
                  "label": "RAHWAY",
                  "value": "RAHWAY"
              },
              {
                  "name": "PERTH AMBOR",
                  "label": "PERTH AMBOR",
                  "value": "PERTH AMBOR"
              },
              {
                  "name": "EGG HARBOR TWP",
                  "label": "EGG HARBOR TWP",
                  "value": "EGG HARBOR TWP"
              },
              {
                  "name": "MAPLEWOOD",
                  "label": "MAPLEWOOD",
                  "value": "MAPLEWOOD"
              },
              {
                  "name": "CARTERET",
                  "label": "CARTERET",
                  "value": "CARTERET"
              },
              {
                  "name": "MAHWAH",
                  "label": "MAHWAH",
                  "value": "MAHWAH"
              },
              {
                  "name": "RARITAN",
                  "label": "RARITAN",
                  "value": "RARITAN"
              },
              {
                  "name": "MANASQUAN",
                  "label": "MANASQUAN",
                  "value": "MANASQUAN"
              },
              {
                  "name": "BAYONNE",
                  "label": "BAYONNE",
                  "value": "BAYONNE"
              },
              {
                  "name": "MILLVILLE",
                  "label": "MILLVILLE",
                  "value": "MILLVILLE"
              }
          ]
      },
      {
          "name": "MD",
          "label": "Maryland",
          "value": "MD",
          "cities": [
              {
                  "name": "OAKLAND",
                  "label": "OAKLAND",
                  "value": "OAKLAND"
              },
              {
                  "name": "CUMBERLAND",
                  "label": "CUMBERLAND",
                  "value": "CUMBERLAND"
              }
          ]
      },
      {
          "name": "ME",
          "label": "Maine",
          "value": "ME",
          "cities": [
              {
                  "name": "SOUTH PORTLAND",
                  "label": "SOUTH PORTLAND",
                  "value": "SOUTH PORTLAND"
              },
              {
                  "name": "FARMINGTON",
                  "label": "FARMINGTON",
                  "value": "FARMINGTON"
              },
              {
                  "name": "BREWER",
                  "label": "BREWER",
                  "value": "BREWER"
              },
              {
                  "name": "SCARBOROUGH",
                  "label": "SCARBOROUGH",
                  "value": "SCARBOROUGH"
              },
              {
                  "name": "AUGUSTA",
                  "label": "AUGUSTA",
                  "value": "AUGUSTA"
              },
              {
                  "name": "SANFORD",
                  "label": "SANFORD",
                  "value": "SANFORD"
              },
              {
                  "name": "PORTLAND",
                  "label": "PORTLAND",
                  "value": "PORTLAND"
              },
              {
                  "name": "YORK",
                  "label": "YORK",
                  "value": "YORK"
              },
              {
                  "name": "CARIBOU",
                  "label": "CARIBOU",
                  "value": "CARIBOU"
              },
              {
                  "name": "DAMARISCOTTA",
                  "label": "DAMARISCOTTA",
                  "value": "DAMARISCOTTA"
              },
              {
                  "name": "SIDNEY",
                  "label": "SIDNEY",
                  "value": "SIDNEY"
              },
              {
                  "name": "LEWISTON",
                  "label": "LEWISTON",
                  "value": "LEWISTON"
              },
              {
                  "name": "BANGOR",
                  "label": "BANGOR",
                  "value": "BANGOR"
              },
              {
                  "name": "CORINTH",
                  "label": "CORINTH",
                  "value": "CORINTH"
              },
              {
                  "name": "KENNEBUNK",
                  "label": "KENNEBUNK",
                  "value": "KENNEBUNK"
              },
              {
                  "name": "BRUNSWICK",
                  "label": "BRUNSWICK",
                  "value": "BRUNSWICK"
              },
              {
                  "name": "PRESQUE ISLE",
                  "label": "PRESQUE ISLE",
                  "value": "PRESQUE ISLE"
              },
              {
                  "name": "BELFAST",
                  "label": "BELFAST",
                  "value": "BELFAST"
              },
              {
                  "name": "MILLINOCKET",
                  "label": "MILLINOCKET",
                  "value": "MILLINOCKET"
              },
              {
                  "name": "TOPSHAM",
                  "label": "TOPSHAM",
                  "value": "TOPSHAM"
              },
              {
                  "name": "WALDOBORO",
                  "label": "WALDOBORO",
                  "value": "WALDOBORO"
              },
              {
                  "name": "BRIDGTON",
                  "label": "BRIDGTON",
                  "value": "BRIDGTON"
              },
              {
                  "name": "ROCKPORT",
                  "label": "ROCKPORT",
                  "value": "ROCKPORT"
              },
              {
                  "name": "ELLSWORTH",
                  "label": "ELLSWORTH",
                  "value": "ELLSWORTH"
              },
              {
                  "name": "WATERVILLE",
                  "label": "WATERVILLE",
                  "value": "WATERVILLE"
              }
          ]
      },
      {
          "name": "VT",
          "label": "Vermont",
          "value": "VT",
          "cities": [
              {
                  "name": "BURLINGTON",
                  "label": "BURLINGTON",
                  "value": "BURLINGTON"
              },
              {
                  "name": "BERLIN",
                  "label": "BERLIN",
                  "value": "BERLIN"
              },
              {
                  "name": "NEWPORT",
                  "label": "NEWPORT",
                  "value": "NEWPORT"
              },
              {
                  "name": "RUTLAND",
                  "label": "RUTLAND",
                  "value": "RUTLAND"
              },
              {
                  "name": "MORRISVILLE",
                  "label": "MORRISVILLE",
                  "value": "MORRISVILLE"
              },
              {
                  "name": "WINDSOR",
                  "label": "WINDSOR",
                  "value": "WINDSOR"
              },
              {
                  "name": "BENNINGTON",
                  "label": "BENNINGTON",
                  "value": "BENNINGTON"
              },
              {
                  "name": "WHITE RIVER JUNCTION",
                  "label": "WHITE RIVER JUNCTION",
                  "value": "WHITE RIVER JUNCTION"
              },
              {
                  "name": "COLCHESTER",
                  "label": "COLCHESTER",
                  "value": "COLCHESTER"
              }
          ]
      },
      {
          "name": "RI",
          "label": "Rhode Island",
          "value": "RI",
          "cities": [
              {
                  "name": "WAKEFIELD",
                  "label": "WAKEFIELD",
                  "value": "WAKEFIELD"
              },
              {
                  "name": "PROVIDENCE",
                  "label": "PROVIDENCE",
                  "value": "PROVIDENCE"
              },
              {
                  "name": "WESTERLY",
                  "label": "WESTERLY",
                  "value": "WESTERLY"
              },
              {
                  "name": "EAST GREENWICH",
                  "label": "EAST GREENWICH",
                  "value": "EAST GREENWICH"
              },
              {
                  "name": "WOONSOCKET",
                  "label": "WOONSOCKET",
                  "value": "WOONSOCKET"
              },
              {
                  "name": "CRANSTON",
                  "label": "CRANSTON",
                  "value": "CRANSTON"
              },
              {
                  "name": "WARWICK",
                  "label": "WARWICK",
                  "value": "WARWICK"
              },
              {
                  "name": "EAST PROVIDENCE",
                  "label": "EAST PROVIDENCE",
                  "value": "EAST PROVIDENCE"
              },
              {
                  "name": "COVENTRY",
                  "label": "COVENTRY",
                  "value": "COVENTRY"
              },
              {
                  "name": "RIVERSIDE",
                  "label": "RIVERSIDE",
                  "value": "RIVERSIDE"
              },
              {
                  "name": "NORTH SMITHFIELD",
                  "label": "NORTH SMITHFIELD",
                  "value": "NORTH SMITHFIELD"
              },
              {
                  "name": "LINCOLN",
                  "label": "LINCOLN",
                  "value": "LINCOLN"
              }
          ]
      },
      {
          "name": "NH",
          "label": "New Hampshire",
          "value": "NH",
          "cities": [
              {
                  "name": "DERRY",
                  "label": "DERRY",
                  "value": "DERRY"
              },
              {
                  "name": "LEBANON",
                  "label": "LEBANON",
                  "value": "LEBANON"
              },
              {
                  "name": "PLYMOUTH",
                  "label": "PLYMOUTH",
                  "value": "PLYMOUTH"
              },
              {
                  "name": "NORTH CONWAY",
                  "label": "NORTH CONWAY",
                  "value": "NORTH CONWAY"
              },
              {
                  "name": "CONCORD",
                  "label": "CONCORD",
                  "value": "CONCORD"
              },
              {
                  "name": "HOOKSETT",
                  "label": "HOOKSETT",
                  "value": "HOOKSETT"
              },
              {
                  "name": "MANCHESTER",
                  "label": "MANCHESTER",
                  "value": "MANCHESTER"
              },
              {
                  "name": "DOVER",
                  "label": "DOVER",
                  "value": "DOVER"
              },
              {
                  "name": "NASHUA",
                  "label": "NASHUA",
                  "value": "NASHUA"
              },
              {
                  "name": "KEENE",
                  "label": "KEENE",
                  "value": "KEENE"
              },
              {
                  "name": "PORTSMOUTH",
                  "label": "PORTSMOUTH",
                  "value": "PORTSMOUTH"
              },
              {
                  "name": "ROCHESTER",
                  "label": "ROCHESTER",
                  "value": "ROCHESTER"
              },
              {
                  "name": "EXETER",
                  "label": "EXETER",
                  "value": "EXETER"
              }
          ]
      },
      {
          "name": "WV",
          "label": "West Virginia",
          "value": "WV",
          "cities": [
              {
                  "name": "MORGANTOWN",
                  "label": "MORGANTOWN",
                  "value": "MORGANTOWN"
              },
              {
                  "name": "WHEELING",
                  "label": "WHEELING",
                  "value": "WHEELING"
              },
              {
                  "name": "WEIRTON",
                  "label": "WEIRTON",
                  "value": "WEIRTON"
              },
              {
                  "name": "MOUNDSVILLE",
                  "label": "MOUNDSVILLE",
                  "value": "MOUNDSVILLE"
              },
              {
                  "name": "BRIDGEPORT",
                  "label": "BRIDGEPORT",
                  "value": "BRIDGEPORT"
              },
              {
                  "name": "GLEN DALE",
                  "label": "GLEN DALE",
                  "value": "GLEN DALE"
              }
          ]
      }
  ]
};

export const allStatesCities = {
  "0": [
      {
          "name": "ALL",
          "label": "ALL",
          "value": "ALL",
          "cities": []
      },
      {
          "name": "CA",
          "label": "California",
          "value": "CA",
          "cities": [
              {
                  "name": "DOWNEY",
                  "label": "DOWNEY",
                  "value": "DOWNEY"
              },
              {
                  "name": "DUARTE",
                  "label": "DUARTE",
                  "value": "DUARTE"
              },
              {
                  "name": "SAN BERNARDINO",
                  "label": "SAN BERNARDINO",
                  "value": "SAN BERNARDINO"
              },
              {
                  "name": "SANTA MARIA",
                  "label": "SANTA MARIA",
                  "value": "SANTA MARIA"
              },
              {
                  "name": "SAN FRANCISCO",
                  "label": "SAN FRANCISCO",
                  "value": "SAN FRANCISCO"
              },
              {
                  "name": "LOS ANGELES",
                  "label": "LOS ANGELES",
                  "value": "LOS ANGELES"
              },
              {
                  "name": "HEMET",
                  "label": "HEMET",
                  "value": "HEMET"
              },
              {
                  "name": "ORANGE",
                  "label": "ORANGE",
                  "value": "ORANGE"
              },
              {
                  "name": "SAN DIEGO",
                  "label": "SAN DIEGO",
                  "value": "SAN DIEGO"
              },
              {
                  "name": "WOODLAND HILLS",
                  "label": "WOODLAND HILLS",
                  "value": "WOODLAND HILLS"
              },
              {
                  "name": "LOMA LINDA",
                  "label": "LOMA LINDA",
                  "value": "LOMA LINDA"
              },
              {
                  "name": "COSTA MESA",
                  "label": "COSTA MESA",
                  "value": "COSTA MESA"
              },
              {
                  "name": "FONTANA",
                  "label": "FONTANA",
                  "value": "FONTANA"
              },
              {
                  "name": "BAKERSFIELD",
                  "label": "BAKERSFIELD",
                  "value": "BAKERSFIELD"
              },
              {
                  "name": "CERRITOS",
                  "label": "CERRITOS",
                  "value": "CERRITOS"
              },
              {
                  "name": "TORRANCE",
                  "label": "TORRANCE",
                  "value": "TORRANCE"
              },
              {
                  "name": "SOUTH SAN FRANCISCO",
                  "label": "SOUTH SAN FRANCISCO",
                  "value": "SOUTH SAN FRANCISCO"
              },
              {
                  "name": "MILL VALLEY",
                  "label": "MILL VALLEY",
                  "value": "MILL VALLEY"
              },
              {
                  "name": "LAGUNA NIGUEL",
                  "label": "LAGUNA NIGUEL",
                  "value": "LAGUNA NIGUEL"
              },
              {
                  "name": "ARLETA",
                  "label": "ARLETA",
                  "value": "ARLETA"
              },
              {
                  "name": "IRVINE",
                  "label": "IRVINE",
                  "value": "IRVINE"
              },
              {
                  "name": "LANCASTER",
                  "label": "LANCASTER",
                  "value": "LANCASTER"
              },
              {
                  "name": "LA JOLLA",
                  "label": "LA JOLLA",
                  "value": "LA JOLLA"
              },
              {
                  "name": "SACRAMENTO",
                  "label": "SACRAMENTO",
                  "value": "SACRAMENTO"
              },
              {
                  "name": "MADERA",
                  "label": "MADERA",
                  "value": "MADERA"
              },
              {
                  "name": "BURBANK",
                  "label": "BURBANK",
                  "value": "BURBANK"
              },
              {
                  "name": "SALINAS",
                  "label": "SALINAS",
                  "value": "SALINAS"
              },
              {
                  "name": "STANFORD",
                  "label": "STANFORD",
                  "value": "STANFORD"
              },
              {
                  "name": "STOCKTON",
                  "label": "STOCKTON",
                  "value": "STOCKTON"
              },
              {
                  "name": "VACAVILLE",
                  "label": "VACAVILLE",
                  "value": "VACAVILLE"
              },
              {
                  "name": "ANAHEIM",
                  "label": "ANAHEIM",
                  "value": "ANAHEIM"
              },
              {
                  "name": "SANTA ROSA",
                  "label": "SANTA ROSA",
                  "value": "SANTA ROSA"
              },
              {
                  "name": "SANTA BARBARA",
                  "label": "SANTA BARBARA",
                  "value": "SANTA BARBARA"
              },
              {
                  "name": "LOS ALAMITOS",
                  "label": "LOS ALAMITOS",
                  "value": "LOS ALAMITOS"
              },
              {
                  "name": "SUNNYVALE",
                  "label": "SUNNYVALE",
                  "value": "SUNNYVALE"
              },
              {
                  "name": "OAKLAND",
                  "label": "OAKLAND",
                  "value": "OAKLAND"
              },
              {
                  "name": "EMERYVILLE",
                  "label": "EMERYVILLE",
                  "value": "EMERYVILLE"
              },
              {
                  "name": "WEST HOLLYWOOD",
                  "label": "WEST HOLLYWOOD",
                  "value": "WEST HOLLYWOOD"
              },
              {
                  "name": "REDDING",
                  "label": "REDDING",
                  "value": "REDDING"
              },
              {
                  "name": "PLEASANT HILL",
                  "label": "PLEASANT HILL",
                  "value": "PLEASANT HILL"
              },
              {
                  "name": "SOUTH SAN FRANC",
                  "label": "SOUTH SAN FRANC",
                  "value": "SOUTH SAN FRANC"
              },
              {
                  "name": "OCEANSIDE",
                  "label": "OCEANSIDE",
                  "value": "OCEANSIDE"
              },
              {
                  "name": "LONG BEACH",
                  "label": "LONG BEACH",
                  "value": "LONG BEACH"
              },
              {
                  "name": "CHICO",
                  "label": "CHICO",
                  "value": "CHICO"
              },
              {
                  "name": "PORTERVILLE",
                  "label": "PORTERVILLE",
                  "value": "PORTERVILLE"
              },
              {
                  "name": "SAN JOSE",
                  "label": "SAN JOSE",
                  "value": "SAN JOSE"
              },
              {
                  "name": "CASTRO VALLEY",
                  "label": "CASTRO VALLEY",
                  "value": "CASTRO VALLEY"
              },
              {
                  "name": "MARTINEZ",
                  "label": "MARTINEZ",
                  "value": "MARTINEZ"
              },
              {
                  "name": "FAIRFIELD",
                  "label": "FAIRFIELD",
                  "value": "FAIRFIELD"
              },
              {
                  "name": "NEWPORT BEACH",
                  "label": "NEWPORT BEACH",
                  "value": "NEWPORT BEACH"
              },
              {
                  "name": "MONTEREY",
                  "label": "MONTEREY",
                  "value": "MONTEREY"
              },
              {
                  "name": "HARBOR CITY",
                  "label": "HARBOR CITY",
                  "value": "HARBOR CITY"
              },
              {
                  "name": "BERKELEY",
                  "label": "BERKELEY",
                  "value": "BERKELEY"
              },
              {
                  "name": "RANCHO MIRAGE",
                  "label": "RANCHO MIRAGE",
                  "value": "RANCHO MIRAGE"
              },
              {
                  "name": "FOUNTAIN VALLEY",
                  "label": "FOUNTAIN VALLEY",
                  "value": "FOUNTAIN VALLEY"
              },
              {
                  "name": "BALDWIN PARK",
                  "label": "BALDWIN PARK",
                  "value": "BALDWIN PARK"
              },
              {
                  "name": "BEVERLY HILLS",
                  "label": "BEVERLY HILLS",
                  "value": "BEVERLY HILLS"
              },
              {
                  "name": "OXNARD",
                  "label": "OXNARD",
                  "value": "OXNARD"
              },
              {
                  "name": "REDWOOD CITY",
                  "label": "REDWOOD CITY",
                  "value": "REDWOOD CITY"
              },
              {
                  "name": "ROSEVILLE",
                  "label": "ROSEVILLE",
                  "value": "ROSEVILLE"
              },
              {
                  "name": "FRENCH CAMP",
                  "label": "FRENCH CAMP",
                  "value": "FRENCH CAMP"
              },
              {
                  "name": "LAGUNA BEACH",
                  "label": "LAGUNA BEACH",
                  "value": "LAGUNA BEACH"
              },
              {
                  "name": "SAN LUIS OBISPO",
                  "label": "SAN LUIS OBISPO",
                  "value": "SAN LUIS OBISPO"
              },
              {
                  "name": "FRESNO",
                  "label": "FRESNO",
                  "value": "FRESNO"
              },
              {
                  "name": "PALO ALTO",
                  "label": "PALO ALTO",
                  "value": "PALO ALTO"
              },
              {
                  "name": "VALLEJO",
                  "label": "VALLEJO",
                  "value": "VALLEJO"
              },
              {
                  "name": "GLENDALE",
                  "label": "GLENDALE",
                  "value": "GLENDALE"
              },
              {
                  "name": "RIVERSIDE",
                  "label": "RIVERSIDE",
                  "value": "RIVERSIDE"
              },
              {
                  "name": "TEMECULA",
                  "label": "TEMECULA",
                  "value": "TEMECULA"
              },
              {
                  "name": "CHINO",
                  "label": "CHINO",
                  "value": "CHINO"
              },
              {
                  "name": "SANTA CRUZ",
                  "label": "SANTA CRUZ",
                  "value": "SANTA CRUZ"
              },
              {
                  "name": "VAN NUYS",
                  "label": "VAN NUYS",
                  "value": "VAN NUYS"
              },
              {
                  "name": "MOUNTAIN VIEW",
                  "label": "MOUNTAIN VIEW",
                  "value": "MOUNTAIN VIEW"
              },
              {
                  "name": "CAMERON PARK",
                  "label": "CAMERON PARK",
                  "value": "CAMERON PARK"
              },
              {
                  "name": "MARYSVILLE",
                  "label": "MARYSVILLE",
                  "value": "MARYSVILLE"
              },
              {
                  "name": "SANTA MONICA",
                  "label": "SANTA MONICA",
                  "value": "SANTA MONICA"
              },
              {
                  "name": "TUSTIN",
                  "label": "TUSTIN",
                  "value": "TUSTIN"
              },
              {
                  "name": "HAYWARD",
                  "label": "HAYWARD",
                  "value": "HAYWARD"
              },
              {
                  "name": "COLTON",
                  "label": "COLTON",
                  "value": "COLTON"
              },
              {
                  "name": "GLENDORA",
                  "label": "GLENDORA",
                  "value": "GLENDORA"
              },
              {
                  "name": "PALMDALE",
                  "label": "PALMDALE",
                  "value": "PALMDALE"
              },
              {
                  "name": "WALNUT CREEK",
                  "label": "WALNUT CREEK",
                  "value": "WALNUT CREEK"
              },
              {
                  "name": "CALABASAS",
                  "label": "CALABASAS",
                  "value": "CALABASAS"
              },
              {
                  "name": "LAGUNA HILLS",
                  "label": "LAGUNA HILLS",
                  "value": "LAGUNA HILLS"
              },
              {
                  "name": "HUNTINGTON BEACH",
                  "label": "HUNTINGTON BEACH",
                  "value": "HUNTINGTON BEACH"
              },
              {
                  "name": "SANTA CLARA",
                  "label": "SANTA CLARA",
                  "value": "SANTA CLARA"
              },
              {
                  "name": "LOSANGELES",
                  "label": "LOSANGELES",
                  "value": "LOSANGELES"
              },
              {
                  "name": "MODESTO",
                  "label": "MODESTO",
                  "value": "MODESTO"
              },
              {
                  "name": "MERCED",
                  "label": "MERCED",
                  "value": "MERCED"
              },
              {
                  "name": "BELLFLOWER",
                  "label": "BELLFLOWER",
                  "value": "BELLFLOWER"
              },
              {
                  "name": "LA MESA",
                  "label": "LA MESA",
                  "value": "LA MESA"
              },
              {
                  "name": "CORONA",
                  "label": "CORONA",
                  "value": "CORONA"
              },
              {
                  "name": "SYLMAR",
                  "label": "SYLMAR",
                  "value": "SYLMAR"
              },
              {
                  "name": "MORENO VALLEY",
                  "label": "MORENO VALLEY",
                  "value": "MORENO VALLEY"
              },
              {
                  "name": "VISALIA",
                  "label": "VISALIA",
                  "value": "VISALIA"
              },
              {
                  "name": "EL CENTRO",
                  "label": "EL CENTRO",
                  "value": "EL CENTRO"
              },
              {
                  "name": "SOUTH PASADENA",
                  "label": "SOUTH PASADENA",
                  "value": "SOUTH PASADENA"
              },
              {
                  "name": "ANTIOCH",
                  "label": "ANTIOCH",
                  "value": "ANTIOCH"
              },
              {
                  "name": "PASADENA",
                  "label": "PASADENA",
                  "value": "PASADENA"
              },
              {
                  "name": "WEST COVINA",
                  "label": "WEST COVINA",
                  "value": "WEST COVINA"
              },
              {
                  "name": "SEAL BEACH",
                  "label": "SEAL BEACH",
                  "value": "SEAL BEACH"
              },
              {
                  "name": "PIEDMONT",
                  "label": "PIEDMONT",
                  "value": "PIEDMONT"
              },
              {
                  "name": "RICHMOND",
                  "label": "RICHMOND",
                  "value": "RICHMOND"
              },
              {
                  "name": "GILROY",
                  "label": "GILROY",
                  "value": "GILROY"
              },
              {
                  "name": "SAN MARCOS",
                  "label": "SAN MARCOS",
                  "value": "SAN MARCOS"
              },
              {
                  "name": "MISSION VIEJO",
                  "label": "MISSION VIEJO",
                  "value": "MISSION VIEJO"
              },
              {
                  "name": "LYNWOOD",
                  "label": "LYNWOOD",
                  "value": "LYNWOOD"
              },
              {
                  "name": "PANORAMA CITY",
                  "label": "PANORAMA CITY",
                  "value": "PANORAMA CITY"
              },
              {
                  "name": "FULLERTON",
                  "label": "FULLERTON",
                  "value": "FULLERTON"
              },
              {
                  "name": "SOLVANG",
                  "label": "SOLVANG",
                  "value": "SOLVANG"
              },
              {
                  "name": "VALENCIA",
                  "label": "VALENCIA",
                  "value": "VALENCIA"
              },
              {
                  "name": "MATHER",
                  "label": "MATHER",
                  "value": "MATHER"
              },
              {
                  "name": "EL SEGUNDO",
                  "label": "EL SEGUNDO",
                  "value": "EL SEGUNDO"
              },
              {
                  "name": "BELMONT",
                  "label": "BELMONT",
                  "value": "BELMONT"
              },
              {
                  "name": "Paramount",
                  "label": "Paramount",
                  "value": "Paramount"
              },
              {
                  "name": "VICTORVILLE",
                  "label": "VICTORVILLE",
                  "value": "VICTORVILLE"
              },
              {
                  "name": "UKIAH",
                  "label": "UKIAH",
                  "value": "UKIAH"
              },
              {
                  "name": "WALNUT",
                  "label": "WALNUT",
                  "value": "WALNUT"
              },
              {
                  "name": "EUREKA",
                  "label": "EUREKA",
                  "value": "EUREKA"
              },
              {
                  "name": "SANTA ANA",
                  "label": "SANTA ANA",
                  "value": "SANTA ANA"
              },
              {
                  "name": "CHULA VISTA",
                  "label": "CHULA VISTA",
                  "value": "CHULA VISTA"
              },
              {
                  "name": "BREA",
                  "label": "BREA",
                  "value": "BREA"
              },
              {
                  "name": "WHITTIER",
                  "label": "WHITTIER",
                  "value": "WHITTIER"
              },
              {
                  "name": "FREMONT",
                  "label": "FREMONT",
                  "value": "FREMONT"
              },
              {
                  "name": "SAN MATEO",
                  "label": "SAN MATEO",
                  "value": "SAN MATEO"
              },
              {
                  "name": "CUPERTINO",
                  "label": "CUPERTINO",
                  "value": "CUPERTINO"
              },
              {
                  "name": "ARCADIA",
                  "label": "ARCADIA",
                  "value": "ARCADIA"
              },
              {
                  "name": "LA CANADA FLINTRIDGE",
                  "label": "LA CANADA FLINTRIDGE",
                  "value": "LA CANADA FLINTRIDGE"
              },
              {
                  "name": "WINTERHAVEN",
                  "label": "WINTERHAVEN",
                  "value": "WINTERHAVEN"
              },
              {
                  "name": "CLOVIS",
                  "label": "CLOVIS",
                  "value": "CLOVIS"
              },
              {
                  "name": "THOUSAND OAKS",
                  "label": "THOUSAND OAKS",
                  "value": "THOUSAND OAKS"
              },
              {
                  "name": "SONORA",
                  "label": "SONORA",
                  "value": "SONORA"
              },
              {
                  "name": "SAN RAMON",
                  "label": "SAN RAMON",
                  "value": "SAN RAMON"
              },
              {
                  "name": "PACIFIC PALISADES",
                  "label": "PACIFIC PALISADES",
                  "value": "PACIFIC PALISADES"
              },
              {
                  "name": "WEST HILLS",
                  "label": "WEST HILLS",
                  "value": "WEST HILLS"
              },
              {
                  "name": "ALISO VIEJO",
                  "label": "ALISO VIEJO",
                  "value": "ALISO VIEJO"
              },
              {
                  "name": "CARMICHAEL",
                  "label": "CARMICHAEL",
                  "value": "CARMICHAEL"
              },
              {
                  "name": "NORTHRIDGE",
                  "label": "NORTHRIDGE",
                  "value": "NORTHRIDGE"
              },
              {
                  "name": "CANOGA PARK",
                  "label": "CANOGA PARK",
                  "value": "CANOGA PARK"
              },
              {
                  "name": "DALY CITY",
                  "label": "DALY CITY",
                  "value": "DALY CITY"
              },
              {
                  "name": "TARZANA",
                  "label": "TARZANA",
                  "value": "TARZANA"
              },
              {
                  "name": "YUBA CITY",
                  "label": "YUBA CITY",
                  "value": "YUBA CITY"
              },
              {
                  "name": "MURRIETA",
                  "label": "MURRIETA",
                  "value": "MURRIETA"
              },
              {
                  "name": "PALM SPRINGS",
                  "label": "PALM SPRINGS",
                  "value": "PALM SPRINGS"
              },
              {
                  "name": "MISSION HILLS",
                  "label": "MISSION HILLS",
                  "value": "MISSION HILLS"
              },
              {
                  "name": "SACRAMNETO",
                  "label": "SACRAMNETO",
                  "value": "SACRAMNETO"
              },
              {
                  "name": "LAKEWOOD",
                  "label": "LAKEWOOD",
                  "value": "LAKEWOOD"
              },
              {
                  "name": "CONCORD",
                  "label": "CONCORD",
                  "value": "CONCORD"
              },
              {
                  "name": "MONTEREY PARK",
                  "label": "MONTEREY PARK",
                  "value": "MONTEREY PARK"
              },
              {
                  "name": "ALHAMBRA",
                  "label": "ALHAMBRA",
                  "value": "ALHAMBRA"
              },
              {
                  "name": "FOLSOM",
                  "label": "FOLSOM",
                  "value": "FOLSOM"
              },
              {
                  "name": "GREENBRAE",
                  "label": "GREENBRAE",
                  "value": "GREENBRAE"
              },
              {
                  "name": "ESCONDIDO",
                  "label": "ESCONDIDO",
                  "value": "ESCONDIDO"
              },
              {
                  "name": "POMONA",
                  "label": "POMONA",
                  "value": "POMONA"
              },
              {
                  "name": "WOODLAND",
                  "label": "WOODLAND",
                  "value": "WOODLAND"
              },
              {
                  "name": "MONTEBELLO",
                  "label": "MONTEBELLO",
                  "value": "MONTEBELLO"
              },
              {
                  "name": "PLEASANTON",
                  "label": "PLEASANTON",
                  "value": "PLEASANTON"
              },
              {
                  "name": "TULARE",
                  "label": "TULARE",
                  "value": "TULARE"
              },
              {
                  "name": "VENTURA",
                  "label": "VENTURA",
                  "value": "VENTURA"
              },
              {
                  "name": "NAPA",
                  "label": "NAPA",
                  "value": "NAPA"
              },
              {
                  "name": "FORT BRAGG",
                  "label": "FORT BRAGG",
                  "value": "FORT BRAGG"
              },
              {
                  "name": "AUBURN",
                  "label": "AUBURN",
                  "value": "AUBURN"
              },
              {
                  "name": "UPLAND",
                  "label": "UPLAND",
                  "value": "UPLAND"
              },
              {
                  "name": "SAINT HELENA",
                  "label": "SAINT HELENA",
                  "value": "SAINT HELENA"
              },
              {
                  "name": "CARLSBAD",
                  "label": "CARLSBAD",
                  "value": "CARLSBAD"
              },
              {
                  "name": "REDONDO BEACH",
                  "label": "REDONDO BEACH",
                  "value": "REDONDO BEACH"
              },
              {
                  "name": "WESTLAKE VILLAGE",
                  "label": "WESTLAKE VILLAGE",
                  "value": "WESTLAKE VILLAGE"
              },
              {
                  "name": "ROCKLIN",
                  "label": "ROCKLIN",
                  "value": "ROCKLIN"
              },
              {
                  "name": "CLAREMONT",
                  "label": "CLAREMONT",
                  "value": "CLAREMONT"
              },
              {
                  "name": "GROVER BEACH",
                  "label": "GROVER BEACH",
                  "value": "GROVER BEACH"
              },
              {
                  "name": "GRASS VALLEY",
                  "label": "GRASS VALLEY",
                  "value": "GRASS VALLEY"
              },
              {
                  "name": "CLEARLAKE",
                  "label": "CLEARLAKE",
                  "value": "CLEARLAKE"
              },
              {
                  "name": "LOS GATOS",
                  "label": "LOS GATOS",
                  "value": "LOS GATOS"
              },
              {
                  "name": "TRUCKEE",
                  "label": "TRUCKEE",
                  "value": "TRUCKEE"
              },
              {
                  "name": "TURLOCK",
                  "label": "TURLOCK",
                  "value": "TURLOCK"
              },
              {
                  "name": "MARINA DEL REY",
                  "label": "MARINA DEL REY",
                  "value": "MARINA DEL REY"
              },
              {
                  "name": "GARDEN GROVE",
                  "label": "GARDEN GROVE",
                  "value": "GARDEN GROVE"
              },
              {
                  "name": "HANFORD",
                  "label": "HANFORD",
                  "value": "HANFORD"
              },
              {
                  "name": "LOMPOC",
                  "label": "LOMPOC",
                  "value": "LOMPOC"
              },
              {
                  "name": "SAN RAFAEL",
                  "label": "SAN RAFAEL",
                  "value": "SAN RAFAEL"
              },
              {
                  "name": "BARSTOW",
                  "label": "BARSTOW",
                  "value": "BARSTOW"
              },
              {
                  "name": "CHINO HILLS",
                  "label": "CHINO HILLS",
                  "value": "CHINO HILLS"
              },
              {
                  "name": "REDLANDS",
                  "label": "REDLANDS",
                  "value": "REDLANDS"
              },
              {
                  "name": "CRESCENT CITY",
                  "label": "CRESCENT CITY",
                  "value": "CRESCENT CITY"
              },
              {
                  "name": "SHERMAN OAKS",
                  "label": "SHERMAN OAKS",
                  "value": "SHERMAN OAKS"
              },
              {
                  "name": "MANTECA",
                  "label": "MANTECA",
                  "value": "MANTECA"
              },
              {
                  "name": "BALDWIN HILLS",
                  "label": "BALDWIN HILLS",
                  "value": "BALDWIN HILLS"
              },
              {
                  "name": "APTOS",
                  "label": "APTOS",
                  "value": "APTOS"
              },
              {
                  "name": "MANHATTAN BEACH",
                  "label": "MANHATTAN BEACH",
                  "value": "MANHATTAN BEACH"
              },
              {
                  "name": "INGLEWOOD",
                  "label": "INGLEWOOD",
                  "value": "INGLEWOOD"
              },
              {
                  "name": "ARROYO GRANDE",
                  "label": "ARROYO GRANDE",
                  "value": "ARROYO GRANDE"
              },
              {
                  "name": "MENLO PARK",
                  "label": "MENLO PARK",
                  "value": "MENLO PARK"
              },
              {
                  "name": "ALAMEDA",
                  "label": "ALAMEDA",
                  "value": "ALAMEDA"
              },
              {
                  "name": "JACKSON",
                  "label": "JACKSON",
                  "value": "JACKSON"
              },
              {
                  "name": "RANCHO CORDOVA",
                  "label": "RANCHO CORDOVA",
                  "value": "RANCHO CORDOVA"
              },
              {
                  "name": "PORTER RANCH",
                  "label": "PORTER RANCH",
                  "value": "PORTER RANCH"
              },
              {
                  "name": "MOKELUMNE HILL",
                  "label": "MOKELUMNE HILL",
                  "value": "MOKELUMNE HILL"
              },
              {
                  "name": "OROVILLE",
                  "label": "OROVILLE",
                  "value": "OROVILLE"
              },
              {
                  "name": "NEWBURY PARK",
                  "label": "NEWBURY PARK",
                  "value": "NEWBURY PARK"
              },
              {
                  "name": "PACIFICA",
                  "label": "PACIFICA",
                  "value": "PACIFICA"
              },
              {
                  "name": "ELK GROVE",
                  "label": "ELK GROVE",
                  "value": "ELK GROVE"
              },
              {
                  "name": "SAN LEANDRO",
                  "label": "SAN LEANDRO",
                  "value": "SAN LEANDRO"
              },
              {
                  "name": "BURLINGAME",
                  "label": "BURLINGAME",
                  "value": "BURLINGAME"
              },
              {
                  "name": "BEAUMONT",
                  "label": "BEAUMONT",
                  "value": "BEAUMONT"
              },
              {
                  "name": "DIXON",
                  "label": "DIXON",
                  "value": "DIXON"
              },
              {
                  "name": "RED BLUFF",
                  "label": "RED BLUFF",
                  "value": "RED BLUFF"
              },
              {
                  "name": "LAGUNA HILL",
                  "label": "LAGUNA HILL",
                  "value": "LAGUNA HILL"
              },
              {
                  "name": "FAIR OAKS",
                  "label": "FAIR OAKS",
                  "value": "FAIR OAKS"
              },
              {
                  "name": "ROSEMEAD",
                  "label": "ROSEMEAD",
                  "value": "ROSEMEAD"
              },
              {
                  "name": "LIVERMORE",
                  "label": "LIVERMORE",
                  "value": "LIVERMORE"
              },
              {
                  "name": "LODI",
                  "label": "LODI",
                  "value": "LODI"
              },
              {
                  "name": "DANA POINT",
                  "label": "DANA POINT",
                  "value": "DANA POINT"
              },
              {
                  "name": "SAN CLEMENTE",
                  "label": "SAN CLEMENTE",
                  "value": "SAN CLEMENTE"
              },
              {
                  "name": "NORCO",
                  "label": "NORCO",
                  "value": "NORCO"
              },
              {
                  "name": "JOSHUA TREE",
                  "label": "JOSHUA TREE",
                  "value": "JOSHUA TREE"
              },
              {
                  "name": "VISTA",
                  "label": "VISTA",
                  "value": "VISTA"
              },
              {
                  "name": "FOSTER CITY",
                  "label": "FOSTER CITY",
                  "value": "FOSTER CITY"
              },
              {
                  "name": "AGOURA HILLS",
                  "label": "AGOURA HILLS",
                  "value": "AGOURA HILLS"
              },
              {
                  "name": "NATIONAL CITY",
                  "label": "NATIONAL CITY",
                  "value": "NATIONAL CITY"
              },
              {
                  "name": "EL MONTE",
                  "label": "EL MONTE",
                  "value": "EL MONTE"
              },
              {
                  "name": "BRAWLEY",
                  "label": "BRAWLEY",
                  "value": "BRAWLEY"
              },
              {
                  "name": "RANCHOCUCOMONGA",
                  "label": "RANCHOCUCOMONGA",
                  "value": "RANCHOCUCOMONGA"
              },
              {
                  "name": "CAMARILLO",
                  "label": "CAMARILLO",
                  "value": "CAMARILLO"
              },
              {
                  "name": "DANVILLE",
                  "label": "DANVILLE",
                  "value": "DANVILLE"
              },
              {
                  "name": "RANCHO CUCAMONG",
                  "label": "RANCHO CUCAMONG",
                  "value": "RANCHO CUCAMONG"
              },
              {
                  "name": "ATHERTON",
                  "label": "ATHERTON",
                  "value": "ATHERTON"
              },
              {
                  "name": "NIPOMO",
                  "label": "NIPOMO",
                  "value": "NIPOMO"
              },
              {
                  "name": "NORTH HOLLYWOOD",
                  "label": "NORTH HOLLYWOOD",
                  "value": "NORTH HOLLYWOOD"
              },
              {
                  "name": "TEMPLETON",
                  "label": "TEMPLETON",
                  "value": "TEMPLETON"
              },
              {
                  "name": "SOLEDAD",
                  "label": "SOLEDAD",
                  "value": "SOLEDAD"
              }
          ]
      },
      {
          "name": "CO",
          "label": "Colorado",
          "value": "CO",
          "cities": [
              {
                  "name": "AURORA",
                  "label": "AURORA",
                  "value": "AURORA"
              },
              {
                  "name": "DENVER",
                  "label": "DENVER",
                  "value": "DENVER"
              },
              {
                  "name": "COLORADO SPRINGS",
                  "label": "COLORADO SPRINGS",
                  "value": "COLORADO SPRINGS"
              },
              {
                  "name": "FT COLLINS",
                  "label": "FT COLLINS",
                  "value": "FT COLLINS"
              },
              {
                  "name": "ENGLEWOOD",
                  "label": "ENGLEWOOD",
                  "value": "ENGLEWOOD"
              },
              {
                  "name": "USAF ACADEMY",
                  "label": "USAF ACADEMY",
                  "value": "USAF ACADEMY"
              },
              {
                  "name": "GLENWOOD SPRINGS",
                  "label": "GLENWOOD SPRINGS",
                  "value": "GLENWOOD SPRINGS"
              },
              {
                  "name": "CANON CITY",
                  "label": "CANON CITY",
                  "value": "CANON CITY"
              },
              {
                  "name": "WHEAT RIDGE",
                  "label": "WHEAT RIDGE",
                  "value": "WHEAT RIDGE"
              },
              {
                  "name": "FORT COLLINS",
                  "label": "FORT COLLINS",
                  "value": "FORT COLLINS"
              },
              {
                  "name": "MONTROSE",
                  "label": "MONTROSE",
                  "value": "MONTROSE"
              },
              {
                  "name": "TRINIDAD",
                  "label": "TRINIDAD",
                  "value": "TRINIDAD"
              },
              {
                  "name": "THORNTON",
                  "label": "THORNTON",
                  "value": "THORNTON"
              },
              {
                  "name": "LITTLETON",
                  "label": "LITTLETON",
                  "value": "LITTLETON"
              },
              {
                  "name": "GRAND JUNCTION",
                  "label": "GRAND JUNCTION",
                  "value": "GRAND JUNCTION"
              },
              {
                  "name": "LOVELAND",
                  "label": "LOVELAND",
                  "value": "LOVELAND"
              },
              {
                  "name": "SALIDA",
                  "label": "SALIDA",
                  "value": "SALIDA"
              },
              {
                  "name": "ASPEN",
                  "label": "ASPEN",
                  "value": "ASPEN"
              },
              {
                  "name": "LAKEWOOD",
                  "label": "LAKEWOOD",
                  "value": "LAKEWOOD"
              },
              {
                  "name": "LONGMONT",
                  "label": "LONGMONT",
                  "value": "LONGMONT"
              },
              {
                  "name": "BRIGHTON",
                  "label": "BRIGHTON",
                  "value": "BRIGHTON"
              },
              {
                  "name": "COLORADO SPGS",
                  "label": "COLORADO SPGS",
                  "value": "COLORADO SPGS"
              },
              {
                  "name": "GLENDALE",
                  "label": "GLENDALE",
                  "value": "GLENDALE"
              },
              {
                  "name": "PUEBLO",
                  "label": "PUEBLO",
                  "value": "PUEBLO"
              },
              {
                  "name": "BOULDER",
                  "label": "BOULDER",
                  "value": "BOULDER"
              },
              {
                  "name": "DURANGO",
                  "label": "DURANGO",
                  "value": "DURANGO"
              },
              {
                  "name": "PAGOSA SPRINGS",
                  "label": "PAGOSA SPRINGS",
                  "value": "PAGOSA SPRINGS"
              },
              {
                  "name": "LONE TREE",
                  "label": "LONE TREE",
                  "value": "LONE TREE"
              },
              {
                  "name": "GREENWOOD VILLAGE",
                  "label": "GREENWOOD VILLAGE",
                  "value": "GREENWOOD VILLAGE"
              },
              {
                  "name": "LAFAYETTE",
                  "label": "LAFAYETTE",
                  "value": "LAFAYETTE"
              },
              {
                  "name": "ARVADA",
                  "label": "ARVADA",
                  "value": "ARVADA"
              },
              {
                  "name": "STERLING",
                  "label": "STERLING",
                  "value": "STERLING"
              },
              {
                  "name": "GOLDEN",
                  "label": "GOLDEN",
                  "value": "GOLDEN"
              },
              {
                  "name": "HIGHLANDS RANCH",
                  "label": "HIGHLANDS RANCH",
                  "value": "HIGHLANDS RANCH"
              },
              {
                  "name": "DELTA",
                  "label": "DELTA",
                  "value": "DELTA"
              },
              {
                  "name": "STMBT SPGS",
                  "label": "STMBT SPGS",
                  "value": "STMBT SPGS"
              },
              {
                  "name": "PARKER",
                  "label": "PARKER",
                  "value": "PARKER"
              },
              {
                  "name": "GREELEY",
                  "label": "GREELEY",
                  "value": "GREELEY"
              },
              {
                  "name": "ALAMOSA",
                  "label": "ALAMOSA",
                  "value": "ALAMOSA"
              },
              {
                  "name": "EDWARDS",
                  "label": "EDWARDS",
                  "value": "EDWARDS"
              },
              {
                  "name": "BROOMFIELD",
                  "label": "BROOMFIELD",
                  "value": "BROOMFIELD"
              }
          ]
      },
      {
          "name": "NC",
          "label": "North Carolina",
          "value": "NC",
          "cities": [
              {
                  "name": "GREENSBORO",
                  "label": "GREENSBORO",
                  "value": "GREENSBORO"
              },
              {
                  "name": "CHAPEL HILL",
                  "label": "CHAPEL HILL",
                  "value": "CHAPEL HILL"
              },
              {
                  "name": "DURHAM",
                  "label": "DURHAM",
                  "value": "DURHAM"
              },
              {
                  "name": "WINSTON SALEM",
                  "label": "WINSTON SALEM",
                  "value": "WINSTON SALEM"
              },
              {
                  "name": "FAYETTEVILLE",
                  "label": "FAYETTEVILLE",
                  "value": "FAYETTEVILLE"
              },
              {
                  "name": "RALEIGH",
                  "label": "RALEIGH",
                  "value": "RALEIGH"
              },
              {
                  "name": "CHARLOTTE",
                  "label": "CHARLOTTE",
                  "value": "CHARLOTTE"
              },
              {
                  "name": "ASHEVILLE",
                  "label": "ASHEVILLE",
                  "value": "ASHEVILLE"
              },
              {
                  "name": "GREENVILLE",
                  "label": "GREENVILLE",
                  "value": "GREENVILLE"
              },
              {
                  "name": "MONROE",
                  "label": "MONROE",
                  "value": "MONROE"
              },
              {
                  "name": "MORGANTON",
                  "label": "MORGANTON",
                  "value": "MORGANTON"
              },
              {
                  "name": "NAGS HEAD",
                  "label": "NAGS HEAD",
                  "value": "NAGS HEAD"
              },
              {
                  "name": "BOONE",
                  "label": "BOONE",
                  "value": "BOONE"
              },
              {
                  "name": "REIDSVILLE",
                  "label": "REIDSVILLE",
                  "value": "REIDSVILLE"
              },
              {
                  "name": "HENDERSONVILLE",
                  "label": "HENDERSONVILLE",
                  "value": "HENDERSONVILLE"
              },
              {
                  "name": "LINCOLNTON",
                  "label": "LINCOLNTON",
                  "value": "LINCOLNTON"
              },
              {
                  "name": "CARY",
                  "label": "CARY",
                  "value": "CARY"
              },
              {
                  "name": "PINEHURST",
                  "label": "PINEHURST",
                  "value": "PINEHURST"
              },
              {
                  "name": "GASTONIA",
                  "label": "GASTONIA",
                  "value": "GASTONIA"
              },
              {
                  "name": "EDEN",
                  "label": "EDEN",
                  "value": "EDEN"
              },
              {
                  "name": "HICKORY",
                  "label": "HICKORY",
                  "value": "HICKORY"
              },
              {
                  "name": "N WILKESBORO",
                  "label": "N WILKESBORO",
                  "value": "N WILKESBORO"
              },
              {
                  "name": "CLYDE",
                  "label": "CLYDE",
                  "value": "CLYDE"
              },
              {
                  "name": "WINDSOR",
                  "label": "WINDSOR",
                  "value": "WINDSOR"
              },
              {
                  "name": "HUNTERSVILLE",
                  "label": "HUNTERSVILLE",
                  "value": "HUNTERSVILLE"
              },
              {
                  "name": "HENDERSON",
                  "label": "HENDERSON",
                  "value": "HENDERSON"
              },
              {
                  "name": "HIGH POINT",
                  "label": "HIGH POINT",
                  "value": "HIGH POINT"
              },
              {
                  "name": "WILSON",
                  "label": "WILSON",
                  "value": "WILSON"
              },
              {
                  "name": "STATESVILLE",
                  "label": "STATESVILLE",
                  "value": "STATESVILLE"
              },
              {
                  "name": "ASHEBORO",
                  "label": "ASHEBORO",
                  "value": "ASHEBORO"
              },
              {
                  "name": "SALISBURY",
                  "label": "SALISBURY",
                  "value": "SALISBURY"
              },
              {
                  "name": "THOMASVILLE",
                  "label": "THOMASVILLE",
                  "value": "THOMASVILLE"
              },
              {
                  "name": "MOREHEAD CITY",
                  "label": "MOREHEAD CITY",
                  "value": "MOREHEAD CITY"
              },
              {
                  "name": "BURLINGTON",
                  "label": "BURLINGTON",
                  "value": "BURLINGTON"
              },
              {
                  "name": "SHELBY",
                  "label": "SHELBY",
                  "value": "SHELBY"
              },
              {
                  "name": "CARRBORO",
                  "label": "CARRBORO",
                  "value": "CARRBORO"
              },
              {
                  "name": "WILMINGTON",
                  "label": "WILMINGTON",
                  "value": "WILMINGTON"
              },
              {
                  "name": "NEW BERN",
                  "label": "NEW BERN",
                  "value": "NEW BERN"
              },
              {
                  "name": "LAURINBURG",
                  "label": "LAURINBURG",
                  "value": "LAURINBURG"
              },
              {
                  "name": "ROANOKE RAPIDS",
                  "label": "ROANOKE RAPIDS",
                  "value": "ROANOKE RAPIDS"
              },
              {
                  "name": "GARNER",
                  "label": "GARNER",
                  "value": "GARNER"
              },
              {
                  "name": "CONCORD",
                  "label": "CONCORD",
                  "value": "CONCORD"
              },
              {
                  "name": "MATTHEWS",
                  "label": "MATTHEWS",
                  "value": "MATTHEWS"
              },
              {
                  "name": "GOLDSBORO",
                  "label": "GOLDSBORO",
                  "value": "GOLDSBORO"
              },
              {
                  "name": "NORLINA",
                  "label": "NORLINA",
                  "value": "NORLINA"
              },
              {
                  "name": "MOORESVILLE",
                  "label": "MOORESVILLE",
                  "value": "MOORESVILLE"
              },
              {
                  "name": "LUMBERTON",
                  "label": "LUMBERTON",
                  "value": "LUMBERTON"
              },
              {
                  "name": "VALDESE",
                  "label": "VALDESE",
                  "value": "VALDESE"
              },
              {
                  "name": "CULLOWHEE",
                  "label": "CULLOWHEE",
                  "value": "CULLOWHEE"
              },
              {
                  "name": "KINGS MOUNTAIN",
                  "label": "KINGS MOUNTAIN",
                  "value": "KINGS MOUNTAIN"
              },
              {
                  "name": "CONOVER",
                  "label": "CONOVER",
                  "value": "CONOVER"
              },
              {
                  "name": "MILLS RIVER",
                  "label": "MILLS RIVER",
                  "value": "MILLS RIVER"
              },
              {
                  "name": "ROCKY MOUNT",
                  "label": "ROCKY MOUNT",
                  "value": "ROCKY MOUNT"
              },
              {
                  "name": "SMITHFIELD",
                  "label": "SMITHFIELD",
                  "value": "SMITHFIELD"
              },
              {
                  "name": "MARION",
                  "label": "MARION",
                  "value": "MARION"
              },
              {
                  "name": "WHITEVILLE",
                  "label": "WHITEVILLE",
                  "value": "WHITEVILLE"
              },
              {
                  "name": "CAROLINA BEACH",
                  "label": "CAROLINA BEACH",
                  "value": "CAROLINA BEACH"
              },
              {
                  "name": "KENANSVILLE",
                  "label": "KENANSVILLE",
                  "value": "KENANSVILLE"
              },
              {
                  "name": "SHALLOTTE",
                  "label": "SHALLOTTE",
                  "value": "SHALLOTTE"
              },
              {
                  "name": "KINSTON",
                  "label": "KINSTON",
                  "value": "KINSTON"
              },
              {
                  "name": "LENOIR",
                  "label": "LENOIR",
                  "value": "LENOIR"
              },
              {
                  "name": "JACKSONVILLE",
                  "label": "JACKSONVILLE",
                  "value": "JACKSONVILLE"
              },
              {
                  "name": "NEBO",
                  "label": "NEBO",
                  "value": "NEBO"
              },
              {
                  "name": "MORRISVILLE",
                  "label": "MORRISVILLE",
                  "value": "MORRISVILLE"
              },
              {
                  "name": "FLETCHER",
                  "label": "FLETCHER",
                  "value": "FLETCHER"
              },
              {
                  "name": "CLEMMONS",
                  "label": "CLEMMONS",
                  "value": "CLEMMONS"
              },
              {
                  "name": "LILLINGTON",
                  "label": "LILLINGTON",
                  "value": "LILLINGTON"
              },
              {
                  "name": "ALBEMARLE",
                  "label": "ALBEMARLE",
                  "value": "ALBEMARLE"
              },
              {
                  "name": "KERNERSVILLE",
                  "label": "KERNERSVILLE",
                  "value": "KERNERSVILLE"
              },
              {
                  "name": "ROWLAND",
                  "label": "ROWLAND",
                  "value": "ROWLAND"
              },
              {
                  "name": "SPRUCE PINE",
                  "label": "SPRUCE PINE",
                  "value": "SPRUCE PINE"
              },
              {
                  "name": "NEWPORT",
                  "label": "NEWPORT",
                  "value": "NEWPORT"
              },
              {
                  "name": "ELIZABETH CITY",
                  "label": "ELIZABETH CITY",
                  "value": "ELIZABETH CITY"
              },
              {
                  "name": "FOREST CITY",
                  "label": "FOREST CITY",
                  "value": "FOREST CITY"
              },
              {
                  "name": "Sylva",
                  "label": "Sylva",
                  "value": "Sylva"
              },
              {
                  "name": "LEWISVILLE",
                  "label": "LEWISVILLE",
                  "value": "LEWISVILLE"
              },
              {
                  "name": "AHOSKIE",
                  "label": "AHOSKIE",
                  "value": "AHOSKIE"
              }
          ]
      },
      {
          "name": "TN",
          "label": "Tennessee",
          "value": "TN",
          "cities": [
              {
                  "name": "MURFREESBORO",
                  "label": "MURFREESBORO",
                  "value": "MURFREESBORO"
              },
              {
                  "name": "MEMPHIS",
                  "label": "MEMPHIS",
                  "value": "MEMPHIS"
              },
              {
                  "name": "NASHVILLE",
                  "label": "NASHVILLE",
                  "value": "NASHVILLE"
              },
              {
                  "name": "KNOXVILLE",
                  "label": "KNOXVILLE",
                  "value": "KNOXVILLE"
              },
              {
                  "name": "CHATTANOOGA",
                  "label": "CHATTANOOGA",
                  "value": "CHATTANOOGA"
              },
              {
                  "name": "GERMANTOWN",
                  "label": "GERMANTOWN",
                  "value": "GERMANTOWN"
              },
              {
                  "name": "OAK RIDGE",
                  "label": "OAK RIDGE",
                  "value": "OAK RIDGE"
              },
              {
                  "name": "GREENEVILLE",
                  "label": "GREENEVILLE",
                  "value": "GREENEVILLE"
              },
              {
                  "name": "ALCOA",
                  "label": "ALCOA",
                  "value": "ALCOA"
              },
              {
                  "name": "JOHNSON CITY",
                  "label": "JOHNSON CITY",
                  "value": "JOHNSON CITY"
              },
              {
                  "name": "GALLATIN",
                  "label": "GALLATIN",
                  "value": "GALLATIN"
              },
              {
                  "name": "COLUMBIA",
                  "label": "COLUMBIA",
                  "value": "COLUMBIA"
              },
              {
                  "name": "JACKSON",
                  "label": "JACKSON",
                  "value": "JACKSON"
              },
              {
                  "name": "HERMITAGE",
                  "label": "HERMITAGE",
                  "value": "HERMITAGE"
              },
              {
                  "name": "BARTLETT",
                  "label": "BARTLETT",
                  "value": "BARTLETT"
              },
              {
                  "name": "POWELL",
                  "label": "POWELL",
                  "value": "POWELL"
              },
              {
                  "name": "SEVIERVILLE",
                  "label": "SEVIERVILLE",
                  "value": "SEVIERVILLE"
              },
              {
                  "name": "HENDERSONVILLE",
                  "label": "HENDERSONVILLE",
                  "value": "HENDERSONVILLE"
              },
              {
                  "name": "CLARKSVILLE",
                  "label": "CLARKSVILLE",
                  "value": "CLARKSVILLE"
              },
              {
                  "name": "KINGSPORT",
                  "label": "KINGSPORT",
                  "value": "KINGSPORT"
              },
              {
                  "name": "ATHENS",
                  "label": "ATHENS",
                  "value": "ATHENS"
              },
              {
                  "name": "CROSSVILLE",
                  "label": "CROSSVILLE",
                  "value": "CROSSVILLE"
              },
              {
                  "name": "DICKSON",
                  "label": "DICKSON",
                  "value": "DICKSON"
              },
              {
                  "name": "DYERSBURG",
                  "label": "DYERSBURG",
                  "value": "DYERSBURG"
              },
              {
                  "name": "FRANKLIN",
                  "label": "FRANKLIN",
                  "value": "FRANKLIN"
              },
              {
                  "name": "LA FOLLETTE",
                  "label": "LA FOLLETTE",
                  "value": "LA FOLLETTE"
              },
              {
                  "name": "SPRING HILL",
                  "label": "SPRING HILL",
                  "value": "SPRING HILL"
              },
              {
                  "name": "CHURCH HILL",
                  "label": "CHURCH HILL",
                  "value": "CHURCH HILL"
              },
              {
                  "name": "MCMINNVILLE",
                  "label": "MCMINNVILLE",
                  "value": "MCMINNVILLE"
              },
              {
                  "name": "LENOIR CITY",
                  "label": "LENOIR CITY",
                  "value": "LENOIR CITY"
              },
              {
                  "name": "SHELBYVILLE",
                  "label": "SHELBYVILLE",
                  "value": "SHELBYVILLE"
              },
              {
                  "name": "COOKEVILLE",
                  "label": "COOKEVILLE",
                  "value": "COOKEVILLE"
              },
              {
                  "name": "MARYVILLE",
                  "label": "MARYVILLE",
                  "value": "MARYVILLE"
              },
              {
                  "name": "HARROGATE",
                  "label": "HARROGATE",
                  "value": "HARROGATE"
              },
              {
                  "name": "DANDRIDGE",
                  "label": "DANDRIDGE",
                  "value": "DANDRIDGE"
              },
              {
                  "name": "SPRINGFIELD",
                  "label": "SPRINGFIELD",
                  "value": "SPRINGFIELD"
              },
              {
                  "name": "MORRISTOWN",
                  "label": "MORRISTOWN",
                  "value": "MORRISTOWN"
              },
              {
                  "name": "UNION CITY",
                  "label": "UNION CITY",
                  "value": "UNION CITY"
              },
              {
                  "name": "SMYRNA",
                  "label": "SMYRNA",
                  "value": "SMYRNA"
              },
              {
                  "name": "COLLIERVILLE",
                  "label": "COLLIERVILLE",
                  "value": "COLLIERVILLE"
              },
              {
                  "name": "SAVANNAH",
                  "label": "SAVANNAH",
                  "value": "SAVANNAH"
              },
              {
                  "name": "PARIS",
                  "label": "PARIS",
                  "value": "PARIS"
              },
              {
                  "name": "MOUNTAIN HOME",
                  "label": "MOUNTAIN HOME",
                  "value": "MOUNTAIN HOME"
              },
              {
                  "name": "LAFOLLETTE",
                  "label": "LAFOLLETTE",
                  "value": "LAFOLLETTE"
              },
              {
                  "name": "CLEVELAND",
                  "label": "CLEVELAND",
                  "value": "CLEVELAND"
              },
              {
                  "name": "JONESBOROUGH",
                  "label": "JONESBOROUGH",
                  "value": "JONESBOROUGH"
              },
              {
                  "name": "COVINGTON",
                  "label": "COVINGTON",
                  "value": "COVINGTON"
              }
          ]
      },
      {
          "name": "FL",
          "label": "Florida",
          "value": "FL",
          "cities": [
              {
                  "name": "CAPE CORAL",
                  "label": "CAPE CORAL",
                  "value": "CAPE CORAL"
              },
              {
                  "name": "MIAMI",
                  "label": "MIAMI",
                  "value": "MIAMI"
              },
              {
                  "name": "TAMPA",
                  "label": "TAMPA",
                  "value": "TAMPA"
              },
              {
                  "name": "JACKSONVILLE",
                  "label": "JACKSONVILLE",
                  "value": "JACKSONVILLE"
              },
              {
                  "name": "ORLANDO",
                  "label": "ORLANDO",
                  "value": "ORLANDO"
              },
              {
                  "name": "KISSIMMEE",
                  "label": "KISSIMMEE",
                  "value": "KISSIMMEE"
              },
              {
                  "name": "PORT RICHEY",
                  "label": "PORT RICHEY",
                  "value": "PORT RICHEY"
              },
              {
                  "name": "GAINESVILLE",
                  "label": "GAINESVILLE",
                  "value": "GAINESVILLE"
              },
              {
                  "name": "PEMBROKE PINES",
                  "label": "PEMBROKE PINES",
                  "value": "PEMBROKE PINES"
              },
              {
                  "name": "THE VILLAGES",
                  "label": "THE VILLAGES",
                  "value": "THE VILLAGES"
              },
              {
                  "name": "MAITLAND",
                  "label": "MAITLAND",
                  "value": "MAITLAND"
              },
              {
                  "name": "CLERMONT",
                  "label": "CLERMONT",
                  "value": "CLERMONT"
              },
              {
                  "name": "STUART",
                  "label": "STUART",
                  "value": "STUART"
              },
              {
                  "name": "WEST PALM BEACH",
                  "label": "WEST PALM BEACH",
                  "value": "WEST PALM BEACH"
              },
              {
                  "name": "FERNANDINA BEACH",
                  "label": "FERNANDINA BEACH",
                  "value": "FERNANDINA BEACH"
              },
              {
                  "name": "WESTON",
                  "label": "WESTON",
                  "value": "WESTON"
              },
              {
                  "name": "HIALEAH",
                  "label": "HIALEAH",
                  "value": "HIALEAH"
              },
              {
                  "name": "BROOKSVILLE",
                  "label": "BROOKSVILLE",
                  "value": "BROOKSVILLE"
              },
              {
                  "name": "PLANTATION",
                  "label": "PLANTATION",
                  "value": "PLANTATION"
              },
              {
                  "name": "OCALA",
                  "label": "OCALA",
                  "value": "OCALA"
              },
              {
                  "name": "Hollywood",
                  "label": "Hollywood",
                  "value": "Hollywood"
              },
              {
                  "name": "REDDICK",
                  "label": "REDDICK",
                  "value": "REDDICK"
              },
              {
                  "name": "ST AUGUSTINE",
                  "label": "ST AUGUSTINE",
                  "value": "ST AUGUSTINE"
              },
              {
                  "name": "PENSACOLA",
                  "label": "PENSACOLA",
                  "value": "PENSACOLA"
              },
              {
                  "name": "SUNRISE",
                  "label": "SUNRISE",
                  "value": "SUNRISE"
              },
              {
                  "name": "BOCA RATON",
                  "label": "BOCA RATON",
                  "value": "BOCA RATON"
              },
              {
                  "name": "TALLAHASSEE",
                  "label": "TALLAHASSEE",
                  "value": "TALLAHASSEE"
              },
              {
                  "name": "FORT WALTON BEACH",
                  "label": "FORT WALTON BEACH",
                  "value": "FORT WALTON BEACH"
              },
              {
                  "name": "DAYTONA BEACH",
                  "label": "DAYTONA BEACH",
                  "value": "DAYTONA BEACH"
              },
              {
                  "name": "ORANGE PARK",
                  "label": "ORANGE PARK",
                  "value": "ORANGE PARK"
              },
              {
                  "name": "VERO BEACH",
                  "label": "VERO BEACH",
                  "value": "VERO BEACH"
              },
              {
                  "name": "SARASOTA",
                  "label": "SARASOTA",
                  "value": "SARASOTA"
              },
              {
                  "name": "MIAMI BEACH",
                  "label": "MIAMI BEACH",
                  "value": "MIAMI BEACH"
              },
              {
                  "name": "SAINT PETERSBURG",
                  "label": "SAINT PETERSBURG",
                  "value": "SAINT PETERSBURG"
              },
              {
                  "name": "CLEARWATER",
                  "label": "CLEARWATER",
                  "value": "CLEARWATER"
              },
              {
                  "name": "CORAL GABLES",
                  "label": "CORAL GABLES",
                  "value": "CORAL GABLES"
              },
              {
                  "name": "PALM COAST",
                  "label": "PALM COAST",
                  "value": "PALM COAST"
              },
              {
                  "name": "LEESBURG",
                  "label": "LEESBURG",
                  "value": "LEESBURG"
              },
              {
                  "name": "PANAMA CITY",
                  "label": "PANAMA CITY",
                  "value": "PANAMA CITY"
              },
              {
                  "name": "PALM BAY",
                  "label": "PALM BAY",
                  "value": "PALM BAY"
              },
              {
                  "name": "PALM BEACH GARDENS",
                  "label": "PALM BEACH GARDENS",
                  "value": "PALM BEACH GARDENS"
              },
              {
                  "name": "JUPITER",
                  "label": "JUPITER",
                  "value": "JUPITER"
              },
              {
                  "name": "ST PETERSBURG",
                  "label": "ST PETERSBURG",
                  "value": "ST PETERSBURG"
              },
              {
                  "name": "MIRAMAR",
                  "label": "MIRAMAR",
                  "value": "MIRAMAR"
              },
              {
                  "name": "MELBOURNE",
                  "label": "MELBOURNE",
                  "value": "MELBOURNE"
              },
              {
                  "name": "HUDSON",
                  "label": "HUDSON",
                  "value": "HUDSON"
              },
              {
                  "name": "TAMARAC",
                  "label": "TAMARAC",
                  "value": "TAMARAC"
              },
              {
                  "name": "RIVERVIEW",
                  "label": "RIVERVIEW",
                  "value": "RIVERVIEW"
              },
              {
                  "name": "ORMOND BEACH",
                  "label": "ORMOND BEACH",
                  "value": "ORMOND BEACH"
              },
              {
                  "name": "TRINITY",
                  "label": "TRINITY",
                  "value": "TRINITY"
              },
              {
                  "name": "FLEMING ISLAND",
                  "label": "FLEMING ISLAND",
                  "value": "FLEMING ISLAND"
              },
              {
                  "name": "WINTER PARK",
                  "label": "WINTER PARK",
                  "value": "WINTER PARK"
              },
              {
                  "name": "DELRAY BEACH",
                  "label": "DELRAY BEACH",
                  "value": "DELRAY BEACH"
              },
              {
                  "name": "MIAMI LAKES",
                  "label": "MIAMI LAKES",
                  "value": "MIAMI LAKES"
              },
              {
                  "name": "BRANDON",
                  "label": "BRANDON",
                  "value": "BRANDON"
              },
              {
                  "name": "NEW SMYRNA BEACH",
                  "label": "NEW SMYRNA BEACH",
                  "value": "NEW SMYRNA BEACH"
              },
              {
                  "name": "FORT LAUDERDALE",
                  "label": "FORT LAUDERDALE",
                  "value": "FORT LAUDERDALE"
              },
              {
                  "name": "CORAL SPRINGS",
                  "label": "CORAL SPRINGS",
                  "value": "CORAL SPRINGS"
              },
              {
                  "name": "SPRING HILL",
                  "label": "SPRING HILL",
                  "value": "SPRING HILL"
              },
              {
                  "name": "PORT ST LUCIE",
                  "label": "PORT ST LUCIE",
                  "value": "PORT ST LUCIE"
              },
              {
                  "name": "OVIEDO",
                  "label": "OVIEDO",
                  "value": "OVIEDO"
              },
              {
                  "name": "WINTER HAVEN",
                  "label": "WINTER HAVEN",
                  "value": "WINTER HAVEN"
              },
              {
                  "name": "RUSKIN",
                  "label": "RUSKIN",
                  "value": "RUSKIN"
              },
              {
                  "name": "LOXAHATCHEE",
                  "label": "LOXAHATCHEE",
                  "value": "LOXAHATCHEE"
              },
              {
                  "name": "PORT ORANGE",
                  "label": "PORT ORANGE",
                  "value": "PORT ORANGE"
              },
              {
                  "name": "HOMESTEAD",
                  "label": "HOMESTEAD",
                  "value": "HOMESTEAD"
              },
              {
                  "name": "ST. PETERSBURG",
                  "label": "ST. PETERSBURG",
                  "value": "ST. PETERSBURG"
              },
              {
                  "name": "NEW PORT RICHEY",
                  "label": "NEW PORT RICHEY",
                  "value": "NEW PORT RICHEY"
              },
              {
                  "name": "FORT MYERS",
                  "label": "FORT MYERS",
                  "value": "FORT MYERS"
              },
              {
                  "name": "SOUTH MIAMI",
                  "label": "SOUTH MIAMI",
                  "value": "SOUTH MIAMI"
              },
              {
                  "name": "TAVARES",
                  "label": "TAVARES",
                  "value": "TAVARES"
              },
              {
                  "name": "LAKEWOOD RANCH",
                  "label": "LAKEWOOD RANCH",
                  "value": "LAKEWOOD RANCH"
              },
              {
                  "name": "TEMPLE TERRACE",
                  "label": "TEMPLE TERRACE",
                  "value": "TEMPLE TERRACE"
              },
              {
                  "name": "PALM SPRINGS",
                  "label": "PALM SPRINGS",
                  "value": "PALM SPRINGS"
              },
              {
                  "name": "DAVENPORT",
                  "label": "DAVENPORT",
                  "value": "DAVENPORT"
              },
              {
                  "name": "PORT CHARLOTTE",
                  "label": "PORT CHARLOTTE",
                  "value": "PORT CHARLOTTE"
              },
              {
                  "name": "NAPLES",
                  "label": "NAPLES",
                  "value": "NAPLES"
              },
              {
                  "name": "BAY PINES",
                  "label": "BAY PINES",
                  "value": "BAY PINES"
              },
              {
                  "name": "MIAMI GARDENS",
                  "label": "MIAMI GARDENS",
                  "value": "MIAMI GARDENS"
              },
              {
                  "name": "DELAND",
                  "label": "DELAND",
                  "value": "DELAND"
              },
              {
                  "name": "LARGO",
                  "label": "LARGO",
                  "value": "LARGO"
              },
              {
                  "name": "MARIANNA",
                  "label": "MARIANNA",
                  "value": "MARIANNA"
              },
              {
                  "name": "ENGLEWOOD",
                  "label": "ENGLEWOOD",
                  "value": "ENGLEWOOD"
              },
              {
                  "name": "LAKELAND",
                  "label": "LAKELAND",
                  "value": "LAKELAND"
              },
              {
                  "name": "LAKE WORTH",
                  "label": "LAKE WORTH",
                  "value": "LAKE WORTH"
              },
              {
                  "name": "PLANT CITY",
                  "label": "PLANT CITY",
                  "value": "PLANT CITY"
              },
              {
                  "name": "PORT SAINT LUCIE",
                  "label": "PORT SAINT LUCIE",
                  "value": "PORT SAINT LUCIE"
              },
              {
                  "name": "BOYNTON BEACH",
                  "label": "BOYNTON BEACH",
                  "value": "BOYNTON BEACH"
              },
              {
                  "name": "BRADENTON",
                  "label": "BRADENTON",
                  "value": "BRADENTON"
              },
              {
                  "name": "VENICE",
                  "label": "VENICE",
                  "value": "VENICE"
              },
              {
                  "name": "FORT PIERCE",
                  "label": "FORT PIERCE",
                  "value": "FORT PIERCE"
              },
              {
                  "name": "FT LAUDERDALE",
                  "label": "FT LAUDERDALE",
                  "value": "FT LAUDERDALE"
              },
              {
                  "name": "ORANGE CITY",
                  "label": "ORANGE CITY",
                  "value": "ORANGE CITY"
              },
              {
                  "name": "LAKE CITY",
                  "label": "LAKE CITY",
                  "value": "LAKE CITY"
              },
              {
                  "name": "WESLEY CHAPEL",
                  "label": "WESLEY CHAPEL",
                  "value": "WESLEY CHAPEL"
              },
              {
                  "name": "SEBRING",
                  "label": "SEBRING",
                  "value": "SEBRING"
              },
              {
                  "name": "LECANTO",
                  "label": "LECANTO",
                  "value": "LECANTO"
              },
              {
                  "name": "EGLIN AFB",
                  "label": "EGLIN AFB",
                  "value": "EGLIN AFB"
              },
              {
                  "name": "WEST JACKSONVILLE",
                  "label": "WEST JACKSONVILLE",
                  "value": "WEST JACKSONVILLE"
              },
              {
                  "name": "ALTAMONTE SPRINGS",
                  "label": "ALTAMONTE SPRINGS",
                  "value": "ALTAMONTE SPRINGS"
              },
              {
                  "name": "PALM CITY",
                  "label": "PALM CITY",
                  "value": "PALM CITY"
              },
              {
                  "name": "ATLANTIS",
                  "label": "ATLANTIS",
                  "value": "ATLANTIS"
              },
              {
                  "name": "BONITA SPRINGS",
                  "label": "BONITA SPRINGS",
                  "value": "BONITA SPRINGS"
              },
              {
                  "name": "ZEPHYRHILLS",
                  "label": "ZEPHYRHILLS",
                  "value": "ZEPHYRHILLS"
              },
              {
                  "name": "TITUSVILLE",
                  "label": "TITUSVILLE",
                  "value": "TITUSVILLE"
              },
              {
                  "name": "FT WALTON BEACH",
                  "label": "FT WALTON BEACH",
                  "value": "FT WALTON BEACH"
              },
              {
                  "name": "SANTA ROSA BEACH",
                  "label": "SANTA ROSA BEACH",
                  "value": "SANTA ROSA BEACH"
              },
              {
                  "name": "MARGATE",
                  "label": "MARGATE",
                  "value": "MARGATE"
              },
              {
                  "name": "DEERFIELD BEACH",
                  "label": "DEERFIELD BEACH",
                  "value": "DEERFIELD BEACH"
              },
              {
                  "name": "SEBASTIAN",
                  "label": "SEBASTIAN",
                  "value": "SEBASTIAN"
              },
              {
                  "name": "MIDDLEBURG",
                  "label": "MIDDLEBURG",
                  "value": "MIDDLEBURG"
              },
              {
                  "name": "KEY WEST",
                  "label": "KEY WEST",
                  "value": "KEY WEST"
              },
              {
                  "name": "AVENTURA",
                  "label": "AVENTURA",
                  "value": "AVENTURA"
              },
              {
                  "name": "ROCKLEDGE",
                  "label": "ROCKLEDGE",
                  "value": "ROCKLEDGE"
              },
              {
                  "name": "LADY LAKE",
                  "label": "LADY LAKE",
                  "value": "LADY LAKE"
              },
              {
                  "name": "INVERNESS",
                  "label": "INVERNESS",
                  "value": "INVERNESS"
              },
              {
                  "name": "ALTAMONTE",
                  "label": "ALTAMONTE",
                  "value": "ALTAMONTE"
              },
              {
                  "name": "MARATHON",
                  "label": "MARATHON",
                  "value": "MARATHON"
              },
              {
                  "name": "SAFETY HARBOR",
                  "label": "SAFETY HARBOR",
                  "value": "SAFETY HARBOR"
              },
              {
                  "name": "DORAL",
                  "label": "DORAL",
                  "value": "DORAL"
              },
              {
                  "name": "LAKE MARY",
                  "label": "LAKE MARY",
                  "value": "LAKE MARY"
              },
              {
                  "name": "OAKLAND PARK",
                  "label": "OAKLAND PARK",
                  "value": "OAKLAND PARK"
              },
              {
                  "name": "MIAMI SHORES",
                  "label": "MIAMI SHORES",
                  "value": "MIAMI SHORES"
              },
              {
                  "name": "MIRAMAR BEACH",
                  "label": "MIRAMAR BEACH",
                  "value": "MIRAMAR BEACH"
              },
              {
                  "name": "PB GARDENS",
                  "label": "PB GARDENS",
                  "value": "PB GARDENS"
              },
              {
                  "name": "LAKE WALES",
                  "label": "LAKE WALES",
                  "value": "LAKE WALES"
              },
              {
                  "name": "OCOEE",
                  "label": "OCOEE",
                  "value": "OCOEE"
              },
              {
                  "name": "ESTERO",
                  "label": "ESTERO",
                  "value": "ESTERO"
              },
              {
                  "name": "SEFFNER",
                  "label": "SEFFNER",
                  "value": "SEFFNER"
              },
              {
                  "name": "POMPANO BEACH",
                  "label": "POMPANO BEACH",
                  "value": "POMPANO BEACH"
              },
              {
                  "name": "WELLINGTON",
                  "label": "WELLINGTON",
                  "value": "WELLINGTON"
              },
              {
                  "name": "JACKSONVILLE BEACH",
                  "label": "JACKSONVILLE BEACH",
                  "value": "JACKSONVILLE BEACH"
              },
              {
                  "name": "HOLIDAY",
                  "label": "HOLIDAY",
                  "value": "HOLIDAY"
              },
              {
                  "name": "STARKE",
                  "label": "STARKE",
                  "value": "STARKE"
              },
              {
                  "name": "NORTH MIAMI",
                  "label": "NORTH MIAMI",
                  "value": "NORTH MIAMI"
              },
              {
                  "name": "FT MYERS",
                  "label": "FT MYERS",
                  "value": "FT MYERS"
              },
              {
                  "name": "MERRITT ISLAND",
                  "label": "MERRITT ISLAND",
                  "value": "MERRITT ISLAND"
              },
              {
                  "name": "PONTE VEDRA BEACH",
                  "label": "PONTE VEDRA BEACH",
                  "value": "PONTE VEDRA BEACH"
              },
              {
                  "name": "SUMMERFIELD",
                  "label": "SUMMERFIELD",
                  "value": "SUMMERFIELD"
              },
              {
                  "name": "PALATKA",
                  "label": "PALATKA",
                  "value": "PALATKA"
              },
              {
                  "name": "VIERA",
                  "label": "VIERA",
                  "value": "VIERA"
              }
          ]
      },
      {
          "name": "NY",
          "label": "New York",
          "value": "NY",
          "cities": [
              {
                  "name": "BROOKLYN",
                  "label": "BROOKLYN",
                  "value": "BROOKLYN"
              },
              {
                  "name": "NEW YORK",
                  "label": "NEW YORK",
                  "value": "NEW YORK"
              },
              {
                  "name": "LAKE GROVE",
                  "label": "LAKE GROVE",
                  "value": "LAKE GROVE"
              },
              {
                  "name": "GREAT NECK",
                  "label": "GREAT NECK",
                  "value": "GREAT NECK"
              },
              {
                  "name": "TROY",
                  "label": "TROY",
                  "value": "TROY"
              },
              {
                  "name": "COOPERSTOWN",
                  "label": "COOPERSTOWN",
                  "value": "COOPERSTOWN"
              },
              {
                  "name": "BRONX",
                  "label": "BRONX",
                  "value": "BRONX"
              },
              {
                  "name": "ROCHESTER",
                  "label": "ROCHESTER",
                  "value": "ROCHESTER"
              },
              {
                  "name": "FLUSHING",
                  "label": "FLUSHING",
                  "value": "FLUSHING"
              },
              {
                  "name": "SYRACUSE",
                  "label": "SYRACUSE",
                  "value": "SYRACUSE"
              },
              {
                  "name": "ITHACA",
                  "label": "ITHACA",
                  "value": "ITHACA"
              },
              {
                  "name": "VALLEY COTTAGE",
                  "label": "VALLEY COTTAGE",
                  "value": "VALLEY COTTAGE"
              },
              {
                  "name": "EAST SYRACUSE",
                  "label": "EAST SYRACUSE",
                  "value": "EAST SYRACUSE"
              },
              {
                  "name": "OCEANSIDE",
                  "label": "OCEANSIDE",
                  "value": "OCEANSIDE"
              },
              {
                  "name": "GREENVALE",
                  "label": "GREENVALE",
                  "value": "GREENVALE"
              },
              {
                  "name": "ALBANY",
                  "label": "ALBANY",
                  "value": "ALBANY"
              },
              {
                  "name": "BUFFALO",
                  "label": "BUFFALO",
                  "value": "BUFFALO"
              },
              {
                  "name": "KINGSTON",
                  "label": "KINGSTON",
                  "value": "KINGSTON"
              },
              {
                  "name": "HEMPSTEAD",
                  "label": "HEMPSTEAD",
                  "value": "HEMPSTEAD"
              },
              {
                  "name": "WEST ISLIP",
                  "label": "WEST ISLIP",
                  "value": "WEST ISLIP"
              },
              {
                  "name": "STATEN ISLAND",
                  "label": "STATEN ISLAND",
                  "value": "STATEN ISLAND"
              },
              {
                  "name": "POUGHKEEPSIE",
                  "label": "POUGHKEEPSIE",
                  "value": "POUGHKEEPSIE"
              },
              {
                  "name": "YONKERS",
                  "label": "YONKERS",
                  "value": "YONKERS"
              },
              {
                  "name": "NEW HYDE PARK",
                  "label": "NEW HYDE PARK",
                  "value": "NEW HYDE PARK"
              },
              {
                  "name": "JAMAICA",
                  "label": "JAMAICA",
                  "value": "JAMAICA"
              },
              {
                  "name": "WHITE PLAINS",
                  "label": "WHITE PLAINS",
                  "value": "WHITE PLAINS"
              },
              {
                  "name": "UNIONDALE",
                  "label": "UNIONDALE",
                  "value": "UNIONDALE"
              },
              {
                  "name": "STONY BROOK",
                  "label": "STONY BROOK",
                  "value": "STONY BROOK"
              },
              {
                  "name": "CANDOR",
                  "label": "CANDOR",
                  "value": "CANDOR"
              },
              {
                  "name": "FRESH MEADOWS",
                  "label": "FRESH MEADOWS",
                  "value": "FRESH MEADOWS"
              },
              {
                  "name": "PATCHOGUE",
                  "label": "PATCHOGUE",
                  "value": "PATCHOGUE"
              },
              {
                  "name": "BROCKPORT",
                  "label": "BROCKPORT",
                  "value": "BROCKPORT"
              },
              {
                  "name": "WESTBURY",
                  "label": "WESTBURY",
                  "value": "WESTBURY"
              },
              {
                  "name": "CLAVERACK",
                  "label": "CLAVERACK",
                  "value": "CLAVERACK"
              },
              {
                  "name": "SUFFERN",
                  "label": "SUFFERN",
                  "value": "SUFFERN"
              },
              {
                  "name": "WILLIAMSVILLE",
                  "label": "WILLIAMSVILLE",
                  "value": "WILLIAMSVILLE"
              },
              {
                  "name": "LAKE SUCCESS",
                  "label": "LAKE SUCCESS",
                  "value": "LAKE SUCCESS"
              },
              {
                  "name": "GLENS FALLS",
                  "label": "GLENS FALLS",
                  "value": "GLENS FALLS"
              },
              {
                  "name": "KINGS PARK",
                  "label": "KINGS PARK",
                  "value": "KINGS PARK"
              },
              {
                  "name": "CHEEKTOWAGA",
                  "label": "CHEEKTOWAGA",
                  "value": "CHEEKTOWAGA"
              },
              {
                  "name": "HOGANSBURG",
                  "label": "HOGANSBURG",
                  "value": "HOGANSBURG"
              },
              {
                  "name": "MANHASSET",
                  "label": "MANHASSET",
                  "value": "MANHASSET"
              },
              {
                  "name": "HAWTHORNE",
                  "label": "HAWTHORNE",
                  "value": "HAWTHORNE"
              },
              {
                  "name": "WOODBURY",
                  "label": "WOODBURY",
                  "value": "WOODBURY"
              },
              {
                  "name": "FARMINGDALE",
                  "label": "FARMINGDALE",
                  "value": "FARMINGDALE"
              },
              {
                  "name": "JAMESTOWN",
                  "label": "JAMESTOWN",
                  "value": "JAMESTOWN"
              },
              {
                  "name": "EASTCHESTER",
                  "label": "EASTCHESTER",
                  "value": "EASTCHESTER"
              },
              {
                  "name": "SLEEPY HOLLOW",
                  "label": "SLEEPY HOLLOW",
                  "value": "SLEEPY HOLLOW"
              },
              {
                  "name": "PORT JEFFERSON",
                  "label": "PORT JEFFERSON",
                  "value": "PORT JEFFERSON"
              },
              {
                  "name": "HORNELL",
                  "label": "HORNELL",
                  "value": "HORNELL"
              },
              {
                  "name": "LATHAM",
                  "label": "LATHAM",
                  "value": "LATHAM"
              },
              {
                  "name": "WEST HARRISON",
                  "label": "WEST HARRISON",
                  "value": "WEST HARRISON"
              },
              {
                  "name": "PORT JEFFERSON STATION",
                  "label": "PORT JEFFERSON STATION",
                  "value": "PORT JEFFERSON STATION"
              },
              {
                  "name": "NORTHPORT",
                  "label": "NORTHPORT",
                  "value": "NORTHPORT"
              },
              {
                  "name": "CLIFTON PARK",
                  "label": "CLIFTON PARK",
                  "value": "CLIFTON PARK"
              },
              {
                  "name": "ELMIRA",
                  "label": "ELMIRA",
                  "value": "ELMIRA"
              },
              {
                  "name": "HUNTINGTON",
                  "label": "HUNTINGTON",
                  "value": "HUNTINGTON"
              },
              {
                  "name": "SHIRLEY",
                  "label": "SHIRLEY",
                  "value": "SHIRLEY"
              },
              {
                  "name": "ROCKVILLE CENTRE",
                  "label": "ROCKVILLE CENTRE",
                  "value": "ROCKVILLE CENTRE"
              },
              {
                  "name": "MIDDLETOWN",
                  "label": "MIDDLETOWN",
                  "value": "MIDDLETOWN"
              },
              {
                  "name": "VALHALLA",
                  "label": "VALHALLA",
                  "value": "VALHALLA"
              },
              {
                  "name": "CORTLAND",
                  "label": "CORTLAND",
                  "value": "CORTLAND"
              },
              {
                  "name": "NYACK",
                  "label": "NYACK",
                  "value": "NYACK"
              },
              {
                  "name": "SARATOGA SPRINGS",
                  "label": "SARATOGA SPRINGS",
                  "value": "SARATOGA SPRINGS"
              },
              {
                  "name": "OGDENSBURG",
                  "label": "OGDENSBURG",
                  "value": "OGDENSBURG"
              },
              {
                  "name": "LANCASTER",
                  "label": "LANCASTER",
                  "value": "LANCASTER"
              },
              {
                  "name": "PLATTSBURGH",
                  "label": "PLATTSBURGH",
                  "value": "PLATTSBURGH"
              },
              {
                  "name": "WELLSVILLE",
                  "label": "WELLSVILLE",
                  "value": "WELLSVILLE"
              },
              {
                  "name": "FISHKILL",
                  "label": "FISHKILL",
                  "value": "FISHKILL"
              },
              {
                  "name": "RIVERHEAD",
                  "label": "RIVERHEAD",
                  "value": "RIVERHEAD"
              },
              {
                  "name": "COMMACK",
                  "label": "COMMACK",
                  "value": "COMMACK"
              },
              {
                  "name": "OSWEGO",
                  "label": "OSWEGO",
                  "value": "OSWEGO"
              },
              {
                  "name": "LIVERPOOL",
                  "label": "LIVERPOOL",
                  "value": "LIVERPOOL"
              },
              {
                  "name": "EAST PATCHOGUE",
                  "label": "EAST PATCHOGUE",
                  "value": "EAST PATCHOGUE"
              },
              {
                  "name": "MINEOLA",
                  "label": "MINEOLA",
                  "value": "MINEOLA"
              },
              {
                  "name": "AUBURN",
                  "label": "AUBURN",
                  "value": "AUBURN"
              },
              {
                  "name": "JOHNSON CITY",
                  "label": "JOHNSON CITY",
                  "value": "JOHNSON CITY"
              },
              {
                  "name": "MONSEY",
                  "label": "MONSEY",
                  "value": "MONSEY"
              },
              {
                  "name": "KENMORE",
                  "label": "KENMORE",
                  "value": "KENMORE"
              },
              {
                  "name": "BELLEROSE",
                  "label": "BELLEROSE",
                  "value": "BELLEROSE"
              },
              {
                  "name": "CLIFTON SPRINGS",
                  "label": "CLIFTON SPRINGS",
                  "value": "CLIFTON SPRINGS"
              },
              {
                  "name": "WATERTOWN",
                  "label": "WATERTOWN",
                  "value": "WATERTOWN"
              },
              {
                  "name": "POTSDAM",
                  "label": "POTSDAM",
                  "value": "POTSDAM"
              },
              {
                  "name": "BREWSTER",
                  "label": "BREWSTER",
                  "value": "BREWSTER"
              },
              {
                  "name": "LYNBROOK",
                  "label": "LYNBROOK",
                  "value": "LYNBROOK"
              },
              {
                  "name": "ONEIDA",
                  "label": "ONEIDA",
                  "value": "ONEIDA"
              },
              {
                  "name": "UTICA",
                  "label": "UTICA",
                  "value": "UTICA"
              },
              {
                  "name": "OLEAN",
                  "label": "OLEAN",
                  "value": "OLEAN"
              },
              {
                  "name": "GLEN OAKS",
                  "label": "GLEN OAKS",
                  "value": "GLEN OAKS"
              },
              {
                  "name": "SARANAC LAKE",
                  "label": "SARANAC LAKE",
                  "value": "SARANAC LAKE"
              },
              {
                  "name": "FULTON",
                  "label": "FULTON",
                  "value": "FULTON"
              },
              {
                  "name": "SCARSDALE",
                  "label": "SCARSDALE",
                  "value": "SCARSDALE"
              },
              {
                  "name": "MOUNT KISCO",
                  "label": "MOUNT KISCO",
                  "value": "MOUNT KISCO"
              },
              {
                  "name": "ELLICOTTVILLE",
                  "label": "ELLICOTTVILLE",
                  "value": "ELLICOTTVILLE"
              },
              {
                  "name": "BALDWIN",
                  "label": "BALDWIN",
                  "value": "BALDWIN"
              },
              {
                  "name": "ELMHURST",
                  "label": "ELMHURST",
                  "value": "ELMHURST"
              },
              {
                  "name": "NORTH MERRICK",
                  "label": "NORTH MERRICK",
                  "value": "NORTH MERRICK"
              },
              {
                  "name": "BAYSHORE",
                  "label": "BAYSHORE",
                  "value": "BAYSHORE"
              },
              {
                  "name": "AMSTERDAM",
                  "label": "AMSTERDAM",
                  "value": "AMSTERDAM"
              },
              {
                  "name": "BAYSIDE",
                  "label": "BAYSIDE",
                  "value": "BAYSIDE"
              },
              {
                  "name": "RONKONKOMA",
                  "label": "RONKONKOMA",
                  "value": "RONKONKOMA"
              },
              {
                  "name": "LAKE RONKONKOMA",
                  "label": "LAKE RONKONKOMA",
                  "value": "LAKE RONKONKOMA"
              },
              {
                  "name": "GREENPORT",
                  "label": "GREENPORT",
                  "value": "GREENPORT"
              },
              {
                  "name": "BRONXVILLE",
                  "label": "BRONXVILLE",
                  "value": "BRONXVILLE"
              },
              {
                  "name": "TARRYTOWN",
                  "label": "TARRYTOWN",
                  "value": "TARRYTOWN"
              },
              {
                  "name": "MASTIC",
                  "label": "MASTIC",
                  "value": "MASTIC"
              },
              {
                  "name": "ROCKY POINT",
                  "label": "ROCKY POINT",
                  "value": "ROCKY POINT"
              },
              {
                  "name": "YORKTOWN HEIGHTS",
                  "label": "YORKTOWN HEIGHTS",
                  "value": "YORKTOWN HEIGHTS"
              },
              {
                  "name": "NEWBURGH",
                  "label": "NEWBURGH",
                  "value": "NEWBURGH"
              },
              {
                  "name": "BAY SHORE",
                  "label": "BAY SHORE",
                  "value": "BAY SHORE"
              },
              {
                  "name": "WARWICK",
                  "label": "WARWICK",
                  "value": "WARWICK"
              },
              {
                  "name": "BETHPAGE",
                  "label": "BETHPAGE",
                  "value": "BETHPAGE"
              },
              {
                  "name": "WHITE",
                  "label": "WHITE",
                  "value": "WHITE"
              },
              {
                  "name": "REGO PARK",
                  "label": "REGO PARK",
                  "value": "REGO PARK"
              },
              {
                  "name": "NEW CITY",
                  "label": "NEW CITY",
                  "value": "NEW CITY"
              },
              {
                  "name": "ONEONTA",
                  "label": "ONEONTA",
                  "value": "ONEONTA"
              },
              {
                  "name": "FOREST HILLS",
                  "label": "FOREST HILLS",
                  "value": "FOREST HILLS"
              },
              {
                  "name": "PLAINVIEW",
                  "label": "PLAINVIEW",
                  "value": "PLAINVIEW"
              },
              {
                  "name": "NANUET",
                  "label": "NANUET",
                  "value": "NANUET"
              },
              {
                  "name": "ROSLYN",
                  "label": "ROSLYN",
                  "value": "ROSLYN"
              },
              {
                  "name": "NORTH BABYLON",
                  "label": "NORTH BABYLON",
                  "value": "NORTH BABYLON"
              },
              {
                  "name": "EAST HAMPTON",
                  "label": "EAST HAMPTON",
                  "value": "EAST HAMPTON"
              },
              {
                  "name": "ORCHARD PARK",
                  "label": "ORCHARD PARK",
                  "value": "ORCHARD PARK"
              },
              {
                  "name": "SYOSSET",
                  "label": "SYOSSET",
                  "value": "SYOSSET"
              },
              {
                  "name": "BRENTWOOD",
                  "label": "BRENTWOOD",
                  "value": "BRENTWOOD"
              },
              {
                  "name": "GOSHEN",
                  "label": "GOSHEN",
                  "value": "GOSHEN"
              },
              {
                  "name": "PINE BUSH",
                  "label": "PINE BUSH",
                  "value": "PINE BUSH"
              },
              {
                  "name": "EAST MEADOW",
                  "label": "EAST MEADOW",
                  "value": "EAST MEADOW"
              },
              {
                  "name": "NEW ROCHELLE",
                  "label": "NEW ROCHELLE",
                  "value": "NEW ROCHELLE"
              },
              {
                  "name": "WOODSIDE",
                  "label": "WOODSIDE",
                  "value": "WOODSIDE"
              },
              {
                  "name": "NEW HARTFORD",
                  "label": "NEW HARTFORD",
                  "value": "NEW HARTFORD"
              },
              {
                  "name": "HUDSON",
                  "label": "HUDSON",
                  "value": "HUDSON"
              },
              {
                  "name": "MERRICK",
                  "label": "MERRICK",
                  "value": "MERRICK"
              },
              {
                  "name": "SMITHTOWN",
                  "label": "SMITHTOWN",
                  "value": "SMITHTOWN"
              },
              {
                  "name": "HORSEHEADS",
                  "label": "HORSEHEADS",
                  "value": "HORSEHEADS"
              },
              {
                  "name": "WEST SENECA",
                  "label": "WEST SENECA",
                  "value": "WEST SENECA"
              },
              {
                  "name": "SCHENECTADY",
                  "label": "SCHENECTADY",
                  "value": "SCHENECTADY"
              },
              {
                  "name": "LINDENHURST",
                  "label": "LINDENHURST",
                  "value": "LINDENHURST"
              },
              {
                  "name": "NISKAYUNA",
                  "label": "NISKAYUNA",
                  "value": "NISKAYUNA"
              },
              {
                  "name": "GANSEVOORT",
                  "label": "GANSEVOORT",
                  "value": "GANSEVOORT"
              },
              {
                  "name": "CORNWALL",
                  "label": "CORNWALL",
                  "value": "CORNWALL"
              },
              {
                  "name": "FARMINGVILLE",
                  "label": "FARMINGVILLE",
                  "value": "FARMINGVILLE"
              },
              {
                  "name": "RICHMOND HILL",
                  "label": "RICHMOND HILL",
                  "value": "RICHMOND HILL"
              },
              {
                  "name": "SOUTHAMPTON",
                  "label": "SOUTHAMPTON",
                  "value": "SOUTHAMPTON"
              },
              {
                  "name": "GREENLAWN",
                  "label": "GREENLAWN",
                  "value": "GREENLAWN"
              },
              {
                  "name": "PORT JERVIS",
                  "label": "PORT JERVIS",
                  "value": "PORT JERVIS"
              },
              {
                  "name": "VOORHEESVILLE",
                  "label": "VOORHEESVILLE",
                  "value": "VOORHEESVILLE"
              },
              {
                  "name": "GLENDALE",
                  "label": "GLENDALE",
                  "value": "GLENDALE"
              },
              {
                  "name": "HUDSON FALLS",
                  "label": "HUDSON FALLS",
                  "value": "HUDSON FALLS"
              },
              {
                  "name": "ELMA",
                  "label": "ELMA",
                  "value": "ELMA"
              },
              {
                  "name": "PURCHASE",
                  "label": "PURCHASE",
                  "value": "PURCHASE"
              },
              {
                  "name": "ROME",
                  "label": "ROME",
                  "value": "ROME"
              },
              {
                  "name": "MORICHES",
                  "label": "MORICHES",
                  "value": "MORICHES"
              },
              {
                  "name": "CHITTENANGO",
                  "label": "CHITTENANGO",
                  "value": "CHITTENANGO"
              },
              {
                  "name": "NIAGARA FALLS",
                  "label": "NIAGARA FALLS",
                  "value": "NIAGARA FALLS"
              },
              {
                  "name": "CORNING",
                  "label": "CORNING",
                  "value": "CORNING"
              },
              {
                  "name": "SLINGERLANDS",
                  "label": "SLINGERLANDS",
                  "value": "SLINGERLANDS"
              },
              {
                  "name": "IRVINGTON",
                  "label": "IRVINGTON",
                  "value": "IRVINGTON"
              },
              {
                  "name": "CORTLANDT MANOR",
                  "label": "CORTLANDT MANOR",
                  "value": "CORTLANDT MANOR"
              },
              {
                  "name": "HOWARD BEACH",
                  "label": "HOWARD BEACH",
                  "value": "HOWARD BEACH"
              },
              {
                  "name": "PEEKSKILL",
                  "label": "PEEKSKILL",
                  "value": "PEEKSKILL"
              },
              {
                  "name": "MALTA",
                  "label": "MALTA",
                  "value": "MALTA"
              },
              {
                  "name": "MONROE",
                  "label": "MONROE",
                  "value": "MONROE"
              },
              {
                  "name": "WESTBROOKEVILLE",
                  "label": "WESTBROOKEVILLE",
                  "value": "WESTBROOKEVILLE"
              },
              {
                  "name": "POMONA",
                  "label": "POMONA",
                  "value": "POMONA"
              },
              {
                  "name": "MOUNT VERNON",
                  "label": "MOUNT VERNON",
                  "value": "MOUNT VERNON"
              },
              {
                  "name": "NORTH SYRACUSE",
                  "label": "NORTH SYRACUSE",
                  "value": "NORTH SYRACUSE"
              },
              {
                  "name": "SPRING VALLEY",
                  "label": "SPRING VALLEY",
                  "value": "SPRING VALLEY"
              },
              {
                  "name": "RINEBECK",
                  "label": "RINEBECK",
                  "value": "RINEBECK"
              },
              {
                  "name": "LONG ISLAND CITY",
                  "label": "LONG ISLAND CITY",
                  "value": "LONG ISLAND CITY"
              },
              {
                  "name": "MELVILLE",
                  "label": "MELVILLE",
                  "value": "MELVILLE"
              },
              {
                  "name": "CANANDAIGUA",
                  "label": "CANANDAIGUA",
                  "value": "CANANDAIGUA"
              },
              {
                  "name": "HAUPPAUGE",
                  "label": "HAUPPAUGE",
                  "value": "HAUPPAUGE"
              },
              {
                  "name": "NEW WINDSOR",
                  "label": "NEW WINDSOR",
                  "value": "NEW WINDSOR"
              },
              {
                  "name": "MIDDLE ISLAND",
                  "label": "MIDDLE ISLAND",
                  "value": "MIDDLE ISLAND"
              },
              {
                  "name": "WESTHAMPTON BEACH",
                  "label": "WESTHAMPTON BEACH",
                  "value": "WESTHAMPTON BEACH"
              },
              {
                  "name": "MALONE",
                  "label": "MALONE",
                  "value": "MALONE"
              },
              {
                  "name": "BRIGHTWATERS",
                  "label": "BRIGHTWATERS",
                  "value": "BRIGHTWATERS"
              },
              {
                  "name": "WAPPINGERS FALLS",
                  "label": "WAPPINGERS FALLS",
                  "value": "WAPPINGERS FALLS"
              },
              {
                  "name": "CEDARHURST",
                  "label": "CEDARHURST",
                  "value": "CEDARHURST"
              },
              {
                  "name": "ROSLYN HEIGHTS",
                  "label": "ROSLYN HEIGHTS",
                  "value": "ROSLYN HEIGHTS"
              },
              {
                  "name": "CORAM",
                  "label": "CORAM",
                  "value": "CORAM"
              },
              {
                  "name": "CENTRAL SQUARE",
                  "label": "CENTRAL SQUARE",
                  "value": "CENTRAL SQUARE"
              },
              {
                  "name": "HOLBROOK",
                  "label": "HOLBROOK",
                  "value": "HOLBROOK"
              },
              {
                  "name": "QUEENS VILLAGE",
                  "label": "QUEENS VILLAGE",
                  "value": "QUEENS VILLAGE"
              },
              {
                  "name": "VALLEY STREAM",
                  "label": "VALLEY STREAM",
                  "value": "VALLEY STREAM"
              },
              {
                  "name": "FREEVILLE",
                  "label": "FREEVILLE",
                  "value": "FREEVILLE"
              },
              {
                  "name": "CENTRAL ISLIP",
                  "label": "CENTRAL ISLIP",
                  "value": "CENTRAL ISLIP"
              },
              {
                  "name": "ALBERTSON",
                  "label": "ALBERTSON",
                  "value": "ALBERTSON"
              },
              {
                  "name": "BLUE POINT",
                  "label": "BLUE POINT",
                  "value": "BLUE POINT"
              }
          ]
      },
      {
          "name": "TX",
          "label": "Texas",
          "value": "TX",
          "cities": [
              {
                  "name": "BROWNSVILLE",
                  "label": "BROWNSVILLE",
                  "value": "BROWNSVILLE"
              },
              {
                  "name": "SAN ANTONIO",
                  "label": "SAN ANTONIO",
                  "value": "SAN ANTONIO"
              },
              {
                  "name": "CYPRESS",
                  "label": "CYPRESS",
                  "value": "CYPRESS"
              },
              {
                  "name": "HOUSTON",
                  "label": "HOUSTON",
                  "value": "HOUSTON"
              },
              {
                  "name": "ALLEN",
                  "label": "ALLEN",
                  "value": "ALLEN"
              },
              {
                  "name": "DALLAS",
                  "label": "DALLAS",
                  "value": "DALLAS"
              },
              {
                  "name": "EL PASO",
                  "label": "EL PASO",
                  "value": "EL PASO"
              },
              {
                  "name": "IOWA PARK",
                  "label": "IOWA PARK",
                  "value": "IOWA PARK"
              },
              {
                  "name": "AMARILLO",
                  "label": "AMARILLO",
                  "value": "AMARILLO"
              },
              {
                  "name": "MCALLEN",
                  "label": "MCALLEN",
                  "value": "MCALLEN"
              },
              {
                  "name": "PARIS",
                  "label": "PARIS",
                  "value": "PARIS"
              },
              {
                  "name": "SHENANDOAH",
                  "label": "SHENANDOAH",
                  "value": "SHENANDOAH"
              },
              {
                  "name": "LAKE JACKSON",
                  "label": "LAKE JACKSON",
                  "value": "LAKE JACKSON"
              },
              {
                  "name": "TOMBALL",
                  "label": "TOMBALL",
                  "value": "TOMBALL"
              },
              {
                  "name": "EDINBURG",
                  "label": "EDINBURG",
                  "value": "EDINBURG"
              },
              {
                  "name": "THE WOODLANDS",
                  "label": "THE WOODLANDS",
                  "value": "THE WOODLANDS"
              },
              {
                  "name": "LUBBOCK",
                  "label": "LUBBOCK",
                  "value": "LUBBOCK"
              },
              {
                  "name": "FLOWER MOUND",
                  "label": "FLOWER MOUND",
                  "value": "FLOWER MOUND"
              },
              {
                  "name": "FORT WORTH",
                  "label": "FORT WORTH",
                  "value": "FORT WORTH"
              },
              {
                  "name": "BELLAIRE",
                  "label": "BELLAIRE",
                  "value": "BELLAIRE"
              },
              {
                  "name": "ROWLETT",
                  "label": "ROWLETT",
                  "value": "ROWLETT"
              },
              {
                  "name": "PASADENA",
                  "label": "PASADENA",
                  "value": "PASADENA"
              },
              {
                  "name": "AUSTIN",
                  "label": "AUSTIN",
                  "value": "AUSTIN"
              },
              {
                  "name": "FT SAM HOUSTON",
                  "label": "FT SAM HOUSTON",
                  "value": "FT SAM HOUSTON"
              },
              {
                  "name": "BEDFORD",
                  "label": "BEDFORD",
                  "value": "BEDFORD"
              },
              {
                  "name": "SUGAR LAND",
                  "label": "SUGAR LAND",
                  "value": "SUGAR LAND"
              },
              {
                  "name": "ABILENE",
                  "label": "ABILENE",
                  "value": "ABILENE"
              },
              {
                  "name": "HARLINGEN",
                  "label": "HARLINGEN",
                  "value": "HARLINGEN"
              },
              {
                  "name": "TEXARKANA",
                  "label": "TEXARKANA",
                  "value": "TEXARKANA"
              },
              {
                  "name": "WICHITA FALLS",
                  "label": "WICHITA FALLS",
                  "value": "WICHITA FALLS"
              },
              {
                  "name": "TYLER",
                  "label": "TYLER",
                  "value": "TYLER"
              },
              {
                  "name": "DENTON",
                  "label": "DENTON",
                  "value": "DENTON"
              },
              {
                  "name": "DENISON",
                  "label": "DENISON",
                  "value": "DENISON"
              },
              {
                  "name": "WEATHERFORD",
                  "label": "WEATHERFORD",
                  "value": "WEATHERFORD"
              },
              {
                  "name": "WACO",
                  "label": "WACO",
                  "value": "WACO"
              },
              {
                  "name": "PLANO",
                  "label": "PLANO",
                  "value": "PLANO"
              },
              {
                  "name": "IRVING",
                  "label": "IRVING",
                  "value": "IRVING"
              },
              {
                  "name": "NEW BRAUNFELS",
                  "label": "NEW BRAUNFELS",
                  "value": "NEW BRAUNFELS"
              },
              {
                  "name": "ODESSA",
                  "label": "ODESSA",
                  "value": "ODESSA"
              },
              {
                  "name": "COLLEGE STATION",
                  "label": "COLLEGE STATION",
                  "value": "COLLEGE STATION"
              },
              {
                  "name": "PORT ARTHUR",
                  "label": "PORT ARTHUR",
                  "value": "PORT ARTHUR"
              },
              {
                  "name": "ARLINGTON",
                  "label": "ARLINGTON",
                  "value": "ARLINGTON"
              },
              {
                  "name": "FRISCO",
                  "label": "FRISCO",
                  "value": "FRISCO"
              },
              {
                  "name": "CORPUS CHRISTI",
                  "label": "CORPUS CHRISTI",
                  "value": "CORPUS CHRISTI"
              },
              {
                  "name": "MIDLOTHIAN",
                  "label": "MIDLOTHIAN",
                  "value": "MIDLOTHIAN"
              },
              {
                  "name": "BEAUMONT",
                  "label": "BEAUMONT",
                  "value": "BEAUMONT"
              },
              {
                  "name": "BURLESON",
                  "label": "BURLESON",
                  "value": "BURLESON"
              },
              {
                  "name": "RICHARDSON",
                  "label": "RICHARDSON",
                  "value": "RICHARDSON"
              },
              {
                  "name": "LAREDO",
                  "label": "LAREDO",
                  "value": "LAREDO"
              },
              {
                  "name": "ROUND ROCK",
                  "label": "ROUND ROCK",
                  "value": "ROUND ROCK"
              },
              {
                  "name": "KINGWOOD",
                  "label": "KINGWOOD",
                  "value": "KINGWOOD"
              },
              {
                  "name": "ADDISON",
                  "label": "ADDISON",
                  "value": "ADDISON"
              },
              {
                  "name": "BROWNWOOD",
                  "label": "BROWNWOOD",
                  "value": "BROWNWOOD"
              },
              {
                  "name": "SOUTHLAKE",
                  "label": "SOUTHLAKE",
                  "value": "SOUTHLAKE"
              },
              {
                  "name": "TEMPLE",
                  "label": "TEMPLE",
                  "value": "TEMPLE"
              },
              {
                  "name": "KELLER",
                  "label": "KELLER",
                  "value": "KELLER"
              },
              {
                  "name": "VICTORIA",
                  "label": "VICTORIA",
                  "value": "VICTORIA"
              },
              {
                  "name": "LONGVIEW",
                  "label": "LONGVIEW",
                  "value": "LONGVIEW"
              },
              {
                  "name": "MOUNT PLEASANT",
                  "label": "MOUNT PLEASANT",
                  "value": "MOUNT PLEASANT"
              },
              {
                  "name": "HUMBLE",
                  "label": "HUMBLE",
                  "value": "HUMBLE"
              },
              {
                  "name": "GEORGETOWN",
                  "label": "GEORGETOWN",
                  "value": "GEORGETOWN"
              },
              {
                  "name": "LUFKIN",
                  "label": "LUFKIN",
                  "value": "LUFKIN"
              },
              {
                  "name": "MANSFIELD",
                  "label": "MANSFIELD",
                  "value": "MANSFIELD"
              },
              {
                  "name": "PEARLAND",
                  "label": "PEARLAND",
                  "value": "PEARLAND"
              },
              {
                  "name": "LIVE OAK",
                  "label": "LIVE OAK",
                  "value": "LIVE OAK"
              },
              {
                  "name": "MCKINNEY",
                  "label": "MCKINNEY",
                  "value": "MCKINNEY"
              },
              {
                  "name": "GRAPEVINE",
                  "label": "GRAPEVINE",
                  "value": "GRAPEVINE"
              },
              {
                  "name": "WEBSTER",
                  "label": "WEBSTER",
                  "value": "WEBSTER"
              },
              {
                  "name": "MC KINNEY",
                  "label": "MC KINNEY",
                  "value": "MC KINNEY"
              },
              {
                  "name": "KILLEEN",
                  "label": "KILLEEN",
                  "value": "KILLEEN"
              },
              {
                  "name": "KATY",
                  "label": "KATY",
                  "value": "KATY"
              },
              {
                  "name": "ALICE",
                  "label": "ALICE",
                  "value": "ALICE"
              },
              {
                  "name": "MESQUITE",
                  "label": "MESQUITE",
                  "value": "MESQUITE"
              },
              {
                  "name": "FREDERICKSBURG",
                  "label": "FREDERICKSBURG",
                  "value": "FREDERICKSBURG"
              },
              {
                  "name": "BRYAN",
                  "label": "BRYAN",
                  "value": "BRYAN"
              },
              {
                  "name": "WESLACO",
                  "label": "WESLACO",
                  "value": "WESLACO"
              },
              {
                  "name": "LAKEWAY",
                  "label": "LAKEWAY",
                  "value": "LAKEWAY"
              },
              {
                  "name": "NEW CANEY",
                  "label": "NEW CANEY",
                  "value": "NEW CANEY"
              },
              {
                  "name": "DEL RIO",
                  "label": "DEL RIO",
                  "value": "DEL RIO"
              },
              {
                  "name": "KYLE",
                  "label": "KYLE",
                  "value": "KYLE"
              },
              {
                  "name": "GALVESTON",
                  "label": "GALVESTON",
                  "value": "GALVESTON"
              },
              {
                  "name": "ALEDO",
                  "label": "ALEDO",
                  "value": "ALEDO"
              },
              {
                  "name": "CONROE",
                  "label": "CONROE",
                  "value": "CONROE"
              },
              {
                  "name": "MIDLAND",
                  "label": "MIDLAND",
                  "value": "MIDLAND"
              },
              {
                  "name": "HURST",
                  "label": "HURST",
                  "value": "HURST"
              },
              {
                  "name": "WAXAHACHIE",
                  "label": "WAXAHACHIE",
                  "value": "WAXAHACHIE"
              },
              {
                  "name": "NORTH RICHLAND HILL",
                  "label": "NORTH RICHLAND HILL",
                  "value": "NORTH RICHLAND HILL"
              },
              {
                  "name": "BASTROP",
                  "label": "BASTROP",
                  "value": "BASTROP"
              },
              {
                  "name": "HUNTSVILLE",
                  "label": "HUNTSVILLE",
                  "value": "HUNTSVILLE"
              },
              {
                  "name": "DECATUR",
                  "label": "DECATUR",
                  "value": "DECATUR"
              },
              {
                  "name": "TEXAS CITY",
                  "label": "TEXAS CITY",
                  "value": "TEXAS CITY"
              },
              {
                  "name": "SAN ANGELO",
                  "label": "SAN ANGELO",
                  "value": "SAN ANGELO"
              },
              {
                  "name": "CEDAR PARK",
                  "label": "CEDAR PARK",
                  "value": "CEDAR PARK"
              },
              {
                  "name": "ATHENS",
                  "label": "ATHENS",
                  "value": "ATHENS"
              },
              {
                  "name": "CARROLLTON",
                  "label": "CARROLLTON",
                  "value": "CARROLLTON"
              },
              {
                  "name": "GARLAND",
                  "label": "GARLAND",
                  "value": "GARLAND"
              },
              {
                  "name": "KERRVILLE",
                  "label": "KERRVILLE",
                  "value": "KERRVILLE"
              },
              {
                  "name": "MISSOURI CITY",
                  "label": "MISSOURI CITY",
                  "value": "MISSOURI CITY"
              },
              {
                  "name": "EAGLE PASS",
                  "label": "EAGLE PASS",
                  "value": "EAGLE PASS"
              },
              {
                  "name": "CLEBURNE",
                  "label": "CLEBURNE",
                  "value": "CLEBURNE"
              },
              {
                  "name": "GRANBURY",
                  "label": "GRANBURY",
                  "value": "GRANBURY"
              },
              {
                  "name": "FORT SAM HOUSTON",
                  "label": "FORT SAM HOUSTON",
                  "value": "FORT SAM HOUSTON"
              },
              {
                  "name": "GRANDVIEW",
                  "label": "GRANDVIEW",
                  "value": "GRANDVIEW"
              },
              {
                  "name": "HORIZON CITY",
                  "label": "HORIZON CITY",
                  "value": "HORIZON CITY"
              },
              {
                  "name": "BAYTOWN",
                  "label": "BAYTOWN",
                  "value": "BAYTOWN"
              },
              {
                  "name": "UVALDE",
                  "label": "UVALDE",
                  "value": "UVALDE"
              },
              {
                  "name": "SPRING",
                  "label": "SPRING",
                  "value": "SPRING"
              },
              {
                  "name": "SAN MARCOS",
                  "label": "SAN MARCOS",
                  "value": "SAN MARCOS"
              },
              {
                  "name": "PALESTINE",
                  "label": "PALESTINE",
                  "value": "PALESTINE"
              },
              {
                  "name": "DUNCANVILLE",
                  "label": "DUNCANVILLE",
                  "value": "DUNCANVILLE"
              },
              {
                  "name": "LEWISVILLE",
                  "label": "LEWISVILLE",
                  "value": "LEWISVILLE"
              },
              {
                  "name": "ROCKWALL",
                  "label": "ROCKWALL",
                  "value": "ROCKWALL"
              }
          ]
      },
      {
          "name": "OH",
          "label": "Ohio",
          "value": "OH",
          "cities": [
              {
                  "name": "COLUMBUS",
                  "label": "COLUMBUS",
                  "value": "COLUMBUS"
              },
              {
                  "name": "CINCINNATI",
                  "label": "CINCINNATI",
                  "value": "CINCINNATI"
              },
              {
                  "name": "CLEVELAND",
                  "label": "CLEVELAND",
                  "value": "CLEVELAND"
              },
              {
                  "name": "LIMA",
                  "label": "LIMA",
                  "value": "LIMA"
              },
              {
                  "name": "HARPSTER",
                  "label": "HARPSTER",
                  "value": "HARPSTER"
              },
              {
                  "name": "DAYTON",
                  "label": "DAYTON",
                  "value": "DAYTON"
              },
              {
                  "name": "SYLVANIA",
                  "label": "SYLVANIA",
                  "value": "SYLVANIA"
              },
              {
                  "name": "MARION",
                  "label": "MARION",
                  "value": "MARION"
              },
              {
                  "name": "TOLEDO",
                  "label": "TOLEDO",
                  "value": "TOLEDO"
              },
              {
                  "name": "AKRON",
                  "label": "AKRON",
                  "value": "AKRON"
              },
              {
                  "name": "ASHTABULA",
                  "label": "ASHTABULA",
                  "value": "ASHTABULA"
              },
              {
                  "name": "KETTERING",
                  "label": "KETTERING",
                  "value": "KETTERING"
              },
              {
                  "name": "NORTH CANTON",
                  "label": "NORTH CANTON",
                  "value": "NORTH CANTON"
              },
              {
                  "name": "ENGLEWOOD",
                  "label": "ENGLEWOOD",
                  "value": "ENGLEWOOD"
              },
              {
                  "name": "WRIGHT PATTERSON AFB",
                  "label": "WRIGHT PATTERSON AFB",
                  "value": "WRIGHT PATTERSON AFB"
              },
              {
                  "name": "LORAIN",
                  "label": "LORAIN",
                  "value": "LORAIN"
              },
              {
                  "name": "CHILLICOTHE",
                  "label": "CHILLICOTHE",
                  "value": "CHILLICOTHE"
              },
              {
                  "name": "HILLIARD",
                  "label": "HILLIARD",
                  "value": "HILLIARD"
              },
              {
                  "name": "AVON",
                  "label": "AVON",
                  "value": "AVON"
              },
              {
                  "name": "Mayfield Heights",
                  "label": "Mayfield Heights",
                  "value": "Mayfield Heights"
              },
              {
                  "name": "ALLIANCE",
                  "label": "ALLIANCE",
                  "value": "ALLIANCE"
              },
              {
                  "name": "PARMA",
                  "label": "PARMA",
                  "value": "PARMA"
              },
              {
                  "name": "FAIRFIELD",
                  "label": "FAIRFIELD",
                  "value": "FAIRFIELD"
              },
              {
                  "name": "SANDUSKY",
                  "label": "SANDUSKY",
                  "value": "SANDUSKY"
              },
              {
                  "name": "WESTERVILLE",
                  "label": "WESTERVILLE",
                  "value": "WESTERVILLE"
              },
              {
                  "name": "GALLIPOLIS",
                  "label": "GALLIPOLIS",
                  "value": "GALLIPOLIS"
              },
              {
                  "name": "WOOSTER",
                  "label": "WOOSTER",
                  "value": "WOOSTER"
              },
              {
                  "name": "CANTON",
                  "label": "CANTON",
                  "value": "CANTON"
              },
              {
                  "name": "FINDLAY",
                  "label": "FINDLAY",
                  "value": "FINDLAY"
              },
              {
                  "name": "MENTOR",
                  "label": "MENTOR",
                  "value": "MENTOR"
              },
              {
                  "name": "WESTLAKE",
                  "label": "WESTLAKE",
                  "value": "WESTLAKE"
              },
              {
                  "name": "YOUNGSTOWN",
                  "label": "YOUNGSTOWN",
                  "value": "YOUNGSTOWN"
              },
              {
                  "name": "ELYRIA",
                  "label": "ELYRIA",
                  "value": "ELYRIA"
              },
              {
                  "name": "MANSFIELD",
                  "label": "MANSFIELD",
                  "value": "MANSFIELD"
              },
              {
                  "name": "CHARDON",
                  "label": "CHARDON",
                  "value": "CHARDON"
              },
              {
                  "name": "HAMILTON",
                  "label": "HAMILTON",
                  "value": "HAMILTON"
              },
              {
                  "name": "NORWOOD",
                  "label": "NORWOOD",
                  "value": "NORWOOD"
              },
              {
                  "name": "LEBANON",
                  "label": "LEBANON",
                  "value": "LEBANON"
              },
              {
                  "name": "PLAIN CITY",
                  "label": "PLAIN CITY",
                  "value": "PLAIN CITY"
              },
              {
                  "name": "BATAVIA",
                  "label": "BATAVIA",
                  "value": "BATAVIA"
              },
              {
                  "name": "WILMINGTON",
                  "label": "WILMINGTON",
                  "value": "WILMINGTON"
              },
              {
                  "name": "WEST CHESTER",
                  "label": "WEST CHESTER",
                  "value": "WEST CHESTER"
              },
              {
                  "name": "WAUSEON",
                  "label": "WAUSEON",
                  "value": "WAUSEON"
              },
              {
                  "name": "CLEVELAND HEIGHTS",
                  "label": "CLEVELAND HEIGHTS",
                  "value": "CLEVELAND HEIGHTS"
              },
              {
                  "name": "BRYAN",
                  "label": "BRYAN",
                  "value": "BRYAN"
              },
              {
                  "name": "CAMBRIDGE",
                  "label": "CAMBRIDGE",
                  "value": "CAMBRIDGE"
              },
              {
                  "name": "ASHLAND",
                  "label": "ASHLAND",
                  "value": "ASHLAND"
              },
              {
                  "name": "BROOKLYN",
                  "label": "BROOKLYN",
                  "value": "BROOKLYN"
              },
              {
                  "name": "BEAVERCREEK",
                  "label": "BEAVERCREEK",
                  "value": "BEAVERCREEK"
              },
              {
                  "name": "UPPER SANDUSKY",
                  "label": "UPPER SANDUSKY",
                  "value": "UPPER SANDUSKY"
              },
              {
                  "name": "STEUBENVILLE",
                  "label": "STEUBENVILLE",
                  "value": "STEUBENVILLE"
              },
              {
                  "name": "MARIETTA",
                  "label": "MARIETTA",
                  "value": "MARIETTA"
              },
              {
                  "name": "MIDDLEBURG HTS",
                  "label": "MIDDLEBURG HTS",
                  "value": "MIDDLEBURG HTS"
              },
              {
                  "name": "OAKWOOD",
                  "label": "OAKWOOD",
                  "value": "OAKWOOD"
              },
              {
                  "name": "COPLEY",
                  "label": "COPLEY",
                  "value": "COPLEY"
              },
              {
                  "name": "WADSWORTH",
                  "label": "WADSWORTH",
                  "value": "WADSWORTH"
              },
              {
                  "name": "NORTH RIDGEVILLE",
                  "label": "NORTH RIDGEVILLE",
                  "value": "NORTH RIDGEVILLE"
              },
              {
                  "name": "OXFORD",
                  "label": "OXFORD",
                  "value": "OXFORD"
              },
              {
                  "name": "WARREN",
                  "label": "WARREN",
                  "value": "WARREN"
              },
              {
                  "name": "EUCLID",
                  "label": "EUCLID",
                  "value": "EUCLID"
              },
              {
                  "name": "NEWARK",
                  "label": "NEWARK",
                  "value": "NEWARK"
              },
              {
                  "name": "PORTSMOUTH",
                  "label": "PORTSMOUTH",
                  "value": "PORTSMOUTH"
              },
              {
                  "name": "ZANESVILLE",
                  "label": "ZANESVILLE",
                  "value": "ZANESVILLE"
              },
              {
                  "name": "RUSHVILLE",
                  "label": "RUSHVILLE",
                  "value": "RUSHVILLE"
              },
              {
                  "name": "COSHOCTON",
                  "label": "COSHOCTON",
                  "value": "COSHOCTON"
              },
              {
                  "name": "MOUNT VERNON",
                  "label": "MOUNT VERNON",
                  "value": "MOUNT VERNON"
              },
              {
                  "name": "FRANKLIN",
                  "label": "FRANKLIN",
                  "value": "FRANKLIN"
              },
              {
                  "name": "NEW BOSTON",
                  "label": "NEW BOSTON",
                  "value": "NEW BOSTON"
              },
              {
                  "name": "DUBLIN",
                  "label": "DUBLIN",
                  "value": "DUBLIN"
              },
              {
                  "name": "HOLLAND",
                  "label": "HOLLAND",
                  "value": "HOLLAND"
              },
              {
                  "name": "SALEM",
                  "label": "SALEM",
                  "value": "SALEM"
              },
              {
                  "name": "BARBERTON",
                  "label": "BARBERTON",
                  "value": "BARBERTON"
              },
              {
                  "name": "NORWALK",
                  "label": "NORWALK",
                  "value": "NORWALK"
              },
              {
                  "name": "COLDWATER",
                  "label": "COLDWATER",
                  "value": "COLDWATER"
              },
              {
                  "name": "SHAKER HEIGHTS",
                  "label": "SHAKER HEIGHTS",
                  "value": "SHAKER HEIGHTS"
              },
              {
                  "name": "WAPAKONETA",
                  "label": "WAPAKONETA",
                  "value": "WAPAKONETA"
              },
              {
                  "name": "LANCASTER",
                  "label": "LANCASTER",
                  "value": "LANCASTER"
              },
              {
                  "name": "FREMONT",
                  "label": "FREMONT",
                  "value": "FREMONT"
              },
              {
                  "name": "MASSILLON",
                  "label": "MASSILLON",
                  "value": "MASSILLON"
              },
              {
                  "name": "SPRINGFIELD",
                  "label": "SPRINGFIELD",
                  "value": "SPRINGFIELD"
              },
              {
                  "name": "NORTH OLMSTED",
                  "label": "NORTH OLMSTED",
                  "value": "NORTH OLMSTED"
              },
              {
                  "name": "RAVENNA",
                  "label": "RAVENNA",
                  "value": "RAVENNA"
              },
              {
                  "name": "LEESBURG",
                  "label": "LEESBURG",
                  "value": "LEESBURG"
              },
              {
                  "name": "SEAMAN",
                  "label": "SEAMAN",
                  "value": "SEAMAN"
              },
              {
                  "name": "MEDINA",
                  "label": "MEDINA",
                  "value": "MEDINA"
              },
              {
                  "name": "GEORGETOWN",
                  "label": "GEORGETOWN",
                  "value": "GEORGETOWN"
              },
              {
                  "name": "CANFIELD",
                  "label": "CANFIELD",
                  "value": "CANFIELD"
              },
              {
                  "name": "AVON LAKE",
                  "label": "AVON LAKE",
                  "value": "AVON LAKE"
              },
              {
                  "name": "YELLOW SPRINGS",
                  "label": "YELLOW SPRINGS",
                  "value": "YELLOW SPRINGS"
              },
              {
                  "name": "DELAWARE",
                  "label": "DELAWARE",
                  "value": "DELAWARE"
              },
              {
                  "name": "GALION",
                  "label": "GALION",
                  "value": "GALION"
              },
              {
                  "name": "JACKSON",
                  "label": "JACKSON",
                  "value": "JACKSON"
              },
              {
                  "name": "LONDON",
                  "label": "LONDON",
                  "value": "LONDON"
              },
              {
                  "name": "KENTON",
                  "label": "KENTON",
                  "value": "KENTON"
              },
              {
                  "name": "CLEVELEAND",
                  "label": "CLEVELEAND",
                  "value": "CLEVELEAND"
              },
              {
                  "name": "CIRCLEVILLE",
                  "label": "CIRCLEVILLE",
                  "value": "CIRCLEVILLE"
              },
              {
                  "name": "HILLSBORO",
                  "label": "HILLSBORO",
                  "value": "HILLSBORO"
              },
              {
                  "name": "GENEVA",
                  "label": "GENEVA",
                  "value": "GENEVA"
              },
              {
                  "name": "IRONTON",
                  "label": "IRONTON",
                  "value": "IRONTON"
              },
              {
                  "name": "PICKERINGTON",
                  "label": "PICKERINGTON",
                  "value": "PICKERINGTON"
              },
              {
                  "name": "MIDDLETOWN",
                  "label": "MIDDLETOWN",
                  "value": "MIDDLETOWN"
              },
              {
                  "name": "TROY",
                  "label": "TROY",
                  "value": "TROY"
              },
              {
                  "name": "WOODVILLE",
                  "label": "WOODVILLE",
                  "value": "WOODVILLE"
              },
              {
                  "name": "SIDNEY",
                  "label": "SIDNEY",
                  "value": "SIDNEY"
              },
              {
                  "name": "MARYSVILLE",
                  "label": "MARYSVILLE",
                  "value": "MARYSVILLE"
              },
              {
                  "name": "HIGHLAND HILLS",
                  "label": "HIGHLAND HILLS",
                  "value": "HIGHLAND HILLS"
              },
              {
                  "name": "FREDERICKTOWN",
                  "label": "FREDERICKTOWN",
                  "value": "FREDERICKTOWN"
              },
              {
                  "name": "GAHANNA",
                  "label": "GAHANNA",
                  "value": "GAHANNA"
              },
              {
                  "name": "MIAMISBURG",
                  "label": "MIAMISBURG",
                  "value": "MIAMISBURG"
              }
          ]
      },
      {
          "name": "PA",
          "label": "Pennsylvania",
          "value": "PA",
          "cities": [
              {
                  "name": "PHILADELPHIA",
                  "label": "PHILADELPHIA",
                  "value": "PHILADELPHIA"
              },
              {
                  "name": "CLAIRTON",
                  "label": "CLAIRTON",
                  "value": "CLAIRTON"
              },
              {
                  "name": "HERSHEY",
                  "label": "HERSHEY",
                  "value": "HERSHEY"
              },
              {
                  "name": "KITTANNING",
                  "label": "KITTANNING",
                  "value": "KITTANNING"
              },
              {
                  "name": "PITTSBURGH",
                  "label": "PITTSBURGH",
                  "value": "PITTSBURGH"
              },
              {
                  "name": "CAMP HILL",
                  "label": "CAMP HILL",
                  "value": "CAMP HILL"
              },
              {
                  "name": "WALLINGFORD",
                  "label": "WALLINGFORD",
                  "value": "WALLINGFORD"
              },
              {
                  "name": "SAYRE",
                  "label": "SAYRE",
                  "value": "SAYRE"
              },
              {
                  "name": "MECHANICSBURG",
                  "label": "MECHANICSBURG",
                  "value": "MECHANICSBURG"
              },
              {
                  "name": "WEST READING",
                  "label": "WEST READING",
                  "value": "WEST READING"
              },
              {
                  "name": "CLARKS SUMMIT",
                  "label": "CLARKS SUMMIT",
                  "value": "CLARKS SUMMIT"
              },
              {
                  "name": "HUNTINGDON",
                  "label": "HUNTINGDON",
                  "value": "HUNTINGDON"
              },
              {
                  "name": "BUTLER",
                  "label": "BUTLER",
                  "value": "BUTLER"
              },
              {
                  "name": "PLYMOUTH MEETING",
                  "label": "PLYMOUTH MEETING",
                  "value": "PLYMOUTH MEETING"
              },
              {
                  "name": "WILLOW GROVE",
                  "label": "WILLOW GROVE",
                  "value": "WILLOW GROVE"
              },
              {
                  "name": "LANCASTER",
                  "label": "LANCASTER",
                  "value": "LANCASTER"
              },
              {
                  "name": "LANGHORNE",
                  "label": "LANGHORNE",
                  "value": "LANGHORNE"
              },
              {
                  "name": "ALLENTOWN",
                  "label": "ALLENTOWN",
                  "value": "ALLENTOWN"
              },
              {
                  "name": "DANVILLE",
                  "label": "DANVILLE",
                  "value": "DANVILLE"
              },
              {
                  "name": "ERIE",
                  "label": "ERIE",
                  "value": "ERIE"
              },
              {
                  "name": "NORRISTOWN",
                  "label": "NORRISTOWN",
                  "value": "NORRISTOWN"
              },
              {
                  "name": "HORSHAM",
                  "label": "HORSHAM",
                  "value": "HORSHAM"
              },
              {
                  "name": "YORK",
                  "label": "YORK",
                  "value": "YORK"
              },
              {
                  "name": "CANONSBURG",
                  "label": "CANONSBURG",
                  "value": "CANONSBURG"
              },
              {
                  "name": "GETTYSBURG",
                  "label": "GETTYSBURG",
                  "value": "GETTYSBURG"
              },
              {
                  "name": "MEADVILLE",
                  "label": "MEADVILLE",
                  "value": "MEADVILLE"
              },
              {
                  "name": "MONONGAHELA",
                  "label": "MONONGAHELA",
                  "value": "MONONGAHELA"
              },
              {
                  "name": "SCRANTON",
                  "label": "SCRANTON",
                  "value": "SCRANTON"
              },
              {
                  "name": "BETHLEHEM",
                  "label": "BETHLEHEM",
                  "value": "BETHLEHEM"
              },
              {
                  "name": "PHOENIXVILLE",
                  "label": "PHOENIXVILLE",
                  "value": "PHOENIXVILLE"
              },
              {
                  "name": "MCKEES ROCKS",
                  "label": "MCKEES ROCKS",
                  "value": "MCKEES ROCKS"
              },
              {
                  "name": "HAZLETON",
                  "label": "HAZLETON",
                  "value": "HAZLETON"
              },
              {
                  "name": "PAOLI",
                  "label": "PAOLI",
                  "value": "PAOLI"
              },
              {
                  "name": "LEWISBURG",
                  "label": "LEWISBURG",
                  "value": "LEWISBURG"
              },
              {
                  "name": "STATE COLLEGE",
                  "label": "STATE COLLEGE",
                  "value": "STATE COLLEGE"
              },
              {
                  "name": "RIDLEY PARK",
                  "label": "RIDLEY PARK",
                  "value": "RIDLEY PARK"
              },
              {
                  "name": "ABINGTON",
                  "label": "ABINGTON",
                  "value": "ABINGTON"
              },
              {
                  "name": "SELLERSVILLE",
                  "label": "SELLERSVILLE",
                  "value": "SELLERSVILLE"
              },
              {
                  "name": "KING OF PRUSSIA",
                  "label": "KING OF PRUSSIA",
                  "value": "KING OF PRUSSIA"
              },
              {
                  "name": "MCKEESPORT",
                  "label": "MCKEESPORT",
                  "value": "MCKEESPORT"
              },
              {
                  "name": "EASTON",
                  "label": "EASTON",
                  "value": "EASTON"
              },
              {
                  "name": "GREENSBURG",
                  "label": "GREENSBURG",
                  "value": "GREENSBURG"
              },
              {
                  "name": "PHILA",
                  "label": "PHILA",
                  "value": "PHILA"
              },
              {
                  "name": "DARBY",
                  "label": "DARBY",
                  "value": "DARBY"
              },
              {
                  "name": "DUNMORE",
                  "label": "DUNMORE",
                  "value": "DUNMORE"
              },
              {
                  "name": "PUNXSUTAWNEY",
                  "label": "PUNXSUTAWNEY",
                  "value": "PUNXSUTAWNEY"
              },
              {
                  "name": "EAST NORRITON",
                  "label": "EAST NORRITON",
                  "value": "EAST NORRITON"
              },
              {
                  "name": "LEBANON",
                  "label": "LEBANON",
                  "value": "LEBANON"
              },
              {
                  "name": "EPHRATA",
                  "label": "EPHRATA",
                  "value": "EPHRATA"
              },
              {
                  "name": "CARLISLE",
                  "label": "CARLISLE",
                  "value": "CARLISLE"
              },
              {
                  "name": "FARRELL",
                  "label": "FARRELL",
                  "value": "FARRELL"
              },
              {
                  "name": "GROVE CITY",
                  "label": "GROVE CITY",
                  "value": "GROVE CITY"
              },
              {
                  "name": "NEWTOWN",
                  "label": "NEWTOWN",
                  "value": "NEWTOWN"
              },
              {
                  "name": "HARRISBURG",
                  "label": "HARRISBURG",
                  "value": "HARRISBURG"
              },
              {
                  "name": "MONROEVILLE",
                  "label": "MONROEVILLE",
                  "value": "MONROEVILLE"
              },
              {
                  "name": "EAST STROUDSBURG",
                  "label": "EAST STROUDSBURG",
                  "value": "EAST STROUDSBURG"
              },
              {
                  "name": "WILLIAMSPORT",
                  "label": "WILLIAMSPORT",
                  "value": "WILLIAMSPORT"
              },
              {
                  "name": "HANOVER",
                  "label": "HANOVER",
                  "value": "HANOVER"
              },
              {
                  "name": "BRYN MAWR",
                  "label": "BRYN MAWR",
                  "value": "BRYN MAWR"
              },
              {
                  "name": "DU BOIS",
                  "label": "DU BOIS",
                  "value": "DU BOIS"
              },
              {
                  "name": "CHAMBERSBURG",
                  "label": "CHAMBERSBURG",
                  "value": "CHAMBERSBURG"
              },
              {
                  "name": "WEST CHESTER",
                  "label": "WEST CHESTER",
                  "value": "WEST CHESTER"
              },
              {
                  "name": "GIBSONIA",
                  "label": "GIBSONIA",
                  "value": "GIBSONIA"
              },
              {
                  "name": "WAMPUM",
                  "label": "WAMPUM",
                  "value": "WAMPUM"
              },
              {
                  "name": "READING",
                  "label": "READING",
                  "value": "READING"
              },
              {
                  "name": "WILKES BARRE",
                  "label": "WILKES BARRE",
                  "value": "WILKES BARRE"
              },
              {
                  "name": "BETHEL PARK",
                  "label": "BETHEL PARK",
                  "value": "BETHEL PARK"
              },
              {
                  "name": "FOUNTAIN HILL",
                  "label": "FOUNTAIN HILL",
                  "value": "FOUNTAIN HILL"
              },
              {
                  "name": "WARREN",
                  "label": "WARREN",
                  "value": "WARREN"
              },
              {
                  "name": "POTTSVILLE",
                  "label": "POTTSVILLE",
                  "value": "POTTSVILLE"
              },
              {
                  "name": "KINGSTON",
                  "label": "KINGSTON",
                  "value": "KINGSTON"
              },
              {
                  "name": "UNIONTOWN",
                  "label": "UNIONTOWN",
                  "value": "UNIONTOWN"
              },
              {
                  "name": "ALTOONA",
                  "label": "ALTOONA",
                  "value": "ALTOONA"
              },
              {
                  "name": "CHESTER",
                  "label": "CHESTER",
                  "value": "CHESTER"
              },
              {
                  "name": "MEADOWBROOK",
                  "label": "MEADOWBROOK",
                  "value": "MEADOWBROOK"
              },
              {
                  "name": "YARDLEY",
                  "label": "YARDLEY",
                  "value": "YARDLEY"
              },
              {
                  "name": "POTTSTOWN",
                  "label": "POTTSTOWN",
                  "value": "POTTSTOWN"
              },
              {
                  "name": "BERWYN",
                  "label": "BERWYN",
                  "value": "BERWYN"
              },
              {
                  "name": "INDIANA",
                  "label": "INDIANA",
                  "value": "INDIANA"
              },
              {
                  "name": "BROOMALL",
                  "label": "BROOMALL",
                  "value": "BROOMALL"
              },
              {
                  "name": "WEXFORD",
                  "label": "WEXFORD",
                  "value": "WEXFORD"
              },
              {
                  "name": "BEAVER",
                  "label": "BEAVER",
                  "value": "BEAVER"
              },
              {
                  "name": "NEW CASTLE",
                  "label": "NEW CASTLE",
                  "value": "NEW CASTLE"
              },
              {
                  "name": "LEWISTOWN",
                  "label": "LEWISTOWN",
                  "value": "LEWISTOWN"
              },
              {
                  "name": "WYNDMOOR",
                  "label": "WYNDMOOR",
                  "value": "WYNDMOOR"
              },
              {
                  "name": "WYNNEWOOD",
                  "label": "WYNNEWOOD",
                  "value": "WYNNEWOOD"
              },
              {
                  "name": "WARWICK",
                  "label": "WARWICK",
                  "value": "WARWICK"
              },
              {
                  "name": "GREENVILLE",
                  "label": "GREENVILLE",
                  "value": "GREENVILLE"
              },
              {
                  "name": "LATROBE",
                  "label": "LATROBE",
                  "value": "LATROBE"
              },
              {
                  "name": "E STROUDSBURG",
                  "label": "E STROUDSBURG",
                  "value": "E STROUDSBURG"
              },
              {
                  "name": "CHALFONT",
                  "label": "CHALFONT",
                  "value": "CHALFONT"
              },
              {
                  "name": "COUDERSPORT",
                  "label": "COUDERSPORT",
                  "value": "COUDERSPORT"
              },
              {
                  "name": "BENSALEM",
                  "label": "BENSALEM",
                  "value": "BENSALEM"
              },
              {
                  "name": "STROUDSBURG",
                  "label": "STROUDSBURG",
                  "value": "STROUDSBURG"
              },
              {
                  "name": "NORTHAMPTON",
                  "label": "NORTHAMPTON",
                  "value": "NORTHAMPTON"
              },
              {
                  "name": "LEVITTOWN",
                  "label": "LEVITTOWN",
                  "value": "LEVITTOWN"
              },
              {
                  "name": "WASHINGTON",
                  "label": "WASHINGTON",
                  "value": "WASHINGTON"
              },
              {
                  "name": "NATRONA HEIGHTS",
                  "label": "NATRONA HEIGHTS",
                  "value": "NATRONA HEIGHTS"
              },
              {
                  "name": "GLEN MILLS",
                  "label": "GLEN MILLS",
                  "value": "GLEN MILLS"
              },
              {
                  "name": "DUBOIS",
                  "label": "DUBOIS",
                  "value": "DUBOIS"
              },
              {
                  "name": "WEST MIFFLIN",
                  "label": "WEST MIFFLIN",
                  "value": "WEST MIFFLIN"
              },
              {
                  "name": "JOHNSTOWN",
                  "label": "JOHNSTOWN",
                  "value": "JOHNSTOWN"
              },
              {
                  "name": "DOWNINGTOWN",
                  "label": "DOWNINGTOWN",
                  "value": "DOWNINGTOWN"
              },
              {
                  "name": "MOON TOWNSHIP",
                  "label": "MOON TOWNSHIP",
                  "value": "MOON TOWNSHIP"
              },
              {
                  "name": "WATSONTOWN",
                  "label": "WATSONTOWN",
                  "value": "WATSONTOWN"
              },
              {
                  "name": "CLARION",
                  "label": "CLARION",
                  "value": "CLARION"
              },
              {
                  "name": "N HUNTINGDON",
                  "label": "N HUNTINGDON",
                  "value": "N HUNTINGDON"
              },
              {
                  "name": "MCMURRAY",
                  "label": "MCMURRAY",
                  "value": "MCMURRAY"
              },
              {
                  "name": "WAYNE",
                  "label": "WAYNE",
                  "value": "WAYNE"
              },
              {
                  "name": "CLEARFIELD",
                  "label": "CLEARFIELD",
                  "value": "CLEARFIELD"
              },
              {
                  "name": "SENECA",
                  "label": "SENECA",
                  "value": "SENECA"
              },
              {
                  "name": "SELINSGROVE",
                  "label": "SELINSGROVE",
                  "value": "SELINSGROVE"
              },
              {
                  "name": "MT PLEASANT",
                  "label": "MT PLEASANT",
                  "value": "MT PLEASANT"
              },
              {
                  "name": "SEVEN FIELDS",
                  "label": "SEVEN FIELDS",
                  "value": "SEVEN FIELDS"
              },
              {
                  "name": "WEST GROVE",
                  "label": "WEST GROVE",
                  "value": "WEST GROVE"
              },
              {
                  "name": "WAYMART",
                  "label": "WAYMART",
                  "value": "WAYMART"
              },
              {
                  "name": "MARS",
                  "label": "MARS",
                  "value": "MARS"
              },
              {
                  "name": "MEDIA",
                  "label": "MEDIA",
                  "value": "MEDIA"
              },
              {
                  "name": "EXTON",
                  "label": "EXTON",
                  "value": "EXTON"
              },
              {
                  "name": "CHESTER SPRINGS",
                  "label": "CHESTER SPRINGS",
                  "value": "CHESTER SPRINGS"
              },
              {
                  "name": "WELLSBORO",
                  "label": "WELLSBORO",
                  "value": "WELLSBORO"
              },
              {
                  "name": "RADNOR",
                  "label": "RADNOR",
                  "value": "RADNOR"
              },
              {
                  "name": "LANSDALE",
                  "label": "LANSDALE",
                  "value": "LANSDALE"
              },
              {
                  "name": "HATBORO",
                  "label": "HATBORO",
                  "value": "HATBORO"
              },
              {
                  "name": "ROSEMONT",
                  "label": "ROSEMONT",
                  "value": "ROSEMONT"
              },
              {
                  "name": "BRADFORD",
                  "label": "BRADFORD",
                  "value": "BRADFORD"
              },
              {
                  "name": "ALLISON PARK",
                  "label": "ALLISON PARK",
                  "value": "ALLISON PARK"
              },
              {
                  "name": "LOWER BURRELL",
                  "label": "LOWER BURRELL",
                  "value": "LOWER BURRELL"
              }
          ]
      },
      {
          "name": "ID",
          "label": "Idaho",
          "value": "ID",
          "cities": [
              {
                  "name": "BOISE",
                  "label": "BOISE",
                  "value": "BOISE"
              },
              {
                  "name": "COEUR D ALENE",
                  "label": "COEUR D ALENE",
                  "value": "COEUR D ALENE"
              },
              {
                  "name": "Meridian",
                  "label": "Meridian",
                  "value": "Meridian"
              },
              {
                  "name": "TWIN FALLS",
                  "label": "TWIN FALLS",
                  "value": "TWIN FALLS"
              },
              {
                  "name": "IDAHO FALLS",
                  "label": "IDAHO FALLS",
                  "value": "IDAHO FALLS"
              },
              {
                  "name": "NAMPA",
                  "label": "NAMPA",
                  "value": "NAMPA"
              },
              {
                  "name": "COEUR D'ALENE",
                  "label": "COEUR D'ALENE",
                  "value": "COEUR D'ALENE"
              },
              {
                  "name": "LEWISTON",
                  "label": "LEWISTON",
                  "value": "LEWISTON"
              },
              {
                  "name": "REXBURG",
                  "label": "REXBURG",
                  "value": "REXBURG"
              },
              {
                  "name": "SANDPOINT",
                  "label": "SANDPOINT",
                  "value": "SANDPOINT"
              },
              {
                  "name": "CALDWELL",
                  "label": "CALDWELL",
                  "value": "CALDWELL"
              },
              {
                  "name": "POCATELLO",
                  "label": "POCATELLO",
                  "value": "POCATELLO"
              },
              {
                  "name": "POST FALLS",
                  "label": "POST FALLS",
                  "value": "POST FALLS"
              },
              {
                  "name": "DRIGGS",
                  "label": "DRIGGS",
                  "value": "DRIGGS"
              }
          ]
      },
      {
          "name": "LA",
          "label": "Louisiana",
          "value": "LA",
          "cities": [
              {
                  "name": "NEW ORLEANS",
                  "label": "NEW ORLEANS",
                  "value": "NEW ORLEANS"
              },
              {
                  "name": "METAIRIE",
                  "label": "METAIRIE",
                  "value": "METAIRIE"
              },
              {
                  "name": "BATON ROUGE",
                  "label": "BATON ROUGE",
                  "value": "BATON ROUGE"
              },
              {
                  "name": "SHREVEPORT",
                  "label": "SHREVEPORT",
                  "value": "SHREVEPORT"
              },
              {
                  "name": "ALEXANDRIA",
                  "label": "ALEXANDRIA",
                  "value": "ALEXANDRIA"
              },
              {
                  "name": "NEW IBERIA",
                  "label": "NEW IBERIA",
                  "value": "NEW IBERIA"
              },
              {
                  "name": "HAMMOND",
                  "label": "HAMMOND",
                  "value": "HAMMOND"
              },
              {
                  "name": "COVINGTON",
                  "label": "COVINGTON",
                  "value": "COVINGTON"
              },
              {
                  "name": "SLIDELL",
                  "label": "SLIDELL",
                  "value": "SLIDELL"
              },
              {
                  "name": "LAFAYETTE",
                  "label": "LAFAYETTE",
                  "value": "LAFAYETTE"
              },
              {
                  "name": "MARRERO",
                  "label": "MARRERO",
                  "value": "MARRERO"
              },
              {
                  "name": "ABBEVILLE",
                  "label": "ABBEVILLE",
                  "value": "ABBEVILLE"
              },
              {
                  "name": "LEESVILLE",
                  "label": "LEESVILLE",
                  "value": "LEESVILLE"
              },
              {
                  "name": "THIBODAUX",
                  "label": "THIBODAUX",
                  "value": "THIBODAUX"
              },
              {
                  "name": "CROWLEY",
                  "label": "CROWLEY",
                  "value": "CROWLEY"
              },
              {
                  "name": "HOUMA",
                  "label": "HOUMA",
                  "value": "HOUMA"
              },
              {
                  "name": "LAKE CHARLES",
                  "label": "LAKE CHARLES",
                  "value": "LAKE CHARLES"
              },
              {
                  "name": "SPRINGHILL",
                  "label": "SPRINGHILL",
                  "value": "SPRINGHILL"
              },
              {
                  "name": "RUSTON",
                  "label": "RUSTON",
                  "value": "RUSTON"
              },
              {
                  "name": "WINNSBORO",
                  "label": "WINNSBORO",
                  "value": "WINNSBORO"
              },
              {
                  "name": "KENNER",
                  "label": "KENNER",
                  "value": "KENNER"
              },
              {
                  "name": "OPELOUSAS",
                  "label": "OPELOUSAS",
                  "value": "OPELOUSAS"
              },
              {
                  "name": "ZACHARY",
                  "label": "ZACHARY",
                  "value": "ZACHARY"
              },
              {
                  "name": "GRETNA",
                  "label": "GRETNA",
                  "value": "GRETNA"
              },
              {
                  "name": "MAMOU",
                  "label": "MAMOU",
                  "value": "MAMOU"
              },
              {
                  "name": "WEST MONROE",
                  "label": "WEST MONROE",
                  "value": "WEST MONROE"
              },
              {
                  "name": "MONROE",
                  "label": "MONROE",
                  "value": "MONROE"
              },
              {
                  "name": "HARVEY",
                  "label": "HARVEY",
                  "value": "HARVEY"
              },
              {
                  "name": "RACELAND",
                  "label": "RACELAND",
                  "value": "RACELAND"
              }
          ]
      },
      {
          "name": "AZ",
          "label": "Arizona",
          "value": "AZ",
          "cities": [
              {
                  "name": "PHOENIX",
                  "label": "PHOENIX",
                  "value": "PHOENIX"
              },
              {
                  "name": "TUCSON",
                  "label": "TUCSON",
                  "value": "TUCSON"
              },
              {
                  "name": "SCOTTSDALE",
                  "label": "SCOTTSDALE",
                  "value": "SCOTTSDALE"
              },
              {
                  "name": "KEARNY",
                  "label": "KEARNY",
                  "value": "KEARNY"
              },
              {
                  "name": "GILBERT",
                  "label": "GILBERT",
                  "value": "GILBERT"
              },
              {
                  "name": "TEMPE",
                  "label": "TEMPE",
                  "value": "TEMPE"
              },
              {
                  "name": "GLENDALE",
                  "label": "GLENDALE",
                  "value": "GLENDALE"
              },
              {
                  "name": "COTTONWOOD",
                  "label": "COTTONWOOD",
                  "value": "COTTONWOOD"
              },
              {
                  "name": "GREEN VALLEY",
                  "label": "GREEN VALLEY",
                  "value": "GREEN VALLEY"
              },
              {
                  "name": "GOODYEAR",
                  "label": "GOODYEAR",
                  "value": "GOODYEAR"
              },
              {
                  "name": "MESA",
                  "label": "MESA",
                  "value": "MESA"
              },
              {
                  "name": "PEORIA",
                  "label": "PEORIA",
                  "value": "PEORIA"
              },
              {
                  "name": "CHANDLER",
                  "label": "CHANDLER",
                  "value": "CHANDLER"
              },
              {
                  "name": "YUMA",
                  "label": "YUMA",
                  "value": "YUMA"
              },
              {
                  "name": "SIERRA VISTA",
                  "label": "SIERRA VISTA",
                  "value": "SIERRA VISTA"
              },
              {
                  "name": "ANTHEM",
                  "label": "ANTHEM",
                  "value": "ANTHEM"
              },
              {
                  "name": "GLOBE",
                  "label": "GLOBE",
                  "value": "GLOBE"
              },
              {
                  "name": "SURPRISE",
                  "label": "SURPRISE",
                  "value": "SURPRISE"
              },
              {
                  "name": "CHINLE",
                  "label": "CHINLE",
                  "value": "CHINLE"
              },
              {
                  "name": "SUN CITY",
                  "label": "SUN CITY",
                  "value": "SUN CITY"
              },
              {
                  "name": "PRESCOTT VALLEY",
                  "label": "PRESCOTT VALLEY",
                  "value": "PRESCOTT VALLEY"
              },
              {
                  "name": "LAKE HAVASU CITY",
                  "label": "LAKE HAVASU CITY",
                  "value": "LAKE HAVASU CITY"
              },
              {
                  "name": "CASA GRANDE",
                  "label": "CASA GRANDE",
                  "value": "CASA GRANDE"
              },
              {
                  "name": "SUN CITY WEST",
                  "label": "SUN CITY WEST",
                  "value": "SUN CITY WEST"
              },
              {
                  "name": "PARKS",
                  "label": "PARKS",
                  "value": "PARKS"
              },
              {
                  "name": "FLAGSTAFF",
                  "label": "FLAGSTAFF",
                  "value": "FLAGSTAFF"
              },
              {
                  "name": "TUBA CITY",
                  "label": "TUBA CITY",
                  "value": "TUBA CITY"
              },
              {
                  "name": "AVONDALE",
                  "label": "AVONDALE",
                  "value": "AVONDALE"
              },
              {
                  "name": "RED MESA",
                  "label": "RED MESA",
                  "value": "RED MESA"
              },
              {
                  "name": "BULLHEAD CITY",
                  "label": "BULLHEAD CITY",
                  "value": "BULLHEAD CITY"
              },
              {
                  "name": "SELLS",
                  "label": "SELLS",
                  "value": "SELLS"
              },
              {
                  "name": "PRESCOTT",
                  "label": "PRESCOTT",
                  "value": "PRESCOTT"
              },
              {
                  "name": "KINGMAN",
                  "label": "KINGMAN",
                  "value": "KINGMAN"
              }
          ]
      },
      {
          "name": "MI",
          "label": "Michigan",
          "value": "MI",
          "cities": [
              {
                  "name": "ANN ARBOR",
                  "label": "ANN ARBOR",
                  "value": "ANN ARBOR"
              },
              {
                  "name": "ROCHESTER",
                  "label": "ROCHESTER",
                  "value": "ROCHESTER"
              },
              {
                  "name": "CADILLAC",
                  "label": "CADILLAC",
                  "value": "CADILLAC"
              },
              {
                  "name": "GRAND RAPIDS",
                  "label": "GRAND RAPIDS",
                  "value": "GRAND RAPIDS"
              },
              {
                  "name": "FARMINGTON",
                  "label": "FARMINGTON",
                  "value": "FARMINGTON"
              },
              {
                  "name": "COLDWATER",
                  "label": "COLDWATER",
                  "value": "COLDWATER"
              },
              {
                  "name": "WEST BRANCH",
                  "label": "WEST BRANCH",
                  "value": "WEST BRANCH"
              },
              {
                  "name": "KALAMAZOO",
                  "label": "KALAMAZOO",
                  "value": "KALAMAZOO"
              },
              {
                  "name": "ROSEVILLE",
                  "label": "ROSEVILLE",
                  "value": "ROSEVILLE"
              },
              {
                  "name": "HOLLY",
                  "label": "HOLLY",
                  "value": "HOLLY"
              },
              {
                  "name": "SOUTHFIELD",
                  "label": "SOUTHFIELD",
                  "value": "SOUTHFIELD"
              },
              {
                  "name": "DETROIT",
                  "label": "DETROIT",
                  "value": "DETROIT"
              },
              {
                  "name": "GAYLORD",
                  "label": "GAYLORD",
                  "value": "GAYLORD"
              },
              {
                  "name": "FREMONT",
                  "label": "FREMONT",
                  "value": "FREMONT"
              },
              {
                  "name": "SAGINAW",
                  "label": "SAGINAW",
                  "value": "SAGINAW"
              },
              {
                  "name": "PONTIAC",
                  "label": "PONTIAC",
                  "value": "PONTIAC"
              },
              {
                  "name": "ROYAL OAK",
                  "label": "ROYAL OAK",
                  "value": "ROYAL OAK"
              },
              {
                  "name": "MOUNT PLEASANT",
                  "label": "MOUNT PLEASANT",
                  "value": "MOUNT PLEASANT"
              },
              {
                  "name": "MACOMB",
                  "label": "MACOMB",
                  "value": "MACOMB"
              },
              {
                  "name": "SAULT SAINT MARIE",
                  "label": "SAULT SAINT MARIE",
                  "value": "SAULT SAINT MARIE"
              },
              {
                  "name": "IONIA",
                  "label": "IONIA",
                  "value": "IONIA"
              },
              {
                  "name": "BAY CITY",
                  "label": "BAY CITY",
                  "value": "BAY CITY"
              },
              {
                  "name": "IRON MOUNTAIN",
                  "label": "IRON MOUNTAIN",
                  "value": "IRON MOUNTAIN"
              },
              {
                  "name": "BATTLE CREEK",
                  "label": "BATTLE CREEK",
                  "value": "BATTLE CREEK"
              },
              {
                  "name": "WYANDOTTE",
                  "label": "WYANDOTTE",
                  "value": "WYANDOTTE"
              },
              {
                  "name": "WYOMING",
                  "label": "WYOMING",
                  "value": "WYOMING"
              },
              {
                  "name": "BROWNSTOWN",
                  "label": "BROWNSTOWN",
                  "value": "BROWNSTOWN"
              },
              {
                  "name": "FLINT",
                  "label": "FLINT",
                  "value": "FLINT"
              },
              {
                  "name": "BLOOMFIELD HILLS",
                  "label": "BLOOMFIELD HILLS",
                  "value": "BLOOMFIELD HILLS"
              },
              {
                  "name": "OAK PARK",
                  "label": "OAK PARK",
                  "value": "OAK PARK"
              },
              {
                  "name": "MONROE",
                  "label": "MONROE",
                  "value": "MONROE"
              },
              {
                  "name": "STERLING HEIGHTS",
                  "label": "STERLING HEIGHTS",
                  "value": "STERLING HEIGHTS"
              },
              {
                  "name": "LANSING",
                  "label": "LANSING",
                  "value": "LANSING"
              },
              {
                  "name": "SAINT JOSEPH",
                  "label": "SAINT JOSEPH",
                  "value": "SAINT JOSEPH"
              },
              {
                  "name": "EAST LANSING",
                  "label": "EAST LANSING",
                  "value": "EAST LANSING"
              },
              {
                  "name": "LAPEER",
                  "label": "LAPEER",
                  "value": "LAPEER"
              },
              {
                  "name": "YPSILANTI",
                  "label": "YPSILANTI",
                  "value": "YPSILANTI"
              },
              {
                  "name": "PORT HOPE",
                  "label": "PORT HOPE",
                  "value": "PORT HOPE"
              },
              {
                  "name": "CLINTON TOWNSHIP",
                  "label": "CLINTON TOWNSHIP",
                  "value": "CLINTON TOWNSHIP"
              },
              {
                  "name": "COMMERCE TOWNSHIP",
                  "label": "COMMERCE TOWNSHIP",
                  "value": "COMMERCE TOWNSHIP"
              },
              {
                  "name": "PETOSKEY",
                  "label": "PETOSKEY",
                  "value": "PETOSKEY"
              },
              {
                  "name": "GROSSE POINTE",
                  "label": "GROSSE POINTE",
                  "value": "GROSSE POINTE"
              },
              {
                  "name": "MARSHALL",
                  "label": "MARSHALL",
                  "value": "MARSHALL"
              },
              {
                  "name": "NOVI",
                  "label": "NOVI",
                  "value": "NOVI"
              },
              {
                  "name": "DEARBORN",
                  "label": "DEARBORN",
                  "value": "DEARBORN"
              },
              {
                  "name": "ROCHESTER HILLS",
                  "label": "ROCHESTER HILLS",
                  "value": "ROCHESTER HILLS"
              },
              {
                  "name": "TROY",
                  "label": "TROY",
                  "value": "TROY"
              },
              {
                  "name": "OWOSSO",
                  "label": "OWOSSO",
                  "value": "OWOSSO"
              },
              {
                  "name": "FARMINGTON HILLS",
                  "label": "FARMINGTON HILLS",
                  "value": "FARMINGTON HILLS"
              },
              {
                  "name": "GROSSE POINTE WOODS",
                  "label": "GROSSE POINTE WOODS",
                  "value": "GROSSE POINTE WOODS"
              },
              {
                  "name": "REED CITY",
                  "label": "REED CITY",
                  "value": "REED CITY"
              },
              {
                  "name": "BROOKLYN",
                  "label": "BROOKLYN",
                  "value": "BROOKLYN"
              },
              {
                  "name": "GREENVILLE",
                  "label": "GREENVILLE",
                  "value": "GREENVILLE"
              },
              {
                  "name": "GRAND BLANC",
                  "label": "GRAND BLANC",
                  "value": "GRAND BLANC"
              },
              {
                  "name": "MUSKEGON",
                  "label": "MUSKEGON",
                  "value": "MUSKEGON"
              },
              {
                  "name": "MARQUETTE",
                  "label": "MARQUETTE",
                  "value": "MARQUETTE"
              },
              {
                  "name": "OKEMOS",
                  "label": "OKEMOS",
                  "value": "OKEMOS"
              },
              {
                  "name": "JACKSON",
                  "label": "JACKSON",
                  "value": "JACKSON"
              },
              {
                  "name": "GLADWIN",
                  "label": "GLADWIN",
                  "value": "GLADWIN"
              },
              {
                  "name": "MT PLEASANT",
                  "label": "MT PLEASANT",
                  "value": "MT PLEASANT"
              },
              {
                  "name": "HANCOCK",
                  "label": "HANCOCK",
                  "value": "HANCOCK"
              },
              {
                  "name": "MIDLAND",
                  "label": "MIDLAND",
                  "value": "MIDLAND"
              },
              {
                  "name": "CHELSEA",
                  "label": "CHELSEA",
                  "value": "CHELSEA"
              },
              {
                  "name": "WEST BLOOMFIELD",
                  "label": "WEST BLOOMFIELD",
                  "value": "WEST BLOOMFIELD"
              },
              {
                  "name": "ESCANABA",
                  "label": "ESCANABA",
                  "value": "ESCANABA"
              },
              {
                  "name": "TRAVERSE CITY",
                  "label": "TRAVERSE CITY",
                  "value": "TRAVERSE CITY"
              },
              {
                  "name": "TAYLOR",
                  "label": "TAYLOR",
                  "value": "TAYLOR"
              },
              {
                  "name": "CHARLEVOIX",
                  "label": "CHARLEVOIX",
                  "value": "CHARLEVOIX"
              },
              {
                  "name": "MADISON HEIGHTS",
                  "label": "MADISON HEIGHTS",
                  "value": "MADISON HEIGHTS"
              },
              {
                  "name": "CLARKSTON",
                  "label": "CLARKSTON",
                  "value": "CLARKSTON"
              },
              {
                  "name": "BIG RAPIDS",
                  "label": "BIG RAPIDS",
                  "value": "BIG RAPIDS"
              },
              {
                  "name": "LIVONIA",
                  "label": "LIVONIA",
                  "value": "LIVONIA"
              },
              {
                  "name": "CLINTON TWP",
                  "label": "CLINTON TWP",
                  "value": "CLINTON TWP"
              },
              {
                  "name": "ORION",
                  "label": "ORION",
                  "value": "ORION"
              },
              {
                  "name": "HOLLAND",
                  "label": "HOLLAND",
                  "value": "HOLLAND"
              },
              {
                  "name": "LUDINGTON",
                  "label": "LUDINGTON",
                  "value": "LUDINGTON"
              },
              {
                  "name": "MOUNT CLEMENS",
                  "label": "MOUNT CLEMENS",
                  "value": "MOUNT CLEMENS"
              },
              {
                  "name": "BRIGHTON",
                  "label": "BRIGHTON",
                  "value": "BRIGHTON"
              },
              {
                  "name": "HAMTRAMCK",
                  "label": "HAMTRAMCK",
                  "value": "HAMTRAMCK"
              },
              {
                  "name": "PIGEON",
                  "label": "PIGEON",
                  "value": "PIGEON"
              },
              {
                  "name": "PORT HURON",
                  "label": "PORT HURON",
                  "value": "PORT HURON"
              },
              {
                  "name": "KINGSFORD",
                  "label": "KINGSFORD",
                  "value": "KINGSFORD"
              },
              {
                  "name": "BROWNSTOWN TWP",
                  "label": "BROWNSTOWN TWP",
                  "value": "BROWNSTOWN TWP"
              },
              {
                  "name": "ALPENA",
                  "label": "ALPENA",
                  "value": "ALPENA"
              },
              {
                  "name": "LAURIUM",
                  "label": "LAURIUM",
                  "value": "LAURIUM"
              },
              {
                  "name": "GRAND HAVEN",
                  "label": "GRAND HAVEN",
                  "value": "GRAND HAVEN"
              },
              {
                  "name": "BROCKWAY",
                  "label": "BROCKWAY",
                  "value": "BROCKWAY"
              },
              {
                  "name": "WOODHAVEN",
                  "label": "WOODHAVEN",
                  "value": "WOODHAVEN"
              },
              {
                  "name": "GROSSE PTE WDS",
                  "label": "GROSSE PTE WDS",
                  "value": "GROSSE PTE WDS"
              },
              {
                  "name": "NORTON SHORES",
                  "label": "NORTON SHORES",
                  "value": "NORTON SHORES"
              },
              {
                  "name": "WARREN",
                  "label": "WARREN",
                  "value": "WARREN"
              },
              {
                  "name": "IMLAY CITY",
                  "label": "IMLAY CITY",
                  "value": "IMLAY CITY"
              },
              {
                  "name": "IRONWOOD",
                  "label": "IRONWOOD",
                  "value": "IRONWOOD"
              },
              {
                  "name": "REDFORD",
                  "label": "REDFORD",
                  "value": "REDFORD"
              },
              {
                  "name": "DOWAGIAC",
                  "label": "DOWAGIAC",
                  "value": "DOWAGIAC"
              },
              {
                  "name": "SHELBY TWP",
                  "label": "SHELBY TWP",
                  "value": "SHELBY TWP"
              },
              {
                  "name": "SOUTH HAVEN",
                  "label": "SOUTH HAVEN",
                  "value": "SOUTH HAVEN"
              },
              {
                  "name": "GRAYLING",
                  "label": "GRAYLING",
                  "value": "GRAYLING"
              },
              {
                  "name": "ALLEN PARK",
                  "label": "ALLEN PARK",
                  "value": "ALLEN PARK"
              },
              {
                  "name": "FORT GRATIOT",
                  "label": "FORT GRATIOT",
                  "value": "FORT GRATIOT"
              },
              {
                  "name": "TAWAS CITY",
                  "label": "TAWAS CITY",
                  "value": "TAWAS CITY"
              },
              {
                  "name": "HASTINGS",
                  "label": "HASTINGS",
                  "value": "HASTINGS"
              },
              {
                  "name": "THREE RIVERS",
                  "label": "THREE RIVERS",
                  "value": "THREE RIVERS"
              },
              {
                  "name": "ALMA",
                  "label": "ALMA",
                  "value": "ALMA"
              },
              {
                  "name": "HIGHLAND",
                  "label": "HIGHLAND",
                  "value": "HIGHLAND"
              },
              {
                  "name": "KALKASKA",
                  "label": "KALKASKA",
                  "value": "KALKASKA"
              }
          ]
      },
      {
          "name": "WI",
          "label": "Wisconsin",
          "value": "WI",
          "cities": [
              {
                  "name": "GREEN BAY",
                  "label": "GREEN BAY",
                  "value": "GREEN BAY"
              },
              {
                  "name": "APPLETON",
                  "label": "APPLETON",
                  "value": "APPLETON"
              },
              {
                  "name": "MADISON",
                  "label": "MADISON",
                  "value": "MADISON"
              },
              {
                  "name": "MILWAUKEE",
                  "label": "MILWAUKEE",
                  "value": "MILWAUKEE"
              },
              {
                  "name": "MARSHFIELD",
                  "label": "MARSHFIELD",
                  "value": "MARSHFIELD"
              },
              {
                  "name": "EAU CLAIRE",
                  "label": "EAU CLAIRE",
                  "value": "EAU CLAIRE"
              },
              {
                  "name": "KESHENA",
                  "label": "KESHENA",
                  "value": "KESHENA"
              },
              {
                  "name": "WEST ALLIS",
                  "label": "WEST ALLIS",
                  "value": "WEST ALLIS"
              },
              {
                  "name": "NEENAH",
                  "label": "NEENAH",
                  "value": "NEENAH"
              },
              {
                  "name": "ASHLAND",
                  "label": "ASHLAND",
                  "value": "ASHLAND"
              },
              {
                  "name": "STEVENS POINT",
                  "label": "STEVENS POINT",
                  "value": "STEVENS POINT"
              },
              {
                  "name": "MINOCQUA",
                  "label": "MINOCQUA",
                  "value": "MINOCQUA"
              },
              {
                  "name": "WAUKESHA",
                  "label": "WAUKESHA",
                  "value": "WAUKESHA"
              },
              {
                  "name": "KENOSHA",
                  "label": "KENOSHA",
                  "value": "KENOSHA"
              },
              {
                  "name": "BURLINGTON",
                  "label": "BURLINGTON",
                  "value": "BURLINGTON"
              },
              {
                  "name": "Barron",
                  "label": "Barron",
                  "value": "Barron"
              },
              {
                  "name": "LA CROSSE",
                  "label": "LA CROSSE",
                  "value": "LA CROSSE"
              },
              {
                  "name": "OSCEOLA",
                  "label": "OSCEOLA",
                  "value": "OSCEOLA"
              },
              {
                  "name": "WAUWATOSA",
                  "label": "WAUWATOSA",
                  "value": "WAUWATOSA"
              },
              {
                  "name": "HARTLAND",
                  "label": "HARTLAND",
                  "value": "HARTLAND"
              },
              {
                  "name": "RICE LAKE",
                  "label": "RICE LAKE",
                  "value": "RICE LAKE"
              },
              {
                  "name": "WAUSAU",
                  "label": "WAUSAU",
                  "value": "WAUSAU"
              },
              {
                  "name": "TWO RIVERS",
                  "label": "TWO RIVERS",
                  "value": "TWO RIVERS"
              },
              {
                  "name": "WISCONSIN RAPIDS",
                  "label": "WISCONSIN RAPIDS",
                  "value": "WISCONSIN RAPIDS"
              },
              {
                  "name": "MIDDLETON",
                  "label": "MIDDLETON",
                  "value": "MIDDLETON"
              },
              {
                  "name": "OCONTO",
                  "label": "OCONTO",
                  "value": "OCONTO"
              },
              {
                  "name": "MARINETTE",
                  "label": "MARINETTE",
                  "value": "MARINETTE"
              },
              {
                  "name": "PLEASANT PRAIRIE",
                  "label": "PLEASANT PRAIRIE",
                  "value": "PLEASANT PRAIRIE"
              },
              {
                  "name": "FOND DU LAC",
                  "label": "FOND DU LAC",
                  "value": "FOND DU LAC"
              },
              {
                  "name": "NEW RICHMOND",
                  "label": "NEW RICHMOND",
                  "value": "NEW RICHMOND"
              },
              {
                  "name": "OCONOMOWOC",
                  "label": "OCONOMOWOC",
                  "value": "OCONOMOWOC"
              },
              {
                  "name": "JANESVILLE",
                  "label": "JANESVILLE",
                  "value": "JANESVILLE"
              },
              {
                  "name": "SHEBOYGAN",
                  "label": "SHEBOYGAN",
                  "value": "SHEBOYGAN"
              },
              {
                  "name": "OSHKOSH",
                  "label": "OSHKOSH",
                  "value": "OSHKOSH"
              },
              {
                  "name": "LUXEMBURG",
                  "label": "LUXEMBURG",
                  "value": "LUXEMBURG"
              },
              {
                  "name": "BROOKFIELD",
                  "label": "BROOKFIELD",
                  "value": "BROOKFIELD"
              },
              {
                  "name": "KAUKAUNA",
                  "label": "KAUKAUNA",
                  "value": "KAUKAUNA"
              },
              {
                  "name": "TOMAH",
                  "label": "TOMAH",
                  "value": "TOMAH"
              },
              {
                  "name": "RACINE",
                  "label": "RACINE",
                  "value": "RACINE"
              },
              {
                  "name": "GRAFTON",
                  "label": "GRAFTON",
                  "value": "GRAFTON"
              },
              {
                  "name": "GLENDALE",
                  "label": "GLENDALE",
                  "value": "GLENDALE"
              },
              {
                  "name": "BELOIT",
                  "label": "BELOIT",
                  "value": "BELOIT"
              },
              {
                  "name": "BLACK RIVER FALLS",
                  "label": "BLACK RIVER FALLS",
                  "value": "BLACK RIVER FALLS"
              },
              {
                  "name": "FORT ATKINSON",
                  "label": "FORT ATKINSON",
                  "value": "FORT ATKINSON"
              },
              {
                  "name": "ALTOONA",
                  "label": "ALTOONA",
                  "value": "ALTOONA"
              },
              {
                  "name": "BALDWIN",
                  "label": "BALDWIN",
                  "value": "BALDWIN"
              },
              {
                  "name": "NEW BERLIN",
                  "label": "NEW BERLIN",
                  "value": "NEW BERLIN"
              },
              {
                  "name": "ONALASKA",
                  "label": "ONALASKA",
                  "value": "ONALASKA"
              },
              {
                  "name": "BERLIN",
                  "label": "BERLIN",
                  "value": "BERLIN"
              },
              {
                  "name": "WEST BEND",
                  "label": "WEST BEND",
                  "value": "WEST BEND"
              },
              {
                  "name": "REEDSBURG",
                  "label": "REEDSBURG",
                  "value": "REEDSBURG"
              },
              {
                  "name": "MEQUON",
                  "label": "MEQUON",
                  "value": "MEQUON"
              },
              {
                  "name": "OCONTO FALLS",
                  "label": "OCONTO FALLS",
                  "value": "OCONTO FALLS"
              },
              {
                  "name": "MONROE",
                  "label": "MONROE",
                  "value": "MONROE"
              },
              {
                  "name": "NEW LONDON",
                  "label": "NEW LONDON",
                  "value": "NEW LONDON"
              },
              {
                  "name": "AMERY",
                  "label": "AMERY",
                  "value": "AMERY"
              },
              {
                  "name": "RHINELANDER",
                  "label": "RHINELANDER",
                  "value": "RHINELANDER"
              },
              {
                  "name": "MENASHA",
                  "label": "MENASHA",
                  "value": "MENASHA"
              },
              {
                  "name": "FRANKLIN",
                  "label": "FRANKLIN",
                  "value": "FRANKLIN"
              },
              {
                  "name": "GERMANTOWN",
                  "label": "GERMANTOWN",
                  "value": "GERMANTOWN"
              },
              {
                  "name": "MENOMONEE FALLS",
                  "label": "MENOMONEE FALLS",
                  "value": "MENOMONEE FALLS"
              },
              {
                  "name": "SUMMIT",
                  "label": "SUMMIT",
                  "value": "SUMMIT"
              },
              {
                  "name": "SAINT CROIX FALLS",
                  "label": "SAINT CROIX FALLS",
                  "value": "SAINT CROIX FALLS"
              },
              {
                  "name": "MUKWONAGO",
                  "label": "MUKWONAGO",
                  "value": "MUKWONAGO"
              },
              {
                  "name": "SULLIVAN",
                  "label": "SULLIVAN",
                  "value": "SULLIVAN"
              },
              {
                  "name": "PRAIRIE DU SAC",
                  "label": "PRAIRIE DU SAC",
                  "value": "PRAIRIE DU SAC"
              },
              {
                  "name": "DE PERE",
                  "label": "DE PERE",
                  "value": "DE PERE"
              }
          ]
      },
      {
          "name": "CT",
          "label": "Connecticut",
          "value": "CT",
          "cities": [
              {
                  "name": "NEW HAVEN",
                  "label": "NEW HAVEN",
                  "value": "NEW HAVEN"
              },
              {
                  "name": "TRUMBULL",
                  "label": "TRUMBULL",
                  "value": "TRUMBULL"
              },
              {
                  "name": "MIDDLETOWN",
                  "label": "MIDDLETOWN",
                  "value": "MIDDLETOWN"
              },
              {
                  "name": "WATERBURY",
                  "label": "WATERBURY",
                  "value": "WATERBURY"
              },
              {
                  "name": "MANCHESTER",
                  "label": "MANCHESTER",
                  "value": "MANCHESTER"
              },
              {
                  "name": "BRIDGEPORT",
                  "label": "BRIDGEPORT",
                  "value": "BRIDGEPORT"
              },
              {
                  "name": "PUTNAM",
                  "label": "PUTNAM",
                  "value": "PUTNAM"
              },
              {
                  "name": "HARTFORD",
                  "label": "HARTFORD",
                  "value": "HARTFORD"
              },
              {
                  "name": "NORWICH",
                  "label": "NORWICH",
                  "value": "NORWICH"
              },
              {
                  "name": "WETHERSFIELD",
                  "label": "WETHERSFIELD",
                  "value": "WETHERSFIELD"
              },
              {
                  "name": "ENFIELD",
                  "label": "ENFIELD",
                  "value": "ENFIELD"
              },
              {
                  "name": "ORANGE",
                  "label": "ORANGE",
                  "value": "ORANGE"
              },
              {
                  "name": "GUILFORD",
                  "label": "GUILFORD",
                  "value": "GUILFORD"
              },
              {
                  "name": "WATERFORD",
                  "label": "WATERFORD",
                  "value": "WATERFORD"
              },
              {
                  "name": "FARMINGTON",
                  "label": "FARMINGTON",
                  "value": "FARMINGTON"
              },
              {
                  "name": "DANBURY",
                  "label": "DANBURY",
                  "value": "DANBURY"
              },
              {
                  "name": "MERIDEN",
                  "label": "MERIDEN",
                  "value": "MERIDEN"
              },
              {
                  "name": "STAMFORD",
                  "label": "STAMFORD",
                  "value": "STAMFORD"
              },
              {
                  "name": "GREENWICH",
                  "label": "GREENWICH",
                  "value": "GREENWICH"
              },
              {
                  "name": "NEW BRITAIN",
                  "label": "NEW BRITAIN",
                  "value": "NEW BRITAIN"
              },
              {
                  "name": "SOUTHBURY",
                  "label": "SOUTHBURY",
                  "value": "SOUTHBURY"
              },
              {
                  "name": "ROCKY HILL",
                  "label": "ROCKY HILL",
                  "value": "ROCKY HILL"
              },
              {
                  "name": "DERBY",
                  "label": "DERBY",
                  "value": "DERBY"
              },
              {
                  "name": "NORWALK",
                  "label": "NORWALK",
                  "value": "NORWALK"
              },
              {
                  "name": "TORRINGTON",
                  "label": "TORRINGTON",
                  "value": "TORRINGTON"
              },
              {
                  "name": "LAKEVILLE",
                  "label": "LAKEVILLE",
                  "value": "LAKEVILLE"
              },
              {
                  "name": "WILLIMANTIC",
                  "label": "WILLIMANTIC",
                  "value": "WILLIMANTIC"
              },
              {
                  "name": "AVON",
                  "label": "AVON",
                  "value": "AVON"
              },
              {
                  "name": "MILFORD",
                  "label": "MILFORD",
                  "value": "MILFORD"
              },
              {
                  "name": "HAMDEN",
                  "label": "HAMDEN",
                  "value": "HAMDEN"
              },
              {
                  "name": "BRISTOL",
                  "label": "BRISTOL",
                  "value": "BRISTOL"
              },
              {
                  "name": "FAIRFIELD",
                  "label": "FAIRFIELD",
                  "value": "FAIRFIELD"
              },
              {
                  "name": "BROOKFIELD",
                  "label": "BROOKFIELD",
                  "value": "BROOKFIELD"
              },
              {
                  "name": "NORTH HAVEN",
                  "label": "NORTH HAVEN",
                  "value": "NORTH HAVEN"
              },
              {
                  "name": "WEST HAVEN",
                  "label": "WEST HAVEN",
                  "value": "WEST HAVEN"
              },
              {
                  "name": "BETHEL",
                  "label": "BETHEL",
                  "value": "BETHEL"
              },
              {
                  "name": "WALLINGFORD",
                  "label": "WALLINGFORD",
                  "value": "WALLINGFORD"
              }
          ]
      },
      {
          "name": "MA",
          "label": "Massachusetts",
          "value": "MA",
          "cities": [
              {
                  "name": "BOSTON",
                  "label": "BOSTON",
                  "value": "BOSTON"
              },
              {
                  "name": "ATTLEBORO",
                  "label": "ATTLEBORO",
                  "value": "ATTLEBORO"
              },
              {
                  "name": "PITTSFIELD",
                  "label": "PITTSFIELD",
                  "value": "PITTSFIELD"
              },
              {
                  "name": "CAMBRIDGE",
                  "label": "CAMBRIDGE",
                  "value": "CAMBRIDGE"
              },
              {
                  "name": "BRIGHTON",
                  "label": "BRIGHTON",
                  "value": "BRIGHTON"
              },
              {
                  "name": "WORCESTER",
                  "label": "WORCESTER",
                  "value": "WORCESTER"
              },
              {
                  "name": "FALMOUTH",
                  "label": "FALMOUTH",
                  "value": "FALMOUTH"
              },
              {
                  "name": "LOWELL",
                  "label": "LOWELL",
                  "value": "LOWELL"
              },
              {
                  "name": "QUINCY",
                  "label": "QUINCY",
                  "value": "QUINCY"
              },
              {
                  "name": "OXFORD",
                  "label": "OXFORD",
                  "value": "OXFORD"
              },
              {
                  "name": "PLYMOUTH",
                  "label": "PLYMOUTH",
                  "value": "PLYMOUTH"
              },
              {
                  "name": "GARDNER",
                  "label": "GARDNER",
                  "value": "GARDNER"
              },
              {
                  "name": "MILFORD",
                  "label": "MILFORD",
                  "value": "MILFORD"
              },
              {
                  "name": "METHUEN",
                  "label": "METHUEN",
                  "value": "METHUEN"
              },
              {
                  "name": "NEWTON",
                  "label": "NEWTON",
                  "value": "NEWTON"
              },
              {
                  "name": "BURLINGTON",
                  "label": "BURLINGTON",
                  "value": "BURLINGTON"
              },
              {
                  "name": "FRAMINGHAM",
                  "label": "FRAMINGHAM",
                  "value": "FRAMINGHAM"
              },
              {
                  "name": "FALL RIVER",
                  "label": "FALL RIVER",
                  "value": "FALL RIVER"
              },
              {
                  "name": "NEWBURYPORT",
                  "label": "NEWBURYPORT",
                  "value": "NEWBURYPORT"
              },
              {
                  "name": "FAIRHAVEN",
                  "label": "FAIRHAVEN",
                  "value": "FAIRHAVEN"
              },
              {
                  "name": "NEEDHAM",
                  "label": "NEEDHAM",
                  "value": "NEEDHAM"
              },
              {
                  "name": "SPRINGFIELD",
                  "label": "SPRINGFIELD",
                  "value": "SPRINGFIELD"
              },
              {
                  "name": "WELLESLEY",
                  "label": "WELLESLEY",
                  "value": "WELLESLEY"
              },
              {
                  "name": "NORTHAMPTON",
                  "label": "NORTHAMPTON",
                  "value": "NORTHAMPTON"
              },
              {
                  "name": "WEYMOUTH",
                  "label": "WEYMOUTH",
                  "value": "WEYMOUTH"
              },
              {
                  "name": "LAWRENCE",
                  "label": "LAWRENCE",
                  "value": "LAWRENCE"
              },
              {
                  "name": "SOUTH WEYMOUTH",
                  "label": "SOUTH WEYMOUTH",
                  "value": "SOUTH WEYMOUTH"
              },
              {
                  "name": "DANVERS",
                  "label": "DANVERS",
                  "value": "DANVERS"
              },
              {
                  "name": "BRAINTREE",
                  "label": "BRAINTREE",
                  "value": "BRAINTREE"
              },
              {
                  "name": "NORTH DARTMOUTH",
                  "label": "NORTH DARTMOUTH",
                  "value": "NORTH DARTMOUTH"
              },
              {
                  "name": "HYANNIS",
                  "label": "HYANNIS",
                  "value": "HYANNIS"
              },
              {
                  "name": "WESTON",
                  "label": "WESTON",
                  "value": "WESTON"
              },
              {
                  "name": "MILTON",
                  "label": "MILTON",
                  "value": "MILTON"
              },
              {
                  "name": "WALTHAM",
                  "label": "WALTHAM",
                  "value": "WALTHAM"
              },
              {
                  "name": "BROCKTON",
                  "label": "BROCKTON",
                  "value": "BROCKTON"
              },
              {
                  "name": "ATHOL",
                  "label": "ATHOL",
                  "value": "ATHOL"
              },
              {
                  "name": "FEEDING HILLS",
                  "label": "FEEDING HILLS",
                  "value": "FEEDING HILLS"
              },
              {
                  "name": "CONCORD",
                  "label": "CONCORD",
                  "value": "CONCORD"
              },
              {
                  "name": "CHELSEA",
                  "label": "CHELSEA",
                  "value": "CHELSEA"
              },
              {
                  "name": "SOMERVILLE",
                  "label": "SOMERVILLE",
                  "value": "SOMERVILLE"
              },
              {
                  "name": "ACTON",
                  "label": "ACTON",
                  "value": "ACTON"
              },
              {
                  "name": "WINCHESTER",
                  "label": "WINCHESTER",
                  "value": "WINCHESTER"
              },
              {
                  "name": "SOUTHBRIDGE",
                  "label": "SOUTHBRIDGE",
                  "value": "SOUTHBRIDGE"
              },
              {
                  "name": "TAUNTON",
                  "label": "TAUNTON",
                  "value": "TAUNTON"
              },
              {
                  "name": "GREENFIELD",
                  "label": "GREENFIELD",
                  "value": "GREENFIELD"
              },
              {
                  "name": "STONEHAM",
                  "label": "STONEHAM",
                  "value": "STONEHAM"
              },
              {
                  "name": "NORTH FALMOUTH",
                  "label": "NORTH FALMOUTH",
                  "value": "NORTH FALMOUTH"
              },
              {
                  "name": "EVERETT",
                  "label": "EVERETT",
                  "value": "EVERETT"
              },
              {
                  "name": "TYNGSBORO",
                  "label": "TYNGSBORO",
                  "value": "TYNGSBORO"
              },
              {
                  "name": "FITCHBURG",
                  "label": "FITCHBURG",
                  "value": "FITCHBURG"
              },
              {
                  "name": "SHARON",
                  "label": "SHARON",
                  "value": "SHARON"
              },
              {
                  "name": "PEABODY",
                  "label": "PEABODY",
                  "value": "PEABODY"
              },
              {
                  "name": "CHESTNUT HILL",
                  "label": "CHESTNUT HILL",
                  "value": "CHESTNUT HILL"
              },
              {
                  "name": "NATICK",
                  "label": "NATICK",
                  "value": "NATICK"
              },
              {
                  "name": "DORCHESTER",
                  "label": "DORCHESTER",
                  "value": "DORCHESTER"
              },
              {
                  "name": "OAK BLUFFS",
                  "label": "OAK BLUFFS",
                  "value": "OAK BLUFFS"
              },
              {
                  "name": "HOLYOKE",
                  "label": "HOLYOKE",
                  "value": "HOLYOKE"
              },
              {
                  "name": "MARBLEHEAD",
                  "label": "MARBLEHEAD",
                  "value": "MARBLEHEAD"
              },
              {
                  "name": "EAST BOSTON",
                  "label": "EAST BOSTON",
                  "value": "EAST BOSTON"
              },
              {
                  "name": "JAMAICA PLAIN",
                  "label": "JAMAICA PLAIN",
                  "value": "JAMAICA PLAIN"
              },
              {
                  "name": "WEST ROXBURY",
                  "label": "WEST ROXBURY",
                  "value": "WEST ROXBURY"
              },
              {
                  "name": "AYER",
                  "label": "AYER",
                  "value": "AYER"
              },
              {
                  "name": "NORTH ANDOVER",
                  "label": "NORTH ANDOVER",
                  "value": "NORTH ANDOVER"
              },
              {
                  "name": "REVERE",
                  "label": "REVERE",
                  "value": "REVERE"
              },
              {
                  "name": "WELLESLEY HILLS",
                  "label": "WELLESLEY HILLS",
                  "value": "WELLESLEY HILLS"
              },
              {
                  "name": "MELROSE",
                  "label": "MELROSE",
                  "value": "MELROSE"
              },
              {
                  "name": "DARTMOUTH",
                  "label": "DARTMOUTH",
                  "value": "DARTMOUTH"
              }
          ]
      },
      {
          "name": "IA",
          "label": "Iowa",
          "value": "IA",
          "cities": [
              {
                  "name": "RED OAK",
                  "label": "RED OAK",
                  "value": "RED OAK"
              },
              {
                  "name": "DES MOINES",
                  "label": "DES MOINES",
                  "value": "DES MOINES"
              },
              {
                  "name": "IOWA CITY",
                  "label": "IOWA CITY",
                  "value": "IOWA CITY"
              },
              {
                  "name": "SIOUX CITY",
                  "label": "SIOUX CITY",
                  "value": "SIOUX CITY"
              },
              {
                  "name": "CRESTON",
                  "label": "CRESTON",
                  "value": "CRESTON"
              },
              {
                  "name": "MT PLEASANT",
                  "label": "MT PLEASANT",
                  "value": "MT PLEASANT"
              },
              {
                  "name": "WATERLOO",
                  "label": "WATERLOO",
                  "value": "WATERLOO"
              },
              {
                  "name": "CEDAR RAPIDS",
                  "label": "CEDAR RAPIDS",
                  "value": "CEDAR RAPIDS"
              },
              {
                  "name": "DECORAH",
                  "label": "DECORAH",
                  "value": "DECORAH"
              },
              {
                  "name": "AMES",
                  "label": "AMES",
                  "value": "AMES"
              },
              {
                  "name": "MASON CITY",
                  "label": "MASON CITY",
                  "value": "MASON CITY"
              },
              {
                  "name": "CLINTON",
                  "label": "CLINTON",
                  "value": "CLINTON"
              },
              {
                  "name": "MARENGO",
                  "label": "MARENGO",
                  "value": "MARENGO"
              },
              {
                  "name": "HARLAN",
                  "label": "HARLAN",
                  "value": "HARLAN"
              },
              {
                  "name": "SHENANDOAH",
                  "label": "SHENANDOAH",
                  "value": "SHENANDOAH"
              },
              {
                  "name": "MO VALLEY",
                  "label": "MO VALLEY",
                  "value": "MO VALLEY"
              },
              {
                  "name": "DAVENPORT",
                  "label": "DAVENPORT",
                  "value": "DAVENPORT"
              },
              {
                  "name": "FORT DODGE",
                  "label": "FORT DODGE",
                  "value": "FORT DODGE"
              },
              {
                  "name": "WEST DES MOINES",
                  "label": "WEST DES MOINES",
                  "value": "WEST DES MOINES"
              },
              {
                  "name": "DENISON",
                  "label": "DENISON",
                  "value": "DENISON"
              },
              {
                  "name": "BETTENDORF",
                  "label": "BETTENDORF",
                  "value": "BETTENDORF"
              },
              {
                  "name": "COUNCIL BLUFFS",
                  "label": "COUNCIL BLUFFS",
                  "value": "COUNCIL BLUFFS"
              },
              {
                  "name": "DUBUQUE",
                  "label": "DUBUQUE",
                  "value": "DUBUQUE"
              },
              {
                  "name": "SPENCER",
                  "label": "SPENCER",
                  "value": "SPENCER"
              },
              {
                  "name": "BELLE PLAINE",
                  "label": "BELLE PLAINE",
                  "value": "BELLE PLAINE"
              },
              {
                  "name": "WEST BURLINGTON",
                  "label": "WEST BURLINGTON",
                  "value": "WEST BURLINGTON"
              },
              {
                  "name": "CARROLL",
                  "label": "CARROLL",
                  "value": "CARROLL"
              },
              {
                  "name": "ATLANTIC",
                  "label": "ATLANTIC",
                  "value": "ATLANTIC"
              }
          ]
      },
      {
          "name": "IL",
          "label": "Illinois",
          "value": "IL",
          "cities": [
              {
                  "name": "CHICAGO",
                  "label": "CHICAGO",
                  "value": "CHICAGO"
              },
              {
                  "name": "SILVIS",
                  "label": "SILVIS",
                  "value": "SILVIS"
              },
              {
                  "name": "MELROSE PARK",
                  "label": "MELROSE PARK",
                  "value": "MELROSE PARK"
              },
              {
                  "name": "MCHENRY",
                  "label": "MCHENRY",
                  "value": "MCHENRY"
              },
              {
                  "name": "ROLLING MEADOWS",
                  "label": "ROLLING MEADOWS",
                  "value": "ROLLING MEADOWS"
              },
              {
                  "name": "SPRINGFIELD",
                  "label": "SPRINGFIELD",
                  "value": "SPRINGFIELD"
              },
              {
                  "name": "CHICAGO RIDGE",
                  "label": "CHICAGO RIDGE",
                  "value": "CHICAGO RIDGE"
              },
              {
                  "name": "ELMHURST",
                  "label": "ELMHURST",
                  "value": "ELMHURST"
              },
              {
                  "name": "URBANA",
                  "label": "URBANA",
                  "value": "URBANA"
              },
              {
                  "name": "NILES",
                  "label": "NILES",
                  "value": "NILES"
              },
              {
                  "name": "NAPERVILLE",
                  "label": "NAPERVILLE",
                  "value": "NAPERVILLE"
              },
              {
                  "name": "PEORIA",
                  "label": "PEORIA",
                  "value": "PEORIA"
              },
              {
                  "name": "DOWNERS GROVE",
                  "label": "DOWNERS GROVE",
                  "value": "DOWNERS GROVE"
              },
              {
                  "name": "BARRINGTON",
                  "label": "BARRINGTON",
                  "value": "BARRINGTON"
              },
              {
                  "name": "BURR RIDGE",
                  "label": "BURR RIDGE",
                  "value": "BURR RIDGE"
              },
              {
                  "name": "MAYWWOD",
                  "label": "MAYWWOD",
                  "value": "MAYWWOD"
              },
              {
                  "name": "CARTERVILLE",
                  "label": "CARTERVILLE",
                  "value": "CARTERVILLE"
              },
              {
                  "name": "ROCKFORD",
                  "label": "ROCKFORD",
                  "value": "ROCKFORD"
              },
              {
                  "name": "MOLINE",
                  "label": "MOLINE",
                  "value": "MOLINE"
              },
              {
                  "name": "OAK LAWN",
                  "label": "OAK LAWN",
                  "value": "OAK LAWN"
              },
              {
                  "name": "DECATUR",
                  "label": "DECATUR",
                  "value": "DECATUR"
              },
              {
                  "name": "SHILOH",
                  "label": "SHILOH",
                  "value": "SHILOH"
              },
              {
                  "name": "WARRENVILLE",
                  "label": "WARRENVILLE",
                  "value": "WARRENVILLE"
              },
              {
                  "name": "KANKAKEE",
                  "label": "KANKAKEE",
                  "value": "KANKAKEE"
              },
              {
                  "name": "HINSDALE",
                  "label": "HINSDALE",
                  "value": "HINSDALE"
              },
              {
                  "name": "PALOS HEIGHTS",
                  "label": "PALOS HEIGHTS",
                  "value": "PALOS HEIGHTS"
              },
              {
                  "name": "HAZEL CREST",
                  "label": "HAZEL CREST",
                  "value": "HAZEL CREST"
              },
              {
                  "name": "ELGIN",
                  "label": "ELGIN",
                  "value": "ELGIN"
              },
              {
                  "name": "ZION",
                  "label": "ZION",
                  "value": "ZION"
              },
              {
                  "name": "TINLEY PARK",
                  "label": "TINLEY PARK",
                  "value": "TINLEY PARK"
              },
              {
                  "name": "HARVEY",
                  "label": "HARVEY",
                  "value": "HARVEY"
              },
              {
                  "name": "PARK RIDGE",
                  "label": "PARK RIDGE",
                  "value": "PARK RIDGE"
              },
              {
                  "name": "EVANSTON",
                  "label": "EVANSTON",
                  "value": "EVANSTON"
              },
              {
                  "name": "AURORA",
                  "label": "AURORA",
                  "value": "AURORA"
              },
              {
                  "name": "LIBERTYVILLE",
                  "label": "LIBERTYVILLE",
                  "value": "LIBERTYVILLE"
              },
              {
                  "name": "GENEVA",
                  "label": "GENEVA",
                  "value": "GENEVA"
              },
              {
                  "name": "MOUNT VERNON",
                  "label": "MOUNT VERNON",
                  "value": "MOUNT VERNON"
              },
              {
                  "name": "QUINCY",
                  "label": "QUINCY",
                  "value": "QUINCY"
              },
              {
                  "name": "CARBONDALE",
                  "label": "CARBONDALE",
                  "value": "CARBONDALE"
              },
              {
                  "name": "JOLIET",
                  "label": "JOLIET",
                  "value": "JOLIET"
              },
              {
                  "name": "LOCKPORT",
                  "label": "LOCKPORT",
                  "value": "LOCKPORT"
              },
              {
                  "name": "SHELBYVILLE",
                  "label": "SHELBYVILLE",
                  "value": "SHELBYVILLE"
              },
              {
                  "name": "BERWYN",
                  "label": "BERWYN",
                  "value": "BERWYN"
              },
              {
                  "name": "NEW LENOX",
                  "label": "NEW LENOX",
                  "value": "NEW LENOX"
              },
              {
                  "name": "LISLE",
                  "label": "LISLE",
                  "value": "LISLE"
              },
              {
                  "name": "DEERFIELD",
                  "label": "DEERFIELD",
                  "value": "DEERFIELD"
              },
              {
                  "name": "MATTOON",
                  "label": "MATTOON",
                  "value": "MATTOON"
              },
              {
                  "name": "HUNTLEY",
                  "label": "HUNTLEY",
                  "value": "HUNTLEY"
              },
              {
                  "name": "ROSEMONT",
                  "label": "ROSEMONT",
                  "value": "ROSEMONT"
              },
              {
                  "name": "CHAMPAIGN",
                  "label": "CHAMPAIGN",
                  "value": "CHAMPAIGN"
              },
              {
                  "name": "CAROL STREAM",
                  "label": "CAROL STREAM",
                  "value": "CAROL STREAM"
              },
              {
                  "name": "CRYSTAL LAKE",
                  "label": "CRYSTAL LAKE",
                  "value": "CRYSTAL LAKE"
              },
              {
                  "name": "DARIEN",
                  "label": "DARIEN",
                  "value": "DARIEN"
              },
              {
                  "name": "MOKENA",
                  "label": "MOKENA",
                  "value": "MOKENA"
              },
              {
                  "name": "GURNEE",
                  "label": "GURNEE",
                  "value": "GURNEE"
              },
              {
                  "name": "SKOKIE",
                  "label": "SKOKIE",
                  "value": "SKOKIE"
              },
              {
                  "name": "NORMAL",
                  "label": "NORMAL",
                  "value": "NORMAL"
              },
              {
                  "name": "NORTH AURORA",
                  "label": "NORTH AURORA",
                  "value": "NORTH AURORA"
              },
              {
                  "name": "BOLINGBROOK",
                  "label": "BOLINGBROOK",
                  "value": "BOLINGBROOK"
              },
              {
                  "name": "DEKALB",
                  "label": "DEKALB",
                  "value": "DEKALB"
              },
              {
                  "name": "LAKEMOOR",
                  "label": "LAKEMOOR",
                  "value": "LAKEMOOR"
              },
              {
                  "name": "STERLING",
                  "label": "STERLING",
                  "value": "STERLING"
              },
              {
                  "name": "MARION",
                  "label": "MARION",
                  "value": "MARION"
              },
              {
                  "name": "HOMEWOOD",
                  "label": "HOMEWOOD",
                  "value": "HOMEWOOD"
              },
              {
                  "name": "NORTH CHICAGO",
                  "label": "NORTH CHICAGO",
                  "value": "NORTH CHICAGO"
              },
              {
                  "name": "MARQUETTE HEIGHTS",
                  "label": "MARQUETTE HEIGHTS",
                  "value": "MARQUETTE HEIGHTS"
              },
              {
                  "name": "HIGHLAND PARK",
                  "label": "HIGHLAND PARK",
                  "value": "HIGHLAND PARK"
              },
              {
                  "name": "ARLNGTN HTS",
                  "label": "ARLNGTN HTS",
                  "value": "ARLNGTN HTS"
              },
              {
                  "name": "EFFINGHAM",
                  "label": "EFFINGHAM",
                  "value": "EFFINGHAM"
              },
              {
                  "name": "MARYVILLE",
                  "label": "MARYVILLE",
                  "value": "MARYVILLE"
              },
              {
                  "name": "GLENVIEW",
                  "label": "GLENVIEW",
                  "value": "GLENVIEW"
              },
              {
                  "name": "PITTSFIELD",
                  "label": "PITTSFIELD",
                  "value": "PITTSFIELD"
              },
              {
                  "name": "BLOOMINGTON",
                  "label": "BLOOMINGTON",
                  "value": "BLOOMINGTON"
              },
              {
                  "name": "HINES",
                  "label": "HINES",
                  "value": "HINES"
              },
              {
                  "name": "ROCK ISLAND",
                  "label": "ROCK ISLAND",
                  "value": "ROCK ISLAND"
              },
              {
                  "name": "BELLEVILLE",
                  "label": "BELLEVILLE",
                  "value": "BELLEVILLE"
              },
              {
                  "name": "WINFIELD",
                  "label": "WINFIELD",
                  "value": "WINFIELD"
              },
              {
                  "name": "MORTON",
                  "label": "MORTON",
                  "value": "MORTON"
              },
              {
                  "name": "NORTHBROOK",
                  "label": "NORTHBROOK",
                  "value": "NORTHBROOK"
              },
              {
                  "name": "LAKE FOREST",
                  "label": "LAKE FOREST",
                  "value": "LAKE FOREST"
              },
              {
                  "name": "OTTAWA",
                  "label": "OTTAWA",
                  "value": "OTTAWA"
              },
              {
                  "name": "EVERGREEN PARK",
                  "label": "EVERGREEN PARK",
                  "value": "EVERGREEN PARK"
              },
              {
                  "name": "MT CARMEL",
                  "label": "MT CARMEL",
                  "value": "MT CARMEL"
              },
              {
                  "name": "ARLINGTON HEIGHTS",
                  "label": "ARLINGTON HEIGHTS",
                  "value": "ARLINGTON HEIGHTS"
              },
              {
                  "name": "ELK GROVE VILLAGE",
                  "label": "ELK GROVE VILLAGE",
                  "value": "ELK GROVE VILLAGE"
              },
              {
                  "name": "NORTHFIELD",
                  "label": "NORTHFIELD",
                  "value": "NORTHFIELD"
              },
              {
                  "name": "WAUKEGAN",
                  "label": "WAUKEGAN",
                  "value": "WAUKEGAN"
              },
              {
                  "name": "CARLINVILLE",
                  "label": "CARLINVILLE",
                  "value": "CARLINVILLE"
              },
              {
                  "name": "O FALLON",
                  "label": "O FALLON",
                  "value": "O FALLON"
              },
              {
                  "name": "ALTON",
                  "label": "ALTON",
                  "value": "ALTON"
              },
              {
                  "name": "ORLAND PARK",
                  "label": "ORLAND PARK",
                  "value": "ORLAND PARK"
              },
              {
                  "name": "OLYMPIA FIELDS",
                  "label": "OLYMPIA FIELDS",
                  "value": "OLYMPIA FIELDS"
              },
              {
                  "name": "GRANITE CITY",
                  "label": "GRANITE CITY",
                  "value": "GRANITE CITY"
              },
              {
                  "name": "HAINESVILLE",
                  "label": "HAINESVILLE",
                  "value": "HAINESVILLE"
              },
              {
                  "name": "SANDWICH",
                  "label": "SANDWICH",
                  "value": "SANDWICH"
              },
              {
                  "name": "EUREKA",
                  "label": "EUREKA",
                  "value": "EUREKA"
              },
              {
                  "name": "MORRISON",
                  "label": "MORRISON",
                  "value": "MORRISON"
              },
              {
                  "name": "DANVILLE",
                  "label": "DANVILLE",
                  "value": "DANVILLE"
              },
              {
                  "name": "CHESTER",
                  "label": "CHESTER",
                  "value": "CHESTER"
              },
              {
                  "name": "FREEPORT",
                  "label": "FREEPORT",
                  "value": "FREEPORT"
              },
              {
                  "name": "DESPLAINES",
                  "label": "DESPLAINES",
                  "value": "DESPLAINES"
              }
          ]
      },
      {
          "name": "UT",
          "label": "Utah",
          "value": "UT",
          "cities": [
              {
                  "name": "AMERICAN FORK",
                  "label": "AMERICAN FORK",
                  "value": "AMERICAN FORK"
              },
              {
                  "name": "SALT LAKE CITY",
                  "label": "SALT LAKE CITY",
                  "value": "SALT LAKE CITY"
              },
              {
                  "name": "MURRAY",
                  "label": "MURRAY",
                  "value": "MURRAY"
              },
              {
                  "name": "PROVO",
                  "label": "PROVO",
                  "value": "PROVO"
              },
              {
                  "name": "OGDEN",
                  "label": "OGDEN",
                  "value": "OGDEN"
              },
              {
                  "name": "BOUNTIFUL",
                  "label": "BOUNTIFUL",
                  "value": "BOUNTIFUL"
              },
              {
                  "name": "LOGAN",
                  "label": "LOGAN",
                  "value": "LOGAN"
              },
              {
                  "name": "ST GEORGE",
                  "label": "ST GEORGE",
                  "value": "ST GEORGE"
              },
              {
                  "name": "OREM",
                  "label": "OREM",
                  "value": "OREM"
              },
              {
                  "name": "SAINT GEORGE",
                  "label": "SAINT GEORGE",
                  "value": "SAINT GEORGE"
              },
              {
                  "name": "ST. GEORGE",
                  "label": "ST. GEORGE",
                  "value": "ST. GEORGE"
              },
              {
                  "name": "BRIGHAM CITY",
                  "label": "BRIGHAM CITY",
                  "value": "BRIGHAM CITY"
              },
              {
                  "name": "W VALLEY CITY",
                  "label": "W VALLEY CITY",
                  "value": "W VALLEY CITY"
              },
              {
                  "name": "CLEARFIELD",
                  "label": "CLEARFIELD",
                  "value": "CLEARFIELD"
              },
              {
                  "name": "SARATOGA SPRINGS",
                  "label": "SARATOGA SPRINGS",
                  "value": "SARATOGA SPRINGS"
              },
              {
                  "name": "WEST JORDAN",
                  "label": "WEST JORDAN",
                  "value": "WEST JORDAN"
              },
              {
                  "name": "SOUTH JORDAN",
                  "label": "SOUTH JORDAN",
                  "value": "SOUTH JORDAN"
              }
          ]
      },
      {
          "name": "GA",
          "label": "Georgia",
          "value": "GA",
          "cities": [
              {
                  "name": "MARIETTA",
                  "label": "MARIETTA",
                  "value": "MARIETTA"
              },
              {
                  "name": "ATLANTA",
                  "label": "ATLANTA",
                  "value": "ATLANTA"
              },
              {
                  "name": "DAHLONEGA",
                  "label": "DAHLONEGA",
                  "value": "DAHLONEGA"
              },
              {
                  "name": "ALPHARETTA",
                  "label": "ALPHARETTA",
                  "value": "ALPHARETTA"
              },
              {
                  "name": "JONESBORO",
                  "label": "JONESBORO",
                  "value": "JONESBORO"
              },
              {
                  "name": "ALBANY",
                  "label": "ALBANY",
                  "value": "ALBANY"
              },
              {
                  "name": "DALTON",
                  "label": "DALTON",
                  "value": "DALTON"
              },
              {
                  "name": "SAVANNAH",
                  "label": "SAVANNAH",
                  "value": "SAVANNAH"
              },
              {
                  "name": "ROSWELL",
                  "label": "ROSWELL",
                  "value": "ROSWELL"
              },
              {
                  "name": "CARROLLTON",
                  "label": "CARROLLTON",
                  "value": "CARROLLTON"
              },
              {
                  "name": "CUMMING",
                  "label": "CUMMING",
                  "value": "CUMMING"
              },
              {
                  "name": "NEWNAN",
                  "label": "NEWNAN",
                  "value": "NEWNAN"
              },
              {
                  "name": "STOCKBRIDGE",
                  "label": "STOCKBRIDGE",
                  "value": "STOCKBRIDGE"
              },
              {
                  "name": "VALDOSTA",
                  "label": "VALDOSTA",
                  "value": "VALDOSTA"
              },
              {
                  "name": "MACON",
                  "label": "MACON",
                  "value": "MACON"
              },
              {
                  "name": "GAINESVILLE",
                  "label": "GAINESVILLE",
                  "value": "GAINESVILLE"
              },
              {
                  "name": "AUGUSTA",
                  "label": "AUGUSTA",
                  "value": "AUGUSTA"
              },
              {
                  "name": "JOHNS CREEK",
                  "label": "JOHNS CREEK",
                  "value": "JOHNS CREEK"
              },
              {
                  "name": "ATHENS",
                  "label": "ATHENS",
                  "value": "ATHENS"
              },
              {
                  "name": "CHATSWORTH",
                  "label": "CHATSWORTH",
                  "value": "CHATSWORTH"
              },
              {
                  "name": "BLUE RIDGE",
                  "label": "BLUE RIDGE",
                  "value": "BLUE RIDGE"
              },
              {
                  "name": "COLUMBUS",
                  "label": "COLUMBUS",
                  "value": "COLUMBUS"
              },
              {
                  "name": "CONYERS",
                  "label": "CONYERS",
                  "value": "CONYERS"
              },
              {
                  "name": "DECATUR",
                  "label": "DECATUR",
                  "value": "DECATUR"
              },
              {
                  "name": "AMERICUS",
                  "label": "AMERICUS",
                  "value": "AMERICUS"
              },
              {
                  "name": "FAYETTEVILLE",
                  "label": "FAYETTEVILLE",
                  "value": "FAYETTEVILLE"
              },
              {
                  "name": "Lawrenceville",
                  "label": "Lawrenceville",
                  "value": "Lawrenceville"
              },
              {
                  "name": "CLEVELAND",
                  "label": "CLEVELAND",
                  "value": "CLEVELAND"
              },
              {
                  "name": "DUBLIN",
                  "label": "DUBLIN",
                  "value": "DUBLIN"
              },
              {
                  "name": "DOUGLASVILLE",
                  "label": "DOUGLASVILLE",
                  "value": "DOUGLASVILLE"
              },
              {
                  "name": "LAGRANGE",
                  "label": "LAGRANGE",
                  "value": "LAGRANGE"
              },
              {
                  "name": "LITHONIA",
                  "label": "LITHONIA",
                  "value": "LITHONIA"
              },
              {
                  "name": "TIFTON",
                  "label": "TIFTON",
                  "value": "TIFTON"
              },
              {
                  "name": "AUSTELL",
                  "label": "AUSTELL",
                  "value": "AUSTELL"
              },
              {
                  "name": "CALHOUN",
                  "label": "CALHOUN",
                  "value": "CALHOUN"
              },
              {
                  "name": "ST. MARYS",
                  "label": "ST. MARYS",
                  "value": "ST. MARYS"
              },
              {
                  "name": "MOULTRIE",
                  "label": "MOULTRIE",
                  "value": "MOULTRIE"
              },
              {
                  "name": "MILLEDGEVILLE",
                  "label": "MILLEDGEVILLE",
                  "value": "MILLEDGEVILLE"
              },
              {
                  "name": "HOMELAND",
                  "label": "HOMELAND",
                  "value": "HOMELAND"
              },
              {
                  "name": "WAYCROSS",
                  "label": "WAYCROSS",
                  "value": "WAYCROSS"
              },
              {
                  "name": "SNELLVILLE",
                  "label": "SNELLVILLE",
                  "value": "SNELLVILLE"
              },
              {
                  "name": "BLAIRSVILLE",
                  "label": "BLAIRSVILLE",
                  "value": "BLAIRSVILLE"
              },
              {
                  "name": "DALLAS",
                  "label": "DALLAS",
                  "value": "DALLAS"
              },
              {
                  "name": "DOUGLAS",
                  "label": "DOUGLAS",
                  "value": "DOUGLAS"
              },
              {
                  "name": "GRIFFIN",
                  "label": "GRIFFIN",
                  "value": "GRIFFIN"
              },
              {
                  "name": "EATONTON",
                  "label": "EATONTON",
                  "value": "EATONTON"
              },
              {
                  "name": "DULUTH",
                  "label": "DULUTH",
                  "value": "DULUTH"
              },
              {
                  "name": "SMYRNA",
                  "label": "SMYRNA",
                  "value": "SMYRNA"
              },
              {
                  "name": "METTER",
                  "label": "METTER",
                  "value": "METTER"
              },
              {
                  "name": "ROME",
                  "label": "ROME",
                  "value": "ROME"
              },
              {
                  "name": "HAWKINSVILLE",
                  "label": "HAWKINSVILLE",
                  "value": "HAWKINSVILLE"
              },
              {
                  "name": "SWAINSBORO",
                  "label": "SWAINSBORO",
                  "value": "SWAINSBORO"
              },
              {
                  "name": "KENNESAW",
                  "label": "KENNESAW",
                  "value": "KENNESAW"
              },
              {
                  "name": "THOMASVILLE",
                  "label": "THOMASVILLE",
                  "value": "THOMASVILLE"
              },
              {
                  "name": "COVINGTON",
                  "label": "COVINGTON",
                  "value": "COVINGTON"
              },
              {
                  "name": "CANTON",
                  "label": "CANTON",
                  "value": "CANTON"
              },
              {
                  "name": "BRUNSWICK",
                  "label": "BRUNSWICK",
                  "value": "BRUNSWICK"
              },
              {
                  "name": "SAINT MARYS",
                  "label": "SAINT MARYS",
                  "value": "SAINT MARYS"
              },
              {
                  "name": "CARTERSVILLE",
                  "label": "CARTERSVILLE",
                  "value": "CARTERSVILLE"
              },
              {
                  "name": "SPRINGFIELD",
                  "label": "SPRINGFIELD",
                  "value": "SPRINGFIELD"
              },
              {
                  "name": "HIRAM",
                  "label": "HIRAM",
                  "value": "HIRAM"
              },
              {
                  "name": "JASPER",
                  "label": "JASPER",
                  "value": "JASPER"
              },
              {
                  "name": "VIDALIA",
                  "label": "VIDALIA",
                  "value": "VIDALIA"
              },
              {
                  "name": "ST SIMONS ISLAND",
                  "label": "ST SIMONS ISLAND",
                  "value": "ST SIMONS ISLAND"
              },
              {
                  "name": "STONE MOUNTAIN",
                  "label": "STONE MOUNTAIN",
                  "value": "STONE MOUNTAIN"
              },
              {
                  "name": "THOMASTON",
                  "label": "THOMASTON",
                  "value": "THOMASTON"
              },
              {
                  "name": "CORDELE",
                  "label": "CORDELE",
                  "value": "CORDELE"
              },
              {
                  "name": "SANDY SPRINGS",
                  "label": "SANDY SPRINGS",
                  "value": "SANDY SPRINGS"
              },
              {
                  "name": "MCDONOUGH",
                  "label": "MCDONOUGH",
                  "value": "MCDONOUGH"
              },
              {
                  "name": "FORT BENNING",
                  "label": "FORT BENNING",
                  "value": "FORT BENNING"
              },
              {
                  "name": "GREENSBORO",
                  "label": "GREENSBORO",
                  "value": "GREENSBORO"
              },
              {
                  "name": "BROOKS",
                  "label": "BROOKS",
                  "value": "BROOKS"
              }
          ]
      },
      {
          "name": "KY",
          "label": "Kentucky",
          "value": "KY",
          "cities": [
              {
                  "name": "LOUISVILLE",
                  "label": "LOUISVILLE",
                  "value": "LOUISVILLE"
              },
              {
                  "name": "GEORGETOWN",
                  "label": "GEORGETOWN",
                  "value": "GEORGETOWN"
              },
              {
                  "name": "BOWLING GREEN",
                  "label": "BOWLING GREEN",
                  "value": "BOWLING GREEN"
              },
              {
                  "name": "NICHOLASVILLE",
                  "label": "NICHOLASVILLE",
                  "value": "NICHOLASVILLE"
              },
              {
                  "name": "CORBIN",
                  "label": "CORBIN",
                  "value": "CORBIN"
              },
              {
                  "name": "ELIZABETHTOWN",
                  "label": "ELIZABETHTOWN",
                  "value": "ELIZABETHTOWN"
              },
              {
                  "name": "PADUCAH",
                  "label": "PADUCAH",
                  "value": "PADUCAH"
              },
              {
                  "name": "ASHLAND",
                  "label": "ASHLAND",
                  "value": "ASHLAND"
              },
              {
                  "name": "HOPKINSVILLE",
                  "label": "HOPKINSVILLE",
                  "value": "HOPKINSVILLE"
              },
              {
                  "name": "COLUMBIA",
                  "label": "COLUMBIA",
                  "value": "COLUMBIA"
              },
              {
                  "name": "LEXINGTON",
                  "label": "LEXINGTON",
                  "value": "LEXINGTON"
              },
              {
                  "name": "FLORENCE",
                  "label": "FLORENCE",
                  "value": "FLORENCE"
              },
              {
                  "name": "DANVILLE",
                  "label": "DANVILLE",
                  "value": "DANVILLE"
              },
              {
                  "name": "HAZARD",
                  "label": "HAZARD",
                  "value": "HAZARD"
              },
              {
                  "name": "PIKEVILLE",
                  "label": "PIKEVILLE",
                  "value": "PIKEVILLE"
              },
              {
                  "name": "CRESTWOOD",
                  "label": "CRESTWOOD",
                  "value": "CRESTWOOD"
              },
              {
                  "name": "MANCHESTER",
                  "label": "MANCHESTER",
                  "value": "MANCHESTER"
              },
              {
                  "name": "COVINGTON",
                  "label": "COVINGTON",
                  "value": "COVINGTON"
              },
              {
                  "name": "FRANKFORT",
                  "label": "FRANKFORT",
                  "value": "FRANKFORT"
              },
              {
                  "name": "HARLAN",
                  "label": "HARLAN",
                  "value": "HARLAN"
              },
              {
                  "name": "EDGEWOOD",
                  "label": "EDGEWOOD",
                  "value": "EDGEWOOD"
              },
              {
                  "name": "HARRODSBURG",
                  "label": "HARRODSBURG",
                  "value": "HARRODSBURG"
              },
              {
                  "name": "GLASGOW",
                  "label": "GLASGOW",
                  "value": "GLASGOW"
              },
              {
                  "name": "LEITCHFIELD",
                  "label": "LEITCHFIELD",
                  "value": "LEITCHFIELD"
              },
              {
                  "name": "FRANKLIN",
                  "label": "FRANKLIN",
                  "value": "FRANKLIN"
              },
              {
                  "name": "MOREHEAD",
                  "label": "MOREHEAD",
                  "value": "MOREHEAD"
              },
              {
                  "name": "HENDERSON",
                  "label": "HENDERSON",
                  "value": "HENDERSON"
              },
              {
                  "name": "MADISONVILLE",
                  "label": "MADISONVILLE",
                  "value": "MADISONVILLE"
              },
              {
                  "name": "SOMERSET",
                  "label": "SOMERSET",
                  "value": "SOMERSET"
              },
              {
                  "name": "MIDDLESBORO",
                  "label": "MIDDLESBORO",
                  "value": "MIDDLESBORO"
              },
              {
                  "name": "BARDSTOWN",
                  "label": "BARDSTOWN",
                  "value": "BARDSTOWN"
              },
              {
                  "name": "JACKSON",
                  "label": "JACKSON",
                  "value": "JACKSON"
              },
              {
                  "name": "SOUTH WILLIAMSON",
                  "label": "SOUTH WILLIAMSON",
                  "value": "SOUTH WILLIAMSON"
              },
              {
                  "name": "MOUNT VERNON",
                  "label": "MOUNT VERNON",
                  "value": "MOUNT VERNON"
              },
              {
                  "name": "OWENSBORO",
                  "label": "OWENSBORO",
                  "value": "OWENSBORO"
              },
              {
                  "name": "MURRAY",
                  "label": "MURRAY",
                  "value": "MURRAY"
              },
              {
                  "name": "SHELBYVILLE",
                  "label": "SHELBYVILLE",
                  "value": "SHELBYVILLE"
              },
              {
                  "name": "ERLANGER",
                  "label": "ERLANGER",
                  "value": "ERLANGER"
              },
              {
                  "name": "BRANDENBURG",
                  "label": "BRANDENBURG",
                  "value": "BRANDENBURG"
              },
              {
                  "name": "PRESTONSBURG",
                  "label": "PRESTONSBURG",
                  "value": "PRESTONSBURG"
              },
              {
                  "name": "HIGHLAND HEIGHTS",
                  "label": "HIGHLAND HEIGHTS",
                  "value": "HIGHLAND HEIGHTS"
              },
              {
                  "name": "LAGRANGE",
                  "label": "LAGRANGE",
                  "value": "LAGRANGE"
              },
              {
                  "name": "LONDON",
                  "label": "LONDON",
                  "value": "LONDON"
              },
              {
                  "name": "MAYSVILLE",
                  "label": "MAYSVILLE",
                  "value": "MAYSVILLE"
              },
              {
                  "name": "FT THOMAS",
                  "label": "FT THOMAS",
                  "value": "FT THOMAS"
              },
              {
                  "name": "MOUNT STERLING",
                  "label": "MOUNT STERLING",
                  "value": "MOUNT STERLING"
              },
              {
                  "name": "WINCHESTER",
                  "label": "WINCHESTER",
                  "value": "WINCHESTER"
              }
          ]
      },
      {
          "name": "NJ",
          "label": "New Jersey",
          "value": "NJ",
          "cities": [
              {
                  "name": "MOORESTOWN",
                  "label": "MOORESTOWN",
                  "value": "MOORESTOWN"
              },
              {
                  "name": "BELLEVILLE",
                  "label": "BELLEVILLE",
                  "value": "BELLEVILLE"
              },
              {
                  "name": "UNION",
                  "label": "UNION",
                  "value": "UNION"
              },
              {
                  "name": "BERKELEY HEIGHTS",
                  "label": "BERKELEY HEIGHTS",
                  "value": "BERKELEY HEIGHTS"
              },
              {
                  "name": "NEWARK",
                  "label": "NEWARK",
                  "value": "NEWARK"
              },
              {
                  "name": "BASKING RIDGE",
                  "label": "BASKING RIDGE",
                  "value": "BASKING RIDGE"
              },
              {
                  "name": "CAMDEN",
                  "label": "CAMDEN",
                  "value": "CAMDEN"
              },
              {
                  "name": "VINELAND",
                  "label": "VINELAND",
                  "value": "VINELAND"
              },
              {
                  "name": "VOORHEES",
                  "label": "VOORHEES",
                  "value": "VOORHEES"
              },
              {
                  "name": "WEST LONG BRANCH",
                  "label": "WEST LONG BRANCH",
                  "value": "WEST LONG BRANCH"
              },
              {
                  "name": "EAST ORANGE",
                  "label": "EAST ORANGE",
                  "value": "EAST ORANGE"
              },
              {
                  "name": "PATERSON",
                  "label": "PATERSON",
                  "value": "PATERSON"
              },
              {
                  "name": "PENNINGTON",
                  "label": "PENNINGTON",
                  "value": "PENNINGTON"
              },
              {
                  "name": "SOMERVILLE",
                  "label": "SOMERVILLE",
                  "value": "SOMERVILLE"
              },
              {
                  "name": "MOUNTAIN LAKES",
                  "label": "MOUNTAIN LAKES",
                  "value": "MOUNTAIN LAKES"
              },
              {
                  "name": "HACKENSACK",
                  "label": "HACKENSACK",
                  "value": "HACKENSACK"
              },
              {
                  "name": "MORRISTOWN",
                  "label": "MORRISTOWN",
                  "value": "MORRISTOWN"
              },
              {
                  "name": "MANAHAWKIN",
                  "label": "MANAHAWKIN",
                  "value": "MANAHAWKIN"
              },
              {
                  "name": "ELIZABETH",
                  "label": "ELIZABETH",
                  "value": "ELIZABETH"
              },
              {
                  "name": "ENGLEWOOD",
                  "label": "ENGLEWOOD",
                  "value": "ENGLEWOOD"
              },
              {
                  "name": "EGG HARBOR TOWNSHIP",
                  "label": "EGG HARBOR TOWNSHIP",
                  "value": "EGG HARBOR TOWNSHIP"
              },
              {
                  "name": "HOWELL",
                  "label": "HOWELL",
                  "value": "HOWELL"
              },
              {
                  "name": "EAST BRUNSWICK",
                  "label": "EAST BRUNSWICK",
                  "value": "EAST BRUNSWICK"
              },
              {
                  "name": "TOMS RIVER",
                  "label": "TOMS RIVER",
                  "value": "TOMS RIVER"
              },
              {
                  "name": "NEW BRUNSWICK",
                  "label": "NEW BRUNSWICK",
                  "value": "NEW BRUNSWICK"
              },
              {
                  "name": "JERSEY CITY",
                  "label": "JERSEY CITY",
                  "value": "JERSEY CITY"
              },
              {
                  "name": "WEST ORANGE",
                  "label": "WEST ORANGE",
                  "value": "WEST ORANGE"
              },
              {
                  "name": "MARMORA",
                  "label": "MARMORA",
                  "value": "MARMORA"
              },
              {
                  "name": "NORTHFIELD",
                  "label": "NORTHFIELD",
                  "value": "NORTHFIELD"
              },
              {
                  "name": "FLANDERS",
                  "label": "FLANDERS",
                  "value": "FLANDERS"
              },
              {
                  "name": "LITTLE SILVER",
                  "label": "LITTLE SILVER",
                  "value": "LITTLE SILVER"
              },
              {
                  "name": "SOMERSET",
                  "label": "SOMERSET",
                  "value": "SOMERSET"
              },
              {
                  "name": "ATLANTIC CITY",
                  "label": "ATLANTIC CITY",
                  "value": "ATLANTIC CITY"
              },
              {
                  "name": "HARRISON",
                  "label": "HARRISON",
                  "value": "HARRISON"
              },
              {
                  "name": "EDISON",
                  "label": "EDISON",
                  "value": "EDISON"
              },
              {
                  "name": "REDBANK",
                  "label": "REDBANK",
                  "value": "REDBANK"
              },
              {
                  "name": "MONROE TOWNSHIP",
                  "label": "MONROE TOWNSHIP",
                  "value": "MONROE TOWNSHIP"
              },
              {
                  "name": "BRICK",
                  "label": "BRICK",
                  "value": "BRICK"
              },
              {
                  "name": "CHERRY HILL",
                  "label": "CHERRY HILL",
                  "value": "CHERRY HILL"
              },
              {
                  "name": "MIDDLETOWN",
                  "label": "MIDDLETOWN",
                  "value": "MIDDLETOWN"
              },
              {
                  "name": "AVENEL",
                  "label": "AVENEL",
                  "value": "AVENEL"
              },
              {
                  "name": "NEPTUNE",
                  "label": "NEPTUNE",
                  "value": "NEPTUNE"
              },
              {
                  "name": "MONTVALE",
                  "label": "MONTVALE",
                  "value": "MONTVALE"
              },
              {
                  "name": "MULLICA HILL",
                  "label": "MULLICA HILL",
                  "value": "MULLICA HILL"
              },
              {
                  "name": "SEWELL",
                  "label": "SEWELL",
                  "value": "SEWELL"
              },
              {
                  "name": "FLEMINGTON",
                  "label": "FLEMINGTON",
                  "value": "FLEMINGTON"
              },
              {
                  "name": "LIVINGSTON",
                  "label": "LIVINGSTON",
                  "value": "LIVINGSTON"
              },
              {
                  "name": "ENGLEWOOD CLIFFS",
                  "label": "ENGLEWOOD CLIFFS",
                  "value": "ENGLEWOOD CLIFFS"
              },
              {
                  "name": "FREEHOLD",
                  "label": "FREEHOLD",
                  "value": "FREEHOLD"
              },
              {
                  "name": "CLIFTON",
                  "label": "CLIFTON",
                  "value": "CLIFTON"
              },
              {
                  "name": "LONG BRANCH",
                  "label": "LONG BRANCH",
                  "value": "LONG BRANCH"
              },
              {
                  "name": "TRENTON",
                  "label": "TRENTON",
                  "value": "TRENTON"
              },
              {
                  "name": "ISELIN",
                  "label": "ISELIN",
                  "value": "ISELIN"
              },
              {
                  "name": "LINWOOD",
                  "label": "LINWOOD",
                  "value": "LINWOOD"
              },
              {
                  "name": "AUDUBON",
                  "label": "AUDUBON",
                  "value": "AUDUBON"
              },
              {
                  "name": "GALLOWAY",
                  "label": "GALLOWAY",
                  "value": "GALLOWAY"
              },
              {
                  "name": "HILLSBOROUGH",
                  "label": "HILLSBOROUGH",
                  "value": "HILLSBOROUGH"
              },
              {
                  "name": "PHILLIPSBURG",
                  "label": "PHILLIPSBURG",
                  "value": "PHILLIPSBURG"
              },
              {
                  "name": "LAUREL SPRINGS",
                  "label": "LAUREL SPRINGS",
                  "value": "LAUREL SPRINGS"
              },
              {
                  "name": "WAYNE",
                  "label": "WAYNE",
                  "value": "WAYNE"
              },
              {
                  "name": "SUMMIT",
                  "label": "SUMMIT",
                  "value": "SUMMIT"
              },
              {
                  "name": "FORT LEE",
                  "label": "FORT LEE",
                  "value": "FORT LEE"
              },
              {
                  "name": "MICKLETON",
                  "label": "MICKLETON",
                  "value": "MICKLETON"
              },
              {
                  "name": "NORTH BERGEN",
                  "label": "NORTH BERGEN",
                  "value": "NORTH BERGEN"
              },
              {
                  "name": "TEANECK",
                  "label": "TEANECK",
                  "value": "TEANECK"
              },
              {
                  "name": "PARLIN",
                  "label": "PARLIN",
                  "value": "PARLIN"
              },
              {
                  "name": "BEACHWOOD",
                  "label": "BEACHWOOD",
                  "value": "BEACHWOOD"
              },
              {
                  "name": "WILLINGBORO",
                  "label": "WILLINGBORO",
                  "value": "WILLINGBORO"
              },
              {
                  "name": "SPRINGFIELD",
                  "label": "SPRINGFIELD",
                  "value": "SPRINGFIELD"
              },
              {
                  "name": "PASSAIC",
                  "label": "PASSAIC",
                  "value": "PASSAIC"
              },
              {
                  "name": "ROCKAWAY",
                  "label": "ROCKAWAY",
                  "value": "ROCKAWAY"
              },
              {
                  "name": "HOBOKEN",
                  "label": "HOBOKEN",
                  "value": "HOBOKEN"
              },
              {
                  "name": "NORTH BRUNSWICK",
                  "label": "NORTH BRUNSWICK",
                  "value": "NORTH BRUNSWICK"
              },
              {
                  "name": "PRINCETON",
                  "label": "PRINCETON",
                  "value": "PRINCETON"
              },
              {
                  "name": "TURNERSVILLE",
                  "label": "TURNERSVILLE",
                  "value": "TURNERSVILLE"
              },
              {
                  "name": "LYONS",
                  "label": "LYONS",
                  "value": "LYONS"
              },
              {
                  "name": "PLAINSBORO",
                  "label": "PLAINSBORO",
                  "value": "PLAINSBORO"
              },
              {
                  "name": "MT LAUREL",
                  "label": "MT LAUREL",
                  "value": "MT LAUREL"
              },
              {
                  "name": "RIDGEWOOD",
                  "label": "RIDGEWOOD",
                  "value": "RIDGEWOOD"
              },
              {
                  "name": "SPARTA",
                  "label": "SPARTA",
                  "value": "SPARTA"
              },
              {
                  "name": "RUTHERFORD",
                  "label": "RUTHERFORD",
                  "value": "RUTHERFORD"
              },
              {
                  "name": "FAIR LAWN",
                  "label": "FAIR LAWN",
                  "value": "FAIR LAWN"
              },
              {
                  "name": "RED BANK",
                  "label": "RED BANK",
                  "value": "RED BANK"
              },
              {
                  "name": "PARAMUS",
                  "label": "PARAMUS",
                  "value": "PARAMUS"
              },
              {
                  "name": "RUMSON",
                  "label": "RUMSON",
                  "value": "RUMSON"
              },
              {
                  "name": "KEANSBURG",
                  "label": "KEANSBURG",
                  "value": "KEANSBURG"
              },
              {
                  "name": "NEPTUNE TWP",
                  "label": "NEPTUNE TWP",
                  "value": "NEPTUNE TWP"
              },
              {
                  "name": "MONTCLAIR",
                  "label": "MONTCLAIR",
                  "value": "MONTCLAIR"
              },
              {
                  "name": "MARLTON",
                  "label": "MARLTON",
                  "value": "MARLTON"
              },
              {
                  "name": "SOMERS POINT",
                  "label": "SOMERS POINT",
                  "value": "SOMERS POINT"
              },
              {
                  "name": "WESTFIELD",
                  "label": "WESTFIELD",
                  "value": "WESTFIELD"
              },
              {
                  "name": "WESTWOOD",
                  "label": "WESTWOOD",
                  "value": "WESTWOOD"
              },
              {
                  "name": "POMPTON PLAINS",
                  "label": "POMPTON PLAINS",
                  "value": "POMPTON PLAINS"
              },
              {
                  "name": "LAWRENCEVILLE",
                  "label": "LAWRENCEVILLE",
                  "value": "LAWRENCEVILLE"
              },
              {
                  "name": "BOUND BROOK",
                  "label": "BOUND BROOK",
                  "value": "BOUND BROOK"
              },
              {
                  "name": "LAKEWOOD",
                  "label": "LAKEWOOD",
                  "value": "LAKEWOOD"
              },
              {
                  "name": "MARLBORO",
                  "label": "MARLBORO",
                  "value": "MARLBORO"
              },
              {
                  "name": "OCEAN",
                  "label": "OCEAN",
                  "value": "OCEAN"
              },
              {
                  "name": "WEST ATLANTIC CITY",
                  "label": "WEST ATLANTIC CITY",
                  "value": "WEST ATLANTIC CITY"
              },
              {
                  "name": "MOUNT HOLLY",
                  "label": "MOUNT HOLLY",
                  "value": "MOUNT HOLLY"
              },
              {
                  "name": "PRINCETON JUNCTION",
                  "label": "PRINCETON JUNCTION",
                  "value": "PRINCETON JUNCTION"
              },
              {
                  "name": "SWEDESBORO",
                  "label": "SWEDESBORO",
                  "value": "SWEDESBORO"
              },
              {
                  "name": "ROBBINSVILLE",
                  "label": "ROBBINSVILLE",
                  "value": "ROBBINSVILLE"
              },
              {
                  "name": "EAST WINDSOR",
                  "label": "EAST WINDSOR",
                  "value": "EAST WINDSOR"
              },
              {
                  "name": "LINCROFT",
                  "label": "LINCROFT",
                  "value": "LINCROFT"
              },
              {
                  "name": "FAIR HAVEN",
                  "label": "FAIR HAVEN",
                  "value": "FAIR HAVEN"
              },
              {
                  "name": "RAHWAY",
                  "label": "RAHWAY",
                  "value": "RAHWAY"
              },
              {
                  "name": "PERTH AMBOR",
                  "label": "PERTH AMBOR",
                  "value": "PERTH AMBOR"
              },
              {
                  "name": "EGG HARBOR TWP",
                  "label": "EGG HARBOR TWP",
                  "value": "EGG HARBOR TWP"
              },
              {
                  "name": "MAPLEWOOD",
                  "label": "MAPLEWOOD",
                  "value": "MAPLEWOOD"
              },
              {
                  "name": "CARTERET",
                  "label": "CARTERET",
                  "value": "CARTERET"
              },
              {
                  "name": "MAHWAH",
                  "label": "MAHWAH",
                  "value": "MAHWAH"
              },
              {
                  "name": "RARITAN",
                  "label": "RARITAN",
                  "value": "RARITAN"
              },
              {
                  "name": "MANASQUAN",
                  "label": "MANASQUAN",
                  "value": "MANASQUAN"
              },
              {
                  "name": "BAYONNE",
                  "label": "BAYONNE",
                  "value": "BAYONNE"
              },
              {
                  "name": "MILLVILLE",
                  "label": "MILLVILLE",
                  "value": "MILLVILLE"
              }
          ]
      },
      {
          "name": "WA",
          "label": "Washington",
          "value": "WA",
          "cities": [
              {
                  "name": "VANCOUVER",
                  "label": "VANCOUVER",
                  "value": "VANCOUVER"
              },
              {
                  "name": "SEATTLE",
                  "label": "SEATTLE",
                  "value": "SEATTLE"
              },
              {
                  "name": "TACOMA",
                  "label": "TACOMA",
                  "value": "TACOMA"
              },
              {
                  "name": "EVERETT",
                  "label": "EVERETT",
                  "value": "EVERETT"
              },
              {
                  "name": "JOINT BASE LEWI",
                  "label": "JOINT BASE LEWI",
                  "value": "JOINT BASE LEWI"
              },
              {
                  "name": "PORT TOWNSEND",
                  "label": "PORT TOWNSEND",
                  "value": "PORT TOWNSEND"
              },
              {
                  "name": "SUNNYSIDE",
                  "label": "SUNNYSIDE",
                  "value": "SUNNYSIDE"
              },
              {
                  "name": "OLYMPIA",
                  "label": "OLYMPIA",
                  "value": "OLYMPIA"
              },
              {
                  "name": "RICHLAND",
                  "label": "RICHLAND",
                  "value": "RICHLAND"
              },
              {
                  "name": "SPOKANE",
                  "label": "SPOKANE",
                  "value": "SPOKANE"
              },
              {
                  "name": "PUYALLUP",
                  "label": "PUYALLUP",
                  "value": "PUYALLUP"
              },
              {
                  "name": "PORT ORCHARD",
                  "label": "PORT ORCHARD",
                  "value": "PORT ORCHARD"
              },
              {
                  "name": "POULSBO",
                  "label": "POULSBO",
                  "value": "POULSBO"
              },
              {
                  "name": "FEDERAL WAY",
                  "label": "FEDERAL WAY",
                  "value": "FEDERAL WAY"
              },
              {
                  "name": "KIRKLAND",
                  "label": "KIRKLAND",
                  "value": "KIRKLAND"
              },
              {
                  "name": "LONGVIEW",
                  "label": "LONGVIEW",
                  "value": "LONGVIEW"
              },
              {
                  "name": "RENTON",
                  "label": "RENTON",
                  "value": "RENTON"
              },
              {
                  "name": "WALLA WALLA",
                  "label": "WALLA WALLA",
                  "value": "WALLA WALLA"
              },
              {
                  "name": "YAKIMA",
                  "label": "YAKIMA",
                  "value": "YAKIMA"
              },
              {
                  "name": "BELLINGHAM",
                  "label": "BELLINGHAM",
                  "value": "BELLINGHAM"
              },
              {
                  "name": "LYNNWOOD",
                  "label": "LYNNWOOD",
                  "value": "LYNNWOOD"
              },
              {
                  "name": "BREMERTON",
                  "label": "BREMERTON",
                  "value": "BREMERTON"
              },
              {
                  "name": "MOUNT VERNON",
                  "label": "MOUNT VERNON",
                  "value": "MOUNT VERNON"
              },
              {
                  "name": "CENTRALIA",
                  "label": "CENTRALIA",
                  "value": "CENTRALIA"
              },
              {
                  "name": "BELLEVUE",
                  "label": "BELLEVUE",
                  "value": "BELLEVUE"
              },
              {
                  "name": "AUBURN",
                  "label": "AUBURN",
                  "value": "AUBURN"
              },
              {
                  "name": "COLFAX",
                  "label": "COLFAX",
                  "value": "COLFAX"
              },
              {
                  "name": "EDMONDS",
                  "label": "EDMONDS",
                  "value": "EDMONDS"
              },
              {
                  "name": "KENNEWICK",
                  "label": "KENNEWICK",
                  "value": "KENNEWICK"
              },
              {
                  "name": "COUPEVILLE",
                  "label": "COUPEVILLE",
                  "value": "COUPEVILLE"
              },
              {
                  "name": "FIFE",
                  "label": "FIFE",
                  "value": "FIFE"
              },
              {
                  "name": "SEQUIM",
                  "label": "SEQUIM",
                  "value": "SEQUIM"
              },
              {
                  "name": "SEDRO WOOLLEY",
                  "label": "SEDRO WOOLLEY",
                  "value": "SEDRO WOOLLEY"
              },
              {
                  "name": "WENATCHEE",
                  "label": "WENATCHEE",
                  "value": "WENATCHEE"
              },
              {
                  "name": "LAKEWOOD",
                  "label": "LAKEWOOD",
                  "value": "LAKEWOOD"
              },
              {
                  "name": "CHATTAROY",
                  "label": "CHATTAROY",
                  "value": "CHATTAROY"
              },
              {
                  "name": "ISSAQUAH",
                  "label": "ISSAQUAH",
                  "value": "ISSAQUAH"
              },
              {
                  "name": "LACEY",
                  "label": "LACEY",
                  "value": "LACEY"
              },
              {
                  "name": "BURIEN",
                  "label": "BURIEN",
                  "value": "BURIEN"
              },
              {
                  "name": "RITZVILLE",
                  "label": "RITZVILLE",
                  "value": "RITZVILLE"
              },
              {
                  "name": "NEAH BAY",
                  "label": "NEAH BAY",
                  "value": "NEAH BAY"
              },
              {
                  "name": "BOTHELL",
                  "label": "BOTHELL",
                  "value": "BOTHELL"
              },
              {
                  "name": "WHITE SALMON",
                  "label": "WHITE SALMON",
                  "value": "WHITE SALMON"
              },
              {
                  "name": "SILVERDALE",
                  "label": "SILVERDALE",
                  "value": "SILVERDALE"
              },
              {
                  "name": "ANACORTES",
                  "label": "ANACORTES",
                  "value": "ANACORTES"
              },
              {
                  "name": "ABERDEEN",
                  "label": "ABERDEEN",
                  "value": "ABERDEEN"
              },
              {
                  "name": "COLLEGE PLACE",
                  "label": "COLLEGE PLACE",
                  "value": "COLLEGE PLACE"
              },
              {
                  "name": "GIG HARBOR",
                  "label": "GIG HARBOR",
                  "value": "GIG HARBOR"
              },
              {
                  "name": "MCCHORD AFB",
                  "label": "MCCHORD AFB",
                  "value": "MCCHORD AFB"
              },
              {
                  "name": "WOODINVILLE",
                  "label": "WOODINVILLE",
                  "value": "WOODINVILLE"
              },
              {
                  "name": "CHEHALIS",
                  "label": "CHEHALIS",
                  "value": "CHEHALIS"
              },
              {
                  "name": "SNOHOMISH",
                  "label": "SNOHOMISH",
                  "value": "SNOHOMISH"
              },
              {
                  "name": "SUMAS",
                  "label": "SUMAS",
                  "value": "SUMAS"
              }
          ]
      },
      {
          "name": "MD",
          "label": "Maryland",
          "value": "MD",
          "cities": [
              {
                  "name": "SALISBURY",
                  "label": "SALISBURY",
                  "value": "SALISBURY"
              },
              {
                  "name": "BALTIMORE",
                  "label": "BALTIMORE",
                  "value": "BALTIMORE"
              },
              {
                  "name": "TOWSON",
                  "label": "TOWSON",
                  "value": "TOWSON"
              },
              {
                  "name": "LANHAM",
                  "label": "LANHAM",
                  "value": "LANHAM"
              },
              {
                  "name": "COLUMBIA",
                  "label": "COLUMBIA",
                  "value": "COLUMBIA"
              },
              {
                  "name": "ROCKVILLE",
                  "label": "ROCKVILLE",
                  "value": "ROCKVILLE"
              },
              {
                  "name": "GLEN BURNIE",
                  "label": "GLEN BURNIE",
                  "value": "GLEN BURNIE"
              },
              {
                  "name": "ANNAPOLIS",
                  "label": "ANNAPOLIS",
                  "value": "ANNAPOLIS"
              },
              {
                  "name": "POTOMAC",
                  "label": "POTOMAC",
                  "value": "POTOMAC"
              },
              {
                  "name": "GREENBELT",
                  "label": "GREENBELT",
                  "value": "GREENBELT"
              },
              {
                  "name": "BETHESDA",
                  "label": "BETHESDA",
                  "value": "BETHESDA"
              },
              {
                  "name": "RIVERDALE",
                  "label": "RIVERDALE",
                  "value": "RIVERDALE"
              },
              {
                  "name": "SILVER SPRING",
                  "label": "SILVER SPRING",
                  "value": "SILVER SPRING"
              },
              {
                  "name": "WALDORF",
                  "label": "WALDORF",
                  "value": "WALDORF"
              },
              {
                  "name": "LARGO",
                  "label": "LARGO",
                  "value": "LARGO"
              },
              {
                  "name": "BERLIN",
                  "label": "BERLIN",
                  "value": "BERLIN"
              },
              {
                  "name": "LUTHERVILLE",
                  "label": "LUTHERVILLE",
                  "value": "LUTHERVILLE"
              },
              {
                  "name": "HAGERSTOWN",
                  "label": "HAGERSTOWN",
                  "value": "HAGERSTOWN"
              },
              {
                  "name": "BEL AIR",
                  "label": "BEL AIR",
                  "value": "BEL AIR"
              },
              {
                  "name": "LINTHICUM",
                  "label": "LINTHICUM",
                  "value": "LINTHICUM"
              },
              {
                  "name": "CATONSVILLE",
                  "label": "CATONSVILLE",
                  "value": "CATONSVILLE"
              },
              {
                  "name": "CLINTON",
                  "label": "CLINTON",
                  "value": "CLINTON"
              },
              {
                  "name": "PRINCE FREDERICK",
                  "label": "PRINCE FREDERICK",
                  "value": "PRINCE FREDERICK"
              },
              {
                  "name": "ROSEDALE",
                  "label": "ROSEDALE",
                  "value": "ROSEDALE"
              },
              {
                  "name": "WESTMINSTER",
                  "label": "WESTMINSTER",
                  "value": "WESTMINSTER"
              },
              {
                  "name": "FREDERICK",
                  "label": "FREDERICK",
                  "value": "FREDERICK"
              },
              {
                  "name": "NORTH BETHESDA",
                  "label": "NORTH BETHESDA",
                  "value": "NORTH BETHESDA"
              },
              {
                  "name": "CALIFORNIA",
                  "label": "CALIFORNIA",
                  "value": "CALIFORNIA"
              },
              {
                  "name": "EASTON",
                  "label": "EASTON",
                  "value": "EASTON"
              },
              {
                  "name": "OAKLAND",
                  "label": "OAKLAND",
                  "value": "OAKLAND"
              },
              {
                  "name": "CUMBERLAND",
                  "label": "CUMBERLAND",
                  "value": "CUMBERLAND"
              },
              {
                  "name": "CHEVERLY",
                  "label": "CHEVERLY",
                  "value": "CHEVERLY"
              },
              {
                  "name": "BOWIE",
                  "label": "BOWIE",
                  "value": "BOWIE"
              },
              {
                  "name": "HYATTSVILLE",
                  "label": "HYATTSVILLE",
                  "value": "HYATTSVILLE"
              },
              {
                  "name": "ELLICOTT CITY",
                  "label": "ELLICOTT CITY",
                  "value": "ELLICOTT CITY"
              },
              {
                  "name": "GERMANTOWN",
                  "label": "GERMANTOWN",
                  "value": "GERMANTOWN"
              },
              {
                  "name": "ELKTON",
                  "label": "ELKTON",
                  "value": "ELKTON"
              },
              {
                  "name": "GAITHERSBURG",
                  "label": "GAITHERSBURG",
                  "value": "GAITHERSBURG"
              },
              {
                  "name": "SEVERN",
                  "label": "SEVERN",
                  "value": "SEVERN"
              },
              {
                  "name": "OLNEY",
                  "label": "OLNEY",
                  "value": "OLNEY"
              },
              {
                  "name": "LAUREL",
                  "label": "LAUREL",
                  "value": "LAUREL"
              },
              {
                  "name": "KENSINGTON",
                  "label": "KENSINGTON",
                  "value": "KENSINGTON"
              },
              {
                  "name": "CHEVY CHASE",
                  "label": "CHEVY CHASE",
                  "value": "CHEVY CHASE"
              },
              {
                  "name": "HOLLYWOOD",
                  "label": "HOLLYWOOD",
                  "value": "HOLLYWOOD"
              },
              {
                  "name": "CLARKSVILLE",
                  "label": "CLARKSVILLE",
                  "value": "CLARKSVILLE"
              }
          ]
      },
      {
          "name": "MN",
          "label": "Minnesota",
          "value": "MN",
          "cities": [
              {
                  "name": "ROCHESTER",
                  "label": "ROCHESTER",
                  "value": "ROCHESTER"
              },
              {
                  "name": "FARIBAULT",
                  "label": "FARIBAULT",
                  "value": "FARIBAULT"
              },
              {
                  "name": "ST LOUIS PARK",
                  "label": "ST LOUIS PARK",
                  "value": "ST LOUIS PARK"
              },
              {
                  "name": "MINNEAPOLIS",
                  "label": "MINNEAPOLIS",
                  "value": "MINNEAPOLIS"
              },
              {
                  "name": "BURNSVILLE",
                  "label": "BURNSVILLE",
                  "value": "BURNSVILLE"
              },
              {
                  "name": "RED WING",
                  "label": "RED WING",
                  "value": "RED WING"
              },
              {
                  "name": "HIBBING",
                  "label": "HIBBING",
                  "value": "HIBBING"
              },
              {
                  "name": "ST PAUL",
                  "label": "ST PAUL",
                  "value": "ST PAUL"
              },
              {
                  "name": "SAINT CLOUD",
                  "label": "SAINT CLOUD",
                  "value": "SAINT CLOUD"
              },
              {
                  "name": "BRAINERD",
                  "label": "BRAINERD",
                  "value": "BRAINERD"
              },
              {
                  "name": "DULUTH",
                  "label": "DULUTH",
                  "value": "DULUTH"
              },
              {
                  "name": "BEMIDJI",
                  "label": "BEMIDJI",
                  "value": "BEMIDJI"
              },
              {
                  "name": "PLYMOUTH",
                  "label": "PLYMOUTH",
                  "value": "PLYMOUTH"
              },
              {
                  "name": "COON RAPIDS",
                  "label": "COON RAPIDS",
                  "value": "COON RAPIDS"
              },
              {
                  "name": "EDINA",
                  "label": "EDINA",
                  "value": "EDINA"
              },
              {
                  "name": "SAINT PAUL",
                  "label": "SAINT PAUL",
                  "value": "SAINT PAUL"
              },
              {
                  "name": "ALBERT LEA",
                  "label": "ALBERT LEA",
                  "value": "ALBERT LEA"
              },
              {
                  "name": "ROSEVILLE",
                  "label": "ROSEVILLE",
                  "value": "ROSEVILLE"
              },
              {
                  "name": "ST CLOUD",
                  "label": "ST CLOUD",
                  "value": "ST CLOUD"
              },
              {
                  "name": "MONTEVIDEO",
                  "label": "MONTEVIDEO",
                  "value": "MONTEVIDEO"
              },
              {
                  "name": "MARSHALL",
                  "label": "MARSHALL",
                  "value": "MARSHALL"
              },
              {
                  "name": "WILLMAR",
                  "label": "WILLMAR",
                  "value": "WILLMAR"
              },
              {
                  "name": "WORTHINGTON",
                  "label": "WORTHINGTON",
                  "value": "WORTHINGTON"
              },
              {
                  "name": "MAPLEWOOD",
                  "label": "MAPLEWOOD",
                  "value": "MAPLEWOOD"
              },
              {
                  "name": "ROBBINSDALE",
                  "label": "ROBBINSDALE",
                  "value": "ROBBINSDALE"
              },
              {
                  "name": "CHASKA",
                  "label": "CHASKA",
                  "value": "CHASKA"
              },
              {
                  "name": "E GRAND FORKS",
                  "label": "E GRAND FORKS",
                  "value": "E GRAND FORKS"
              },
              {
                  "name": "MANKATO",
                  "label": "MANKATO",
                  "value": "MANKATO"
              },
              {
                  "name": "ALEXANDRIA",
                  "label": "ALEXANDRIA",
                  "value": "ALEXANDRIA"
              },
              {
                  "name": "WOODBURY",
                  "label": "WOODBURY",
                  "value": "WOODBURY"
              },
              {
                  "name": "STILLWATER",
                  "label": "STILLWATER",
                  "value": "STILLWATER"
              },
              {
                  "name": "CLOQUET",
                  "label": "CLOQUET",
                  "value": "CLOQUET"
              },
              {
                  "name": "GRAND RAPIDS",
                  "label": "GRAND RAPIDS",
                  "value": "GRAND RAPIDS"
              },
              {
                  "name": "ROGERS",
                  "label": "ROGERS",
                  "value": "ROGERS"
              },
              {
                  "name": "EDEN PRAIRIE",
                  "label": "EDEN PRAIRIE",
                  "value": "EDEN PRAIRIE"
              },
              {
                  "name": "HUTCHINSON",
                  "label": "HUTCHINSON",
                  "value": "HUTCHINSON"
              },
              {
                  "name": "MORA",
                  "label": "MORA",
                  "value": "MORA"
              },
              {
                  "name": "PAYNESVILLE",
                  "label": "PAYNESVILLE",
                  "value": "PAYNESVILLE"
              },
              {
                  "name": "FRIDLEY",
                  "label": "FRIDLEY",
                  "value": "FRIDLEY"
              },
              {
                  "name": "MENDOTA HEIGHTS",
                  "label": "MENDOTA HEIGHTS",
                  "value": "MENDOTA HEIGHTS"
              },
              {
                  "name": "CROOKSTON",
                  "label": "CROOKSTON",
                  "value": "CROOKSTON"
              },
              {
                  "name": "CAMBRIDGE",
                  "label": "CAMBRIDGE",
                  "value": "CAMBRIDGE"
              },
              {
                  "name": "THIEF RIVER FALLS",
                  "label": "THIEF RIVER FALLS",
                  "value": "THIEF RIVER FALLS"
              },
              {
                  "name": "OAKDALE",
                  "label": "OAKDALE",
                  "value": "OAKDALE"
              },
              {
                  "name": "ROSEAU",
                  "label": "ROSEAU",
                  "value": "ROSEAU"
              },
              {
                  "name": "SAINT LOUIS PARK",
                  "label": "SAINT LOUIS PARK",
                  "value": "SAINT LOUIS PARK"
              },
              {
                  "name": "LAKEVILLE",
                  "label": "LAKEVILLE",
                  "value": "LAKEVILLE"
              },
              {
                  "name": "WYOMING",
                  "label": "WYOMING",
                  "value": "WYOMING"
              },
              {
                  "name": "BLOOMINGTON",
                  "label": "BLOOMINGTON",
                  "value": "BLOOMINGTON"
              },
              {
                  "name": "OSSEO",
                  "label": "OSSEO",
                  "value": "OSSEO"
              },
              {
                  "name": "NORTHFIELD",
                  "label": "NORTHFIELD",
                  "value": "NORTHFIELD"
              },
              {
                  "name": "FERGUS FALLS",
                  "label": "FERGUS FALLS",
                  "value": "FERGUS FALLS"
              },
              {
                  "name": "RICHFIELD",
                  "label": "RICHFIELD",
                  "value": "RICHFIELD"
              },
              {
                  "name": "BUFFALO",
                  "label": "BUFFALO",
                  "value": "BUFFALO"
              },
              {
                  "name": "BAXTER",
                  "label": "BAXTER",
                  "value": "BAXTER"
              },
              {
                  "name": "WACONIA",
                  "label": "WACONIA",
                  "value": "WACONIA"
              },
              {
                  "name": "WINONA",
                  "label": "WINONA",
                  "value": "WINONA"
              },
              {
                  "name": "FAIRMONT",
                  "label": "FAIRMONT",
                  "value": "FAIRMONT"
              }
          ]
      },
      {
          "name": "ME",
          "label": "Maine",
          "value": "ME",
          "cities": [
              {
                  "name": "SOUTH PORTLAND",
                  "label": "SOUTH PORTLAND",
                  "value": "SOUTH PORTLAND"
              },
              {
                  "name": "FARMINGTON",
                  "label": "FARMINGTON",
                  "value": "FARMINGTON"
              },
              {
                  "name": "BREWER",
                  "label": "BREWER",
                  "value": "BREWER"
              },
              {
                  "name": "SCARBOROUGH",
                  "label": "SCARBOROUGH",
                  "value": "SCARBOROUGH"
              },
              {
                  "name": "AUGUSTA",
                  "label": "AUGUSTA",
                  "value": "AUGUSTA"
              },
              {
                  "name": "SANFORD",
                  "label": "SANFORD",
                  "value": "SANFORD"
              },
              {
                  "name": "PORTLAND",
                  "label": "PORTLAND",
                  "value": "PORTLAND"
              },
              {
                  "name": "YORK",
                  "label": "YORK",
                  "value": "YORK"
              },
              {
                  "name": "CARIBOU",
                  "label": "CARIBOU",
                  "value": "CARIBOU"
              },
              {
                  "name": "DAMARISCOTTA",
                  "label": "DAMARISCOTTA",
                  "value": "DAMARISCOTTA"
              },
              {
                  "name": "SIDNEY",
                  "label": "SIDNEY",
                  "value": "SIDNEY"
              },
              {
                  "name": "LEWISTON",
                  "label": "LEWISTON",
                  "value": "LEWISTON"
              },
              {
                  "name": "BANGOR",
                  "label": "BANGOR",
                  "value": "BANGOR"
              },
              {
                  "name": "CORINTH",
                  "label": "CORINTH",
                  "value": "CORINTH"
              },
              {
                  "name": "KENNEBUNK",
                  "label": "KENNEBUNK",
                  "value": "KENNEBUNK"
              },
              {
                  "name": "BRUNSWICK",
                  "label": "BRUNSWICK",
                  "value": "BRUNSWICK"
              },
              {
                  "name": "PRESQUE ISLE",
                  "label": "PRESQUE ISLE",
                  "value": "PRESQUE ISLE"
              },
              {
                  "name": "BELFAST",
                  "label": "BELFAST",
                  "value": "BELFAST"
              },
              {
                  "name": "MILLINOCKET",
                  "label": "MILLINOCKET",
                  "value": "MILLINOCKET"
              },
              {
                  "name": "TOPSHAM",
                  "label": "TOPSHAM",
                  "value": "TOPSHAM"
              },
              {
                  "name": "WALDOBORO",
                  "label": "WALDOBORO",
                  "value": "WALDOBORO"
              },
              {
                  "name": "BRIDGTON",
                  "label": "BRIDGTON",
                  "value": "BRIDGTON"
              },
              {
                  "name": "ROCKPORT",
                  "label": "ROCKPORT",
                  "value": "ROCKPORT"
              },
              {
                  "name": "ELLSWORTH",
                  "label": "ELLSWORTH",
                  "value": "ELLSWORTH"
              },
              {
                  "name": "WATERVILLE",
                  "label": "WATERVILLE",
                  "value": "WATERVILLE"
              }
          ]
      },
      {
          "name": "MO",
          "label": "Missouri",
          "value": "MO",
          "cities": [
              {
                  "name": "JEFFERSON CITY",
                  "label": "JEFFERSON CITY",
                  "value": "JEFFERSON CITY"
              },
              {
                  "name": "SPRINGFIELD",
                  "label": "SPRINGFIELD",
                  "value": "SPRINGFIELD"
              },
              {
                  "name": "CHESTERFIELD",
                  "label": "CHESTERFIELD",
                  "value": "CHESTERFIELD"
              },
              {
                  "name": "JOPLIN",
                  "label": "JOPLIN",
                  "value": "JOPLIN"
              },
              {
                  "name": "INDEPENDENCE",
                  "label": "INDEPENDENCE",
                  "value": "INDEPENDENCE"
              },
              {
                  "name": "SAINT LOUIS",
                  "label": "SAINT LOUIS",
                  "value": "SAINT LOUIS"
              },
              {
                  "name": "ST. LOUIS",
                  "label": "ST. LOUIS",
                  "value": "ST. LOUIS"
              },
              {
                  "name": "LAKE SAINT LOUIS",
                  "label": "LAKE SAINT LOUIS",
                  "value": "LAKE SAINT LOUIS"
              },
              {
                  "name": "KANSAS CITY",
                  "label": "KANSAS CITY",
                  "value": "KANSAS CITY"
              },
              {
                  "name": "COLUMBIA",
                  "label": "COLUMBIA",
                  "value": "COLUMBIA"
              },
              {
                  "name": "WEST PLAINS",
                  "label": "WEST PLAINS",
                  "value": "WEST PLAINS"
              },
              {
                  "name": "SEDALIA",
                  "label": "SEDALIA",
                  "value": "SEDALIA"
              },
              {
                  "name": "LIBERTY",
                  "label": "LIBERTY",
                  "value": "LIBERTY"
              },
              {
                  "name": "ST LOUIS",
                  "label": "ST LOUIS",
                  "value": "ST LOUIS"
              },
              {
                  "name": "MONETT",
                  "label": "MONETT",
                  "value": "MONETT"
              },
              {
                  "name": "SAINT JOSEPH",
                  "label": "SAINT JOSEPH",
                  "value": "SAINT JOSEPH"
              },
              {
                  "name": "ROLLA",
                  "label": "ROLLA",
                  "value": "ROLLA"
              },
              {
                  "name": "POPLAR BLUFF",
                  "label": "POPLAR BLUFF",
                  "value": "POPLAR BLUFF"
              },
              {
                  "name": "BRANSON",
                  "label": "BRANSON",
                  "value": "BRANSON"
              },
              {
                  "name": "HANNIBAL",
                  "label": "HANNIBAL",
                  "value": "HANNIBAL"
              },
              {
                  "name": "NORTH KANSAS CITY",
                  "label": "NORTH KANSAS CITY",
                  "value": "NORTH KANSAS CITY"
              },
              {
                  "name": "FENTON",
                  "label": "FENTON",
                  "value": "FENTON"
              },
              {
                  "name": "ST CHARLES",
                  "label": "ST CHARLES",
                  "value": "ST CHARLES"
              },
              {
                  "name": "AURORA",
                  "label": "AURORA",
                  "value": "AURORA"
              },
              {
                  "name": "LEES SUMMIT",
                  "label": "LEES SUMMIT",
                  "value": "LEES SUMMIT"
              },
              {
                  "name": "MEXICO",
                  "label": "MEXICO",
                  "value": "MEXICO"
              },
              {
                  "name": "FESTUS",
                  "label": "FESTUS",
                  "value": "FESTUS"
              },
              {
                  "name": "BRIDGETON",
                  "label": "BRIDGETON",
                  "value": "BRIDGETON"
              },
              {
                  "name": "CAPE GIRARDEAU",
                  "label": "CAPE GIRARDEAU",
                  "value": "CAPE GIRARDEAU"
              },
              {
                  "name": "WASHINGTON",
                  "label": "WASHINGTON",
                  "value": "WASHINGTON"
              },
              {
                  "name": "OSAGE BEACH",
                  "label": "OSAGE BEACH",
                  "value": "OSAGE BEACH"
              },
              {
                  "name": "N KANSAS CITY",
                  "label": "N KANSAS CITY",
                  "value": "N KANSAS CITY"
              },
              {
                  "name": "CLARKSON VALLEY",
                  "label": "CLARKSON VALLEY",
                  "value": "CLARKSON VALLEY"
              },
              {
                  "name": "BLUE SPRINGS",
                  "label": "BLUE SPRINGS",
                  "value": "BLUE SPRINGS"
              },
              {
                  "name": "CASSVILLE",
                  "label": "CASSVILLE",
                  "value": "CASSVILLE"
              },
              {
                  "name": "FARMINGTON",
                  "label": "FARMINGTON",
                  "value": "FARMINGTON"
              },
              {
                  "name": "BOLIVAR",
                  "label": "BOLIVAR",
                  "value": "BOLIVAR"
              },
              {
                  "name": "AFFTON",
                  "label": "AFFTON",
                  "value": "AFFTON"
              },
              {
                  "name": "RAYMORE",
                  "label": "RAYMORE",
                  "value": "RAYMORE"
              },
              {
                  "name": "SAINT CHARLES",
                  "label": "SAINT CHARLES",
                  "value": "SAINT CHARLES"
              },
              {
                  "name": "CLINTON",
                  "label": "CLINTON",
                  "value": "CLINTON"
              },
              {
                  "name": "CREVE COEUR",
                  "label": "CREVE COEUR",
                  "value": "CREVE COEUR"
              },
              {
                  "name": "CHILLICOTHE",
                  "label": "CHILLICOTHE",
                  "value": "CHILLICOTHE"
              },
              {
                  "name": "LEBANON",
                  "label": "LEBANON",
                  "value": "LEBANON"
              },
              {
                  "name": "MEMPHIS",
                  "label": "MEMPHIS",
                  "value": "MEMPHIS"
              },
              {
                  "name": "SAINT PETERS",
                  "label": "SAINT PETERS",
                  "value": "SAINT PETERS"
              }
          ]
      },
      {
          "name": "VT",
          "label": "Vermont",
          "value": "VT",
          "cities": [
              {
                  "name": "BURLINGTON",
                  "label": "BURLINGTON",
                  "value": "BURLINGTON"
              },
              {
                  "name": "BERLIN",
                  "label": "BERLIN",
                  "value": "BERLIN"
              },
              {
                  "name": "NEWPORT",
                  "label": "NEWPORT",
                  "value": "NEWPORT"
              },
              {
                  "name": "RUTLAND",
                  "label": "RUTLAND",
                  "value": "RUTLAND"
              },
              {
                  "name": "MORRISVILLE",
                  "label": "MORRISVILLE",
                  "value": "MORRISVILLE"
              },
              {
                  "name": "WINDSOR",
                  "label": "WINDSOR",
                  "value": "WINDSOR"
              },
              {
                  "name": "BENNINGTON",
                  "label": "BENNINGTON",
                  "value": "BENNINGTON"
              },
              {
                  "name": "WHITE RIVER JUNCTION",
                  "label": "WHITE RIVER JUNCTION",
                  "value": "WHITE RIVER JUNCTION"
              },
              {
                  "name": "COLCHESTER",
                  "label": "COLCHESTER",
                  "value": "COLCHESTER"
              }
          ]
      },
      {
          "name": "VA",
          "label": "Virginia",
          "value": "VA",
          "cities": [
              {
                  "name": "CHESAPEAKE",
                  "label": "CHESAPEAKE",
                  "value": "CHESAPEAKE"
              },
              {
                  "name": "FARMVILLE",
                  "label": "FARMVILLE",
                  "value": "FARMVILLE"
              },
              {
                  "name": "ARLINGTON",
                  "label": "ARLINGTON",
                  "value": "ARLINGTON"
              },
              {
                  "name": "CHARLOTTESVILLE",
                  "label": "CHARLOTTESVILLE",
                  "value": "CHARLOTTESVILLE"
              },
              {
                  "name": "NEWPORT NEWS",
                  "label": "NEWPORT NEWS",
                  "value": "NEWPORT NEWS"
              },
              {
                  "name": "HARRISONBURG",
                  "label": "HARRISONBURG",
                  "value": "HARRISONBURG"
              },
              {
                  "name": "LEESBURG",
                  "label": "LEESBURG",
                  "value": "LEESBURG"
              },
              {
                  "name": "WOODBRIDGE",
                  "label": "WOODBRIDGE",
                  "value": "WOODBRIDGE"
              },
              {
                  "name": "BRAMBLETON",
                  "label": "BRAMBLETON",
                  "value": "BRAMBLETON"
              },
              {
                  "name": "WINCHESTER",
                  "label": "WINCHESTER",
                  "value": "WINCHESTER"
              },
              {
                  "name": "CHESTER",
                  "label": "CHESTER",
                  "value": "CHESTER"
              },
              {
                  "name": "RICHMOND",
                  "label": "RICHMOND",
                  "value": "RICHMOND"
              },
              {
                  "name": "FREDERICKSBURG",
                  "label": "FREDERICKSBURG",
                  "value": "FREDERICKSBURG"
              },
              {
                  "name": "NORFOLK",
                  "label": "NORFOLK",
                  "value": "NORFOLK"
              },
              {
                  "name": "ABINGDON",
                  "label": "ABINGDON",
                  "value": "ABINGDON"
              },
              {
                  "name": "SUFFOLK",
                  "label": "SUFFOLK",
                  "value": "SUFFOLK"
              },
              {
                  "name": "FAIRFAX",
                  "label": "FAIRFAX",
                  "value": "FAIRFAX"
              },
              {
                  "name": "FALLS CHURCH",
                  "label": "FALLS CHURCH",
                  "value": "FALLS CHURCH"
              },
              {
                  "name": "MECHANICSVILLE",
                  "label": "MECHANICSVILLE",
                  "value": "MECHANICSVILLE"
              },
              {
                  "name": "HILTONS",
                  "label": "HILTONS",
                  "value": "HILTONS"
              },
              {
                  "name": "ALEXANDRIA",
                  "label": "ALEXANDRIA",
                  "value": "ALEXANDRIA"
              },
              {
                  "name": "PETERSBURG",
                  "label": "PETERSBURG",
                  "value": "PETERSBURG"
              },
              {
                  "name": "VIRGINIA BEACH",
                  "label": "VIRGINIA BEACH",
                  "value": "VIRGINIA BEACH"
              },
              {
                  "name": "FISHERSVILLE",
                  "label": "FISHERSVILLE",
                  "value": "FISHERSVILLE"
              },
              {
                  "name": "ROANOKE",
                  "label": "ROANOKE",
                  "value": "ROANOKE"
              },
              {
                  "name": "WILLIAMSBURG",
                  "label": "WILLIAMSBURG",
                  "value": "WILLIAMSBURG"
              },
              {
                  "name": "RESTON",
                  "label": "RESTON",
                  "value": "RESTON"
              },
              {
                  "name": "HAMPTON",
                  "label": "HAMPTON",
                  "value": "HAMPTON"
              },
              {
                  "name": "CULPEPER",
                  "label": "CULPEPER",
                  "value": "CULPEPER"
              },
              {
                  "name": "DANVILLE",
                  "label": "DANVILLE",
                  "value": "DANVILLE"
              },
              {
                  "name": "POUNDING MILL",
                  "label": "POUNDING MILL",
                  "value": "POUNDING MILL"
              },
              {
                  "name": "VIENNA",
                  "label": "VIENNA",
                  "value": "VIENNA"
              },
              {
                  "name": "FORT BELVOIR",
                  "label": "FORT BELVOIR",
                  "value": "FORT BELVOIR"
              },
              {
                  "name": "LYNCHBURG",
                  "label": "LYNCHBURG",
                  "value": "LYNCHBURG"
              },
              {
                  "name": "BRISTOL",
                  "label": "BRISTOL",
                  "value": "BRISTOL"
              },
              {
                  "name": "TAPPAHANNOCK",
                  "label": "TAPPAHANNOCK",
                  "value": "TAPPAHANNOCK"
              },
              {
                  "name": "HUDDLESTON",
                  "label": "HUDDLESTON",
                  "value": "HUDDLESTON"
              },
              {
                  "name": "LORTON",
                  "label": "LORTON",
                  "value": "LORTON"
              },
              {
                  "name": "GAINESVILLE",
                  "label": "GAINESVILLE",
                  "value": "GAINESVILLE"
              },
              {
                  "name": "WYTHEVILLE",
                  "label": "WYTHEVILLE",
                  "value": "WYTHEVILLE"
              },
              {
                  "name": "MARTINSVILLE",
                  "label": "MARTINSVILLE",
                  "value": "MARTINSVILLE"
              },
              {
                  "name": "PORTSMOUTH",
                  "label": "PORTSMOUTH",
                  "value": "PORTSMOUTH"
              },
              {
                  "name": "MIDLOTHIAN",
                  "label": "MIDLOTHIAN",
                  "value": "MIDLOTHIAN"
              },
              {
                  "name": "SPOTSYLVANIA",
                  "label": "SPOTSYLVANIA",
                  "value": "SPOTSYLVANIA"
              },
              {
                  "name": "MCLEAN",
                  "label": "MCLEAN",
                  "value": "MCLEAN"
              },
              {
                  "name": "SOUTH BOSTON",
                  "label": "SOUTH BOSTON",
                  "value": "SOUTH BOSTON"
              },
              {
                  "name": "SALEM",
                  "label": "SALEM",
                  "value": "SALEM"
              },
              {
                  "name": "ASHBURN",
                  "label": "ASHBURN",
                  "value": "ASHBURN"
              },
              {
                  "name": "NORTON",
                  "label": "NORTON",
                  "value": "NORTON"
              },
              {
                  "name": "PULASKI",
                  "label": "PULASKI",
                  "value": "PULASKI"
              },
              {
                  "name": "PRINCE GEORGE",
                  "label": "PRINCE GEORGE",
                  "value": "PRINCE GEORGE"
              },
              {
                  "name": "QUANTICO",
                  "label": "QUANTICO",
                  "value": "QUANTICO"
              },
              {
                  "name": "FORT LEE",
                  "label": "FORT LEE",
                  "value": "FORT LEE"
              },
              {
                  "name": "STAFFORD",
                  "label": "STAFFORD",
                  "value": "STAFFORD"
              },
              {
                  "name": "RICHLANDS",
                  "label": "RICHLANDS",
                  "value": "RICHLANDS"
              },
              {
                  "name": "CONCORD",
                  "label": "CONCORD",
                  "value": "CONCORD"
              },
              {
                  "name": "BLACKSBURG",
                  "label": "BLACKSBURG",
                  "value": "BLACKSBURG"
              },
              {
                  "name": "VINTON",
                  "label": "VINTON",
                  "value": "VINTON"
              },
              {
                  "name": "KILMARNOCK",
                  "label": "KILMARNOCK",
                  "value": "KILMARNOCK"
              },
              {
                  "name": "NORTH DINWIDDIE",
                  "label": "NORTH DINWIDDIE",
                  "value": "NORTH DINWIDDIE"
              },
              {
                  "name": "GLOUCESTER",
                  "label": "GLOUCESTER",
                  "value": "GLOUCESTER"
              },
              {
                  "name": "MANASSAS",
                  "label": "MANASSAS",
                  "value": "MANASSAS"
              },
              {
                  "name": "GLEN ALLEN",
                  "label": "GLEN ALLEN",
                  "value": "GLEN ALLEN"
              }
          ]
      },
      {
          "name": "MS",
          "label": "Mississippi",
          "value": "MS",
          "cities": [
              {
                  "name": "NEW ALBANY",
                  "label": "NEW ALBANY",
                  "value": "NEW ALBANY"
              },
              {
                  "name": "GRENADA",
                  "label": "GRENADA",
                  "value": "GRENADA"
              },
              {
                  "name": "STARKVILLE",
                  "label": "STARKVILLE",
                  "value": "STARKVILLE"
              },
              {
                  "name": "JACKSON",
                  "label": "JACKSON",
                  "value": "JACKSON"
              },
              {
                  "name": "LAUREL",
                  "label": "LAUREL",
                  "value": "LAUREL"
              },
              {
                  "name": "SOUTHAVEN",
                  "label": "SOUTHAVEN",
                  "value": "SOUTHAVEN"
              },
              {
                  "name": "GULFPORT",
                  "label": "GULFPORT",
                  "value": "GULFPORT"
              },
              {
                  "name": "HATTIESBURG",
                  "label": "HATTIESBURG",
                  "value": "HATTIESBURG"
              },
              {
                  "name": "WATER VALLEY",
                  "label": "WATER VALLEY",
                  "value": "WATER VALLEY"
              },
              {
                  "name": "PASCAGOULA",
                  "label": "PASCAGOULA",
                  "value": "PASCAGOULA"
              },
              {
                  "name": "GAUTIER",
                  "label": "GAUTIER",
                  "value": "GAUTIER"
              },
              {
                  "name": "OLIVE BRANCH",
                  "label": "OLIVE BRANCH",
                  "value": "OLIVE BRANCH"
              },
              {
                  "name": "GREENVILLE",
                  "label": "GREENVILLE",
                  "value": "GREENVILLE"
              },
              {
                  "name": "BAY ST LOUIS",
                  "label": "BAY ST LOUIS",
                  "value": "BAY ST LOUIS"
              },
              {
                  "name": "GREENWOOD",
                  "label": "GREENWOOD",
                  "value": "GREENWOOD"
              },
              {
                  "name": "OXFORD",
                  "label": "OXFORD",
                  "value": "OXFORD"
              },
              {
                  "name": "MERIDIAN",
                  "label": "MERIDIAN",
                  "value": "MERIDIAN"
              },
              {
                  "name": "RIDGELAND",
                  "label": "RIDGELAND",
                  "value": "RIDGELAND"
              },
              {
                  "name": "KEESLER AFB",
                  "label": "KEESLER AFB",
                  "value": "KEESLER AFB"
              },
              {
                  "name": "TUPELO",
                  "label": "TUPELO",
                  "value": "TUPELO"
              },
              {
                  "name": "NATCHEZ",
                  "label": "NATCHEZ",
                  "value": "NATCHEZ"
              },
              {
                  "name": "CLARKSDALE",
                  "label": "CLARKSDALE",
                  "value": "CLARKSDALE"
              },
              {
                  "name": "CLINTON",
                  "label": "CLINTON",
                  "value": "CLINTON"
              },
              {
                  "name": "CORINTH",
                  "label": "CORINTH",
                  "value": "CORINTH"
              },
              {
                  "name": "MC COMB",
                  "label": "MC COMB",
                  "value": "MC COMB"
              },
              {
                  "name": "OCEAN SPRINGS",
                  "label": "OCEAN SPRINGS",
                  "value": "OCEAN SPRINGS"
              },
              {
                  "name": "COLUMBUS",
                  "label": "COLUMBUS",
                  "value": "COLUMBUS"
              },
              {
                  "name": "MCCOMB",
                  "label": "MCCOMB",
                  "value": "MCCOMB"
              },
              {
                  "name": "WEST POINT",
                  "label": "WEST POINT",
                  "value": "WEST POINT"
              }
          ]
      },
      {
          "name": "AL",
          "label": "Alabama",
          "value": "AL",
          "cities": [
              {
                  "name": "MONTGOMERY",
                  "label": "MONTGOMERY",
                  "value": "MONTGOMERY"
              },
              {
                  "name": "BIRMINGHAM",
                  "label": "BIRMINGHAM",
                  "value": "BIRMINGHAM"
              },
              {
                  "name": "TUSCALOOSA",
                  "label": "TUSCALOOSA",
                  "value": "TUSCALOOSA"
              },
              {
                  "name": "HUNTSVILLE",
                  "label": "HUNTSVILLE",
                  "value": "HUNTSVILLE"
              },
              {
                  "name": "JASPER",
                  "label": "JASPER",
                  "value": "JASPER"
              },
              {
                  "name": "MOBILE",
                  "label": "MOBILE",
                  "value": "MOBILE"
              },
              {
                  "name": "VESTAVIA",
                  "label": "VESTAVIA",
                  "value": "VESTAVIA"
              },
              {
                  "name": "ALABASTER",
                  "label": "ALABASTER",
                  "value": "ALABASTER"
              },
              {
                  "name": "DOTHAN",
                  "label": "DOTHAN",
                  "value": "DOTHAN"
              },
              {
                  "name": "GADSDEN",
                  "label": "GADSDEN",
                  "value": "GADSDEN"
              },
              {
                  "name": "BOAZ",
                  "label": "BOAZ",
                  "value": "BOAZ"
              },
              {
                  "name": "OPELIKA",
                  "label": "OPELIKA",
                  "value": "OPELIKA"
              },
              {
                  "name": "MONROEVILLE",
                  "label": "MONROEVILLE",
                  "value": "MONROEVILLE"
              },
              {
                  "name": "DAPHNE",
                  "label": "DAPHNE",
                  "value": "DAPHNE"
              },
              {
                  "name": "DECATUR",
                  "label": "DECATUR",
                  "value": "DECATUR"
              },
              {
                  "name": "MUSCLE SHOALS",
                  "label": "MUSCLE SHOALS",
                  "value": "MUSCLE SHOALS"
              },
              {
                  "name": "HOMEWOOD",
                  "label": "HOMEWOOD",
                  "value": "HOMEWOOD"
              },
              {
                  "name": "ANNISTON",
                  "label": "ANNISTON",
                  "value": "ANNISTON"
              },
              {
                  "name": "FLORENCE",
                  "label": "FLORENCE",
                  "value": "FLORENCE"
              },
              {
                  "name": "GREENVILLE",
                  "label": "GREENVILLE",
                  "value": "GREENVILLE"
              },
              {
                  "name": "BREWTON",
                  "label": "BREWTON",
                  "value": "BREWTON"
              },
              {
                  "name": "HOOVER",
                  "label": "HOOVER",
                  "value": "HOOVER"
              },
              {
                  "name": "FOLEY",
                  "label": "FOLEY",
                  "value": "FOLEY"
              },
              {
                  "name": "PRATTVILLE",
                  "label": "PRATTVILLE",
                  "value": "PRATTVILLE"
              },
              {
                  "name": "NORTHPORT",
                  "label": "NORTHPORT",
                  "value": "NORTHPORT"
              },
              {
                  "name": "WEDOWEE",
                  "label": "WEDOWEE",
                  "value": "WEDOWEE"
              },
              {
                  "name": "WINFIELD",
                  "label": "WINFIELD",
                  "value": "WINFIELD"
              },
              {
                  "name": "ALEXANDER CITY",
                  "label": "ALEXANDER CITY",
                  "value": "ALEXANDER CITY"
              },
              {
                  "name": "PHENIX CITY",
                  "label": "PHENIX CITY",
                  "value": "PHENIX CITY"
              },
              {
                  "name": "IRVINGTON",
                  "label": "IRVINGTON",
                  "value": "IRVINGTON"
              },
              {
                  "name": "CULLMAN",
                  "label": "CULLMAN",
                  "value": "CULLMAN"
              },
              {
                  "name": "BESSEMER",
                  "label": "BESSEMER",
                  "value": "BESSEMER"
              },
              {
                  "name": "ALBERTVILLE",
                  "label": "ALBERTVILLE",
                  "value": "ALBERTVILLE"
              },
              {
                  "name": "AUBURN",
                  "label": "AUBURN",
                  "value": "AUBURN"
              },
              {
                  "name": "FT RUCKER",
                  "label": "FT RUCKER",
                  "value": "FT RUCKER"
              },
              {
                  "name": "GLENCOE",
                  "label": "GLENCOE",
                  "value": "GLENCOE"
              },
              {
                  "name": "ASHLAND",
                  "label": "ASHLAND",
                  "value": "ASHLAND"
              },
              {
                  "name": "ATTALLA",
                  "label": "ATTALLA",
                  "value": "ATTALLA"
              }
          ]
      },
      {
          "name": "MT",
          "label": "Montana",
          "value": "MT",
          "cities": [
              {
                  "name": "HELENA",
                  "label": "HELENA",
                  "value": "HELENA"
              },
              {
                  "name": "BILLINGS",
                  "label": "BILLINGS",
                  "value": "BILLINGS"
              },
              {
                  "name": "KALISPELL",
                  "label": "KALISPELL",
                  "value": "KALISPELL"
              },
              {
                  "name": "MISSOULA",
                  "label": "MISSOULA",
                  "value": "MISSOULA"
              },
              {
                  "name": "BOZEMAN",
                  "label": "BOZEMAN",
                  "value": "BOZEMAN"
              },
              {
                  "name": "SIDNEY",
                  "label": "SIDNEY",
                  "value": "SIDNEY"
              },
              {
                  "name": "GREAT FALLS",
                  "label": "GREAT FALLS",
                  "value": "GREAT FALLS"
              },
              {
                  "name": "HAVRE",
                  "label": "HAVRE",
                  "value": "HAVRE"
              },
              {
                  "name": "BUTTE",
                  "label": "BUTTE",
                  "value": "BUTTE"
              },
              {
                  "name": "SHERIDAN",
                  "label": "SHERIDAN",
                  "value": "SHERIDAN"
              },
              {
                  "name": "MILES CITY",
                  "label": "MILES CITY",
                  "value": "MILES CITY"
              }
          ]
      },
      {
          "name": "IN",
          "label": "Indiana",
          "value": "IN",
          "cities": [
              {
                  "name": "AVON",
                  "label": "AVON",
                  "value": "AVON"
              },
              {
                  "name": "MUNSTER",
                  "label": "MUNSTER",
                  "value": "MUNSTER"
              },
              {
                  "name": "LAFAYETTE",
                  "label": "LAFAYETTE",
                  "value": "LAFAYETTE"
              },
              {
                  "name": "FRANKLIN",
                  "label": "FRANKLIN",
                  "value": "FRANKLIN"
              },
              {
                  "name": "MISHAWAKA",
                  "label": "MISHAWAKA",
                  "value": "MISHAWAKA"
              },
              {
                  "name": "SOUTH BEND",
                  "label": "SOUTH BEND",
                  "value": "SOUTH BEND"
              },
              {
                  "name": "FLOYDS KNOBS",
                  "label": "FLOYDS KNOBS",
                  "value": "FLOYDS KNOBS"
              },
              {
                  "name": "INDIANAPOLIS",
                  "label": "INDIANAPOLIS",
                  "value": "INDIANAPOLIS"
              },
              {
                  "name": "NEWBURGH",
                  "label": "NEWBURGH",
                  "value": "NEWBURGH"
              },
              {
                  "name": "NOBLESVILLE",
                  "label": "NOBLESVILLE",
                  "value": "NOBLESVILLE"
              },
              {
                  "name": "FORT WAYNE",
                  "label": "FORT WAYNE",
                  "value": "FORT WAYNE"
              },
              {
                  "name": "VALPARAISO",
                  "label": "VALPARAISO",
                  "value": "VALPARAISO"
              },
              {
                  "name": "DYER",
                  "label": "DYER",
                  "value": "DYER"
              },
              {
                  "name": "LA PORTE",
                  "label": "LA PORTE",
                  "value": "LA PORTE"
              },
              {
                  "name": "GREENFIELD",
                  "label": "GREENFIELD",
                  "value": "GREENFIELD"
              },
              {
                  "name": "EVANSVILLE",
                  "label": "EVANSVILLE",
                  "value": "EVANSVILLE"
              },
              {
                  "name": "GOSHEN",
                  "label": "GOSHEN",
                  "value": "GOSHEN"
              },
              {
                  "name": "ELKHART",
                  "label": "ELKHART",
                  "value": "ELKHART"
              },
              {
                  "name": "MARTINSVILLE",
                  "label": "MARTINSVILLE",
                  "value": "MARTINSVILLE"
              },
              {
                  "name": "CORYDON",
                  "label": "CORYDON",
                  "value": "CORYDON"
              },
              {
                  "name": "ANDERSON",
                  "label": "ANDERSON",
                  "value": "ANDERSON"
              },
              {
                  "name": "MICHIGAN CITY",
                  "label": "MICHIGAN CITY",
                  "value": "MICHIGAN CITY"
              },
              {
                  "name": "TERRE HAUTE",
                  "label": "TERRE HAUTE",
                  "value": "TERRE HAUTE"
              },
              {
                  "name": "DANVILLE",
                  "label": "DANVILLE",
                  "value": "DANVILLE"
              },
              {
                  "name": "BOONVILLE",
                  "label": "BOONVILLE",
                  "value": "BOONVILLE"
              },
              {
                  "name": "GREENDALE",
                  "label": "GREENDALE",
                  "value": "GREENDALE"
              },
              {
                  "name": "JASPER",
                  "label": "JASPER",
                  "value": "JASPER"
              },
              {
                  "name": "MUNCIE",
                  "label": "MUNCIE",
                  "value": "MUNCIE"
              },
              {
                  "name": "RICHMOND",
                  "label": "RICHMOND",
                  "value": "RICHMOND"
              },
              {
                  "name": "CROWN POINT",
                  "label": "CROWN POINT",
                  "value": "CROWN POINT"
              },
              {
                  "name": "BLOOMINGTON",
                  "label": "BLOOMINGTON",
                  "value": "BLOOMINGTON"
              },
              {
                  "name": "VINCENNES",
                  "label": "VINCENNES",
                  "value": "VINCENNES"
              },
              {
                  "name": "COLUMBUS",
                  "label": "COLUMBUS",
                  "value": "COLUMBUS"
              },
              {
                  "name": "BEDFORD",
                  "label": "BEDFORD",
                  "value": "BEDFORD"
              },
              {
                  "name": "NEW ALBANY",
                  "label": "NEW ALBANY",
                  "value": "NEW ALBANY"
              },
              {
                  "name": "SEYMOUR",
                  "label": "SEYMOUR",
                  "value": "SEYMOUR"
              },
              {
                  "name": "MERRILLVILLE",
                  "label": "MERRILLVILLE",
                  "value": "MERRILLVILLE"
              },
              {
                  "name": "HOBART",
                  "label": "HOBART",
                  "value": "HOBART"
              },
              {
                  "name": "KOKOMO",
                  "label": "KOKOMO",
                  "value": "KOKOMO"
              },
              {
                  "name": "MARION",
                  "label": "MARION",
                  "value": "MARION"
              },
              {
                  "name": "FISHERS",
                  "label": "FISHERS",
                  "value": "FISHERS"
              },
              {
                  "name": "MOORESVILLE",
                  "label": "MOORESVILLE",
                  "value": "MOORESVILLE"
              },
              {
                  "name": "CLARKSVILLE",
                  "label": "CLARKSVILLE",
                  "value": "CLARKSVILLE"
              },
              {
                  "name": "LAWRENCEBURG",
                  "label": "LAWRENCEBURG",
                  "value": "LAWRENCEBURG"
              },
              {
                  "name": "JEFFERSONVILLE",
                  "label": "JEFFERSONVILLE",
                  "value": "JEFFERSONVILLE"
              },
              {
                  "name": "SHELBYVILLE",
                  "label": "SHELBYVILLE",
                  "value": "SHELBYVILLE"
              },
              {
                  "name": "WESTVILLE",
                  "label": "WESTVILLE",
                  "value": "WESTVILLE"
              },
              {
                  "name": "WARSAW",
                  "label": "WARSAW",
                  "value": "WARSAW"
              },
              {
                  "name": "GREENWOOD",
                  "label": "GREENWOOD",
                  "value": "GREENWOOD"
              },
              {
                  "name": "CARMEL",
                  "label": "CARMEL",
                  "value": "CARMEL"
              }
          ]
      },
      {
          "name": "DE",
          "label": "Delaware",
          "value": "DE",
          "cities": [
              {
                  "name": "NEWARK",
                  "label": "NEWARK",
                  "value": "NEWARK"
              },
              {
                  "name": "WILMINGTON",
                  "label": "WILMINGTON",
                  "value": "WILMINGTON"
              },
              {
                  "name": "REHOBOTH BEACH",
                  "label": "REHOBOTH BEACH",
                  "value": "REHOBOTH BEACH"
              },
              {
                  "name": "LEWES",
                  "label": "LEWES",
                  "value": "LEWES"
              },
              {
                  "name": "DOVER",
                  "label": "DOVER",
                  "value": "DOVER"
              },
              {
                  "name": "MILFORD",
                  "label": "MILFORD",
                  "value": "MILFORD"
              },
              {
                  "name": "NEWPORT",
                  "label": "NEWPORT",
                  "value": "NEWPORT"
              },
              {
                  "name": "SEAFORD",
                  "label": "SEAFORD",
                  "value": "SEAFORD"
              }
          ]
      },
      {
          "name": "NV",
          "label": "Nevada",
          "value": "NV",
          "cities": [
              {
                  "name": "LAS VEGAS",
                  "label": "LAS VEGAS",
                  "value": "LAS VEGAS"
              },
              {
                  "name": "RENO",
                  "label": "RENO",
                  "value": "RENO"
              },
              {
                  "name": "HENDERSON",
                  "label": "HENDERSON",
                  "value": "HENDERSON"
              },
              {
                  "name": "GARDNERVILLE",
                  "label": "GARDNERVILLE",
                  "value": "GARDNERVILLE"
              },
              {
                  "name": "Carson City",
                  "label": "Carson City",
                  "value": "Carson City"
              },
              {
                  "name": "PAHRUMP",
                  "label": "PAHRUMP",
                  "value": "PAHRUMP"
              },
              {
                  "name": "SPARKS",
                  "label": "SPARKS",
                  "value": "SPARKS"
              },
              {
                  "name": "NELLIS AFB",
                  "label": "NELLIS AFB",
                  "value": "NELLIS AFB"
              },
              {
                  "name": "FERNLEY",
                  "label": "FERNLEY",
                  "value": "FERNLEY"
              }
          ]
      },
      {
          "name": "OR",
          "label": "Oregon",
          "value": "OR",
          "cities": [
              {
                  "name": "CORVALLIS",
                  "label": "CORVALLIS",
                  "value": "CORVALLIS"
              },
              {
                  "name": "SALEM",
                  "label": "SALEM",
                  "value": "SALEM"
              },
              {
                  "name": "HOOD RIVER",
                  "label": "HOOD RIVER",
                  "value": "HOOD RIVER"
              },
              {
                  "name": "BEND",
                  "label": "BEND",
                  "value": "BEND"
              },
              {
                  "name": "MEDFORD",
                  "label": "MEDFORD",
                  "value": "MEDFORD"
              },
              {
                  "name": "THE DALLES",
                  "label": "THE DALLES",
                  "value": "THE DALLES"
              },
              {
                  "name": "EUGENE",
                  "label": "EUGENE",
                  "value": "EUGENE"
              },
              {
                  "name": "PORTLAND",
                  "label": "PORTLAND",
                  "value": "PORTLAND"
              },
              {
                  "name": "GRANTS PASS",
                  "label": "GRANTS PASS",
                  "value": "GRANTS PASS"
              },
              {
                  "name": "OREGON CITY",
                  "label": "OREGON CITY",
                  "value": "OREGON CITY"
              },
              {
                  "name": "ROSEBURG",
                  "label": "ROSEBURG",
                  "value": "ROSEBURG"
              },
              {
                  "name": "TIGARD",
                  "label": "TIGARD",
                  "value": "TIGARD"
              },
              {
                  "name": "COOS BAY",
                  "label": "COOS BAY",
                  "value": "COOS BAY"
              },
              {
                  "name": "KLAMATH FALLS",
                  "label": "KLAMATH FALLS",
                  "value": "KLAMATH FALLS"
              },
              {
                  "name": "PORRTLAND",
                  "label": "PORRTLAND",
                  "value": "PORRTLAND"
              },
              {
                  "name": "LAKE OSWEGO",
                  "label": "LAKE OSWEGO",
                  "value": "LAKE OSWEGO"
              },
              {
                  "name": "ASTORIA",
                  "label": "ASTORIA",
                  "value": "ASTORIA"
              },
              {
                  "name": "CLACKAMAS",
                  "label": "CLACKAMAS",
                  "value": "CLACKAMAS"
              },
              {
                  "name": "FLORENCE",
                  "label": "FLORENCE",
                  "value": "FLORENCE"
              },
              {
                  "name": "PENDLETON",
                  "label": "PENDLETON",
                  "value": "PENDLETON"
              },
              {
                  "name": "LA GRANDE",
                  "label": "LA GRANDE",
                  "value": "LA GRANDE"
              },
              {
                  "name": "SEASIDE",
                  "label": "SEASIDE",
                  "value": "SEASIDE"
              },
              {
                  "name": "MCMINNVILLE",
                  "label": "MCMINNVILLE",
                  "value": "MCMINNVILLE"
              },
              {
                  "name": "NEWBERG",
                  "label": "NEWBERG",
                  "value": "NEWBERG"
              },
              {
                  "name": "JACKSONVILLE",
                  "label": "JACKSONVILLE",
                  "value": "JACKSONVILLE"
              },
              {
                  "name": "BROOKINGS",
                  "label": "BROOKINGS",
                  "value": "BROOKINGS"
              },
              {
                  "name": "HILLSBORO",
                  "label": "HILLSBORO",
                  "value": "HILLSBORO"
              },
              {
                  "name": "TILLAMOOK",
                  "label": "TILLAMOOK",
                  "value": "TILLAMOOK"
              },
              {
                  "name": "NORTH BEND",
                  "label": "NORTH BEND",
                  "value": "NORTH BEND"
              },
              {
                  "name": "BEAVERTON",
                  "label": "BEAVERTON",
                  "value": "BEAVERTON"
              },
              {
                  "name": "BANDON",
                  "label": "BANDON",
                  "value": "BANDON"
              },
              {
                  "name": "TUALATIN",
                  "label": "TUALATIN",
                  "value": "TUALATIN"
              },
              {
                  "name": "COTTAGE GROVE",
                  "label": "COTTAGE GROVE",
                  "value": "COTTAGE GROVE"
              }
          ]
      },
      {
          "name": "AR",
          "label": "Arkansas",
          "value": "AR",
          "cities": [
              {
                  "name": "JONESBORO",
                  "label": "JONESBORO",
                  "value": "JONESBORO"
              },
              {
                  "name": "LITTLE ROCK",
                  "label": "LITTLE ROCK",
                  "value": "LITTLE ROCK"
              },
              {
                  "name": "ROGERS",
                  "label": "ROGERS",
                  "value": "ROGERS"
              },
              {
                  "name": "SPRINGDALE",
                  "label": "SPRINGDALE",
                  "value": "SPRINGDALE"
              },
              {
                  "name": "FAYETTEVILLE",
                  "label": "FAYETTEVILLE",
                  "value": "FAYETTEVILLE"
              },
              {
                  "name": "HOT SPRINGS NP",
                  "label": "HOT SPRINGS NP",
                  "value": "HOT SPRINGS NP"
              },
              {
                  "name": "BLYTHEVILLE",
                  "label": "BLYTHEVILLE",
                  "value": "BLYTHEVILLE"
              },
              {
                  "name": "CROSSETT",
                  "label": "CROSSETT",
                  "value": "CROSSETT"
              },
              {
                  "name": "HARRISON",
                  "label": "HARRISON",
                  "value": "HARRISON"
              },
              {
                  "name": "FORT SMITH",
                  "label": "FORT SMITH",
                  "value": "FORT SMITH"
              },
              {
                  "name": "NORTH LITTLE ROCK",
                  "label": "NORTH LITTLE ROCK",
                  "value": "NORTH LITTLE ROCK"
              },
              {
                  "name": "WEST MEMPHIS",
                  "label": "WEST MEMPHIS",
                  "value": "WEST MEMPHIS"
              },
              {
                  "name": "HOT SPRINGS",
                  "label": "HOT SPRINGS",
                  "value": "HOT SPRINGS"
              },
              {
                  "name": "BATESVILLE",
                  "label": "BATESVILLE",
                  "value": "BATESVILLE"
              },
              {
                  "name": "RUSSELLVILLE",
                  "label": "RUSSELLVILLE",
                  "value": "RUSSELLVILLE"
              },
              {
                  "name": "MAGNOLIA",
                  "label": "MAGNOLIA",
                  "value": "MAGNOLIA"
              },
              {
                  "name": "SEARCY",
                  "label": "SEARCY",
                  "value": "SEARCY"
              },
              {
                  "name": "MONTICELLO",
                  "label": "MONTICELLO",
                  "value": "MONTICELLO"
              },
              {
                  "name": "PINE BLUFF",
                  "label": "PINE BLUFF",
                  "value": "PINE BLUFF"
              },
              {
                  "name": "BENTON",
                  "label": "BENTON",
                  "value": "BENTON"
              },
              {
                  "name": "SILOAM SPRINGS",
                  "label": "SILOAM SPRINGS",
                  "value": "SILOAM SPRINGS"
              },
              {
                  "name": "NEWPORT",
                  "label": "NEWPORT",
                  "value": "NEWPORT"
              },
              {
                  "name": "LOWELL",
                  "label": "LOWELL",
                  "value": "LOWELL"
              },
              {
                  "name": "CONWAY",
                  "label": "CONWAY",
                  "value": "CONWAY"
              },
              {
                  "name": "EL DORADO",
                  "label": "EL DORADO",
                  "value": "EL DORADO"
              },
              {
                  "name": "MOUNTAIN HOME",
                  "label": "MOUNTAIN HOME",
                  "value": "MOUNTAIN HOME"
              },
              {
                  "name": "BLACK ROCK",
                  "label": "BLACK ROCK",
                  "value": "BLACK ROCK"
              },
              {
                  "name": "BERRYVILLE",
                  "label": "BERRYVILLE",
                  "value": "BERRYVILLE"
              },
              {
                  "name": "JUDSONIA",
                  "label": "JUDSONIA",
                  "value": "JUDSONIA"
              },
              {
                  "name": "BENTONVILLE",
                  "label": "BENTONVILLE",
                  "value": "BENTONVILLE"
              }
          ]
      },
      {
          "name": "KS",
          "label": "Kansas",
          "value": "KS",
          "cities": [
              {
                  "name": "TOPEKA",
                  "label": "TOPEKA",
                  "value": "TOPEKA"
              },
              {
                  "name": "GARDEN CITY",
                  "label": "GARDEN CITY",
                  "value": "GARDEN CITY"
              },
              {
                  "name": "OVERLAND PARK",
                  "label": "OVERLAND PARK",
                  "value": "OVERLAND PARK"
              },
              {
                  "name": "LAWRENCE",
                  "label": "LAWRENCE",
                  "value": "LAWRENCE"
              },
              {
                  "name": "HUTCHINSON",
                  "label": "HUTCHINSON",
                  "value": "HUTCHINSON"
              },
              {
                  "name": "SHAWNEE MISSION",
                  "label": "SHAWNEE MISSION",
                  "value": "SHAWNEE MISSION"
              },
              {
                  "name": "PITTSBURG",
                  "label": "PITTSBURG",
                  "value": "PITTSBURG"
              },
              {
                  "name": "WICHITA",
                  "label": "WICHITA",
                  "value": "WICHITA"
              },
              {
                  "name": "KANSAS CITY",
                  "label": "KANSAS CITY",
                  "value": "KANSAS CITY"
              },
              {
                  "name": "EMPORIA",
                  "label": "EMPORIA",
                  "value": "EMPORIA"
              },
              {
                  "name": "NEWTON",
                  "label": "NEWTON",
                  "value": "NEWTON"
              },
              {
                  "name": "LENEXA",
                  "label": "LENEXA",
                  "value": "LENEXA"
              },
              {
                  "name": "OLATHE",
                  "label": "OLATHE",
                  "value": "OLATHE"
              },
              {
                  "name": "WESTWOOD",
                  "label": "WESTWOOD",
                  "value": "WESTWOOD"
              },
              {
                  "name": "GREAT BEND",
                  "label": "GREAT BEND",
                  "value": "GREAT BEND"
              },
              {
                  "name": "SALINA",
                  "label": "SALINA",
                  "value": "SALINA"
              },
              {
                  "name": "HAYS",
                  "label": "HAYS",
                  "value": "HAYS"
              },
              {
                  "name": "LEAWOOD",
                  "label": "LEAWOOD",
                  "value": "LEAWOOD"
              },
              {
                  "name": "MANHATTAN",
                  "label": "MANHATTAN",
                  "value": "MANHATTAN"
              },
              {
                  "name": "DODGE CITY",
                  "label": "DODGE CITY",
                  "value": "DODGE CITY"
              },
              {
                  "name": "PRATT",
                  "label": "PRATT",
                  "value": "PRATT"
              },
              {
                  "name": "FORT LEAVENWORTH",
                  "label": "FORT LEAVENWORTH",
                  "value": "FORT LEAVENWORTH"
              },
              {
                  "name": "HOISINGTON",
                  "label": "HOISINGTON",
                  "value": "HOISINGTON"
              },
              {
                  "name": "COFFEYVILLE",
                  "label": "COFFEYVILLE",
                  "value": "COFFEYVILLE"
              },
              {
                  "name": "WINFIELD",
                  "label": "WINFIELD",
                  "value": "WINFIELD"
              },
              {
                  "name": "NEODESHA",
                  "label": "NEODESHA",
                  "value": "NEODESHA"
              }
          ]
      },
      {
          "name": "RI",
          "label": "Rhode Island",
          "value": "RI",
          "cities": [
              {
                  "name": "WAKEFIELD",
                  "label": "WAKEFIELD",
                  "value": "WAKEFIELD"
              },
              {
                  "name": "PROVIDENCE",
                  "label": "PROVIDENCE",
                  "value": "PROVIDENCE"
              },
              {
                  "name": "WESTERLY",
                  "label": "WESTERLY",
                  "value": "WESTERLY"
              },
              {
                  "name": "EAST GREENWICH",
                  "label": "EAST GREENWICH",
                  "value": "EAST GREENWICH"
              },
              {
                  "name": "WOONSOCKET",
                  "label": "WOONSOCKET",
                  "value": "WOONSOCKET"
              },
              {
                  "name": "CRANSTON",
                  "label": "CRANSTON",
                  "value": "CRANSTON"
              },
              {
                  "name": "WARWICK",
                  "label": "WARWICK",
                  "value": "WARWICK"
              },
              {
                  "name": "EAST PROVIDENCE",
                  "label": "EAST PROVIDENCE",
                  "value": "EAST PROVIDENCE"
              },
              {
                  "name": "COVENTRY",
                  "label": "COVENTRY",
                  "value": "COVENTRY"
              },
              {
                  "name": "RIVERSIDE",
                  "label": "RIVERSIDE",
                  "value": "RIVERSIDE"
              },
              {
                  "name": "NORTH SMITHFIELD",
                  "label": "NORTH SMITHFIELD",
                  "value": "NORTH SMITHFIELD"
              },
              {
                  "name": "LINCOLN",
                  "label": "LINCOLN",
                  "value": "LINCOLN"
              }
          ]
      },
      {
          "name": "NE",
          "label": "Nebraska",
          "value": "NE",
          "cities": [
              {
                  "name": "KEARNEY",
                  "label": "KEARNEY",
                  "value": "KEARNEY"
              },
              {
                  "name": "GRAND ISLAND",
                  "label": "GRAND ISLAND",
                  "value": "GRAND ISLAND"
              },
              {
                  "name": "LINCOLN",
                  "label": "LINCOLN",
                  "value": "LINCOLN"
              },
              {
                  "name": "OMAHA",
                  "label": "OMAHA",
                  "value": "OMAHA"
              },
              {
                  "name": "SCHUYLER",
                  "label": "SCHUYLER",
                  "value": "SCHUYLER"
              },
              {
                  "name": "NORTH PLATTE",
                  "label": "NORTH PLATTE",
                  "value": "NORTH PLATTE"
              },
              {
                  "name": "NORFOLK",
                  "label": "NORFOLK",
                  "value": "NORFOLK"
              },
              {
                  "name": "HASTINGS",
                  "label": "HASTINGS",
                  "value": "HASTINGS"
              },
              {
                  "name": "BASSETT",
                  "label": "BASSETT",
                  "value": "BASSETT"
              },
              {
                  "name": "HEBRON",
                  "label": "HEBRON",
                  "value": "HEBRON"
              },
              {
                  "name": "FREMONT",
                  "label": "FREMONT",
                  "value": "FREMONT"
              },
              {
                  "name": "BELLEVUE",
                  "label": "BELLEVUE",
                  "value": "BELLEVUE"
              },
              {
                  "name": "PAPILLION",
                  "label": "PAPILLION",
                  "value": "PAPILLION"
              },
              {
                  "name": "LA VISTA",
                  "label": "LA VISTA",
                  "value": "LA VISTA"
              },
              {
                  "name": "BLAIR",
                  "label": "BLAIR",
                  "value": "BLAIR"
              },
              {
                  "name": "ONEILL",
                  "label": "ONEILL",
                  "value": "ONEILL"
              },
              {
                  "name": "SCOTTSBLUFF",
                  "label": "SCOTTSBLUFF",
                  "value": "SCOTTSBLUFF"
              },
              {
                  "name": "GOTHENBURG",
                  "label": "GOTHENBURG",
                  "value": "GOTHENBURG"
              },
              {
                  "name": "MCCOOK",
                  "label": "MCCOOK",
                  "value": "MCCOOK"
              },
              {
                  "name": "RED CLOUD",
                  "label": "RED CLOUD",
                  "value": "RED CLOUD"
              },
              {
                  "name": "VALLEY",
                  "label": "VALLEY",
                  "value": "VALLEY"
              },
              {
                  "name": "HOLDREGE",
                  "label": "HOLDREGE",
                  "value": "HOLDREGE"
              }
          ]
      },
      {
          "name": "NH",
          "label": "New Hampshire",
          "value": "NH",
          "cities": [
              {
                  "name": "DERRY",
                  "label": "DERRY",
                  "value": "DERRY"
              },
              {
                  "name": "LEBANON",
                  "label": "LEBANON",
                  "value": "LEBANON"
              },
              {
                  "name": "PLYMOUTH",
                  "label": "PLYMOUTH",
                  "value": "PLYMOUTH"
              },
              {
                  "name": "NORTH CONWAY",
                  "label": "NORTH CONWAY",
                  "value": "NORTH CONWAY"
              },
              {
                  "name": "CONCORD",
                  "label": "CONCORD",
                  "value": "CONCORD"
              },
              {
                  "name": "HOOKSETT",
                  "label": "HOOKSETT",
                  "value": "HOOKSETT"
              },
              {
                  "name": "MANCHESTER",
                  "label": "MANCHESTER",
                  "value": "MANCHESTER"
              },
              {
                  "name": "DOVER",
                  "label": "DOVER",
                  "value": "DOVER"
              },
              {
                  "name": "NASHUA",
                  "label": "NASHUA",
                  "value": "NASHUA"
              },
              {
                  "name": "KEENE",
                  "label": "KEENE",
                  "value": "KEENE"
              },
              {
                  "name": "PORTSMOUTH",
                  "label": "PORTSMOUTH",
                  "value": "PORTSMOUTH"
              },
              {
                  "name": "ROCHESTER",
                  "label": "ROCHESTER",
                  "value": "ROCHESTER"
              },
              {
                  "name": "EXETER",
                  "label": "EXETER",
                  "value": "EXETER"
              }
          ]
      },
      {
          "name": "WV",
          "label": "West Virginia",
          "value": "WV",
          "cities": [
              {
                  "name": "PRINCETON",
                  "label": "PRINCETON",
                  "value": "PRINCETON"
              },
              {
                  "name": "BLUEFIELD",
                  "label": "BLUEFIELD",
                  "value": "BLUEFIELD"
              },
              {
                  "name": "BECKLEY",
                  "label": "BECKLEY",
                  "value": "BECKLEY"
              },
              {
                  "name": "MORGANTOWN",
                  "label": "MORGANTOWN",
                  "value": "MORGANTOWN"
              },
              {
                  "name": "HUNTINGTON",
                  "label": "HUNTINGTON",
                  "value": "HUNTINGTON"
              },
              {
                  "name": "WHEELING",
                  "label": "WHEELING",
                  "value": "WHEELING"
              },
              {
                  "name": "MARTINSBURG",
                  "label": "MARTINSBURG",
                  "value": "MARTINSBURG"
              },
              {
                  "name": "BUCKHANNON",
                  "label": "BUCKHANNON",
                  "value": "BUCKHANNON"
              },
              {
                  "name": "WEIRTON",
                  "label": "WEIRTON",
                  "value": "WEIRTON"
              },
              {
                  "name": "SOUTH CHARLESTON",
                  "label": "SOUTH CHARLESTON",
                  "value": "SOUTH CHARLESTON"
              },
              {
                  "name": "MOUNDSVILLE",
                  "label": "MOUNDSVILLE",
                  "value": "MOUNDSVILLE"
              },
              {
                  "name": "BRIDGEPORT",
                  "label": "BRIDGEPORT",
                  "value": "BRIDGEPORT"
              },
              {
                  "name": "CHARLESTON",
                  "label": "CHARLESTON",
                  "value": "CHARLESTON"
              },
              {
                  "name": "GASSAWAY",
                  "label": "GASSAWAY",
                  "value": "GASSAWAY"
              },
              {
                  "name": "PARKERSBURG",
                  "label": "PARKERSBURG",
                  "value": "PARKERSBURG"
              },
              {
                  "name": "LOGAN",
                  "label": "LOGAN",
                  "value": "LOGAN"
              },
              {
                  "name": "ELKINS",
                  "label": "ELKINS",
                  "value": "ELKINS"
              },
              {
                  "name": "GLEN DALE",
                  "label": "GLEN DALE",
                  "value": "GLEN DALE"
              },
              {
                  "name": "POINT PLEASANT",
                  "label": "POINT PLEASANT",
                  "value": "POINT PLEASANT"
              },
              {
                  "name": "SOUTHCHARLESTON",
                  "label": "SOUTHCHARLESTON",
                  "value": "SOUTHCHARLESTON"
              },
              {
                  "name": "MADISON",
                  "label": "MADISON",
                  "value": "MADISON"
              },
              {
                  "name": "MINERAL WELLS",
                  "label": "MINERAL WELLS",
                  "value": "MINERAL WELLS"
              }
          ]
      },
      {
          "name": "AK",
          "label": "Alaska",
          "value": "AK",
          "cities": [
              {
                  "name": "JUNEAU",
                  "label": "JUNEAU",
                  "value": "JUNEAU"
              },
              {
                  "name": "PALMER",
                  "label": "PALMER",
                  "value": "PALMER"
              },
              {
                  "name": "FAIRBANKS",
                  "label": "FAIRBANKS",
                  "value": "FAIRBANKS"
              },
              {
                  "name": "ANCHORAGE",
                  "label": "ANCHORAGE",
                  "value": "ANCHORAGE"
              },
              {
                  "name": "SOLDOTNA",
                  "label": "SOLDOTNA",
                  "value": "SOLDOTNA"
              },
              {
                  "name": "HOMER",
                  "label": "HOMER",
                  "value": "HOMER"
              },
              {
                  "name": "KETCHIKAN",
                  "label": "KETCHIKAN",
                  "value": "KETCHIKAN"
              },
              {
                  "name": "NAKNEK",
                  "label": "NAKNEK",
                  "value": "NAKNEK"
              }
          ]
      },
      {
          "name": "OK",
          "label": "Oklahoma",
          "value": "OK",
          "cities": [
              {
                  "name": "OKLAHOMA CITY",
                  "label": "OKLAHOMA CITY",
                  "value": "OKLAHOMA CITY"
              },
              {
                  "name": "LAWTON",
                  "label": "LAWTON",
                  "value": "LAWTON"
              },
              {
                  "name": "TULSA",
                  "label": "TULSA",
                  "value": "TULSA"
              },
              {
                  "name": "MUSKOGEE",
                  "label": "MUSKOGEE",
                  "value": "MUSKOGEE"
              },
              {
                  "name": "ADA",
                  "label": "ADA",
                  "value": "ADA"
              },
              {
                  "name": "ATOKA",
                  "label": "ATOKA",
                  "value": "ATOKA"
              },
              {
                  "name": "BARTLESVILLE",
                  "label": "BARTLESVILLE",
                  "value": "BARTLESVILLE"
              },
              {
                  "name": "TAHLEQUAH",
                  "label": "TAHLEQUAH",
                  "value": "TAHLEQUAH"
              },
              {
                  "name": "NORMAN",
                  "label": "NORMAN",
                  "value": "NORMAN"
              },
              {
                  "name": "STILLWATER",
                  "label": "STILLWATER",
                  "value": "STILLWATER"
              },
              {
                  "name": "ARDMORE",
                  "label": "ARDMORE",
                  "value": "ARDMORE"
              },
              {
                  "name": "MIDWEST CITY",
                  "label": "MIDWEST CITY",
                  "value": "MIDWEST CITY"
              },
              {
                  "name": "POTEAU",
                  "label": "POTEAU",
                  "value": "POTEAU"
              },
              {
                  "name": "SHAWNEE",
                  "label": "SHAWNEE",
                  "value": "SHAWNEE"
              },
              {
                  "name": "MCALESTER",
                  "label": "MCALESTER",
                  "value": "MCALESTER"
              },
              {
                  "name": "EDMOND",
                  "label": "EDMOND",
                  "value": "EDMOND"
              },
              {
                  "name": "SAPULPA",
                  "label": "SAPULPA",
                  "value": "SAPULPA"
              }
          ]
      },
      {
          "name": "SC",
          "label": "South Carolina",
          "value": "SC",
          "cities": [
              {
                  "name": "HILTON HEAD ISLAND",
                  "label": "HILTON HEAD ISLAND",
                  "value": "HILTON HEAD ISLAND"
              },
              {
                  "name": "GREENVILLE",
                  "label": "GREENVILLE",
                  "value": "GREENVILLE"
              },
              {
                  "name": "WEST COLUMBIA",
                  "label": "WEST COLUMBIA",
                  "value": "WEST COLUMBIA"
              },
              {
                  "name": "CHARLESTON",
                  "label": "CHARLESTON",
                  "value": "CHARLESTON"
              },
              {
                  "name": "MYRTLE BEACH",
                  "label": "MYRTLE BEACH",
                  "value": "MYRTLE BEACH"
              },
              {
                  "name": "COLUMBIA",
                  "label": "COLUMBIA",
                  "value": "COLUMBIA"
              },
              {
                  "name": "SPARTANBURG",
                  "label": "SPARTANBURG",
                  "value": "SPARTANBURG"
              },
              {
                  "name": "FLORENCE",
                  "label": "FLORENCE",
                  "value": "FLORENCE"
              },
              {
                  "name": "MURRELLS INLET",
                  "label": "MURRELLS INLET",
                  "value": "MURRELLS INLET"
              },
              {
                  "name": "ORANGEBURG",
                  "label": "ORANGEBURG",
                  "value": "ORANGEBURG"
              },
              {
                  "name": "NORTH CHARLESTON",
                  "label": "NORTH CHARLESTON",
                  "value": "NORTH CHARLESTON"
              },
              {
                  "name": "ROCK HILL",
                  "label": "ROCK HILL",
                  "value": "ROCK HILL"
              },
              {
                  "name": "BLUFFTON",
                  "label": "BLUFFTON",
                  "value": "BLUFFTON"
              },
              {
                  "name": "CONWAY",
                  "label": "CONWAY",
                  "value": "CONWAY"
              },
              {
                  "name": "N CHARLESTON",
                  "label": "N CHARLESTON",
                  "value": "N CHARLESTON"
              },
              {
                  "name": "OKATIE",
                  "label": "OKATIE",
                  "value": "OKATIE"
              },
              {
                  "name": "SUMTER",
                  "label": "SUMTER",
                  "value": "SUMTER"
              },
              {
                  "name": "LITTLE RIVER",
                  "label": "LITTLE RIVER",
                  "value": "LITTLE RIVER"
              },
              {
                  "name": "ST MATTHEWS",
                  "label": "ST MATTHEWS",
                  "value": "ST MATTHEWS"
              },
              {
                  "name": "SENECA",
                  "label": "SENECA",
                  "value": "SENECA"
              },
              {
                  "name": "GREENWOOD",
                  "label": "GREENWOOD",
                  "value": "GREENWOOD"
              },
              {
                  "name": "AIKEN",
                  "label": "AIKEN",
                  "value": "AIKEN"
              },
              {
                  "name": "ANDERSON",
                  "label": "ANDERSON",
                  "value": "ANDERSON"
              },
              {
                  "name": "CLINTON",
                  "label": "CLINTON",
                  "value": "CLINTON"
              },
              {
                  "name": "MOUNT PLEASANT",
                  "label": "MOUNT PLEASANT",
                  "value": "MOUNT PLEASANT"
              },
              {
                  "name": "BEAUFORT",
                  "label": "BEAUFORT",
                  "value": "BEAUFORT"
              },
              {
                  "name": "LADSON",
                  "label": "LADSON",
                  "value": "LADSON"
              },
              {
                  "name": "EASLEY",
                  "label": "EASLEY",
                  "value": "EASLEY"
              },
              {
                  "name": "GAFFNEY",
                  "label": "GAFFNEY",
                  "value": "GAFFNEY"
              }
          ]
      },
      {
          "name": "HI",
          "label": "Hawaii",
          "value": "HI",
          "cities": [
              {
                  "name": "HONOLULU",
                  "label": "HONOLULU",
                  "value": "HONOLULU"
              },
              {
                  "name": "HILO",
                  "label": "HILO",
                  "value": "HILO"
              },
              {
                  "name": "TRIPLER ARMY MEDICAL CENTER",
                  "label": "TRIPLER ARMY MEDICAL CENTER",
                  "value": "TRIPLER ARMY MEDICAL CENTER"
              },
              {
                  "name": "WAILUKU",
                  "label": "WAILUKU",
                  "value": "WAILUKU"
              },
              {
                  "name": "PAHOA",
                  "label": "PAHOA",
                  "value": "PAHOA"
              },
              {
                  "name": "JBPHH",
                  "label": "JBPHH",
                  "value": "JBPHH"
              },
              {
                  "name": "KAILUA",
                  "label": "KAILUA",
                  "value": "KAILUA"
              },
              {
                  "name": "AIEA",
                  "label": "AIEA",
                  "value": "AIEA"
              },
              {
                  "name": "KAMUELA",
                  "label": "KAMUELA",
                  "value": "KAMUELA"
              }
          ]
      },
      {
          "name": "SD",
          "label": "South Dakota",
          "value": "SD",
          "cities": [
              {
                  "name": "SIOUX FALLS",
                  "label": "SIOUX FALLS",
                  "value": "SIOUX FALLS"
              },
              {
                  "name": "WINNER",
                  "label": "WINNER",
                  "value": "WINNER"
              },
              {
                  "name": "FORT MEADE",
                  "label": "FORT MEADE",
                  "value": "FORT MEADE"
              },
              {
                  "name": "RAPID CITY",
                  "label": "RAPID CITY",
                  "value": "RAPID CITY"
              },
              {
                  "name": "VERMILLION",
                  "label": "VERMILLION",
                  "value": "VERMILLION"
              },
              {
                  "name": "ABERDEEN",
                  "label": "ABERDEEN",
                  "value": "ABERDEEN"
              },
              {
                  "name": "YANKTON",
                  "label": "YANKTON",
                  "value": "YANKTON"
              },
              {
                  "name": "WATERTOWN",
                  "label": "WATERTOWN",
                  "value": "WATERTOWN"
              },
              {
                  "name": "SPEARFISH",
                  "label": "SPEARFISH",
                  "value": "SPEARFISH"
              }
          ]
      },
      {
          "name": "DC",
          "label": "District of Columbia",
          "value": "DC",
          "cities": [
              {
                  "name": "WASHINGTON",
                  "label": "WASHINGTON",
                  "value": "WASHINGTON"
              },
              {
                  "name": "COLUMBIA",
                  "label": "COLUMBIA",
                  "value": "COLUMBIA"
              }
          ]
      },
      {
          "name": "PR",
          "label": "Puerto Rico",
          "value": "PR",
          "cities": [
              {
                  "name": "HUMACAO",
                  "label": "HUMACAO",
                  "value": "HUMACAO"
              },
              {
                  "name": "MAYAGUEZ",
                  "label": "MAYAGUEZ",
                  "value": "MAYAGUEZ"
              },
              {
                  "name": "CAROLINA",
                  "label": "CAROLINA",
                  "value": "CAROLINA"
              },
              {
                  "name": "SAN JUAN",
                  "label": "SAN JUAN",
                  "value": "SAN JUAN"
              },
              {
                  "name": "PONCE",
                  "label": "PONCE",
                  "value": "PONCE"
              },
              {
                  "name": "SANTA ISABEL",
                  "label": "SANTA ISABEL",
                  "value": "SANTA ISABEL"
              },
              {
                  "name": "BAYAMON",
                  "label": "BAYAMON",
                  "value": "BAYAMON"
              },
              {
                  "name": "CAGUAS",
                  "label": "CAGUAS",
                  "value": "CAGUAS"
              },
              {
                  "name": "MANATI",
                  "label": "MANATI",
                  "value": "MANATI"
              },
              {
                  "name": "AGUADILLA",
                  "label": "AGUADILLA",
                  "value": "AGUADILLA"
              },
              {
                  "name": "SANTURCE",
                  "label": "SANTURCE",
                  "value": "SANTURCE"
              },
              {
                  "name": "TOA ALTA",
                  "label": "TOA ALTA",
                  "value": "TOA ALTA"
              },
              {
                  "name": "GUAYNABO",
                  "label": "GUAYNABO",
                  "value": "GUAYNABO"
              },
              {
                  "name": "SAN GERMAN",
                  "label": "SAN GERMAN",
                  "value": "SAN GERMAN"
              },
              {
                  "name": "DORADO",
                  "label": "DORADO",
                  "value": "DORADO"
              },
              {
                  "name": "ARECIBO",
                  "label": "ARECIBO",
                  "value": "ARECIBO"
              },
              {
                  "name": "FAJARDO",
                  "label": "FAJARDO",
                  "value": "FAJARDO"
              },
              {
                  "name": "MERCEDITA",
                  "label": "MERCEDITA",
                  "value": "MERCEDITA"
              },
              {
                  "name": "HATO REY",
                  "label": "HATO REY",
                  "value": "HATO REY"
              },
              {
                  "name": "RIO PIEDRAS",
                  "label": "RIO PIEDRAS",
                  "value": "RIO PIEDRAS"
              },
              {
                  "name": "BARCELONETA",
                  "label": "BARCELONETA",
                  "value": "BARCELONETA"
              },
              {
                  "name": "ADJUNTAS",
                  "label": "ADJUNTAS",
                  "value": "ADJUNTAS"
              },
              {
                  "name": "CAYEY",
                  "label": "CAYEY",
                  "value": "CAYEY"
              },
              {
                  "name": "YAUCO",
                  "label": "YAUCO",
                  "value": "YAUCO"
              },
              {
                  "name": "TOA BAJA",
                  "label": "TOA BAJA",
                  "value": "TOA BAJA"
              }
          ]
      },
      {
          "name": "NM",
          "label": "New Mexico",
          "value": "NM",
          "cities": [
              {
                  "name": "ALBUQUERQUE",
                  "label": "ALBUQUERQUE",
                  "value": "ALBUQUERQUE"
              },
              {
                  "name": "RIO RANCHO",
                  "label": "RIO RANCHO",
                  "value": "RIO RANCHO"
              },
              {
                  "name": "SILVER CITY",
                  "label": "SILVER CITY",
                  "value": "SILVER CITY"
              },
              {
                  "name": "SUNLAND PARK",
                  "label": "SUNLAND PARK",
                  "value": "SUNLAND PARK"
              },
              {
                  "name": "LAS CRUCES",
                  "label": "LAS CRUCES",
                  "value": "LAS CRUCES"
              },
              {
                  "name": "SANTA FE",
                  "label": "SANTA FE",
                  "value": "SANTA FE"
              },
              {
                  "name": "DEMING",
                  "label": "DEMING",
                  "value": "DEMING"
              },
              {
                  "name": "FARMINGTON",
                  "label": "FARMINGTON",
                  "value": "FARMINGTON"
              },
              {
                  "name": "CARLSBAD",
                  "label": "CARLSBAD",
                  "value": "CARLSBAD"
              },
              {
                  "name": "ROSWELL",
                  "label": "ROSWELL",
                  "value": "ROSWELL"
              },
              {
                  "name": "ANTHONY",
                  "label": "ANTHONY",
                  "value": "ANTHONY"
              },
              {
                  "name": "LOS ALAMOS",
                  "label": "LOS ALAMOS",
                  "value": "LOS ALAMOS"
              },
              {
                  "name": "LOS LUNAS",
                  "label": "LOS LUNAS",
                  "value": "LOS LUNAS"
              },
              {
                  "name": "LOVINGTON",
                  "label": "LOVINGTON",
                  "value": "LOVINGTON"
              },
              {
                  "name": "GALLUP",
                  "label": "GALLUP",
                  "value": "GALLUP"
              },
              {
                  "name": "CLOVIS",
                  "label": "CLOVIS",
                  "value": "CLOVIS"
              },
              {
                  "name": "ALAMOGORDO",
                  "label": "ALAMOGORDO",
                  "value": "ALAMOGORDO"
              }
          ]
      },
      {
          "name": "ND",
          "label": "North Dakota",
          "value": "ND",
          "cities": [
              {
                  "name": "FARGO",
                  "label": "FARGO",
                  "value": "FARGO"
              },
              {
                  "name": "BISMARCK",
                  "label": "BISMARCK",
                  "value": "BISMARCK"
              },
              {
                  "name": "GRAND FORKS",
                  "label": "GRAND FORKS",
                  "value": "GRAND FORKS"
              },
              {
                  "name": "WILLISTON",
                  "label": "WILLISTON",
                  "value": "WILLISTON"
              },
              {
                  "name": "MINOT",
                  "label": "MINOT",
                  "value": "MINOT"
              },
              {
                  "name": "DICKINSON",
                  "label": "DICKINSON",
                  "value": "DICKINSON"
              }
          ]
      },
      {
          "name": "WY",
          "label": "Wyoming",
          "value": "WY",
          "cities": [
              {
                  "name": "CODY",
                  "label": "CODY",
                  "value": "CODY"
              },
              {
                  "name": "JACKSON",
                  "label": "JACKSON",
                  "value": "JACKSON"
              },
              {
                  "name": "SHERIDAN",
                  "label": "SHERIDAN",
                  "value": "SHERIDAN"
              },
              {
                  "name": "CHEYENNE",
                  "label": "CHEYENNE",
                  "value": "CHEYENNE"
              },
              {
                  "name": "LARAMIE",
                  "label": "LARAMIE",
                  "value": "LARAMIE"
              },
              {
                  "name": "POWELL",
                  "label": "POWELL",
                  "value": "POWELL"
              },
              {
                  "name": "LANDER",
                  "label": "LANDER",
                  "value": "LANDER"
              },
              {
                  "name": "CASPER",
                  "label": "CASPER",
                  "value": "CASPER"
              }
          ]
      },
      {
          "name": "GU",
          "label": "Guam",
          "value": "GU",
          "cities": [
              {
                  "name": "TAMUNING",
                  "label": "TAMUNING",
                  "value": "TAMUNING"
              },
              {
                  "name": "DEDEDO",
                  "label": "DEDEDO",
                  "value": "DEDEDO"
              }
          ]
      },
      {
          "name": "MP",
          "label": "Northern Mariana Islands",
          "value": "MP",
          "cities": [
              {
                  "name": "SAIPAN",
                  "label": "SAIPAN",
                  "value": "SAIPAN"
              }
          ]
      },
      {
          "name": "VI",
          "label": "Virgin Islands",
          "value": "VI",
          "cities": [
              {
                  "name": "CHRISTIANSTED",
                  "label": "CHRISTIANSTED",
                  "value": "CHRISTIANSTED"
              },
              {
                  "name": "ST THOMAS",
                  "label": "ST THOMAS",
                  "value": "ST THOMAS"
              }
          ]
      }
  ]
}