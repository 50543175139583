import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { logOut } from "../../../store/actions/authActions";
import axios from "axios";

import { Link } from "react-router-dom";
import NavHader from "./NavHader";


/// Image
import profiles from "../../../images/rep_image.png";

const Header = ({ onNote, toggle, onProfile, onNotification, onBox }) => {

  const [profile, setProfile] = useState(null);
  const repId = useSelector((state) => state.firebase.auth.uid);

  useEffect(() => {

    axios
      .get(`/v1/rep_orchestrator/rep/search?rep_id=${repId}`, { headers: { 'x-rep-uid': repId } })
      .then((res) => {
        setProfile(res.data.reps[0]);
        console.log(res.data.reps[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

 
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
      ? filterName.filter((f) => f !== "ui")
      : filterName.includes("uc")
        ? filterName.filter((f) => f !== "uc")
        : filterName.includes("basic")
          ? filterName.filter((f) => f !== "basic")
          : filterName.includes("form")
            ? filterName.filter((f) => f !== "form")
            : filterName.includes("table")
              ? filterName.filter((f) => f !== "table")
              : filterName.includes("page")
                ? filterName.filter((f) => f !== "page")
                : filterName.includes("email")
                  ? filterName.filter((f) => f !== "email")
                  : filterName.includes("ecom")
                    ? filterName.filter((f) => f !== "ecom")
                    : filterName.includes("chart")
                      ? filterName.filter((f) => f !== "chart")
                      : filterName.includes("editor")
                        ? filterName.filter((f) => f !== "editor")
                        : filterName;

  const dispatch = useDispatch();
  return (
    <div className="header" style={{ paddingLeft: '43%' }}>
      <NavHader />
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {/* {finalName.join(" ")} */}
              </div>
            </div>
            <ul className="navbar-nav header-right">
              <li
                className="nav-item dropdown header-profile"
                onClick={() => onProfile()}
              >
                <Link
                  className="nav-link"
                  to="#"
                  role="button"
                  data-toggle="dropdown"
                >
                  <img src={profiles} width={60} height={60} alt="" />
                  <div className="header-info">
                    <span>
                    
                      Hello,<strong> {profile?.first_name}</strong>
                    </span>
                  </div>
                </Link>

                <div
                  className={`dropdown-menu dropdown-menu-right ${toggle === "profile" ? "show" : ""
                    }`}
                >
                  <Link
                    to={{
                      pathname: "/user-profile/R" + profile?.id,
                    }}

                  className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ml-2">Profile </span>
                  </Link>
                  <Link
                    onClick={() => dispatch(logOut())}
                    to="#"
                    className="dropdown-item ai-icon"
                  >
                    <svg
                      id="icon-logout"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-danger"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                      <polyline points="16 17 21 12 16 7" />
                      <line x1={21} y1={12} x2={9} y2={12} />
                    </svg>
                    <span className="ml-2">Logout </span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
