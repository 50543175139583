import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

export const firebaseConfig = {
  apiKey: "AIzaSyAw4yk1Ya7qCSoGe3FV-KCkRCphlT5Jkk0",
  authDomain: "web-rep-tool.firebaseapp.com",
  projectId: "web-rep-tool",
  storageBucket: "web-rep-tool.appspot.com",
  messagingSenderId: "250421459588",
  appId: "1:250421459588:web:4ea3c916e23b23bddda0d1",
  measurementId: "G-HTMKJ25FXX",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;
