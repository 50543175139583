import React, { useEffect, useMemo, useState } from "react";
import UserProfileTable from "../table/UserProfileTable";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import swal from "sweetalert";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Link } from "react-router-dom";

import doc from "../../../images/doc2.png";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import useKeypress from "../../../hooks/useKeypress";
// import { DatePicker } from "@y0c/react-datepicker";
import axios from "axios";
import HomeModal from "../webRepModals/HomeModal";
import { Chip } from "@material-ui/core";
import { Select, MenuItem } from "@material-ui/core";

const options = [
  { value: "MOA", label: "MOA" },
  { value: "Safety", label: "Safety" },
  { value: "Efficacy", label: "Efficacy" },
  { value: "Dosing & Administration", label: "Dosing & Administration" },
  { value: "Others", label: "Others" },
];

const defaultData = {
  call_date: new Date(),
  key_message: [{ id: 1, name: "MOA" }],
  call_record_id: "",
  cid: "",
  status: "",
  interaction_type: { id: 1, name: "Face to Face" },
  product: { id: 1, name: "Fyarro (ABI-009)" },
  doctors: [],
  leave_behinds: [
    {
      name: "JCO",
      value: false,
    },
    // {
    //   name: 'NEJM',
    //   value: false
    // }
  ],
  notes: "",
};
const WebRepCallRecordHospital = () => {
  const repId = useSelector((state) => state.firebase.auth.uid);
  const { id } = useParams();
  const [profile, setProfile] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [alreadyLinkedDoctors, setAlreadyLinkedDoctors] = useState([]);
  const [alreadyDoneStatus, setAlreadyDoneStatus] = useState(false);
  const [hospitals, setHospitals] = useState([]);
  const [search, setSearch] = useState([]);
  const [interaction, setInteraction] = useState([]);
  const [doctorsUniverse, setDoctorsUniverse] = useState([]);
  const [message, setMessage] = useState([]);
  const [products, setProducts] = useState([]);
  const [affilatedDone, setAffilatedDone] = useState(false);
  const [callRecord, setCallRecord] = useState([]);
  const [editShow, setEditShow] = React.useState(true);
  const [tableLoadOnPost, setTableLoadOnPost] = useState(false);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedInteraction, setSelectedInteraction] = useState({});
  const [alreadyLinkedIds, setAlreadyLinkedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [completed1, setCompleted1] = useState(false);
  const [completed2, setCompleted2] = useState(false);
  const [completed3, setCompleted3] = useState(false);
  const [completed4, setCompleted4] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState(defaultData);
  const [page, setPage] = useState(1);
  const [rowPerPage] = useState(10);
  const [totalCallRecord, setTotalCallRecord] = useState(0);
  const enter = useKeypress("Enter");
  const [isSerachActive, setSearchActive] = useState(false);
  const [keyMessage, setKeyMessage] = useState([]);
  const inputs = [
    { name: "date", date: true },
    { name: "key_message", select: true },
    { name: "interaction_type", select: true },
    { name: "product", select: true },
  ];

  console.log("profile", profile);

  const onDoctorUpdate = (value, id, updatedDoctor) => {
    console.log(
      "value = ",
      value,
      " id =  ",
      id,
      " updated = ",
      updatedDoctor,
      "profile.id (hospital ID)",
      profile.id
    );
    var message = document.createElement("div");

    message.style = "position: relative;";

    let newProfile = updatedDoctor;
    if (id === "LINKING_H") {
      message.innerHTML = "New HCP is successfully affiliated!!";
      newProfile = {
        ...newProfile,
        update_type: "HCP_HOSPITAL_LINKING_H",
      };
    }

    if (id !== "LINKING_H" && id !== "LINKING_D") {
      delete newProfile["hospital_ids"];
    }

    if (!updatedDoctor) {
      var data = newProfile;
      if (value === "NONE") {
        newProfile = { ...newProfile, tags: [] };
      }
      if (id === "LINKING_H") {
        data = { ...newProfile, hospital_ids: [value.id] };
      }

      console.log(data);
      axios
        .put(`/v1/rep_orchestrator/doctor`, data, {
          headers: { "x-rep-uid": repId },
        })
        .then((res) => {
          console.log(res);
          swal({
            content: message,
            icon: "success",
          });
          if (id !== "LINKING_H") {
            setProfile(newProfile);
          }
          // axios
          // .get(`/v1/rep_orchestrator/hospital/search?doc_ids=${newProfile.id}&page_number=1&page_size=100&sort_by=hospital_name&sort_order=asc`,
          // { headers: { 'x-rep-uid': repId }})
          // .then((res) => {
          //   setHospitals(res.data.hospitals);
          // })
          // .catch((err) => {
          //   console.log(err);
          //   setCompleted1(true);
          // });
        })
        .catch((err) => {
          console.log(err);
          swal("Something went wrong!", err.message, "error");
        });
    } else {
      var data = newProfile;
      console.log(data);
      if (id === "LINKING_H") {
        data = { ...data, hospital_ids: [value.id] };
      }
      axios
        .put(`/v1/rep_orchestrator/doctor`, data, {
          headers: { "x-rep-uid": repId },
        })
        .then((res) => {
          console.log(res);
          swal({
            content: message,
            icon: "success",
          });
          if (id !== "LINKING_H") {
            setProfile(newProfile);
          }
          axios
            .get(
              `/v1/rep_orchestrator/hospital/search?doc_ids=${profile.id}&page_number=1&page_size=100&sort_by=hospital_name&sort_order=asc`,
              { headers: { "x-rep-uid": repId } }
            )
            .then((res) => {
              setHospitals(res.data.hospitals);
              console.log(" ===== updating hospitals after linking ");
            })
            .catch((err) => {
              console.log(err);
              setCompleted1(true);
            });
        })
        .catch((err) => {
          console.log(err);
          swal("Something went wrong!", err.message, "error");
        });
    }
  };

  const sortTable = (name, type, order) => {
    console.log(" name 1= ", name);
    const newSData = [].concat(callRecord);
    if (newSData) {
      console.log(" before data = ", newSData);
      if (order === 1) {
        newSData.sort((a, b) => {
          if (typeof a[name] === "string") {
            return a[name]?.toLowerCase() > b[name]?.toLowerCase() ? 1 : -1;
          }
          return a[name] > b[name] ? 1 : -1;
        });
      } else {
        newSData.sort((a, b) => {
          if (typeof a[name] === "string") {
            return b[name]?.toLowerCase() > a[name]?.toLowerCase() ? 1 : -1;
          }
          return b[name] > a[name] ? 1 : -1;
        });
      }
      setCallRecord(newSData);
      console.log(" after sort ", newSData);
    }
  };

  const formatter = (data) => {
    return data.map((v) => ({
      label: v.name,
      value: v.name,
      id: v.id,
    }));
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/v1/rep_orchestrator/hospital/search?hospital_ids=${id}`, {
        headers: { "x-rep-uid": repId },
      })
      .then((res) => {
        setProfile(res.data.hospitals[0]);
        // setSelectedDoctors([...selectedDoctors,{id:res.data.doctors[0].id,label:res.data.doctors[0].display_name,tier:data?.doctors[0]?.tier}]);
        setCompleted1(true);
      })
      .catch((err) => {
        console.log(err);
        setCompleted1(true);
      });

    axios
      .get(
        `/v1/rep_orchestrator/doctor/search?hospital_id=${id}&page_number=1&page_size=20`,
        { headers: { "x-rep-uid": repId } }
      )
      .then((res) => {
        setDoctors(res.data.doctors);
        setAlreadyLinkedDoctors(res.data.doctors);
        setAlreadyDoneStatus(true);
        setHospitals(res.data.hospitals);
        setCompleted2(true);
      })
      .catch((err) => {
        console.log(err);
        setCompleted2(true);
      });

    axios
      .get(
        `/v1/rep_orchestrator/call_record/search?rep_id=${repId}&hospital_ids=${id}&page_size=${rowPerPage}`,
        { headers: { "x-rep-uid": repId } }
      )
      .then((res) => {
        setCallRecord(res.data.call_records);
        setTotalCallRecord(res.data.total);
        setCompleted3(true);
      })
      .catch((err) => {
        console.log(err);
        setCompleted3(true);
      });

    axios
      .get(`/v1/rep_orchestrator/call_record/meta`)
      .then((res) => {
        setCompleted4(true);
        setInteraction(res.data.interaction_type);
        setMessage(res.data.key_message);
        setProducts(res.data.products);
        setKeyMessage([res.data.key_message[0]?.id]);

        setData((prev) => ({
          ...prev,
          key_message: [res.data.key_message[0]],
          interaction_type: res.data.interaction_type[0],
          product: res.data.products[0],
        }));
      })
      .catch((err) => {
        console.log(err);
        setCompleted4(true);
      });
  }, [id]);

  useEffect(() => {
    if (tableLoadOnPost) {
      axios
        .get(
          `/v1/rep_orchestrator/call_record/search?rep_id=${repId}&hospital_ids=${id}&page_number=${page}&page_size=${rowPerPage}`,
          { headers: { "x-rep-uid": repId } }
        )
        .then((res) => {
          setCallRecord(res.data.call_records);
          setTotalCallRecord(res.data.total);
          setTableLoadOnPost(false);
        })
        .catch((err) => {
          console.log(err);
          setTableLoadOnPost(false);
        });
    }
  }, [tableLoadOnPost]);

  useEffect(() => {
    axios
      .get(
        `/v1/rep_orchestrator/call_record/search?rep_id=${repId}&hospital_ids=${id}&page_number=${page}&page_size=${rowPerPage}`,
        { headers: { "x-rep-uid": repId } }
      )
      .then((res) => {
        setCallRecord(res.data.call_records);
        setTotalCallRecord(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);

  useEffect(() => {
    if (
      completed1 === true &&
      completed2 === true &&
      completed3 === true &&
      completed4 === true
    ) {
      setLoading(false);
    }
  }, [completed1, completed2, completed3, completed4]);
  useEffect(() => {
    if (enter && isSerachActive) {
      setSearchActive(false);
      onSearch();
    }
  }, [enter]);
  if (loading === true) {
    return <> loading... </>;
  }
  const isSameDoctor = (a, b) => a.id == b.id;

  const filterDocs = (univ) => {
    if (!doctors || doctors.length === 0) {
      return univ;
    }

    if (univ && univ.length > 0) {
      // Get items that only occur in the left array,
      // using the compareFunction to determine equality.
      const onlyInLeft = (left, right, compareFunction) =>
        left.filter(
          (leftValue) =>
            !right.some((rightValue) => compareFunction(leftValue, rightValue))
        );

      return onlyInLeft(univ, doctors, isSameDoctor);
    }
    return [];
  };

  const onSearch = () => {
    setSearchActive(false);
    axios
      .get(
        `/v1/rep_orchestrator/doctor/search?keyword=${search}&page_number=1&page_size=20`,
        { headers: { "x-rep-uid": repId } }
      )
      .then((res) => {
        console.log(res);
        setDoctors(res.data.doctors);
        setCompleted2(true);
      })
      .catch((err) => {
        console.log(err);
        setCompleted2(true);
      });
  };
  const handleDelete = (item) => {
    console.log();
    setSelectedDoctors(selectedDoctors.filter((val) => val.id !== item.id));
  };

  console.log(
    "filterDocs(doctorsUniverse) from Rephospital Call: ",
    filterDocs(doctorsUniverse)
  );

  console.log("setDoctorsUniverse", doctorsUniverse);

  const setOption = (name) => {
    switch (name) {
      case "key_message":
        return message;

      case "interaction_type":
        return interaction;

      case "product":
        return products;

      default:
        return options;
    }
  };

  const setSelected = (name, item) => {
    switch (name) {
      case "key_message":
        return setSelectedMessage(item);

      case "interaction_type":
        return setSelectedInteraction(item);

      case "product":
        return setSelectedProduct(item);

      default:
        return options;
    }
  };

  const setValue = (name) => {
    switch (name) {
      case "KeyMessage":
        return selectedMessage;

      case "interaction_type":
        return selectedInteraction;

      case "product":
        return selectedProduct;

      default:
        return options;
    }
  };

  const setDefaultValue = (name) => {
    switch (name) {
      case "Interaction Type":
        return data.interaction_type;
      case "Product":
        return data.product;
      default:
        return {};
    }
  };

  const doctorFormmater = (data, profile) => {
    const result = data.map((v) => ({
      id: v?.value,
      // tier: v?.label,
    }));

    return result;
  };

  const hospitalFormatter = (data) => {
    return [
      {
        id: data?.id,
        name: data?.value,
      },
    ];
  };

  const selectedDropdownFormatter = (data) => {
    return {
      id: data?.id,
      name: data?.value,
    };
  };

  const keyMessageFormatter = (data) => {
    if (!Array.isArray(data)) return [];
    return data.map((item) => ({
      id: item?.id,
      name: item?.value,
    }));
  };

  const productFormatter = (data) => {
    return {
      id: data?.id,
      product_id: data?.value,
    };
  };

  const onSubmit = () => {
    const newData = {
      status: "SUBMITTED",
      representative: {
        id: 1,
        rep_id: repId,
      },
      doctors: selectedDoctors,
      hospitals: hospitalFormatter(profile),
      key_message: keyMessageFormatter(data.key_message),
      call_record_id: data.call_record_id,
      interaction_type: selectedDropdownFormatter(data.interaction_type),
      product: productFormatter(data.product),
      leave_behinds: data.leave_behinds,
      call_date: data.call_date,
    };
    if (!newData.doctors.length) {
      newData.doctors = [{}];
    }

    if (!newData.call_record_id) {
      axios
        .post(`/v1/rep_orchestrator/call_record/`, newData)
        .then((res) => {
          swal("Submitted Successfully").then(() => {
            setTableLoadOnPost(true);
          });
        })
        .catch((err) => {
          swal(err.message).then(() => {
            setTableLoadOnPost(true);
          });
        });
    } else {
      axios
        .put(`/v1/rep_orchestrator/call_record/`, newData)
        .then((res) => {
          swal("Submitted Successfully").then(() => {
            setTableLoadOnPost(true);
          });
        })
        .catch((res) => {
          console.log(" res ", res);
          swal(res.message).then(() => {
            setTableLoadOnPost(true);
          });
        });
    }
  };

  const onSave = () => {
    const newData = {
      status: "SAVED",
      representative: {
        rep_id: repId,
      },
      doctors: selectedDoctors,
      key_message: data.key_message,
      hospitals: hospitalFormatter(profile),
      interaction_type: data.interaction_type,
      product: data.product,
      leave_behinds: data.leave_behinds,
      call_record_id: data.call_record_id,
      call_date: data.call_date,
    };
    if (!newData.doctors.length) {
      newData.doctors = [{ id: profile.id, tier: profile.tier }];
    }
    if (!newData.call_record_id) {
      axios
        .post(`/v1/rep_orchestrator/call_record/`, newData)
        .then((res) => {
          swal("Saved Successfully").then(() => {
            setTableLoadOnPost(true);
          });
        })
        .catch((res) => {
          swal(res.message).then(() => {
            setTableLoadOnPost(true);
          });
        });
    } else {
      axios
        .put(`/v1/rep_orchestrator/call_record/`, newData)
        .then((res) => {
          swal("Saved Successfully").then(() => {
            setTableLoadOnPost(true);
          });
        })
        .catch((res) => {
          swal(res.message).then(() => {
            setTableLoadOnPost(true);
          });
        });
    }
  };

  const handleChange = (value, name) => {
    const newData = { ...data };
    if (name === "interaction_type") {
      newData.interaction_type = setOption(name).filter(
        (option) => option.id === value
      )[0];
    } else if (name === "product") {
      newData.product = setOption(name).filter(
        (option) => option.id === value
      )[0];
    } else if (name === "call_date") {
      newData.call_date = value;
    } else if (name === "notes") {
      newData.notes = value;
    } else if (name === "key_message") {
      setKeyMessage(value);
      newData.key_message = setOption(name).filter((option) =>
        value.some((val) => val == option.id)
      );
    } else if (name === "doctors") {
      newData.doctors = value;
    } else if (name === "CLM") {
    } else {
      newData.leave_behinds[name].value = value;
    }
    setData(newData);
  };
  const selectDoctorHandle = (event, data) => {
    console.log(data);
    if (event.target.checked) {
      setSelectedDoctors([
        ...selectedDoctors,
        { label: data.display_name, id: data.id, tier: data.tier },
      ]);
    } else {
      setSelectedDoctors(
        selectedDoctors.filter((doc) => doc.value !== data.id)
      );
    }
  };

  const handleCancel = () => {
    setData(defaultData);
    setSelectedDoctors([]);
    // setKeyMessage([{id: 1, name: "Clinical trials"}]);
  };

  const alreadyLinked = alreadyLinkedDoctors?.map((i) => i.id);
  console.log("alreadyLinkedDoctors", alreadyLinkedDoctors);

  return (
    <>
      <HomeModal
        formType="DOCTOR"
        setLargeModal={setShow}
        largeModal={show}
        hospital_id={id}
      />
      <section className="user-profile">
        <div className="card">
          <div className="col-12 px-0">
            <div className="py-3 px-4">
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                <div className="d-flex flex-column flex-md-row p-3">
                  <div className="mb-2 mb-md-0">
                    {" "}
                    <img
                      alt="pic"
                      className="rounded mr-sm-4 mr-0"
                      width={130}
                      height={130}
                      src={doc}
                    />
                  </div>
                  <div>
                    <h3 className="fs-22 text-black font-w600 mb-0">
                      <Link
                        to={{
                          pathname: "/user-profile/H" + profile?.id,
                        }}
                      >
                        {profile?.hospital_name}
                      </Link>
                    </h3>
                    <p className="mb-2 mb-sm-2 text-primary">
                      {profile?.primary_speciality}
                    </p>
                    <p className="mb-2 mb-sm-2 text-primary">{profile?.tier}</p>
                    {/* <p className="mb-2 mb-sm-2 text-primary">
                      Office Phone: {profile?.office_phone}
                    </p> */}
                  </div>
                </div>

                <div className="d-flex flex-column flex-md-row p-3">
                  <div className="mb-4 mb-md-0">
                    {" "}
                    <span className="p-3 border border-primary-light rounded-circle mr-3 mb-3">
                      <svg
                        width={22}
                        height={22}
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0)">
                          <path
                            d="M27.5716 13.4285C27.5716 22.4285 16.0001 30.1428 16.0001 30.1428C16.0001 30.1428 4.42871 22.4285 4.42871 13.4285C4.42871 10.3596 5.64784 7.41637 7.8179 5.24631C9.98797 3.07625 12.9312 1.85712 16.0001 1.85712C19.0691 1.85712 22.0123 3.07625 24.1824 5.24631C26.3524 7.41637 27.5716 10.3596 27.5716 13.4285Z"
                            stroke="#2BC155"
                            strokeWidth={3}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.0002 17.2857C18.1305 17.2857 19.8574 15.5588 19.8574 13.4286C19.8574 11.2983 18.1305 9.57141 16.0002 9.57141C13.87 9.57141 12.1431 11.2983 12.1431 13.4286C12.1431 15.5588 13.87 17.2857 16.0002 17.2857Z"
                            stroke="#2BC155"
                            strokeWidth={3}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect
                              width="30.8571"
                              height="30.8571"
                              fill="white"
                              transform="translate(0.571533 0.571411)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                  <div>
                    {" "}
                    <span className="d-block text-light mb-2">Address</span>
                    <p className="fs-18 text-dark">
                      {profile?.address?.line1}
                      {profile?.address?.street}
                      {", "}
                      {profile?.address?.city}
                      {", "}
                      <strong>
                        {profile?.address?.state}
                        {", "} {profile?.address?.country}
                        {profile?.address?.zipcode}
                      </strong>
                    </p>
                  </div>
                </div>
                {/* <div className="map-bx p-3">
                <img src={mapSvg} alt="" />

                <Link to="/patient-details" className="map-button">
                  View in Fullscreen
                </Link>
                <Link className="map-marker" to="#">
                  <i className="las la-map-marker-alt" />
                </Link>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className={`mt-5 ${editShow ? "d-block " : "d-none"}`}>
          <div className="row animated bounceInUp">
            <div className="col">
              <div className="card p-4">
                <div className="width250 mb-3">
                  <button
                    type="button"
                    className="btn btn-primary rounded-pill w-100 py-2"
                    onClick={() => setShow(true)}
                  >
                    Create a New Attendee
                  </button>
                </div>
                {/* Header */}
                <form
                  noValidate
                  className="form-head"
                  onSubmit={(event) => event.preventDefault()}
                >
                  <h3 class="fs-20 mb-3 text-black">
                    Physicians in the Account
                  </h3>
                  <div className="input-group search-area d-inline-flex py-2 border">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setSearchActive(true);
                      }}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="input-group-text"
                        onClick={onSearch}
                      >
                        <i className="flaticon-381-search-2" />
                      </button>
                    </div>
                  </div>
                </form>
                {/* /Header */}
                <PerfectScrollbar
                  id="DZ_W_Todo2"
                  className="widget-media dz-scroll ps ps--active-y height400 mt-4 pr-4"
                >
                  {doctors?.map((item) => (
                    <div className="d-flex mb-4">
                      <div>
                        <div className="font-weight-bold text-black">
                          <Link
                            to={{
                              pathname: "/user-profile/D" + item?.id,
                            }}
                          >
                            {item?.display_name}
                          </Link>
                        </div>
                        <div>{item?.primary_speciality}</div>
                      </div>
                      <div
                        className="ml-auto"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 15,
                        }}
                      >
                        {alreadyLinked?.includes(item.id) ? null : (
                          <button
                            type="button"
                            disabled={affilatedDone ? true : false}
                            className="btn btn-primary rounded-pill w-20 py-2"
                            onClick={() =>
                              onDoctorUpdate(profile, "LINKING_H", item)
                            }
                          >
                            Affiliate
                          </button>
                        )}

                        <div className="custom-control custom-checkbox mb-0 check-lg">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={item.id}
                            required
                            checked={selectedDoctors.some(
                              (doc) => doc.id === item.id
                            )}
                            onChange={(e) => selectDoctorHandle(e, item)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={item.id}
                          ></label>
                        </div>
                      </div>
                    </div>
                  ))}
                </PerfectScrollbar>
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="card p-4 ">
                <form noValidate>
                  <div className="row mx-0 row-cols-1 row-cols-md-2 row-cols-xl-2">
                    {inputs.map((item) => (
                      <div className="form-group row mx-0">
                        <label className="col-sm-5 col-form-label text-capitalize">
                          {item.name}
                        </label>
                        <div className="col-sm-7">
                          {item.name === "key_message" ? (
                            <Select
                              defaultValue={keyMessage}
                              value={keyMessage}
                              multiple
                              onChange={(event) =>
                                handleChange(event.target.value, item.name)
                              }
                              name="key_message"
                              fullWidth
                            >
                              {setOption(item.name).map((option) => (
                                <MenuItem value={option.id}>
                                  {option.name}
                                </MenuItem> //TODO CHANGE THE NAME OF THIS TO MORE READABLE
                              ))}
                            </Select>
                          ) : item.select ? (
                            <Select
                              value={data[item.name]?.id}
                              placeholder={item.placeholder}
                              onChange={(e) =>
                                handleChange(e.target.value, item.name)
                              }
                              fullWidth
                            >
                              {setOption(item.name).map((option) => (
                                <MenuItem value={option.id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : item.date ? (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                autoOk
                                value={data.call_date}
                                format="MM/dd/yyyy"
                                onChange={(date) =>
                                  handleChange(date, "call_date")
                                }
                              />
                            </MuiPickersUtilsProvider>
                          ) : item.withIcon ? (
                            <div className="input-group input-group-sm">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className={`fa ${item.icon}`} />
                                </span>
                              </div>
                              <input
                                type={item.type}
                                className="form-control"
                                placeholder={item.placeholder}
                              />
                            </div>
                          ) : (
                            <input
                              type={item.type}
                              className="form-control"
                              placeholder={item.placeholder}
                            />
                          )}
                        </div>
                      </div>
                    ))}

                    <div className="form-group row mx-0">
                      <label className="col-sm-5 col-form-label text-capitalize">
                        Leave Behinds
                      </label>
                      <div className="col-sm-7 row">
                        <div className="col-6">
                          <div class="custom-control custom-checkbox mb-3">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="jco"
                              checked={data.leave_behinds[0].value}
                              onChange={(event) =>
                                handleChange(event.target.checked, 0)
                              }
                            />
                            <label class="custom-control-label" for="jco">
                              JCO
                            </label>
                          </div>
                        </div>
                        {/* <div className='col-6'>
                          <div class="custom-control custom-checkbox mb-3">
                            <input type="checkbox" class="custom-control-input" id="NEJM" checked={data.leave_behinds[1].value} onChange={(event) => handleChange(event.target.checked, 1)} />
                            <label class="custom-control-label" for="NEJM">NEJM</label>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    {data.call_record_id ? (
                      <div className="form-group row mx-0">
                        <label className="col-sm-5 col-form-label  text-capitalize">
                          Call Record ID:
                        </label>
                        <div className="col-sm-7 row">
                          <div className="col-9 text-dark mb-3 ">
                            {data.cid}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/*<div className="form-group row mx-0">
                     <label className="col-sm-5 col-form-label text-capitalize">
                     CLM
                    </label> 
                    <div className='col-sm-7 row'>
                    <div className='col-6'>
                    <div class="custom-control custom-checkbox mb-3">
											<input type="checkbox" class="custom-control-input" id="clm" onChange={(event)=> handleChange(event.target.checked,"CLM")} />
											<label class="custom-control-label" for="clm">CLM call</label>
										</div>
                    </div> 
                    </div>
                  </div> */}

                  <div className="form-group row mx-0">
                    <div class="col-2">
                      <label className="col-form-label text-capitalize">
                        Attendees
                      </label>
                    </div>
                    <div className="col-10">
                      {selectedDoctors?.map((item) => (
                        <Chip
                          label={`${item.label}`}
                          onDelete={() => handleDelete(item)}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="mt-5 mb-4 d-flex align-items-center justify-content-end flex-column flex-md-row">
                    <button
                      type="button"
                      disabled={
                        data.status === "SUBMITTED" ||
                        selectedDoctors.length === 0
                      }
                      className="btn bg-primary-alt text-black rounded-pill px-5"
                      onClick={onSave}
                      style={{
                        cursor:
                          data.status === "SUBMITTED" ||
                          selectedDoctors.length === 0
                            ? "not-allowed"
                            : "default",
                      }}
                    >
                      Save
                    </button>
                    <span className="my-3 mx-3" />
                    <button
                      type="button"
                      className="btn btn-primary rounded-pill px-5"
                      onClick={onSubmit}
                      style={{
                        cursor:
                          data.status === "SUBMITTED" ||
                          selectedDoctors.length === 0
                            ? "not-allowed"
                            : "default",
                      }}
                      disabled={
                        data.status === "SUBMITTED" ||
                        selectedDoctors.length === 0
                      }
                    >
                      Submit
                    </button>
                    <span className="my-3 mx-3" />
                    <button
                      type="button"
                      className="btn btn-dark rounded-pill px-5"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <UserProfileTable
            data={callRecord}
            setSelectedDoctors={setSelectedDoctors}
            setSelectedMessage={setSelectedMessage}
            setSelectedInteraction={setSelectedInteraction}
            setSelectedProduct={setSelectedProduct}
            profile={profile}
            hospitals={hospitals}
            setData={setData}
            setTableLoadOnPost={setTableLoadOnPost}
            page={page}
            setPage={setPage}
            totalCallRecord={totalCallRecord}
            rowPerPage={rowPerPage}
            setKeyMessage={setKeyMessage}
            sortTable={sortTable}
          />
        </div>
      </section>
    </>
  );
};

export default WebRepCallRecordHospital;
