import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { sortTable, sarcomaCentersQueryParam, requiredDataFormTypeMap, tiersQueryParam, physicianTags, viewTargetTypes, inputs, defaultHCPViewTypes, defaultHCOViewTypes, modalOptionsMap, enumNames } from "../table/RepModalConstants";

import { repCityStateMappings } from "../table/Constants";
import { repAccountStateCityMappings } from "../table/AccountConstants";

import { useSelector } from "react-redux";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import axios from "axios";
import { primaryStyle } from "../PluginsMenu/Select2/SelectStyles";
import Select from "react-select";
import PerfectScrollbar from "react-perfect-scrollbar";
import useKeypress from '../../../hooks/useKeypress';
import cr from "../../../images/call_record_green.png";
import swal from "sweetalert";



const RepViewModal = ({ setLargeModal, largeModal, formType, hospital_id, view, setView, fetchAndSetMyViews }) => {

    const [formData, setFormData] = useState({});
    const [search, setSearch] = React.useState("");
    const [submitting, setSubmitting] = useState(false);
    const [viewDescription, setViewDescription] = useState('');
    const [defaultViewType, setDefaultViewType] = useState(null);
    const [viewName, setViewName] = useState('');
    const [viewTargetType, setViewTargetType] = useState(null);
    const [tier, setTier] = useState(null);
    const [state, setState] = useState(null);
    const [sarcomaCenter, setSarcomaCenter] = useState(null);
    const [disabled, setDisabled] = useState(false);

    const [targetDoctors, setTargetDoctors] = useState();
    const [targetHospitals, setTargetHospitals] = useState();
    const [title, setTitle] = useState("");
    const [selectedTargetDoctors, setSelectedTargetDoctors] = useState([]);
    const [selectedTargetHospitals, setSelectedTargetHospitals] = useState([]);
    const enter = useKeypress('Enter');
    const repId = useSelector((state) => state.firebase.auth.uid);

    const [defaultSuperTargets, setDefaultSuperTargets] = useState([]);
    const [defaultHighDecile, setDefaultHighDecile] = useState([]);
    const [defaultKols, setDefaultKols] = useState([]);
    const [defaultSarcomaCenters, setDefaultSarcomaCenters] = useState([]);

    const viewTargetTypes = [
        { label: "Physicians", value: "HCP", name: 'view_type', disabled: disabled },
        { label: "Accounts", value: "HCO", name: "view_type", disabled: disabled  },
    ];

    useEffect(() => {
        if (view && Object.keys(view).length !== 0) {
            setSelectedTargetDoctors([]);
            setSelectedTargetHospitals([]);

            setViewTargetType(view.view_type === 'ACCOUNTS' ? { label: "Accounts", value: "HCO", name: 'view_type' } : { label: "Physicians", value: "HCP", name: 'view_type' });
            let searchType = (view?.view_type === 'PHYSICIANS' ? 'doctor' : 'hospital');

            axios
                .get(`/v1/rep_orchestrator/${searchType}/search?view_id=${view?.id}&page_number=1&page_size=200`,
                    { headers: { 'x-rep-uid': repId } })
                .then((res) => {
                    if (searchType === 'doctor') {
                        setSelectedTargetDoctors(res.data.doctors);
                    } else {
                        setSelectedTargetHospitals(res.data.hospitals);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            setViewName({ label: view?.view_name, value: view?.view_name, name: view?.view_name });
            setViewDescription({ label: view?.description, value: view?.description, name: view?.description });
            setDefaultViewType(null);
            setTier(null);
            setState(null);
            setSarcomaCenter(null);
            setTitle("Edit View");
            setDisabled(true);
        } else {
            setToDefaultState();
            setTitle("Create View");
        }
    }, [view]);

    useEffect(() => {
        axios
            .get(`/v1/rep_orchestrator/rep/target?tiers=TIER_1&page_number=1&page_size=200&fetch_type=MY_PHYSICIANS`,
                { headers: { 'x-rep-uid': repId } })
            .then((res) => {
                setDefaultSuperTargets(res.data.mappings[0].doctors);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`/v1/rep_orchestrator/rep/target?tiers=TIER_2&page_number=1&page_size=200&fetch_type=MY_PHYSICIANS`,
                { headers: { 'x-rep-uid': repId } })
            .then((res) => {
                setDefaultHighDecile(res.data.mappings[0].doctors);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`/v1/rep_orchestrator/rep/target?sarcoma_kols=true&page_number=1&page_size=200&fetch_type=MY_PHYSICIANS`,
                { headers: { 'x-rep-uid': repId } })
            .then((res) => {
                setDefaultKols(res.data.mappings[0].doctors);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`/v1/rep_orchestrator/rep/target?sarcoma_center=YES&page_number=1&page_size=200&fetch_type=MY_ACCOUNTS`,
                { headers: { 'x-rep-uid': repId } })
            .then((res) => {
                setDefaultSarcomaCenters(res.data.mappings[0].hospitals);
            })
            .catch((err) => {
                console.log(err);
            });


    }, []);


    useEffect(() => {
        if (enter) {
            onSearch();
        }
    }, [enter]);


    const getStates = (value) => {
        return value === 'HCP' ? repCityStateMappings[repId.substring(0, 5)] : repAccountStateCityMappings[repId.substring(0, 5)];
    }

    var statesQueryParam = getStates('HCP');

    const setToDefaultState = () => {
        setViewTargetType(null);
        setSelectedTargetDoctors([]);
        setSelectedTargetHospitals([]);
        setFormData({});
        setViewDescription(null);
        setDefaultViewType(null);
        setViewName(null);
        setTier(null);
        setState(null);
        setSarcomaCenter(null);
        setDisabled(false);
    };

    const removeAndAddDoctorOnCheckBox = (value, currItem, type) => {
        if (type === 'DOCTOR') {
            const index = selectedTargetDoctors.findIndex((item => item.id == currItem.id));
            setSelectedTargetDoctors(prev =>
                [
                    ...prev.slice(0, index),
                    Object.assign({}, prev[index], { 'executed': value }),
                    ...prev.slice(index + 1)
                ]
            );
        } else {
            const index = selectedTargetHospitals.findIndex((item => item.id == currItem.id));
            setSelectedTargetHospitals(prev =>
                [
                    ...prev.slice(0, index),
                    Object.assign({}, prev[index], { 'executed': value }),
                    ...prev.slice(index + 1)
                ]
            );
        }
    };

    const replaceSelectedDoctorsWithDefaultDoctors = (doctorList) => {
        setSelectedTargetDoctors(Array.from(new Set(doctorList)));
        if (targetDoctors) {
            for (let i = 0; i < doctorList.length; i++) {
                setTargetDoctors(targetDoctors.filter(item => item !== doctorList[i]));
            }
        }
    };

    const replaceSelectedHospitalsWithDefaultHospitals = (hospitalList) => {
        setSelectedTargetHospitals(Array.from(new Set(hospitalList)));
        if (targetHospitals) {
            for (let i = 0; i < hospitalList.length; i++) {
                setTargetHospitals(targetHospitals.filter(item => item !== hospitalList[i]));
            }
        }
    };

    const removeFromTargetSelectedHospitals = (hospital) => {
        setSelectedTargetHospitals(selectedTargetHospitals.filter((item) => item.id !== hospital.id));
    };

    const removeFromTargetSelectedDoctors = (doctor) => {
        setSelectedTargetDoctors(selectedTargetDoctors.filter((item) => item.id !== doctor.id));
    };

    const addToTargetSelectedHospitals = (hospital) => {
        if (selectedTargetHospitals.findIndex((item) => item.id === hospital.id) === -1) {
            hospital.executed = false;
            setSelectedTargetHospitals([hospital, ...selectedTargetHospitals]);
            setTargetHospitals(targetHospitals.filter(item => item !== hospital));
        } else {
            swal('HCO already selected.');
        }
    };

    const addToTargetSelectedDoctors = (doctor) => {
        if (selectedTargetDoctors.findIndex((item) => item.id === doctor.id) === -1) {
            doctor.executed = false;
            setSelectedTargetDoctors([doctor, ...selectedTargetDoctors]);
            setTargetDoctors(targetDoctors.filter(item => item !== doctor));
        } else {
            swal('HCP already selected.');
        }
    };


    useEffect(() => {
        onSearch();
    }, [search, state, tier, sarcomaCenter]);


    const onSearch = (type) => {
        if (!type) {
            type = viewTargetType?.value ? viewTargetType.value : 'HCP';
        }
        var searchType = (type && type === 'HCP') ? 'MY_PHYSICIANS' : 'MY_ACCOUNTS';

        var queryParams = '';
        if (tier) {
            if (tier.value === 'ALL') {
                queryParams = queryParams + `&tiers=`;
            } else {
                queryParams = queryParams + `&tiers=${tier.value}`;
            }
        }

        if (state) {
            if (state.value === 'ALL') {
                queryParams = queryParams + `&state=`;
            } else {
                queryParams = queryParams + `&state=${state.value}`;
            }
        }

        if (sarcomaCenter) {
            queryParams = queryParams + `&sarcoma_center=${sarcomaCenter.value}`;
        }

        axios
            .get(`/v1/rep_orchestrator/rep/target?key_word=${search}&page_number=1&fetch_type=${searchType}&page_size=50${queryParams}`,
                { headers: { 'x-rep-uid': repId } })
            .then((res) => {
                if (searchType === 'MY_PHYSICIANS') {
                    setTargetDoctors(res.data.mappings[0].doctors);
                } else {
                    setTargetHospitals(res.data.mappings[0].hospitals);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const submitHandler = (e) => {
        let isValid = true;
        if (!viewName || !viewTargetType || !viewDescription) {
            swal('Please fill the required field');
            isValid = false;
        }

        e.preventDefault();
        if (!isValid)
            return;

        if (viewTargetType?.value === 'HCP') {
            if (selectedTargetDoctors.length === 0) {
                swal('Please select atleast one physician for the view');
                isValid = false;
            } else if (selectedTargetDoctors.length > 200) {
                swal('View cannot have more than 200 physicians');
                isValid = false;
            }
        } else {
            if (selectedTargetHospitals.length === 0) {
                swal('Please select atleast one Account for the view !!');
                isValid = false;
            } else if (selectedTargetHospitals.length > 200) {
                swal('View cannot have more than 200 Account !!');
                isValid = false;
            }
        }


        e.preventDefault();
        if (!isValid)
            return;


        if (view && Object.keys(view).length === 0) {

            const data = {
                'view_name': viewName?.value,
                'description': viewDescription?.value,
                'view_type': viewTargetType?.value === 'HCP' ? 'PHYSICIANS' : 'ACCOUNTS',
                'doctorIds': selectedTargetDoctors.map(x => x.id).toString(),
                'hospitalIds': selectedTargetHospitals.map(x => x.id).toString(),
                'doctors': selectedTargetDoctors,
                'hospitals': selectedTargetHospitals
            };

            axios
                .post(`/v1/rep_orchestrator/view/create`, data
                    , { headers: { 'x-rep-uid': repId } })
                .then((res) => {
                    console.log(res);
                    setSubmitting(false);
                    swal("Success!", `Created ${viewName?.label}  Successfully! Navigate to 'My Views' to see the view content `, "success")
                        .then((val) => {
                            setLargeModal(false);
                            setFormData({});
                        });
                    fetchAndSetMyViews();
                })
                .catch((err) => {
                    console.log(err);
                    swal("Something went wrong!", err.message, "error")
                    setSubmitting(false);
                });
        } else {
            var data = view;
            data['doctorIds'] = selectedTargetDoctors.map(x => x.id).toString();
            data['doctors'] = selectedTargetDoctors;
            data['hospitals'] = selectedTargetHospitals;
            data['hospitalIds'] = selectedTargetHospitals.map(x => x.id).toString();
            delete data['comments'];

            axios
                .put(`/v1/rep_orchestrator/view`, data
                    , { headers: { 'x-rep-uid': repId } })
                .then((res) => {
                    console.log(res);
                    setSubmitting(false);
                    swal("Success!", `Saved ${viewName?.label}  Successfully!`, "success")
                        .then((val) => {
                            setLargeModal(false);
                            setFormData({});
                        })
                })
                .catch((err) => {
                    console.log(err);
                    swal("Something went wrong!", err.message, "error")
                    // if (err.response) {
                    //     // Request made and server responded
                    //     swal("Error!", err.response.data.error_message, "error")
                    // } else {
                    //     swal("Something went wrong!", err.message, "error")
                    // }
                });
        }
        setLargeModal(false);
    };

    const getPlaceDynamicDropdownInfo = () => {
        const keyname = ('default_views_list_').concat(viewTargetType ? viewTargetType.value : '');
        return modalOptionsMap[keyname]['options'];
    }

    var defaultSearchMap = {
        "super_targets": defaultSuperTargets,
        "high_decile": defaultHighDecile,
        "kols": defaultKols,
        // "fyarro_adopters": defaultViewFyarroAdopters,
        "sarcoma_centers": defaultSarcomaCenters,
        // "fyarro_adopters_accounts": defaultViewFyarroAdoptersAccounts,
    }

    return (
        <Modal
            centered
            show={largeModal}
            size="xl"
            onHide={() => setLargeModal(false)}
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <Button
                    variant=""
                    className="close"
                    onClick={() => {
                        setLargeModal(false);
                        // setToDefaultState();
                    }}
                >
                    <span>&times;</span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="card mb-0">
                    <span style={{ fontSize: '12px', fontStyle: 'italic' }}> Mandatory fields are marked with an asterisk (*)</span>
                    {formType === 'VIEW' ? <span style={{ fontSize: '12px', fontStyle: 'italic' }}> Maximum records/entries allowed in a view is 200</span> : ''}
                    <form onSubmit={(e) => submitHandler(e)}>
                        <div className="row mx-0 row-cols-1 row-cols-xl-2 py-4">
                            <div className="form-group row mx-0">
                                <label className="col-sm-5 col-form-label text-capitalize">
                                    View Name*
                                </label>
                                <div className="col-sm-6">
                                    <input
                                        type='text'
                                        className="form-control text-center"
                                        placeholder={"Enter View Name"}
                                        disabled={disabled? "disabled" : ""}
                                        value={viewName ? viewName.value : ''}
                                        onChange={(e) => {
                                            setViewName({ "label": e.target.value, "value": e.target.value });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form-group row mx-0">
                                <label className="col-sm-5 col-form-label text-capitalize">
                                    View Type*
                                </label>
                                <div className="col-sm-6">
                                    <Select
                                        styles={primaryStyle}
                                        placeholder={'Select View Type'}
                                        value={viewTargetType}
                                        isDisabled={disabled}
                                        options={viewTargetTypes}
                                        onChange={(e) => {
                                            setViewTargetType(e);
                                            setState(null);
                                            onSearch(e.value);
                                            setDefaultViewType(null);
                                            statesQueryParam = getStates(e.value);
                                        }} />
                                </div>
                            </div>
                            <div className="form-group row mx-0">
                                <label className="col-sm-5 col-form-label text-capitalize">
                                    View Description*
                                </label>
                                <div className="col-sm-6">
                                    <input
                                        type='text'
                                        className="form-control text-center"
                                        placeholder={"Enter View Description"}
                                        disabled={disabled}
                                        value={viewDescription ? viewDescription.value : ''}
                                        onChange={(e) => {
                                            setViewDescription({ "label": e.target.value, "value": e.target.value });
                                        }}
                                    />
                                </div>
                            </div>
                            {view && Object.keys(view).length > 0 ?
                            ''
                            :
                            <div className="form-group row mx-0">
                                <label className="col-sm-5 col-form-label text-capitalize">
                                    Default View Type
                                </label>
                                <div className="col-sm-6">
                                    <Select
                                        styles={primaryStyle}
                                        placeholder={'Select Default View'}
                                        value={defaultViewType}
                                        options={getPlaceDynamicDropdownInfo()}
                                        onChange={(e) => {
                                            setDefaultViewType(e);
                                            if (e.name === 'default_views_list_HCP') {
                                                replaceSelectedDoctorsWithDefaultDoctors(defaultSearchMap[e.value]);
                                            } else if (e.name === 'default_views_list_HCO') {
                                                replaceSelectedHospitalsWithDefaultHospitals(defaultSearchMap[e.value]);
                                            }
                                        }} />
                                </div>
                            </div> 
                            }
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-lg-5 mb-3'>
                                    {
                                        viewTargetType ?
                                            (
                                                <div>
                                                    <form noValidate className="form-head" onSubmit={(event) => event.preventDefault()}>
                                                        <div>
                                                            <div style={{ "marginLeft": 20, "marginTop": 16 }} >
                                                                <p className='fs-18 text-dark align-items-center  justify-content-center font-w600 mb-0 py-4' >
                                                                    Add {viewTargetType?.value ? viewTargetType.value.concat("s") : 'targets'} from my geography
                                                                </p>
                                                            </div>
                                                            <div class="form-head d-flex flex-wrap  align-items-center  justify-content-center">
                                                                <div class="width650 mr-3 mb-3" style={{ "marginTop": 10, "paddingTop": 10, "paddingBottom": 0 }}>
                                                                    <div className="input-group search-area d-inline-flex  w-300 px-0 py-1 border">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={'Search '.concat(viewTargetType?.value).concat(' by name')}
                                                                            onChange={(e) => {
                                                                                setSearch(e.target.value);
                                                                            }}
                                                                        />
                                                                        <div className="input-group-append" style={{ "marginLeft": 0, "marginRight": 0, "paddingLeft": 0, "paddingTop": 0, "paddingBottom": 0 }}>
                                                                            <button
                                                                                type="button"
                                                                                className="input-group-text"
                                                                                onClick={() => onSearch()}
                                                                            >
                                                                                <i className="flaticon-381-search-2" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {(viewTargetType && viewTargetType.value === 'HCP') ?
                                                                (<div class="form-head d-flex flex-wrap  align-items-center  justify-content-center" style={{ "marginTop": 0, "paddingTop": 0, "paddingBottom": 15 }}>
                                                                    <div class="width160 mr-3 mb-3" style={{ "marginTop": 10, "paddingTop": 10, "paddingBottom": 0 }}>
                                                                        <Select
                                                                            styles={primaryStyle}
                                                                            placeholder="Tier"
                                                                            options={tiersQueryParam}
                                                                            value={tier}
                                                                            onChange={(e) => {
                                                                                setTier(e);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div class="width160 mr-3 mb-3" style={{ "marginTop": 10, "paddingTop": 10, "paddingBottom": 0 }}>
                                                                        <Select
                                                                            styles={primaryStyle}
                                                                            placeholder="State"
                                                                            options={statesQueryParam}
                                                                            value={state}
                                                                            onChange={(e) => {
                                                                                setState(e);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>) :
                                                                (<div class="form-head d-flex flex-wrap  align-items-center  justify-content-center" style={{ "marginTop": 0, "paddingTop": 0, "paddingBottom": 15 }}>
                                                                    <div class="width200 mr-3 mb-3" style={{ "marginTop": 10, "paddingTop": 10, "paddingBottom": 0 }}>
                                                                        <Select
                                                                            styles={primaryStyle}
                                                                            placeholder="Sarcoma Center"
                                                                            options={sarcomaCentersQueryParam}
                                                                            value={sarcomaCenter}
                                                                            onChange={(e) => {
                                                                                setSarcomaCenter(e);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div class="width110 mr-3 mb-3" style={{ "marginTop": 10, "paddingTop": 10, "paddingBottom": 0 }}>
                                                                        <Select
                                                                            styles={primaryStyle}
                                                                            placeholder="State"
                                                                            options={statesQueryParam}
                                                                            value={state}
                                                                            onChange={(e) => {
                                                                                setState(e);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>)
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            ) : ''
                                    }
                                    {
                                        (viewTargetType && viewTargetType.value === 'HCP' && targetDoctors) ?
                                            (
                                                <div>
                                                    <PerfectScrollbar
                                                        id="DZ_W_Todo2"
                                                        className="widget-media dz-scroll ps ps--active-y height400  mt-8 pr-5"
                                                    >
                                                        {
                                                            targetDoctors?.map((item) => (
                                                                <div className="d-flex mb-4">
                                                                    <div>
                                                                        <div className="font-weight-bold text-black">
                                                                            <Link
                                                                                to={{
                                                                                    pathname: "/user-profile/D" + item?.id,
                                                                                }}
                                                                                target="_blank"
                                                                            >
                                                                                {item?.display_name}
                                                                            </Link>
                                                                        </div>
                                                                        <div>{item.tier ? item.tier : 'Non Target'}{', '}{item?.address?.city}</div>
                                                                    </div>
                                                                    <div className="ml-auto">
                                                                        <div className="custom-control custom-checkbox mb-0 check-lg">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary rounded-pill w-20 py-2"
                                                                                onClick={() => addToTargetSelectedDoctors(item)}
                                                                            >
                                                                                Add
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </PerfectScrollbar>
                                                </div>

                                            )
                                            :
                                            (
                                                // <div className="alert alert-danger" role="alert" style={{ "marginTop": 30, "paddingTop": 10, "paddingBottom": 0 }}>
                                                //   No results found
                                                // </div>

                                                ''
                                            )
                                    }
                                    {
                                        (viewTargetType && viewTargetType.value === 'HCO' && targetHospitals) ?
                                            (
                                                <div>
                                                    <PerfectScrollbar
                                                        id="DZ_W_Todo2"
                                                        className="widget-media dz-scroll ps ps--active-y height400  mt-8 pr-5"
                                                    >
                                                        {
                                                            targetHospitals?.map((item) => (
                                                                <div className="d-flex mb-4">
                                                                    <div>
                                                                        <div className="font-weight-bold text-black">
                                                                            <Link
                                                                                to={{
                                                                                    pathname: "/user-profile/H" + item?.id,
                                                                                }}
                                                                                target="_blank"
                                                                            >
                                                                                {item?.hospital_name}
                                                                            </Link>
                                                                        </div>
                                                                        <div>{item?.sarcoma_specialized ? "Sarcoma Center," : ""}{item?.address?.city}</div>
                                                                    </div>
                                                                    <div className="ml-auto">
                                                                        <div className="custom-control custom-checkbox mb-0 check-lg">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary rounded-pill w-20 py-2"
                                                                                onClick={() => addToTargetSelectedHospitals(item)}
                                                                            >
                                                                                Add
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </PerfectScrollbar>
                                                </div>
                                            ) : ''
                                    }
                                </div>
                                <div className='col-lg-7 mb-3'>
                                    {
                                        viewTargetType && viewTargetType.value === 'HCP' ?
                                            (
                                                <div className="col-sm-12">
                                                    <p className='fs-18 text-dark font-w600 mb-0 py-4'>
                                                        Selected HCPs (#{selectedTargetDoctors.length})
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary rounded-pill px-3"
                                                            style={{ "marginLeft": 100, "paddingLeft": 30 }}
                                                            disabled={selectedTargetDoctors.length === 0}
                                                            onClick={() => { setSelectedTargetDoctors([]); }}
                                                        >
                                                            Clear all HCPs
                                                        </button>
                                                    </p>
                                                    <div className="card">
                                                        <div className="card-body p-0">
                                                            <div className="dataTables_wrapper">
                                                                <Table
                                                                    responsive
                                                                    striped
                                                                    hover
                                                                    id="patientTable_basic_table"
                                                                    className="display dataTable no-footer  w-100"
                                                                >
                                                                    <thead>
                                                                        <tr role="row">
                                                                            <th className="sorting_asc">
                                                                                <div className="d-flex align-items-top">
                                                                                    <span>Name</span>
                                                                                    <div className="d-flex flex-column ml-2">
                                                                                        <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => {
                                                                                            setSelectedTargetDoctors(sortTable('display_name', 'DOCTORS', 0, selectedTargetDoctors));
                                                                                        }} />
                                                                                        <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() => setSelectedTargetDoctors(sortTable('display_name', 'DOCTORS', 1, selectedTargetDoctors))} />
                                                                                    </div>
                                                                                </div>
                                                                            </th>
                                                                            <th className="sorting">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span>Record</span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="sorting">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span>Remove</span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="sorting">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span>Executed</span>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {selectedTargetDoctors?.map((item) => (
                                                                            <tr role="row" className={item?.trClassName}>
                                                                                <td>{item?.display_name}
                                                                                    <div>{item.tier ? item?.tier : ''}</div></td>
                                                                                <td>
                                                                                    <Link
                                                                                        to={{
                                                                                            pathname: "/call-record/" + item?.id,
                                                                                        }}
                                                                                        target="_blank"
                                                                                    >
                                                                                        <img
                                                                                            alt='pic'
                                                                                            className='rounded mr-sm-4 mr-0'
                                                                                            width={40}
                                                                                            src={cr}
                                                                                        />
                                                                                    </Link>
                                                                                </td>
                                                                                <td>
                                                                                    <i
                                                                                        className="flaticon-381-trash scale2"
                                                                                        onClick={() => {
                                                                                            removeFromTargetSelectedDoctors(item);
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <div className="custom-control custom-checkbox mb-0 check-lg">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="custom-control-input"
                                                                                            id={item.id}
                                                                                            checked={ item.executed}
                                                                                            onChange={(e) => removeAndAddDoctorOnCheckBox(e.target.checked, item, 'DOCTOR')}
                                                                                        />
                                                                                        <label
                                                                                            className="custom-control-label"
                                                                                            htmlFor={item.id}
                                                                                        ></label>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : ''
                                    }
                                    {
                                        viewTargetType && viewTargetType.value === 'HCO' ?

                                            (<div className="col-sm-12">
                                                <p className='fs-18 text-dark font-w600 mb-0 py-4'>
                                                    Selected HCOs {'('}#{selectedTargetHospitals.length}{')'}
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary rounded-pill px-3"
                                                        style={{ "marginLeft": 100, "paddingLeft": 30 }}
                                                        disabled={selectedTargetHospitals.length === 0}
                                                        onClick={() => { setSelectedTargetHospitals([]); }}
                                                    >
                                                        Clear all HCOs
                                                    </button>
                                                </p>
                                                <div className="card">
                                                    <div className="card-body p-0">
                                                        <div className="dataTables_wrapper">
                                                            <Table
                                                                responsive
                                                                striped
                                                                hover
                                                                id="patientTable_basic_table"
                                                                className="display dataTable no-footer  w-100"
                                                            >
                                                                <thead>
                                                                    <tr role="row">
                                                                        <th className="sorting_asc">
                                                                            <div className="d-flex align-items-top">
                                                                                <span>Name</span>
                                                                                <div className="d-flex flex-column ml-2">
                                                                                    <i className="fa fa-sort-up line-height-0 btn btn-sort p-1" onClick={() => {
                                                                                        setSelectedTargetHospitals(sortTable('hospital_name', 'DOCTORS', 0, selectedTargetHospitals));
                                                                                    }} />

                                                                                    <i className="fa fa-sort-down line-height-0 btn btn-sort p-1" onClick={() =>
                                                                                        setSelectedTargetHospitals(sortTable('hospital_name', 'DOCTORS', 1, selectedTargetHospitals))
                                                                                    } />
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th className="sorting">
                                                                            <div className="d-flex align-items-center">
                                                                                <span>Record</span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="sorting">
                                                                            <div className="d-flex align-items-center">
                                                                                <span>Remove</span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="sorting">
                                                                            <div className="d-flex align-items-center">
                                                                                <span>Executed</span>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {selectedTargetHospitals?.map((item) => (
                                                                        <tr role="row" className={item?.trClassName}>
                                                                            <td>{item?.hospital_name}</td>
                                                                            <td>
                                                                                <Link
                                                                                    to={{
                                                                                        pathname: "/call-record-hospital/" + item?.id,
                                                                                    }}
                                                                                    target="_blank"
                                                                                >
                                                                                    <img
                                                                                        alt='pic'
                                                                                        className='rounded mr-sm-4 mr-0'
                                                                                        width={40}
                                                                                        src={cr}
                                                                                    />
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                <i
                                                                                    className="flaticon-381-trash scale2"
                                                                                    onClick={() => {
                                                                                        removeFromTargetSelectedHospitals(item);
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <div className="custom-control custom-checkbox mb-0 check-lg">
                                                                                    <input
                                                                                       type="checkbox"
                                                                                       className="custom-control-input"
                                                                                       id={item.id}
                                                                                       checked={item.executed ? true : false}
                                                                                       onClick={(e) => removeAndAddDoctorOnCheckBox(e.target.checked, item, 'HOSPITAL')}
                                                                               
                                                                                    />
                                                                                    <label
                                                                                        className="custom-control-label"
                                                                                        htmlFor={item.id}
                                                                                    ></label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>))
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>) : ''
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="mt-5 mb-4 d-flex align-items-center justify-content-center flex-column flex-md-row">
                            <button
                                type="submit"
                                className="btn btn-primary rounded-pill px-5"
                                disabled={submitting}
                            >
                                {view && Object.keys(view).length > 0 ? 'Save the View' : 'Create View'}
                            </button>
                            <span className="my-3 mx-3" />
                            <button type="button"
                                className="btn bg-primary-alt text-black rounded-pill px-5"
                                onClick={() => {
                                    setLargeModal(false);
                                    // setToDefaultState();
                                }}>
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default RepViewModal;
