import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
import logo from "../../../images/logo.png";
import logoText from "../../../images/logo-text.png";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);

  const refreshPage = ()=>{
    window.location.reload();
  }

  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo" >
        <a onClick={() => {window.location.href="/"}}>
          <img className="logo-abbr" src={logo} alt="" center-block />
          <img className="logo-compact" src={logoText} alt="" />
          </a>
          {/* <button onClick={refreshPage}>Refresh</button> */}
          {/* <img className="brand-title" src={logoText} alt="" /> */}
          {/* <div className="brand-title text-nowrap text-primary h3 mb-0 font-w900 text-uppercase">
          CRM
        </div> */}
        {/* </a> */}
      </Link>

    </div>
  );
};

export default NavHader;
