import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useParams } from "react-router";

import swal from "sweetalert";
import loadable from "@loadable/component";
import HomeTableSearch from "../table/HomeTableSearch";
import HomeModal from "../webRepModals/HomeModal";
import { primaryStyle } from "../PluginsMenu/Select2/SelectStyles";
import AddUserModal from "../webRepModals/AddUserModal";
import { CSVLink } from "react-csv";
// Images
import widget01 from "../../../images/rep_image.png";
import widget02 from "../../../images/widget/2.jpg";
import widget03 from "../../../images/widget/3.jpg";
import { Tab, Nav } from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useKeypress from '../../../hooks/useKeypress'
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
const ApexLine5 = loadable(() => import("../charts/apexcharts/Line5"));

const WebRepDashboard = () => {
  const [modalCentered, setModalCentered] = useState(false);
  const { id } = useParams();
  const enter = useKeypress('Enter');
  const repId = useSelector((state) => state.firebase.auth.uid);
  const [reportDownloadList,setReportDownloadList] = useState([]);
  const [search, setSearch] = useState('');
  const [bulkUploadList,setBulkUploadList] = useState([]);
  const [uploadType, setUploadType] = useState('');
  const [downloadType, setDownloadType] = useState('');
  const [downloadCsvData, setDownloadCsvData] = useState('');
  const [recentReps,setRecentReps] = useState([]);
  const [formType, setForm] = useState("doctors");
  const [show, setShow] = useState(false);
  const [searching, setSearching] = useState(false);
  const [secondType, setSecondType] = useState("PHYSICIANS");
  const [speciality, setSpeciality] = useState('');
  const [searchedData, setSearchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tiers, setTiers] = useState('');
  const [isSerachActive, setSearchActive] = useState(true);
  const [totalRecord,setTotalRecord]=useState(0)
  const [page,setPage]=useState(1);
  const [adminStats,setAdminStats] = useState({});
  const [graphStats,setGraphStats] = useState({});
  const [isGraphLoading, setIsGraphLoading] = useState(true);
  const roles = [
    { value: "REPRESENTATIVES", label: "Representatives" },
    { value: "PHYSICIANS", label: "Physicians" },
    { value: "ACCOUNTS", label: "Accounts" },
  ];
  const options = [
    {value:"vanilla",label: "Vanilla"}
  ];
  const tierMappings = {
    "TIER_1": "Super Targets",
    "TIER_2": "High Decile",
    "TIER_3": "Medium Decile",
    "TIER_4": "Low Decile",
    'ALL': 'All'
  }

  const hiddenFileInput = React.useRef(null);
  const csvLink = React.useRef(null);

  const handleDownloadClick = () => {
    if(!downloadType){
      swal("Alert","Select a option to download");
    }
    else{
      axios.post(`/v1/rep_orchestrator/admin/report?rep_id=${repId}&report_type=${downloadType}`)
        .then((res) => {
          setDownloadCsvData(res.data);
          swal("Success!!", "Download Successful!!","success");
          csvLink?.current?.link?.click();
        })
        .catch((err) => {
          swal("Error", "Error Downloading","error");
        })
    }
  }

  const updateDownloadType = (e) => {
    setDownloadType(e.value);
  }

  const inputs = [
    { name: "name", type: "text" },
    { name: "NPID", type: "text" },
    { name: "AGE", type: "text" },
    { name: "speciality", select: true },
    { name: "ZIP", type: "text" },
    { name: "Hospitals", type: "text" },
    { name: "Address", type: "text" },
    { name: "Phone", type: "text" },
    { name: "Display Name", type: "text" },
  ];

  const handleModal=(type)=>{
    setForm(type);
    setShow(true);
  }

  const recentArr = [
    { src: widget01, name: "Tom" },
    { src: widget02, name: "Aziz Bakree" },
    { src: widget03, name: "john Bakree" },
    { src: widget01, name: "Oconner" },
    { src: widget01, name: "Tom" },
    { src: widget02, name: "Aziz Bakree" },
    { src: widget03, name: "john Bakree" },
    { src: widget01, name: "Oconner" },
  ];
  const ROW_PER_PAGE=20;


  useEffect(()=>{
    axios
      .get(`/v1/rep_orchestrator/admin/stats?rep_id=${repId}`)
      .then((res) => {

        setReportDownloadList(res.data.report_download_list.map(val => {
         const obj = {};
         obj.value = val;
         obj.label = val;
         return obj;
        }));
        setBulkUploadList(res.data.bulk_upload_list.map(val=>{
          const obj = {};
          obj.value = val;
         obj.label = val;
         return obj;
        }));
        setRecentReps(res.data.recent_reps);
        setAdminStats({total_doctors_count:res.data.total_doctors_count,total_hospitals_count:res.data.total_hospitals_count,total_representatives_count:res.data.total_representatives_count});
        setGraphStats({month_year: res.data.month_year, month_year_count: res.data.month_year_count});
        setIsGraphLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  },[])

  useEffect(() => {
    if (searching) {
      onSearch();
    }
  }, [secondType,page,tiers])


  useEffect(() => {
    if (isSerachActive || enter) {
      setSearchActive(false);
      onSearch();
    }
  }, [enter])


  const sortTable = (name, type, order) => {

    console.log(" name 1= ", name);

    const newSData = [].concat(searchedData);
    if (newSData) {
      console.log(" before data = ", newSData);
      if (order === 1) {
        if (name instanceof Array) {
          console.log(" name = ", name);
          newSData.sort((a, b) => {
            return a[name[0]][name[1]]?.toLowerCase() > b[name[0]][name[1]]?.toLowerCase() ? 1 : -1;
          });
        } else {
          newSData.sort((a, b) => {
            if(name === "tier"){
              let extraTier = "TIER_"+ (Object.keys(tierMappings).length+1);
              let aName = a[name] ?? extraTier , bName = b[name] ?? extraTier;
              return bName?.toLowerCase() > aName?.toLowerCase() ? 1 : -1; 
            }
            if (typeof a[name] === 'string') {
              return a[name]?.toLowerCase() > b[name]?.toLowerCase() ? 1 : -1;
            }
            return a[name] > b[name] ? 1 : -1;
          });
        }
      } else {
        if (name instanceof Array) {
          console.log(" name = ", name);
          newSData.sort((a, b) => {
            return b[name[0]][name[1]]?.toLowerCase() > a[name[0]][name[1]]?.toLowerCase() ? 1 : -1;
          });
        } else {
          newSData.sort((a, b) => {
            if(name === "tier"){
              let extraTier = "TIER_"+ (Object.keys(tierMappings).length+1);
              let aName = a[name] ?? extraTier , bName = b[name] ?? extraTier;
              return bName?.toLowerCase() < aName?.toLowerCase() ? 1 : -1;
            }
            
            if (typeof a[name] === 'string') {
              return b[name]?.toLowerCase() > a[name]?.toLowerCase() ? 1 : -1;
            }
            return b[name] > a[name] ? 1 : -1;
          });
        }
      }
      setSearchedData(newSData);
      console.log(" after sort ", newSData);
    }
  };

  const onSearch = () => {
    setSearchActive(false);

    if (secondType !== null) {
      let tierPassed = tiers ? (tiers === "ALL" ? false : true) : false;
      let specialityPassed = speciality ? (speciality === "ALL" ? false : true) : false;
      let query = `&page_size=${ROW_PER_PAGE}${tierPassed ? `&tiers=${tiers}` : ''}${specialityPassed ? `&specialities=${speciality}` : ''}&page_number=${page}`;
      if (secondType === "PHYSICIANS") {
        setSearching(true);
        setLoading(true);
        axios
          .get(`v1/rep_orchestrator/doctor/search?keyword=${search}${query}` ,
          { headers: { 'x-rep-uid': repId } })
          .then((res) => {
            setSearchedData(res.data.doctors);
            setTotalRecord(res.data.total)
            setLoading(false);
          })
          .catch((err) => console.log(err));
          console.log(" physicians ", searchedData);

      } else if (secondType === "ACCOUNTS"){
        setSearching(true);
        setLoading(true);
        axios
          .get(`v1/rep_orchestrator/hospital/search?keyword=${search}${query}`
          ,{ headers: { 'x-rep-uid': repId }})
          .then((res) => {
            setSearchedData(res.data.hospitals);
            setTotalRecord(res.data.total)
            setLoading(false);
          })
          .catch((err) => console.log(err));
      }
      else if (secondType === "REPRESENTATIVES"){
        setSearching(true);
        setLoading(true);
        axios
          .get(`v1/rep_orchestrator/rep/search?keyword=${search}${query}`,{ headers: { 'x-rep-uid': repId }})
          .then((res) => {
            setSearchedData(res.data.reps);
            setTotalRecord(res.data.reps.length);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      }
    } else {
      swal("Something went wrong!", "Please Fill both fields", "error");
    }
  };

  const handleUploadClick = event => {
    hiddenFileInput.current.click();
  };
  const updateUploadType = (event) => setUploadType(event.value);
  const sendUploadedFile = (event) => {
    let file = event.target.files[0];

    var data = new FormData();
    data.append('file', file);

    axios.post(`/v1/rep_orchestrator/admin/upload?rep_id=${repId}&upload_type=${uploadType}`, data,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      ).then( resp => {
        swal(`${uploadType} Uploaded successfully`)
      }).catch( error => {
        swal("Error Occured")
      });
  }

  return (
      <section>
         <HomeModal
    formType={formType}
    setLargeModal={setShow}
    largeModal={show}
  />
   <AddUserModal
        modalCentered={modalCentered}
        setModalCentered={setModalCentered}
      />
        <div className="mt-4 form-head d-flex mb-3 mb-md-4 align-items-center flex-wrap">
            <div className="width220 mr-4 mb-3">
              <Select
                styles={primaryStyle}
                placeholder="SELECT UPLOAD TYPE"
                options={bulkUploadList}
                onChange={updateUploadType}
              />
            </div>
            <div className="mt-2 form-head d-flex mb-2 mr-2 align-items-center flex-wrap">
              <input
                ref={hiddenFileInput}
                type="file"
                accept=".csv"
                style={{ display: 'none' }}
                onChange={sendUploadedFile}
                id="csv-upload"
              />
              <button onClick={handleUploadClick} type="button" className="mt-2 btn btn-primary btn-sm rounded-sm">
                Upload
              </button>
            </div>


            <div className="width170 mr-2 mt-2 ml-auto">
            <Select
              styles={primaryStyle}
              placeholder="Physicians"
              options={roles}
              onChange={(e) => {
                setSecondType(e.value);
                setSpeciality('')
              }}
            />
          </div>
          <div className="mt-2 mr-2 input-group search-area d-inline-flex">
          <input
              type="text"
              className="form-control text-center"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value);
                setSearchActive(true);

              }}
            />
            <div className="input-group-append">
              <button
                type="button"
                className="input-group-text"
                disabled={search.length === 0}
                onClick={onSearch}
              >
                <i className="flaticon-381-search-2" />
              </button>
            </div>
          </div>
          <div className="width170 mb-3 mr-3">
            <button
              type="button"
              className="btn btn-primary rounded-pill w-100 py-2"
              onClick={() => handleModal('DOCTOR')}
            >
              Add Physician
            </button>
          </div>
          <div className="width200 mb-3 mr-3">
            <button
              type="button"
              className="btn btn-primary rounded-pill w-100 py-2"
              onClick={() => handleModal('HOSPITAL')}
            >
              Add Account
            </button>
          </div>
          <div className="width250 mb-3 mr-3">
            <button
              type="button"
              className="btn btn-primary rounded-pill w-100 py-2"
              onClick={() =>setModalCentered(true)}
            >
              Add Representative
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-xxl-4 col-sm-4">
            <div className="card gradient-bx text-white bg-danger rounded">
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="mb-1">Total Representatives</p>
                    <div className="d-flex flex-wrap">
                      <h2 className="fs-40 font-w600 text-white mb-0 mr-3">
                        {adminStats.total_representatives_count}
                      </h2>
                    </div>
                  </div>
                  <span className="border rounded-circle p-4">
                    <svg
                      width={34}
                      height={34}
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.6667 0.333311C21.1963 0.264777 18.7993 1.17744 17 2.87164C15.2008 1.17744 12.8038 0.264777 10.3334 0.333311C8.9341 0.337244 7.55292 0.649469 6.28803 1.24778C5.02315 1.84608 3.90564 2.71577 3.01502 3.79498C-0.039984 7.45998 -0.261651 13.3333 1.19668 17.5966C1.21002 17.6266 1.22002 17.6566 1.23335 17.6866C3.91168 25.3333 15.2717 33.6666 17 33.6666C18.6983 33.6666 30.025 25.5166 32.7667 17.6866C32.78 17.6566 32.79 17.6266 32.8034 17.5966C34.2417 13.4016 34.0867 7.51498 30.985 3.79498C30.0944 2.71577 28.9769 1.84608 27.712 1.24778C26.4471 0.649469 25.0659 0.337244 23.6667 0.333311ZM17 30.03C14.6817 28.5233 8.23168 24 5.30335 18.6666H12C12.2743 18.6667 12.5444 18.599 12.7863 18.4696C13.0282 18.3403 13.2344 18.1532 13.3867 17.925L14.83 15.7583L17.0867 22.525C17.1854 22.8207 17.3651 23.0829 17.6054 23.2816C17.8456 23.4803 18.1368 23.6076 18.4458 23.6491C18.7548 23.6906 19.0693 23.6446 19.3535 23.5163C19.6376 23.388 19.8801 23.1825 20.0533 22.9233L22.8917 18.6666H28.6984C25.7684 24 19.3183 28.5233 17 30.03ZM29.975 15.3333H22C21.7257 15.3333 21.4556 15.4009 21.2137 15.5303C20.9718 15.6597 20.7656 15.8468 20.6133 16.075L19.17 18.2416L16.9133 11.475C16.8146 11.1792 16.6349 10.9171 16.3947 10.7184C16.1544 10.5196 15.8632 10.3923 15.5542 10.3508C15.2452 10.3093 14.9307 10.3553 14.6466 10.4837C14.3624 10.612 14.1199 10.8174 13.9467 11.0766L11.1084 15.3333H4.02502C3.35835 12.1816 3.50502 8.41164 5.57668 5.92831C6.151 5.22081 6.87614 4.65057 7.69911 4.25927C8.52209 3.86797 9.42209 3.6655 10.3334 3.66664C15.445 3.66664 14.9117 7.16664 16.9817 7.18664H17C19.0733 7.18664 18.5483 3.66664 23.6667 3.66664C24.5785 3.665 25.4792 3.86723 26.3027 4.25855C27.1263 4.64987 27.852 5.22037 28.4267 5.92831C30.4867 8.40331 30.6467 12.1666 29.975 15.3333Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-xxl-4 col-sm-4">
            <div className="card gradient-bx text-white bg-success rounded">
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="mb-1">Physicians</p>
                    <div className="d-flex flex-wrap">
                      <h2 className="fs-40 font-w600 text-white mb-0 mr-3">
                        {adminStats.total_doctors_count}
                      </h2>
                    </div>
                  </div>
                  <span className="border rounded-circle p-4">
                    <svg
                      width={34}
                      height={34}
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M37.3333 15.6666C37.3383 14.7488 37.0906 13.8473 36.6174 13.061C36.1441 12.2746 35.4635 11.6336 34.6501 11.2084C33.8368 10.7831 32.9221 10.5899 32.0062 10.65C31.0904 10.7101 30.2087 11.021 29.4579 11.5489C28.707 12.0767 28.1159 12.8011 27.7494 13.6425C27.3829 14.484 27.255 15.4101 27.3799 16.3194C27.5047 17.2287 27.8774 18.086 28.4572 18.7976C29.0369 19.5091 29.8013 20.0473 30.6667 20.3533V25.6666C30.6667 27.8768 29.7887 29.9964 28.2259 31.5592C26.6631 33.122 24.5435 34 22.3333 34C20.1232 34 18.0036 33.122 16.4408 31.5592C14.878 29.9964 14 27.8768 14 25.6666V23.8666C16.7735 23.4642 19.3097 22.0777 21.1456 19.9603C22.9815 17.8429 23.9946 15.1358 24 12.3333V2.33329C24 1.89127 23.8244 1.46734 23.5118 1.15478C23.1993 0.842221 22.7754 0.666626 22.3333 0.666626H17.3333C16.8913 0.666626 16.4674 0.842221 16.1548 1.15478C15.8423 1.46734 15.6667 1.89127 15.6667 2.33329C15.6667 2.77532 15.8423 3.19924 16.1548 3.5118C16.4674 3.82436 16.8913 3.99996 17.3333 3.99996H20.6667V12.3333C20.6667 14.5434 19.7887 16.663 18.2259 18.2258C16.6631 19.7887 14.5435 20.6666 12.3333 20.6666C10.1232 20.6666 8.00358 19.7887 6.44077 18.2258C4.87797 16.663 4 14.5434 4 12.3333V3.99996H7.33333C7.77536 3.99996 8.19928 3.82436 8.51184 3.5118C8.8244 3.19924 9 2.77532 9 2.33329C9 1.89127 8.8244 1.46734 8.51184 1.15478C8.19928 0.842221 7.77536 0.666626 7.33333 0.666626H2.33333C1.8913 0.666626 1.46738 0.842221 1.15482 1.15478C0.842259 1.46734 0.666664 1.89127 0.666664 2.33329V12.3333C0.672024 15.1358 1.68515 17.8429 3.52106 19.9603C5.35697 22.0777 7.8932 23.4642 10.6667 23.8666V25.6666C10.6667 28.7608 11.8958 31.7283 14.0837 33.9162C16.2717 36.1041 19.2391 37.3333 22.3333 37.3333C25.4275 37.3333 28.395 36.1041 30.5829 33.9162C32.7708 31.7283 34 28.7608 34 25.6666V20.3533C34.9723 20.0131 35.8151 19.3797 36.4122 18.5402C37.0092 17.7008 37.3311 16.6967 37.3333 15.6666Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-xxl-4 col-sm-4">
            <div className="card gradient-bx text-white bg-info rounded">
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="mb-1">Hospitals</p>
                    <div className="d-flex flex-wrap">
                      <h2 className="fs-40 font-w600 text-white mb-0 mr-3">
                        {adminStats.total_hospitals_count}
                      </h2>
                    </div>
                  </div>
                  <span className="border rounded-circle p-4">
                    <svg
                      width={34}
                      height={34}
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26.6667 3.33343C24.1963 3.2649 21.7993 4.17756 20 5.87177C18.2008 4.17756 15.8038 3.2649 13.3334 3.33343C11.9341 3.33737 10.5529 3.64959 9.28806 4.2479C8.02318 4.8462 6.90567 5.71589 6.01505 6.7951C2.96005 10.4601 2.73838 16.3334 4.19671 20.5968C4.21005 20.6268 4.22005 20.6568 4.23338 20.6868C6.91171 28.3334 18.2717 36.6668 20 36.6668C21.6984 36.6668 33.025 28.5168 35.7667 20.6868C35.78 20.6568 35.7901 20.6268 35.8034 20.5968C37.2417 16.4018 37.0867 10.5151 33.9851 6.7951C33.0944 5.71589 31.9769 4.8462 30.712 4.2479C29.4472 3.64959 28.066 3.33737 26.6667 3.33343ZM20 33.0301C17.6817 31.5234 11.2317 27.0001 8.30338 21.6668H15C15.2744 21.6668 15.5445 21.5991 15.7864 21.4698C16.0282 21.3404 16.2345 21.1533 16.3867 20.9251L17.83 18.7584L20.0867 25.5251C20.1854 25.8209 20.3651 26.083 20.6054 26.2817C20.8456 26.4804 21.1368 26.6078 21.4458 26.6493C21.7549 26.6908 22.0693 26.6447 22.3535 26.5164C22.6377 26.3881 22.8801 26.1827 23.0534 25.9234L25.8917 21.6668H31.6984C28.7684 27.0001 22.3184 31.5234 20 33.0301ZM32.975 18.3334H25C24.7257 18.3334 24.4556 18.4011 24.2137 18.5304C23.9718 18.6598 23.7656 18.8469 23.6134 19.0751L22.17 21.2418L19.9134 14.4751C19.8147 14.1793 19.635 13.9172 19.3947 13.7185C19.1544 13.5198 18.8633 13.3924 18.5542 13.3509C18.2452 13.3094 17.9308 13.3555 17.6466 13.4838C17.3624 13.6121 17.1199 13.8175 16.9467 14.0768L14.1084 18.3334H7.02505C6.35838 15.1818 6.50505 11.4118 8.57671 8.92843C9.15103 8.22093 9.87617 7.65069 10.6991 7.25939C11.5221 6.86809 12.4221 6.66562 13.3334 6.66677C18.445 6.66677 17.9117 10.1668 19.9817 10.1868H20C22.0734 10.1868 21.5484 6.66677 26.6667 6.66677C27.5785 6.66512 28.4792 6.86736 29.3028 7.25868C30.1264 7.64999 30.852 8.22049 31.4267 8.92843C33.4867 11.4034 33.6467 15.1668 32.975 18.3334Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        
          <div className="col-xl-12 col-xxl-12 col-lg-12">
            <HomeTableSearch
              type={secondType}
              data={searchedData}
              loading={loading}
              totalRecord={totalRecord}
              rowPerSize={ROW_PER_PAGE}
              setPage={setPage}
              page={page}
              pageSource={"ADMIN"}
              sortTable={sortTable}
            />
        </div>
          <div className="col-xl-9 col-xxl-8 col-lg-8">
            <Tab.Container defaultActiveKey="monthly">
              <div className="card">
                <div className="card-header d-sm-flex d-block border-0 pb-0">
                  <h3 className="fs-20 mb-3 mb-sm-0 text-black">
                    Representative Statistics
                  </h3>
                  <div className="card-action card-tabs mt-3 mt-sm-0 mt-3 mt-sm-0">
                    <Nav className="nav nav-tabs" role="tablist">
                      <Nav.Item className="nav-item">
                        <Nav.Link
                          className="nav-link"
                          data-toggle="tab"
                          eventKey="monthly"
                          role="tab"
                        >
                          Monthly
                        </Nav.Link>
                      </Nav.Item>
                      {/* <li className="nav-item">
                        <Nav.Link
                          className="nav-link"
                          data-toggle="tab"
                          eventKey="weekly"
                          role="tab"
                        >
                          Weekly
                        </Nav.Link>
                      </li>
                      <li className="nav-item">
                        <Nav.Link
                          className="nav-link"
                          data-toggle="tab"
                          eventKey="today"
                          role="tab"
                        >
                          Today
                        </Nav.Link>
                      </li> */}
                    </Nav>
                  </div>
                </div>
                <Tab.Content>
                  <div className="card-body pb-0">
                    <div className="tab-pan active show pb-0">
                      <div
                        className="chart-warp"
                        style={{ overflow: "hidden" }}
                      >
                        {
                         ! isGraphLoading ?
                         ( <ApexLine5 graphStats={graphStats} />):
                         ''
                        }
                       
                      </div>
                    </div>
                  </div>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
          <div className="col-xl-3 col-xxl-4 col-lg-5">
          <div className="card h-auto">
              <div className="card-header border-0 pb-0">
                <h3 className="fs-20 mb-0 text-black">Report Download</h3>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <Select
                    styles={primaryStyle}
                    placeholder="Download Options"
                    options={reportDownloadList}
                    onChange={updateDownloadType}
                  />
                </div>
                <div className="form-group">
                  <a
                    download
                    href
                    class="btn btn-primary btn-sm rounded-sm w-100"
                    onClick={handleDownloadClick}
                  >
                    Download
                  </a>
                  <CSVLink
                    data={downloadCsvData}
                    filename={`${downloadType}.csv`}
                    className='hidden'
                    ref={csvLink}
                    target='_blank'
                  />
                </div>
              </div>
            </div>
            <div className="card border-0 pb-0">
              <div className="card-header flex-wrap border-0 pb-0">
                <h3 className="fs-20 mb-0 text-black">Recent Reps</h3>
                {/* <Link to="/patient-list" className="text-primary font-w500">
                  More &gt;&gt;
                </Link> */}
              </div>
              <div className="card-body">
                <PerfectScrollbar
                  id="DZ_W_Todo2"
                  className="widget-media dz-scroll ps ps--active-y height260"
                >
                  <ul className="timeline">
                    {recentReps?.map((item) => (
                      <li>
                        <div className="timeline-panel flex-wrap">
                          <div className="media mr-3">
                            <img
                              className="rounded-circle"
                              alt="widget"
                              width={50}
                              src={recentArr[0].src}
                            />
                          </div>
                          <div className="media-body">
                            <h5 className="mb-1">
                              <Link
                                className="text-black text-capitalize"
                                to={{
                                  pathname: "/user-profile/R" + item?.id,
                                }}
                              >
                                {item.first_name}
                              </Link>
                            </h5>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </PerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default WebRepDashboard;