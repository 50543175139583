import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { signIn } from "../../../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

const WebLoginPage = ({ history }) => {
  const { user, isAuthenticated, isLoading } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({});
  const handleBlur = (e) => {
    const newLoginData = { ...loginData };
    newLoginData[e.target.name] = e.target.value;
    setLoginData(newLoginData);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    const credential = {
      email: loginData.email,
      password: loginData.password,
    };

    dispatch(signIn(credential));
    // history.push("/");
  };

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated, history, isLoading]);

  return (
    <div className="authincation">
      <div className="container h-100 my-0">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h4 className="text-center mb-4">
                      Sign in to your Aadi Bio CRM account
                    </h4>
                    <form action="" onSubmit={(e) => submitHandler(e)}>
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          onChange={handleBlur}
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          name="password"
                          onChange={handleBlur}
                        />
                      </div>
                      <div className="form-row d-flex justify-content-between mt-4 mb-2">
                        {/* <div className="form-group">
                          <div className="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="basic_checkbox_1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="basic_checkbox_1"
                            >
                              Remember my preference
                            </label>
                          </div>
                        </div> */}
                        <div className="form-group">
                          <Link to=
                          {{
                            pathname: "/forgot-password",
                          }}>
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      <div className="text-center">
                        <input
                          type="submit"
                          value="Sign Me In"
                          className="btn btn-primary btn-block"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebLoginPage;
