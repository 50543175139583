import React, { useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { signIn, signUp } from "../../../store/actions/authActions";
import { useDispatch } from "react-redux";

const AddUserModal = ({ modalCentered, setModalCentered }) => {
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({});
  const handleBlur = (e) => {
    const newLoginData = { ...loginData };
    newLoginData[e.target.name] = e.target.value;
    setLoginData(newLoginData);
  };
  const submitHandler = (e) => {
    e.preventDefault();

    const credential = {
      email: loginData.email,
      password: loginData.password,
    };

    dispatch(signUp(credential, setModalCentered));

    console.log(e);
    // history.push("/");
  };

  return (
    <Modal
      className="fade"
      show={modalCentered}
      onHide={() => setModalCentered(false)}
    >
      <Modal.Header>
        <Modal.Title>Add User</Modal.Title>
        <Button
          onClick={() => setModalCentered(false)}
          variant=""
          className="close"
        >
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-12 col-lg-12">
          <div className="card mb-0">
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => submitHandler(e)}>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter First Name"
                        name="username"
                        onChange={handleBlur}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        onChange={handleBlur}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        onChange={handleBlur}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>City</label>
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        onChange={handleBlur}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Check me out</label>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Add User
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={() => setModalCentered(false)} variant="danger light">
          Close
        </Button>
        <Button variant="primary">Save changes</Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default AddUserModal;
