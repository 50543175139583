import React, { useEffect, useState } from "react";
import axios from "axios";

import { useSelector } from "react-redux";
// import SignatureCanvas from 'react-signature-canvas'
import Select from "react-select";
import { primaryStyle } from "../PluginsMenu/Select2/SelectStyles";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";

import { requiredDataFormTypeMap } from "../table/RepModalConstants";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";

import swal from "sweetalert";
import '../../../css/input.css';



// This form will ask a specific set of questions to doctor id
const MirfFormModal = ({ setLargeModal, largeModal, formType, hcp, currentMirf, fetchAndSetMyMirfs }) => {

    const [formData, setFormData] = useState({});
    const [profile, setProfile] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [disabledSure, setDisabledSure] = useState(true);
    const [disabledOnUpdate, setDisabledOnUpdate] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [visitType, setVisitType] = useState({ label: "In Person", value: "IN_PERSON", name: 'view_type', disabled: disabled });
    const repId = useSelector((state) => state.firebase.auth.uid);

    const [repProfile, setRepProfile] = useState({});
    const [formName, setFormName] = useState(null);
    const [formNpi, setFormNpi] = useState(null);
    const [formOffice, setFormOffice] = useState(null);
    const [formDegree, setFormDegree] = useState(null);
    const [formStreetAddress, setFormStreetAddress] = useState(null);
    const [formCity, setFormCity] = useState(null);
    const [formMedicalInfoRequest, setFormMedicalInfoRequest] = useState(null);
    const [formSubmittedBy, setFormSubmittedBy] = useState(null);
    const [formProduct, setFormProduct] = useState({ label: "Fyarro", value: "Fyarro", name: 'product'});
    const [formState, setFormState] = useState(null);
    const [formZipCode, setFormZipCode] = useState(null);
    const [formTelephone, setFormTelephone] = useState(null);
    const [formFax, setFormFax] = useState(null);
    const [formSpeciality, setFormSpeciality] = useState(null);
    const [formEmail, setFormEmail] = useState(null);
    const [formRepEmail, setFormRepEmail] = useState(null);
    const [formDateOfRequest, setFormDateOfRequest] = useState(null);
    const [formConsent, setFormConsent] = useState({
        'checked': false,
    });
    // const [formSignature, setFormSignature] = useState({});
    const [deliveryMethodStatus, setDeliveryMethodStatus] = useState({
        "EMAIL": false,
        "FAX": false,
        "CUSTOM_DELIVERY": false
    })
    const [isSigned, setIsSigned] = useState({
        "status": false,
    });



    useEffect(() => {
        axios
            .get(`/v1/rep_orchestrator/rep/search?rep_id=${repId}`, { headers: { 'x-rep-uid': repId } })
            .then((res) => {
                setRepProfile(res.data.reps[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [])

    useEffect(() => {
        if (currentMirf) {
            setDisabledOnUpdate(true);
            setVisitType(currentMirf.visit_type === 'IN_PERSON' ?
                { label: "In Person", value: "IN_PERSON", name: 'view_type', disabled: disabled } :
                { label: "Virtual", value: "VIRTUAL", name: "view_type", disabled: disabled },
            );

            if (currentMirf?.preferred_delivery_method) {
                console.log("currentMirf.preferred_delivery_methods", currentMirf.preferred_delivery_method);
                var methods = {};
                currentMirf?.preferred_delivery_method.forEach((method) => {
                   methods[method] = true;
                })
                setDeliveryMethodStatus(methods);
            }
            setFormName(currentMirf.name);
            setFormNpi(currentMirf.npi);
            setFormState(currentMirf?.state);
            setFormOffice(currentMirf?.office);
            setFormDegree(currentMirf?.degree);
            setFormStreetAddress(currentMirf?.street_address);
            setFormMedicalInfoRequest(currentMirf?.medical_info_request);
            setFormCity(currentMirf?.city);
            setFormZipCode(currentMirf?.zipcode);
            setFormTelephone(currentMirf?.telephone);
            setFormFax(currentMirf?.fax);
            setFormSpeciality(currentMirf?.speciality);
            setFormEmail(currentMirf?.email);
            setFormRepEmail(currentMirf?.rep_email);
            setFormDateOfRequest(currentMirf?.requested_date);
            // setFormSignature(currentMirf?.signature);
            setFormProduct({ label: currentMirf?.product, value: currentMirf?.product, name: 'product'});
        }
    }, [currentMirf]);

    useEffect(() => {
        if (hcp) {
            setDisabledOnUpdate(false);
            setFormName(hcp?.display_name);
            setDeliveryMethodStatus({
                "EMAIL": false,
                "FAX": false,
                "CUSTOM_DELIVERY": false
            });
            setFormNpi(hcp?.npid);
            setFormStreetAddress(hcp?.address?.street);
            setFormCity(hcp?.address?.city);
            setFormState(hcp?.address?.state);
            setFormZipCode(hcp?.address?.zipcode);
            setFormSpeciality(hcp?.primary_speciality);
            setFormSubmittedBy(repProfile?.first_name);
            setFormRepEmail(repProfile?.email);
            setFormEmail(hcp?.email);
            setFormDegree(null);
            setFormOffice(null);
            setFormProduct({ label: "Fyarro", value: "Fyarro", name: 'product'});
            setFormTelephone(null);
            setFormFax(null);
            setFormMedicalInfoRequest(null);
            // setFormSignature(null);
            setFormDateOfRequest(getTodayDate());
            handleSign(false);
            handleFormConsent(false);
        }
    }, [hcp]);

    const visitTypes = [
        { label: "In Person", value: "IN_PERSON", name: 'view_type', disabled: disabled },
        { label: "Virtual", value: "VIRTUAL", name: "view_type", disabled: disabled },
    ];

    const productTypes = [
        { label: "Fyarro", value: "Fyarro", name: 'product'},
        { label: "nab-Sirolimus", value: "nab-Sirolimus", name: 'product'},
        { label: "Other", value: "Other", name: 'product'}
    ]

    const getIsDisabled = () => {

        if (!formConsent || !formConsent.checked) {
            console.log(" disabling because of consent ", formConsent);
            return true;
        }
        // if ( visitType && visitType === 'IN_PERSON' && !isSigned.status ) {
        //     console.log("disabling because of sign ");
        //     return true;
        // }
        return false;
    }

    const fetchDeliveryMethods = () => {
        var deliveryMethods = [];
        for (var key in deliveryMethodStatus) {
            if (deliveryMethodStatus[key]) {
                deliveryMethods.push(key);
            }
        }
        return deliveryMethods;
    }

    const clearAllStates = () => {
        setFormDegree(null);
        setFormOffice(null);
        setFormProduct({ label: "Fyarro", value: "Fyarro", name: 'product'});
        setFormTelephone(null);
        setFormFax(null);
        setFormMedicalInfoRequest(null);
        // setFormSignature(null);
        setFormDateOfRequest(getTodayDate());
        handleSign(false);
        handleFormConsent(false);
        setFormEmail(hcp?.email);
        setDeliveryMethodStatus({
            "EMAIL": false,
            "FAX": false,
            "CUSTOM_DELIVERY": false
        });
        setVisitType({ label: "In Person", value: "IN_PERSON", name: 'view_type', disabled: disabled });
        setFormSpeciality(hcp?.primary_speciality);
        //street city address, city state zipcode
        
        setFormStreetAddress(hcp?.address?.street);
        setFormCity(hcp?.address?.city);
        setFormState(hcp?.address?.state);
        setFormZipCode(hcp?.address?.zipcode);
    };


    const getDisplayNameForRequiredField = (key) => {
        const requiredFieldDisplayNameMap = {
            "street_address": "Street Address",
            "degree": "Degree/ Profession"
        }
        return requiredFieldDisplayNameMap[key] ? requiredFieldDisplayNameMap[key] : key;
    };

    const submitHandler = (e) => {

        const formData = {
            name: formName,
            npi: formNpi,
            office: formOffice,
            degree: formDegree,
            street_address: formStreetAddress,
            city: formCity,
            state: formState,
            zipcode: formZipCode,
            telephone: formTelephone,
            fax: formFax,
            speciality: formSpeciality,
            email: formEmail,
            rep_email: repProfile?.email,
            preferred_delivery_method: fetchDeliveryMethods(),
            requested_date: formDateOfRequest,
            consent: formConsent?.checked,
            // signature: visitType.value === "IN_PERSON" ? formSignature.getTrimmedCanvas().toDataURL('image/png') : null,
            product: formProduct.value,
            medical_info_request: formMedicalInfoRequest,
            submitted_by: repProfile?.first_name,
            rep_id: repId,
            visit_type: visitType.value,
        };

        let isValid = true;
        let requiredData = requiredDataFormTypeMap['MIRF'];

        for (const key in requiredData) {
            if (!(formData[key])) {
                isValid = false;
                swal('Please fill the required field ' + getDisplayNameForRequiredField(key));
                break;
            }
        }

        e.preventDefault();
        if (!isValid) return;

        // Make a call to the backend to save the form data
        axios
            .post(`/v1/rep_orchestrator/rep/mirf`, formData
                , { headers: { 'x-rep-uid': repId } })
            .then((res) => {
                console.log(res);
                setSubmitting(false);
                swal("Success!", `Submitted MIRF successfully`, "success")
                    .then((val) => {
                        setLargeModal(false);
                        setFormData({});
                    });
                fetchAndSetMyMirfs();
                
            })
            .catch((err) => {
                console.log(err);
                swal("Something went wrong!", err.message, "error")
                setSubmitting(false);

            });
        clearAllStates();
        setLargeModal(false);
        setFormData({});
    };

    const getTodayDate = () => {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() < 10 ? '0':'') +(today.getMonth() + 1) + '-'+ (today.getDate() < 10 ? '0':'') + today.getDate();
        console.log("date is ", date);
        return date;
    };


    const handleFormConsent = (value) => {
        setFormConsent({
            ...formConsent,
            // 'checked': value && (visitType.value === 'VIRTUAL' || isSigned.status)
            'checked': value
        });
    }

    const handleSign = (value) => {
        setIsSigned({
            ...isSigned,
            'status': value
        })
    };

    return (
        <Modal
            centered
            show={largeModal}
            size="xl"
            onHide={() => setLargeModal(false)}
        >
            <Modal.Header>
                <Modal.Title>
                    {
                        currentMirf ?
                            "View submitted MIRF " :
                            `Submit MIRF for ${hcp?.display_name}`
                    }
                </Modal.Title>
                <Button
                    variant=""
                    className="close"
                    onClick={() => {
                        setLargeModal(false);
                        clearAllStates();
                    }}
                >
                    <span>&times;</span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="card mb-0">
                    <span style={{ fontSize: '12px', fontStyle: 'italic' }}> Mandatory fields are marked with an asterisk (*)</span>
                    <form onSubmit={(e) => submitHandler(e)}>
                        <div className='row'>
                            <div className="col-xl-12 col-lg-8 mb-2">
                                <div className="basic-form" onChange={(e) => {
                                    // onDoctorUpdate(e.target.value, "TAGS") 
                                }}>
                                    <div className="row mx-0 row-cols-1 row-cols-xl-2 py-4">
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                Name*
                                            </label>
                                            <div className="col-sm-6">
                                                <input
                                                    type='text'
                                                    className="form-control text-center"
                                                    placeholder={"Enter HCP Name"}
                                                    // disabled={disabled ? "disabled" : ""}
                                                    isDisabled={true}
                                                    disabled={true}
                                                    value={formName}
                                                    onChange={(e) => {
                                                        setFormName(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                NPI# *
                                            </label>
                                            <div className="col-sm-6">
                                                <input
                                                    type='text'
                                                    className="form-control text-center"
                                                    isDisabled={disabledSure || disabledOnUpdate}
                                                    disabled={disabledSure}
                                                    value={formNpi}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                Visit Type*
                                            </label>
                                            <div className="col-sm-6">
                                                <Select
                                                    styles={primaryStyle}
                                                    placeholder={'Select Visit Type'}
                                                    value={visitType}
                                                    isDisabled={disabled || disabledOnUpdate}
                                                    options={visitTypes}
                                                    onChange={(e) => {
                                                        setVisitType(e);
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                Date of Request*
                                            </label>
                                            <div className="col-sm-6">
                                                <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}>
                                                    <DatePicker
                                                        autoOk
                                                        disabled={disabledOnUpdate}
                                                        value={formDateOfRequest}
                                                        format="MM/dd/yyyy"
                                                        onChange={(date) => setFormDateOfRequest(date)}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                Product*
                                            </label>
                                            <div className="col-sm-6">
                                                <Select
                                                    styles={primaryStyle}
                                                    placeholder={'Select Product'}
                                                    value={formProduct}
                                                    isDisabled={disabled || disabledOnUpdate}
                                                    options={productTypes}
                                                    onChange={(e) => {
                                                        setFormProduct(e);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                Office/Institution Name
                                            </label>
                                            <div className="col-sm-6">
                                                <input
                                                    type='text'
                                                    className="form-control text-center"
                                                    placeholder={"Enter Office"}
                                                    disabled={disabled || disabledOnUpdate}
                                                    isDisabled={disabledOnUpdate}
                                                    value={formOffice}
                                                    onChange={(e) => {
                                                        setFormOffice(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                Speciality
                                            </label>
                                            <div className="col-sm-6">
                                                <input
                                                    type='text'
                                                    className="form-control text-center"
                                                    placeholder={"Enter Speciality"}
                                                    disabled={disabled || disabledOnUpdate}
                                                    value={formSpeciality}
                                                    onChange={(e) => {
                                                        setFormSpeciality(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                Degree/ Profession*
                                            </label>
                                            <div className="col-sm-6">
                                                <input
                                                    type='text'
                                                    className="form-control text-center"
                                                    placeholder={"Enter Degree"}
                                                    disabled={disabled || disabledOnUpdate}
                                                    isDisabled={disabledOnUpdate}
                                                    value={formDegree}
                                                    onChange={(e) => {
                                                        setFormDegree(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                Street Address*
                                            </label>
                                            <div className="col-sm-6">
                                                <input
                                                    type='text'
                                                    className="form-control text-center"
                                                    placeholder={"Enter Street Address"}
                                                    isDisabled={disabledOnUpdate}
                                                    disabled={disabled || disabledOnUpdate}
                                                    value={formStreetAddress}
                                                    onChange={(e) => {
                                                        setFormStreetAddress(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                City*
                                            </label>
                                            <div className="col-sm-6">
                                                <input
                                                    type='text'
                                                    className="form-control text-center"
                                                    placeholder={"Enter City"}
                                                    isDisabled={disabledOnUpdate}
                                                    disabled={disabled || disabledOnUpdate}
                                                    value={formCity}
                                                    onChange={(e) => {
                                                        setFormCity(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                State*
                                            </label>
                                            <div className="col-sm-6">
                                                <input
                                                    type='text'
                                                    className="form-control text-center"
                                                    placeholder={"Enter State"}
                                                    disabled={disabled || disabledOnUpdate}
                                                    value={formState}
                                                    onChange={(e) => {
                                                        setFormState(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                ZipCode*
                                            </label>
                                            <div className="col-sm-6">
                                                <input
                                                    type='text'
                                                    className="form-control text-center"
                                                    placeholder={"Enter ZipCode"}
                                                    disabled={disabled || disabledOnUpdate}
                                                    value={formZipCode}
                                                    onChange={(e) => {
                                                        setFormZipCode(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                Telephone
                                            </label>
                                            <div className="col-sm-6">
                                                <input
                                                    type='text'
                                                    className="form-control text-center"
                                                    placeholder={"Enter Telephone"}
                                                    disabled={disabled || disabledOnUpdate}
                                                    value={formTelephone}
                                                    onChange={(e) => {
                                                        setFormTelephone(e.target.value);
                                                        console.log("formTelephone", formTelephone);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                Fax
                                            </label>
                                            <div className="col-sm-6">
                                                <input
                                                    type='text'
                                                    className="form-control text-center"
                                                    placeholder={"Enter Fax"}
                                                    disabled={disabled || disabledOnUpdate}
                                                    value={formFax}
                                                    onChange={(e) => {
                                                        setFormFax(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                Email*
                                            </label>
                                            <div className="col-sm-6">
                                                <input
                                                    type='text'
                                                    className="form-control text-center"
                                                    placeholder={"Enter Email"}
                                                    disabled={disabled || disabledOnUpdate}
                                                    isDisabled={disabledOnUpdate}
                                                    value={formEmail}
                                                    onChange={(e) => {
                                                        setFormEmail(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                Submitted By*
                                            </label>
                                            <div className="col-sm-6">
                                                <input
                                                    type='text'
                                                    className="form-control text-center"
                                                    isDisabled={disabledSure || disabledOnUpdate}
                                                    disabled={disabledSure || disabledOnUpdate}
                                                    value={repProfile?.first_name}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mx-0">
                                            <label className="col-sm-5 col-form-label text-capitalize">
                                                RBM Email*
                                            </label>
                                            <div className="col-sm-6">
                                                <input
                                                    type='text'
                                                    className="form-control text-center"
                                                    isDisabled={disabledSure || disabledOnUpdate}
                                                    disabled={disabledSure}
                                                    value={repProfile?.email}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <p className='fs-18 text-dark font-w600 mb-0'>
                                            Preferred Delivery Method
                                        </p>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-lg-2 col-md-5 mb-md-0 mb-2'>
                                                <div class="custom-control custom-checkbox mb-4" style={{ paddingLeft: '40px' }}>
                                                    <input type="checkbox" class="custom-control-input" id="email"
                                                        checked={deliveryMethodStatus['EMAIL']}
                                                        style={{ paddingLeft: '10px' }}
                                                        disabled={disabledOnUpdate}
                                                        onClick={
                                                            (event) => {
                                                                setDeliveryMethodStatus({
                                                                    ...deliveryMethodStatus,
                                                                    'EMAIL': event.target.checked
                                                                });
                                                            }
                                                        }
                                                    />
                                                    <label class="custom-control-label" for="email" style={{ paddingLeft: '10px' }}>
                                                        <p >
                                                            Email
                                                        </p>
                                                    </label>
                                                </div>
                                            </div>

                                            <div className='col-lg-2 col-md-4 mb-md-0 mb-2'>
                                                <div class="custom-control custom-checkbox mb-4">
                                                    <input type="checkbox" class="custom-control-input" id="fax"
                                                        checked={deliveryMethodStatus['FAX']}
                                                        disabled={disabledOnUpdate}
                                                        onClick={
                                                            (event) => {
                                                                setDeliveryMethodStatus({
                                                                    ...deliveryMethodStatus,
                                                                    'FAX': event.target.checked
                                                                });
                                                            }
                                                        }
                                                    />
                                                    <label class="custom-control-label" for="fax" style={{ paddingLeft: 8 }}>
                                                        <p >
                                                            Fax
                                                        </p>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-lg-8 col-md-5 mb-md-0 mb-2'>
                                                <div class="custom-control custom-checkbox mb-4">
                                                    <input type="checkbox" class="custom-control-input" id="custom"
                                                        checked={deliveryMethodStatus['CUSTOM_DELIVERY']}
                                                        disabled={disabledOnUpdate}
                                                        onClick={
                                                            (event) => {
                                                                setDeliveryMethodStatus({
                                                                    ...deliveryMethodStatus,
                                                                    'CUSTOM_DELIVERY': event.target.checked
                                                                });
                                                            }
                                                        }
                                                    />
                                                    <label class="custom-control-label" for="custom" style={{ paddingLeft: 8 }}>
                                                        <p >
                                                            I would like to discuss my questions with a Medical Affairs Representative (Medical Science Team)
                                                        </p>
                                                    </label>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div >
                                        <p className='fs-18 text-dark font-w600 mb-0'>
                                            {
                                                visitType && visitType.value === 'IN_PERSON' ?
                                                 'Medical Information Request(s) [HCP Question is required]' :
                                                'Medical Information Request(s) [HCP Question is required]'
                                            }
                                        </p>
                                    </div>
                                    <div className="form-group" style={{ paddingLeft: '10px', paddingTop: '10px', paddingRight: '10px' }}>
                                        <textarea
                                            rows="5"
                                            placeholder={"Enter Medical Information Requests"}
                                            value={formMedicalInfoRequest}
                                            disabled={disabledOnUpdate}
                                            style={{ minWidth: '100%', paddingLeft: '10px', paddingTop: '10px' }}
                                            onChange={(e) => {
                                                setFormMedicalInfoRequest(e.target.value);
                                            }}
                                        />
                                    </div>
                                    {/* {
                                        visitType && visitType.value === 'IN_PERSON' ?
                                            <div className="col-md-6">
                                                <p >
                                                    HCP Requestor Signature*
                                                </p>
                                            </div> :
                                            ''
                                    }
                                    {
                                        visitType && visitType.value === 'IN_PERSON' && !currentMirf ?
                                            (
                                                <div>
                                                    <div className="form-group row mx-1">

                                                        <SignatureCanvas penColor='green' backgroundColor='rgba(226, 217, 217, 0.8)'
                                                            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                                            onEnd={() => { handleSign(true) }}
                                                            ref={(ref) => {
                                                                setFormSignature(ref);
                                                            }}
                                                        />

                                                    </div>
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn bg-primary-alt text-black rounded-pill px-5"
                                                            onClick={() => {
                                                                formSignature.clear();
                                                                handleSign(false);
                                                                setFormSignature(formSignature);
                                                                handleFormConsent(false);
                                                                console.log(" signed = " , isSigned);
                                                            }}
                                                        >
                                                            Clear Sign
                                                        </button>
                                                    </div>
                                                </div>
                                            ) :
                                            (
                                                currentMirf && visitType && visitType.value === 'IN_PERSON' ?
                                                    <div className="form-group row mx-1">
                                                        <div className="col-sm-6">
                                                            <img src={currentMirf.signature} />
                                                        </div>
                                                    </div>
                                                    :
                                                    ''
                                            )
                                    } */}
                                </div>
                                {
                                    !currentMirf ?
                                        (
                                            <div className='col-lg-12 col-md-5 mb-md-0 mb-2'>
                                                <div class="custom-control custom-checkbox mb-4">
                                                    <input type="checkbox" class="custom-control-input" id="terms"
                                                        checked={formConsent['checked']}
                                                        onClick={
                                                            (event) => {
                                                                console.log(" consent = event.target.checked", event.target.checked);
                                                                handleFormConsent(event.target.checked);
                                                            }
                                                        }
                                                    />
                                                    <label class="custom-control-label" for="terms" style={{ paddingLeft: 8 }}>
                                                    {
                                                            visitType && visitType.value === 'IN_PERSON' ?
                                                                (<p>
                                                                    By checking this box, I attest that this inquiry is an unsolicited request for information from the HCP identified above.
                                                                </p>) :
                                                                (<p>
                                                                    By checking this box, I attest that this inquiry is an unsolicited request for information from the HCP identified above.
                                                                </p>)
                                                    }        
                                                    </label>
                                                </div>
                                            </div>
                                        ) :
                                        ''
                                }
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column flex-md-row"
                         style={{ paddingBottom: '0px' , paddingTop: '0px', marginTop: '0px' , marginBottom: '10px'}}>
                            {
                                !currentMirf ?
                                    <div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary rounded-pill px-5"
                                            disabled={
                                                getIsDisabled()
                                            }
                                        >
                                            Submit MIRF
                                        </button>
                                        <span className="my-3 mx-3" />
                                        <button type="button"
                                            className="btn bg-primary-alt text-black rounded-pill px-5"
                                            onClick={() => {
                                                setLargeModal(false);
                                                clearAllStates();
                                            }}>
                                            Cancel
                                        </button>
                                    </div>
                                    : ''
                            }


                        </div>
                        <div>
                            <p className="new border border-danger">
                                If an adverse event is identified, it must be reported within 24 hours to Aadi Medical Information by calling
                                1-888-BIO-AADI (888-246-2234) or by emailing <a href="mailto:medinfo@aadibio.com"> medinfo@aadibio.com</a>
                            </p>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default MirfFormModal;