import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import DoneIcon from "@material-ui/icons/Done";
import Select from "react-select";
import cr from "../../../images/call_record_green.png";
import PerfectScrollbar from "react-perfect-scrollbar";
import useKeypress from "../../../hooks/useKeypress";
import axios from "axios";
import "../../../css/table.css";

import CloseIcon from "@material-ui/icons/Close";
//Images
import doc from "../../../images/hospital_image.png";
import { IconButton } from "@material-ui/core";
import { primaryStyle } from "../PluginsMenu/Select2/SelectStyles";

const primaryQueryParam = [
  { value: "YES", label: "Yes" },
  { value: "NO", label: "No" },
];

const accessibilityQueryParam = [
  { value: "Email Access Only", label: "Email Access Only" },
  {
    value: "No See Account (Only DTC Promotion)",
    label: "No See Account (Only DTC Promotion)",
  },
  { value: "Closed Campus (No Access)", label: "Closed Campus (No Access)" },
  { value: "One-on-One Appointment", label: "One-on-One Appointment" },
  { value: "Lunch Appointments", label: "Lunch Appointments" },
  { value: "", label: "None" },
];

const HospitalUserProfile = ({
  data,
  profile,
  hospitals,
  onDoctorUpdate,
  onHospitalUpdate,
  doctorsUniverse,
  setDoctorsUniverse,
  doctors,
  updateExistingDoctor,
  sortTable,
  repId,
}) => {
  const [email, setEmail] = useState(profile?.accessibility_field);
  const [editEmail, setEditEmail] = React.useState(false);
  const [editPrimaryHospital, setEditPrimaryHospital] = React.useState(false);
  const [editHospitalId, setEditHospitalId] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const [linkingDoctorsEnabled, setLinkingDoctorsEnabled] =
    React.useState(false);
  const enter = useKeypress("Enter");

  console.log("PRIMARY_H - Doctor", doctors);

  const isPrimaryHospital = (item) => {
    return item.primary_hospital_affiliated_id &&
      item.primary_hospital_affiliated_id == profile.id
      ? "YES"
      : "NO";
  };

  useEffect(() => {
    if (enter) {
      onSearch();
    }
  }, [enter]);

  const wait = (ms) => {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  };

  const onSearch = () => {
    axios
      .get(`/v1/rep_orchestrator/doctor/search?keyword=${search}`, {
        headers: { "x-rep-uid": repId },
      })
      .then((res) => {
        setDoctorsUniverse(res.data.doctors);
        setLinkingDoctorsEnabled(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // A comparer used to determine if two entries are equal.
  const isSameDoctor = (a, b) => a.id == b.id;

  const filterDocs = (univ) => {
    if (!doctors || doctors.length === 0) {
      return univ;
    }

    if (univ && univ.length > 0) {
      // Get items that only occur in the left array,
      // using the compareFunction to determine equality.
      const onlyInLeft = (left, right, compareFunction) =>
        left.filter(
          (leftValue) =>
            !right.some((rightValue) => compareFunction(leftValue, rightValue))
        );

      return onlyInLeft(univ, doctors, isSameDoctor);
    }
    return [];
  };

  const handleLinking = (doc) => {
    onDoctorUpdate(profile, "LINKING_H", doc);
    // .then(() => {
    wait(500);
    setLinkingDoctorsEnabled(false);
    // });
  };

  const handleEditEmailClick = () => {
    setEditEmail(true);
  };

  const closeEditMode = () => {
    setEditEmail(false);
  };

  const disableLinking = () => {
    setLinkingDoctorsEnabled(false);
    if (filterDocs(doctorsUniverse).length == 0) {
      setSearch("");
      // onSearch();
    }
  };

  const enableLinking = () => {
    if (filterDocs(doctorsUniverse).length == 0) {
      onSearch();
    }
    setLinkingDoctorsEnabled(true);
  };

  const handleEditPrimaryHospitalClick = (value) => {
    setEditPrimaryHospital(true);
    setEditHospitalId(value);
  };

  const closeEditPrimaryHospitalMode = () => {
    setEditPrimaryHospital(false);
    setEditHospitalId(null);
  };

  const tagDoctors = (doctors, tagName) => {
    var tagDoctors = [];
    for (var i = 0; i < doctors?.length; ++i) {
      if (doctors[i]?.tags && doctors[i]?.tags.includes(tagName)) {
        console.log(" doctor ", doctors[i].display_name);
        tagDoctors.push(doctors[i]);
      }
    }
    return tagDoctors;
  };

  console.log("Universe Doctors", doctorsUniverse);
  console.log("doctors", doctors);
  return (
    <div className="card mx-3">
      <div className="col-12 col-xl-12">
        <div className="card-body">
          <div className="media d-sm-flex d-block text-center text-sm-left pb-4 mb-4 border-bottom">
            <img
              alt="pic"
              className="rounded mr-sm-4 mr-0"
              width={130}
              src={doc}
            />
            <div className="media-body align-items-center">
              <div className="d-sm-flex d-block justify-content-between my-3 my-sm-0">
                <div>
                  <h3 className="fs-22 text-black font-w600 mb-0">
                    {profile?.display_name
                      ? profile?.display_name
                      : profile?.hospital_name}
                  </h3>
                  <p className="mb-2 mb-sm-2">{profile?.primary_speciality}</p>
                </div>
              </div>
            </div>
            <div className="width350 mb-3">
              <Link
                to={{
                  pathname: "/call-record-hospital/" + profile?.id,
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary rounded-pill w-100 py-2"
                >
                  Record a Call
                </button>
              </Link>
              <a href="https://forms.office.com/r/ruwnpvVpHN" target="_blank">
                <button type="button" className="btn w-150 py-2">
                  Affiliate Physicians / Raise DCR
                </button>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div className="media">
                <span className="p-3 border border-primary-light rounded-circle mr-3">
                  <svg
                    width={22}
                    height={22}
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M27.5716 13.4285C27.5716 22.4285 16.0001 30.1428 16.0001 30.1428C16.0001 30.1428 4.42871 22.4285 4.42871 13.4285C4.42871 10.3596 5.64784 7.41637 7.8179 5.24631C9.98797 3.07625 12.9312 1.85712 16.0001 1.85712C19.0691 1.85712 22.0123 3.07625 24.1824 5.24631C26.3524 7.41637 27.5716 10.3596 27.5716 13.4285Z"
                        stroke="#2BC155"
                        strokeWidth={3}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.0002 17.2857C18.1305 17.2857 19.8574 15.5588 19.8574 13.4286C19.8574 11.2983 18.1305 9.57141 16.0002 9.57141C13.87 9.57141 12.1431 11.2983 12.1431 13.4286C12.1431 15.5588 13.87 17.2857 16.0002 17.2857Z"
                        stroke="#2BC155"
                        strokeWidth={3}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect
                          width="30.8571"
                          height="30.8571"
                          fill="white"
                          transform="translate(0.571533 0.571411)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <div>
                  {" "}
                  <span className="d-block  mb-2">Address</span>
                  <p className="fs-18 text-dark">
                    {profile?.address?.line1}
                    {profile?.address?.street}
                    {", "}
                    {profile?.address?.city}
                    {", "}
                    <strong>
                      {profile?.address?.state}
                      {", "} {profile?.address?.country}
                      {profile?.address?.zipcode}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <span className="d-block  mb-2">HID</span>
              <p className="fs-18 text-dark font-w600 mb-0">
                {profile?.legacy_id}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-6">
              <span className="d-block  mb-2"> Account Accessibility</span>
              {!editEmail ? (
                <p className="fs-18 text-dark font-w600 mb-0">
                  {profile?.accessibility_field
                    ? profile?.accessibility_field
                    : "NONE"}
                  <i
                    className="fa fa-edit line-height-0 btn btn-sort p-1 ml-2"
                    onClick={handleEditEmailClick}
                  />
                </p>
              ) : (
                <p className="fs-18 text-dark font-w600 mb-0">
                  <div className="d-inline-flex justify-content-around">
                    <div className="width280 mr-3 mb-3">
                      <Select
                        styles={primaryStyle}
                        options={accessibilityQueryParam}
                        onChange={(e) => {
                          closeEditPrimaryHospitalMode();
                          setEmail(e.value);
                        }}
                      />
                    </div>

                    <IconButton
                      color="green"
                      onClick={() => {
                        onHospitalUpdate(email, "ACCESSIBILITY", profile);
                        closeEditMode();
                      }}
                      style={{
                        color: "green",
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <DoneIcon />
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        closeEditMode();
                      }}
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </p>
              )}
            </div>
            <div className="col-lg-6 mb-6">
              <span className="d-block  mb-2">Sarcoma Center</span>
              <p className="fs-18 text-dark font-w600 mb-0">
                {profile?.sarcoma_specialized ? "YES" : "NO"}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-2 mb-4">
              <span className="d-block text-black mb-2">
                Physician Assistants:
              </span>

              <tbody>
                {tagDoctors(doctors, "PA")?.map((item) => (
                  <tr role="row" className={item.trClassName}>
                    <td>
                      <Link
                        to={{
                          pathname: "/user-profile/D" + item?.id,
                        }}
                        target="_blank"
                      >
                        {item?.display_name}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </div>
            <div className="col-lg-2 mb-4">
              <span className="d-block text-black mb-2">
                Nurse Practitioners:
              </span>

              <tbody>
                {tagDoctors(doctors, "NP")?.map((item) => (
                  <tr role="row" className={item.trClassName}>
                    <td>
                      <Link
                        to={{
                          pathname: "/user-profile/D" + item?.id,
                        }}
                        target="_blank"
                      >
                        {item?.display_name}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </div>
            <div className="col-lg-2 mb-4">
              <span className="d-block text-black mb-2">
                Registered Nurses:
              </span>

              <tbody>
                {tagDoctors(doctors, "RN")?.map((item) => (
                  <tr role="row" className={item.trClassName}>
                    <td>
                      <Link
                        to={{
                          pathname: "/user-profile/D" + item?.id,
                        }}
                        target="_blank"
                      >
                        {item?.display_name}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </div>
            <div className="col-lg-2 mb-4">
              <span className="d-block text-black mb-2">
                Reimbursement Managers:
              </span>

              <tbody>
                {tagDoctors(doctors, "REBM")?.map((item) => (
                  <tr role="row" className={item.trClassName}>
                    <td>
                      <Link
                        to={{
                          pathname: "/user-profile/D" + item?.id,
                        }}
                        target="_blank"
                      >
                        {item?.display_name}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </div>
            <div className="col-lg-2 mb-4">
              <span className="d-block text-black mb-2">Pharmacists:</span>

              <tbody>
                {tagDoctors(doctors, "PHM")?.map((item) => (
                  <tr role="row" className={item.trClassName}>
                    <td>
                      <Link
                        to={{
                          pathname: "/user-profile/D" + item?.id,
                        }}
                        target="_blank"
                      >
                        {item?.display_name}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </div>
            <div className="col-lg-2 mb-4">
              <span className="d-block text-black mb-2">Others:</span>

              <tbody>
                {tagDoctors(doctors, "OTH")?.map((item) => (
                  <tr role="row" className={item.trClassName}>
                    <td>
                      <Link
                        to={{
                          pathname: "/user-profile/D" + item?.id,
                        }}
                        target="_blank"
                      >
                        {item?.display_name}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </div>
          </div>
          <div>
            {linkingDoctorsEnabled ? (
              <div className="row">
                <div className="col-8">
                  <div className="card p-4">
                    <p className="fs-18 text-dark font-w600 mb-0 py-4">
                      Affiliated HCPs:
                    </p>
                    <div className="col-15">
                      {/* <div className="card">
                      <div className="card-body p-0"> */}
                      <div className="dataTables_wrapper">
                        <Table
                          responsive
                          striped
                          hover
                          id="patientTable_basic_table"
                          className="display dataTable no-footer  w-100"
                        >
                          <thead>
                            <tr role="row">
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span> Name</span>
                                  <div className="d-flex flex-column ml-2">
                                    <i
                                      className="fa fa-sort-up line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable("display_name", "DOCTORS", 0)
                                      }
                                    />
                                    <i
                                      className="fa fa-sort-down line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable("display_name", "DOCTORS", 1)
                                      }
                                    />
                                  </div>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Primary ?</span>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>NPID</span>
                                  <div className="d-flex flex-column ml-2">
                                    <i
                                      className="fa fa-sort-up line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable("npid", "DOCTORS", 0)
                                      }
                                    />
                                    <i
                                      className="fa fa-sort-down line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable("npid", "DOCTORS", 1)
                                      }
                                    />
                                  </div>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>City</span>
                                  <div className="d-flex flex-column ml-2">
                                    <i
                                      className="fa fa-sort-up line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "city"],
                                          "DOCTORS",
                                          0
                                        )
                                      }
                                    />
                                    <i
                                      className="fa fa-sort-down line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "city"],
                                          "DOCTORS",
                                          1
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>State</span>
                                  <div className="d-flex flex-column ml-2">
                                    <i
                                      className="fa fa-sort-up line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "state"],
                                          "DOCTORS",
                                          0
                                        )
                                      }
                                    />
                                    <i
                                      className="fa fa-sort-down line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "state"],
                                          "DOCTORS",
                                          1
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Zipcode</span>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Record</span>
                                </div>
                              </th>
                              {/* <th className="sorting">
                                  <div className="d-flex align-items-center">
                                    <span>Profile</span>
                                  </div>
                                </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {doctors?.map((item) => (
                              <tr role="row" className={item.trClassName}>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/user-profile/D" + item?.id,
                                    }}
                                    target="_blank"
                                  >
                                    {item?.display_name}
                                  </Link>
                                </td>
                                <td>
                                  {editPrimaryHospital &&
                                  editHospitalId === item?.id
                                    ? ""
                                    : isPrimaryHospital(item)}
                                  {editPrimaryHospital &&
                                  editHospitalId === item?.id ? (
                                    <p className="fs-18 text-dark font-w600 mb-0">
                                      <div className="d-inline-flex justify-content-around">
                                        <div className="width150 mr-3 mb-3">
                                          <Select
                                            styles={primaryStyle}
                                            options={primaryQueryParam}
                                            onChange={(e) => {
                                              closeEditPrimaryHospitalMode();
                                              onDoctorUpdate(
                                                ""
                                                  .concat(profile?.id, "_")
                                                  .concat(e.value),
                                                "PRIMARY_H",
                                                item
                                              );
                                            }}
                                          />
                                        </div>
                                        <IconButton
                                          onClick={() => {
                                            closeEditPrimaryHospitalMode();
                                          }}
                                          style={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                          }}
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                      </div>
                                    </p>
                                  ) : (
                                    <i
                                      className="fa fa-edit line-height-0 btn btn-sort p-1 ml-2"
                                      onClick={() => {
                                        handleEditPrimaryHospitalClick(
                                          item?.id
                                        );
                                      }}
                                    />
                                  )}
                                </td>
                                <td>{item?.npid}</td>
                                <td> {item?.address?.city}</td>
                                <td> {item?.address?.state}</td>
                                <td> {item?.address?.zipcode}</td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/call-record/" + item?.id,
                                    }}
                                    target="_blank"
                                  >
                                    <img
                                      alt="pic"
                                      className="rounded mr-sm-4 mr-0"
                                      width={40}
                                      src={cr}
                                    />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                        {/* </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="card p-4">
                    <form
                      noValidate
                      className="form-head"
                      onSubmit={(event) => event.preventDefault()}
                    >
                      <div>
                        <p className="fs-18 text-dark font-w600 mb-0 py-4">
                          Search HCPs
                          <button
                            className="btn btn-primary btn-sm ml-2"
                            style={{ marginLeft: "900px" }}
                            onClick={() => {
                              disableLinking();
                            }}
                          >
                            Close
                          </button>
                        </p>
                      </div>
                      <div className="input-group search-area d-inline-flex py-2 border">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search here"
                          onChange={(e) => {
                            setSearch(e.target.value);
                            // setSearchActive(true);
                          }}
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="input-group-text"
                            onClick={() => onSearch()}
                          >
                            <i className="flaticon-381-search-2" />
                          </button>
                        </div>
                      </div>
                    </form>
                    {filterDocs(doctorsUniverse).length == 0 ? (
                      <div
                        className="alert alert-danger"
                        role="alert"
                        style={{
                          marginTop: 30,
                          paddingTop: 10,
                          paddingBottom: 0,
                        }}
                      >
                        No results found
                      </div>
                    ) : (
                      ""
                    )}
                    {filterDocs(doctorsUniverse) ? (
                      <div>
                        <PerfectScrollbar
                          id="DZ_W_Todo2"
                          className="widget-media dz-scroll ps ps--active-y height400 mt-4 pr-4"
                        >
                          {filterDocs(doctorsUniverse)?.map((item) => (
                            <div className="d-flex mb-4">
                              <div>
                                <div className="font-weight-bold text-black">
                                  <Link
                                    to={{
                                      pathname: "/user-profile/D" + item?.id,
                                    }}
                                    target="_blank"
                                  >
                                    {item?.display_name}
                                  </Link>
                                </div>
                                <div>{item?.primary_speciality}</div>
                                <div>{item?.npid}</div>
                                {/* <div>{item?.tier}</div> */}
                              </div>
                              <div className="ml-auto">
                                <div className="custom-control custom-checkbox mb-0 check-lg">
                                  <button
                                    type="button"
                                    className="btn btn-primary rounded-pill w-20 py-2"
                                    onClick={() => handleLinking(item)}
                                  >
                                    Affiliate
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </PerfectScrollbar>
                      </div>
                    ) : (
                      <div>
                        <p className="fs-18 text-dark font-w600 mb-0 py-4">
                          No Results Found
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                {/* <p className='text-dark font-w600 mb-1 py-4 '> */}
                <p className="fs-18 text-dark font-w600 mb-0 py-4">
                  Affiliated Physicians:
                  <button
                    type="button"
                    className="btn btn-primary rounded-pill w-20 py-2"
                    style={{ marginLeft: "850px" }}
                    onClick={() => enableLinking()}
                  >
                    Affiliate new HCPs
                  </button>
                </p>
                <div className="col-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div className="dataTables_wrapper">
                        <Table
                          responsive
                          striped
                          hover
                          id="patientTable_basic_table"
                          className="display dataTable no-footer  w-100"
                        >
                          <thead>
                            <tr role="row">
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span> Name</span>
                                  <div className="d-flex flex-column ml-2">
                                    <i
                                      className="fa fa-sort-up line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable("display_name", "DOCTORS", 0)
                                      }
                                    />
                                    <i
                                      className="fa fa-sort-down line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable("display_name", "DOCTORS", 1)
                                      }
                                    />
                                  </div>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Primary Affiliated ?</span>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>NPID</span>
                                  <div className="d-flex flex-column ml-2">
                                    <i
                                      className="fa fa-sort-up line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable("npid", "DOCTORS", 0)
                                      }
                                    />
                                    <i
                                      className="fa fa-sort-down line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable("npid", "DOCTORS", 1)
                                      }
                                    />
                                  </div>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>City</span>
                                  <div className="d-flex flex-column ml-2">
                                    <i
                                      className="fa fa-sort-up line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "city"],
                                          "DOCTORS",
                                          0
                                        )
                                      }
                                    />
                                    <i
                                      className="fa fa-sort-down line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "city"],
                                          "DOCTORS",
                                          1
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>State</span>
                                  <div className="d-flex flex-column ml-2">
                                    <i
                                      className="fa fa-sort-up line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "state"],
                                          "DOCTORS",
                                          0
                                        )
                                      }
                                    />
                                    <i
                                      className="fa fa-sort-down line-height-0 btn btn-sort p-1"
                                      onClick={() =>
                                        sortTable(
                                          ["address", "state"],
                                          "DOCTORS",
                                          1
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Zipcode</span>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Record</span>
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex align-items-center">
                                  <span>Profile</span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {doctors?.map((item) => (
                              <tr role="row" className={item.trClassName}>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/user-profile/D" + item?.id,
                                    }}
                                    target="_blank"
                                  >
                                    {item?.display_name}
                                  </Link>
                                </td>
                                <td>
                                  {editPrimaryHospital &&
                                  editHospitalId === item?.id
                                    ? ""
                                    : isPrimaryHospital(item)}
                                  {editPrimaryHospital &&
                                  editHospitalId === item?.id ? (
                                    <p className="fs-18 text-dark font-w600 mb-0">
                                      <div className="d-inline-flex justify-content-around">
                                        <div className="width150 mr-3 mb-3">
                                          <Select
                                            styles={primaryStyle}
                                            options={primaryQueryParam}
                                            onChange={(e) => {
                                              closeEditPrimaryHospitalMode();
                                              onDoctorUpdate(
                                                ""
                                                  .concat(profile?.id, "_")
                                                  .concat(e.value),
                                                "PRIMARY_H",
                                                item
                                              );
                                            }}
                                          />
                                        </div>
                                        <IconButton
                                          onClick={() => {
                                            closeEditPrimaryHospitalMode();
                                          }}
                                          style={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                          }}
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                      </div>
                                    </p>
                                  ) : (
                                    <i
                                      className="fa fa-edit line-height-0 btn btn-sort p-1 ml-2"
                                      onClick={() => {
                                        handleEditPrimaryHospitalClick(
                                          item?.id
                                        );
                                      }}
                                    />
                                  )}
                                </td>
                                <td>{item?.npid}</td>
                                <td> {item?.address?.city}</td>
                                <td> {item?.address?.state}</td>
                                <td> {item?.address?.zipcode}</td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/call-record/" + item?.id,
                                    }}
                                    target="_blank"
                                  >
                                    <img
                                      alt="pic"
                                      className="rounded mr-sm-4 mr-0"
                                      width={40}
                                      src={cr}
                                    />
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/user-profile/D" + item?.id,
                                    }}
                                    target="_blank"
                                  >
                                    <svg
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M15.75 12C15.75 12.9946 15.3549 13.9484 14.6517 14.6517C13.9484 15.3549 12.9946 15.75 12 15.75C11.0054 15.75 10.0516 15.3549 9.34835 14.6517C8.64509 13.9484 8.25 12.9946 8.25 12C8.25 11.0054 8.64509 10.0516 9.34835 9.34835C10.0516 8.64509 11.0054 8.25 12 8.25C12.9946 8.25 13.9484 8.64509 14.6517 9.34835C15.3549 10.0516 15.75 11.0054 15.75 12V12Z"
                                        fill="black"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0 12C0 12 4.5 3.75 12 3.75C19.5 3.75 24 12 24 12C24 12 19.5 20.25 12 20.25C4.5 20.25 0 12 0 12ZM12 17.25C13.3924 17.25 14.7277 16.6969 15.7123 15.7123C16.6969 14.7277 17.25 13.3924 17.25 12C17.25 10.6076 16.6969 9.27226 15.7123 8.28769C14.7277 7.30312 13.3924 6.75 12 6.75C10.6076 6.75 9.27226 7.30312 8.28769 8.28769C7.30312 9.27226 6.75 10.6076 6.75 12C6.75 13.3924 7.30312 14.7277 8.28769 15.7123C9.27226 16.6969 10.6076 17.25 12 17.25V17.25Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default HospitalUserProfile;
