import authReducer from "./authReducer";
import userReducer from "./userReducer";
import { firebaseReducer } from "react-redux-firebase";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth: authReducer,
  firebase: firebaseReducer,
  user: userReducer,
});

export default rootReducer;
